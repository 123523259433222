import WithTitleSetter from "./WithTitleSetter";
import WithAccessTokenOContext from "./WithAccessTokenOContext";
import IAccessToken from "../IAccessToken";
import {ReactElement} from "react";

export const WithTitleSetterAndAccessTokenContext = (
    {
        title,
        child
    }: {
        title: string;
        child: (accessTokenO: IAccessToken | null) => ReactElement;
    }
) =>
    <WithTitleSetter title={title}>
        <WithAccessTokenOContext child={child}/>
    </WithTitleSetter>;

export const WithTitleOSetterAndAccessTokenOContext = (
    {
        titleO,
        child
    }: {
        titleO?: string;
        child: (accessTokenO: IAccessToken | null) => ReactElement;
    }
) =>
    !!titleO
        ? <WithTitleSetter title={titleO}>
            <WithAccessTokenOContext child={child}/>
        </WithTitleSetter> :
        <WithAccessTokenOContext child={child}/>;
