import BankinStarted from "./Bankin/BankinStarted";
import Button from "factor-lib/Buttons/Button";
import { ICustomer } from "../../sellerDetailsQuery";
import IAuthUser from "../../../../../IAuthUser";
import { useState } from "react";
import Loader from "factor-lib/Loader";
import { Axios } from "axios";
import GraphQLQueryWrapper from "../../../../../utils/GraphQLQueryWrapper";

// const sendBankinConnectEmailAsync = async (
//     accessToken: string,
//     customerId: string
// ): Promise<void> => {
//     await axios.put<string>(
//         `${CurrentEnv.baseBackendUrl}/adminBankin/${customerId}/connectEmail`,
//         { },
//         optionsHeadersAuthorizationHeaderAndJsonContentOnly(accessToken)
//     ) // TODO : update the query
//     .catch(windowErrorHandler);
// }

const BankinSynchronizationLinkButton = (
    {
        axios,
        customerId
    }: {
        axios: Axios;
        customerId: string;
    }
) => {
    const [ getLink, setGetLink ] = useState(false);

    return (
        !getLink
            ? <Button id='getBankinLink'
                      className='p-margin-right-4'
                      text='Get link'
                      isLoading={false}
                      actionO={() => setGetLink(true)}/>
            : <GraphQLQueryWrapper queryKey={['bankin-link', customerId]}
                                   axios={axios}
                                   queryParams={{
                                       query: `
                                           query Q($customerId: Guid!) {
                                               customerDetails (id: $customerId) {
                                                   bankin { link }
                                               }
                                           }
                                       `,
                                       variables: {
                                           customerId
                                       }
                                   }}
                                   onLoading={() => <Loader/> }
                                   onSuccess={(r: { customerDetails:  { bankin:  { link: string }  } }) =>
                                       <textarea className='textarea is-small'
                                                 rows={2}
                                                 readOnly
                                                 value={r.customerDetails.bankin.link} />
                                   } />
    );
}

const BankinSection = (
    {
        axios,
        authUser,
        customer,
        sellerIbanO
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        customer: ICustomer;
        sellerIbanO: string | null;
    }
) =>
    <div>
        { !!customer.bankin.started
            ? <BankinStarted axios={axios}
                             customerId={customer.id}
                             authUser={authUser}
                             incompleteStep={customer.bankin.started.incompleteStep}
                             items={customer.bankin.started.items.map((i) => i.content)}
                             sellerIbanO={sellerIbanO} />
            : 'Bankin flow not started'
        }

        { !!authUser?.canManageProfiles &&
            <div className='p-margin-top-5'>
                {/* <FButtonPromise className='p-margin-right-4'
                                    id='sendBankinEmail'
                                    kind={KIND_PRIMARY}
                                    size={SIZE_FIXED}
                                    text='Send bankin email'
                                    action={() => sendBankinConnectEmailAsync(accessToken, customer.id)}/>*/}
                <BankinSynchronizationLinkButton axios={axios}
                                                 customerId={customer.id} />
            </div>
        }
    </div>;

export default BankinSection;
