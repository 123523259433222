import {ICompany, ISellerReminderEmailsSettings} from "../../sellerDetailsQuery";
import UpdateSellerContact from "./MainInfos/UpdateSellerContact";
import IAuthUser from "../../../../../IAuthUser";
import { Link } from "react-router-dom";
import SellerReminderEmailsSettings from "./MainInfos/ReminderEmailsSettings";
import DimplScoreInfos from "../../../../../utils/DimplScore/DimplScoreInfos";
import {Axios} from "axios";
import UpdateSellerIban from "./MainInfos/UpdateSellerIban";
import {customerUrl} from "../../../../Customers/customersUrls";
import { formatIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import {companyUrl} from "../../../../Companies/companiesUrls";


const SellerMainInfosSection = (
    {
        axios,
        authUser,
        sellerId,
        customerId,
        marketplaceName,
        email,
        phone,
        ibanO,
        isCertified,
        company,
        customCompanyFriendlyName,
        reminderEmailsSettings
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        sellerId: string;
        customerId: string;
        marketplaceName: string | null; // null -> direct
        email: string;
        phone: string;
        ibanO: string | null;
        isCertified: boolean;
        company: ICompany;
        customCompanyFriendlyName: string | null;
        reminderEmailsSettings: ISellerReminderEmailsSettings;
    }
) =>
    <div>
        <table className='table is-narrow'>
            <tbody>
                <tr>
                    <td className='p-bold'>Nom société</td>
                    <td >
                        <span style={{textTransform: 'uppercase'}}>
                            { company.name }
                        </span>
                        &nbsp;[
                        <Link to={companyUrl(company.id)}>
                            lien
                        </Link>
                        ]
                    </td>
                </tr>
                <tr>
                    <td className='p-bold'>Plateforme</td>
                    <td>
                        { !marketplaceName ? 'Directe' : `Marketplace ${marketplaceName}` }
                        &nbsp;[
                        <Link id='sellerCustomerLink'
                              to={customerUrl(customerId)}>
                            lien
                        </Link>
                        ]
                    </td>
                </tr>
                <tr>
                    <td className='p-bold'>Identifiant</td>
                    <td>{ formatIntCompanyIdentifier(company.identifier) }</td>
                </tr>
                <tr>
                    <td className='p-bold'>Email</td>
                    <td>{ email }</td>
                </tr>
                <tr>
                    <td className='p-bold'>Téléphone</td>
                    <td>{ phone }</td>
                </tr>
                <tr>
                    <td className='p-bold'>IBAN</td>
                    <td>{ ibanO ?? '<<none>>' }</td>
                </tr>
            </tbody>
        </table>

        <DimplScoreInfos loadedClassName='p-margin-top-5'
                         axios={axios}
                         companyId={company.id} />

        <div className='p-horizontal-center p-margin-top-4'>
            { !!authUser?.canManageProfiles &&
                <div className='p-horizontal-center'>
                    <UpdateSellerContact axios={axios}
                                         sellerId={sellerId}
                                         email={email}
                                         phone={phone} />

                    { (!isCertified || authUser?.canUpdateIbanPostCertif) &&
                        <UpdateSellerIban className='p-margin-left-5'
                                          axios={axios}
                                          sellerId={sellerId}
                                          isCertified={isCertified}
                                          ibanO={ibanO} />
                    }
                </div>
            }

            <SellerReminderEmailsSettings className='p-margin-left-5'
                                          axios={axios}
                                          authUser={authUser}
                                          sellerId={sellerId}
                                          settings={reminderEmailsSettings}
                                          customCompanyFriendlyName={customCompanyFriendlyName} />
        </div>
    </div>;

export default SellerMainInfosSection;
