import {ICustomerQueryResult} from "./customersQuery";
import {useContext} from "react";
import { IPaginated, getNodes, hasNextPage } from "factor-lib/utils/graphQLPagination";
import {NavigateFunction, useParams} from "react-router-dom";
import {customerUrl, CustomerUrlCustomerIdParam} from "./customersUrls";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import {InfiniteData} from "@tanstack/react-query";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Loader from 'factor-lib/Loader';

// const compareCustomerDate = comparingRev(
//     comparingDate((customer: ICustomerQueryResult) => serverDateDeserialization(customer.creationDateTime))
// );

const CustomersListLoaded = (
    {
        customers,
        fetchNextPage,
        isIncrementalLoading,
        // activeFilter,
        displaySpecific
    }: {
        // customers: Array<ICustomerQueryResult>;
        customers: InfiniteData<IPaginated<ICustomerQueryResult>>;
        fetchNextPage: () => void;
        isIncrementalLoading: boolean;
        // activeFilter: (customer: ICustomerQueryResult) => boolean;
        displaySpecific: boolean;
    }
) => {

    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    const selectedCustomerId = useParams()[CustomerUrlCustomerIdParam]!;

    const customersHasNextPage = hasNextPage(customers);

    const [sentryRef] = useInfiniteScroll({
        loading: isIncrementalLoading,
        hasNextPage: customersHasNextPage,
        onLoadMore: fetchNextPage,
        rootMargin: '0px 0px 400px 0px',
    });

    return (
        <div>
            { getNodes<ICustomerQueryResult>(customers)
                // .filter(activeFilter)
                // .sort(compareCustomerDate)
                .map((customer) =>
                    <div key={`customer-${customer.id}`}
                        className={`panel-block ${customer.id === selectedCustomerId ? 'p-ok p-bold' : 'p-clickable'}`}
                        onClick={() => navigate(customerUrl(customer.id))}
                        style={{
                            fontSize: '1rem' // TODO
                        }}
                    >
                        { displaySpecific && <span className='p-bold'>{ !!customer.marketplace ? '[M]' : '[D]' }&nbsp;</span> }
                        { customer.contactEmail }&nbsp;-&nbsp;<span style={{textTransform: 'uppercase'}}>{ customer.company.name }</span>
                    </div>
                )
            }

            {/* https://github.com/onderonur/react-infinite-scroll-hook#simple-example */}
            { (isIncrementalLoading || customersHasNextPage) &&
                <div ref={sentryRef}>
                    <Loader />
                </div>
            }
        </div>
    );
}

export default CustomersListLoaded;
