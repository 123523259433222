import { Axios } from "axios";
import {QueryClient, useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import {
    ICustomerFinancingRequest,
} from "../../../utils/FinancingRequest/FinancingRequestsQuery";
import {
    INewFinancingResponse,
    financeManualAction,
    postFinanceUpdateQueries,
} from "../../../utils/Financing/financingPaymentUtils";
import ILoadedSelected from "./ILoadedSelected";
import ButtonWithConfirmationModal from "factor-lib/Buttons/ButtonMutationWithConfirmationModal";
import {IBaseInvoiceSellerWithCompany, IInvoiceBuyerCompany} from "../../../utils/IInvoice";

const FinanceManualEnabled = (
    {
        customerFinancingRequests,
        axios,
        kind,
        size,
        text,

        sellers,
        buyerCompanies
    } : {
        customerFinancingRequests: ICustomerFinancingRequest[];
        axios: Axios;
        kind: string;
        size: string;
        text: string;
        sellers: IBaseInvoiceSellerWithCompany[];
        buyerCompanies: IInvoiceBuyerCompany[];
    }
) => {
    const queryClient: QueryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const financeManualMutation: UseMutationResult<ReadonlyArray<INewFinancingResponse>, any, ReadonlyArray<ICustomerFinancingRequest>> =
        useMutation<ReadonlyArray<INewFinancingResponse>, any, ReadonlyArray<ICustomerFinancingRequest>>(
            (selectedCustomerFinancingRequests2) => financeManualAction(axios, selectedCustomerFinancingRequests2),
            ({
                onSuccess: (
                    newFinancings: ReadonlyArray<INewFinancingResponse>,
                    selectedCustomerFinancingRequests2: ReadonlyArray<ICustomerFinancingRequest>
                ) => postFinanceUpdateQueries(
                    queryClient,
                    selectedCustomerFinancingRequests2,
                    sellers,
                    buyerCompanies,
                    newFinancings
                )
            })
        );

    return (
        <ButtonWithConfirmationModal id='financeManual'
                                     kind={kind}
                                     size={size}
                                     text={text}
                                     confirmationText='Etes vous sûr de vouloir payer manuellement ?'
                                     mutation={financeManualMutation}
                                     value={customerFinancingRequests} />
    );
}

const ButtonText = 'Payer manuellement';

const PendingPaymentsPayManually = (
    {
        className,
        axios,
        loadedO
    }: {
        className?: string;
        axios: Axios;
        loadedO: ILoadedSelected | null;
    }
) =>
    <div className={className}>
        { !!loadedO
            ? loadedO.selectedCustomerFinancingRequests.length > 0
                ? <FinanceManualEnabled axios={axios}
                                        customerFinancingRequests={loadedO.selectedCustomerFinancingRequests}
                                        sellers={loadedO.sellers}
                                        buyerCompanies={loadedO.buyerCompanies}
                                        kind={KIND_PRIMARY}
                                        size={SIZE_FIXED}
                                        text={ButtonText} />
                : <ButtonDisabled kind={KIND_PRIMARY}
                                  size={SIZE_FIXED}
                                  text={ButtonText} />

            : <ButtonDisabled kind={KIND_PRIMARY}
                              size={SIZE_FIXED}
                              text={ButtonText} />
        }
    </div>;

export default PendingPaymentsPayManually;
