import CustomerMainInfosSection from "./CustomerInfosLoaded/CustomerMainInfosSection";
import { ICustomerDetails } from "./customerDetailsQuery";
import CustomerLimitsSection from "./CustomerInfosLoaded/CustomerLimitsSection";
import IAuthUser from "../../../IAuthUser";
import CustomerDirectInfosSection from "./CustomerInfosLoaded/CustomerDirectInfos";
import CustomerMarketplaceInfosSection from "./CustomerInfosLoaded/CustomerMarketplaceInfos";
import { Axios } from "axios";
import CustomerUsersSection from "./CustomerInfosLoaded/CustomerUsersSection";
import CustomerStripeSection from "./CustomerInfosLoaded/CustomerStripeSection";
import { ReactElement } from "react";
import CustomerMarketplaceSwanSection from "./CustomerInfosLoaded/CustomerMarketplaceSwanSection";
import CustomerMiscSection from "./CustomerInfosLoaded/CustomerMiscSection";

const Section = (
    {
        title,
        children
    }: {
        title: string;
        children: ReactElement;
    }
) =>
    <section className='box'>
        <div className='title'>{ title }</div>
        { children }
    </section>;

const CustomerInfosTab = (
    {
        axios,
        authUserO,
        customerId,
        customerDetails
    }: {
        axios: Axios;
        authUserO: IAuthUser | null;
        customerId: string;
        customerDetails: ICustomerDetails;
    }
) =>
    <div>
        <Section title='Informations Générales'>
            <CustomerMainInfosSection axios={axios}
                                      authUser={authUserO}
                                      customerId={customerId}
                                      hasCertifiedSellers={!!customerDetails.marketplace?.hasCertifiedSellers|| !!customerDetails.directSeller?.seller.certif}
                                      contactEmail={customerDetails.contactEmail}
                                      contactPhone={customerDetails.contactPhone}
                                      billingIbanO={customerDetails.billingIban}
                                      company={customerDetails.company} />
        </Section>

        <Section title='Utilisateurs'>
            <CustomerUsersSection axios={axios}
                                  authUser={authUserO}
                                  customerId={customerId}
                                  users={customerDetails.users} />
        </Section>

        <Section title='Stripe'>
            <CustomerStripeSection axios={axios}
                                   authUser={authUserO}
                                   customerId={customerId}
                                   companyName={customerDetails.company.name}
                                   contactEmail={customerDetails.contactEmail}
                                   billingIbanO={customerDetails.billingIban}
                                   directSellerO={!!customerDetails.directSeller ? {
                                       ibanO: customerDetails.directSeller.seller.iban
                                   } : null}
                                   existsInStripe={!!customerDetails.stripeCustomerId} />
        </Section>

        <Section title='Limites'>
            <CustomerLimitsSection axios={axios}
                                   authUser={authUserO}
                                   customerId={customerId}
                                   outstandingLimit={customerDetails.outstandingLimit}
                                   minInvoiceAmount={customerDetails.minInvoiceAmount}
                                   autoApproveFinancingRequests={customerDetails.autoApproveFinancingRequests}/>
        </Section>

        <Section title={`Infos supplémentaires de ${!!customerDetails.marketplace ? 'la marketplace' : 'la plateforme directe'}`}>
            { !!customerDetails.marketplace
                ? <CustomerMarketplaceInfosSection axios={axios}
                                                   authUser={authUserO}
                                                   customerId={customerId}
                                                   marketplaceId={customerDetails.marketplace.id}
                                                   outstandingLimitPerSeller={customerDetails.marketplace.outstandingLimitPerSeller} />
                : <CustomerDirectInfosSection customerId={customerId}
                                              sellerId={customerDetails.directSeller?.seller.id || null} />
            }
        </Section>

        { !!customerDetails.marketplace /* Display only for marketplace */ &&
            <Section title='Swan'>
                <CustomerMarketplaceSwanSection axios={axios}
                                                authUserO={authUserO}
                                                customerId={customerId}
                                                virtualIban={customerDetails.dimplWallet?.virtualIban || null}
                                                instantPayment={customerDetails.marketplace.swanInstantPayment}
                                                marketplaceId={customerDetails.marketplace.id}
                                                invoicePer10000ForSeller={customerDetails.marketplace.invoicePer10000ForSeller} />
            </Section>
        }

        <Section title='Misc'>
            <CustomerMiscSection axios={axios}
                                 authUser={authUserO}
                                 customerId={customerId}
                                 newPricingAcceptedDate={customerDetails.newPricingAcceptedDate}
                                 newPricingRequiredDate={customerDetails.newPricingRequiredDate}
                                 accountManager={customerDetails.effectiveAccountManager} />
        </Section>
    </div>;

export default CustomerInfosTab;
