import { ConfidenceFlag } from "../../utils/ConfidenceFlags";
import {ISellerQueryResult} from "./sellersQuery";
import { comparingRev } from "factor-lib/utils/comparingUtils";
import {useContext} from "react";
import { serverDateDeserialization, comparingDate } from "factor-lib/utils/dateUtils";
import {NavigateFunction, useParams} from "react-router-dom";
import {sellerUrl, SellerUrlSellerIdParam} from "./sellersUrls";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";

const compareSellerDate = comparingRev(
    comparingDate((seller: ISellerQueryResult) => serverDateDeserialization(seller.creationDateTime))
);

const filterSellerInput = (filterInput: string) =>
    (seller: ISellerQueryResult): boolean => 
        [
            seller.id,
            seller.email,
            seller.company.name,
            seller.company.identifier.type,
            seller.company.identifier.value
        ]
            .some((s) => s.toLowerCase().includes(filterInput.toLowerCase()));

const SellersListLoaded = (
    {
        sellers,
        activeFilter,
        searchInput,
        displaySpecific
    }: {
        sellers: Array<ISellerQueryResult>;
        activeFilter: (seller: ISellerQueryResult) => boolean;
        searchInput: string;
        displaySpecific: boolean;
    }
) => {
    const selectedSellerId = useParams()[SellerUrlSellerIdParam]!;
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    return (
        <div>
            { sellers
                .filter(activeFilter)
                .filter(filterSellerInput(searchInput))
                .sort(compareSellerDate)
                .map((seller) =>
                    <div key={`seller-${seller.id}`}
                        className={`panel-block ${seller.id === selectedSellerId ? 'p-ok p-bold' : ' p-clickable'}`}
                        onClick={() => navigate(sellerUrl(seller.id))}
                        style={{
                            fontSize: '1rem', // TODO
                            color: seller.isBlocked || (
                                !!seller.certif &&
                                (seller.certif.solvaConfidenceFlag === ConfidenceFlag.RED || seller.certif.fraudConfidenceFlag === ConfidenceFlag.RED)
                            )
                                ? 'red'
                                : undefined
                        }}
                    >
                        { displaySpecific && <span className='p-bold'>{ !!seller.customer.directSeller ? '[D]' : '[M]' }&nbsp;</span> }
                        { seller.email }&nbsp;-&nbsp;<span style={{textTransform: 'uppercase'}}>{ seller.company.name }</span>
                    </div>
                )
            }
        </div>
    );
}

export default SellersListLoaded;
