import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import GraphQLWithAccessTokenWrapper from "../../../GraphQLQueryWithAccessTokenWrapper";
import IAccessToken from "../../../IAccessToken";
import IAuthUserLoad from "../../../IAuthUserLoad";
import { AddressGraphQLFields } from "../../../utils/IAddress";
import { companyDetailsQueryKey, ICompanyDetailsQueryResult } from "./companyDetailsQuery";
import CompanyInfosTemplate from "./CompanyInfosTemplate";

const CompanyInfosTab = (
    {
        accessTokenO,
        companyId
    }: {
        accessTokenO: IAccessToken | null;
        companyId: string;
    }
) =>
    <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                   queryKey={companyDetailsQueryKey(companyId)}
                                   queryParams={{
                                       query: `query Q($companyId: Guid!) {
                                           companyDetails (id: $companyId) {
                                               id
                                               creationDateTime
                                               identifier { ${IntCompanyIdentifierGraphQLFields} }
                                               name
                                               address { ${AddressGraphQLFields} }
                                           }
                                       }`,
                                       variables: {
                                           companyId
                                       }
                                   }}
                                   onLoading={() =>
                                       <CompanyInfosTemplate companyId={companyId}
                                                             loaded={null} />
                                    }
                                   onSuccess={(r: IAuthUserLoad<ICompanyDetailsQueryResult>) =>
                                       <CompanyInfosTemplate companyId={companyId}
                                                             loaded={{
                                                                axios: r.axios,
                                                                authUserO: r.authUserO,
                                                                value: r.value.companyDetails
                                                             }} />
                                   } />;

export default CompanyInfosTab;
