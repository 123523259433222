import {Axios} from "axios";
import {NavigateFunction} from "react-router-dom";
import { KIND_DANGER, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import ButtonMutationWithConfirmation from "factor-lib/Buttons/ButtonMutationWithConfirmationModal";
import { IInvoicesResponse } from "../../Invoices/EligibleOrPendingEligibleInvoicesTable";
import { ICustomerFinancingRequestsResponse, NewFinancingRequestsQueryKeyPrefix } from "../../../utils/FinancingRequest/FinancingRequestsQuery";
import { useContext } from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { InvoicesUrl } from "../../Invoices/invoicesUrls";
import { InvoicesNotFinancingRequestedQueryKeyPrefix } from "../../../utils/InvoiceUtils";

const InvoiceDeleteButtonEnabled = (
    {
        axios,
        invoiceId
    } : {
        axios: Axios;
        invoiceId: string;
    }
) => {

    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    const queryClient = useQueryClient();

    const deleteInvoiceMutation: UseMutationResult<void, any, string> =
    useMutation<void, any, string>(
        async (invoiceId2) => {
            await axios.delete<string>(
                `/adminInvoices/${invoiceId2}`
            );
        },
        ({
            onSuccess: () => {
                queryClient.setQueryData<IInvoicesResponse>(
                    InvoicesNotFinancingRequestedQueryKeyPrefix,
                    old => !!old ? {
                        invoices: {
                            ...old.invoices,
                            base: old.invoices.base.filter(i => i.id !== invoiceId)
                        }
                    } : undefined
                );

                queryClient.setQueriesData<ICustomerFinancingRequestsResponse<null>>(
                    NewFinancingRequestsQueryKeyPrefix,
                    old => !!old ? {
                        financingRequests: {
                            ...old.financingRequests,
                            base: old.financingRequests.base
                                .filter(i => i.invoice.id !== invoiceId)
                        }
                    } : undefined
                );

                navigate(InvoicesUrl);
            }
        })
    );

    return (
        <div className='level'>
            <div className='level-left'></div>
            <div className='level-right'>
                <ButtonMutationWithConfirmation id='deleteInvoice'
                                                kind={KIND_DANGER}
                                                size={SIZE_FIXED}
                                                text='Supprimer facture'
                                                confirmationText='Etes vous sûr de vouloir supprimer cette facture ?'
                                                mutation={deleteInvoiceMutation}
                                                value={invoiceId} />
            </div>
        </div>
    );
}

export default InvoiceDeleteButtonEnabled;
