import {Axios} from "axios";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { useState } from "react";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import Input from "factor-lib/forms/Inputs/Input";
import { ResultOrErrors } from "../../../../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../../../../utils/ShowValidationErrorsButton";
import {MainCustomerNamespace} from "../../../customersQuery";
import { ICustomerDetailsQueryResult, customerDetailsQueryKey } from "../../customerDetailsQuery";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import {isValidEmail, validateEmailError} from 'factor-lib/forms/Inputs/emailUtils';
import {isValidPhone, validatePhoneError} from 'factor-lib/forms/Inputs/phoneUtils';

const ConfirmText = 'Confirmer';

interface IUpdateCustomerContactParams {
    // Null -> no update
    email: string | null;
    phone: string | null;
}

const UpdateCustomerContactEnabled = (
    {
        customerId,
        axios,
        updateParams,
        closeModal,
        kind,
        size,
        text
    } : {
        customerId: string;
        axios: Axios;
        updateParams: IUpdateCustomerContactParams;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateCustomerContactMutation: UseMutationResult<void, any, IUpdateCustomerContactParams, null> =
        useMutation<void, any, IUpdateCustomerContactParams, null>(
            async (updateParams2) => {
                await axios.put<void>(
                    `/adminCustomers/${customerId}/contact`,
                    updateParams2
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId),
                        (old: ICustomerDetailsQueryResult | undefined) => ({
                            customerDetails: {
                                ...old!.customerDetails,
                                contactEmail: updateParams.email ?? old!.customerDetails.contactEmail,
                                contactPhone: updateParams.phone ?? old!.customerDetails.contactPhone
                            }
                        })
                    );
                    queryClient.setQueryData(
                        [MainCustomerNamespace],
                        (old: unknown[] | undefined) => !!old
                            ? old!.map((customer: any) =>
                                customer.id === customerId ? {
                                    ...customer,
                                    contactEmail: updateParams.email ?? customer.contactEmail,
                                } : customer
                            ) : undefined
                    );
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateCustomerContactButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateCustomerContactMutation}
                               displayFullError={true}
                               value={updateParams} />
    );
}

const getUpdateCustomerContactParams = (
    oldContactEmail: string,
    oldContactPhone: string,

    newContactEmailInput: string,
    newContactPhoneInput: string
): ResultOrErrors<IUpdateCustomerContactParams> | null => {
    const validationErrors: string[] = [];

    if (!isValidEmail(newContactEmailInput)) {
        validationErrors.push(`L'email est invalide`);
    }

    if (!isValidPhone(newContactPhoneInput)) {
        validationErrors.push(`Le téléphone est invalide`);
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    const contactEmailUpdate: string | null = newContactEmailInput !== oldContactEmail ? newContactEmailInput : null;
    const contactPhoneUpdate: string | null = newContactPhoneInput !== oldContactPhone ? newContactPhoneInput : null;

    return (!!contactEmailUpdate || !!contactPhoneUpdate)
        ? ResultOrErrors.fromResult({
            email: contactEmailUpdate,
            phone: contactPhoneUpdate
        }) : null;
};

const UpdateCustomerContactModalContent = (
    {
        axios,
        customerId,
        contactEmail,
        contactPhone,
        closeModal
    }: {
        axios: Axios;
        customerId: string;
        contactEmail: string;
        contactPhone: string;
        closeModal: () => void;
    }
) => {
    const [newContactEmailInput, setNewContactEmailInput] = useState(contactEmail);
    const [newContactPhoneInput, setNewContactPhoneInput] = useState(contactPhone);

    const updateParams: ResultOrErrors<IUpdateCustomerContactParams> | null = getUpdateCustomerContactParams(
        contactEmail,
        contactPhone,

        newContactEmailInput,
        newContactPhoneInput
    );

    return (
        <div className='p-padding-3'>
            <div>
                <span className='p-margin-right-4 p-bold'>Email de contact</span>
                <Input inputValue={newContactEmailInput}
                       enabled={{
                           updateInputValue: setNewContactEmailInput,
                           validateInput: () => validateEmailError(newContactEmailInput),
                           innerId: {
                               value: 'contactEmailInner',
                               autofocus: true
                           }
                       }} />
            </div>

            <div className='p-margin-top-4'>
                <span className='p-margin-right-4 p-bold'>Téléphone de contact</span>
                <Input inputValue={newContactPhoneInput}
                       enabled={{
                           updateInputValue: setNewContactPhoneInput,
                           validateInput: () => validatePhoneError(newContactPhoneInput)
                       }} />
            </div>

            <div className='p-margin-top-4 p-vertical-center'>
                { !!updateParams
                    ? !!updateParams.result
                        ? <UpdateCustomerContactEnabled axios={axios}
                                                        customerId={customerId}
                                                        updateParams={updateParams.result}
                                                        closeModal={closeModal}
                                                        kind={KIND_PRIMARY}
                                                        size={SIZE_FIXED}
                                                        text={ConfirmText} />
                        : <ShowValidationErrorsButton validationErrors={updateParams.errors!}
                                                      kind={KIND_PRIMARY}
                                                      size={SIZE_FIXED}
                                                      text={ConfirmText} />
                    : <ButtonDisabled kind={KIND_PRIMARY}
                                      size={SIZE_FIXED}
                                      text={ConfirmText} />
                }
            </div>
        </div>
    );
}

const UpdateCustomerContact = (
    {
        className,
        axios,
        customerId,
        contactEmail,
        contactPhone
    }: {
        className?: string;
        axios: Axios;
        customerId: string;
        contactEmail: string;
        contactPhone: string;
    }
) =>
    <ButtonForModal className={className}
                    id='showUpdateCustomerContactModal'
                    text='Modifier Contact'
                    childModalContent={(closeModal) =>
                        <UpdateCustomerContactModalContent axios={axios}
                                                           customerId={customerId}
                                                           contactEmail={contactEmail}
                                                           contactPhone={contactPhone}
                                                           closeModal={closeModal } />
                    }/>

export default UpdateCustomerContact;
