import { Axios } from "axios";
import { ButtonKind, ButtonSize } from "../CustomerFinancingRequestButtonProps";
import { ICustomerFinancingRequest, NewFinancingRequestsQueryKeyPrefix } from "../../../../../utils/FinancingRequest/FinancingRequestsQuery";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { IInvoiceDetailsQueryResult, invoiceDetailsQueryKey } from "../../../invoiceDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { AcceptedFinancingRequestsQueryKey } from "../../../../Invoices/PendingPayments/PendingPaymentsTable";
import { ICustomerFinancingRequestsResponse } from "../../../../../utils/FinancingRequest/FinancingRequestsQuery";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";

const AcceptText = 'Validate Invoice Checks';

const acceptFinancingAction = async (
    axios: Axios,
    customerFinancingRequestId: string,
    skipEligibilityCheck: boolean
) => {
    await axios.post<null>(
        `/adminCustomerFinancingRequests/${customerFinancingRequestId}/accept`,
        {
            skipEligibilityCheck
        }
    );
};

const InvoiceAcceptCustomerFinancingRequest = (
    {
        axios,
        customerFinancingRequest
    }: {
        axios: Axios;
        customerFinancingRequest: ICustomerFinancingRequest;
    }
) => {
    const queryClient = useQueryClient();

    const acceptCustomerFinancingRequestMutationOnSuccess = () => {
        queryClient.setQueryData<IInvoiceDetailsQueryResult>(
            invoiceDetailsQueryKey(customerFinancingRequest.invoice.id),
            old => !!old ? {
                ...old,
                invoiceDetails: {
                    ...old.invoiceDetails,
                    status: {
                        ...old.invoiceDetails.status,
                        customerFinancingRequest: {
                            ...old.invoiceDetails.status.customerFinancingRequest!, // can't be null
                            accepted: {
                                creationDateTime: serverDateSerialization(new Date()),
                                financing: null,
                                autoApproved: false
                            }
                        }
                    }
                }
            } : undefined
        );

        // remove from new financing requests (all, seller, buyer)
        queryClient.setQueriesData<ICustomerFinancingRequestsResponse<null>>(
            NewFinancingRequestsQueryKeyPrefix,
            old => !!old ? {
                ...old,
                financingRequests: {
                    ...old.financingRequests,
                    base: old.financingRequests
                        .base.filter((f) => f.id !== customerFinancingRequest.id)
                }
            } : undefined
        );

        // add to pending payments
        // TODO: need to add seller but we are missing the "swanInstantPayment" field, just invalidate for now
        return queryClient.invalidateQueries(AcceptedFinancingRequestsQueryKey);
        // queryClient.setQueriesData<ICustomerFinancingRequestsResponse<IAccepted>>(
        //     AcceptedFinancingRequestsQueryKey,
        //     old => !!old ? {
        //         ...old,
        //         financingRequests: {
        //             ...old.financingRequests,
        //             base: [
        //                 ...old.financingRequests.base,
        //                 {
        //                     ...customerFinancingRequest,
        //                     accepted: {
        //                         creationDateTime: serverDateSerialization(new Date())
        //                     }
        //                 }
        //             ],
        //             sellers: [
        //                 ...old.financingRequests.sellers,
        //                 {
        //                     // missing swanInstantPayment
        //                 }
        //             ]
        //         }
        //     } : undefined
        // );
    }; // Avant de mettre a jour le statut, sinon on perd le composant

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates

    const acceptCustomerFinancingRequestMutationSkipEligiblityCheck: UseMutationResult<void, any, null> =
        useMutation<void, any, null>(
            () => acceptFinancingAction(
                axios,
                customerFinancingRequest.id,
                true
            ),
            ({
                onSuccess: acceptCustomerFinancingRequestMutationOnSuccess,
            })
        );

    const acceptCustomerFinancingRequestMutation: UseMutationResult<void, any, null> =
        useMutation<void, any, null>(
            () => acceptFinancingAction(
                axios,
                customerFinancingRequest.id,
                false
            ),
            ({
                onSuccess: acceptCustomerFinancingRequestMutationOnSuccess,

                onError: (e: any) => {
                    // C'est malgré tout une erreur : normalement on aurait du bloquer la demande.
                    if (e.response?.status === 400 && !!e.response.data && e.response.data.startsWith('La facture n\'est pas éligible :') && window.confirm(`${e.response.data}. Voulez-vous néanmoins forcer l'acceptation de la demande de financement ?`)) {
                        acceptCustomerFinancingRequestMutationSkipEligiblityCheck.mutate(null);
                    } else {
                        throw e;
                    }
                }
            })
        );

    return (
        <ButtonMutationEnabled id='acceptCustomerFinancingRequestButton'
                               kind={ButtonKind}
                               size={ButtonSize}
                               text={AcceptText}
                               mutation={acceptCustomerFinancingRequestMutation}
                               displayFullError={true}
                               value={null} />
    );
}

export default InvoiceAcceptCustomerFinancingRequest;
