import { GlobalOutstandingsQueryKey, IGlobalOutstandingsQueryResult } from "./globalOutstandingsQuery";
import IAuthUserLoad from "../../../IAuthUserLoad";
import GraphQLWithAccessTokenWrapper from "../../../GraphQLQueryWithAccessTokenWrapper";
import IAccessToken from "../../../IAccessToken";
import GlobalOutstandingsLoaded from "./GlobalOutstandingsLoaded";
import Loader from "factor-lib/Loader";

const GlobalOutstandings = (
    {
        accessTokenO
    } : {
        accessTokenO: IAccessToken | null;
    }
) =>
    <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                   queryKey={GlobalOutstandingsQueryKey}
                                   queryParams={{
                                       query: `query  {
                                           globalOutstandings {
                                               limit
                                               activeFinancings
                                               activeFinancingRequests
                                           }
                                       }`
                                   }}
                                   onLoading={() => <Loader />}
                                   onSuccess={(r: IAuthUserLoad<IGlobalOutstandingsQueryResult>) =>
                                       <GlobalOutstandingsLoaded axios={r.axios}
                                                                 authUserO={r.authUserO}
                                                                 globalOutstandings={r.value.globalOutstandings} />
                                   } />;

export default GlobalOutstandings;

