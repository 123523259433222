import { Link } from "react-router-dom";
import { IArchivedPreInvoiceEmail, IPendingPreInvoiceEmail, IPreInvoiceWithEmailRecipients } from "./preInvoicesQuery";
import { preInvoiceUrl } from "./preInvoicesUrls";
import { comparingDate, serverDateDeserialization } from "factor-lib/utils/dateUtils";
import { comparingRev } from "factor-lib/utils/comparingUtils";
import {formatAdminDateStr} from "../../utils/dateTimeUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";

const formatEmailTitle = (title: string): string =>
    title.length < 40 ? title : title.substring(0, 40) + '...';

const comparePreInvoiceDate = comparingRev(comparingDate(
    (p: IPreInvoiceWithStatus) => serverDateDeserialization(p.preInvoice.preInvoice.creationDateTime)
));

export interface IPreInvoiceWithStatus {
    preInvoice: IPreInvoiceWithEmailRecipients<IPendingPreInvoiceEmail | IArchivedPreInvoiceEmail>;
    statusComponent: JSX.Element;
}

const PreInvoicesTableLoaded = (
    {
        preInvoices
    }: {
        preInvoices: Array<IPreInvoiceWithStatus>;
    }
) =>
    <tbody>
        { preInvoices
            .sort(comparePreInvoiceDate)
            .map((p) => {
                const preInvoice = p.preInvoice.preInvoice;
                const preInvoiceEmail = preInvoice.email;
                const PreInvoiceStatusComponent = p.statusComponent;
                return (
                    <tr key={preInvoice.id}>
                        <td>{ formatAdminDateStr(preInvoiceEmail.sentDateTime) }</td>
                        <td>{ preInvoiceEmail.senderEmail }</td>
                        <td>{ p.preInvoice.preInvoiceEmailRecipients.join(', ') }</td>
                        <td>{ preInvoice.invoiceNumber }</td>
                        <td>
                            <div>{ (!!preInvoice.amountWoTax ? formatAmount(preInvoice.amountWoTax) : 'na') + ' HT' }</div>
                            <div>{ (!!preInvoice.amountTax ? formatAmount(preInvoice.amountTax) : 'na') + ' tax' }</div>
                        </td>
                        <td>{ preInvoiceEmail.thread.length }</td>
                        <td>{ formatEmailTitle(preInvoiceEmail.title) }</td>
                        <td>
                            <a href={preInvoiceEmail.thread.link} target="_blank" rel="noreferrer">link</a>
                        </td>
                        { PreInvoiceStatusComponent }
                        <td><Link to={preInvoiceUrl(preInvoice.id)}>View</Link></td>
                    </tr>
                )
            })
        }
    </tbody>;

export default PreInvoicesTableLoaded;
