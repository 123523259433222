import { Axios } from "axios";
import { QueryClient } from "@tanstack/react-query";
import {
    IAccepted,
    ICustomerFinancingRequest,
    ICustomerFinancingRequestsResponse
} from "../FinancingRequest/FinancingRequestsQuery";
import { IInvoiceDetailsQueryResult, invoiceDetailsQueryKey } from "../../Pages/Invoice/invoiceDetailsQuery";
import { AcceptedFinancingRequestsQueryKey } from "../../Pages/Invoices/PendingPayments/PendingPaymentsTable";
import { swanRedirect } from "../swanUtils";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import { GlobalOutstandingsQueryKey } from "../../Pages/Misc/GlobalOutstandings/globalOutstandingsQuery";
import { IBaseInvoiceSellerWithCompany, IInvoiceBuyerCompany } from "../IInvoice";
import { ActiveFinancingsQueryKeyPrefix } from "./financingsQuery";

export const FinancingPaymentsEndpoint = '/adminFinancingPayments';

export interface INewFinancingResponse {
    financingId: string;
    amount: number;
    reminderEmailsPaused: boolean;
}

const updateInvoiceDetailsSetFinancing = (
    newFinancing: INewFinancingResponse,
    old? : IInvoiceDetailsQueryResult,
): IInvoiceDetailsQueryResult | undefined =>
    !!old ? ({
        ...old,
        invoiceDetails: {
            ...old.invoiceDetails,
            status: {
                ...old.invoiceDetails.status,
                customerFinancingRequest: {
                    ...old.invoiceDetails.status.customerFinancingRequest!,
                    accepted: {
                        ...old.invoiceDetails.status.customerFinancingRequest!.accepted!,
                        financing: {
                            id: newFinancing.financingId,
                            settled: null,
                            amount: newFinancing.amount,
                            creationDateTime: serverDateSerialization(new Date()),
                            reminderEmailsPaused: newFinancing.reminderEmailsPaused,
                            commingLingAmountToReturn: 0,
                            merciFacteurMails: []
                        }
                    }
                }
            }
        }
    }) : undefined;

export const financeManualAction = async (
    axios: Axios,
    customerFinancingRequests: ReadonlyArray<ICustomerFinancingRequest>
): Promise<ReadonlyArray<INewFinancingResponse>> =>
    (await axios.put<ReadonlyArray<INewFinancingResponse>>(
        `${FinancingPaymentsEndpoint}/finance-manual`,
        {
            customerFinancingRequestIds: customerFinancingRequests.map((c) => c.id)
        }
    )).data;

export const postFinanceUpdateQueries = (
    queryClient: QueryClient,
    customerFinancingRequests: ReadonlyArray<ICustomerFinancingRequest>,
    sellers: IBaseInvoiceSellerWithCompany[],
    buyerCompanies: IInvoiceBuyerCompany[],
    newFinancings: ReadonlyArray<INewFinancingResponse>
) => {

    const selectedCustomerFinancingRequestsIds = customerFinancingRequests.map((c) => c.id);
    const customerFinancingRequestIdsSet = new Set<string>(selectedCustomerFinancingRequestsIds)

    queryClient.setQueriesData<ICustomerFinancingRequestsResponse<IAccepted>>(
        AcceptedFinancingRequestsQueryKey,
        (old) => !!old ? ({
            financingRequests: {
                ...old.financingRequests,
                base: old.financingRequests.base
                    .filter((f) => !customerFinancingRequestIdsSet.has(f.id))
            }
        }) : undefined
    );

    queryClient.setQueriesData<any /* We have different cases */>(
        ActiveFinancingsQueryKeyPrefix,
        (old?: any) => !!old
            ? updateFinancings(
                old,
                customerFinancingRequests,
                sellers,
                buyerCompanies
            )
            : undefined
    );

    const indexById = new Map<string, number>(
        selectedCustomerFinancingRequestsIds.map((s, index) => [s, index])
    );

    customerFinancingRequests.map((c) =>
        queryClient.setQueryData<IInvoiceDetailsQueryResult>(
            invoiceDetailsQueryKey(c.invoice.id),
            (old?: IInvoiceDetailsQueryResult) =>
                updateInvoiceDetailsSetFinancing(newFinancings[indexById.get(c.id)!], old)
        )
    );

    return queryClient.invalidateQueries(GlobalOutstandingsQueryKey);
}

const updateFinancings = (
    old: any,
    customerFinancingRequests: ReadonlyArray<ICustomerFinancingRequest>,
    sellers: IBaseInvoiceSellerWithCompany[],
    buyerCompanies: IInvoiceBuyerCompany[]
) => {
    let r: any = ({
        financings: {
            base: [
                ...old.financings.base,
                ...customerFinancingRequests
                    .map((c) => ({
                        creationDateTime: serverDateSerialization(new Date()),
                        invoice: c.invoice,
                        settled: null
                    }))
            ],
            // we could merge, but does not hurt
            buyerCompanies: [
                ...old.financings.buyerCompanies,
                ...buyerCompanies
            ]
        }
    });

    if (!!old.financings.sellers) {
        r = {
            ...r,
            // we could merge, but does not hurt
            sellers: [
                ...old.financings.sellers,
                ...sellers
            ],
        }
    }
    return r;
}

export const financeSwanAction = (
    baseUrl: string,
    clientId: string,
    queryClient: QueryClient,
    customerFinancingRequests: ReadonlyArray<ICustomerFinancingRequest>,
    sellers: IBaseInvoiceSellerWithCompany[],
    buyerCompanies: IInvoiceBuyerCompany[]
) =>
    swanRedirect(
        baseUrl,
        clientId,
        (axios, swanAuthorizationCode) => axios.post<string>(
            `${FinancingPaymentsEndpoint}/init-finance-swan`,
            {
                customerFinancingRequestIds: customerFinancingRequests.map((f) => f.id),
                swanAuthorizationCode: swanAuthorizationCode
            }
        ),
        (axios, consentId) => axios.put<INewFinancingResponse[]>(
            `${FinancingPaymentsEndpoint}/${consentId}/validate-finance-swan`
        ),
        (newFinancings) => postFinanceUpdateQueries(
            queryClient,
            customerFinancingRequests,
            sellers,
            buyerCompanies,
            newFinancings
        ),
        (axios, consentId) => axios.put<void>(
            `${FinancingPaymentsEndpoint}/${consentId}/reject-finance-swan`
        )
    );
