import { SIZE_FIXED } from "factor-lib/Buttons/Button";
import { KIND_PRIMARY } from "factor-lib/Buttons/Button";
import { useContext, useState} from "react";
import { getInitialNewAddressInput, IAddressInput } from "../../utils/AddressInput";
import { ResultOrErrors} from "../../utils/ResultOrErrors";
import { Axios} from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import Loader from "factor-lib/Loader";
import { IntCompanyIdentifierTypeSiren } from "factor-lib/Company/IIntCompanyIdentifier";
import { existingCompaniesQueryKey, IExistingCompaniesQueryResult } from "../../utils/Company/ExistingCompanySelector";
import IAccessToken from "../../IAccessToken";
import CompanyInput, { getCompanyParams, ICompanyParams } from "./CompanyInput";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { NavigateFunction } from "react-router-dom";
import { companyUrl } from "./companiesUrls";
import ValidationErrorModal from "../../utils/ValidationErrorModal";
import Button from "factor-lib/Buttons/Button";
import Modal from "factor-lib/Modal";

const AddButtonText = 'Ajouter';
const AddButtonKind = KIND_PRIMARY;
const AddButtonSize = SIZE_FIXED;

interface IAddCompanyResponse {
    id: string;
}

const AddCompanyEnabled = (
    {
        className,
        axios,
        params,
        reset
    } : {
        className?: string;
        axios: Axios;
        params: ResultOrErrors<ICompanyParams>;
        reset: () => void;
    }
) => {
    const queryClient = useQueryClient();

    const [addResponse, setAddResponse] = useState<IAddCompanyResponse | null>(null);

    const addCompanyMutation: UseMutationResult<IAddCompanyResponse, any, ICompanyParams> =
        useMutation<IAddCompanyResponse, any, ICompanyParams>(
            async (params2) => 
                (await axios.post<IAddCompanyResponse>(
                    `/adminCompanies`,
                    params2
                )).data,
            ({
                onSuccess: (response, params2) => {
                    queryClient.setQueryData<IExistingCompaniesQueryResult>(
                        existingCompaniesQueryKey,
                        (old: IExistingCompaniesQueryResult | undefined) => !!old ? {
                            ...old,
                            companies: [
                                ...old.companies,
                                {
                                    id: response.id,
                                    name: params2.name,
                                    identifier: params2.identifier
                                }
                            ]
                        } : undefined
                    );

                    setAddResponse(response);
                }
            })
        );

    const [showValidationErrorsModal, setShowValidationErrorsModal] = useState<string[] | null>(null);

    return (
        <div className={className}>
            <Button id='addCompanyButton'
                    kind={AddButtonKind}
                    size={AddButtonSize}
                    text={AddButtonText}
                    isLoading={addCompanyMutation.isLoading}
                    actionO={() => {
                        if (!!params.result) {
                            addCompanyMutation.mutate(params.result);
                        } else {
                            setShowValidationErrorsModal(params.errors!);
                        }
                    }} />

            { showValidationErrorsModal &&
                <ValidationErrorModal validationErrors={showValidationErrorsModal}
                                      close={() => setShowValidationErrorsModal(null)}/>
            }

            { !!addResponse &&
                <Modal id='addCompanyResponseModal'
                       maxWidth={null}
                       fullMaxWidth={false}
                       close={() => {
                           setAddResponse(null);
                           reset();
                       }}>
                    <AddCompanyResultModalContent companyId={addResponse.id}
                                                  reset={() => {
                                                      setAddResponse(null);
                                                      reset();
                                                  }} />
                </Modal>
            }
        </div>
    );
}

const AddCompanyResultModalContent = (
    {
        companyId,
        reset
    }: {
        companyId: string;
        reset: () => void;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    return (
        <div className='p-padding-4 p-vertical-center'>
            <span>La société a bien été ajoutée</span>

            <div className='columns p-margin-top-5'>
                <div className='column'>
                    <Button id='gotoAddedCompanyDetailsButton'
                            text='Détails de la société'
                            isLoading={false}
                            actionO={() => navigate(companyUrl(companyId))} />

                </div>
                <div className='column'>
                    <Button id='addAnotherCompanyButton'
                            text='Ajouter une autre société'
                            isLoading={false}
                            actionO={reset} />
                </div>
            </div>
        </div>
    );
}

const AddCompany = (
    {
        accessTokenO
    }: {
        accessTokenO: IAccessToken | null;
    }
)  => {
    const [identifierType, setIdentifierType] = useState<string | null>(IntCompanyIdentifierTypeSiren);
    const [identifierValueInput, setIdentifierValueInput] = useState('');
    const [nameInput, setNameInput] = useState('');
    const [addressInput, setAddressInput] = useState<IAddressInput>(getInitialNewAddressInput());

    const reset = () => {
        setIdentifierType(IntCompanyIdentifierTypeSiren);
        setIdentifierValueInput('')
        setNameInput('');
        setAddressInput(getInitialNewAddressInput());
    };

    return (
        <div className='container p-both-center p-margin-top-3'>
            <div style={{width: 600}}>
                <div className='title p-both-center'>
                    Ajouter une société
                </div>

                <div className='box'>
                    <CompanyInput identifierType={identifierType}
                                  setIdentifierType={setIdentifierType}
                                  identifierValueInput={identifierValueInput}
                                  setIdentifierValueInput={setIdentifierValueInput}
                                  nameInput={nameInput}
                                  setNameInput={setNameInput}
                                  addressInput={addressInput}
                                  setAddressInput={setAddressInput}
                                  autofocus={true} />
                </div>

                <div className='p-margin-3 p-both-center'>
                    { !!accessTokenO
                        ? <AddCompanyEnabled axios={accessTokenO.axios}
                                             params={getCompanyParams(
                                                 identifierType,
                                                 identifierValueInput,
                                                 nameInput,
                                                 addressInput
                                             )}
                                             reset={reset}/>
                        : <Loader />
                    }
                </div>
            </div>
        </div>
    );
}

export default AddCompany;