import IAuthUser from "../../../../../IAuthUser";
import { Axios } from "axios";
import { customerDetailsQueryKey, ICustomerDetailsQueryResult } from "../../customerDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { formatAdminDateStr } from "../../../../../utils/dateTimeUtils";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";


const UpdateText = 'Require new pricing';

const RequireNewPricingEnabled = (
    {
        axios,
        customerId,
        kind,
        size
    }: {
        axios: Axios;
        customerId: string;
        kind: string;
        size: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const requireNewPricingMutation: UseMutationResult<void, any, any, void> =
        useMutation<void, any, any, void>(
            async () => {
                await axios.put<void>(
                    `/adminCustomers/${customerId}/requireNewPricing`,
                    {}
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId),
                        (old) => ({
                            customerDetails: {
                                ...old!.customerDetails,
                                newPricingRequiredDate: serverDateSerialization(new Date())
                            }
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='requireNewPricingId'
                               kind={kind}
                               size={size}
                               text={UpdateText}
                               mutation={requireNewPricingMutation}
                               displayFullError={true}
                               value={null} />
    );
}

const CustomerNewPricing = (
    {
        className,
        axios,
        authUser,
        customerId,
        newPricingRequiredDate,
        newPricingAcceptedDate
    }: {
        className?: string;
        axios: Axios;
        authUser: IAuthUser | null;
        customerId: string;
        newPricingRequiredDate: string | null;
        newPricingAcceptedDate: string | null;
    }
) =>
    <div className={className}>
        { !!newPricingRequiredDate
            ? <div>
                <div>New pricing required on {formatAdminDateStr(newPricingRequiredDate)}</div>
                { !!newPricingAcceptedDate &&
                    <div> New pricing accepted on {formatAdminDateStr(newPricingAcceptedDate)}</div>
                }
            </div>
            : authUser?.canManageProfiles
                ? <RequireNewPricingEnabled kind={KIND_PRIMARY}
                                            size={SIZE_FIXED}
                                            axios={axios}
                                            customerId={customerId} />
                : <div>New pricing not required</div>
        }
    </div>;

export default CustomerNewPricing;
