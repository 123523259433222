import { Axios } from "axios";
import { formatIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import { ICompanyDetails } from "./companyDetailsQuery";
import { formatAddress } from "../../../utils/IAddress";
import IAuthUser from "../../../IAuthUser";
import DimplScoreInfos from "../../../utils/DimplScore/DimplScoreInfos";
import CompanyMainInfosUpdate from "./CompanyMainInfosUpdate";

const CompanyMainInfosSection = (
    {
        axios,
        authUserO,
        companyId,
        companyDetails
    }: {
        axios: Axios;
        authUserO: IAuthUser | null;
        companyId: string;
        companyDetails: ICompanyDetails;
    }
) =>
    <div>
        <table className='table is-narrow'>
            <tbody>
                <tr>
                    <td className='p-bold'>Nom</td>
                    <td style={{textTransform: 'uppercase'}}>{ companyDetails.name }</td>
                </tr>
                <tr>
                    <td className='p-bold'>Identifiant interne</td>
                    <td>{ companyId }</td>
                </tr>
                <tr>
                    <td className='p-bold'>Identifiant</td>
                    <td>{ formatIntCompanyIdentifier(companyDetails.identifier) }</td>
                </tr>
                <tr>
                    <td className='p-bold'>Adresse</td>
                    <td>{ formatAddress(companyDetails.address) }</td>
                </tr>
            </tbody>
        </table>

        <DimplScoreInfos loadedClassName='p-margin-top-5'
                         axios={axios}
                         companyId={companyId} />

        { !!authUserO?.canManageProfiles &&
            <CompanyMainInfosUpdate className='p-margin-top-4'
                                    axios={axios}
                                    companyId={companyId}
                                    companyDetails={companyDetails} />
        }
    </div>;

export default CompanyMainInfosSection;
