import {ReactElement, ReactNode} from "react";
import {Axios} from "axios";
import graphQLQueryAsync, { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import ReactQueryWrapper from "./ReactQueryWrapper";
import {getFactorContext} from "../IFactorContext";
import {UseQueryOptions} from "@tanstack/react-query/src/types";

const GraphQLQueryWrapper = <T, >(
    {
        axios,
        queryKey,
        queryParams,
        onLoading,
        onError,
        onSuccess,
        options
    }: {
        axios: Axios;
        queryKey: string[];
        queryParams: IGraphQLParams;
        onLoading?: () => ReactElement;
        onError?: (e: string) => ReactElement; // TODO: make required?
        onSuccess: (t: T) => ReactNode;
        options?: Omit<
            UseQueryOptions<T>,
            'queryKey' | 'queryFn' | 'initialData'
        > & { initialData?: () => undefined }
    }
) =>
    <ReactQueryWrapper queryKey={queryKey}
                       queryFunc={(signal?: AbortSignal) => graphQLQueryAsync<T>(
                           axios,
                           queryParams,
                           getFactorContext().logger,
                           queryKey,
                           signal
                       )}
                       onLoading={onLoading}
                       onError={onError}
                       onSuccess={onSuccess}
                       options={options}/>;

export default GraphQLQueryWrapper;
