import IAuthUser from "../../../../../../IAuthUser";
import { ICustomer, ISellerDetailsQueryResult, sellerDetailsQueryKey } from "../../../sellerDetailsQuery";
import { KIND_PRIMARY, SIZE_COMPACT } from "factor-lib/Buttons/Button";
import {Axios} from "axios";
import { UrlGraphQLField } from "factor-lib/forms/Upload/IUrlOnly";
import { FileUploadTimeoutMilliseconds } from "factor-lib/serverUtils/axiosConfigUtils";
import { PDF_OR_JPEG_OR_PNG_SET } from "factor-lib/forms/Upload/uploadUtils";
import SingleGraphQLFileVisualizerWithUpload from "../../../../../../utils/SingleGraphQLFileVisualizerWithUpload";

const uploadBankStatementFileAsync = async (
    axios: Axios,
    bankStatementNumber: number,
    customerId: string,
    file: File,
    onUploadProgress: (progressEvent: ProgressEvent) => void,
): Promise<void> => {
    const data = new FormData();
    data.append('bankStatementFile', file);

    return await
        axios.put<void>(
            `adminCustomers/${customerId}/bs${bankStatementNumber}`,
            data,
            {
                onUploadProgress,
                timeout: FileUploadTimeoutMilliseconds
            }
        )
        .then(() =>
            window.alert('bank statement file successfully uploaded')
        );
};

const BankStatement = (
    {
        className,
        axios,
        authUser,
        customerId,
        sellerId,
        bankStatementNumber,
        existingBankStatementFileName,
        customerUpdater

    }: {
        className?: string;
        axios: Axios;
        authUser: IAuthUser | null;
        customerId: string;
        sellerId: string;
        bankStatementNumber: number;
        existingBankStatementFileName: string | null;
        customerUpdater: (oldCustomer: ICustomer, newFileName: string) => ICustomer;
    }
) =>
    <SingleGraphQLFileVisualizerWithUpload className={className}
                                           axios={axios}
                                           title={`Bank Statement ${bankStatementNumber}`}
                                           fetchId={`fetchBankStatementFile-${bankStatementNumber}`}
                                           fetchText='Fetch'
                                           queryKey={[`bank-statement-file-${bankStatementNumber}`]}
                                           queryParams={{
                                               query: `query Q($customerId: Guid!) {
                                                   customerDetails (id: $customerId) {
                                                   bankStatement${bankStatementNumber} { ${UrlGraphQLField} }
                                                   }
                                               }`,
                                               variables: {
                                                   customerId
                                               }
                                           }}
                                           visualizerId={`viewBankStatementFile-${bankStatementNumber}`}
                                           visualizerText='View'
                                           buttonKind={KIND_PRIMARY}
                                           buttonSize={SIZE_COMPACT}
                                           existingFileName={existingBankStatementFileName}
                                           urlExtractor={(d: any) => d.customerDetails[`bankStatement${bankStatementNumber}`].url}
                                           upload={!!authUser?.canManageFinancings ? {
                                               id: `uploadBankStatementFile-${bankStatementNumber}`,
                                               acceptedFileTypesSet: PDF_OR_JPEG_OR_PNG_SET,
                                               uploadFileAsync: (file: File, onUploadProgress: (progressEvent: ProgressEvent) => void) =>
                                                   uploadBankStatementFileAsync(axios, bankStatementNumber, customerId, file, onUploadProgress),
                                               updateQueries: (queryClient, file) =>
                                                   queryClient.setQueryData<ISellerDetailsQueryResult>(
                                                       sellerDetailsQueryKey(sellerId),
                                                       (old?: ISellerDetailsQueryResult) => ({
                                                           sellerDetails: {
                                                               ...old!.sellerDetails,
                                                               customer: customerUpdater(old!.sellerDetails.customer, file.name)
                                                           }
                                                       })
                                                   )
                                           } : null} />;

export default BankStatement;
