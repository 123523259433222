import { Axios } from "axios";
import { useState } from "react";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { ISwanIncomingPaymentsQueryResult, SwanIncomingPaymentsQueryKey } from "../SwanIncomingPayments/swanIncomingPaymentsQuery";
import { ISwanIncomingPaymentDetailsQueryResult, swanIncomingPaymentDetailsQueryKey } from "./swanIncomingPaymentDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import Input from "factor-lib/forms/Inputs/Input";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { validateMandatoryFieldError } from "factor-lib/forms/Inputs/utils";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";

const ArchiveSwanIncomingPaymentButtonEnabled = (
    {
        axios,
        swanIncomingPaymentId,
        comment,
        closeModal,
        kind,
        size,
        text
    } : {
        axios: Axios;
        swanIncomingPaymentId: string;
        comment: string;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const archiveSwanIncomingPaymentMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (comment2) => {
                await axios.post<void>(
                    `/adminSwanIncomingPayments/${swanIncomingPaymentId}/archive`,
                    {
                        comment: comment2
                    }
                );
            },
            ({
                onSuccess: (_, comment2) => {
                    const archivedDateTimeStr = serverDateSerialization(new Date());

                    queryClient.setQueryData<ISwanIncomingPaymentDetailsQueryResult>(
                        swanIncomingPaymentDetailsQueryKey(swanIncomingPaymentId),
                        (old: ISwanIncomingPaymentDetailsQueryResult | undefined) => !!old ? ({
                            ...old,
                            swanIncomingPaymentDetails: {
                                ...old!.swanIncomingPaymentDetails,
                                archivedDateTime: archivedDateTimeStr,
                                archivedComment: comment2
                            }
                        }) : undefined
                    );

                    queryClient.setQueryData<ISwanIncomingPaymentsQueryResult>(
                        SwanIncomingPaymentsQueryKey,
                        (old: ISwanIncomingPaymentsQueryResult | undefined) => !!old ? {
                            ...old,
                            swanIncomingPayments: [
                                ...old!.swanIncomingPayments.filter((s) => s.id !== swanIncomingPaymentId),
                                ...old!.swanIncomingPayments.filter((s) => s.id === swanIncomingPaymentId).map((s) => ({
                                    ...s,
                                    archivedDateTime: archivedDateTimeStr,
                                    archivedComment: comment2
                                }))
                            ]
                        } : undefined
                    );

                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='archiveSwanIncomingPaymentButtonEnabled'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={archiveSwanIncomingPaymentMutation}
                               value={comment}
                               displayFullError={true} />
    );
}

const ArchiveSwanIncomingPaymentModalContent = (
    {
        axios,
        swanIncomingPaymentId,
        closeModal
    }: {
        axios: Axios;
        swanIncomingPaymentId: string;
        closeModal: () => void;
    }
) => {
    const [commentInput, setCommentInput] = useState('');

    return (
        <div className='p-padding-4 p-vertical-center'>
            <div className='p-vertical-center p-size-6 p-padding-4'>
                <span className='p-bold'>Archiver le paiement Swan</span>
                { swanIncomingPaymentId }
            </div>
            
            <Input inputValue={commentInput}
                   placeHolder='Commentaire'
                   enabled={{
                       updateInputValue: setCommentInput,
                       validateInput: () => validateMandatoryFieldError(commentInput),
                       innerId: {
                           value: 'commentInput',
                           autofocus: true
                       }
                   }} />

            <div className='p-margin-top-4'>
                <ArchiveSwanIncomingPaymentButtonEnabled axios={axios}
                                                         swanIncomingPaymentId={swanIncomingPaymentId}
                                                         comment={commentInput}
                                                         closeModal={closeModal}
                                                         text='Archiver'
                                                         kind={KIND_PRIMARY}
                                                         size={SIZE_FIXED} />
            </div>
        </div>
    );
}

const ArchiveSwanIncomingPaymentButton = (
    {
        className,
        axios,
        swanIncomingPaymentId,
        kind,
        size
    }: {
        className?: string;
        axios: Axios;
        swanIncomingPaymentId: string;
        kind: string;
        size: string;
    }
) =>
    <ButtonForModal className={className}
                    id='showArchiveSwanIncomingPaymentModal'
                    text='Archiver'
                    kind={kind}
                    size={size}
                    childModalContent={(closeModal) =>
                        <ArchiveSwanIncomingPaymentModalContent axios={axios}
                                                                swanIncomingPaymentId={swanIncomingPaymentId}
                                                                closeModal={closeModal} />
                    } />;

export default ArchiveSwanIncomingPaymentButton;
