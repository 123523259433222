import IDimplScore, {DimplScoreGraphQLQueryFields} from "./DimplScore";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";

export const dimplScoreQueryKey = (companyId: string) =>
    ['company', companyId, 'dimpl-score'];

export type DimplScoreO = IDimplScore | null;

export interface IDimplScoreQueryResult {
    dimplScore: DimplScoreO;
}

const dimplScoreSingleCompanyQuery = (alias: string, graphQLCompanyVariableName: string, companyId: string): IGraphQLParams =>
    ({
        query: `
            query ($${graphQLCompanyVariableName}: Guid!) {
                ${alias} : companyDetails (id: $${graphQLCompanyVariableName}) {
                    ${DimplScoreGraphQLQueryFields}
                }
            }
        `,
        variables: {
            [graphQLCompanyVariableName] : companyId
        }
    });

export default dimplScoreSingleCompanyQuery;