import { SIZE_COMPACT, KIND_PRIMARY } from "factor-lib/Buttons/Button";
import AddCustomerUser from "./AddCustomerUser";
import RemoveCustomerUser from "./RemoveCustomerUserEnabled";
import { Axios } from "axios";
import IAuthUser from "../../../../../IAuthUser";
import { ICustomerUser } from "../../customerDetailsQuery";

const CustomerUsersList = (
    {
        axios,
        authUser,
        customerId,
        users
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        customerId: string;
        users: ReadonlyArray<ICustomerUser>;
    }
) =>
    <table className='table is-narrow'>
        <thead>
            <tr>
                <th style={{verticalAlign: 'middle'}}>
                    Azure Object ID
                </th>

                { !!authUser?.canManageProfiles &&
                    <th>
                        <AddCustomerUser axios={axios}
                                         customerId={customerId}
                                         users={users}
                                         kind={KIND_PRIMARY}
                                         size={SIZE_COMPACT} />
                    </th>
                }
            </tr>
        </thead>
        <tbody>
            { users.map((customerUser, i) =>
                <tr key={i}>
                    <td className='p-padding-6'>
                        { customerUser.azureObjectId }
                    </td>

                    { !!authUser?.canManageProfiles &&
                        <td className='p-both-center p-padding-6'>
                            <RemoveCustomerUser axios={axios}
                                                customerId={customerId}
                                                customerUser={customerUser} />
                        </td>
                    }
                </tr>
                
            ) }
        </tbody>
    </table>;

export default CustomerUsersList;
