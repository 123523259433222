import {Axios} from "axios";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {
    customerDetailsQueryKey,
    ICustomerDetailsQueryResult
} from "../../Pages/Customers/CustomerInfos/customerDetailsQuery";
import IAuthUser from "../../IAuthUser";
import FButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import InstantPayments from "./InstantPayments";
import {
    ISellerDetailsQueryResult,
    sellerDetailsQueryKey
} from "../../Pages/Sellers/SellerInfos/sellerDetailsQuery";
import { SIZE_COMPACT, KIND_PRIMARY } from "factor-lib/Buttons/Button";

interface ICreateVirtualIbanResponse {
    virtualIban: string;
}

const CreateVirtualIbanEnabled = (
    {
        axios,
        customerId,
        directSellerSellerId,
        kind,
        size,
        text
    }: {
        axios: Axios;
        customerId: string;
        directSellerSellerId: string | null;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    const createVirtualIbanInSwanMutation: UseMutationResult<ICreateVirtualIbanResponse, any, string> =
        useMutation<ICreateVirtualIbanResponse, any, string>(
            async (customerId2: string) => (
                await axios.put<ICreateVirtualIbanResponse>(
                    `/adminCustomers/${customerId2}/virtualIban`
                ))
                .data,
            ({
                onSuccess: (response) => {
                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId), (old?: ICustomerDetailsQueryResult) => !!old ? ({
                            customerDetails: {
                                ...old.customerDetails,
                                dimplWallet: {
                                    virtualIban: response.virtualIban
                                }
                            }
                        }) : undefined
                    );

                    if (!!directSellerSellerId) {
                        queryClient.setQueryData<ISellerDetailsQueryResult>(
                            sellerDetailsQueryKey(directSellerSellerId), (old?: ISellerDetailsQueryResult) => !!old ? ({
                                sellerDetails: {
                                    ...old.sellerDetails,
                                    customer: {
                                        ...old.sellerDetails.customer,
                                        dimplWallet: {
                                            virtualIban: response.virtualIban
                                        }
                                    }
                                }
                            }) : undefined
                        );
                    }
                }
            })
        );

    return (
        <FButtonMutationEnabled id='createVirtualIban'
                                kind={kind}
                                size={size}
                                text={text}
                                mutation={createVirtualIbanInSwanMutation}
                                value={customerId}
                                displayFullError={true}/>
    )
};

export interface IUpdateSwanInstantPaymentMutationParams {
    newInstantPayment: boolean;
    value: string;
}

const CustomerSwanSection = (
    {
        axios,
        authUserO,
        customerId,
        directSellerSellerId,
        virtualIban,
        instantPayment,
        updateSwanInstantPaymentMutation,
        updateSwanInstantPaymentMutationValue
    } : {
        axios: Axios;
        authUserO: IAuthUser | null;
        customerId: string;
        directSellerSellerId: string | null;
        virtualIban: string | null;
        instantPayment: boolean;
        updateSwanInstantPaymentMutation: UseMutationResult<void, any, IUpdateSwanInstantPaymentMutationParams>;
        updateSwanInstantPaymentMutationValue: string;
    }
) =>
    <table className='table is-narrow'>
        <tbody>
            <tr>
                <td style={{verticalAlign: 'middle'}}>Iban virtuel</td>
                <td style={{verticalAlign: 'middle'}}>{ virtualIban ?? '<<none>>' }</td>
                { !!authUserO?.canManageProfiles && !virtualIban &&
                    <td>
                        <CreateVirtualIbanEnabled axios={axios}
                                                  customerId={customerId}
                                                  directSellerSellerId={directSellerSellerId}
                                                  kind={KIND_PRIMARY}
                                                  size={SIZE_COMPACT}
                                                  text='Créer' />
                    </td>
                }
            </tr>
            <InstantPayments authUserO={authUserO}
                             instantPayment={instantPayment}
                             updateSwanInstantPaymentMutation={updateSwanInstantPaymentMutation}
                             updateSwanInstantPaymentMutationValue={updateSwanInstantPaymentMutationValue}/>
        </tbody>
    </table>;

export default CustomerSwanSection;