import BuyerContactsInput, { ILineContactInput } from "factor-lib/AddInvoice/BuyerContactsInput";
import BuyerSelection, { IBuyerCompany } from "factor-lib/AddInvoice/BuyerSelection";
import {
    buildCompanySelectionGraphQlQueryParams,
    companyMatchesSelectionP
} from "factor-lib/Company/ICompanySelection";
import { formatCompanyInput } from "factor-lib/Company/companyUtils";
import ISearchCompaniesByNameResponse from "factor-lib/CompanySearcher/ISearchCompaniesByNameResponse";
import Input from "factor-lib/forms/Inputs/Input";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import GraphQLQueryWrapper from "../../../utils/GraphQLQueryWrapper";
import { Axios } from "axios";
import {useState} from "react";
import IOption from "factor-lib/forms/Select/IOption";
import IBuyerCompanySelection from "factor-lib/AddInvoice/IBuyerCompanySelection";


export interface IInvoiceBuyerInfosInput {
    companySelection: IBuyerCompanySelection | null;
    contacts: ILineContactInput[];
}

export const buyerContactsForAddInvoiceQueryKey = (buyerSelection: IBuyerCompanySelection) =>
    ['company', 'buyer-contacts-for-add-invoice', (buyerSelection.existingIdO ?? buyerSelection.newSirenO)!];

export interface IBuyerContactsForAddInvoiceQueryResult {
    buyerEmails: Array<{
        value: string;
    }>;
    buyerPhones: Array<{
        value: string;
    }>;
}

const SellerAddInvoiceBuyerContact = (
    {
        className,
        axios,
        buyerSelection,
        buyers,
        buyerContactsInputs,
        setBuyerContactsInputs
    }: {
        className?: string;
        axios: Axios;
        buyerSelection: IBuyerCompanySelection | null;
        buyers: ReadonlyArray<IBuyerCompany> | null;
        buyerContactsInputs: ILineContactInput[];
        setBuyerContactsInputs: (newValue: ILineContactInput[]) => void;
    }
) => {
    const buyerSelectionCompany = !!buyerSelection ? ({
        id: buyerSelection.existingIdO,
        siren: buyerSelection.newSirenO
    }) : null;
    return (
        !!buyerSelection && (!buyers || buyers.some(companyMatchesSelectionP(buyerSelectionCompany!)))
            ? <GraphQLQueryWrapper queryKey={buyerContactsForAddInvoiceQueryKey(buyerSelection)}
                                   axios={axios}
                                   queryParams={buildCompanySelectionGraphQlQueryParams(buyerSelectionCompany!, (paramName, paramType) =>
                                       `
                                       query Q($${paramName}: ${paramType}) {
                                           buyerEmails(${paramName}: $${paramName}) { value }
                                           buyerPhones(${paramName}: $${paramName}) { value }
                                       }
                                   `
                                   )}
                                   onLoading={() =>
                                       <BuyerContactsInput className={className}
                                                           buyerContactsInputs={buyerContactsInputs}
                                                           setBuyerContactsInputs={setBuyerContactsInputs}
                                                           extraBuyerEmailErrorO={null /* TODO ? */}
                                                           autoCompleteSuggestionsO={null} />
                                   }
                                   onSuccess={(d: IBuyerContactsForAddInvoiceQueryResult) =>
                                       <BuyerContactsInput className={className}
                                                           buyerContactsInputs={buyerContactsInputs}
                                                           setBuyerContactsInputs={setBuyerContactsInputs}
                                                           extraBuyerEmailErrorO={null /* TODO ? */}
                                                           autoCompleteSuggestionsO={{
                                                               // Remove duplicates, not sure whether this is still needed
                                                               buyerEmails: Array.from(new Set<string>(d.buyerEmails.map((b) => b.value))),
                                                               buyerPhones: Array.from(new Set<string>(d.buyerPhones.map((b) => b.value)))
                                                           }} />
                                   } />
            : <BuyerContactsInput className={className}
                                  buyerContactsInputs={buyerContactsInputs}
                                  extraBuyerEmailErrorO={null /* TODO ? */}
                                  setBuyerContactsInputs={setBuyerContactsInputs}
                                  autoCompleteSuggestionsO={null} />
    );
}


export const SelectExistingBuyer = (
    {
        buyers,
        setBuyerCompanyId
    }: {
        buyers: ReadonlyArray<IBuyerCompany>;
        setBuyerCompanyId: (id: string | null) => void;
    }
) => {
    const buyerOptions: IOption<IBuyerCompany>[] = buyers.map((buyer) => ({
        label: formatCompanyInput(buyer.identifier, buyer.name),
        value: buyer
    }));

    const [selectedBuyerOption, setSelectedBuyerOption] = useState<IOption<IBuyerCompany> | null>(null);

    return (
        <SingleSelect options={buyerOptions}
                      selectedOption={selectedBuyerOption}
                      selectOption={(newOption) => {
                          setSelectedBuyerOption(newOption);
                          setBuyerCompanyId(newOption?.value.id ?? null);
                      }}
                      placeholder='Choisissez un client'/>
    );
};

const SellerAddInvoiceBuyerInfos = (
    {
        className,
        axios,

        buyer,
        buyers,

        input,
        setBuyerSelection,
        setBuyerContactsInputs,

        autofocus,

        companySearchRequest
    }: {
        className?: string;
        axios: Axios;

        // one or the other
        buyer: IBuyerCompany | null;
        buyers: ReadonlyArray<IBuyerCompany> | null;

        input: IInvoiceBuyerInfosInput;
        setBuyerSelection: (selection: IBuyerCompanySelection | null) => void;
        setBuyerContactsInputs: (newValue: ILineContactInput[]) => void;

        autofocus: boolean;

        companySearchRequest: (inputCompanySearch: string) => Promise<ISearchCompaniesByNameResponse>;
    }
) => {
    const inputCompany = input.companySelection;

    return (
        <div className={className}>
            { !!buyer
                ? <Input className='field'
                         inputValue={formatCompanyInput(buyer.identifier, buyer.name)}
                         enabled={null} />
                : <BuyerSelection className='field'
                                  displayFullError={true}
                                  newBuyerPlaceholder='Nom ou SIREN de la société cliente'
                                  companySearchRequest={companySearchRequest}
                                  setBuyerSelection={setBuyerSelection}
                                  autofocus={autofocus}
                                  resetBuyer={() => {
                                      setBuyerSelection(null);
                                      setBuyerContactsInputs([{ email: '', phone: '' }]);
                                  }}
                                  existingBuyers={buyers!.length > 0
                                      ? {
                                          // Keep default labels
                                          SelectElement: <SelectExistingBuyer buyers={buyers!}
                                                                              setBuyerCompanyId={(id: string | null) =>
                                                                                  setBuyerSelection(!!id ? { existingIdO: id, newSirenO: null } : null)
                                                                              }/>
                                      }
                                      : null
                                  } />
            }

            <SellerAddInvoiceBuyerContact className='field'
                                          axios={axios}
                                          buyers={buyers}
                                          buyerSelection={inputCompany}
                                          buyerContactsInputs={input.contacts}
                                          setBuyerContactsInputs={setBuyerContactsInputs} />
        </div>
    );
}

export default SellerAddInvoiceBuyerInfos;
