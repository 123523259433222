import IAccessToken from "../../IAccessToken";
import SellerPage from "./SellerPage";
import WithSellerIdParamWrapper from "./WithSellerIdParamWrapper";
import SellerTabs from "./SellerTabs";
import { Navigate } from "react-router-dom";
import { SellerUrlMapping, sellerUrl } from "./sellersUrls";
import IRouteDefinitionWithAxiosAndRolesO from "../../routerBuilder/IRouteDefinitionWithAxiosAndRolesO";

const SellerRoute: IRouteDefinitionWithAxiosAndRolesO = ({
    path: SellerUrlMapping,
    titleO: 'Sellers',
    component: (accessTokenO: IAccessToken | null) =>
        <WithSellerIdParamWrapper child={(sellerId: string) =>
            <SellerPage accessTokenO={accessTokenO}
                        sellerId={sellerId} />
        }/>,
    children: [
        // Default redirect
        {
            path: SellerUrlMapping,
            component: () =>
                <WithSellerIdParamWrapper child={(sellerId) =>
                    <Navigate to={sellerUrl(sellerId)}/>
                }/>
        },
        ...SellerTabs.map((p) => ({
            path: p.path,
            component: (accessTokenO: IAccessToken | null) =>
                <WithSellerIdParamWrapper child={(sellerId) =>
                    p.component(accessTokenO, sellerId)
                }/>
        }))
    ]
});

export default SellerRoute;