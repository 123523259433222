import { Axios } from "axios";
import { SimulationText } from "./SimulatorPage";
import { ISimulationBuyerResult, SimulationBuyerInfos } from "./SimulationBuyerEnabled";
import { ICompanySelection } from "factor-lib/Company/ICompanySelection";
import IFailable from "factor-lib/IFailable";
import {useMutation, UseMutationResult} from "@tanstack/react-query";
import { formatAmount } from "factor-lib/utils/amountUtils";
import ButtonMutationForModal from "factor-lib/Buttons/ButtonForModal/ButtonMutationForModal";
import Button from "factor-lib/Buttons/Button";

interface ISimulationOrderResult {
    // at most one of the three
    notEligibleReason: string | null;
    pendingBuyerDefaultOutstandingLimit: IFailable<number | null> | null;
    eligible: ISimulationBuyerResult | null;
}

const simulationOrderAction = async (
    axios: Axios,
    buyerSelection: ICompanySelection,
    amountTTC: number
): Promise<ISimulationOrderResult> =>
    (await axios.get<ISimulationOrderResult>(
        `/adminSimulator/order?${buyerSelection.id !== null ? `companyId=${buyerSelection.id}` : `siren=${buyerSelection.siren!}`}&amountTTC=${amountTTC}`
    )).data;

const ResultModalContent = (
    {
        result,
        closeModal
    }: {
        result: ISimulationOrderResult;
        closeModal: () => void;
    }
) =>
    <div className='p-vertical-center p-padding-4'>
        { !!result.eligible
            ? <div>
                <div>La simulation est <span className='p-bold' style={{color: 'green'}}>Eligible</span></div>
                <SimulationBuyerInfos {...result.eligible} />
            </div>
            : !!result.notEligibleReason
                ? <div>
                    La simulation est <span className='p-bold' style={{color: 'red'}}>Non éligible</span>
                    <div>{ result.notEligibleReason }</div>
                </div>
                : <div>
                    La simulation est <span className='p-bold' style={{color: 'orange'}}>En cours de revue</span>
                    { result.pendingBuyerDefaultOutstandingLimit === null
                        ? <div>La présense d'une limite d'encours buyer <span className='p-bold'>manuelle</span> est nécessaire (buyer étranger)</div>
                        : result.pendingBuyerDefaultOutstandingLimit.errorO !== null
                            ? <div>Erreur lors de la récupération du Dimpl Score</div>
                            : result.pendingBuyerDefaultOutstandingLimit.successO === null
                                ? <div>La présense d'une limite d'encours buyer <span className='p-bold'>manuelle</span> est nécessaire (pas de limite discrétionnaire)</div>
                                : <div>La limite d'encours buyer <span className='p-bold'>discrétionnaire</span> de {formatAmount(result.pendingBuyerDefaultOutstandingLimit.successO)} sera dépassée</div>
                    }
                </div>
        }

        <Button id='closeModalClearForm'
                className='p-margin-top-5'
                text='Fermer'
                isLoading={false}
                actionO={closeModal} />
    </div>;

const SimulatorOrderEnabled = (
    {
        axios,
        buyerSelection,
        amountTTC,
        kind,
        size,
        onClose
    } : {
        axios: Axios;
        buyerSelection: ICompanySelection;
        amountTTC: number;
        kind?: string;
        size?: string;
        onClose: () => void;
    }
) => {
    const mutation: UseMutationResult<ISimulationOrderResult, any, any> =
        useMutation<ISimulationOrderResult, any, any>(
            () => simulationOrderAction(
                axios,
                buyerSelection,
                amountTTC
            )
        );

    return (
        <ButtonMutationForModal id='simulationOrder'
                                kind={kind}
                                size={size}
                                text={SimulationText}
                                mutation={mutation}
                                value={null}
                                displayFullError={true}
                                onClose={onClose}
                                resultModalNode={(r: ISimulationOrderResult) =>
                                    <ResultModalContent result={r}
                                                        closeModal={onClose}/>
                                }/>
    );
}

export default SimulatorOrderEnabled;
