import { ISwanIncomingPayment, ISwanIncomingPaymentInvoice, ISwanIncomingPaymentMatch } from "./swanIncomingPaymentsQuery";
import { Link } from "react-router-dom";
import {swanIncomingPaymentUrl} from "./swanIncomingPaymentsUrls";
import { invoiceUrl } from "../Invoices/invoicesUrls";
import {customerUrl} from "../Customers/customersUrls";
import { comparingDate, serverDateDeserialization } from "factor-lib/utils/dateUtils";
import { comparingRev } from "factor-lib/utils/comparingUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";
import {formatAdminDateStr} from "../../utils/dateTimeUtils";
import CheckBox from "factor-lib/forms/CheckBox";
import { IPendingOnly } from "./SwanIncomingPaymentsPage";
import { useEffect } from "react";
import { getAmountRemaining } from "./swanUtils";

const compareSwanPayment =
    comparingRev(
        comparingDate<ISwanIncomingPayment>(
            (p: ISwanIncomingPayment) => serverDateDeserialization(p.swanCreationDateTime)
        )
    );

interface ISwanIncomingPaymentInvoiceMatch  {
    invoice: ISwanIncomingPaymentInvoice;
    isSentToCustomer: boolean;
    isComplete: boolean;
    amount: number;
}

const buildSwanPaymentInvoiceMatchesMap = (
    swanPaymentInvoices: ISwanIncomingPaymentInvoice[],
    swanPaymentMatches: ISwanIncomingPaymentMatch[]
): Map<string, ISwanIncomingPaymentInvoiceMatch[]> => {
    const swanPaymentToInvoicesMap = new Map<string, ISwanIncomingPaymentInvoiceMatch[]>();
    const invoicesMap = new Map(swanPaymentInvoices.map((i) => [i.id, i]));

    swanPaymentMatches.forEach((m) => {
        const swanPaymentId = m.swanIncomingPaymentId;
        const invoice = invoicesMap.get(m.invoiceId)!;

        const swanPaymentInvoiceMatch: ISwanIncomingPaymentInvoiceMatch = {
            invoice,
            isSentToCustomer: m.isSentToCustomer,
            isComplete: m.isComplete,
            amount: m.amount
        };

        const swanPaymentInvoices = swanPaymentToInvoicesMap.get(swanPaymentId);
        if (!!swanPaymentInvoices) {
            swanPaymentInvoices.push(swanPaymentInvoiceMatch);
        } else {
            swanPaymentToInvoicesMap.set(swanPaymentId, [swanPaymentInvoiceMatch]);
        }
    });

    return swanPaymentToInvoicesMap;
};

const SwanIncomingPaymentsTableLoaded = (
    {
        swanIncomingPayments,
        swanIncomingPaymentInvoices,
        swanIncomingPaymentMatches,
        searchInput,
        showPendingOnly,
        setSwanIncomingPaymentsLoaded
    }: {
        swanIncomingPayments: ISwanIncomingPayment[];
        swanIncomingPaymentInvoices: ISwanIncomingPaymentInvoice[];
        swanIncomingPaymentMatches: ISwanIncomingPaymentMatch[];
        searchInput: string;
        showPendingOnly: IPendingOnly | null;
        setSwanIncomingPaymentsLoaded: (s: ISwanIncomingPayment[]) => void;
    }
) => {
    useEffect(() => {
        setSwanIncomingPaymentsLoaded(swanIncomingPayments);
    }, [swanIncomingPayments, setSwanIncomingPaymentsLoaded])

    const hasSearchString = (baseStr: string) =>
        baseStr.toLocaleLowerCase().includes(searchInput.toLowerCase());

    const swanPaymentsFiltered =
        swanIncomingPayments
            .filter((payment) =>
                (!!payment.matchingCustomer && hasSearchString(payment.matchingCustomer.company.name))
                || hasSearchString(payment.label)
                || hasSearchString(payment.id)
                || hasSearchString(payment.reference)
                || hasSearchString(payment.creditedIban)
                || hasSearchString(payment.creditorName)
                || hasSearchString(payment.type)
                || hasSearchString(payment.status)
            )
            .filter((p) =>
                !showPendingOnly || (
                    getAmountRemaining(p) > 0 &&
                    (!showPendingOnly.flaggedNoMatchOnly || !!p.noMatchFlaggedDateTime)
                )
            )
            .sort(compareSwanPayment);

    const swanPaymentInvoiceMatchesMap = buildSwanPaymentInvoiceMatchesMap(
        swanIncomingPaymentInvoices,
        swanIncomingPaymentMatches
    );

    return (
        <tbody>
            { swanPaymentsFiltered
                .map((p, index) => {
                    const invoiceMatches = swanPaymentInvoiceMatchesMap.get(p.id);
                    const customer = p.matchingCustomer;
                    const paymentAmountRemaining = getAmountRemaining(p);

                    return (
                        <tr key={`payment-${index}`}>
                            <td>
                                <Link id={`payment-${p.id}-link`}
                                      to={swanIncomingPaymentUrl(p.id)}>
                                    { p.id }
                                </Link>
                            </td>
                            <td>
                                { formatAdminDateStr(p.swanCreationDateTime) }
                            </td>
                            <td>
                                <CheckBox internalId={`no-match-flagged-${p.id}`}
                                          inputValue={!!p.noMatchFlaggedDateTime}
                                          updateInputValue={null} />
                            </td>
                            <td>
                                <div>{ formatAmount(p.creditedAmount/* , p.currency */) }</div>
                                { p.creditedAmount !== paymentAmountRemaining &&
                                    <div>({formatAmount(paymentAmountRemaining)}&nbsp;restant)</div>
                                }
                            </td>
                            <td>
                                { p.creditorName }
                            </td>
                            <td>
                                { customer !== null
                                    ? <Link id={`payment-${p.id}-customer-link`}
                                            to={customerUrl(customer.id)}>
                                        { customer.company.name }
                                    </Link>
                                    : `${p.creditedIban} (unknown)`
                                }
                            </td>
                            <td>
                                { p.label }
                            </td>
                            <td>
                                { p.reference }
                            </td>
                            <td>
                                { (p.noMatchDateTime !== null || p.archivedDateTime !== null || !!invoiceMatches) &&
                                    <ul>
                                        { p.noMatchDateTime !== null &&
                                            <li>No Match [{formatAmount(p.creditedAmount - p.matchedAmount)}]</li>
                                        }
                                        { !!invoiceMatches && invoiceMatches.map((m, i) =>
                                            <li key={i}>
                                                <Link id={`payment-${p.id}-invoice-${m.invoice.id}-link`}
                                                      to={invoiceUrl(m.invoice.id)}>
                                                    { m.invoice.number }
                                                </Link>
                                                &nbsp;[{m.isComplete ? 'complet' : 'partiel'}
                                                &nbsp;{ `${formatAmount(m.amount)}${m.isSentToCustomer ? ' envoyé à la plateforme' : ''}` }]
                                                
                                            </li>
                                        ) }
                                        { p.archivedComment !== null &&
                                            <li>Archivé</li>
                                        }
                                    </ul>
                                }
                            </td>
                            <td>
                                { p.type } ({p.status})
                            </td>
                        </tr>
                    );
                })
            }
        </tbody>
    );
}

export default SwanIncomingPaymentsTableLoaded;
