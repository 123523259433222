import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import {Axios} from "axios";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import Button from "factor-lib/Buttons/Button";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import Input from "factor-lib/forms/Inputs/Input";
import { KIND_PRIMARY, SIZE_FIXED, SIZE_COMPACT } from "factor-lib/Buttons/Button";
import Modal from "factor-lib/Modal";
import { useState } from "react";
import IAuthUser from "../../../../../../IAuthUser";
import { ISellerDetailsQueryResult, sellerDetailsQueryKey } from "../../../sellerDetailsQuery";
import { isValidString, validateMandatoryFieldError } from "factor-lib/forms/Inputs/utils";

const UpdateButtonText = 'Modifier';
const UpdateButtonKind = KIND_PRIMARY;
const UpdateButtonSize = SIZE_FIXED;

const UpdateOtherDocumentsLinkButtonEnabled = (
    {
        axios,
        sellerId,
        otherDocumentsLink,
        closeModal
    } : {
        axios: Axios;
        sellerId: string;
        otherDocumentsLink: string;
        closeModal: () => void;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateOtherDocumentsLinkMutation: UseMutationResult<void, any, string, null> =
        useMutation<void, any, string, null>(
            async (otherDocumentsLink) => {
                await axios.put<void>(
                    `/adminSellerDocuments/${sellerId}/otherDocumentsLink`,
                    {
                        uri: otherDocumentsLink
                    }
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old: ISellerDetailsQueryResult | undefined) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                otherDocumentsLink: otherDocumentsLink
                            }
                        })
                    );
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='update-other-documents-link-button'
                               displayFullError={true}
                               mutation={updateOtherDocumentsLinkMutation}
                               value={otherDocumentsLink}
                               kind={UpdateButtonKind}
                               size={UpdateButtonSize}
                               text={UpdateButtonText} />
    );
};

const UpdateOtherDocumentsLinkModalContent = (
    {
        axios,
        sellerId,
        sellerOtherDocumentsLink,
        closeModal
    }: {
        axios: Axios;
        sellerId: string;
        sellerOtherDocumentsLink: string | null;
        closeModal: () => void;
    }
) => {
    const [ newOtherDocumentsLinkInput, setNewOtherDocumentsLinkInput ] = useState(sellerOtherDocumentsLink ?? '');
    return (
        <div className='p-padding-4 p-vertical-center'>
            <span>
                Veuillez saisir le nouveau lien <span className='p-size-7'>(avec le prefix https://)</span>
            </span>

            <Input className='p-margin-top-5'
                   inputValue={newOtherDocumentsLinkInput}
                   placeHolder='Lien vers autres documents'
                   enabled={{
                       updateInputValue: setNewOtherDocumentsLinkInput,
                       validateInput: () => validateMandatoryFieldError(newOtherDocumentsLinkInput),
                       innerId: {
                           value: 'update-other-documents-link',
                           autofocus: true
                       }
                   }} />

            <div className='p-margin-top-5'>
                { isValidString(newOtherDocumentsLinkInput)
                    ? <UpdateOtherDocumentsLinkButtonEnabled axios={axios}
                                                             sellerId={sellerId}
                                                             otherDocumentsLink={newOtherDocumentsLinkInput}
                                                             closeModal={closeModal} />
                    : <ButtonDisabled kind={UpdateButtonKind}
                                      size={UpdateButtonSize}
                                      text={UpdateButtonText} />

                }
            </div>
        </div>
    );
}

const UpdateOtherDocumentsLinkModal = (
    {
        axios,
        sellerId,
        sellerOtherDocumentsLink,
        closeModal
    }: {
        axios: Axios;
        sellerId: string;
        sellerOtherDocumentsLink: string | null;
        closeModal: () => void;
    }
) =>
    <Modal id='update-other-documents-link'
           maxWidth={null}
           fullMaxWidth={false}
           close={closeModal}>
        <UpdateOtherDocumentsLinkModalContent axios={axios}
                                              sellerId={sellerId}
                                              sellerOtherDocumentsLink={sellerOtherDocumentsLink}
                                              closeModal={closeModal} />
    </Modal>;

const AddUpdateButton = (
    {
        axios,
        sellerId,
        sellerOtherDocumentsLink
    }: {
        axios: Axios;
        sellerId: string;
        sellerOtherDocumentsLink: string | null;
    }
) => {
    const [ showUpdateOtherDocumentsLinkModal, setShowUpdateOtherDocumentsLinkModal ] = useState(false);
    return (
        <>
            <Button id='show-update-other-documents-link-modal'
                    className='p-margin-left-4'
                    kind={KIND_PRIMARY}
                    size={SIZE_COMPACT}
                    text={sellerOtherDocumentsLink === null ? 'Ajouter' : 'Modifier'}
                    isLoading={showUpdateOtherDocumentsLinkModal}
                    actionO={() => setShowUpdateOtherDocumentsLinkModal(true)} />
            { showUpdateOtherDocumentsLinkModal &&
                <UpdateOtherDocumentsLinkModal axios={axios}
                                               sellerId={sellerId}
                                               sellerOtherDocumentsLink={sellerOtherDocumentsLink}
                                               closeModal={() => setShowUpdateOtherDocumentsLinkModal(false)} />
            }

        </>
    );
}

const OtherDocumentsLink = (
    {
        axios,
        authUser,
        sellerId,
        sellerOtherDocumentsLink
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        sellerId: string;
        sellerOtherDocumentsLink: string | null;
    }
) =>
    <div>
        <div className='p-horizontal-center'>
            <span className='p-bold'>Lien vers d'autres documents</span>

            <div className='p-margin-left-4'>
                { sellerOtherDocumentsLink !== null
                    ? <a href={sellerOtherDocumentsLink}>
                        { sellerOtherDocumentsLink.length > 64
                            ? sellerOtherDocumentsLink.slice(0, 64) + "..."
                            : sellerOtherDocumentsLink
                        }
                    </a>
                    : <span>Aucun lien n'a été enregistré</span>
                }
            </div>

            { authUser?.canManageProfiles &&
                <AddUpdateButton axios={axios}
                                 sellerId={sellerId}
                                 sellerOtherDocumentsLink={sellerOtherDocumentsLink} />
            }
        </div>
    </div>;

export default OtherDocumentsLink;
