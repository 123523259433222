import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import MainNavRoutes from "./MainNavRoutes";
import { useLocation } from 'react-router-dom'
import { ensureOneAuthUser } from "factor-lib/serverUtils/buildDefaultAuthAxios";
import {getFactorContext} from "./IFactorContext";

import './topbar.scss';

const TopBar = (
    {
        className
    }: {
        className?: string;
    }
) => {
    const { instance, accounts } = useMsal();

    const currentRoute = useLocation().pathname.split('/')[1];

    return (
        <div className={`level f-navbar navbar${!!className ? ` ${className}` : ''}`}>
            <div className='level-left'>
                <nav role='navigation' aria-label='main navigation'>
                    <div className='navbar-menu p-bold'>
                        <div className='navbar-start'>
                            { MainNavRoutes.map((mainRoute, index) =>
                                <Link key={`l-${index}`}
                                      className={`navbar-item${'/' + currentRoute === mainRoute.route.path /* TODO : not exact ? */ ? ' p-border-small f-navbar-selected' : ''}`}
                                      to={mainRoute.route.path!}
                                >
                                    { mainRoute.label }
                                </Link>
                            )}
                        </div>
                    </div>
                </nav>
            </div>

            <div className='level-right navbar-item'>
                { accounts.length > 0
                    ? `Bonjour ${ensureOneAuthUser(instance, getFactorContext().logger, accounts/* , clientId */).name}`
                    : 'Pas encore authentifié'
                }
            </div>
        </div>
    );
}

export default TopBar;
