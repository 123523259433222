import {Axios} from "axios";
import {IInvoiceDetailsQueryResult, invoiceDetailsQueryKey} from "../../invoiceDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";

export const InvoiceUpdateOpsNotesEnabled = (
    {
        invoiceId,
        axios,
        opsNotes,
        kind,
        size,
        text
    } : {
        invoiceId: string;
        axios: Axios;
        opsNotes: string;
        kind: string;
        size: string;
        text: string;
    }
) => {

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateOpsNotesMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (opsNotes2) => {
                await axios.put(
                    `/adminInvoices/${invoiceId}/opsNotes`,
                    {
                        opsNotes: opsNotes2
                    }
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                        invoiceDetailsQueryKey(invoiceId),
                        (old: IInvoiceDetailsQueryResult | undefined) => ({
                            invoiceDetails: {
                                ...old!.invoiceDetails,
                                opsNotes: opsNotes
                            },
                            buyerEvents: old!.buyerEvents
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateInvoiceOpsNotes'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateOpsNotesMutation}
                               displayFullError={true}
                               value={opsNotes} />
    );
}
