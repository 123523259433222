import {Axios} from "axios";
import {useContext, useState} from "react";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import Input from "factor-lib/forms/Inputs/Input";
import IPerson, { getPersonParams } from "../../../utils/Person/IPerson";
import PersonInput, { getInitialNewPersonInput, IPersonInput } from "../../../utils/Person/PersonInput";
import { ISellersQueryResult, sellersQueryKey } from "../sellersQuery";
import { ResultOrErrors } from "../../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../../utils/ShowValidationErrorsButton";
import Modal from "factor-lib/Modal";
import Button from "factor-lib/Buttons/Button";
import {NavigateFunction} from "react-router-dom";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IAddressOInput} from "../../../utils/AddressInput";
import {AddSellerUrl, sellerUrl} from "../sellersUrls";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import { formatCompanyInput } from "factor-lib/Company/companyUtils";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import { isValidIban, validateIbanError } from "factor-lib/forms/Inputs/ibanUtils";
import { isValidEmail, validateEmailError } from "factor-lib/forms/Inputs/emailUtils";
import { isValidPhone, validatePhoneError } from "factor-lib/forms/Inputs/phoneUtils";

const AddText = 'Ajouter';

interface IAddDirectSellerParams {
    customerId: string;
    iban: string | null;
    email: string;
    phone : string;
    companyOwner: IPerson;
    dimplTermsAcceptationDateTime: string;
}

interface IAddDirectSellerResponse {
    sellerId: string;
    directSellerId: string;
}

const AddDirectSellerEnabled = (
    {
        axios,
        customerName,
        customerIdentifier,
        addParams,
        setAddResponse,
        kind,
        size,
        text
    } : {
        axios: Axios;
        customerName: string;
        customerIdentifier: IIntCompanyIdentifier;
        addParams: IAddDirectSellerParams;
        setAddResponse: (addResponse: IAddDirectSellerResponse) => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const addDirectSellerMutation: UseMutationResult<IAddDirectSellerResponse, any, IAddDirectSellerParams, null> =
        useMutation<IAddDirectSellerResponse, any, IAddDirectSellerParams, null>(
            async (addParams2) =>
                (await axios.post<IAddDirectSellerResponse>(
                    `/adminDirectSellers`,
                    addParams2
                )).data,
            ({
                onSuccess: (addResponse) => {
                    queryClient.setQueryData<ISellersQueryResult>(
                        sellersQueryKey,
                        (old: ISellersQueryResult | undefined) => ({
                            sellers: [
                                ...old!.sellers,
                                {
                                    id: addResponse.sellerId,
                                    creationDateTime: serverDateSerialization(new Date()),
                                    email: addParams.email,
                                    isBlocked: false,
                                    certif: null,
                                    company: {
                                        name: customerName,
                                        identifier: customerIdentifier
                                    },
                                    customer: {
                                        directSeller: {
                                            id: addResponse.directSellerId
                                        }
                                    }
                                }
                            ]
                        })
                    );
                    setAddResponse(addResponse);
                }
            })
        );

    return (
        <ButtonMutationEnabled id='addDirectSellerButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={addDirectSellerMutation}
                               displayFullError={true}
                               value={addParams} />
    );
}

const getDirectSellerAddParams = (
    customerId: string,

    ibanInput: string,
    emailInput: string,
    phoneInput : string,

    ownerFirstNameInput: string,
    ownerLastNameInput: string,
    ownerNationality: string | null,

    ownerBirthDateInput: string,
    ownerBirthCityInput: string,
    ownerBirthCountry: string | null,

    ownerResidenceAddressInput: IAddressOInput

): ResultOrErrors<IAddDirectSellerParams> => {

    const validationErrors: string[] = [];

    const ibanInputNullable = ibanInput !== '' ? ibanInput : null;
    if (ibanInputNullable !== null && !isValidIban(ibanInputNullable)) {
        validationErrors.push(`L'IBAN est invalide`);
    }

    if (!isValidEmail(emailInput)) {
        validationErrors.push(`L'email est invalide`);
    }

    if (!isValidPhone(phoneInput)) {
        validationErrors.push('Le téléphone est invalide');
    }

    const companyOwnerParamsFailable: ResultOrErrors<IPerson> = getPersonParams(
        ownerFirstNameInput,
        ownerLastNameInput,
        ownerNationality,

        true,
        ownerBirthDateInput,
        ownerBirthCityInput,
        ownerBirthCountry,

        ownerResidenceAddressInput
    );

    if (!!companyOwnerParamsFailable.errors) {
        validationErrors.push(...companyOwnerParamsFailable.errors);
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    return ResultOrErrors.fromResult({
        customerId: customerId,

        iban: ibanInputNullable,
        email: emailInput,
        phone: phoneInput,

        companyOwner: companyOwnerParamsFailable.result!,

        dimplTermsAcceptationDateTime: serverDateSerialization(new Date()),
    });
}

const AddResponseModal = (
    {
        customerName,
        responseSellerId,
        resetResponseAddNewSeller
    }: {
        customerName: string;
        responseSellerId: string;
        resetResponseAddNewSeller: () => void;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    return (
        <Modal id='addDirectSellerResponseModal'
               maxWidth={null}
               fullMaxWidth={false}
               close={resetResponseAddNewSeller}>
            <div className='p-padding-4 p-vertical-center'>
                <span>Le seller de la plateforme directe <span className='p-bold'>{customerName}</span> a bien été ajouté</span>

                <div className='columns p-margin-top-5'>
                    <div className='column'>
                        <Button id='gotoAddedSellerDetailsButton'
                                text='Détails du Seller'
                                isLoading={false}
                                actionO={() => navigate(sellerUrl(responseSellerId))} />

                    </div>
                    <div className='column'>
                        <Button id='addAnotherSellerButton'
                                text='Ajouter un autre Seller'
                                isLoading={false}
                                actionO={resetResponseAddNewSeller} />

                    </div>
                </div>
            </div>
        </Modal>
    );
}

const AddDirectSeller = (
    {
        axios,
        customerId,
        customerName,
        customerIdentifier,

        // for prefilling
        initialSellerEmail,
        initialSellerPhone,
        initialSellerIbanO
    }: {
        axios: Axios;
        customerId: string;
        customerName: string;
        customerIdentifier: IIntCompanyIdentifier;

        initialSellerEmail: string;
        initialSellerPhone: string;
        initialSellerIbanO: string | null;
    }
) => {
    // most likely the same as direct seller infos, we prefill them
    const [emailInput, setEmailInput] = useState(initialSellerEmail);
    const [phoneInput, setPhoneInput] = useState(initialSellerPhone);
    const [ibanInput, setIbanInput] = useState(initialSellerIbanO ?? '');

    const companyOwnerInput: IPersonInput = getInitialNewPersonInput(true);

    const [firstNameInput, setFirstNameInput] = useState(companyOwnerInput.firstName);
    const [lastNameInput, setLastNameInput] = useState(companyOwnerInput.lastName);
    const [nationalityCodeInput, setNationalityCodeInput] = useState(companyOwnerInput.nationalityCode);

    const [birthDateInput, setBirthDateInput] = useState(companyOwnerInput.birthDate);
    const [birthCityInput, setBirthCityInput] = useState(companyOwnerInput.birthCity);
    const [birthCountryCodeInput, setBirthCountryCodeInput] = useState(companyOwnerInput.birthCountryCode);

    const [companyOwnerResidenceAddressInput, setCompanyOwnerResidenceAddressInput] = useState<IAddressOInput>(companyOwnerInput.residenceAddress);

    const addParams: ResultOrErrors<IAddDirectSellerParams> =
        getDirectSellerAddParams(
            customerId,

            ibanInput,
            emailInput,
            phoneInput,

            firstNameInput,
            lastNameInput,
            nationalityCodeInput,

            birthDateInput,
            birthCityInput,
            birthCountryCodeInput,

            companyOwnerResidenceAddressInput
        );

    const [addResponse, setAddResponse] = useState<IAddDirectSellerResponse | null>(null);

    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    return (
        <div className='container p-both-center p-margin-top-3'>
            <div style={{width: 600}}>
                <span className='title p-both-center'>
                    Ajouter le Seller de la plateforme Directe {formatCompanyInput(customerIdentifier, customerName)}
                </span>

                <div className='box'>
                    <span className='p-bold p-size-4 p-both-center p-margin-bottom-4'>Infos générales</span>

                    <div className='p-padding-bottom-5'>
                        <span className='p-bold'>Email</span>
                        <Input inputValue={emailInput}
                               enabled={{
                                   updateInputValue: setEmailInput,
                                   validateInput: () => validateEmailError(emailInput),
                                   innerId: {
                                       value: 'emailInner',
                                       autofocus: true
                                   }
                               }} />
                    </div>

                    <div>
                        <span className='p-bold'>Téléphone</span>
                        <Input inputValue={phoneInput}
                               enabled={{
                                   updateInputValue: setPhoneInput,
                                   validateInput: () => validatePhoneError(phoneInput)
                               }} />
                    </div>
                </div>

                <div className='box'>
                    <span className='p-bold p-size-4 p-both-center p-margin-bottom-4'>Infos de la société</span>
                    <div>
                        <span className='p-bold'>IBAN (optionel)</span>
                        <Input inputValue={ibanInput}
                               enabled={{
                                   updateInputValue: setIbanInput,
                                   validateInput: () => validateIbanError(ibanInput)
                               }} />
                    </div>
                </div>

                <div className='box'>
                    <span className='p-bold p-size-4 p-both-center p-margin-bottom-4'>Mandataire</span>
                    <PersonInput firstNameInput={firstNameInput}
                                 lastNameInput={lastNameInput}
                                 nationalityCodeInput={nationalityCodeInput}
                                 isBirthInfosMandatory={true}
                                 birthDateInput={birthDateInput}
                                 birthCityInput={birthCityInput}
                                 birthCountryCodeInput={birthCountryCodeInput}
                                 residenceAddressInput={companyOwnerResidenceAddressInput}
                                 enabled={{
                                     setFirstNameInput: setFirstNameInput,
                                     setLastNameInput: setLastNameInput,
                                     setNationalityCodeInput: setNationalityCodeInput,
                                     setBirthDateInput: setBirthDateInput,
                                     setBirthCityInput: setBirthCityInput,
                                     setBirthCountryCodeInput: setBirthCountryCodeInput,
                                     setResidenceAddressInput: setCompanyOwnerResidenceAddressInput,
                                     isBirthInfosMandatory: true,
                                     autofocus: false
                                 }} />
                </div>

                <div className='p-margin-3 p-vertical-center'>
                    { !!addParams.result
                        ? <AddDirectSellerEnabled axios={axios}
                                                  customerName={customerName}
                                                  customerIdentifier={customerIdentifier}
                                                  addParams={addParams.result}
                                                  setAddResponse={setAddResponse}
                                                  kind={KIND_PRIMARY}
                                                  size={SIZE_FIXED}
                                                  text={AddText} />
                        : <ShowValidationErrorsButton validationErrors={addParams.errors!}
                                                      kind={KIND_PRIMARY}
                                                      size={SIZE_FIXED}
                                                      text={AddText} />
                    }
                </div>

                { !!addResponse &&
                    <AddResponseModal customerName={customerName}
                                      responseSellerId={addResponse.sellerId}
                                      resetResponseAddNewSeller={() => {
                                          setAddResponse(null);
                                          navigate(AddSellerUrl);
                                      }}/>

                }
            </div>
        </div>
    );
}

export default AddDirectSeller;
