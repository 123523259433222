import {useQuery} from "@tanstack/react-query";
import {ReactElement, ReactNode} from "react";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import Loader from 'factor-lib/Loader';
import {UseQueryOptions} from "@tanstack/react-query/src/types";

const ReactQueryWrapper = <T, > (
    {
        queryKey,
        queryFunc,
        onLoading,
        onError,
        onSuccess,
        options
    }: {
        queryKey: string[];
        queryFunc: (signal?: AbortSignal) => Promise<T>;
        onLoading?: () => ReactElement;
        onError?: (e: string) => ReactElement;
        onSuccess: (t: T) => ReactNode;
        options?: Omit<
            UseQueryOptions<T>,
            'queryKey' | 'queryFn' | 'initialData'
        > & { initialData?: () => undefined }
    }
) =>
    <ReactQueryResultWrapper result={useQuery<T>(queryKey, ({ signal }) => queryFunc(signal), options) /* this useQuery() does not work if in lib (https://github.com/TanStack/query/discussions/4302) */}
                             onLoading={onLoading ?? (() => <Loader />)}
                             onError={onError}
                             onSuccess={onSuccess}
                             displayFullError={true} />;

export default ReactQueryWrapper;
