import { IFinancingStatus, financingWithStatusGraphQLQuery } from "../../Pages/Invoices/invoiceStatus";
import IBaseInvoice, {
    BaseInvoiceGraphQLFields,
    IBaseInvoiceSellerWithCompany,
    IInvoiceBuyerCompany,
    InvoiceBaseSellersGraphQLSubQuery,
    InvoiceBuyerCompaniesGraphQLSubQuery
} from "../IInvoice";

const FinancedInvoiceGraphQLFields =
    `
        ${BaseInvoiceGraphQLFields}
        payment { completePaidDate } 
    `;

interface IFinancedInvoice extends IBaseInvoice {
    payment: {
        completePaidDate: string | null;
    }
}

export interface IFinancing extends IFinancingStatus {
    creationDateTime: string;
    invoice: IFinancedInvoice;
}

export interface IFinancingsQueryResult {
    financings: {
        base: IFinancing[];
        sellers: IBaseInvoiceSellerWithCompany[];
        buyerCompanies: IInvoiceBuyerCompany[];
    };
}

export const ActiveFinancingsQueryKeyPrefix = ['financings', 'active'];

export const getActiveFinancingsGraphQLQuery =
    (sellerId: string | null, buyerCompanyId: string | null): string =>
        `{
            financings (isActive: true${!!sellerId ? `, sellerId: "${sellerId}"` : ''}${!!buyerCompanyId ? `, buyerCompanyId: "${buyerCompanyId}"` : ''} ) {
                base {
                    ${financingWithStatusGraphQLQuery(
                        `
                            creationDateTime
                            invoice {
                                ${FinancedInvoiceGraphQLFields}
                            }
                        `    ,
                        ''
                    )}
                }
                sellers {
                    ${InvoiceBaseSellersGraphQLSubQuery}
                }
                ${InvoiceBuyerCompaniesGraphQLSubQuery}
            }
        }`;
