import {ILiasse} from "./IBilan";

const parseLiasse = (liasseInput: string): ILiasse | null => {
    const liasse: ILiasse = {};
    for (const line of liasseInput.split('\n')) {
        const lineTrimmed = line.trim();
        if (lineTrimmed.length > 0) {
            const keyValue = line.split('=', 2);
            if (keyValue.length === 2) {
                const valueStr = keyValue[1].trim();
                const value = parseInt(valueStr, 10);
                if (isNaN(value)) {
                    return null;
                }
                liasse[keyValue[0].trim()] = value;
            } else {
                return null;
            }
        }
    }
    return liasse;
}

export default parseLiasse;
