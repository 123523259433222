import IAuthUser from "../../../../IAuthUser";
import {ReactElement, useState} from "react";
import InputAmount, { parseInputAmount, validateAmountError } from "factor-lib/forms/Inputs/InputAmount";
import {Axios} from "axios";
import { customerDetailsQueryKey, ICustomerDetailsQueryResult } from "../customerDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import ShowValidationErrorsButton from "../../../../utils/ShowValidationErrorsButton";
import { ResultOrErrors } from "../../../../utils/ResultOrErrors";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonMutation from "factor-lib/Buttons/ButtonMutation";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { formatAmount } from "factor-lib/utils/amountUtils";
import CheckBox from 'factor-lib/forms/CheckBox';

const UpdateText = 'Modifier';

const autoApproveFinancingRequestsId = 'autoApproveFinancingRequests';

const UpdateOutstandingLimitEnabled = (
    {
        axios,
        customerId,
        newOutstandingLimit,
        kind,
        size
    }: {
        axios: Axios;
        customerId: string;
        newOutstandingLimit: number;
        kind: string;
        size: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateOutstandingLimitMutation: UseMutationResult<void, any, number, void> =
        useMutation<void, any, number, void>(
            async (newOutstandingLimit2: number) => {
                await axios.put<void>(
                    `/adminCustomers/${customerId}/outstandingLimit`,
                    {
                        outstandingLimit: newOutstandingLimit2
                    }
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId),
                        (old) => ({
                            customerDetails: {
                                ...old!.customerDetails,
                                outstandingLimit: newOutstandingLimit
                            }
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateCustomerOutstandingLimitButton'
                               kind={kind}
                               size={size}
                               text={UpdateText}
                               mutation={updateOutstandingLimitMutation}
                               displayFullError={true}
                               value={newOutstandingLimit} />
    );
}

const UpdateMinInvoiceAmountEnabled = (
    {
        axios,
        customerId,
        newMinInvoiceAmount,
        kind,
        size
    }: {
        axios: Axios;
        customerId: string;
        newMinInvoiceAmount: number;
        kind: string;
        size: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateMinInvoiceAmountMutation: UseMutationResult<void, any, number, void> =
        useMutation<void, any, number, void>(
            async (newMinInvoiceAmount2: number) => {
                await axios.put<void>(
                    `/adminCustomers/${customerId}/minInvoiceAmount`,
                    {
                        minInvoiceAmount: newMinInvoiceAmount2
                    }
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId),
                        (old) => ({
                            customerDetails: {
                                ...old!.customerDetails,
                                minInvoiceAmount: newMinInvoiceAmount
                            }
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateMinInvoiceAmount'
                               kind={kind}
                               size={size}
                               text={UpdateText}
                               mutation={updateMinInvoiceAmountMutation}
                               displayFullError={true}
                               value={newMinInvoiceAmount} />
    );
}

const UpdateAutoApproveFinancingRequests = (
    {
        axios,
        customerId,
        preValue,
        newValue,
        kind,
        size
    }: {
        axios: Axios;
        customerId: string;
        preValue: boolean;
        newValue: boolean;
        kind: string;
        size: string;
    }
) => {
    const queryClient = useQueryClient();

    const updateAutoApproveFinancingRequestsMutation: UseMutationResult<void, any, boolean, void> =
        useMutation<void, any, boolean, void>(
            async (newValue2: boolean) => {
                await axios.put<void>(
                    `/adminCustomers/${customerId}/autoApproveFinancingRequests`,
                    {
                        autoApproveFinancingRequests: newValue2
                    }
                );
            },
            ({
                onSuccess: (_, r) => {
                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId),
                        (old) => ({
                            customerDetails: {
                                ...old!.customerDetails,
                                autoApproveFinancingRequests: r
                            }
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutation id='updateUpdateAutoApproveFinancingRequests'
                        kind={kind}
                        size={size}
                        text={UpdateText}
                        mutation={updateAutoApproveFinancingRequestsMutation}
                        displayFullError={true}
                        valueO={preValue !== newValue ? ({
                            value: newValue
                        }) : null} />
    );
}

const getUpdate = (
    oldValue: number,
    newValueInput: string,
    validator: (input: string) => string | null,
): ResultOrErrors<number> | null => {
    const validationError: string | null = validator(newValueInput);
    if (!!validationError) {
        return ResultOrErrors.fromError([validationError]);
    }
    const newValue: number = parseInputAmount(newValueInput);
    if (newValue !== oldValue) {
        return ResultOrErrors.fromResult(newValue);
    }
    return null;
}

const LineItem = (
    {
        authUser,
        title,
        nonEditableValue,
        editableValue,
        editableValueButton
    }: {
        authUser: IAuthUser | null;
        title: string;
        nonEditableValue: ReactElement;
        editableValue: ReactElement;
        editableValueButton: ReactElement;
    }
) =>
    <tr>
        <td className='p-bold p-padding-5'>{ title }</td>

        { !authUser?.canManageProfiles
            ? <td style={{verticalAlign: 'middle'}}>{ nonEditableValue }</td>
            : <>
                <td style={{verticalAlign: 'middle'}}>
                    { editableValue }
                </td>
                <td style={{verticalAlign: 'middle'}} className='p-padding-left-4'>
                    { editableValueButton }
                </td>
            </>
        }
    </tr>

const CustomerLimitsSection = (
    {
        axios,
        authUser,
        customerId,
        outstandingLimit,
        minInvoiceAmount,
        autoApproveFinancingRequests,
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        customerId: string;
        outstandingLimit: number;
        minInvoiceAmount: number;
        autoApproveFinancingRequests: boolean;
    }
) => {
    const initialOutstandingLimitInput = (outstandingLimit / 100).toString();
    const initialMinInvoiceAmountInput = (minInvoiceAmount / 100).toString();
    const initialAutoApproveFinancingRequests = autoApproveFinancingRequests;


    const [newOutstandingLimitInput, setNewOutstandingLimitInput] = useState(initialOutstandingLimitInput);
    const [newMinInvoiceAmountInput, setNewMinInvoiceAmountInput] = useState(initialMinInvoiceAmountInput);
    const [newAutoApproveFinancingRequestsInput, setNewAutoApproveFinancingRequestsInput] = useState(initialAutoApproveFinancingRequests);

    const outstandingLimitUpdate: ResultOrErrors<number> | null = getUpdate(outstandingLimit, newOutstandingLimitInput, validateAmountError);
    const minInvoiceAmountUpdate: ResultOrErrors<number> | null = getUpdate(minInvoiceAmount, newMinInvoiceAmountInput, validateAmountError);

    return (
        <table className='table is-narrow'>
            <tbody>
                <LineItem authUser={authUser}
                          title={`Limite d'encours`}
                          nonEditableValue={<span>{ formatAmount(outstandingLimit) }</span>}
                          editableValue={
                                <InputAmount inputAmountValue={newOutstandingLimitInput}
                                             enabled={{
                                                 updateInputValue: setNewOutstandingLimitInput
                                             }} />
                          }
                          editableValueButton={
                              !!outstandingLimitUpdate
                                  ? !outstandingLimitUpdate.errors // 0 result is not accepted with !!outstandingLimitUpdate.result
                                      ? <UpdateOutstandingLimitEnabled axios={axios}
                                                                       customerId={customerId}
                                                                       newOutstandingLimit={outstandingLimitUpdate.result!}
                                                                       kind={KIND_PRIMARY}
                                                                       size={SIZE_FIXED} />
                                      : <ShowValidationErrorsButton validationErrors={outstandingLimitUpdate.errors}
                                                                    kind={KIND_PRIMARY}
                                                                    size={SIZE_FIXED}
                                                                    text={UpdateText} />
                                  : <ButtonDisabled kind={KIND_PRIMARY}
                                                    size={SIZE_FIXED}
                                                    text={UpdateText} />
                          }
                />
                <LineItem authUser={authUser}
                          title={`Minimum invoice amount`}
                          nonEditableValue={<span>{ formatAmount(minInvoiceAmount) }</span>}
                          editableValue={
                              <InputAmount inputAmountValue={newMinInvoiceAmountInput}
                                           enabled={{
                                               updateInputValue: setNewMinInvoiceAmountInput
                                           }} />
                          }
                          editableValueButton={
                              !!minInvoiceAmountUpdate
                                  ? !minInvoiceAmountUpdate.errors // 0 result is not accepted with !!minInvoiceAmountUpdate.result
                                      ? <UpdateMinInvoiceAmountEnabled axios={axios}
                                                                       customerId={customerId}
                                                                       newMinInvoiceAmount={minInvoiceAmountUpdate.result!}
                                                                       kind={KIND_PRIMARY}
                                                                       size={SIZE_FIXED} />
                                      : <ShowValidationErrorsButton validationErrors={minInvoiceAmountUpdate.errors}
                                                                    kind={KIND_PRIMARY}
                                                                    size={SIZE_FIXED}
                                                                    text={UpdateText} />
                                  : <ButtonDisabled kind={KIND_PRIMARY}
                                                    size={SIZE_FIXED}
                                                    text={UpdateText} />
                          } />
                <LineItem authUser={authUser}
                          title={`Auto Approve Financing Requests`}
                          nonEditableValue={
                                <CheckBox internalId={autoApproveFinancingRequestsId}
                                          inputValue={autoApproveFinancingRequests}
                                          updateInputValue={null} />

                          }
                          editableValue={
                              <CheckBox internalId={autoApproveFinancingRequestsId}
                                        inputValue={newAutoApproveFinancingRequestsInput}
                                        updateInputValue={setNewAutoApproveFinancingRequestsInput} />
                          }
                          editableValueButton={
                                <UpdateAutoApproveFinancingRequests axios={axios}
                                                                    customerId={customerId}
                                                                    preValue={initialAutoApproveFinancingRequests}
                                                                    newValue={newAutoApproveFinancingRequestsInput}
                                                                    kind={KIND_PRIMARY}
                                                                    size={SIZE_FIXED} />
                          }
                />
            </tbody>
        </table>
    );
}

export default CustomerLimitsSection;
