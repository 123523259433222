import ILogger from "factor-lib/services/logger/ILogger";

// different from currentEnv, as does not rely on implementations
export default interface IFactorContext {
    baseUrl: string; // with hostname, and port (for Auth redirection)
    swanClientId: string;
    logger: ILogger;
}

let wrapper: IFactorContext | null = null;

export const setFactorContext = (baseUrl: string, swanClientId: string, logger: ILogger): void => {
    wrapper = ({
        baseUrl,
        swanClientId,
        logger
    });
}

const throwError = ():IFactorContext => {
    throw new Error('No context set');
}

export const getFactorContext = (): IFactorContext =>
    wrapper ?? throwError();;