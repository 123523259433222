export const SwanIncomingPaymentsQueryKey = ['swanIncomingPayments'];

export const SwanIncomingPaymentGraphQLFields =
    `
        id
        swanCreationDateTime
        executionDate
        type
        status
        creditorName
        creditedIban
        label
        reference
        creditedAmount
        currency
        archivedDateTime
        archivedComment
        noMatchDateTime
        noMatchFlaggedDateTime
        matchedAmount 
        matchingCustomer {
            id
            company {
                name
            }
            directSeller {
                id
            }
        }
    `;

export interface ISwanIncomingPayment {
    id: string;
    swanCreationDateTime: string;
    executionDate: string;
    type: string;
    status: string;
    creditorName: string;
    creditedIban: string;
    label: string;
    reference: string;
    creditedAmount: number;
    currency: string;
    archivedDateTime: string | null;
    archivedComment: string | null;
    noMatchDateTime: string | null;
    noMatchFlaggedDateTime: string | null;
    matchedAmount: number;
    matchingCustomer: {
        id: string;
        company: {
            name: string;
        };
        directSeller: {
            id: string;
        };
    } | null;
}

export interface ISwanIncomingPaymentMatch {
    swanIncomingPaymentId: string;
    invoiceId: string;
    isSentToCustomer: boolean;
    isComplete: boolean;
    amount: number;
}

export interface ISwanIncomingPaymentInvoice {
    id: string;
    number: string;
}

export interface ISwanIncomingPaymentsQueryResult {
    swanIncomingPayments: ISwanIncomingPayment[];
    swanIncomingPaymentMatches: ISwanIncomingPaymentMatch[];
    invoices: {
        base: ISwanIncomingPaymentInvoice[];
    };
}
