export const CustomerUrlCustomerIdParam = 'customerId';

export const CustomersUrl = '/platforms';
export const CustomerUrlMapping = `${CustomersUrl}/:${CustomerUrlCustomerIdParam}`;

export const customerUrl = (customerId: string) =>
    customerUrlTab(customerId, /* tab by default */CustomerInfosTabParam);
export const customerUrlTab = (customerId: string, tab: string) => `${CustomersUrl}/${customerId}/${tab}`;

export const CustomerInfosTabParam = 'info';
export const CustomerMarketplaceSellersTabParam = 'marketplace_sellers';

export const AddCustomerUrl = `${CustomersUrl}/add`;