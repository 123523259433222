import InputAmount, { formatAmountToInput, isValidNewAmountInput, parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import {useState} from "react";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {Axios} from "axios";
import {GlobalOutstandingsQueryKey, IGlobalOutstandingsQueryResult} from "./globalOutstandingsQuery";
import Button from "factor-lib/Buttons/Button";
import { computeText } from "factor-lib/Buttons/ButtonMutationEnabled";
import { InvoiceQueryKeyPrefix } from "../../../utils/InvoiceUtils";
import { formatAmountWoCents } from "factor-lib/utils/amountUtils";

const validDoubleConfirmationModal = (
    oldValue: number,
    newValueCandidate: number,
    modifyGlobalOutstandingMutation: UseMutationResult<void, any, number>
): void => {
    if (window.confirm(`Êtes vous sûr(e) de vouloir passer la limite de ${formatAmountWoCents(oldValue)} à ${formatAmountWoCents(newValueCandidate)} ?`)) {
        modifyGlobalOutstandingMutation.mutate(newValueCandidate);
    }
}

const ModifyGlobalOutstandingModalContent = (
    {
        closeModal,
        axios,
        currentLimit
    }: {
        closeModal: () => void;
        axios: Axios;
        currentLimit: number;
    }
) => {
    const queryClient = useQueryClient();

    const [limitInput, setNewLimitInput] = useState(formatAmountToInput(currentLimit));

    const modifyGlobalOutstandingMutation: UseMutationResult<void, any, number> =
        useMutation<void, any, number>(
            async (nv) => {
                (await axios.put<void>(
                    '/adminEligibility',
                    {
                        newGlobalOutstandingLimit /* Rename */: nv
                    }
                ));
            },
            ({
                onSuccess: (_, newValue) => {
                    queryClient.setQueryData<IGlobalOutstandingsQueryResult>(
                        GlobalOutstandingsQueryKey,
                        (oldValue: IGlobalOutstandingsQueryResult | undefined) => !!oldValue ? ({
                            ...oldValue,
                            globalOutstandings: {
                                ...oldValue.globalOutstandings,
                                limit: newValue
                            }
                        }) : undefined
                    );
                    // Invalidate all invoice queries to re-compute eligibilities
                    const r = queryClient.invalidateQueries(InvoiceQueryKeyPrefix);
                    closeModal();
                    return r;
                },
            })
        );

    return (
        <div className='p-padding-3 p-vertical-stretch'>
            <div className='p-horizontal-center'>
                <div className='p-bold p-margin-right-5'>
                    Nouvelle limite
                </div>
                <InputAmount inputAmountValue={limitInput}
                             enabled={{
                                 updateInputValue: setNewLimitInput,
                                 innerId: {
                                     value: 'newAmountId',
                                     autofocus: true
                                 },
                                 tryComplete: isValidNewAmountInput(currentLimit, limitInput)
                                     ? (() => validDoubleConfirmationModal(currentLimit, parseInputAmount(limitInput), modifyGlobalOutstandingMutation))
                                     : undefined
                             }} />
            </div>
            <div className='p-margin-top-3 p-vertical-center'>
                <Button text={computeText('Mettre à jour', modifyGlobalOutstandingMutation, true)}
                        isLoading={modifyGlobalOutstandingMutation.isLoading}
                        actionO={isValidNewAmountInput(currentLimit, limitInput)
                            ? (() => validDoubleConfirmationModal(currentLimit, parseInputAmount(limitInput), modifyGlobalOutstandingMutation))
                            : null
                        } />
            </div>
        </div>
    )
}

export default ModifyGlobalOutstandingModalContent;