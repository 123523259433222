import { IIntCompanyIdentifier, IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";

// Not specific to this query.
export const MainCustomerNamespace = 'customers';


export const CustomersQueryKeyPrefix = [MainCustomerNamespace, 'main', 'search'];

export const customersQueryKey = (search: string, customerTypeO?: string) => {
    let r = [...CustomersQueryKeyPrefix, 'search', search];
    if (!!customerTypeO) {
        r = [...r, 'type', customerTypeO];
    }
    return r;
};

export const customerGraphQLQueryFields =
    `
        id
        creationDateTime
        contactEmail
        company { name identifier { ${IntCompanyIdentifierGraphQLFields} } }
        marketplace { id }
    `;

export interface ICustomerQueryResult {
    id: string;
    creationDateTime: string;
    contactEmail: string;
    company: {
        name: string;
        identifier: IIntCompanyIdentifier;
    };
    marketplace: {
        id: string;
    } | null;
}
