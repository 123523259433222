import { useState } from "react";
import DeclareDirectRecuperation from "./DeclareDirectRecuperation";
import { ChoseRecuperationType } from "./ChoseRecuperationType";
import {Axios} from "axios";
import {IInvoiceDetails} from "../../../invoiceDetailsQuery";
import IRecupSelection from "./IRecupSelection";

const BuyerDeclareDirectRecuperationModalContent = (
    {
        axios,
        invoiceId,
        invoice,
        closeModal
    }: {
        axios: Axios;
        invoiceId: string;
        invoice: IInvoiceDetails;
        closeModal: () => void;
    }
) => {
    const [recuperationType, setRecuperationType] = useState<IRecupSelection | null> (null)

    const invoiceAmountTTC = invoice.amountWoTax + invoice.amountTax;
    const partialAmountPaid = invoice.payment.partialAmountPaid;

    return (
        <div className='p-padding-3'>
            { recuperationType !== null // !!paymentType does not display the form if paymentType == 0
                ? <DeclareDirectRecuperation axios={axios}
                                             invoiceId={invoiceId}
                                             invoiceAmountTTC={invoiceAmountTTC}
                                             partialAmountPaid={partialAmountPaid}
                                             directRecuperationType={recuperationType.directRecuperationType}
                                             extraAmountValidationMessageOProvider={(partialAmountInput: number | null) =>
                                                 recuperationType.extraValidationMessageOProvider(invoice, partialAmountInput)
                                             }
                                             closeModal={closeModal} />
                : <ChoseRecuperationType setRecuperationType={setRecuperationType}
                                         invoice={invoice}/>
            }
        </div>
    );
}

export default BuyerDeclareDirectRecuperationModalContent;
