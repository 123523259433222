import { comparingString } from "factor-lib/utils/comparingUtils";
import { Link } from "react-router-dom";
import {
    IBuyer,
    IBuyerEmail, IBuyerPhone,
    IBuyerEvent, IBuyerCompanyDetailsQueryResult
} from "../buyerCompanyDetailsQuery";
import PLink from "factor-lib/Link";
import { useState } from "react";
import { Axios } from "axios";
import IAuthUser from "../../../../IAuthUser";
import { sellerUrl } from "../../../Sellers/sellersUrls";
import { validatePhoneError } from "factor-lib/forms/Inputs/phoneUtils";
import { validateEmailError } from "factor-lib/forms/Inputs/emailUtils";
import BuyerContactUpdateModal from "./BuyerContactUpdateModal";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import AddBuyerModalContent from "./AddBuyerModalContent";

const getBuyerEventContactId = (be: IBuyerEvent): string | null =>
    !!be.email
        ? (be.email.id)
        : !!be.phone
            ? (be.phone.id)
            : null;


const BuyerContactsList = <T extends { id: string; },>(
    {
        buyerContacts,
        valueExtractor,
        showUpdateModal
    }: {
        buyerContacts: T[];
        valueExtractor: (e: T) => string;
        showUpdateModal: ((e: T) => void) | null;
    }
) =>
    <ul>
        { buyerContacts
            .sort(comparingString(valueExtractor))
            .map((buyerContact) => {
                return (
                    <li key={buyerContact.id}>
                        { valueExtractor(buyerContact) }
                        { !!showUpdateModal &&
                            <span>
                                &nbsp;[
                                <PLink id={`showUpdateBuyerContact${buyerContact.id}`}
                                       text='Modifier'
                                       action={{
                                           clickHandler: () => showUpdateModal(buyerContact)
                                       }} />
                                ]
                            </span>
                        }
                    </li>
                );
            })
        }
    </ul>;

const BuyerContactInfosSection = (
    {
        axios,
        authUser,
        buyerCompanyId,
        buyers,
        buyerEmails,
        buyerPhones
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        buyerCompanyId: string;
        buyers: IBuyer[];
        buyerEmails: IBuyerEmail[];
        buyerPhones: IBuyerPhone[];
    }
) => {
    const canUpdateContacts = !!authUser?.canManageProfiles; // TODO: confirm

    const [showUpdateBuyerContact, setShowUpdateBuyerContact] = useState<{
        buyerContactId: string;
        oldValue: string;
        validateNewValue: (newValue: string) => string | null;
        buyerContactEndpoint: (buyerContactId: string) => string;
        updateQuery: (old: IBuyerCompanyDetailsQueryResult, newValue: string) => IBuyerCompanyDetailsQueryResult;
        deleteUpdateQueryO: ((old: IBuyerCompanyDetailsQueryResult) => IBuyerCompanyDetailsQueryResult) | null;
    } | null>(null);

    return (
        <div>
            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <ButtonForModal text='Ajouter'
                                childModalContent={(closeModal) =>
                                    <AddBuyerModalContent axios={axios}
                                                          buyerCompanyId={buyerCompanyId}
                                                          sellers={Array.from(new Set(buyers.map((b) => ({
                                                              id: b.seller.id,
                                                              buyerId: b.id,
                                                              name: b.seller.company.name
                                                          }))))/* remove duplicates */}
                                                          closeModal={closeModal} />
                                }/>
            </div>
            <table className='table is-fullwidth'>
                <thead>
                <tr>
                    <th>Seller</th>
                    <th>Emails</th>
                    <th>Numéros de téléphone</th>
                </tr>
                </thead>
                <tbody>
                    { buyers
                        .sort(comparingString((buyer) => buyer.seller.company.name))
                        .map((buyer, index) =>
                            <tr key={`buyer-contact-${index}`}>
                                <td>
                                    { buyer.seller.company.name }
                                    &nbsp;[
                                    <Link id={`buyer-contact-${buyer.id}-seller-link`}
                                          to={sellerUrl(buyer.seller.id)}>
                                        lien
                                    </Link>
                                    ]
                                </td>
                                <td>
                                    <BuyerContactsList buyerContacts={buyerEmails.filter((be) => be.buyer.id === buyer.id)}
                                                       valueExtractor={(be) => be.value}
                                                       showUpdateModal={canUpdateContacts ? (bce) => setShowUpdateBuyerContact({
                                                           buyerContactId: bce.id,
                                                           oldValue: bce.value,
                                                           validateNewValue: validateEmailError,
                                                           buyerContactEndpoint: (buyerEmailId) => `email/${buyerEmailId}`,
                                                           updateQuery: (old: IBuyerCompanyDetailsQueryResult, newValue: string) => ({
                                                               ...old,
                                                               buyerEmails: old.buyerEmails.map((be) => be.id === bce.id
                                                                       ? ({
                                                                           ...be,
                                                                           value: newValue
                                                                       }) : be),
                                                               buyerEvents: old.buyerEvents.map((be) => getBuyerEventContactId(be) === bce.id
                                                                   ? ({
                                                                       ...be,
                                                                       contactEmail: !!be.email ? {
                                                                           id: be.email.id,
                                                                           value: newValue
                                                                       } : null
                                                                   }) : be)
                                                           }),
                                                           deleteUpdateQueryO: (bce.nbEvents === 0 && bce.nbInvoices === 0)
                                                               ? (old: IBuyerCompanyDetailsQueryResult) => ({
                                                                   ...old,
                                                                   buyerEmails: old.buyerEmails.filter((be) => be.id !== bce.id)
                                                               }) : null
                                                       }) : null} />
                                </td>
                                <td>
                                    <BuyerContactsList buyerContacts={buyerPhones.filter((bp) => bp.buyer.id === buyer.id)}
                                                       valueExtractor={(bp) => bp.value}
                                                       showUpdateModal={canUpdateContacts ? (bcp) => setShowUpdateBuyerContact({
                                                           buyerContactId: bcp.id,
                                                           oldValue: bcp.value,
                                                           validateNewValue: validatePhoneError,
                                                           buyerContactEndpoint: (buyerPhoneId) => `phone/${buyerPhoneId}`,
                                                           updateQuery: (old: IBuyerCompanyDetailsQueryResult, newValue: string) => ({
                                                               ...old,
                                                               buyerPhones: old.buyerPhones.map((bp) => bp.id === bcp.id ? ({
                                                                   ...bp,
                                                                   value: newValue
                                                               }) : bp)  ,
                                                               buyerEvents: old.buyerEvents.map((be) => getBuyerEventContactId(be) === bcp.id
                                                                   ? ({
                                                                       ...be,
                                                                       contactPhone: !!be.phone ? {
                                                                           id: be.phone.id,
                                                                           value: newValue
                                                                       } : null
                                                                   }) : be )
                                                           }),
                                                           deleteUpdateQueryO: (bcp.nbEvents === 0 && bcp.nbInvoices === 0)
                                                               ? (old: IBuyerCompanyDetailsQueryResult) => ({
                                                                   ...old,
                                                                   buyerPhones: old.buyerPhones.filter((bp) => bp.id !== bcp.id)
                                                               }) : null
                                                       }) : null} />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>

            { !!showUpdateBuyerContact &&
                <BuyerContactUpdateModal axios={axios}
                                         buyerCompanyId={buyerCompanyId}
                                         buyerContactId={showUpdateBuyerContact.buyerContactId}
                                         oldValue={showUpdateBuyerContact.oldValue}
                                         validateNewValue={showUpdateBuyerContact.validateNewValue}
                                         buyerContactEndpoint={showUpdateBuyerContact.buyerContactEndpoint}
                                         updateQuery={showUpdateBuyerContact.updateQuery}
                                         deleteUpdateQueryO={showUpdateBuyerContact.deleteUpdateQueryO}
                                         closeModal={() => setShowUpdateBuyerContact(null)} />
            }
        </div>
    );
}

export default BuyerContactInfosSection;
