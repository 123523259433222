import { Link } from "react-router-dom";
import IAccessToken from "../../IAccessToken";
import IAuthUserLoad from "../../IAuthUserLoad";
import { comparingRev } from "factor-lib/utils/comparingUtils";
import { comparingDate } from "factor-lib/utils/dateUtils";
import {
    computeCustomerFinancingRequestStatusNonFinancedNonRejected
} from "../../Pages/Invoices/invoiceStatus";
import IOption from "factor-lib/forms/Select/IOption";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import {ReactNode, useState} from "react";
import CheckBox from "factor-lib/forms/CheckBox";
import FinancingRequestsBatchUpdate from "./FinancingRequestBatchUpdate";
import {
    getFinancingRequestsGraphQLQuery,
    ICustomerFinancingRequest, ICustomerFinancingRequestNonAcceptedNorRejected,
    ICustomerFinancingRequestsResponse
} from "./FinancingRequestsQuery";
import Loader from "factor-lib/Loader";
import { serverDateDeserialization } from "factor-lib/utils/dateUtils";
import {comparingMultiple} from "../comparingUtils";
import GraphQLQueryWithAccessTokenWrapper from "../../GraphQLQueryWithAccessTokenWrapper";
import {filterStrInvoice} from "../InvoiceUtils";
import {invoiceUrl} from "../../Pages/Invoices/invoicesUrls";
import { sellerUrl } from "../../Pages/Sellers/sellersUrls";
import { buyerUrl } from "../../Pages/Buyers/buyersUrls";
import {formatAdminDateStr} from "../dateTimeUtils";
import {formatCompany} from "../companyUtils";
import {formatAmount} from "factor-lib/utils/amountUtils";
import IBaseInvoice, {
    buildBuyerCompaniesMap,
    buildSellersMap,
    IBaseInvoiceSellerWithCompany,
    IInvoiceBuyerCompany
} from "../IInvoice";

export const compareFinancingRequestDate =
    comparingMultiple<ICustomerFinancingRequest>(
        [
            comparingRev(
                comparingDate((customerFinancingRequest: ICustomerFinancingRequest) => serverDateDeserialization(customerFinancingRequest.creationDateTime))
            ),
            // Add id, to have something deterministic, otherwise order by change
            (c, d) => c.id.localeCompare(d.id)
        ]
    );

const FinancingRequestRow = (
    {
        financingRequest,
        isSelected,
        seller,
        buyerCompany,
        setSelectedInvoiceId
    }: {
        financingRequest: ICustomerFinancingRequest,
        isSelected: boolean;
        seller: IBaseInvoiceSellerWithCompany;
        buyerCompany: IInvoiceBuyerCompany;
        setSelectedInvoiceId: (invoiceId: string) => void;
    }
) => {
    const { invoice } = financingRequest;

    return (
        <tr>
            <td>
                <CheckBox inputValue={isSelected}
                          internalId={`select-invoice-${invoice.id}`}
                          updateInputValue={() => {
                              setSelectedInvoiceId(invoice.id)
                          }}
                />
            </td>
            <td>
                <Link to={invoiceUrl(invoice.id)}>
                    { invoice.number }
                </Link>
            </td>
            <td>
                { formatAdminDateStr(financingRequest.creationDateTime) }
            </td>
            <td>
                { !!invoice.payment.completePaidDate
                    ? 'DEJA PAYEE !'
                    : computeCustomerFinancingRequestStatusNonFinancedNonRejected(financingRequest)
                }
            </td>
            <td>
                { formatAdminDateStr(financingRequest.expirationDate) }
            </td>
            <td>
                <Link to={sellerUrl(seller.id)}>
                    { formatCompany(seller.company) }
                </Link>
            </td>
            <td>
                <Link to={buyerUrl(buyerCompany.id, false)}>
                    { formatCompany(buyerCompany) }
                </Link>
            </td>
            <td>
                { formatAdminDateStr(invoice.dueDate) }
            </td>
            <td>
                { formatAmount(invoice.amountWoTax + invoice.amountTax) }
            </td>
        </tr>
    )
};

const FinancingRequestsTableLoaded = (
    {
        className,
        accessToken,
        selectedFilter,
        setSelectedFilter,
        financingRequests,
        filter,
        sellersById,
        buyerCompaniesById,
        searchStrFilterO
    }: {
        className?: string;
        accessToken: IAccessToken;
        selectedFilter: IOption<(financingRequest: ICustomerFinancingRequestNonAcceptedNorRejected) => boolean> | null;
        setSelectedFilter: (newOption: IOption<(financingRequest: ICustomerFinancingRequestNonAcceptedNorRejected) => boolean> | null) => void;
        financingRequests: ICustomerFinancingRequestNonAcceptedNorRejected[];
        filter: ((financingRequest: ICustomerFinancingRequestNonAcceptedNorRejected) => boolean) | null;
        sellersById: Map<string, IBaseInvoiceSellerWithCompany>;
        buyerCompaniesById: Map<string, IInvoiceBuyerCompany>;
        searchStrFilterO: string | null;
    }
) => {
    const [ selectedInvoicesIds, setSelectedInvoicesIds ] = useState<string[]>([]);
    const selectedCustomerFinancingRequestsInvoicesIdsSet = new Set<string>(selectedInvoicesIds);

    let filteredFinancingRequests = financingRequests;
    if (!!filter) {
        filteredFinancingRequests = filteredFinancingRequests.filter(filter);
    }

    let financingsRequestsWithSellerAndBuyerCompaniesFiltered =
        filteredFinancingRequests
            .map((financingRequest) => ({
                financingRequest,
                seller: sellersById.get(financingRequest.invoice.sellerId)!,
                buyerCompany: buyerCompaniesById.get(financingRequest.invoice.buyer.companyId)!
            }));

    if (!!searchStrFilterO) {
        const searchStrFilterLower = searchStrFilterO.toLowerCase();
        financingsRequestsWithSellerAndBuyerCompaniesFiltered =
            financingsRequestsWithSellerAndBuyerCompaniesFiltered.filter((f) => filterStrInvoice(f.financingRequest.invoice.number, f.seller, f.buyerCompany, searchStrFilterLower))
    }

    return (
        <FinancingRequestsTemplate className={className}
                                   accessTokenO={accessToken}
                                   selectedFilter={selectedFilter}
                                   setSelectedFilter={setSelectedFilter}
                                   selectedInvoicesO={financingRequests
                                       .filter((c) => selectedCustomerFinancingRequestsInvoicesIdsSet.has(c.invoice.id))
                                       .map((c) => c.invoice)
                                   }>
            <tbody>
                { financingsRequestsWithSellerAndBuyerCompaniesFiltered
                    .sort((f, g) => compareFinancingRequestDate(f.financingRequest, g.financingRequest))
                    .map((f, index) =>
                        <FinancingRequestRow key={`financing-request-${index}`}
                                             financingRequest={f.financingRequest}
                                             isSelected={selectedCustomerFinancingRequestsInvoicesIdsSet.has(f.financingRequest.invoice.id)}
                                             seller={f.seller}
                                             buyerCompany={f.buyerCompany}
                                             setSelectedInvoiceId={(invoiceId) =>
                                                 setSelectedInvoicesIds(
                                                     !selectedCustomerFinancingRequestsInvoicesIdsSet.has(invoiceId)
                                                         ? [...selectedInvoicesIds, invoiceId]
                                                         : selectedInvoicesIds.filter((i) => i !== invoiceId)
                                                 )
                                             } />
                    )
                }
            </tbody>
        </FinancingRequestsTemplate>
    )
}

const onboardingBuyerNeeded=
    (financingRequest: ICustomerFinancingRequest): boolean =>
        financingRequest.invoice.opsTags.onboardingBuyerNeeded
        && !financingRequest.invoice.opsTags.onboardingBuyerOkEmail
        && !financingRequest.invoice.opsTags.onboardingBuyerOkPhone;

const runningCheckBuyerNeeded =
    (financingRequest: ICustomerFinancingRequest): boolean =>
        financingRequest.invoice.opsTags.runningCheckBuyerNeeded
        && !financingRequest.invoice.opsTags.runningCheckOkEmail
        && !financingRequest.invoice.opsTags.runningCheckOkPhone;

export const financingRequestsFilterOptions: Array<IOption<(financingRequest: ICustomerFinancingRequestNonAcceptedNorRejected) => boolean>> = [
    {
        label: 'Pending Ops Decision (all)',
        value: () =>
            true
    },
    {
        label: 'Onboarding buyer todo',
        value: onboardingBuyerNeeded
    },
    {
        label: 'Running check todo',
        value: runningCheckBuyerNeeded
    }
];

const FinancingRequestsTemplate = (
    {
        className,
        accessTokenO,
        selectedFilter,
        setSelectedFilter,
        selectedInvoicesO,
        children
    }: {
        className?: string;
        accessTokenO: IAccessToken | null;
        selectedFilter: IOption<(financingRequest: ICustomerFinancingRequestNonAcceptedNorRejected) => boolean> | null;
        setSelectedFilter: (newOption: IOption<(financingRequest: ICustomerFinancingRequestNonAcceptedNorRejected) => boolean> | null) => void;
        selectedInvoicesO: IBaseInvoice[] | null;
        children: ReactNode;
    }
) =>
    <div className={className}>
        <div className='level'>
            <div className='p-margin-bottom-5 column is-one-quarter'>
                <SingleSelect options={financingRequestsFilterOptions}
                              selectedOption={selectedFilter}
                              selectOption={setSelectedFilter}
                              placeholder='Filtrer'
                              clearable={true} />
            </div>
            <div className='p-margin-right-4'>
                <FinancingRequestsBatchUpdate accessTokenO={accessTokenO}
                                              selectedInvoicesO={selectedInvoicesO} />
            </div>
        </div>
        <table className='table is-striped is-bordered is-hoverable is-fullwidth'>
            <thead>
                <tr>
                    <th></th>
                    <th>N° de facture</th>
                    <th>Date de demande</th>
                    <th>Status</th>
                    <th>Date d'expiration</th>
                    <th>Seller</th>
                    <th>Buyer</th>
                    <th>Date d'échéance</th>
                    <th>Montant TTC</th>
                </tr>
            </thead>
            { children }
        </table>
    </div>;

export interface INewFinancingRequestsQueryParams {
    sellerId: string | null;
    buyerCompanyId: string | null;
}

const NewFinancingRequestsTable = (
    {
        className,
        accessTokenO,
        queryKey,
        queryParam,

        selectedFilter,
        setSelectedFilter,

        searchStrFilterO
    }: {
        className?: string;
        accessTokenO: IAccessToken | null;
        queryKey: string[];
        queryParam: INewFinancingRequestsQueryParams;

        selectedFilter: IOption<(financingRequest: ICustomerFinancingRequestNonAcceptedNorRejected) => boolean> | null;
        setSelectedFilter: (newOption: IOption<(financingRequest: ICustomerFinancingRequestNonAcceptedNorRejected) => boolean> | null) => void;

        searchStrFilterO: string | null;
    }
) =>
    <GraphQLQueryWithAccessTokenWrapper accessTokenO={accessTokenO}
                                        queryKey={queryKey}
                                        queryParams={{ query: getFinancingRequestsGraphQLQuery(queryParam.sellerId, queryParam.buyerCompanyId, false) }}
                                        onLoading={() =>
                                            <FinancingRequestsTemplate className={className}
                                                                       accessTokenO={accessTokenO}
                                                                       selectedFilter={selectedFilter}
                                                                       setSelectedFilter={setSelectedFilter}
                                                                       selectedInvoicesO={null}>
                                                <tbody>
                                                    <tr>
                                                        <td/>
                                                        <td/>
                                                        <td/>
                                                        <td/>
                                                        <td><Loader /></td>
                                                        <td/>
                                                        <td/>
                                                        <td/>
                                                        <td/>
                                                    </tr>
                                                </tbody>
                                            </FinancingRequestsTemplate>
                                        }
                                        onSuccess={(r: IAuthUserLoad<ICustomerFinancingRequestsResponse<null>>) =>
                                            <FinancingRequestsTableLoaded className={className}
                                                                          accessToken={r}
                                                                          selectedFilter={selectedFilter}
                                                                          setSelectedFilter={setSelectedFilter}
                                                                          financingRequests={r.value.financingRequests.base}
                                                                          filter={selectedFilter?.value ?? null}
                                                                          sellersById={buildSellersMap(r.value.financingRequests.sellers)}
                                                                          buyerCompaniesById={buildBuyerCompaniesMap(r.value.financingRequests.buyerCompanies)}
                                                                          searchStrFilterO={searchStrFilterO}/>
                                        } />;

export default NewFinancingRequestsTable;
