import IAccessToken from "../IAccessToken";
import {ReactElement} from "react";
import Loader from "factor-lib/Loader";

const WithAccessTokenWaiting = (
    {
        accessTokenO,
        child
    }: {
        accessTokenO: IAccessToken | null;
        child: (accessToken: IAccessToken) => ReactElement;
    }
) =>
    !!accessTokenO
        ? child(accessTokenO)
        : <Loader/>;

export default WithAccessTokenWaiting;