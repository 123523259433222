import GraphQLQueryWrapper from "../../../../utils/GraphQLQueryWrapper";
import {Axios} from "axios";
import Loader from "factor-lib/Loader";
import {ActiveFinancingsQueryKeyPrefix} from "../../../../utils/Financing/financingsQuery";
import BuyerMerciFacteurMailFinancingsSelectorLoaded from "./BuyerMerciFacteurMailFinancingsSelectorLoaded";

const BuyerMerciFacteurMailFinancingsSelector = (
    {
        axios,
        generateDocUrl,
        mailLabel,
        mailType,
        closeModal,
        buyerCompanyId,
        sellerId
    }: {
        axios: Axios;
        generateDocUrl: string;
        mailLabel: string;
        mailType: number;
        closeModal: () => void;
        buyerCompanyId: string;
        sellerId: string;
    }
) =>
    <GraphQLQueryWrapper queryKey={[...ActiveFinancingsQueryKeyPrefix, 'seller', sellerId, 'buyerCompanyId', buyerCompanyId, 'base']}
                         axios={axios}
                         queryParams={{
                             query: `query Q($buyerCompanyId: Guid!, $sellerId: Guid!) {
                                    financings (buyerCompanyId: $buyerCompanyId, sellerId: $sellerId, isActive: true) {
                                        base {
                                            id
                                            invoice {
                                                id
                                                number
                                                amountWoTax
                                                amountTax
                                            }
                                        }
                                    }
                                 }`,
                             variables: {
                                 buyerCompanyId,
                                 sellerId
                             }
                         }}
                         onLoading={() => <Loader />}
                         onSuccess={(r: {
                             financings: {
                                 base: Array<{
                                     id: string;
                                     invoice: {
                                         id: string;
                                         number: string;
                                         amountWoTax: number;
                                         amountTax: number;
                                     };
                                 }>;
                             }
                         }) =>
                             <BuyerMerciFacteurMailFinancingsSelectorLoaded axios={axios}
                                                                            generateDocUrl={generateDocUrl}
                                                                            mailLabel={mailLabel}
                                                                            mailType={mailType}
                                                                            closeModal={closeModal}
                                                                            buyerCompanyId={buyerCompanyId}
                                                                            sellerId={sellerId}
                                                                            financings={r.financings.base.map((p) => ({
                                                                                id: p.id,
                                                                                invoice: {
                                                                                    number: p.invoice.number,
                                                                                    id: p.invoice.id,
                                                                                    amountWoTax: p.invoice.amountWoTax,
                                                                                    amountTax: p.invoice.amountTax
                                                                                }
                                                                            }))}/>
                         } />;

export default BuyerMerciFacteurMailFinancingsSelector;
