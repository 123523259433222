import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import Button from "factor-lib/Buttons/Button";
import { financeSwanAction } from "../../../utils/Financing/financingPaymentUtils";
import { useQueryClient } from "@tanstack/react-query";
import { ICustomerFinancingRequestAccepted } from "../../../utils/FinancingRequest/FinancingRequestsQuery";
import ILoadedSelected from "./ILoadedSelected";
import ButtonWithConfirmationModal from "factor-lib/Buttons/ButtonWithConfirmationModal";
import { getInvoiceAmountToFinance } from "../../../utils/Financing/financingUtils";
import { getInstantTransactionNotPossibleReason } from "../../../utils/swanUtils";
import {
    IBaseInvoiceSellerWithCompanyAndSwanInstantPayment
} from "../../../utils/FinancingRequest/IBaseInvoiceSellerWithCompanyAndSwanInstantPayment";
import {IInvoiceBuyerCompany} from "../../../utils/IInvoice";
import IFactorContext, {getFactorContext} from "../../../IFactorContext";


const ButtonText = 'Payer avec Swan';

const PendingPaymentsPayWithSwanEnabled = (
    {
        financingRequests,
        sellers,
        buyerCompanies
    }: {
        financingRequests: ICustomerFinancingRequestAccepted[];
        sellers: IBaseInvoiceSellerWithCompanyAndSwanInstantPayment[];
        buyerCompanies: IInvoiceBuyerCompany[];
    }
) => {
    const queryClient = useQueryClient();

    const factorContext: IFactorContext = getFactorContext();

    const action = () => financeSwanAction(
        factorContext.baseUrl,
        factorContext.swanClientId,
        queryClient,
        financingRequests,
        sellers,
        buyerCompanies
    );

    const sellersMap = new Map<string, IBaseInvoiceSellerWithCompanyAndSwanInstantPayment>(sellers.map((s) => [s.id, s]));

    const nonInstantPaymentsInvoices = financingRequests
        .map((f) => ({
            number: f.invoice.number,
            instantPaymentNotPossibleReason: getInstantTransactionNotPossibleReason(
                sellersMap.get(f.invoice.sellerId)!.swanInstantPayment,
                getInvoiceAmountToFinance(f.invoice)
            )
        }))
        .filter((f) => f.instantPaymentNotPossibleReason !== null);

    return (
        nonInstantPaymentsInvoices.length > 0
            ? <ButtonWithConfirmationModal id='swanPaymentRedirectConfirmationButton'
                                           text={ButtonText}
                                           confirmationBody={
                                               <div>
                                                   Les factures suivantes seront payées avec un virement <span className='p-bold' style={{color: 'orange'}}>non instantané</span> :
                                                   <div>
                                                        { nonInstantPaymentsInvoices.map((i) => `${i.number} (${i.instantPaymentNotPossibleReason})`).join(', ') }
                                                   </div>
                                               </div>
                                           }
                                           action={action} />
            : <Button id='swanPaymentRedirectButton'
                      text={ButtonText}
                      isLoading={false}
                      actionO={action} />

    );
}

const PendingPaymentsPayWithSwanLoaded = (
    {
        loaded
    }: {
        loaded: ILoadedSelected;
    }
) => {
    const financingRequests = loaded.selectedCustomerFinancingRequests;

    return (
        financingRequests.length > 0
            ? <PendingPaymentsPayWithSwanEnabled financingRequests={financingRequests}
                                                 sellers={loaded.sellers}
                                                 buyerCompanies={loaded.buyerCompanies} />
            : <ButtonDisabled kind={KIND_PRIMARY}
                              size={SIZE_FIXED}
                              text={ButtonText} />
    );
}

const PendingPaymentsPayWithSwan = (
    {
        className,
        loadedO
    }: {
        className?: string;
        loadedO: ILoadedSelected | null;
    }
) =>
    <div className={className}>
        { !!loadedO
            ? <PendingPaymentsPayWithSwanLoaded loaded={loadedO} />
            : <ButtonDisabled kind={KIND_PRIMARY}
                              size={SIZE_FIXED}
                              text={ButtonText} />
        }
    </div>;

export default PendingPaymentsPayWithSwan;
