import Modal from "factor-lib/Modal";

const DefaultHeaderText = 'Le formulaire contient une ou plusieurs erreurs';

export const ValidationErrorModalContent = (
    {
        headerText,
        validationErrors,
    }: {
        headerText?: string;
        validationErrors: string[];
    }
) =>
    <div className='p-padding-5 p-vertical-center'>
                                <span className='p-bold p-margin-bottom-5'>
                                    { headerText === undefined ? DefaultHeaderText : headerText }
                                </span>
        <ul>
            { validationErrors.map((validationError, i) =>
                <li key={`validationError-${i}`}>{ validationError }</li>
            ) }
        </ul>
    </div>;


const ValidationErrorModal = (
    {
        headerText,
        validationErrors,
        close
    }: {
        headerText?: string;
        validationErrors: string[];
        close: () => void;
    }
) =>
    <Modal id='validationErrorsModal'
           maxWidth='500px'
           fullMaxWidth={false}
           active={true}
           close={close}>
        <ValidationErrorModalContent headerText={headerText}
                                     validationErrors={validationErrors} />
    </Modal>;


export default ValidationErrorModal;