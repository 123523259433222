import FinancingsTable, { financingsFilterOptions } from "../../utils/Financing/FinancingsTable";
import IAccessToken from "../../IAccessToken";
import useUrlQuery from "../../utils/useUrlQuery";
import { NavigateFunction } from "react-router-dom";
import { useContext } from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { invoicesUrlTab } from "./invoicesUrls";
import { ActiveFinancingsQueryKeyPrefix } from "../../utils/Financing/financingsQuery";


const ALL_FINANCINGS_FILTER_QUERY_PARAM_NAME = 'filter';

export const financingsUrlParam = 'financings';

const financingsAndFilterRoute = (filterIndex: number | null) =>
    `${invoicesUrlTab(financingsUrlParam)}${filterIndex !== null ? `?${ALL_FINANCINGS_FILTER_QUERY_PARAM_NAME}=${filterIndex}` : ''}`;

export const AllActiveFinancingsQueryKey = [...ActiveFinancingsQueryKeyPrefix, 'all'];

const FinancingsTab = (
    {
        className,
        searchStrFilter,
        accessTokenO
    }: {
        className?: string;
        accessTokenO: IAccessToken | null;
        searchStrFilter: string;
    }
) => {
    const query = useUrlQuery();
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    const statusFilterQueryParam: string | null = query.get(ALL_FINANCINGS_FILTER_QUERY_PARAM_NAME);
    const statusFilterIndexO: number | null = !!statusFilterQueryParam ? parseInt(statusFilterQueryParam) : null;

    // Ideally, we could already 'start' the queries (before the redirect), but ok for now
    return (
        <FinancingsTable className={className}
                         accessTokenO={accessTokenO}
                         queryKey={AllActiveFinancingsQueryKey}
                         queryParam={{ sellerId: null, buyerCompanyId: null }}
                         selectedFilter={statusFilterIndexO !== null ? financingsFilterOptions[statusFilterIndexO] : null}
                         setSelectedFilter={(newOption) => navigate(financingsAndFilterRoute(!!newOption ? financingsFilterOptions.findIndex((o) => o.label === newOption.label) : null))}
                         searchStrFilterO={searchStrFilter}/>
    )
}

export default FinancingsTab;
