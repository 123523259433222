import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import BuyerLimitsUploadModalContent from "./BuyerLimitsUploadModalContent";
import {Axios} from "axios";
import {useState} from "react";
import {ICompanyIdentifierWithCurrentLimitAndName} from "./BuyerLimitsBatchUpdateLoaded";
import { promiseWrapper } from "factor-lib/utils/utils";
import TextOnlyModal from "factor-lib/Modals/TextOnlyModal";
import {formatIntCompanyIdentifier, IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";

const BuyerBatchLimits = (
    {
        className,
        axiosO
    }: {
        className?: string;
        axiosO: Axios | null;
    }
) => {

    const [notUpdatedCompanies, setNotUpdatedCompanies] = useState<ICompanyIdentifierWithCurrentLimitAndName[] | null >(null);
    const [displayInvalidIdentifiers, setDisplayInvalidIdentifiers] = useState<IIntCompanyIdentifier[] | null>(null);

    return <div className={className}>
        <ButtonForModal id='buyerBatchLimits'
                        text='Ajouter un batch de limites'
                        modalMaxWidth={null}
                        modalFullMaxWidth={true}
                        childModalContent={(closeModal) =>
                            <BuyerLimitsUploadModalContent axiosO={axiosO}
                                                           closeModal={closeModal}
                                                           setNonUpdatedCompanies={setNotUpdatedCompanies}
                                                           displayErrors={setDisplayInvalidIdentifiers} />
                        } />
        {!!notUpdatedCompanies &&
            <TextOnlyModal message={`Les identifiants suivants n'ont pas pu être modifiés : ${notUpdatedCompanies.map(c => c.identifier.value).join(',')}`}
                           buttonsProps={{
                               id: 'notUpdatedCompanies',
                               text: 'Ok',
                               actionO: promiseWrapper(() => setNotUpdatedCompanies(null)),
                               isLoading: false
                           }}
                           close={() => setNotUpdatedCompanies(null)}
                           maxWidth={null}
                           fullMaxWidth={false} />
        }
        { !!displayInvalidIdentifiers && displayInvalidIdentifiers.length > 0 &&
            <TextOnlyModal message={`Les sirens suivants n'ont pas pu être ajoutés : ${displayInvalidIdentifiers.map(formatIntCompanyIdentifier).join(',')}`}
                           buttonsProps={{
                               id: 'invalidSirensIds',
                               text: 'Ok',
                               actionO: promiseWrapper(() => setDisplayInvalidIdentifiers(null)),
                               isLoading: false
                           }}
                           close={() => setDisplayInvalidIdentifiers(null)}
                           maxWidth={null}
                           fullMaxWidth={false}/>
        }
    </div>
}

export default BuyerBatchLimits;