import IAccessToken from "../../../IAccessToken";
import IAuthUserLoad from "../../../IAuthUserLoad";

import GraphQLWithAccessTokenWrapper from "../../../GraphQLQueryWithAccessTokenWrapper";
import {
    FinancingRequestsQueryKeyPrefix,
    getFinancingRequestsGraphQLQuery,
    IAccepted,
    ICustomerFinancingRequestsResponse
} from "../../../utils/FinancingRequest/FinancingRequestsQuery";
import Loader from "factor-lib/Loader";
import {
    buildBuyerCompaniesMap,
    buildSellersMap
} from "../../../utils/IInvoice";
import PendingPaymentsTemplate from "./PendingPaymentsTemplate";
import PendingPaymentsTableLoaded from "./PendingPaymentsTableLoaded";

export const AcceptedFinancingRequestsQueryKey = [...FinancingRequestsQueryKeyPrefix, 'accepted'];

const PendingPaymentsTable = (
    {
        className,
        accessTokenO,
        searchStrFilter
    }: {
        className?: string;
        accessTokenO: IAccessToken | null;
        searchStrFilter: string;
    }
) =>
    <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                   queryKey={AcceptedFinancingRequestsQueryKey}
                                   queryParams={{ query: getFinancingRequestsGraphQLQuery(null, null, true) }}
                                   onLoading={() =>
                                       <PendingPaymentsTemplate className={className}
                                                                accessTokenO={accessTokenO}
                                                                loadedO={null} >
                                           <tbody>
                                               <tr>
                                                   <td/>
                                                   <td/>
                                                   <td/>
                                                   <td><Loader /></td>
                                                   <td/>
                                                   <td/>
                                                   <td/>
                                               </tr>
                                           </tbody>
                                       </PendingPaymentsTemplate>
                                   }
                                   onSuccess={(r: IAuthUserLoad<ICustomerFinancingRequestsResponse<IAccepted>>) =>
                                       <PendingPaymentsTableLoaded className={className}
                                                                   accessToken={r}
                                                                   pendingPayments={r.value.financingRequests.base}
                                                                   sellers={r.value.financingRequests.sellers}
                                                                   buyerCompanies={r.value.financingRequests.buyerCompanies}
                                                                   sellersById={buildSellersMap(r.value.financingRequests.sellers)}
                                                                   buyerCompaniesById={buildBuyerCompaniesMap(r.value.financingRequests.buyerCompanies)}
                                                                   searchStrFilter={searchStrFilter}/>
                                   } />;

export default PendingPaymentsTable;
