import { ISwanIncomingPayment, ISwanIncomingPaymentsQueryResult, SwanIncomingPaymentGraphQLFields, SwanIncomingPaymentsQueryKey } from "./swanIncomingPaymentsQuery";
import Loader from "factor-lib/Loader";
import SwanIncomingPaymentsTableLoaded from "./SwanIncomingPaymentsTableLoaded";
import IAccessToken from "../../IAccessToken";
import GraphQLWithAccessTokenWrapper from "../../GraphQLQueryWithAccessTokenWrapper";
import IAuthUserLoad from "../../IAuthUserLoad";
import { IPendingOnly } from "./SwanIncomingPaymentsPage";

const SwanIncomingPaymentsTable = (
    {
        className,
        accessTokenO,
        searchInput,
        showPendingOnly,
        setSwanIncomingPaymentsLoaded
    }: {
        className?: string;
        accessTokenO: IAccessToken | null;
        searchInput: string;
        showPendingOnly: IPendingOnly | null;
        setSwanIncomingPaymentsLoaded: (s: ISwanIncomingPayment[]) => void;
    }
) =>
    <div className={`table-container${!!className ? ` ${className}` : ''}`}>
        <table className='table is-narrow is-bordered is-striped is-hoverable is-fullwidth'>
            <thead>
                <tr>
                    <th>Transaction ID</th>
                    <th>Date</th>
                    <th>NM</th>
                    <th>Montant</th>
                    <th>Créditeur</th>
                    <th>Customer</th>
                    <th>Libellé</th>
                    <th>Référence</th>
                    <th>Factures Matchés</th>
                    <th>Type et Statut</th>
                </tr>
            </thead>

            <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                           queryKey={SwanIncomingPaymentsQueryKey}
                                           queryParams={{
                                               query:
                                                   `query {
                                                       swanIncomingPayments {
                                                           ${SwanIncomingPaymentGraphQLFields}
                                                       }
                                                       swanIncomingPaymentMatches {
                                                           swanIncomingPaymentId
                                                           invoiceId
                                                           isSentToCustomer
                                                           isComplete
                                                           amount
                                                       }
                                                       invoices (isMatchedToSwanIncomingPayment: true) {
                                                           base {
                                                               id
                                                               number
                                                           }
                                                       }
                                                   }`
                                           }}
                                           onLoading={() =>
                                               <tbody>
                                                   <tr>
                                                       <td/>
                                                       <td/>
                                                       <td/>
                                                       <td/>
                                                       <td><Loader /></td>
                                                       <td/>
                                                       <td/>
                                                       <td/>
                                                       <td/>
                                                       <td/>
                                                   </tr>
                                               </tbody>
                                           }
                                           onSuccess={(r: IAuthUserLoad<ISwanIncomingPaymentsQueryResult>) =>
                                               <SwanIncomingPaymentsTableLoaded swanIncomingPayments={r.value.swanIncomingPayments}
                                                                                swanIncomingPaymentInvoices={r.value.invoices.base}
                                                                                swanIncomingPaymentMatches={r.value.swanIncomingPaymentMatches}
                                                                                searchInput={searchInput}
                                                                                showPendingOnly={showPendingOnly}
                                                                                setSwanIncomingPaymentsLoaded={setSwanIncomingPaymentsLoaded} />
                                           } />
        </table>
    </div>;

export default SwanIncomingPaymentsTable;