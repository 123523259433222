import IAccessToken from "../../IAccessToken";
import {NavigateFunction} from "react-router-dom";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import dataLoader from "factor-lib/dataLoader/dataLoader";
import {getFactorContext} from "../../IFactorContext";
import CheckBox from "factor-lib/forms/CheckBox";
import graphQLBatchLoadFunction from "factor-lib/dataLoader/graphQLBatchLoadFunctionAsync";
import DataLoader from "dataloader";
import IAccessTokenWithDataLoader from "./IAccessTokenWithDataLoader";
import WithListWithSearchLayoutPage from "../../utils/WithListWithSearchLayoutPage";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import {
    AddBuyerUrl, buyerUrlTab,
    WithBuyerCompanyIdParamWrapper, WithBuyerTabParamWrapper,
    WithIncludeBuyersWithoutInvoiceQueryParamWrapper
} from "./buyersUrls";
import {useContext} from "react";
import WithSearchList from "../../utils/WithSearchList";
import BuyersListAxiosReady from "./BuyersListAxiosReady";
import Loader from "factor-lib/Loader";
import DefaultInput from 'factor-lib/forms/Inputs/DefaultInput';

const BuyersPage = (
    {
        accessTokenO
    }: {
        accessTokenO: IAccessToken | null;
    }
) => {

    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    const graphQLDataLoaderO: DataLoader<IGraphQLQueryWithKeyAndParams, any> | null = !!accessTokenO
        ? dataLoader<IGraphQLQueryWithKeyAndParams, any>(
            (graphQLQueries: ReadonlyArray<IGraphQLQueryWithKeyAndParams>) => graphQLBatchLoadFunction(
                accessTokenO.axios,
                getFactorContext().logger,
                graphQLQueries
            )
        ) : null;

    const accessTokenWithDataLoaderO: IAccessTokenWithDataLoader | null = !!accessTokenO ? ({
        ...accessTokenO,
        graphQLDataLoader: graphQLDataLoaderO!
    }) : null;

    return (
        <WithListWithSearchLayoutPage outletContext={{accessTokenWithDataLoaderO}}
                                      defaultSize='25%'
                                      addButtonPropsO={(!!accessTokenO?.authUserO?.canManageProfiles && ({
                                          id: 'addBuyerButton',
                                          text: 'Ajouter un Buyer',
                                          url: AddBuyerUrl
                                      })) || null}>
            <div className='panel-block p-horizontal-center'>
                <WithBuyerCompanyIdParamWrapper child={(buyerCompanyIdO) =>
                    <WithIncludeBuyersWithoutInvoiceQueryParamWrapper child={(includeBuyersWithoutInvoice) =>
                        <WithBuyerTabParamWrapper child={(tabO: string | null) =>
                            <CheckBox internalId='updateBuyersFilterWithoutInvoice'
                                      inputValue={includeBuyersWithoutInvoice}
                                      updateInputValue={(newValue) => navigate(buyerUrlTab(!!buyerCompanyIdO ? ({
                                          buyerCompanyId: buyerCompanyIdO,
                                          tabO
                                      }): null, newValue))} />
                        } />
                    }/>
                }/>
                <span className='p-margin-left-5'>Include buyers without invoices</span>
            </div>

            {/*<BuyersList accessTokenWithDataLoaderO={accessTokenWithDataLoaderO} />*/}
            <WithSearchList autofocus={false}
                            inputElement={
                                (
                                    setFocused: (newFocus: boolean) => void,
                                    searchInput: string,
                                    setSearchInput: (newInput: string) => void
                                ) =>
                                    <DefaultInput inputValue={searchInput}
                                                  placeHolder='Recherche'
                                                  enabled={{
                                                      updateInputValue: setSearchInput,
                                                      validateInput: () => null,
                                                      // tryComplete,
                                                      uponFocus: () => setFocused(true),
                                                      uponBlur: () => setFocused(false)
                                                  }} />
                            }
                            childrenFactory={(searchInput: string) =>
                                !!accessTokenWithDataLoaderO
                                    ? <WithIncludeBuyersWithoutInvoiceQueryParamWrapper child={(includeBuyersWithoutInvoice: boolean) =>
                                        <BuyersListAxiosReady includeBuyersWithoutInvoice={includeBuyersWithoutInvoice}
                                                              searchInput={searchInput}
                                                              graphQLDataLoader={accessTokenWithDataLoaderO?.graphQLDataLoader}/>
                                    }/>
                                    : <Loader />
                            }/>
        </WithListWithSearchLayoutPage>
    );
}

export default BuyersPage;
