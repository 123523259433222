import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { Axios } from "axios";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import Upload from "factor-lib/forms/Upload/Upload";
import SingleGraphQLFileVisualizer from "./SingleGraphQLFileVisualizer";
import windowErrorHandler from "./windowErrorHandler";

const UploadWrapper = (
    {
        upload,
    }: {
        upload: IUpload
    }
) => {
    const queryClient = useQueryClient();

    return (
        <Upload id={upload.id}
                acceptedFileTypesSet={upload.acceptedFileTypesSet}
                externalInitialErrors={null}
                existingFileName={null}
                actionO={{
                    uploadFile: async (file: File, onUploadProgress: (progressEvent: ProgressEvent) => void) =>
                        await upload.uploadFileAsync(file, onUploadProgress)
                            .then(() => upload.updateQueries(queryClient, file)),
                    deleteFile: null,
                    errorHandler: windowErrorHandler
                }} />
    );
};

interface IUpload {
    id: string;
    acceptedFileTypesSet: Set<string>;
    uploadFileAsync: (file: File, onUploadProgress: (progressEvent: ProgressEvent) => void) => Promise<void>;
    updateQueries: (queryClient: QueryClient, file: File) => void;
}

const SingleGraphQLFileVisualizerWithUpload = <T,>(
    {
        className,
        axios,
        title,
        fetchId,
        fetchText,
        queryKey,
        queryParams,
        visualizerId,
        visualizerText,
        buttonKind,
        buttonSize,
        existingFileName,
        urlExtractor,
        upload
    }: {
        className?: string;
        axios: Axios;
        title: string;
        fetchId: string;
        fetchText: string;
        queryKey: string[];
        queryParams: IGraphQLParams;
        visualizerId: string;
        visualizerText: string;
        buttonKind: string;
        buttonSize: string;
        existingFileName: string | null; // used to force re-render in case file changes
        urlExtractor: (graphQLResponse: T) => string;
        upload: IUpload | null;
    }
) =>
    <div className={`p-vertical-center${!!className ? ` ${className}` : ''}`}>
        <div className='p-bold'>
            { title }
        </div>

        <div className='p-margin-top-5'>
            { !!existingFileName
                ? <SingleGraphQLFileVisualizer axios={axios}
                                               fetchId={fetchId}
                                               fetchText={fetchText}
                                               queryKey={queryKey}
                                               queryParams={queryParams}
                                               visualizerId={visualizerId}
                                               visualizerText={visualizerText}
                                               buttonKind={buttonKind}
                                               buttonSize={buttonSize}
                                               existingFileName={existingFileName}
                                               urlExtractor={urlExtractor} />
                : <span>Not uploaded</span>
            }
        </div>

        { !!upload &&
            <div className='p-margin-top-5 p-vertical-center'>
                <UploadWrapper upload={upload}/>
            </div>
        }
    </div>;

export default SingleGraphQLFileVisualizerWithUpload;
