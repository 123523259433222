import { ReactElement, useEffect } from "react";
import CurrentEnv from "../envs/CurrentEnv";

const WithTitleSetter = (
    {
        title,
        children
    }: {
        title: string;
        children: ReactElement;
    }
) => {
    useEffect(() => {
        document.title = `${CurrentEnv.title}-${title} `;
    }, [title]);
    return children;
}

export default WithTitleSetter;