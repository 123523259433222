import { IInvoiceDetailsCustomerFinancingRequestStatus, IAdminInvoicePayment } from "../invoiceDetailsQuery";
import { directRecuperationTypeArray } from "./InvoiceActions/InvoiceDeclareDirectRecuperation/ChoseRecuperationType";
import { isPaymentNotReporting, PAYMENT_TYPE_COMMINGLING_RECUP, PAYMENT_TYPE_SWAN } from "../../../utils/paymentUtils";
import { userFormatDate, serverDateDeserialization, dateDiffDays } from "factor-lib/utils/dateUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";
import {merciFacteurMailTypeLabel} from "../../../utils/MerciFacteursMailsTypes";

interface IActivityLog {
    description: string;
    date: Date;
    preferenceForLast?: number; // If set, put at the end in case of equality on date // Defaut 0
}

const manualPaymentsTypes: [number, string][] = directRecuperationTypeArray.map((m, index) => [index + 1, m.label]);

export const paymentTypeMap: Map<number, string> = new Map<number, string>([
    ...manualPaymentsTypes,
    [PAYMENT_TYPE_SWAN, 'Matching Swan'],
    // [PAYMENT_TYPE_COMMINGLING_RECUP, 'Prélèvement Stripe']
]);

const invoiceActivitiesSorted = (
    creationDateTime: string,
    invoicePayments: IAdminInvoicePayment[],
    customerFinancingRequest: IInvoiceDetailsCustomerFinancingRequestStatus | null
): IActivityLog[] => {
    const activities: IActivityLog[] = [];

    activities.push({
        description: 'Facture ajoutée',
        date: serverDateDeserialization(creationDateTime)
    });

    invoicePayments.forEach((ip) =>
        activities.push({
            description: ip.base.type === PAYMENT_TYPE_COMMINGLING_RECUP
                ? `Prélèvement ${ip.isComplete ? 'complet' : 'partiel'} Stripe d'un montant de ${formatAmount(ip.amount)} suite à une déclaration de commingling`
                : `Déclaration d'${
                    isPaymentNotReporting(ip.base.type)
                        ? `un paiement ${ip.isComplete ? 'complet' : 'partiel'}`
                        : `une récupération ${ip.isComplete ? 'complète' : 'partielle'} (reporting)`
                } de type "${paymentTypeMap.get(ip.base.type)!}"${!!ip.base.specificPaymentId ? ` (${ip.base.specificPaymentId})` : ''} ${formatAmount(ip.amount)}`,
            date: serverDateDeserialization(ip.creationDateTime) // TODO: use givenDate ?
        })
    );

    if (!!customerFinancingRequest) {
        activities.push({
            description: 'Financement demandé par le Client/Ops',
            date: serverDateDeserialization(customerFinancingRequest.creationDateTime)
        });

        const { accepted } = customerFinancingRequest;

        if (!!accepted) {
            activities.push({
                description: `Demande de financement ${accepted.autoApproved ? 'auto-approved' : 'validée par un Ops'} `,
                date: serverDateDeserialization(accepted.creationDateTime),
                preferenceForLast: accepted.autoApproved ? 1 : undefined
            });

            const { financing } = accepted;

            if (!!financing) {
                activities.push({
                    description: `Financement effectué${!!customerFinancingRequest.amountForMarketplace
                        ? ` (${formatAmount(financing.amount - customerFinancingRequest.amountForMarketplace)} pour le Seller, ${formatAmount(customerFinancingRequest.amountForMarketplace)} pour la Marketplace)`
                        : ''
                    }` ,
                    date: serverDateDeserialization(financing.creationDateTime)
                });

                if (!!financing.settled) {
                    activities.push({
                        description: 'Dossier cloturé (settle)',
                        date: serverDateDeserialization(financing.settled.dateTime)
                    });
                }

                for (const merciFacteurMail of financing.merciFacteurMails) {
                    const courrierLabel =
                        merciFacteurMailTypeLabel(merciFacteurMail.type);

                    activities.push({
                        description: `${courrierLabel} Merci Facteur envoyée`,
                        date: serverDateDeserialization(merciFacteurMail.creationDateTime)
                    });
                    const receivedDateTime = merciFacteurMail.receivedDateTime;
                    if (!!receivedDateTime) {
                        activities.push({
                            description: `${courrierLabel} Merci Facteur reçue`,
                            date: serverDateDeserialization(receivedDateTime)
                        });
                    }
                }
            }
        } else {
            const financingRequestExpiredDate = serverDateDeserialization(customerFinancingRequest.expirationDate);
            if (dateDiffDays(financingRequestExpiredDate, new Date()) > 0) {
                activities.push({
                    description: 'Demande de financement expirée',
                    date: financingRequestExpiredDate
                });
            }
        }

        if (!accepted?.financing) {
            if (!!customerFinancingRequest.rejectedDate) {
                activities.push({
                    description: `Demande de financement rejetée par un Ops, raison : ${customerFinancingRequest.rejectedReason}`,
                    date: serverDateDeserialization(customerFinancingRequest.rejectedDate)
                });
            }
        }
    }

    return activities.sort((a1, a2) => {
        const dateDiff = a2.date.getTime() - a1.date.getTime();
        if (dateDiff === 0) {
            // If equal, check preferenceForLast
            return (a2.preferenceForLast ?? 0) - (a1.preferenceForLast ?? 0);
        }
        return dateDiff;
    });
}

const InvoiceActivitiesSection = (
    {
        creationDateTime,
        customerFinancingRequest,
        payments
    } : {
        creationDateTime: string;
        customerFinancingRequest: IInvoiceDetailsCustomerFinancingRequestStatus | null;
        payments: IAdminInvoicePayment[];
    }
) =>
    <ul>
        { invoiceActivitiesSorted(creationDateTime, payments, customerFinancingRequest)
            .map((activity, index) =>
                <li key={`invoice-activity-${index}`}>
                    <span className='p-bold'>{userFormatDate(activity.date)} :</span> {activity.description}
                </li>
            )
        }
    </ul>;

export default InvoiceActivitiesSection;
