import { Axios } from "axios";
import IAuthUser from "../../../../IAuthUser";
import AccountManager, {
    AccountsManagersQuery,
    DimplOpsAccountManagersQueryKey
} from "../../../../utils/AccountManager";
import DataLoader from "dataloader";
import { IDimplOps } from "../../../../utils/IDimplOps";
import { IInvoiceDetailsQueryResult, invoiceDetailsQueryKey } from "../../invoiceDetailsQuery";
import {useQuery} from "@tanstack/react-query";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";

const InvoiceAccountManagerEnabled = (
    {
        className,
        axios,
        graphQLDataLoader,
        invoiceId,
        accountManagerLoadedO
    }: {
        className?: string;
        axios: Axios;
        graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        invoiceId: string;
        accountManagerLoadedO: IDimplOps | null;
    }
) =>
    <AccountManager className={className}
                    accountManagerLoadedO={accountManagerLoadedO}
                    canUpdate={{
                        axios,
                        endpoint: `adminInvoices/${invoiceId}/accountManager`,
                        mutationUpdate: (queryClient, newDimplOpsAccountManager) => {
                            queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                                invoiceDetailsQueryKey(invoiceId),
                                (old: IInvoiceDetailsQueryResult | undefined) => ({
                                    ...old!,
                                    invoiceDetails: {
                                        ...old!.invoiceDetails,
                                        effectiveAccountManager: newDimplOpsAccountManager
                                    }
                                })
                            );
                        },
                        queryResult: useQuery<IDimplOps[]>(
                            DimplOpsAccountManagersQueryKey,
                            async ({signal}) =>
                                (await graphQLDataLoader.load({
                                    query: AccountsManagersQuery,
                                    queryKey: DimplOpsAccountManagersQueryKey,
                                    signal
                                })).accountManagers
                        )
                    }} />;

const InvoiceAccountManager = (
    {
        className,
        axios,
        graphQLDataLoader,
        authUser,
        invoiceId,
        accountManagerLoadedO
    }: {
        className?: string;
        axios: Axios;
        graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        authUser: IAuthUser;
        invoiceId: string;
        accountManagerLoadedO: IDimplOps | null;
    }
) =>
    authUser.canManageProfiles
        ? <InvoiceAccountManagerEnabled className={className}
                                        axios={axios}
                                        graphQLDataLoader={graphQLDataLoader}
                                        invoiceId={invoiceId}
                                        accountManagerLoadedO={accountManagerLoadedO} />
        : <AccountManager className={className}
                          accountManagerLoadedO={accountManagerLoadedO}
                          canUpdate={null} />;


export default InvoiceAccountManager;
