import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import CustomerSwanSection, {IUpdateSwanInstantPaymentMutationParams} from "../../../../../utils/Swan/CustomerSwanSection";
import {ISellerDetailsQueryResult, sellerDetailsQueryKey} from "../../sellerDetailsQuery";
import {Axios} from "axios";
import IAuthUser from "../../../../../IAuthUser";
import InstantPayments from "../../../../../utils/Swan/InstantPayments";

const SellerSwanSection = (
    {
        axios,
        authUserO,
        customerId,
        virtualIban,
        instantPayment,
        sellerId,
        directSellerId
    } : {
        axios: Axios;
        authUserO: IAuthUser | null;
        customerId: string;
        virtualIban: string | null;
        instantPayment: boolean;
        sellerId: string;
        directSellerId: string | null;
    }
) => {
    const queryClient = useQueryClient();

    const updateSwanInstantPaymentMutation: UseMutationResult<void, any, IUpdateSwanInstantPaymentMutationParams> =
        useMutation<void, any, IUpdateSwanInstantPaymentMutationParams>(
            async (p: IUpdateSwanInstantPaymentMutationParams) => {
                await axios.put<void>(
                    `/adminSellers/${p.value}/swanInstantPayment`,
                    {
                        value: p.newInstantPayment
                    }
                );
            },
            ({
                onSuccess: (_, p: IUpdateSwanInstantPaymentMutationParams) => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(p.value), (old?: ISellerDetailsQueryResult) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                swanInstantPayment: p.newInstantPayment
                            }
                        })
                    );
                }
            })
        );

    return (
        !!directSellerId
            ? <CustomerSwanSection axios={axios}
                                   authUserO={authUserO}
                                   customerId={customerId}
                                   directSellerSellerId={sellerId}
                                   virtualIban={virtualIban}
                                   instantPayment={instantPayment}
                                   updateSwanInstantPaymentMutation={updateSwanInstantPaymentMutation}
                                   updateSwanInstantPaymentMutationValue={sellerId} />
            : <table className='table is-narrow'>
                <tbody>
                    <InstantPayments authUserO={authUserO}
                                     instantPayment={instantPayment}
                                     updateSwanInstantPaymentMutation={updateSwanInstantPaymentMutation}
                                     updateSwanInstantPaymentMutationValue={sellerId}/>
                </tbody>
            </table>
    );
}


export default SellerSwanSection;