import IAccessToken from "../../IAccessToken";
import { Link, Outlet, useLocation } from "react-router-dom";
import CompanyTabs from "./CompanyTabs";
import { companyUrlTab, CompaniesUrl } from "./companiesUrls";

const CompanyPage = (
    {
        accessTokenO,
        companyId
    }: {
        accessTokenO: IAccessToken | null;
        companyId: string;
    }
) => {
    const fullPath = useLocation().pathname;
    const currentRoute = fullPath.substring(CompaniesUrl.length + 36 + 2 /* // + guid size */);
    return (
        <div>
            <div className='tabs'>
                <ul>
                    { CompanyTabs
                        //.filter((tab) => tab.filter(accessTokenO?.authUserO))
                        .map((tab, index) =>
                            <li key={`t-${index}`}
                                className={currentRoute === tab.path ? 'is-active' : ''}>
                                <Link to={companyUrlTab(companyId, tab.path)}> { tab.title } </Link>
                            </li>
                        )
                    }
                </ul>
            </div>

            <div className='p-margin-4'>
                <Outlet context={{accessTokenO}}/>
            </div>
        </div>
    );
}

export default CompanyPage;
