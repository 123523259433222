import {Axios} from "axios";
import { ISellerDetailsQueryResult, sellerDetailsQueryKey } from "../../../sellerDetailsQuery";
import IPerson from "../../../../../../utils/Person/IPerson";
import LinkMutationWithConfirmationModal from "factor-lib/Links/LinkMutationWithConfirmationModal";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

const AddCompanyOwnerAsBeneficiary = (
    {
        axios,
        sellerId,
        directSellerId,
        companyOwner
    } : {
        axios: Axios;
        sellerId: string;
        directSellerId: string;
        companyOwner: IPerson;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const addCompanyOwnerAsBeneficiaryMutation: UseMutationResult<string, any, string, null> =
        useMutation<string, any, string, null>(
            async (directSellerId2) =>
                (await axios.post<string>(
                    `/adminDirectSellerUltimateBeneficiaries/${directSellerId2}/addCompanyOwnerAsBeneficiary`
                )).data,
            ({
                onSuccess: (beneficiaryId) => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old: ISellerDetailsQueryResult | undefined) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                customer: {
                                    ...old!.sellerDetails.customer,
                                    directSeller: {
                                        ...old!.sellerDetails.customer.directSeller!,
                                        isCompanyOwnerAddedAsBeneficiary: true,
                                        ultimateBeneficiaries: [
                                            ...old!.sellerDetails.customer.directSeller!.ultimateBeneficiaries,
                                            {
                                                id: beneficiaryId,
                                                ...companyOwner
                                            }
                                        ]
                                    }
                                }
                            }
                        })
                    );
                }
            })
        );

    return (
        <LinkMutationWithConfirmationModal id='addCompanyOwnerAsBeneficiary'
                                           text='Ajouter en tant que Bénéficiaire'
                                           confirmationText='Etes vous sûr de vouloir ajouter le mandataire en tant que bénéficiaire ?'
                                           mutation={addCompanyOwnerAsBeneficiaryMutation}
                                           value={directSellerId} />

    );
}

export default AddCompanyOwnerAsBeneficiary;
