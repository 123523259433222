import { Axios } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import ConfirmationModalContent from "factor-lib/Modals/ConfirmationModal/ConfirmationModalContent";
import { IInvoiceDetailsQueryResult, invoiceDetailsQueryKey } from "../../invoiceDetailsQuery";
import { formatAmount } from "factor-lib/utils/amountUtils";
import { GlobalOutstandingsQueryKey } from "../../../Misc/GlobalOutstandings/globalOutstandingsQuery";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import { PAYMENT_TYPE_COMMINGLING_RECUP } from "../../../../utils/paymentUtils";

interface IReChargeCommingLingResult {
    id: string;
    isComplete: boolean;
}

const ChargeStripeCommingLingModalContent = (
    {
        axios,
        invoiceId,
        commingLingAmountToReturn,
        closeModal
    }: {
        axios: Axios;
        invoiceId: string;
        commingLingAmountToReturn: number;
        closeModal: () => void;
    }
) => {

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const chargeInStripeMutation: UseMutationResult<IReChargeCommingLingResult, any, string, null> =
        useMutation<IReChargeCommingLingResult, any, string, null>(
            async (invoiceId2) =>
                (await axios.post<IReChargeCommingLingResult>(
                    `/adminInvoices/${invoiceId2}/charge-commingLing`
                )).data,
            ({
                onSuccess: (reChargeCommingLingResult: IReChargeCommingLingResult) => {
                    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                        invoiceDetailsQueryKey(invoiceId),
                        (old) => !!old ? ({
                            ...old!,
                            invoiceDetails: {
                                ...old.invoiceDetails,
                                status: {
                                    ...old.invoiceDetails.status,
                                    customerFinancingRequest: {
                                        ...old.invoiceDetails.status.customerFinancingRequest!,
                                        accepted: {
                                            ...old.invoiceDetails.status.customerFinancingRequest!.accepted!,
                                            financing: {
                                                ...old.invoiceDetails.status.customerFinancingRequest!.accepted!.financing!,
                                                commingLingAmountToReturn: 0
                                            }
                                        }
                                    }
                                },
                                payment: {
                                    ...old.invoiceDetails.payment,
                                    partialAmountPaid: old.invoiceDetails.payment.partialAmountPaid + commingLingAmountToReturn,
                                    completePaidDate: reChargeCommingLingResult.isComplete ? serverDateSerialization(new Date()) : null,
                                    payments: [
                                        ...old.invoiceDetails.payment.payments,
                                        {
                                            id: reChargeCommingLingResult.id,
                                            amount: commingLingAmountToReturn,
                                            isComplete: reChargeCommingLingResult.isComplete,
                                            creationDateTime: serverDateSerialization(new Date()),
                                            base: {
                                                specificPaymentId: reChargeCommingLingResult.id,
                                                type: PAYMENT_TYPE_COMMINGLING_RECUP
                                            }
                                        }
                                    ],
                                }
                            },
                        }) : undefined
                    );

                    // TODO: should update the invoice payment status in invoices/financingRequests/financings tabs

                    const r = queryClient.invalidateQueries(GlobalOutstandingsQueryKey);

                    closeModal();

                    return r;
                }
            })
        );

    const confirmationText =
        `Vous vous appretez à prélever un montant de ${formatAmount(commingLingAmountToReturn)}.`

    return (
        <ConfirmationModalContent openModalCancelButtonId='charge-stripe-recup-cancel'
                                  confirmationBody={<span> { confirmationText } </span>}
                                  closeModal={closeModal}
                                  confirmButton={
                                      <ButtonMutationEnabled id='charge-stripe-recup-confirm'
                                                             text='Prélever'
                                                             mutation={chargeInStripeMutation}
                                                             displayFullError={true}
                                                             value={invoiceId} />
                                  }/>
    );
}

export default ChargeStripeCommingLingModalContent;
