import BillingsTableLayout from "./BillingsTableLayout";
import {IBillingResult} from "./BillingQueries";
import Loader from 'factor-lib/Loader';
import useInfiniteScroll from "react-infinite-scroll-hook";
import { IPaginated, getNodes, hasNextPage } from 'factor-lib/utils/graphQLPagination';
import {InfiniteData} from "@tanstack/react-query";
import BillingRow from "./BillingRow";
import {Axios} from "axios";

const BillingsLoaded = (
    {
        canManageBillingsO,
        axios,
        billings,
        isIncrementalLoading,
        fetchNextPage
    }: {
        canManageBillingsO: boolean | null;
        axios: Axios;
        billings: InfiniteData<IPaginated<IBillingResult>>;
        isIncrementalLoading: boolean;
        fetchNextPage: () => void;
    }
) => {

    const billingsHasNextPage = hasNextPage(billings);

    const [sentryRef] = useInfiniteScroll({
        loading: isIncrementalLoading,
        hasNextPage: billingsHasNextPage,
        onLoadMore: fetchNextPage,
        rootMargin: '0px 0px 400px 0px',
    });

    return (
        <div>
            <BillingsTableLayout>
                <tbody>
                    { getNodes<IBillingResult>(billings).map((b, bi) => (
                        <BillingRow key={`b-${bi}`}
                                    canManageBillingsO={canManageBillingsO}
                                    axios={axios}
                                    billing={b} />
                    ))}
                </tbody>
            </BillingsTableLayout>
            {/* https://github.com/onderonur/react-infinite-scroll-hook#simple-example */}
            { (isIncrementalLoading || billingsHasNextPage) &&
                <div ref={sentryRef}>
                    <Loader />
                </div>
            }
        </div>
    );
}

export default BillingsLoaded;