import {formatAdminDateStr} from "../../../utils/dateTimeUtils";
import {BillingsQueryKey, IBillingResult} from "./BillingQueries";
import {formatYearMonth} from "../IYearMonth";
import {formatAmount} from 'factor-lib/utils/amountUtils';
import {Axios} from "axios";
import PennyLaneLink from "./PennyLaneLink";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import ButtonMutationEnabled from 'factor-lib/Buttons/ButtonMutationEnabled';
import { updateInfiniteQuery } from "factor-lib/utils/graphQLPagination";
import { serverDateSerialization } from 'factor-lib/utils/dateUtils';

const StripeEnabled = (
    {
        axios,
        billingId
    }: {
        axios: Axios;
        billingId: string;
    }
) => {

    const queryClient = useQueryClient();

    const stripeChargeMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (billingId2: string) => (
                await axios.post<void>(
                    `/adminBillings/${billingId2}/charge`
                ))
                .data,
            ({
                onSuccess: (_, param) => {
                    updateInfiniteQuery<IBillingResult>(
                        queryClient,
                        BillingsQueryKey,
                        (b) => b.id === param,
                        (b) => ({
                            ...b,
                            stripeCharge: {
                                creationDateTime: serverDateSerialization(new Date())
                            }
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled mutation={stripeChargeMutation}
                               text='Prélever'
                               value={billingId}
                               displayFullError={true} />
    );
}

const BillingRow = (
    {
        canManageBillingsO,
        axios,
        billing
    }: {
        canManageBillingsO: boolean | null;
        axios: Axios;
        billing: IBillingResult;
    }
) => {
    const b = billing;

    return (
        <tr >
            <td> { b.customer.company.name } </td>
            <td> { formatYearMonth(b.yearMonth) } </td>
            <td> {
                !!b.stripeCharge
                    ? formatAdminDateStr(b.stripeCharge.creationDateTime)
                    : (
                        canManageBillingsO &&
                            <StripeEnabled axios={axios}
                                           billingId={b.id}/>
                    )
            } </td>
            <td> { formatAmount(b.amount) } </td>
            <td>
                { !!b.pennyLaneId
                    ? <PennyLaneLink axios={axios}
                                     billingId={b.id}/>
                    : <span>Pas Pennlylane</span>
                }
            </td>
        </tr>
    );
}

export default BillingRow;