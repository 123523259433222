import IAccessToken from "../../IAccessToken";
import IAuthUserLoad from "../../IAuthUserLoad";
import { comparingDate, serverDateDeserialization } from "factor-lib/utils/dateUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";
import Loader from "factor-lib/Loader";
import { comparingRev } from "factor-lib/utils/comparingUtils";
import IOption from "factor-lib/forms/Select/IOption";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import GraphQLQueryWithAccessTokenWrapper from "../../GraphQLQueryWithAccessTokenWrapper";
import {filterStrInvoice} from "../InvoiceUtils";
import {invoiceUrl} from "../../Pages/Invoices/invoicesUrls";
import {sellerUrl} from "../../Pages/Sellers/sellersUrls";
import {buyerUrl} from "../../Pages/Buyers/buyersUrls";
import {formatAdminDateStr} from "../dateTimeUtils";
import {formatCompany} from "../companyUtils";
import {
    buildBuyerCompaniesMap,
    buildSellersMap,
    IBaseInvoiceSellerWithCompany,
    IInvoiceBuyerCompany
} from "../IInvoice";
import { ISettledFinancing, getSettledFinancingsGraphQLQuery } from "./settledFinancingsQuery";
import { Link } from "react-router-dom";

const notSettledPlaceholderDate = new Date();
const compareSettleDate = comparingRev(
    comparingDate((financing: ISettledFinancing) => !!financing.settled ? serverDateDeserialization(financing.settled.dateTime) : notSettledPlaceholderDate)
);


const SettledFinancingRow = (
    {
        financing,
        seller,
        buyerCompany
    }: {
        financing: ISettledFinancing;
        seller: IBaseInvoiceSellerWithCompany;
        buyerCompany: IInvoiceBuyerCompany;
    }
) => {
    const { invoice } = financing;
    return (
        <tr>
            <td>
                <Link to={invoiceUrl(invoice.id)}>{ invoice.number }</Link>
            </td>
            <td>
                { !!financing.settled ? formatAdminDateStr(financing.settled.dateTime) : '<<none>>' }
            </td>
            <td>
                { !!invoice.payment.completePaidDate ? formatAdminDateStr(invoice.payment.completePaidDate) : '<<none>>' }
            </td>
            <td>
                <Link to={sellerUrl(seller.id)}>
                    { formatCompany(seller.company) }
                </Link>
            </td>
            <td>
                <Link to={buyerUrl(buyerCompany.id, false)}>
                    { formatCompany(buyerCompany) }
                </Link>
            </td>
            <td>
                { formatAdminDateStr(invoice.dueDate) }
            </td>
            <td>
                { formatAmount(invoice.amountWoTax + invoice.amountTax) }
            </td>
        </tr>
    );
}

const SettledFinancingsTableLoaded = (
    {
        financings,
        sellers,
        buyerCompanies,
        filter,
        searchStrFilterO
    }: {
        financings: ISettledFinancing[];
        sellers: IBaseInvoiceSellerWithCompany[];
        buyerCompanies: IInvoiceBuyerCompany[];
        filter: ((settledFinancing: ISettledFinancing) => boolean) | null;
        searchStrFilterO: string | null;
    }
) => {
    const sellersById: Map<string, IBaseInvoiceSellerWithCompany> = buildSellersMap(sellers);
    const buyerCompaniesById: Map<string, IInvoiceBuyerCompany> = buildBuyerCompaniesMap(buyerCompanies);

    let filteredFinancings = financings;
    if (!!filter) {
        filteredFinancings = filteredFinancings.filter(filter);
    }

    let filteredFinancingsWithSellerAndBuyer = filteredFinancings
        .map((financing: ISettledFinancing) => ({
            financing,
            seller: sellersById.get(financing.invoice.sellerId)!,
            buyerCompany: buyerCompaniesById.get(financing.invoice.buyer.companyId)!
        }));

    if (!!searchStrFilterO) {
        const searchStrFilterLower = searchStrFilterO.toLowerCase();
        filteredFinancingsWithSellerAndBuyer = filteredFinancingsWithSellerAndBuyer
            .filter((f) => filterStrInvoice(f.financing.invoice.number, f.seller, f.buyerCompany, searchStrFilterLower))

    }

    return (
        <tbody>
            { filteredFinancingsWithSellerAndBuyer
                .sort((f, g) => compareSettleDate(f.financing, g.financing))
                .map((f, index) =>
                    <SettledFinancingRow key={`settled-financing-${index}`}
                                         financing={f.financing}
                                         seller={f.seller}
                                         buyerCompany={f.buyerCompany} />
                )
            }
       </tbody>
    );
}

export const settledFinancingsFilterOptions: Array<IOption<(financing: ISettledFinancing) => boolean>> = [
    {
        label: 'Payé',
        value: (financing) => !!financing.creationDateTime
    },
    {
        label: 'Défaut',
        value: (financing) => !financing.creationDateTime
    }
];


export interface ISettledFinancingsQueryResult {
    financings: {
        base: ISettledFinancing[];
        sellers: IBaseInvoiceSellerWithCompany[];
        buyerCompanies: IInvoiceBuyerCompany[];
    };
}

export interface ISettledFinancingsQueryParams {
    sellerId: string | null;
    buyerCompanyId: string | null;
}


const SettledFinancingsTable = (
    {
        accessTokenO,
        queryKey,
        queryParam,

        selectedFilter,
        setSelectedFilter,
        searchStrFilterO
    }: {
        accessTokenO: IAccessToken | null;
        queryKey: string[];
        queryParam: ISettledFinancingsQueryParams;

        selectedFilter: IOption<(financing: ISettledFinancing) => boolean> | null;
        setSelectedFilter: (newOption: IOption<(financing: ISettledFinancing) => boolean> | null) => void;
        searchStrFilterO: string | null;
    }
) =>
    <div>
        <div className='p-margin-bottom-5 column is-one-quarter'>
            <SingleSelect options={settledFinancingsFilterOptions}
                          selectedOption={selectedFilter}
                          selectOption={setSelectedFilter}
                          placeholder='Filtrer'
                          clearable={true} />
        </div>
        <table className='table is-striped is-bordered is-hoverable is-fullwidth'>
            <thead>
            <tr>
                <th>N° de facture</th>
                <th>Date de settle</th>
                <th>Date de paiement complet</th>
                <th>Seller</th>
                <th>Buyer</th>
                <th>Date d'échéance</th>
                <th>Montant TTC</th>
            </tr>
            </thead>
            <GraphQLQueryWithAccessTokenWrapper accessTokenO={accessTokenO}
                                                queryKey={queryKey}
                                                queryParams={{ query: getSettledFinancingsGraphQLQuery(queryParam.sellerId, queryParam.buyerCompanyId) }}
                                                onLoading={() =>
                                                    <tbody>
                                                        <tr>
                                                            <td/>
                                                            <td/>
                                                            <td/>
                                                            <td><Loader /></td>
                                                            <td/>
                                                            <td/>
                                                        </tr>
                                                    </tbody>
                                                }
                                                onSuccess={(r: IAuthUserLoad<ISettledFinancingsQueryResult>) =>
                                                    <SettledFinancingsTableLoaded financings={r.value.financings.base}
                                                                                  sellers={r.value.financings.sellers}
                                                                                  buyerCompanies={r.value.financings.buyerCompanies}
                                                                                  filter={selectedFilter?.value ?? null}
                                                                                  searchStrFilterO={searchStrFilterO} />

                                                } />
        </table>
    </div>;

export default SettledFinancingsTable;
