import TelemetryLogger from "factor-lib/services/logger/TelemetryLogger";
import ILogger from "factor-lib/services/logger/ILogger";
import IEnvConfig from "./IEnvConfig";
import DevEnv, {DevDataBackend} from "./DevEnv";

const DemoEnv: IEnvConfig = {

    logger: (): ILogger => new TelemetryLogger('93f1aa3e-cbe8-44a7-aa71-6cc5cc485d3e'),

    title: 'demo',
    swanClientId: DevEnv.swanClientId,
    baseUrl: 'https://admin.sandbox.dimpl.io', //https://salmon-smoke-051b49903.1.azurestaticapps.net'
    azureADClientId: DevEnv.azureADClientId,
    mainBackend: {
        baseUrl: 'https://factor-demo-admin-api.azurewebsites.net',
        scope: DevEnv.mainBackend.scope
    },
    dataBackend: DevDataBackend,
    logMsalInfo: false
};

export default DemoEnv;
