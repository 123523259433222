
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import IOption from "factor-lib/forms/Select/IOption";
import {useState} from "react";
import {Axios} from "axios";
import BuyerMailSellerSelector from "./BuyerMailSellerSelector";
import BuyerMerciFacteurMailFinancingsSelector from "./BuyerMerciFacteurMailFinancingsSelector";

const BuyerMerciFacteurMailSection = (
    {
        className,
        axios,
        buttonText,
        generateDocUrl,
        mailLabel,
        mailType,
        buyerCompanyId
    }: {
        className?: string;
        axios: Axios;
        buttonText: string;
        generateDocUrl: string;
        mailLabel: string;
        mailType: number;
        buyerCompanyId: string;
    }
) => {
    const [selectedSellerOption, setSelectedSellerOption] = useState<IOption<string> | null>(null);
    return (
        <ButtonForModal // id={'buyerSubrogationModal'}
                        className={className}
                        text={buttonText/* 'Envoi subrogation'*/}
                        modalMaxWidth={null}
                        modalFullMaxWidth={false}
                        childModalContent={(closeModal) =>
                            <div className='p-padding-4' style={{minHeight: '300px'}}>
                                <div className='field'>
                                    <div className='p-margin-bottom-6'>Pour quel seller ?</div>
                                    <BuyerMailSellerSelector axios={axios}
                                                             buyerCompanyId={buyerCompanyId}
                                                             selectedOption={selectedSellerOption}
                                                             setSelectedOption={setSelectedSellerOption}/>
                                </div>
                                { !!selectedSellerOption && (
                                    <div className='field'>
                                        <div className='p-margin-bottom-6'>Pour quelles factures de ce seller ?</div>
                                        <BuyerMerciFacteurMailFinancingsSelector axios={axios}
                                                                                 generateDocUrl={generateDocUrl}
                                                                                 mailLabel={mailLabel}
                                                                                 mailType={mailType}
                                                                                 closeModal={closeModal}
                                                                                 buyerCompanyId={buyerCompanyId}
                                                                                 sellerId={selectedSellerOption.value} />
                                    </div>
                                ) }
                            </div>
                        } />
    );
}

export default BuyerMerciFacteurMailSection;