import { IInvoiceMatch, IInvoiceMatchAmountValidated } from "./invoiceMatch";
import SwanIncomingPaymentDetailsInvoiceRow, {
    ISwanIncomingPaymentDetailsInvoiceSeller
} from "./SwanIncomingPaymentDetailsInvoiceRow";
import { ISwanIncomingPaymentDetailsMatchedInvoice, ISwanIncomingPaymentDetailsInvoiceCompany, ISwanIncomingPaymentDetailsMatch } from "../swanIncomingPaymentDetailsQuery";
import {comparingRev } from 'factor-lib/utils/comparingUtils'
import { comparingDate, serverDateDeserialization } from "factor-lib/utils/dateUtils";
import { formatAmountToInput } from 'factor-lib/forms/Inputs/InputAmount';

const compareMatchedInvoice = comparingRev(
    comparingDate((invoice: ISwanIncomingPaymentDetailsMatchedInvoice) => serverDateDeserialization(invoice.dueDate))
);

// an invoice can be matched multiple times to the same payment
const buildExistingInvoiceMatches = (
    existingInvoiceMatches: ISwanIncomingPaymentDetailsMatch[]
): IInvoiceMatch[] => {
    const map = new Map<string, IInvoiceMatchAmountValidated>();

    existingInvoiceMatches.forEach((m) => {
        const invoiceId = m.invoiceId;
        const invoiceMatch = map.get(invoiceId);
        if (!!invoiceMatch) {
            map.set(invoiceId, {
                isComplete: invoiceMatch.isComplete || m.isComplete,
                amount: invoiceMatch.amount + m.amount
            });
        } else {
            map.set(invoiceId, {
                isComplete: m.isComplete,
                amount: m.amount
            });
        }
    });

    return Array.from(map).map(([invoiceId, m]) => ({
        invoiceId: invoiceId,
        matchAmount: {
            isComplete: m.isComplete,
            amountInput: formatAmountToInput(m.amount)
        }
    }));
};

// invoices that are matched multiple times to the payment are multiple times
// distinct by could not be translated in EF
// TODO: fix
const removeDuplicatedInvoices = (
    existingMatchedInvoices: ISwanIncomingPaymentDetailsMatchedInvoice[]
): ISwanIncomingPaymentDetailsMatchedInvoice[] => {
    const result: ISwanIncomingPaymentDetailsMatchedInvoice[] = [];
    const resultSet = new Set<string>();

    existingMatchedInvoices.forEach((i) => {
        const invoiceId = i.id;
        if (!resultSet.has(invoiceId)) {
            result.push(i);
            resultSet.add(invoiceId);
        }
    });

    return result;
};

const SwanIncomingPaymentDetailsMatchedInvoicesRows = (
    {
        existingInvoiceMatches,
        existingMatchedInvoices,
        existingMatchedInvoicesBuyerCompanies,
        existingMatchedInvoicesSellers,
        paymentToDirectSeller
    }: {
        existingInvoiceMatches: ISwanIncomingPaymentDetailsMatch[];
        existingMatchedInvoices: ISwanIncomingPaymentDetailsMatchedInvoice[];
        existingMatchedInvoicesBuyerCompanies: ISwanIncomingPaymentDetailsInvoiceCompany[];
        existingMatchedInvoicesSellers: ISwanIncomingPaymentDetailsInvoiceSeller[];
        paymentToDirectSeller: boolean;
    }
) => {
    const invoiceMatchesMap = new Map(buildExistingInvoiceMatches(existingInvoiceMatches).map((m) => [m.invoiceId, m.matchAmount]));
    const buyerCompaniesMap = new Map(existingMatchedInvoicesBuyerCompanies.map((b) => [b.id, b]));
    const sellersCompaniesNamesMap = new Map(existingMatchedInvoicesSellers.map((s) => [s.id, s.companyName]));

    return (
        <>
            { removeDuplicatedInvoices(existingMatchedInvoices)
                .sort(compareMatchedInvoice)
                .map((invoice) => {
                    const invoiceId = invoice.id;
                    return (
                        <SwanIncomingPaymentDetailsInvoiceRow key={invoiceId}
                                                              invoice={invoice}
                                                              invoicePayment={invoice.payment}
                                                              matchAmount={invoiceMatchesMap.get(invoiceId)!}
                                                              matchEnabled={null}
                                                              buyerCompany={buyerCompaniesMap.get(invoice.buyer.companyId)!}
                                                              paymentToMarketplaceSeller={paymentToDirectSeller ? null : ({
                                                                  id: invoice.sellerId,
                                                                  companyName: sellersCompaniesNamesMap.get(invoice.sellerId)!
                                                              })} />
                    );
                })
            }
        </>
    );
}

export default SwanIncomingPaymentDetailsMatchedInvoicesRows;
