import {useState} from "react";
import IBilan, {ILiasse} from "./IBilan";
import DataBilanLayout from "./DataBilanLayout";
import {Axios} from "axios";
import { numberFormat2Digits, serverDateDeserialization } from "factor-lib/utils/dateUtils";

const convertLiasseToInput = (liasse: ILiasse): string => {
    let r: string = '';
    for (const property in liasse) {
        r = `${r}${property}=${liasse[property].toString(10)}\n`
    }
    return r;
}

const formatBilanExisting = (d: Date, type: string, confidentialite: string | null): string =>
    `"Bilan ${d.getFullYear()}-${numberFormat2Digits(d.getMonth() + 1)}-${numberFormat2Digits(d.getDay())} ${type}${!confidentialite ? ' (user)' : ''}"`;

const DataExistingBilanLoaded = (
    {
        axiosO,
        dataAxiosO,
        siren,

        existingBilan
    }: {
        axiosO: Axios | null;
        dataAxiosO: Axios | null;
        siren: string;

        existingBilan: IBilan;
    }
) => {
    const [liasseInput, setLiasseInput] = useState<string>(convertLiasseToInput(existingBilan.liasse));
    return (
        <DataBilanLayout axiosO={axiosO}
                         dataAxiosO={dataAxiosO}
                         siren={siren}
                         editTitle={`Edit de ${formatBilanExisting(serverDateDeserialization(existingBilan.dateCloture), existingBilan.bilanType, existingBilan.confidentialite)}`}
                         bilanMainInputO={null}
                         existingBilanO={existingBilan}
                         liasseInputO={{
                             liasseInput,
                             setLiasseInput
                         }}/>
    )
}

export default DataExistingBilanLoaded;
