import {Axios} from "axios";
import InvoiceFinanceModalContent from "./InvoiceFinanceModalContent";
import {ICustomerFinancingRequestAccepted} from "../../../../../utils/FinancingRequest/FinancingRequestsQuery";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import {IInvoiceDetailsSeller} from "../../../invoiceDetailsQuery";
import {IInvoiceBuyerCompany} from "../../../../../utils/IInvoice";

const InvoiceFinance = (
    {
        axios,
        className,
        customerFinancingRequest,
        seller,
        buyerCompany
    } : {
        axios: Axios;
        className?: string;
        customerFinancingRequest: ICustomerFinancingRequestAccepted;
        seller: IInvoiceDetailsSeller;
        buyerCompany: IInvoiceBuyerCompany;
    }
) =>
    <ButtonForModal className={className}
                    id='acceptFinancerFinancingRequestButton'
                    text='Finance invoice'
                    modalMaxWidth={null}
                    childModalContent={(closeModal) =>
                        <InvoiceFinanceModalContent axios={axios}
                                                    className={className}
                                                    closeModal={closeModal}
                                                    customerFinancingRequest={customerFinancingRequest}
                                                    seller={seller}
                                                    buyerCompany={buyerCompany} />
    } />;

export default InvoiceFinance;
