import {NewBillingUrl} from "../billingUrls";
import Button from 'factor-lib/Buttons/Button';
import {NavigateFunction} from "react-router-dom";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import {useContext} from "react";

const TopNewBilling = () => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    return (
        <div className='level'>
            <div className='level-left'/>
            <div className='level-right'>
                { /* TODO : rights ? */}
                <Button className='p-margin-right-4'
                        text='Nouvelle facturation'
                        isLoading={false}
                        actionO={() => navigate(NewBillingUrl)} />
            </div>
        </div>
    );
}

export default TopNewBilling;