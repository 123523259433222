import {ReactNode, useState} from "react";

export interface ITab<T/* , P */> {
    tabText: string;
    value: T;
    // filter: (e: T) => boolean;
    // prop: P;
}

const WithTabs = <T, > (
    {
        filters,
        defaultFilter,
        childrenFactory
        // accessTokenO,
        // queryKey,
        // queryFactory,
        // childrenFactory
    }: {
        filters: Array<ITab<T>>;
        defaultFilter: ITab<T>;
        childrenFactory: (activeFilter: T) => ReactNode;
        // accessTokenO: IAccessToken | null;
        // queryKey: string[];
        // queryFactory: (filter: ITabFilter<T, P>) => IGraphQLParams;
        // childrenFactory: (r: QR, activeFilter: ITabFilter<T, P>) => ReactElement;
    }
) => {
    const [activeTab, setActiveTab] = useState(defaultFilter);
    return (
        <div>
            <p className='panel-tabs p-padding-bottom-7'>
                { filters.map((f, fIndex) =>
                    <a className={(f === activeTab) ? 'is-active' : ''}
                       key={`filter-${fIndex}`}
                       onClick={() => setActiveTab(f)}
                    >
                        { f.tabText }
                    </a>
                ) }
            </p>
            { childrenFactory(activeTab.value) }
            {/* !!accessTokenO
                ? <GraphQLQueryWrapper<QR> queryKey={queryKey}
                                           queryParams={queryFactory(activeTab)}
                                           axios={accessTokenO.axios}
                                           onLoading={() => <Loader />}
                                           onSuccess={((r: QR) => childrenFactory(r, activeTab))} />
                : <Loader />
            */ }
        </div>
    )
};

export default WithTabs;