import { Axios } from "axios";
import SingleGraphQLFileVisualizer from "../../../../utils/SingleGraphQLFileVisualizer";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import IUrlOnly, { UrlGraphQLField } from "factor-lib/forms/Upload/IUrlOnly";

export const invoiceFileQueryKey = (invoiceId: string) =>
    [`invoice-${invoiceId}-file`];

interface IInvoiceFileQueryResult {
    invoiceDetails: {
        file: IUrlOnly;
    }
}

const InvoiceFiles = (
    {
        axios,
        invoiceId,
        invoiceFileName
    } : {
        axios: Axios;
        invoiceId: string;
        invoiceFileName: string;
    }
) =>
    <div className='p-horizontal-center'>
        <SingleGraphQLFileVisualizer axios={axios}
                                     fetchId='fetchInvoiceFile'
                                     fetchText='Fetch Invoice File'
                                     queryKey={invoiceFileQueryKey(invoiceId)}
                                     queryParams={{
                                         query: `query Q($invoiceId: Guid!) {
                                             invoiceDetails (id: $invoiceId) {
                                                 file { ${UrlGraphQLField} }
                                             }
                                         }`,
                                         variables: {
                                             invoiceId
                                         }
                                     }}
                                     visualizerId='seeInvoiceFile'
                                     visualizerText='See Invoice File'
                                     buttonKind={KIND_PRIMARY}
                                     buttonSize={SIZE_FIXED}
                                     existingFileName={invoiceFileName}
                                     urlExtractor={(s: IInvoiceFileQueryResult) => s.invoiceDetails.file.url} />
    </div>;

export default InvoiceFiles;
