import { QueryClient, useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Axios} from "axios";
import { ISwanIncomingPayment, SwanIncomingPaymentsQueryKey } from "./swanIncomingPaymentsQuery";
import IAccessToken from "../../IAccessToken";
import Input from "factor-lib/forms/Inputs/Input";
import CheckBox from "factor-lib/forms/CheckBox";
import { useState } from "react";
import SwanIncomingPaymentsTable from "./SwanIncomingPaymentsTable";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import BatchNoMatchButton from "./SwanIncomingPaymentsBatchNoMatchButton";
import { getAmountRemaining } from "./swanUtils";


interface IRefreshSwanIncomingPaymentResult {
    swanIncomingPaymentId: string;
    error: string | null;
}

interface IRefreshSwanIncomingPaymentsResult {
    inserts: IRefreshSwanIncomingPaymentResult[];
    updates: IRefreshSwanIncomingPaymentResult[];
}

const formatRefreshSwanIncomingPaymentsResult = (r: IRefreshSwanIncomingPaymentResult[]) =>
    r.map((s) => `- ${s.swanIncomingPaymentId} (${s.error === null ? 'succès' : `erreur: ${s.error}`})`).join('\n');

const RefreshPaymentsButton = (
    {
        className,
        axios
    }: {
        className?: string;
        axios: Axios;
    }
) => {
    const queryClient: QueryClient = useQueryClient();

    const mutation: UseMutationResult<IRefreshSwanIncomingPaymentsResult, any, null> =
        useMutation<IRefreshSwanIncomingPaymentsResult, any, null>(
            async () => (await axios.post<IRefreshSwanIncomingPaymentsResult>(
                `/adminSwanIncomingPayments/refresh`
            )).data,
            ({
                onSuccess: (response) => {
                    if (response.inserts.length > 0 || response.updates.length > 0) {
                        window.alert(
                            `${response.inserts.length} nouveaux paiements Swan :\n ${formatRefreshSwanIncomingPaymentsResult(response.inserts)}\n` +
                            `${response.updates.length} mises à jour de paiements Swan :\n ${formatRefreshSwanIncomingPaymentsResult(response.updates)}`
                        );
                        return queryClient.invalidateQueries(SwanIncomingPaymentsQueryKey);
                    } else {
                        window.alert('Aucun nouveau paiement Swan');
                        return Promise.resolve();
                    }
                }
            })
        );

    return (
        <ButtonMutationEnabled className={className}
                               id='refreshSwanIncomingPayments'
                               text='Rafraîchir depuis Swan'
                               mutation={mutation}
                               value={null}
                               displayFullError={true} />
    );
}

const NoMatchFlaggedButton = (
    {
        className,
        swanIncomingPaymentsLoaded
    }: {
        className?: string;
        swanIncomingPaymentsLoaded: ISwanIncomingPayment[] | null;
    }
) => {
    const pendingFlaggedNoMatchPaymentsLoaded = !!swanIncomingPaymentsLoaded
        ? swanIncomingPaymentsLoaded.filter((p) => getAmountRemaining(p) > 0 && !!p.noMatchFlaggedDateTime)
        : null;

    return (
        !!pendingFlaggedNoMatchPaymentsLoaded?.length
            ? <BatchNoMatchButton className={className}
                                  swanIncomingPaymentsNoMatchFlagged={pendingFlaggedNoMatchPaymentsLoaded} />
            : null
    );
}

export interface IPendingOnly {
    flaggedNoMatchOnly: boolean;
}

const SwanIncomingPaymentsPage = (
    {
        accessTokenO,
    }: {
        accessTokenO : IAccessToken | null;
    }
) => {

    const [searchInput, setSearchInput] = useState('');
    const [showPendingOnly, setShowPendingOnly] = useState<IPendingOnly | null>({ flaggedNoMatchOnly: false });
    const [swanIncomingPaymentsLoaded, setSwanIncomingPaymentsLoaded] = useState<ISwanIncomingPayment[] | null>(null);

    return (
        <div>
            <div className='p-vertical-center title p-margin-4'>
                Liste des paiements Swan entrants
            </div>

            <div className='level p-margin-5'>
                <div className='level-left level-item'>
                    <div style={{minWidth: '50%'}}>
                        <Input inputValue={searchInput}
                               placeHolder='Filtrer'
                               enabled={{
                                   updateInputValue: setSearchInput,
                                   validateInput: () => null,
                                   innerId: {
                                       value: 'filterId',
                                       autofocus: true
                                   }
                               }} />
                    </div>

                    <div className='p-margin-left-5 p-margin-right-6'>
                        <div>
                            <div className='p-horizontal-center'>
                                <CheckBox internalId='pendingOnlyCheckbox'
                                        inputValue={!!showPendingOnly && !showPendingOnly.flaggedNoMatchOnly}
                                        updateInputValue={(newValue) => setShowPendingOnly(newValue ? { flaggedNoMatchOnly: false } : null)} />
            
                                <div className='p-margin-left-5'>En attente seulement</div>
                            </div>

                            <div className='p-horizontal-center'>
                                <CheckBox internalId='pendingFlaggedNoMatchOnlyCheckbox'
                                        inputValue={!!showPendingOnly && showPendingOnly.flaggedNoMatchOnly}
                                        updateInputValue={(newValue) => setShowPendingOnly(newValue ? { flaggedNoMatchOnly: true } : null)} />
            
                                <div className='p-margin-left-5'>En attente No Match seulement</div>
                            </div>
                        </div>
                    </div>
                </div>

                { !!accessTokenO?.authUserO?.canManageFinancings &&
                    <div className='level-right'>
                        <div className='level-item'>
                            <RefreshPaymentsButton axios={accessTokenO.axios}/>
                        </div>

                        <div className='level-item'>
                            <NoMatchFlaggedButton swanIncomingPaymentsLoaded={swanIncomingPaymentsLoaded} />
                        </div>
                    </div>
                }
            </div>

            <SwanIncomingPaymentsTable className='p-margin-top-4'
                                       accessTokenO={accessTokenO}
                                       searchInput={searchInput}
                                       showPendingOnly={showPendingOnly}
                                       setSwanIncomingPaymentsLoaded={setSwanIncomingPaymentsLoaded}/>
        </div>
    );
}

export default SwanIncomingPaymentsPage;
