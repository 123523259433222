import {useState} from "react";
import {BilanTypeEnum} from "./DataBilanContent";
import DataBilanLayout from "./DataBilanLayout";
import {Axios} from "axios";
import { numberFormat2Digits } from "factor-lib/utils/dateUtils";

const DataNewBilan = (
    {
        axiosO,
        siren
    }: {
        axiosO: Axios | null;
        siren: string;
    }
) => {
    const today = new Date();

    const [dateClotureInput, setDateClotureInput] = useState(`${numberFormat2Digits(today.getDate())}/${numberFormat2Digits(today.getMonth() + 1)}/${today.getFullYear()}`);
    const [dureeExerciceInput, setDureeExerciceInput] = useState('12');
    const [typeBilanInput, setTypeBilanInput] = useState(BilanTypeEnum.Complet);

    const [liasseInput, setLiasseInput] = useState<string>('');

    return <DataBilanLayout axiosO={axiosO}
                            dataAxiosO={null}
                            siren={siren}
                            editTitle={`Edit de "Nouveau bilan user"`}
                            bilanMainInputO={{
                                dateClotureInput, setDateClotureInput,
                                dureeExerciceInput, setDureeExerciceInput,
                                typeBilanInput, setTypeBilanInput
                            }}
                            existingBilanO={null}
                            liasseInputO={{
                                liasseInput,
                                setLiasseInput
                            }}/>
}

export default DataNewBilan;
