import IBaseInvoice, {
    BaseInvoiceGraphQLFields,
    IBaseInvoiceSellerWithCompany,
    IInvoiceBuyerCompany, InvoiceBaseSellersGraphQLSubQuery, InvoiceBuyerCompaniesGraphQLSubQuery
} from "../../utils/IInvoice";
import {InvoicesNotFinancingRequestedQueryKeyPrefix} from "../../utils/InvoiceUtils";
import { IGraphQLParams} from "factor-lib/serverUtils/graphQLQueryAsync";

export interface IIneligibleInvoice extends IBaseInvoice {
    status: {
        eligibility: {
            successO: {
                rejectionReason: string;
            }
        }
    }
}

export interface IInvoices {
    base: IIneligibleInvoice[];
    sellers: IBaseInvoiceSellerWithCompany[];
    buyerCompanies: IInvoiceBuyerCompany[];
}

export interface IInvoicesResponse {
    invoices: IInvoices;
}

export const RecentNotEligibleInvoicesQueryKey = [...InvoicesNotFinancingRequestedQueryKeyPrefix, 'recent-ineligible'];

export const InvoicesNotEligibleQuery: IGraphQLParams = {
    query: `{
        invoices (statuses: [NOT_ELIGIBLE], recentlyAdded: 30) {
            base {
                ${BaseInvoiceGraphQLFields}
                status {
                    eligibility {
                        successO {
                            rejectionReason
                        }
                    }
                } 
            }
            sellers {
                ${InvoiceBaseSellersGraphQLSubQuery}
            }
            ${InvoiceBuyerCompaniesGraphQLSubQuery}
        }
   }`
};