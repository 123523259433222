import {Axios} from 'axios';
import DataExistingBilanLoaded from "./DataExistingBilanLoaded";
import ReactQueryWrapper from "../../../utils/ReactQueryWrapper";
import bilanEndPoint from "./bilanEndPoint";
import {existingBilanQueryKey} from "./existingBilanQuery";
import IBilan from "./IBilan";

//*
const getBilanActionAsync = async (
    dataAxios: Axios,
    siren: string,
    dateCloture: string,
    type: string,
    signal?: AbortSignal
): Promise<IBilan> =>
    (await dataAxios.get<{bilan: IBilan}>(
        `${bilanEndPoint}/get?siren=${siren}&dateCloture=${dateCloture}&bilanType=${type}`,
        { signal }
    )).data.bilan;
 //*/

const ExistingBilanLoader = (
    {
        axiosO,
        dataAxios,
        siren,

        dateClotureBilan,
        typeBilan,

    }: {
        axiosO: Axios | null;
        dataAxios: Axios;
        siren: string;

        dateClotureBilan: string;
        typeBilan: string;

    }
) =>
    <ReactQueryWrapper queryKey={existingBilanQueryKey(siren, dateClotureBilan, typeBilan)}
                       queryFunc={(signal?: AbortSignal) =>
                           getBilanActionAsync(dataAxios, siren, dateClotureBilan, typeBilan, signal)
                           /*
                           Promise.resolve<IBilanGet>({
                               dateCloture: dateClotureBilan,
                               bilanType: typeBilan,
                               dureeExercice: 12,
                               confidentialite: 'non confidentiel',
                               proprietaryLiasse: true,
                               liasse: {
                                   aj_m1: 3066000000,
                                   aj_m2: 1834000000,
                                   aj_m3: 1232000000,
                                   aj_m4: 1103000000,
                                   at_m1: 110704000000,
                                   at_m2: 73057000000,
                                   at_m3: 37647000000,
                                   at_m4: 37405000000,
                                   av_m1: 22242000000,
                                   av_m2: 89000000,
                                   av_m3: 22153000000,
                                   av_m4: 21556000000,
                                   cs_m4: 5000000,
                                   bb_m1: 60974000000,
                                   bb_m2: 709000000,
                                   bb_m3: 60265000000,
                                   bb_m4: 59345000000,
                                   bd_m1: 25403000000,
                                   bd_m2: 258000000,
                                   bd_m3: 25145000000,
                                   bd_m4: 24393000000,
                                   bh_m1: 23829000000,
                                   bh_m2: 146000000,
                                   bh_m3: 23683000000,
                                   bh_m4: 16282000000,
                                   bj_m1: 246218000000,
                                   bj_m2: 76093000000,
                               }
                           }) */
                       }
                       onSuccess={(existingBilan) =>
                           <DataExistingBilanLoaded axiosO={axiosO}
                                                    dataAxiosO={dataAxios}
                                                    siren={siren}
                                                    existingBilan={existingBilan}/>} />;

export default ExistingBilanLoader;
