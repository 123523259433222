
export const DataCompanySearchUrl = '/data';
export const DataUrlSirenParam = 'siren';
export const DataSirenUrlMapping = `${DataCompanySearchUrl}/:${DataUrlSirenParam}`;

export const BilanUrl = '/bilan';
export const DataUrlBilanDateClotureParam = 'bilanDateCloture';
export const DataUrlBilanTypeParam = 'bilanType';

export const DataNewBilanUrlMapping = `${BilanUrl}/:${DataUrlSirenParam}`;
export const DataExistingBilanUrlMapping = `${BilanUrl}/:${DataUrlSirenParam}/:${DataUrlBilanDateClotureParam}/:${DataUrlBilanTypeParam}`;