import { Axios } from "axios";
import { useState } from "react";
import RadioBox from 'factor-lib/forms/Radio/RadioBox';
import CompanySearcher from "./CompanySearcher";
import ExistingCompanySelector from "./ExistingCompanySelector";
import { ICompanySelection } from "factor-lib/Company/ICompanySelection";

const CompanySelector = (
    {
        axios,
        searchPlaceHolder,
        searchInput,
        setSearchInput,
        initialDisplaySearch,
        selection,
        setSelection,
        autofocus
    }: {
        axios: Axios;
        searchPlaceHolder: string;
        searchInput: string;
        setSearchInput: (newCompanySearchInput: string) => void;
        initialDisplaySearch: boolean;
        selection: ICompanySelection | null;
        setSelection: (selection: ICompanySelection | null) => void;
        autofocus: boolean;
    }
) => {
    const [displayCompanySearch, setDisplayCompanySearch] = useState(initialDisplaySearch);

    return (
        <div>
            <div className='level'>
                <div className='level-item p-horizontal-center'>
                    <RadioBox id='companySelectorClassicSearchChoice'
                              inputValue={displayCompanySearch}
                              setInputValue={() => {
                                  if (!displayCompanySearch) {
                                      setSelection(null);
                                  }
                                  setDisplayCompanySearch(true);
                              }}
                              enabled={true} />
                    <span className='p-margin-left-6'>Recherche FR classique</span>
                </div>

                <div className='level-item p-horizontal-center'>
                    <RadioBox id='companySelectorExistingChoice'
                              inputValue={!displayCompanySearch}
                              setInputValue={() => {
                                  if (displayCompanySearch) {
                                      setSelection(null);
                                  }
                                  setDisplayCompanySearch(false);
                              }}
                              enabled={true} />
                    <span className='p-margin-left-6'>Company existante</span>
                </div>
            </div>

            { displayCompanySearch
                ? <CompanySearcher axios={axios}
                                   placeHolder={searchPlaceHolder}
                                   searchInput={searchInput}
                                   setSearchInput={setSearchInput}
                                   autofocus={autofocus}
                                   setSiren={(sirenO) => setSelection(!!sirenO ? { siren: sirenO, id: null } : null)} />
                : <ExistingCompanySelector axios={axios}
                                           companyId={selection?.id ?? null}
                                           setCompanyId={(companyId) => setSelection(!!companyId ? { siren: null, id: companyId } : null)} />
            }
        </div>
    );
}
    

export default CompanySelector;
