import IAccessToken from "../../IAccessToken";
import IAuthUserLoad from "../../IAuthUserLoad";
import {
    customerDetailsQuery,
    customerDetailsQueryKey,
    ICustomerDetailsQueryResult
} from "./CustomerInfos/customerDetailsQuery";
import CustomerPageLoaded from "./CustomerPageLoaded";
import GraphQLWithAccessTokenWrapper from "../../GraphQLQueryWithAccessTokenWrapper";
import Loader from "factor-lib/Loader";

// TODO: we load customer before displaying tabs because we need to check if he is a marketplace
// in this case we display the "marketplace sellers" tab
// better way ?

const CustomerPage = (
    {
        accessTokenO,
        customerId
    }: {
        accessTokenO: IAccessToken | null;
        customerId: string;
    }
) =>
    <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                   queryKey={customerDetailsQueryKey(customerId)}
                                   queryParams={customerDetailsQuery(customerId)}
                                   onLoading={() => <Loader/>}
                                   onSuccess={(r: IAuthUserLoad<ICustomerDetailsQueryResult>) =>
                                       <CustomerPageLoaded accessToken={accessTokenO!}
                                                           customerId={customerId}
                                                           customerDetails={r.value.customerDetails} />
                                   } />;

export default CustomerPage;
