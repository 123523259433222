import SplitPaneWrapper from "./SplitPaneWrapper";
import {NavigateFunction, Outlet} from "react-router-dom";
import {ReactNode, useContext} from "react";
import Button from "factor-lib/Buttons/Button";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";

interface IAddButtonProps {
    id: string;
    text: string;
    url: string;
}

const AddButton = (
    {
        id,
        text,
        url
    }: IAddButtonProps
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    return (
        <div className='panel-heading p-vertical-center'>
            <Button id={id}
                    text={text}
                    isLoading={false}
                    actionO={() => navigate(url)} />
        </div>
    );
}

const WithListWithSearchLayoutPage = <C, >(
    {
        defaultSize,
        addButtonPropsO,

        children,

        outletContext
    }: {
        defaultSize: string;
        addButtonPropsO: IAddButtonProps | null;

        children: ReactNode;

        outletContext: C;
    }
) =>
    <SplitPaneWrapper defaultSize={defaultSize}>
        <div className='panel p-margin-left-7'>
            { !!addButtonPropsO &&
                <AddButton {...addButtonPropsO}/>
            }

            { children }
        </div>

        <Outlet context={outletContext /* {accessTokenO} */} />
    </SplitPaneWrapper>;

export default WithListWithSearchLayoutPage;
