import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";

export const allBuyersWithLimitQueryKey = ['buyersWithLimit'];

export const buyersWithLimitSearch = [...allBuyersWithLimitQueryKey, 'search'];

export const buyersWithLimitSearchQueryKey = (search: string) => [...buyersWithLimitSearch , search];

export interface IBuyerCompanyWithLimit {
    id: string;
    name: string;
    identifier: IIntCompanyIdentifier;
    asBuyer: {
        manualOutstandingLimit: number; // Not null, as per the filter on the query
        outstanding: number;
    }
}

export interface IBuyerCompaniesWithLimitQueryResult {
    companies: Array<IBuyerCompanyWithLimit>;
}
