import IEnvConfig from "./IEnvConfig";
import DevEnv, {DevDataBackend} from "./DevEnv";
import ILogger from "factor-lib/services/logger/ILogger";
import WindowLogger from "factor-lib/services/logger/WindowLogger";

const LocalEnv: IEnvConfig = {

    logger:
        (): ILogger => new WindowLogger(false /* disable here for popup cancel */),
        // devTelemetryProvider,

    title: 'local',
    swanClientId: DevEnv.swanClientId,
    baseUrl: 'http://localhost:3000',
    azureADClientId: DevEnv.azureADClientId,
    mainBackend: {
        baseUrl: 'https://localhost:5001',
        scope: DevEnv.mainBackend.scope
    },
    dataBackend: DevDataBackend,
    logMsalInfo: true
};

export default LocalEnv;
