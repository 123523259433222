
import {useState} from "react";
import IAccessToken from "../../../IAccessToken";

import IOption from "factor-lib/forms/Select/IOption";
import IYearMonth from "../IYearMonth";
import NewBillingPostCustomerYearMonthSelection from "./NewBillingPostCustomerYearMonthSelection";
import CustomerYearMonthSelectionLayout, {ICustomer, monthOptions} from "./CustomerYearMonthSelectionLayout";

const NewBillingPage = (
    {
        accessTokenO
    }: {
        accessTokenO: IAccessToken | null;
    }
) => {
    const [selectedCustomer, selectCustomer] = useState<IOption<ICustomer> | null>(null);
    const [selectedMonth, selectMonth] = useState<IOption<IYearMonth> | null>(monthOptions.length === 1 ? monthOptions[0] : null);

    const areCustomerAndMonthSelected: {
        selectedCustomer: IOption<ICustomer>;
        selectedMonth: IOption<IYearMonth>;
    } | null = !!selectedCustomer && selectedMonth
        ? ({
            selectedCustomer,
            selectedMonth: selectedMonth
        }) : null;

    return (
        areCustomerAndMonthSelected
            ? <NewBillingPostCustomerYearMonthSelection axiosO={accessTokenO?.axios || null}
                                                        selectedCustomer={areCustomerAndMonthSelected.selectedCustomer}
                                                        selectedMonth={areCustomerAndMonthSelected.selectedMonth}
                                                        selectMonth={selectMonth}
                                                        selectCustomer={selectCustomer}/>
            : <CustomerYearMonthSelectionLayout axiosO={accessTokenO?.axios || null}
                                                selectedMonth={selectedMonth}
                                                selectMonth={selectMonth}
                                                selectedCustomer={selectedCustomer}
                                                selectCustomer={selectCustomer}/>

    );
}

export default NewBillingPage;