import IAuthUser from "../../../../../../IAuthUser";
import { formatAdminDateStr } from "../../../../../../utils/dateTimeUtils";
import { IBeneficiary } from "../../../sellerDetailsQuery";
import RemoveBeneficiary from "./RemoveBeneficiary";
import {Axios} from "axios";
import { formatAddress } from "factor-lib/CompanySearcher/ISearchCompanyAddress";
import Link from "factor-lib/Link";
import {formatBirthLocation} from "../../../../../../utils/IBirthLocation";

const BeneficiariesList = (
    {
        axios,
        authUser,
        sellerId,
        directSellerId,
        beneficiaries,
        showAddBeneficiary,
        showUpdateBeneficiary
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        sellerId: string;
        directSellerId: string;
        beneficiaries: IBeneficiary[];
        showAddBeneficiary: () => void;
        showUpdateBeneficiary: (beneficiary: IBeneficiary) => void;
    }
) =>
    <table className='table is-narrow'>
        { !!authUser?.canManageProfiles &&
            <thead>
                <tr>
                    <td/>
                    { beneficiaries.map((beneficiary, i) =>
                        <td key={i}>
                            <Link id={`showUpdateBeneficiaryModal-${beneficiary.id}`}
                                  text='Modifier'
                                  action={{
                                      clickHandler: () => showUpdateBeneficiary(beneficiary)
                                  }} />
                            <RemoveBeneficiary axios={axios}
                                               sellerId={sellerId}
                                               directSellerId={directSellerId}
                                               beneficiaryId={beneficiary.id} />
                        </td>
                    ) }

                    { beneficiaries.length < 4 &&
                        <td>
                            <Link id='showAddBeneficiaryModal'
                                  text='Ajouter'
                                  action={{
                                      clickHandler: showAddBeneficiary
                                  }} />
                        </td>
                    }
                </tr>
            </thead>
        }
        <tbody>
            <tr>
                <td className='p-bold'>Prénom</td>
                { beneficiaries.map((beneficiary, i) =>
                    <td key={i}>{ beneficiary.firstName }</td>)
                }
            </tr>
            <tr>
                <td className='p-bold'>Nom</td>
                { beneficiaries.map((beneficiary, i) =>
                    <td key={i}>{ beneficiary.lastName }</td>)
                }
            </tr>
            <tr>
                <td className='p-bold'>Nationalité</td>
                { beneficiaries.map((beneficiary, i) =>
                    <td key={i}>{ beneficiary.nationalityCode }</td>)
                }
            </tr>
            <tr>
                <td className='p-bold'>Date de naissance</td>
                { beneficiaries.map((beneficiary, i) =>
                    <td key={i}>{ formatAdminDateStr(beneficiary.birthDate! /* Mandatory here */) }</td>)
                }
            </tr>
            <tr>
                <td className='p-bold'>Lieu de naissance</td>
                { beneficiaries.map((beneficiary, i) =>
                    <td key={i}>{ formatBirthLocation(beneficiary.birthLocation)  }</td>)
                }
            </tr>
            <tr>
                <td className='p-bold'>Adresse</td>
                { beneficiaries.map((beneficiary, i) =>
                    <td key={i} style={{maxWidth: 200}}>
                        { !!beneficiary.residenceAddress ? formatAddress(beneficiary.residenceAddress) : '<<none>>' }
                    </td>
                ) }
            </tr>
        </tbody>
    </table>;

export default BeneficiariesList;
