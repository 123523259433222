import { Axios } from "axios";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import Modal from "factor-lib/Modal";
import {validateMandatoryFieldError } from "factor-lib/forms/Inputs/utils";
import {useContext, useState} from "react";
import {NavigateFunction} from "react-router-dom";
import Button from "factor-lib/Buttons/Button";
import { isValidConfidenceFlag } from "../../utils/ConfidenceFlags";
import DateInput, { formatDateInput, parseDateInput } from "factor-lib/forms/DateInput/DateInput";
import Input from "factor-lib/forms/Inputs/Input";
import InputAmount, {formatAmountToInput, parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import ShowValidationErrorsButton from "../../utils/ShowValidationErrorsButton";
import { IPreInvoiceMatchingSeller, IPreInvoiceDetails } from "./PreInvoiceDetailsQuery";
import { formatCompanyInput } from "factor-lib/Company/companyUtils";
import { ResultOrErrors } from "../../utils/ResultOrErrors";
import {
    collectCoreInvoiceParamsErrors, collectInvoiceBuyerParamsErrors,
} from "../../utils/newInvoiceUtils";
import { PlaceHolderSearchBuyer } from "../../utils/Company/CompanySearcher";
import PreInvoiceIdentifySeller from "./PreInvoiceIdentifySeller";
import IAuthUser from "../../IAuthUser";
import { IAddInvoiceResponse } from "../../utils/AddInvoice/addInvoiceUtils";
import { invoiceUrl } from "../Invoices/invoicesUrls";
import CompanySelector from "../../utils/Company/CompanySelector";
import { ICompanySelection } from "factor-lib/Company/ICompanySelection";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { PreInvoicesUrl } from "../PreInvoices/preInvoicesUrls";
import {serverDateNullableDeserialization} from "../../utils/dateTimeUtils";
import PreInvoiceDetailsPageLoadedEmail from "./PreInvoiceDetailsPageLoaded/PreInvoiceDetailsPageLoadedEmail";
import PreInvoiceDetailsPageLoadedPhone from "./PreInvoiceDetailsPageLoaded/PreInvoiceDetailsPageLoadedPhone";
import ApprovePreInvoiceButtonEnabled, {IApprovePreInvoiceParams} from "./PreInvoiceDetailsPageLoaded/ApprovePreInvoiceButtonEnabled";
import DiscardPreInvoiceButtonEnabled from "./PreInvoiceDetailsPageLoaded/DiscardPreInvoiceButtonEnabled";

const AddText = 'Add & Finance';

const computeInputColor = (confidence: number) : string =>
    confidence >= 0.98
        ? 'p-high-confidence'
        : confidence >= 0.9
            ? 'p-mid-confidence'
            : '';

const getPrediction = <TI,TO>(
    confidence: number,
    element: TI | null,
    formatter: (p: TI) => TO,
    defaultValue: TO
) : TO =>
    element !== null && confidence >= 0.9
        ? formatter(element)
        : defaultValue;

const getApprovePreInvoiceParams = (
    seller: IPreInvoiceMatchingSeller | null,

    buyerSelection: ICompanySelection | null,
    buyerEmailInput: string,
    buyerPhoneInput: string,

    numberInput: string,
    issueDateInput: string,
    dueDateInput: string,
    amountWoTaxInput: string,
    amountTaxInput: string
): ResultOrErrors<IApprovePreInvoiceParams> => {

    let validationErrors: string[] = [];

    if (!seller) {
        validationErrors.push(`Le vendeur n'est pas identifié`);
    } else {

        if (!seller.certif) {
            validationErrors.push(`Le vendeur n'est pas certifié`);
        } else {
            if (!isValidConfidenceFlag(seller.certif.solvaConfidenceFlag)) {
                validationErrors.push(`Certification solva rejetée`);
            }
            if (!isValidConfidenceFlag(seller.certif.fraudConfidenceFlag)) {
                validationErrors.push(`Certification fraud rejetée`);
            }
        }

        if (seller.isBlocked) {
            validationErrors.push(`Le seller est bloqué`);
        }
    }

    validationErrors = [
        ...validationErrors,
        ...collectInvoiceBuyerParamsErrors(
            seller?.company ?? null,
            buyerSelection,
            [ buyerEmailInput ],
            [ buyerPhoneInput ]
        )
    ];

    // make sure single phone is not  filtered out if empty (PRED-2418))
    if (buyerPhoneInput.trim().length === 0) {

        validationErrors = [
            ...validationErrors,
            `Le numéro de téléphone du buyer est invalide`
        ];
    }

    validationErrors = [
        ...validationErrors,
        ...collectCoreInvoiceParamsErrors(
            numberInput,
            amountWoTaxInput,
            amountTaxInput,
            issueDateInput,
            dueDateInput
        )
    ];

    if (validationErrors.length > 0) {
        return ResultOrErrors.fromError(validationErrors);
    }
    // else

    return ResultOrErrors.fromResult({
        sellerId: seller!.id,

        buyerSelection: buyerSelection!,
        buyerEmail: buyerEmailInput,
        buyerPhone: buyerPhoneInput,

        invoiceNumber: numberInput,
        invoiceIssueDate: parseDateInput(issueDateInput)!,
        invoiceDueDate: parseDateInput(dueDateInput)!,
        invoiceAmountWoTax: parseInputAmount(amountWoTaxInput),
        invoiceAmountTax: parseInputAmount(amountTaxInput)
    });
}

const ResultModalContent = (
    {
        invoiceId,
        notEligibleReason
    }: IAddInvoiceResponse
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    return (
        <div className='p-padding-4 p-vertical-center'>
            { !!notEligibleReason
                ? <span>La facture a été ajoutée mais elle n'est pas éligible, raison : {notEligibleReason}</span>
                : <div>
                    <span>La facture a été ajoutée et une demande de financement a été ouverte</span>
                    <div className='columns p-margin-top-5'>
                        <div className='column p-margin-left-4'>
                            <Button id='GotoAddedInvoiceDetails'
                                    text='Détails de la facture'
                                    isLoading={false}
                                    actionO={() => navigate(invoiceUrl(invoiceId))} />
                        </div>
                        <div className='column p-margin-left-4'>
                            <Button id='returnToPreInvoicesButton'
                                    text='Retourner aux PreInvoices'
                                    isLoading={false}
                                    actionO={() => navigate(PreInvoicesUrl)} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

const Valid = (
    {
        axios,
        preInvoiceId,
        preInvoice,
        params
    }: {
        axios: Axios;
        preInvoiceId: string;
        preInvoice: IPreInvoiceDetails;
        params: IApprovePreInvoiceParams;
    }
) => {
    const [ result, setResult ] = useState<IAddInvoiceResponse | null>(null);

    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    return (
        <div>
            <ApprovePreInvoiceButtonEnabled axios={axios}
                                            preInvoice={preInvoice}
                                            params={params}
                                            preInvoiceId={preInvoiceId}
                                            setResult={setResult}
                                            text={AddText} />
            { !!result &&
                <Modal id='approvePreInvoiceResultModal'
                       maxWidth={null}
                       fullMaxWidth={false}
                       close={() => navigate(PreInvoicesUrl)}>
                    <ResultModalContent {...result}/>
                </Modal>
            }
        </div>
    );
}

const BuyerCompanySelector = (
    {
        axios,
        buyerSelection,
        setBuyerSelection,
        autofocus
    }: {
        axios: Axios;
        buyerSelection: ICompanySelection | null;
        setBuyerSelection: (buyerSelection: ICompanySelection | null) => void;
        autofocus: boolean;
    }
) => {
    const [buyerCompanySearchInput, setBuyerCompanySearchInput] = useState('');

    return (
        <CompanySelector axios={axios}
                         searchPlaceHolder={PlaceHolderSearchBuyer}
                         searchInput={buyerCompanySearchInput}
                         setSearchInput={setBuyerCompanySearchInput}
                         initialDisplaySearch={true}
                         selection={buyerSelection}
                         setSelection={setBuyerSelection}
                         autofocus={autofocus} />
    );
};

const PreInvoiceDetailsPageLoaded = (
    {
        axios,
        authUser,
        preInvoiceId,
        preInvoice
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        preInvoiceId: string;
        preInvoice: IPreInvoiceDetails;
    }
) => {
    const today = new Date();

    const preInvoiceEmail = preInvoice.email;
    const preInvoiceEmailRecipients: string[] = preInvoiceEmail.recipientEmails;
    const matchingBuyerEmail = preInvoiceEmail.matchingBuyerEmail;
    const matchingBuyer = matchingBuyerEmail?.buyer;

    const [ buyerCompanySelection, setBuyerCompanySelection ] = useState<ICompanySelection | null>(!!matchingBuyer ? { siren: null, id: matchingBuyer.company.id } : null);
    const [ buyerEmailInput, setBuyerEmailInput ] = useState(!!matchingBuyerEmail ? matchingBuyerEmail.value : preInvoiceEmailRecipients.length > 0 ? preInvoiceEmailRecipients[0] : '');
    const [ buyerPhoneInput, setBuyerPhoneInput ] = useState('');

    const [ numberInput, setNumberInput ] = useState(getPrediction(preInvoice.invoiceNumberConfidence, preInvoice.invoiceNumber, n => n, ''));
    const [ issueDateInput, setIssueDateInput ] = useState(getPrediction(preInvoice.issueDateConfidence, serverDateNullableDeserialization(preInvoice.issueDate), formatDateInput, formatDateInput(today)));
    const [ dueDateInput, setDueDateInput ] = useState(getPrediction(preInvoice.dueDateConfidence, serverDateNullableDeserialization(preInvoice.dueDate), formatDateInput, formatDateInput(today)));
    const [ amountWoTaxInput, setAmountWoTaxInput ] = useState(getPrediction(preInvoice.amountWoTaxConfidence, preInvoice.amountWoTax, formatAmountToInput, ''));
    const [ amountTaxInput, setAmountTaxInput ] = useState(getPrediction(preInvoice.amountTaxConfidence, preInvoice.amountTax, formatAmountToInput, ''));

    const params: ResultOrErrors<IApprovePreInvoiceParams> = getApprovePreInvoiceParams(
        preInvoiceEmail.matchingSeller,

        buyerCompanySelection,
        buyerEmailInput,
        buyerPhoneInput,

        numberInput,
        issueDateInput,
        dueDateInput,
        amountWoTaxInput,
        amountTaxInput
    );

    const canUpdate: boolean = !!authUser?.canManageFinancings;

    return (
        <div className='columns'>
            <div className='column'>
                <div className='p-size-4 p-padding-top-5 p-padding-bottom-5 p-no-flex'>
                    Attachment
                </div>
                <object data={preInvoice.blobFile.url}
                        type={preInvoice.blobFile.contentType}
                        className='p-full-container'>
                    The blob file should appear
                </object>
            </div>

            <div className='column p-vertical-stretch'>
                <div className='p-size-4 p-padding-top-5 p-padding-bottom-5'>
                    Extracted infos
                </div>

                <div className='p-margin-top-3'>
                    { !!matchingBuyer
                        ? <Input inputValue={formatCompanyInput(matchingBuyer.company.identifier, matchingBuyer.company.name)}
                                 enabled={null} />
                        : canUpdate
                            ? <BuyerCompanySelector axios={axios}
                                                    buyerSelection={buyerCompanySelection}
                                                    setBuyerSelection={setBuyerCompanySelection}
                                                    autofocus={true} />
                            : <div>Buyer non identifié</div>
                    }
                </div>

                <div className='columns p-margin-top-5'>
                    <PreInvoiceDetailsPageLoadedEmail className='column'
                                                      axios={axios}
                                                      canUpdate={canUpdate}
                                                      buyerCompanySelection={buyerCompanySelection}

                                                      preInvoiceEmailRecipients={preInvoiceEmailRecipients}
                                                      buyerEmailInput={buyerEmailInput}
                                                      setBuyerEmailInput={setBuyerEmailInput} />
                    <PreInvoiceDetailsPageLoadedPhone className='column'
                                                      axios={axios}
                                                      canUpdate={canUpdate}
                                                      buyerCompanySelection={buyerCompanySelection}

                                                      buyerPhoneInput={buyerPhoneInput}
                                                      setBuyerPhoneInput={setBuyerPhoneInput} />
                </div>

                <div className='p-margin-top-5'>
                    <div className='p-padding-bottom-7'>Numéro de facture</div>
                    <Input inputValue={numberInput}
                           placeHolder='Numéro de facture'
                           enabled={canUpdate ? {
                               updateInputValue: setNumberInput,
                               validateInput: () => validateMandatoryFieldError(numberInput)
                           } : null}
                           inputClassName={computeInputColor(preInvoice.invoiceNumberConfidence)} />
                </div>

                <div className='columns p-margin-top-5'>
                    <div className='column'>
                        <div className='p-padding-bottom-7'>Date d'émission</div>
                        <DateInput dateInputValue={issueDateInput}
                                   enabled={canUpdate ? {
                                       updateDateInputValue: setIssueDateInput,
                                       validateDate: () => null
                                   } : null}
                                   inputClassName={computeInputColor(preInvoice.issueDateConfidence)} />
                    </div>

                    <div className='column'>
                        <div className='p-padding-bottom-7'>Date d'échéance</div>
                        <DateInput dateInputValue={dueDateInput}
                                   enabled={canUpdate ? {
                                       updateDateInputValue: setDueDateInput,
                                       validateDate: () => null
                                   } : null}
                                   inputClassName={computeInputColor(preInvoice.dueDateConfidence)} />
                    </div>
                </div>

                <div className='columns p-margin-top-5'>
                    <div className='column'>
                        <div className='p-padding-bottom-7'>Montant HT</div>
                        <InputAmount inputAmountValue={amountWoTaxInput}
                                     placeHolder='Montant Hors Taxes'
                                     enabled={canUpdate ? {
                                         updateInputValue: setAmountWoTaxInput
                                     } : null}
                                     inputClassName={computeInputColor(preInvoice.amountWoTaxConfidence)} />

                    </div>

                    <div className='column'>
                        <div className='p-padding-bottom-7'>Montant des taxes</div>
                        <InputAmount inputAmountValue={amountTaxInput}
                                     placeHolder='Montant des Taxes'
                                     enabled={canUpdate ? {
                                         updateInputValue: setAmountTaxInput
                                     } : null}
                                     inputClassName={computeInputColor(preInvoice.amountTaxConfidence)} />
                    </div>
                </div>

                { canUpdate &&
                    <div className='columns p-margin-top-5'>
                        <div className='column'>
                            <DiscardPreInvoiceButtonEnabled axios={axios}
                                                            preInvoiceId={preInvoiceId}
                                                            preInvoice={preInvoice} />
                        </div>
    
                        <div className='column'>
                            { !!preInvoiceEmail.matchingSeller
                                ? !!params.result
                                    ? <Valid axios={axios}
                                             preInvoiceId={preInvoiceId}
                                             preInvoice={preInvoice}
                                             params={params.result} />
                                    : <ShowValidationErrorsButton validationErrors={params.errors!}
                                                                  kind={KIND_PRIMARY}
                                                                  size={SIZE_FIXED}
                                                                  text={AddText} />
                                : <PreInvoiceIdentifySeller axios={axios}
                                                            preInvoiceId={preInvoiceId}
                                                            preInvoiceSenderEmail={preInvoiceEmail.senderEmail} />
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default PreInvoiceDetailsPageLoaded;
