export enum MindeeFieldEnum {
    NUMBER = "NUMBER",
    ISSUE_DATE = "ISSUE_DATE",
    DUE_DATE = "DUE_DATE",
    AMOUNT_WO_TAX = "AMOUNT_WO_TAX",
    AMOUNT_TAX = "AMOUNT_TAX"
}

export const MindeeFieldFriendlyNames = new Map<MindeeFieldEnum, string>([
    [MindeeFieldEnum.NUMBER, "numéro"],
    [MindeeFieldEnum.ISSUE_DATE, "date d'émission"],
    [MindeeFieldEnum.DUE_DATE, "date d'échéance"],
    [MindeeFieldEnum.AMOUNT_WO_TAX, "montant HT"],
    [MindeeFieldEnum.AMOUNT_TAX, "montant des taxes"]
]);

export enum MindeeIbanComplianceEnum {
    NOT_DETECTED = "NOT_DETECTED",
    PERSONAL = "PERSONAL",
    VIRTUAL = "VIRTUAL",
    UNKNOWN = "UNKNOWN"
}

export const MindeeIbanComplianceFriendlyNames = new Map<MindeeIbanComplianceEnum, string>([
    [MindeeIbanComplianceEnum.NOT_DETECTED, "non détecté"],
    [MindeeIbanComplianceEnum.PERSONAL, "personel"],
    [MindeeIbanComplianceEnum.VIRTUAL, "virtuel"],
    [MindeeIbanComplianceEnum.UNKNOWN, "inconnu"]
]);
