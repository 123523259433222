import {IInvoiceDetailsQueryResult, invoiceDetailsQuery, invoiceDetailsQueryKey} from "./invoiceDetailsQuery";
import InvoiceDetailsTemplate from "./InvoiceDetailsTemplate";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import {useQuery} from "@tanstack/react-query";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import IAuthUser from "../../IAuthUser";
import {Axios} from "axios";
import IAccessToken from "../../IAccessToken";
import {getFactorContext} from "../../IFactorContext";
import dataLoader from "factor-lib/dataLoader/dataLoader";
import graphQLBatchLoadFunction from "factor-lib/dataLoader/graphQLBatchLoadFunctionAsync";
import DataLoader from "dataloader";

const InvoiceDetailsPageWithAxiosReady = (
    {
        invoiceId,
        axios,
        authUser
    }: {
        invoiceId: string;
        axios: Axios;
        authUser: IAuthUser;
    }
) => {
    const queryKey = invoiceDetailsQueryKey(invoiceId);

    const graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any> = dataLoader<IGraphQLQueryWithKeyAndParams, any>(
        (graphQLQueries: ReadonlyArray<IGraphQLQueryWithKeyAndParams>) => graphQLBatchLoadFunction(
            axios,
            getFactorContext().logger,
            graphQLQueries
        )
    );

    const query = useQuery<IInvoiceDetailsQueryResult>(
        queryKey,
        async ({ signal }) =>
            (await graphQLDataLoader.load({
                query: invoiceDetailsQuery(invoiceId),
                queryKey,
                signal
            })) as IInvoiceDetailsQueryResult
    );
    return (
        <ReactQueryResultWrapper result={query}
                                 onLoading={() =>
                                     <InvoiceDetailsTemplate invoiceId={invoiceId}
                                                             al={{
                                                                 r: null,
                                                                 axios,
                                                                 authUser,
                                                                 graphQLDataLoader
                                                             }} />
                                 }

                                 onError={(error: string) =>
                                     <div className='p-vertical-center'> { error } </div>}
                                 onSuccess={((r: IInvoiceDetailsQueryResult) =>
                                         <InvoiceDetailsTemplate invoiceId={invoiceId}
                                                                 al={{
                                                                     r,
                                                                     axios,
                                                                     graphQLDataLoader,
                                                                     authUser
                                                                 }} />
                                 )}
                                 displayFullError={true} />
    );
}

const InvoiceDetailsPage = (
    {
        accessTokenO,
        invoiceId
    } : {
        accessTokenO: IAccessToken | null;
        invoiceId: string;
    }
) =>
    !!accessTokenO
        ? // Wait a little bit to batch the query with that of the account managers of the select
        !!accessTokenO.authUserO
            ? <InvoiceDetailsPageWithAxiosReady invoiceId={invoiceId}
                                                axios={accessTokenO.axios}
                                                authUser={accessTokenO.authUserO} />
            : <InvoiceDetailsTemplate invoiceId={invoiceId}
                                      al={null} />
        : <InvoiceDetailsTemplate invoiceId={invoiceId}
                                  al={null} />;

export default InvoiceDetailsPage;


