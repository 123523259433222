import {BillingsUrl} from "../billingUrls";
import {getFactorContext} from "../../../IFactorContext";
import IOption from "factor-lib/forms/Select/IOption";
import {NavigateFunction} from "react-router-dom";
import {ReactNode, useContext} from "react";
import Button from 'factor-lib/Buttons/Button';
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import {IPaginated, query} from "factor-lib/utils/graphQLPagination";
import graphQLQueryAsync from "factor-lib/serverUtils/graphQLQueryAsync";
import Loader from 'factor-lib/Loader';
import {ExistingPaginatedSelection2} from "../../../utils/ExistingPaginatedSelection";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import {Axios} from "axios";
import IYearMonth, {formatYearMonth} from "../IYearMonth";

export interface ICustomer {
    id: string;
    company: {
        name: string;
    };
    contactEmail: string;
}

const CustomersQuery =
    `query ($after: PaginationGuidCursor, $search: String) {
        customers(search: $search) {
            list(
                first: 10,
                after: $after,
                ${/* orderByField: ${CustomerSortPropertyToOrderByField.get(CustomerSortProperty.Name)!},
                orderByDirection: ${SortOrderToOrderByDirection.get(SortOrder.Asc)!} */ '' }
            ) ${query(`
                id
                company { name }
                contactEmail
            `)}
        }
    }`;

const today = new Date();

// On part d'aout 2022
const availableMonths: IYearMonth[] = [
    // 2022 : 5 months
    ...Array.from(Array(5).keys())
        .map((m) => ({
            month: m + 8,
            year: 2022
        })),
    // 2023 up to past year (0 as for now)
    ...Array.from(Array(today.getFullYear() - 2022 - 1).keys()).flatMap((y) =>
        Array.from(Array(12).keys())
            .map((m) => ({
                month: m + 1,
                year: y + 2022
            }))
    ),
    // current year
    ...Array.from(Array(today.getMonth() + 1).keys())
        .map((m) => ({
            month: m + 1,
            year: today.getFullYear()
        }))
];

export const monthOptions: Array<IOption<IYearMonth>> =
    availableMonths
        .sort((a, b) => {
            const x = a.year * 12 + a.month;
            const y = b.year * 12 + b.month;
            // We revert : on veut les plus récents d'abord
            return x < y ? 1 : x > y ? -1 : 0;
        })
        .map((m) => ({
            value: m,
            label: formatYearMonth(m)
        }));

const CustomerYearMonthSelectionLayout = (
    {
        axiosO,
        selectedMonth,
        selectMonth,
        selectedCustomer,
        selectCustomer,
        topRightNode,
        children
    }: {
        axiosO: Axios | null;
        selectedMonth: IOption<IYearMonth> | null;
        selectMonth: (newMonth: IOption<IYearMonth> | null) => void;
        selectedCustomer: IOption<ICustomer> | null;
        selectCustomer: (newCustomer: IOption<ICustomer> | null) => void;
        topRightNode?: ReactNode;
        children?: ReactNode;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    return (
        <section>
            <div className='container'>
                <Button className='p-margin-bottom-3'
                        text='Retour aux facturations'
                        isLoading={false}
                        actionO={() => navigate(BillingsUrl)} />
                <div className='p-vertical-center p-margin-bottom-3'>
                    <div className='title'>Nouvelle facturation</div>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'space-between'
                }}>
                    <div style={{minWidth: '420px'}}>
                        <div className='field'>
                            { !!axiosO
                                ? <ExistingPaginatedSelection2<ICustomer> selectedOption={selectedCustomer}
                                                                          setSelection={selectCustomer}
                                                                          formatter={(s) => `${s.company.name} - ${s.contactEmail}`}
                                                                          placeholder='Choisissez le client'
                                                                          queryKeyFactory={(searchInputStr) => ['customers', 'search', searchInputStr]}
                                                                          queryFn={async (signal: AbortSignal | undefined, pageParam: any | null, searchInputStr: string) =>
                                                                              (await graphQLQueryAsync<any>(
                                                                                  axiosO,
                                                                                  {
                                                                                      query: CustomersQuery,
                                                                                      variables: {
                                                                                          search: searchInputStr,
                                                                                          after: pageParam
                                                                                      }
                                                                                  },
                                                                                  getFactorContext().logger,
                                                                                  ['customers', 'billings', 'search', searchInputStr, 'billing'],
                                                                                  signal
                                                                              )).customers.list as IPaginated<ICustomer>
                                                                          }
                                                                          />
                                : <Loader/>
                            }
                        </div>
                        <div className='field'>
                            <SingleSelect options={monthOptions}
                                          selectedOption={selectedMonth}
                                          selectOption={selectMonth} />
                        </div>
                    </div>
                    { topRightNode }
                </div>
                { children }
            </div>
        </section>
    );
}

export default CustomerYearMonthSelectionLayout;