import { Axios } from "axios";
import ButtonMutationWithConfirmation from "factor-lib/Buttons/ButtonMutationWithConfirmationModal";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import {manualSettleChangeFinancingUpdateQueriesAsync} from "./manualSettleFinancingUtils";

const ManualSettleFinancing = (
    {
        axios,
        invoiceId,
        financingId,
        sellerId, // to update queries
        buyerCompanyId, // to update queries
        kind,
        size
    } : {
        axios: Axios;
        invoiceId: string;
        financingId: string;
        sellerId: string;
        buyerCompanyId: string;
        kind?: string;
        size?: string;
    }
) => {

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const settleMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (financingIdValue) =>
                (await axios.put<void>(
                    `/adminFinancings/${financingIdValue}/settle`
                )).data,
            ({
                onSuccess:
                    () =>
                        manualSettleChangeFinancingUpdateQueriesAsync(
                            queryClient,
                            invoiceId,
                            sellerId,
                            buyerCompanyId,
                            {
                                dateTime: serverDateSerialization(new Date()),
                                isManual: true
                            },
                            (b) => b.filter((f) => f.invoice.id !== invoiceId)
                        )
            })
        );

    return (
        <ButtonMutationWithConfirmation id='settleFinancing'
                                        kind={kind}
                                        size={size}
                                        text='Settle'
                                        confirmationText='Etes vous sûr de vouloir settle le financement ?'
                                        mutation={settleMutation}
                                        value={financingId} />
    );
}

export default ManualSettleFinancing;
