import {IBilansDescriptions} from "./IBilanDescription";
import Modal from "factor-lib/Modal";
import { SIZE_FIXED, KIND_PRIMARY } from "factor-lib/Buttons/Button";
import BilanSelectionWithExisting from "./BilanSelectionWithExisting";
import ActionBottomBar from "./ActionBottomBar";
import {NavigateFunction} from "react-router-dom";
import { BilanUrl } from "./dataUrls";
import {useContext} from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";

const buttonKind = KIND_PRIMARY;
const buttonSize = SIZE_FIXED;

const BilanSelectionModal = (
    {
        siren,
        existingBilans,
        closeModal
    }: {
        siren: string;
        existingBilans: IBilansDescriptions;
        closeModal: () => void;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    return (
        <Modal id='editBilanModal'
               maxWidth='800px'
               fullMaxWidth={false}
               active={true}
               close={closeModal}>
            <div className='p-padding-5'>
                { existingBilans.length > 0
                    ? <BilanSelectionWithExisting siren={siren}
                                                  existingBilans={existingBilans}
                                                  closeModal={closeModal}
                                                  buttonKind={buttonKind}
                                                  buttonSize={buttonSize} />
                    : <div style={{minWidth: '520px'}}>
                        <div>Pas de bilans !</div>
                        <ActionBottomBar closeModal={closeModal}
                                         kind={buttonKind}
                                         size={buttonSize}
                                         text='Ajouter un nouveau bilan'
                                         actionO={() => navigate(`${BilanUrl}/${siren}`)}/>
                    </div>
                }
            </div>
        </Modal>
    );
}

export default BilanSelectionModal;
