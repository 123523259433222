import {InfiniteData, useInfiniteQuery, UseInfiniteQueryResult} from "@tanstack/react-query";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import {billingsQuery, BillingsQueryKey, IBillingResult, INewBillingMinThresholdQueryResult} from "./BillingQueries";
import graphQLQueryAsync from "factor-lib/serverUtils/graphQLQueryAsync";
import { IPaginated } from 'factor-lib/utils/graphQLPagination';
import {getFactorContext} from "../../../IFactorContext";
import {Axios} from "axios";
import BillingsTableLoading from "./BillingsTableLoading";
import BillingsLoaded from "./BillingsLoaded";

const BillingsWithAxios = (
    {
        canManageBillingsO,
        axios
    }: {
        canManageBillingsO: boolean | null;
        axios: Axios;
    }
) => {
    const query: UseInfiniteQueryResult<IPaginated<IBillingResult>> = useInfiniteQuery<IPaginated<IBillingResult>>(
        ({
            queryKey: BillingsQueryKey,
            queryFn: async ({signal, pageParam = null /* Why default ? */}) =>
                (await graphQLQueryAsync<INewBillingMinThresholdQueryResult>(
                    axios,
                    billingsQuery(pageParam),
                    getFactorContext().logger,
                    BillingsQueryKey,
                    signal
                )).billings.list,
            getNextPageParam: (lastPage, _) => lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.endCursor : undefined
        })
    );

    return (
        <ReactQueryResultWrapper displayFullError={true}
                                 result={query}
                                 onLoading={() => <BillingsTableLoading/> }
                                 onSuccess={(billings: InfiniteData<IPaginated<IBillingResult>>, isIncrementalLoading: boolean) =>
                                     <BillingsLoaded canManageBillingsO={canManageBillingsO}
                                                     axios={axios}
                                                     billings={billings}
                                                     isIncrementalLoading={isIncrementalLoading}
                                                     fetchNextPage={() => query.fetchNextPage()}/>
                                 } />
    );
}

export default BillingsWithAxios;