import IAccessToken from "../../IAccessToken";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import {FinancingRequestsBatchUpdateModalContent} from "./FinancingRequestBatchUpdateModal";
import {Axios} from "axios";
import IBaseInvoice from "../IInvoice";

const batchUpdateText = 'Edit';

const FinancingRequestsBatchUpdateEnabled = (
    {
        axios,
        selectedInvoices
    }: {
        axios: Axios;
        selectedInvoices: IBaseInvoice[];
    }
) =>
    <ButtonForModal text={batchUpdateText}
                    modalMaxWidth='600px'
                    modalFullMaxWidth={false}
                    childModalContent={(closeModal) =>
                        <FinancingRequestsBatchUpdateModalContent axios={axios}
                                                                  selectedInvoices={selectedInvoices}
                                                                  closeModal={closeModal} />
                    }/>

const FinancingRequestsBatchUpdate = (
    {
        accessTokenO,
        selectedInvoicesO
    }: {
        accessTokenO: IAccessToken | null;
        selectedInvoicesO: IBaseInvoice[] | null;
    }
) =>
    !!selectedInvoicesO && selectedInvoicesO.length > 0
        ? <FinancingRequestsBatchUpdateEnabled axios={accessTokenO!/* TODO ? */.axios}
                                               selectedInvoices={selectedInvoicesO! /* TODO */}/>
        : <ButtonDisabled text={batchUpdateText} />;

export default FinancingRequestsBatchUpdate;
