import {useState} from "react";
import InvoicesTableLoaded from "./InvoicesTableLoaded";
import IneligibleInvoicesTemplate from "./IneligibleInvoicesTemplate";
import {IIneligibleInvoice, IInvoices} from "./IneligibleInvoicesQuery";
import {IBaseInvoiceSellerWithCompany, IInvoiceBuyerCompany} from "../../utils/IInvoice";
import {Link} from "react-router-dom";
import {invoiceUrl} from "./invoicesUrls";
import {sellerUrl} from "../Sellers/sellersUrls";
import {formatCompany} from "../../utils/companyUtils";
import {buyerUrl} from "../Buyers/buyersUrls";
import {formatAdminDateStr} from "../../utils/dateTimeUtils";
import {formatAmount} from 'factor-lib/utils/amountUtils';
import CheckBox from "factor-lib/forms/CheckBox";

const InvoiceRow = (
    {
        invoice,
        seller,
        buyerCompany,
        isSelected,
        setSelectedInvoice
    }: {
        invoice: IIneligibleInvoice;
        seller: IBaseInvoiceSellerWithCompany;
        buyerCompany: IInvoiceBuyerCompany;
        isSelected: boolean;
        setSelectedInvoice: (invoiceId: string) => void;
    }
) => (
    <tr>
        <td>
            <CheckBox inputValue={isSelected}
                      internalId={`ineligible-invoice-${invoice.id}`}
                      updateInputValue={() => setSelectedInvoice(invoice.id)}
            />
        </td>
        <td>
            <Link to={invoiceUrl(invoice.id)}>
                { invoice.number }
            </Link>
        </td>
        <td>
            <Link to={sellerUrl(seller.id)}>
                { formatCompany(seller.company) }
            </Link>
        </td>
        <td>
            <Link to={buyerUrl(buyerCompany.id, false)}>
                { formatCompany(buyerCompany) }
            </Link>
        </td>
        <td>
            { formatAdminDateStr(invoice.dueDate) }
        </td>
        <td>
            { formatAmount(invoice.amountWoTax + invoice.amountTax) }
        </td>
        <td>
            { invoice.status.eligibility.successO.rejectionReason }
        </td>
    </tr>
);

const IneligibleInvoicesLoaded = (
    {
        className,
        invoices,
        searchStrFilter
    }: {
        className?: string;
        invoices: IInvoices;
        searchStrFilter: string;
    }
) => {
    const [ selectedInvoicesIds, setSelectedInvoicesIds ] = useState<string[]>([]);
    const invoicesIdsSet = new Set(selectedInvoicesIds);

    const baseInvoices = invoices.base;
    return (
        <IneligibleInvoicesTemplate className={className}
                                    loadedO={{
                                        selectedInvoices: baseInvoices
                                            .filter((p) => invoicesIdsSet.has(p.id)),
                                        isAllSelected: baseInvoices.length === selectedInvoicesIds.length,
                                        selectAll: () =>
                                            setSelectedInvoicesIds(
                                                baseInvoices.length === selectedInvoicesIds.length
                                                    ? []
                                                    : baseInvoices.map((i) => i.id)
                                            )
                                    }} >
            <InvoicesTableLoaded invoices={baseInvoices}
                                 sellers={invoices.sellers}
                                 buyerCompanies={invoices.buyerCompanies}
                                 searchStrFilter={searchStrFilter}
                                 invoiceRowFactory={(i, index) =>
                                     <InvoiceRow key={`invoice-${index}`}
                                                 invoice={i.invoice}
                                                 seller={i.seller}
                                                 buyerCompany={i.buyerCompany}
                                                 isSelected={invoicesIdsSet.has(i.invoice.id)}
                                                 setSelectedInvoice={(invoiceId) =>
                                                     setSelectedInvoicesIds(
                                                         !invoicesIdsSet.has(invoiceId)
                                                             ? [...selectedInvoicesIds, invoiceId] // add
                                                             : selectedInvoicesIds.filter((i) => i !== invoiceId) // remove
                                                     )
                                                 }/>
                                 }/>
        </IneligibleInvoicesTemplate>
    )
}

export default IneligibleInvoicesLoaded;