import { IIntCompanyIdentifier, IntCompanyIdentifierTypeSiren } from "factor-lib/Company/IIntCompanyIdentifier";
import Input from "factor-lib/forms/Inputs/Input";
import { isValidSiren, validateSirenError } from "factor-lib/forms/Inputs/sirenUtils";
import { isValidString, validateMandatoryFieldError } from "factor-lib/forms/Inputs/utils";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import IOption from "factor-lib/forms/Select/IOption";
import { ResultOrErrors } from "../../utils/ResultOrErrors";

interface IIdentifierType {
    name: string;
    value: string;
    country: string;
}

// maps to IntCompanyIdentifierType
const identifierTypes: IIdentifierType[] = [
    {
        name: 'SIREN',
        value: IntCompanyIdentifierTypeSiren,
        country: 'France'
    },
    {
        name: 'CIF',
        value: 'Cif',
        country: 'Espagne'
    },
    {
        name: 'NIF',
        value: 'Nif',
        country: 'Espagne'
    },
    {
        name: 'KVK',
        value: 'Kvk',
        country: 'Pays-Bas'
    },
    {
        name: 'HR',
        value: 'Hr',
        country: 'Allemagne'
    },
    {
        name: 'CHRN',
        value: 'Chrn',
        country: 'Suisse'
    },
    {
        name: 'BERN',
        value: 'Bern',
        country: 'Belgique'
    },
    {
        name: 'VAT',
        value: 'Vat',
        country: 'Global' // TODO
    }
];

const IdentifierTypeOptions: Array<IOption<string>> = identifierTypes.map((i) => ({
    label: `${i.name} (${i.country})`,
    value: i.value
}));

const compareIdentifierType = (identifier1: string | null, identifier2: string) =>
    identifier1 !== null && identifier1.toLowerCase() === identifier2.toLowerCase();

export const getIntCompanyIdentifierParams = (
    identifierType: string | null,
    identifierValueInput: string
): ResultOrErrors<IIntCompanyIdentifier> => {

    const validationErrors: string[] = [];

    if (identifierType === null) {
        validationErrors.push(`Le type d'identifiant n'est pas choisi`);
    } else {
        if (!identifierTypes.some((i) => compareIdentifierType(identifierType, i.value))) {
            validationErrors.push(`Le type d'identifiant est invalide`);   
        }
    
        if (compareIdentifierType(identifierType, IntCompanyIdentifierTypeSiren)) {
            if (!isValidSiren(identifierValueInput)) {
                validationErrors.push(`Le siren est invalide`);
            }
        } else if (!isValidString(identifierValueInput)) {
            validationErrors.push(`L'identifiant est invalide`);
        }
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    return ResultOrErrors.fromResult({
        type: identifierType!,
        value: identifierValueInput
    });
};

export const getUpdateIntCompanyIdentifierParams = (
    old: IIntCompanyIdentifier,
    newParams: IIntCompanyIdentifier
): IIntCompanyIdentifier | null => {

    const typeUpdate: string | null = newParams.type !== old.type ? newParams.type : null;
    const valueUpdate: string | null = newParams.value !== old.value ? newParams.value : null;

    return typeUpdate !== null || valueUpdate !== null
        ? newParams
        : null;
};

const CompanyIdentifierInput = (
    {
        identifierType,
        setIdentifierType,
        identifierValueInput,
        setIdentifierValueInput,
        autofocusInnerId
    }: {
        identifierType: string | null;
        setIdentifierType: (newType: string | null) => void;
        identifierValueInput: string;
        setIdentifierValueInput: (newValue: string) => void;
        autofocusInnerId: string | null;
    }
) =>
    <div className='columns'>
        <div className='column'>
            <span className='p-bold p-margin-bottom-4'>Type Identifiant</span>
            <SingleSelect options={IdentifierTypeOptions}
                          selectedOption={IdentifierTypeOptions.find((i) => compareIdentifierType(identifierType, i.value)) ?? null}
                          selectOption={(newOption) => setIdentifierType(newOption?.value ?? null)} />

        </div>

        <div className='column'>
            <span className='p-bold'>Identifiant</span>
            <Input inputValue={identifierValueInput}
                   enabled={{
                       updateInputValue: setIdentifierValueInput,
                       validateInput: () => compareIdentifierType(identifierType, IntCompanyIdentifierTypeSiren)
                           ? validateSirenError(identifierValueInput)
                           : validateMandatoryFieldError(identifierValueInput),
                       innerId: !!autofocusInnerId ? {
                           value: autofocusInnerId,
                           autofocus: true
                       } : null
                   }} />
        </div>
    </div>;

export default CompanyIdentifierInput;
