import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import {Axios} from "axios";
import { customerDetailsQueryKey, ICustomerDetailsQueryResult, ICustomerUser } from "../../customerDetailsQuery";
import LinkMutationWithConfirmationModal from "factor-lib/Links/LinkMutationWithConfirmationModal";

const RemoveCustomerUserEnabled = (
    {
        axios,
        customerId,
        customerUser
    } : {
        axios: Axios;
        customerId: string;
        customerUser: ICustomerUser;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const removeCustomerUserMutation: UseMutationResult<void, any, ICustomerUser> =
        useMutation<void, any, ICustomerUser>(
            async (customerUser2) => {
                await axios.delete<void>(
                    `adminCustomerUsers/${customerId}/${customerUser2.azureObjectId}`
                );
            },
            ({
                onSuccess: (_, customerUser2) => {
                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId),
                        (old?: ICustomerDetailsQueryResult) => ({
                            customerDetails: {
                                ...old!.customerDetails,
                                users: old!.customerDetails.users.filter((u) => u.azureObjectId !== customerUser2.azureObjectId)
                            }
                        })
                    );
                }
            })
        );

    return (
        <LinkMutationWithConfirmationModal id={`showConfirmCustomerUserRemovalModal-${customerUser.azureObjectId}`}
                                           className='p-danger-text'
                                           text='Supprimer'
                                           confirmationText='Êtes vous sûr de vouloir supprimer cet utilisateur ?'
                                           mutation={removeCustomerUserMutation}
                                           value={customerUser} />
    );
}

export default RemoveCustomerUserEnabled;
