import { useState } from "react";
import { formatAdminDateStr } from "../../utils/dateTimeUtils";
import { Axios } from "axios";
import IAuthUser from "../../IAuthUser";
import {
    ISwanIncomingPaymentDetails,
    ISwanIncomingPaymentDetailsMatchedInvoice,
    ISwanIncomingPaymentDetailsInvoiceCompany,
    ISwanIncomingPaymentDetailsMatch,
    ISwanIncomingPaymentDetailsQueryResult,
    swanIncomingPaymentDetailsQueryKey
} from "./swanIncomingPaymentDetailsQuery";
import Input from "factor-lib/forms/Inputs/Input";
import { Link } from "react-router-dom";
import SwanIncomingPaymentDetailsCandidateInvoicesRows from "./SwanIncomingPaymentDetailsInvoices/SwanIncomingPaymentDetailsCandidateInvoicesRows";
import MatchSwanIncomingPaymentButton from "./SwanIncomingPaymentDetailsMatch/SwanIncomingPaymentDetailsMatch";
import ArchiveSwanIncomingPaymentButton from "./SwanIncomingPaymentDetailsArchive";
import { SIZE_COMPACT, KIND_PRIMARY } from "factor-lib/Buttons/Button";
import { INewInvoiceMatch } from "./SwanIncomingPaymentDetailsInvoices/invoiceMatch";
import { EURO_CODE, sum } from "factor-lib/utils/utils";
import { ResultOrErrors } from "../../utils/ResultOrErrors";
import SwanIncomingPaymentDetailsMatchedInvoicesLoaded from "./SwanIncomingPaymentDetailsInvoices/SwanIncomingPaymentDetailsMatchedInvoicesRows";
import {customerUrl} from "../Customers/customersUrls";
import { formatAmount, formatAmountExtended } from "factor-lib/utils/amountUtils";
import { isValidAmount, parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import { ISwanIncomingPaymentDetailsInvoiceSeller } from "./SwanIncomingPaymentDetailsInvoices/SwanIncomingPaymentDetailsInvoiceRow";
import { QueryClient, UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import { ISwanIncomingPaymentsQueryResult, SwanIncomingPaymentsQueryKey } from "../SwanIncomingPayments/swanIncomingPaymentsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { INewInvoiceMatches } from "./SwanIncomingPaymentDetailsMatch/matchOption";
import { getAmountRemaining } from "../SwanIncomingPayments/swanUtils";

const isValidMatchAmount = (amountInput: string): boolean =>
    isValidAmount(amountInput) && parseInputAmount(amountInput) > 0;


const getValidNewInvoiceMatches = (
    oldPaymentAmountRemaining: number,
    newInvoiceMatches: INewInvoiceMatch[] | null // null -> flagged no match
): ResultOrErrors<INewInvoiceMatches | null> => {
    if (!newInvoiceMatches) {
        return ResultOrErrors.fromResult(null);
    }

    if (newInvoiceMatches.some((i) => !isValidMatchAmount(i.matchAmount.amountInput))) {
        return ResultOrErrors.fromError(['Des montants de match ne sont pas valides']);
    }

    const newInvoiceMatchesValidated = new Map(newInvoiceMatches.map((i) => [
        i.invoiceId,
        ({
            isFinanced: i.isFinanced,
            isComplete: i.matchAmount.isComplete,
            amount: parseInputAmount(i.matchAmount.amountInput)
        })
    ]));

    const newInvoiceMatchesTotal = sum(Array.from(newInvoiceMatchesValidated.values()).map((m) => m.amount));

    if (newInvoiceMatchesTotal > oldPaymentAmountRemaining) {
        return ResultOrErrors.fromError(['Le montant total Matché est supérieur au montant restant du paiement Swan']);
    }

    return ResultOrErrors.fromResult({
        amounts: newInvoiceMatchesValidated,
        total: newInvoiceMatchesTotal
    });
};

const SetNoMatchFlaggedButton = (
    {
        className,
        axios,
        swanIncomingPaymentId,
        flag,
        setNewInvoiceMatches,
        kind,
        size
    }: {
        className?: string;
        axios: Axios;
        swanIncomingPaymentId: string;
        flag: boolean;
        setNewInvoiceMatches: (newValue: INewInvoiceMatch[] | null) => void;
        kind: string;
        size: string;
    }
) => {
    const queryClient: QueryClient = useQueryClient();

    const mutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (swanIncomingPaymentId) => (await axios.put<void>(
                `/adminSwanIncomingPayments/${swanIncomingPaymentId}/${flag ? 'flag-no-match' : 'unflag-no-match'}`
            )).data,
            ({
                onSuccess: (_, swanIncomingPaymentId) => {
                    queryClient.setQueryData<ISwanIncomingPaymentDetailsQueryResult>(
                        swanIncomingPaymentDetailsQueryKey(swanIncomingPaymentId),
                        (old) => !!old ? ({
                            ...old,
                            swanIncomingPaymentDetails: {
                                ...old.swanIncomingPaymentDetails,
                                noMatchFlaggedDateTime: flag ? serverDateSerialization(new Date()) : null
                            }
                        }) : undefined
                    );

                    queryClient.setQueryData<ISwanIncomingPaymentsQueryResult>(
                        SwanIncomingPaymentsQueryKey,
                        (old) => !!old ? ({
                            ...old,
                            swanIncomingPayments: old.swanIncomingPayments
                                .map((s) => s.id === swanIncomingPaymentId
                                    ? {
                                        ...s,
                                        noMatchFlaggedDateTime: flag ? serverDateSerialization(new Date()) : null
                                    }
                                    : s
                                )
                        }) : undefined
                    );

                    setNewInvoiceMatches(flag ? null : []);
                }
            })
        );

    return (
        <ButtonMutationEnabled className={className}
                               id='noMatchFlagButton'
                               text={flag ? 'Flag No Match' : 'Unflag No Match'}
                               mutation={mutation}
                               value={swanIncomingPaymentId}
                               displayFullError={true}
                               kind={kind}
                               size={size} />
    );
}

const SwanIncomingPaymentDetailsLoaded = (
    {
        axios,
        authUser,
        swanIncomingPayment,
        existingInvoiceMatches,
        existingMatchedInvoices,
        existingMatchedInvoicesBuyerCompanies,
        existingMatchedInvoicesSellers
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        swanIncomingPayment: ISwanIncomingPaymentDetails;
        existingInvoiceMatches: ISwanIncomingPaymentDetailsMatch[];
        existingMatchedInvoices: ISwanIncomingPaymentDetailsMatchedInvoice[];
        existingMatchedInvoicesBuyerCompanies: ISwanIncomingPaymentDetailsInvoiceCompany[];
        existingMatchedInvoicesSellers: ISwanIncomingPaymentDetailsInvoiceSeller[];
    }
) => {
    const [searchInput, setSearchInput] = useState('');

    const matchingCustomer = swanIncomingPayment.matchingCustomer;

    // null -> flagged no match
    const [newInvoiceMatches, setNewInvoiceMatches] =
        useState<INewInvoiceMatch[] | null>(!!swanIncomingPayment.noMatchFlaggedDateTime ? null : []);

    const oldPaymentAmountRemaining = getAmountRemaining(swanIncomingPayment);

    const newInvoiceMatchesFailable = getValidNewInvoiceMatches(
        oldPaymentAmountRemaining,
        newInvoiceMatches
    );

    return (
        <div>
            <div className='container p-vertical-center'>
                <div className='title'>
                    Swan Incoming Payment
                </div>

                <div className='box'>
                    <table className='table is-narrow'>
                        <tbody>
                            <tr>
                                <td className='p-bold'>Transaction ID</td>
                                <td>{ swanIncomingPayment.id }</td>
                            </tr>
                            <tr>
                                <td className='p-bold'>Date d'execution</td>
                                <td>{ formatAdminDateStr(swanIncomingPayment.swanCreationDateTime) }</td>
                            </tr>
                            <tr>
                                <td className='p-bold'>Créditeur</td>
                                <td>{ swanIncomingPayment.creditorName }</td>
                            </tr>
                            <tr>
                                <td className='p-bold'>Iban crédité</td>
                                <td>{ swanIncomingPayment.creditedIban }</td>
                            </tr>
                            <tr>
                                <td className='p-bold'>Customer</td>
                                <td>
                                    { !!matchingCustomer
                                        ? <Link id='customer-link'
                                                to={customerUrl(matchingCustomer.id)}>
                                            { matchingCustomer.company.name }
                                        </Link>
                                        : '<<unknown>>'
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className='p-bold'>Libellé</td>
                                <td>{ swanIncomingPayment.label }</td>
                            </tr>
                            <tr>
                                <td className='p-bold'>Référence</td>
                                <td>{ swanIncomingPayment.reference }</td>
                            </tr>
                            <tr>
                                <td className='p-bold'>Montant</td>
                                <td>{ formatAmountExtended(swanIncomingPayment.creditedAmount, true, swanIncomingPayment.currency) }</td>
                            </tr>
                            <tr>
                                <td className='p-bold'>Montant Matché</td>
                                <td>{ formatAmount(swanIncomingPayment.matchedAmount) }</td>
                            </tr>
                            <tr>
                                <td className='p-bold'>Montant versé à la plateforme</td>
                                <td>
                                    { formatAmount(
                                        (swanIncomingPayment.noMatchDateTime !== null ? oldPaymentAmountRemaining : 0)
                                        + sum(existingInvoiceMatches.filter((m) => m.isSentToCustomer).map((m) => m.amount))
                                    ) }
                                </td>
                            </tr>
                            <tr>
                                <td className='p-bold'>Montant restant</td>
                                <td>{ formatAmount(swanIncomingPayment.noMatchDateTime !== null ? 0 : oldPaymentAmountRemaining) }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='p-margin-top-3 p-margin-left-6 p-margin-right-6'>
                <div className='level'>
                    <div className='level-left' style={{minWidth: '50%'}}>
                        <Input inputValue={searchInput}
                               placeHolder='Filtrer'
                               enabled={{
                                   updateInputValue: setSearchInput,
                                   validateInput: () => null,
                                   innerId: {
                                       value: 'filterId',
                                       autofocus: true
                                   }
                               }} />
                    </div>

                    <div className='level-right p-margin-right-5'>
                        { oldPaymentAmountRemaining <= 0
                            ? <div>
                                <div className='p-bold'>Settled</div>
                                { swanIncomingPayment.noMatchDateTime !== null &&
                                    <div>No Match le {formatAdminDateStr(swanIncomingPayment.noMatchDateTime)}</div>
                                }
                                { swanIncomingPayment.archivedDateTime !== null &&
                                    <div>
                                        <div>Archivé le {formatAdminDateStr(swanIncomingPayment.archivedDateTime)}</div>
                                        <div>Commentaire : {swanIncomingPayment.archivedComment ?? '<<none>>'}</div>
                                    </div>
                                }
                            </div>
                            : <div className='level'>
                                <div className='level-item'>
                                    { !matchingCustomer
                                        ? <div className='p-bold'>Aucune plateforme n'a été identifiée à partir de l'IBAN. Le Matching n'est pas possible.</div>
                                        : swanIncomingPayment.currency !== EURO_CODE
                                            ? <div className='p-bold'>Le montant du paiement n'est pas en EURO. Le Matching n'est pas possible.</div>
                                            : !!newInvoiceMatchesFailable.errors
                                                ? <div className='p-bold p-error-message'>{ newInvoiceMatchesFailable.errors.join(', ') }</div>
                                                : !!authUser?.canManageFinancings &&
                                                    <div className='level'>
                                                        <div className='level-item'>
                                                            <SetNoMatchFlaggedButton axios={axios}
                                                                                     swanIncomingPaymentId={swanIncomingPayment.id}
                                                                                     flag={!!newInvoiceMatches}
                                                                                     setNewInvoiceMatches={setNewInvoiceMatches}
                                                                                     kind={KIND_PRIMARY}
                                                                                     size={SIZE_COMPACT} />
                                                        </div>
    
                                                        <div className='level-item'>
                                                            <MatchSwanIncomingPaymentButton axios={axios}
                                                                                            swanIncomingPaymentId={swanIncomingPayment.id}
                                                                                            oldPaymentAmountRemaining={oldPaymentAmountRemaining}
                                                                                            newInvoiceMatches={newInvoiceMatchesFailable.result}
                                                                                            clearNewInvoiceMatches={() => setNewInvoiceMatches([])}
                                                                                            kind={KIND_PRIMARY}
                                                                                            size={SIZE_COMPACT} />
                                                        </div>
                                                    </div>
    
                                    }
                                </div>

                                { swanIncomingPayment.matchedAmount === 0 &&
                                    !!newInvoiceMatches &&
                                    !!authUser?.canManageFinancings &&
                                    <div className='level-item'>
                                        <ArchiveSwanIncomingPaymentButton axios={axios}
                                                                          swanIncomingPaymentId={swanIncomingPayment.id}
                                                                          kind={KIND_PRIMARY}
                                                                          size={SIZE_COMPACT} />
                                    </div>
                                }
                            </div> 
                        }    
                    </div>
                </div>

                <div className='table-container'>
                    <table className='table is-bordered is-striped is-hoverable p-full-width'>
                        <thead>
                            <tr>
                                <th>Numéro de facture</th>
                                <th>Date d'échéance</th>
                                { !swanIncomingPayment.matchingCustomer?.directSeller &&
                                    <th>Seller</th>
                                }
                                <th>Buyer</th>
                                <th>Montant</th>
                                <th>Montant restant</th>
                                <th>Statut</th>
                                <th>Match Complet</th>
                                <th>Match Partiel</th>
                                <th>
                                    Montant du Match
                                    { !newInvoiceMatchesFailable.errors &&
                                        <div>(reste { formatAmount(oldPaymentAmountRemaining - (newInvoiceMatchesFailable.result?.total ?? 0)) })</div>
                                    }
                                </th>
                            </tr>
                        </thead>
        
                        <tbody>
                            <SwanIncomingPaymentDetailsMatchedInvoicesLoaded existingInvoiceMatches={existingInvoiceMatches}
                                                                             existingMatchedInvoices={existingMatchedInvoices}
                                                                             existingMatchedInvoicesBuyerCompanies={existingMatchedInvoicesBuyerCompanies}
                                                                             existingMatchedInvoicesSellers={existingMatchedInvoicesSellers}
                                                                             paymentToDirectSeller={!!swanIncomingPayment.matchingCustomer?.directSeller}/>

                            { !!matchingCustomer && oldPaymentAmountRemaining > 0 &&
                                <>
                                    {/* to show a barrier between old matched invoices and new candidates */}
                                    { existingInvoiceMatches.length > 0 &&
                                        <tr>
                                            <td colSpan={10} style={{textAlign: 'center'}} className='title'>Candidats</td>
                                        </tr>
                                    }
            
                                    <SwanIncomingPaymentDetailsCandidateInvoicesRows axios={axios}
                                                                                     swanIncomingPaymentId={swanIncomingPayment.id}
                                                                                     customerId={matchingCustomer.id}
                                                                                     searchInput={searchInput}
                                                                                     matchEnabled={!!newInvoiceMatches && !!authUser?.canManageFinancings
                                                                                         ? {
                                                                                            newInvoiceMatches,
                                                                                            setNewInvoiceMatches,
                                                                                            newPaymentAmountRemaining: !newInvoiceMatchesFailable.errors
                                                                                                ? oldPaymentAmountRemaining - (newInvoiceMatchesFailable.result?.total ?? 0)
                                                                                                : null,
                                                                                         }
                                                                                         : null
                                                                                     } />
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default SwanIncomingPaymentDetailsLoaded;
