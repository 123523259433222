import { Link, Outlet, useLocation } from "react-router-dom";
import { ICustomerDetails } from "./CustomerInfos/customerDetailsQuery";
import IAccessToken from "../../IAccessToken";
import CustomerTabs from "./CustomerTabs";
import { customerUrlTab, CustomersUrl } from "./customersUrls";

const CustomerPageLoaded = (
    {
        accessToken,
        customerId,
        customerDetails
    }: {
        accessToken: IAccessToken;
        customerId: string;
        customerDetails: ICustomerDetails;
    }
) => {
    const fullPath = useLocation().pathname;
    const currentRoute = fullPath.substring(CustomersUrl.length + 36 + 1 /* / + guid size */);
    return (
        <div>
            <div className='tabs'>
                <ul>
                    { CustomerTabs
                        .filter((p) => p.filter(customerDetails))
                        .map((p, index) => (
                            <li key={`t-${index}`} id={currentRoute === p.path ? 'is-active' : ''}>
                                <Link to={`${customerUrlTab(customerId, p.path)}`}>
                                    { p.title }
                                </Link>
                            </li>
                    )) }
                </ul>
            </div>

            <div className='p-margin-4'>
                <Outlet context={{customerDetails, accessTokenO : accessToken}}/>
            </div>
        </div>
    );
}

export default CustomerPageLoaded;
