import IAccessToken from "../IAccessToken";
import {ReactElement} from "react";
import {useOutletContext} from "react-router-dom";

const WithAccessTokenOContext = (
    {
        child
    }: {
        child: (accessTokenO: IAccessToken | null) => ReactElement;
    }
) => {
    const {accessTokenO} = useOutletContext<{accessTokenO: IAccessToken | null}>();
    return child(accessTokenO);
}

export default WithAccessTokenOContext;