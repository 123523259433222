import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import { ConfidenceFlag } from "../../utils/ConfidenceFlags";
import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";

export const sellersQueryKey = ['sellers'];

export const SellersQuery =
    `{
        sellers {
            id
            creationDateTime
            email
            isBlocked
            certif {
                solvaConfidenceFlag
                fraudConfidenceFlag
            }
            company { name identifier { ${IntCompanyIdentifierGraphQLFields} } }
            customer { directSeller { id } }
        }
    }`;

export interface ISellerQueryResult {
    id: string;
    creationDateTime: string;
    email: string;
    isBlocked: boolean;
    certif: {
        solvaConfidenceFlag: ConfidenceFlag;
        fraudConfidenceFlag: ConfidenceFlag;
    } | null;
    company: {
        name: string;
        identifier: IIntCompanyIdentifier;
    };
    customer: {
        directSeller: {
            id: string;
        } | null;
    };
}

export interface ISellersQueryResult {
    sellers: Array<ISellerQueryResult>;
}
