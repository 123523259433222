import { ResultOrErrors } from "../../../../utils/ResultOrErrors";

export const getUpdatePercentageParam = (
    oldPercentage: number,
    newPercentage: string
): ResultOrErrors<number> => {
    const sanitizedNewPercentage = newPercentage.replace(',', '.');
    const newPercentageParsed = parseInt(Math.round(parseFloat(sanitizedNewPercentage) * 100).toString()); // avoid weird parsing like 79.9 => 79.900000001
    const newPercentageSplit = sanitizedNewPercentage.split('.');

    return newPercentageParsed === oldPercentage 
        ? ResultOrErrors.fromError(["no update"])
        : newPercentageSplit.length === 1 || newPercentageSplit[1].length <= 2 // Check manually if there is no . or 2 digits at most after
            ? ResultOrErrors.fromResult(newPercentageParsed)
            : ResultOrErrors.fromError(["trop de chiffres après la virgule (max 2)"]);
}