import {Link} from "react-router-dom";
import { promiseWrapper } from "factor-lib/utils/utils";
import Modal from "factor-lib/Modal";
import Button from "factor-lib/Buttons/Button";
import {invoiceUrl} from "../../Pages/Invoices/invoicesUrls";

const InvoiceAlreadyExistModal = (
    {
        invoiceId,
        closeModal
    }: {
        invoiceId: string;
        closeModal: () => void;
    }
) =>
    // TODO : reuse TextOnlyModal ?
    <Modal close={closeModal}
           maxWidth={null}
           fullMaxWidth={false}>
        <div className='p-padding-top-3 p-padding-bottom-4 p-padding-left-4 p-padding-right-4 p-vertical-center'>
            <div className='p-margin-bottom-5'>
                Une facture avec le meme <Link to={invoiceUrl(invoiceId)}>numéro</Link> existe déjà
            </div>
            <Button id='DuplicateNumberId'
                    text='Ok'
                    actionO={promiseWrapper(closeModal)}
                    isLoading={false} />
        </div>
    </Modal>;

export default InvoiceAlreadyExistModal;