import { Axios } from "axios";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import Modal from "factor-lib/Modal";
import { useState } from "react";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import DateInput, { formatDateInput, parseDateInput, validateDateInputWithinRange } from "factor-lib/forms/DateInput/DateInput";
import {
    IBuyerEvent, IBuyerEmail, IBuyerPhone, IBuyer, IBuyerEventContact
} from "../buyerCompanyDetailsQuery";
import BuyerEventInvoices from "./BuyerEventInvoices";
import {
    BUYER_EVENT_EMAIL_TYPES, BUYER_EVENT_PHONE_TYPES, BUYER_EVENT_TYPES,
    BUYER_EVENT_TYPES_OPTIONS,
    canUpdateBuyerEvent,
    DEFAULT_BUYER_EVENT_TYPE,
    getDateAndTime,
    getFormattedHoursAndMinutes,
    isValidTime
} from "./buyerEventUtils";
import { ResultOrErrors } from "../../../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../../../utils/ShowValidationErrorsButton";
import BuyerEventUpdateButtonEnabled, { getUpdateBuyerEventParams, IUpdateBuyerEventParams } from "./BuyerEventUpdateButtonEnabled";
import BuyerEventAddButtonEnabled from "./BuyerEventAddButtonEnabled";
import { useMsal } from "@azure/msal-react";
import IAuthUser from "../../../../IAuthUser";
import BuyerEventTypeAndContact, {
    getBuyerEventContactParam
} from "./BuyerEventTypeAndContact";
import {dateDiffDays, serverDateDeserialization, serverDateLocalTimeSerialization } from "factor-lib/utils/dateUtils";

const AddText = 'Ajouter';
const UpdateText = 'Modifier';
const AddUpdateButtonSize = SIZE_FIXED;
const AddUpdateButtonKind = KIND_PRIMARY;

export interface IBuyerContacts {
    // GraphQL mapping
    buyerEmails: IBuyerEmail[];
    buyerPhones: IBuyerPhone[];
}

export interface IBuyersAndContacts extends IBuyerContacts {
    buyers: IBuyer[];
}

interface IBuyerEventInput {
    eventType: string;
    eventDateInput: string;
    eventTimeInput: string;
    eventNotesInput: string;
    contactInput: IBuyerEventContact | null; // this null -> not yet selected
    selectedInvoicesIds: string[];
}

const getInitialExistingBuyerEventInput = (
    buyerEvent: IBuyerEvent,
    initialSelectedInvoicesIds: string[]
): IBuyerEventInput => {
    const buyerEventDateTime: Date = serverDateDeserialization(buyerEvent.eventDateTime);

    return ({
        eventType: BUYER_EVENT_TYPES_OPTIONS[buyerEvent.type].value,
        eventDateInput: formatDateInput(buyerEventDateTime),
        eventTimeInput: getFormattedHoursAndMinutes(buyerEventDateTime),
        eventNotesInput: buyerEvent.notes ?? '',
        contactInput: buyerEvent.email ?? buyerEvent.phone,
        selectedInvoicesIds: initialSelectedInvoicesIds,
    });
};

const getInitialNewBuyerEventInput = (
    initialSelectedInvoicesIds: string[]
): IBuyerEventInput => {
    const now = new Date();
    const eventType = DEFAULT_BUYER_EVENT_TYPE;

    return ({
        eventType: eventType,
        eventDateInput: formatDateInput(now),
        eventTimeInput: getFormattedHoursAndMinutes(now),
        eventNotesInput: '',
        contactInput: null,
        selectedInvoicesIds: initialSelectedInvoicesIds
    });
};

export interface IBuyerEventParams {
    eventType: number;
    eventDateTime: string;
    eventNotes: string | null;
    emailId: string | null;
    phoneId: string | null;
    invoiceIds: string[];
}

export const getBuyerEventParams = (
    buyerEventInput: IBuyerEventInput
): ResultOrErrors<IBuyerEventParams> => {
    const validationErrors: string[] = [];

    const buyerEventType = buyerEventInput.eventType;
    const buyerEventTypeIsEmail = BUYER_EVENT_EMAIL_TYPES.includes(buyerEventType);
    const buyerEventTypeIsPhone = BUYER_EVENT_PHONE_TYPES.includes(buyerEventType);

    const buyerEventContactInput = buyerEventInput.contactInput;
    const buyerEventContactParamFailable = buyerEventTypeIsEmail
        ? getBuyerEventContactParam(buyerEventContactInput)
        : buyerEventTypeIsPhone
            ? getBuyerEventContactParam(buyerEventContactInput)
            : null;

    if (!!buyerEventContactParamFailable && buyerEventContactParamFailable.errors !== null) {
        validationErrors.push(...buyerEventContactParamFailable.errors);
    }

    const buyerEventDate: Date | null = parseDateInput(buyerEventInput.eventDateInput);
    if (buyerEventDate === null) {
        validationErrors.push('la date est invalide');
    } else if (dateDiffDays(new Date(), buyerEventDate) > 0) {
        validationErrors.push('la date doit être dans le passé');
    }

    if (!isValidTime(buyerEventInput.eventTimeInput)) {
        validationErrors.push(`l'heure et les minutes sont invalides`);
    }

    if (validationErrors.length > 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    const buyerEventDateTime = getDateAndTime(buyerEventDate!, buyerEventInput.eventTimeInput);

    return ResultOrErrors.fromResult({
        eventType: BUYER_EVENT_TYPES.indexOf(buyerEventType),
        eventDateTime: serverDateLocalTimeSerialization(buyerEventDateTime),
        eventNotes: buyerEventInput.eventNotesInput === '' ? null : buyerEventInput.eventNotesInput,
        emailId: buyerEventTypeIsEmail ? buyerEventContactParamFailable!.result! : null,
        phoneId: buyerEventTypeIsPhone ? buyerEventContactParamFailable!.result! : null,
        invoiceIds: buyerEventInput.selectedInvoicesIds,
    });
}

const BuyerEventModal = (
    {
        axios,
        authUser,
        buyerCompanyId,
        buyersAndContactsLoaded,
        existingBuyerEvent,
        initialSelectedInvoicesIds,
        alwaysSelectedInvoiceId,
        closeModal
    } : {
        axios: Axios;
        authUser: IAuthUser | null;
        buyerCompanyId: string;
        buyersAndContactsLoaded: IBuyersAndContacts | null;
        existingBuyerEvent: IBuyerEvent | null; // Ideally : split this : every individual attribute may be pre-defined or not
        initialSelectedInvoicesIds: string[];
        alwaysSelectedInvoiceId: string | null;
        closeModal: () => void;
    }
) => {
    const initialBuyerEventInput: IBuyerEventInput = !!existingBuyerEvent
        ? getInitialExistingBuyerEventInput(existingBuyerEvent, initialSelectedInvoicesIds)
        : getInitialNewBuyerEventInput(initialSelectedInvoicesIds);

    const [ eventType, setEventType ] = useState(initialBuyerEventInput.eventType);
    const [ eventDateInput, setEventDateInput ] = useState(initialBuyerEventInput.eventDateInput);
    const [ eventTimeInput, setEventTimeInput ] = useState(initialBuyerEventInput.eventTimeInput);
    const [ eventNotesInput, setEventNotesInput ] = useState(initialBuyerEventInput.eventNotesInput);
    const [ contactInput, setContactInput ] = useState(initialBuyerEventInput.contactInput);
    const [ selectedInvoicesIds, setSelectedInvoicesIds ] = useState(initialBuyerEventInput.selectedInvoicesIds);

    const buyerEventInput: IBuyerEventInput = ({
        eventType,
        eventDateInput,
        eventTimeInput,
        eventNotesInput,
        contactInput,
        selectedInvoicesIds
    });

    const buyerEventParams: ResultOrErrors<IBuyerEventParams> = getBuyerEventParams(buyerEventInput);

    const updateBuyerEventParams: ResultOrErrors<IUpdateBuyerEventParams> | null = !!existingBuyerEvent
        ? getUpdateBuyerEventParams(existingBuyerEvent, initialSelectedInvoicesIds, buyerEventParams)
        : null;

    const today = new Date();

    const { accounts } = useMsal();
    const currentOpsId = accounts[0].localAccountId;

    const canEdit = authUser?.canManageFinancings && (!existingBuyerEvent || canUpdateBuyerEvent(existingBuyerEvent, currentOpsId));

    return (
        <Modal id='buyerEventModal'
                maxWidth={null}
                fullMaxWidth={false}
                close={closeModal}>
            <div className='container p-both-center p-padding-4'>
                <div style={{width: 600}}>
                    <div className='title p-vertical-center'>
                        { !!existingBuyerEvent ? 'Modifier' : 'Ajouter' } un événement
                    </div>

                    <BuyerEventTypeAndContact className='p-margin-top-4'
                                              axios={axios}
                                              buyerCompanyId={buyerCompanyId}
                                              buyersAndContactsLoaded={buyersAndContactsLoaded}
                                              eventType={eventType}
                                              contactInput={contactInput}
                                              enabled={canEdit ? ({
                                                  setEventType: setEventType,
                                                  setContactInput: setContactInput
                                              }) : null} />

                    <div className='p-margin-top-4'>
                        <span className='p-bold'>Date/Heure</span>

                        <div className='p-horizontal-center' >
                            <DateInput dateInputValue={eventDateInput}
                                       enabled={canEdit ? {
                                           updateDateInputValue: setEventDateInput,
                                           validateDate: () => validateDateInputWithinRange(eventDateInput, null, today)
                                       } : null} />

                            <div className='p-margin-left-5'>
                                <input className='input'
                                       style={{maxWidth: 150}}
                                       value={eventTimeInput}
                                       onChange={canEdit ? (e) => setEventTimeInput(e.target.value) : undefined} />
                            </div>
                        </div>
                    </div>

                    <BuyerEventInvoices className='p-margin-top-4'
                                        axios={axios}
                                        buyerCompanyId={buyerCompanyId}
                                        initialSelectedInvoicesIds={initialSelectedInvoicesIds}
                                        selectedInvoicesIds={selectedInvoicesIds}
                                        setSelectedInvoicesIds={setSelectedInvoicesIds}
                                        alwaysSelectedInvoiceId={alwaysSelectedInvoiceId} />

                    <div className='p-margin-top-4'>
                        <span className='p-bold'>Commentaire</span>

                        <textarea className='textarea p-margin-top-6'
                                  placeholder='Notes'
                                  value={eventNotesInput}
                                  onChange={canEdit ? (e) => setEventNotesInput(e.target.value) : undefined} />
                    </div>

                    { canEdit &&
                        <div className='p-vertical-center p-margin-top-4'>
                            { !!existingBuyerEvent
                                ? !!updateBuyerEventParams
                                    ? !!updateBuyerEventParams.result
                                        // update valid
                                        ? <BuyerEventUpdateButtonEnabled axios={axios}
                                                                         existingBuyerEvent={existingBuyerEvent}
                                                                         params={updateBuyerEventParams.result}
                                                                         selectedInvoicesIds={selectedInvoicesIds}
                                                                         previousSelectedInvoiceIds={initialBuyerEventInput.selectedInvoicesIds}
                                                                         buyerCompanyId={buyerCompanyId}
                                                                         closeModal={closeModal}
                                                                         kind={AddUpdateButtonKind}
                                                                         size={AddUpdateButtonSize}
                                                                         text={UpdateText} />
                                        // update invalid
                                        : <ShowValidationErrorsButton validationErrors={updateBuyerEventParams.errors!}
                                                                      kind={AddUpdateButtonKind}
                                                                      size={AddUpdateButtonSize}
                                                                      text={UpdateText} />
                                    // no update
                                    : <ButtonDisabled kind={AddUpdateButtonKind}
                                                      size={AddUpdateButtonSize}
                                                      text={UpdateText} />

                                : !!buyerEventParams.result
                                    // add valid
                                    ? <BuyerEventAddButtonEnabled axios={axios}
                                                                  params={{
                                                                      buyerCompanyId,
                                                                      ...buyerEventParams.result
                                                                  }}
                                                                  selectedInvoicesIds={selectedInvoicesIds}
                                                                  currentOpsId={currentOpsId}
                                                                  closeModal={closeModal}
                                                                  kind={AddUpdateButtonKind}
                                                                  size={AddUpdateButtonSize}
                                                                  text={AddText} />
                                    // add invalid
                                    : <ShowValidationErrorsButton validationErrors={buyerEventParams.errors!}
                                                                  kind={AddUpdateButtonKind}
                                                                  size={AddUpdateButtonSize}
                                                                  text={AddText} />
                            }
                        </div>
                    }
                </div>
            </div>
        </Modal>
    );
}

export default BuyerEventModal;
