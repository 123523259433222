import UpdateCustomerContact from "./CustomerMainInfos/UpdateCustomerContact";
import IAuthUser from "../../../../IAuthUser";
import { Axios } from "axios";
import UpdateCustomerBillingIban from "./CustomerMainInfos/UpdateCustomerBillingIban";
import { formatIntCompanyIdentifier, IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";

const CustomerMainInfosSection = (
    {
        axios,
        authUser,
        customerId,
        hasCertifiedSellers,
        contactEmail,
        contactPhone,
        billingIbanO,
        company
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        customerId: string;
        hasCertifiedSellers: boolean;
        contactEmail: string;
        contactPhone: string;
        billingIbanO: string | null;
        company: {
            id: string;
            name: string;
            identifier: IIntCompanyIdentifier;
        };
    }
) =>
    <div>
        <table className='table is-narrow'>
            <tbody>
                <tr>
                    <td className='p-bold'>Nom société</td>
                    <td style={{textTransform: 'uppercase'}}>{ company.name }</td>
                </tr>
                <tr>
                    <td className='p-bold'>Identifiant</td>
                    <td>{ formatIntCompanyIdentifier(company.identifier) }</td>
                </tr>
                <tr>
                    <td className='p-bold'>Email de contact</td>
                    <td>{ contactEmail }</td>
                </tr>
                <tr>
                    <td className='p-bold'>Téléphone de contact</td>
                    <td>{ contactPhone }</td>
                </tr>
                <tr>
                    <td className='p-bold'>IBAN du billing</td>
                    <td>{ billingIbanO ?? '<<none>>' }</td>
                </tr>
            </tbody>
        </table>

        { !!authUser?.canManageProfiles &&
            <div className='p-horizontal-center p-margin-top-4'>
                <UpdateCustomerContact className='p-margin-left-5'
                                       axios={axios}
                                       customerId={customerId}
                                       contactEmail={contactEmail}
                                       contactPhone={contactPhone} />

                { (!hasCertifiedSellers || authUser?.canUpdateIbanPostCertif) &&
                    <UpdateCustomerBillingIban className='p-margin-left-5'
                                               axios={axios}
                                               customerId={customerId}
                                               hasCertifiedSellers={hasCertifiedSellers}
                                               billingIbanO={billingIbanO} />
                }
            </div>
        }
    </div>;

export default CustomerMainInfosSection;
