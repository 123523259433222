

const checkDuplicateInvoiceId = (message: string): string | null => {
    const matchO = message.match(/^Facture existe déjà : '([-0-9a-f]+)'$/);
    if (!!matchO) {
        return matchO[1];
    }
    return null;
}

export default checkDuplicateInvoiceId;