import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import Input from "factor-lib/forms/Inputs/Input";
import Button, {KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import {useContext, useState} from "react";
import {Axios} from "axios";
import CurrentEnv from "../../envs/CurrentEnv";
import Auth2AxiosCreatorWrapper from "../../Auth2AxiosCreatorWrapper";
import SirenAndDataClientLoaded from "./SirenAndDataClientLoaded";
import SearchRedirect from "./SearchRedirect";
import navigateToNewSirenForBilans from "./navigateToNewSirenForBilans";
import {NavigateFunction} from "react-router-dom";
import Loader from "factor-lib/Loader";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import {validateSirenError} from "factor-lib/forms/Inputs/sirenUtils";

// TODO : review, we should have a single button
const buttonKind = KIND_PRIMARY;
const buttonSize = SIZE_FIXED;
const buttonText = 'Editer';

const DataPage = (
    {
        initialSirenO,
        isMsalAuthenticationComplete
    }: {
        initialSirenO: string | null;
        isMsalAuthenticationComplete: boolean;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    // TODO : initial from url ?
    const [sirenInput, setSirenInput] = useState(initialSirenO || '');
    return (
        <div className='p-horizontal-center p-padding-left-4 p-padding-right-4'>
            <div>
                <Input inputValue={sirenInput}
                       placeHolder='SIREN'
                       enabled={{
                           updateInputValue: setSirenInput,
                           validateInput: () => validateSirenError(sirenInput),
                           innerId: {
                               value: 'sirenInner',
                               autofocus: true
                           },
                           tryComplete: () => navigateToNewSirenForBilans(navigate, sirenInput)
                       }} />
            </div>
            <div className='p-margin-left-4'>
                { !validateSirenError(sirenInput)
                    ? (
                        sirenInput === initialSirenO
                            ? isMsalAuthenticationComplete
                                ? <Auth2AxiosCreatorWrapper backend={CurrentEnv.dataBackend}
                                                            child={(dataAxiosO: Axios | null) =>
                                                            (!!dataAxiosO &&
                                                                <SirenAndDataClientLoaded dataAxios={dataAxiosO}
                                                                                        siren={sirenInput} />
                                                            ) || <Button id='dataSearchRedirect-loading'
                                                                        kind={buttonKind}
                                                                        size={buttonSize}
                                                                        text={buttonText}
                                                                        isLoading={true}
                                                                        actionO={null} /> }/>
                                : <Loader/>
                            : <SearchRedirect kind={buttonKind}
                                              size={buttonSize}
                                              text={buttonText}
                                              siren={sirenInput} />
                    ) : <ButtonDisabled kind={buttonKind}
                                        size={buttonSize}
                                        text={buttonText} />
                }
            </div>
        </div>
    );
}


export default DataPage;
