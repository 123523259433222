import {Axios} from "axios";
import { KIND_PRIMARY, SIZE_COMPACT, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { useState } from "react";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import DateInput, { parseDateInput, validateDateInputWithinRange } from "factor-lib/forms/DateInput/DateInput";
import Input from "factor-lib/forms/Inputs/Input";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import IOption from "factor-lib/forms/Select/IOption";
import ShowValidationErrorsButton from "../../../../utils/ShowValidationErrorsButton";
import { IInvoiceDetailsQueryResult, invoiceDetailsQueryKey } from "../../invoiceDetailsQuery";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { FileUploadTimeoutMilliseconds } from "factor-lib/serverUtils/axiosConfigUtils";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import {dateDiffDays, serverDateSerialization } from "factor-lib/utils/dateUtils";

const AddText = 'Ajouter';

const otherDocumentType = 'OTHER';
const purchaseOrderDocumentType = 'PURCHASE_ORDER';
const deliveryNoteDocumentType = 'DELIVERY_NOTE';

const documentTypes = [
    otherDocumentType,
    purchaseOrderDocumentType,
    deliveryNoteDocumentType
];

export const documentTypeFriendlyNames: Map<string, string> = new Map([
    [otherDocumentType, 'Autre'],
    [purchaseOrderDocumentType, 'Bon de commande'],
    [deliveryNoteDocumentType, 'Bon de livraison']
]);

const documentTypeOptions: Array<IOption<string>> = [
    {
        label: documentTypeFriendlyNames.get(purchaseOrderDocumentType)!,
        value: purchaseOrderDocumentType
    },
    {
        label: documentTypeFriendlyNames.get(deliveryNoteDocumentType)!,
        value: deliveryNoteDocumentType
    },
    {
        label: documentTypeFriendlyNames.get(otherDocumentType)!,
        value: otherDocumentType
    }
];

interface IAddInvoiceAdditionalDocumentResponse {
    invoiceAdditionalDocumentId: string;
}

const AddInvoiceAdditionalDocumentEnabled = (
    {
        axios,
        invoiceId,
        documentType,
        givenDate,
        title,
        file,
        onSuccess,
        kind,
        size,
        text
    } : {
        axios: Axios;
        invoiceId: string;
        documentType: string;
        givenDate: Date;
        title: string | null;
        file: File;
        onSuccess: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const formData = new FormData();

    formData.append('invoiceId', invoiceId);

    formData.append('documentType', documentType);
    formData.append('givenDate', serverDateSerialization(givenDate));

    if (!!title) {
        formData.append('title', title);
    }

    formData.append('file', file);

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const addInvoiceAdditionalDocumentMutation: UseMutationResult<IAddInvoiceAdditionalDocumentResponse, any, FormData> =
        useMutation<IAddInvoiceAdditionalDocumentResponse, any, FormData>(
            async (formData2) =>
                (await axios.post<IAddInvoiceAdditionalDocumentResponse>(
                    `/adminInvoiceFiles/${invoiceId}/additional`,
                    formData2,
                    {
                        timeout: FileUploadTimeoutMilliseconds
                    }
                )).data,
            ({
                onSuccess: (response) => {
                    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                        invoiceDetailsQueryKey(invoiceId),
                        (old: IInvoiceDetailsQueryResult | undefined) => {
                            const { invoiceDetails } = old!;
                            const r: IInvoiceDetailsQueryResult = ({
                                ...old!,
                                invoiceDetails: {
                                    ...invoiceDetails,
                                    additionalDocuments: [
                                        ...invoiceDetails.additionalDocuments,
                                        ({
                                            id: response.invoiceAdditionalDocumentId,
                                            type: documentType,
                                            blobFile: {
                                                fileName: file.name
                                            },
                                            creationDateTime: serverDateSerialization(new Date()),
                                            givenDate: serverDateSerialization(givenDate),
                                            title: title
                                        })
                                    ]
                                }
                            });
                            return r;
                        }
                    );
                    onSuccess();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='AddInvoiceAdditionalDocumentButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={addInvoiceAdditionalDocumentMutation}
                               displayFullError={true}
                               value={formData} />
    );
}

const getValidationErrors = (
    documentType: string | null,
    givenDateInput: string,
    titleInput: string,
    file: File | null
): string[] => {

    const validationErrors: string[] = [];

    if (documentType === null || !documentTypes.includes(documentType)) {
        validationErrors.push(`Le type du document est invalide`);
    }

    const givenDateInputParsed: Date | null = parseDateInput(givenDateInput);
    if (givenDateInputParsed === null) {
        validationErrors.push(`La date est invalide`);
    } else if (dateDiffDays(givenDateInputParsed, new Date()) < 0) {
        validationErrors.push(`La date ne peut pas être dans le futur`);
    }

    if (titleInput.length > 256) {
        validationErrors.push('Le titre est trop long (maximum 256 caractères)');
    }

    if (file === null) {
        validationErrors.push(`Aucun fichier n'a été choisi`);
    } else if (file.size > 10 * 1024 * 1024) {
        validationErrors.push('La taille du fichier est trop grande (maximum 10 MB)');
    }

    return validationErrors;
}

const AddInvoiceAdditionalDocumentModalContent = (
    {
        axios,
        invoiceId,
        closeModal,
    } : {
        axios: Axios;
        invoiceId: string;
        closeModal: () => void;
    }
) => {
    const [documentTypeOption, setDocumentTypeOption] = useState<IOption<string> | null>(null);
    const [givenDateInput, setGivenDateInput] = useState('');
    const [title, setTitle] = useState('');
    const [file, setFile] = useState<File | null>(null);

    const validationErrors: string[] = getValidationErrors(
        documentTypeOption?.value ?? null,
        givenDateInput,
        title,
        file
    );

    return (
        <div className='p-padding-4'>
            <span className='title p-both-center'>Ajouter un document pour la facture</span>

            <div className='p-margin-top-4'>
                <div className='p-padding-bottom-7'>Type de document</div>
                <SingleSelect options={documentTypeOptions}
                              selectedOption={documentTypeOption}
                              selectOption={setDocumentTypeOption} />
            </div>

            <div className='p-margin-top-4'>
                <div className='p-padding-bottom-7'>Date</div>
                <DateInput dateInputValue={givenDateInput}
                           enabled={{
                               updateDateInputValue: setGivenDateInput,
                               validateDate: () => validateDateInputWithinRange(givenDateInput, null, new Date()),
                               innerId: {
                                   value: 'dueDateInner',
                                   autofocus: true
                               }
                           }} />
            </div>

            <div className='p-margin-top-4'>
                <div className='p-padding-bottom-7'>Titre (Optionnel)</div>
                <Input inputValue={title}
                       enabled={{
                           updateInputValue: setTitle,
                           validateInput: () => null
                       }} />
            </div>

            <div className='p-both-center p-margin-top-3'>
                <input className='control'
                       id='filePicker'
                       type='file'
                       onChange={(event) => {
                           const files = event.target.files;
                           if (!!files && files.length > 0) {
                               setFile(files[0]);
                           }
                       }}
                       accept='application/pdf' />
            </div>

            <div className='p-vertical-center p-margin-top-3'>
                { validationErrors.length === 0
                    ? <AddInvoiceAdditionalDocumentEnabled axios={axios}
                                                           invoiceId={invoiceId}
                                                           documentType={documentTypeOption!.value}
                                                           givenDate={parseDateInput(givenDateInput)!}
                                                           title={title}
                                                           file={file!}
                                                           onSuccess={closeModal}
                                                           kind={KIND_PRIMARY}
                                                           size={SIZE_FIXED}
                                                           text={AddText} />
                    : <ShowValidationErrorsButton validationErrors={validationErrors}
                                                  kind={KIND_PRIMARY}
                                                  size={SIZE_FIXED}
                                                  text={AddText} />
                }
            </div>
        </div>
    );
}

const AddInvoiceAdditionalDocument = (
    {
        axios,
        invoiceId
    } : {
        axios: Axios;
        invoiceId: string;
    }
) => 
    <ButtonForModal id='displayAddInvoiceAdditionalDocumentModalButton'
                    size={SIZE_COMPACT}
                    text={AddText}
                    modalMaxWidth='480px'
                    modalFullMaxWidth={false}
                    childModalContent={(closeModal) =>
                        <AddInvoiceAdditionalDocumentModalContent axios={axios}
                                                           invoiceId={invoiceId}
                                                           closeModal={closeModal} />
    } />

export default AddInvoiceAdditionalDocument;
