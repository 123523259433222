import {buyerCompanyDetailsQueryKey, IBuyerCompanyDetailsQueryResult} from "../buyerCompanyDetailsQuery";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { Axios } from "axios";
import {useState} from "react";
import Input from "factor-lib/forms/Inputs/Input";
import Button from "factor-lib/Buttons/Button";
import Modal from "factor-lib/Modal";
import BuyerContactDelete from "./BuyerContactDelete";

const BuyerContactUpdateModal = (
    {
        axios,
        buyerCompanyId,
        buyerContactId,
        oldValue,
        validateNewValue,
        buyerContactEndpoint,
        updateQuery,
        deleteUpdateQueryO,
        closeModal
    }: {
        axios: Axios;
        buyerCompanyId: string;
        buyerContactId: string;
        oldValue: string;
        validateNewValue: (newValue: string) => string | null;
        buyerContactEndpoint: (buyerContactId: string) => string;
        updateQuery: (old: IBuyerCompanyDetailsQueryResult, newValue: string) => IBuyerCompanyDetailsQueryResult;
        deleteUpdateQueryO: ((old: IBuyerCompanyDetailsQueryResult) => IBuyerCompanyDetailsQueryResult) | null;
        closeModal: () => void;
    }
) => {
    const [newValueInput, setNewValueInput] = useState<string>(oldValue);

    const queryClient = useQueryClient();

    const updateBuyerContactMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (updateParams2) => {
                await axios.put<void>(
                    `/adminBuyerContacts/${buyerContactEndpoint(buyerContactId)}`,
                    {
                        value: updateParams2
                    }
                );
            },
            ({
                onSuccess: (_, newValue) => {
                    queryClient.setQueryData<IBuyerCompanyDetailsQueryResult>(
                        buyerCompanyDetailsQueryKey(buyerCompanyId),
                        (old: IBuyerCompanyDetailsQueryResult | undefined) => !!old ? updateQuery(old, newValue) : undefined
                    );
                    closeModal();
                }
            })
        );

    return (
        <Modal id='buyerContactUpdateModal'
               maxWidth={null}
               fullMaxWidth={false}
               close={closeModal}>

            <div className='p-margin-4 p-vertical-center'>
                <div className='p-bold p-size-5 p-margin-5'>
                    Modifier le contact {oldValue}
                </div>

                <Input inputValue={newValueInput}
                       enabled={{
                           validateInput: () => validateNewValue(newValueInput),
                           updateInputValue: setNewValueInput,
                           innerId: {
                               value: 'value',
                               autofocus: true
                           }
                       }} />

                <Button id='updateBuyerContactButton'
                        className='p-margin-top-4'
                        text='Modifier'
                        isLoading={updateBuyerContactMutation.isLoading}
                        actionO={() => {
                            // show alert, because we are already in a modal
                            if (newValueInput === oldValue) {
                                window.alert('Aucun changement');
                            } else if (validateNewValue(newValueInput) === null) {
                                updateBuyerContactMutation.mutate(newValueInput);
                            } else {
                                window.alert('Contact invalide');
                            }
                        }} />

                { !!deleteUpdateQueryO &&
                    <BuyerContactDelete axios={axios}
                                        className='p-margin-top-4 p-danger-text'
                                        buyerCompanyId={buyerCompanyId}
                                        buyerContactId={buyerContactId}
                                        buyerContactEndpoint={buyerContactEndpoint}
                                        updateQuery={deleteUpdateQueryO}
                                        closeModal={closeModal}/>
                }
            </div>
        </Modal>
    );
}

export default BuyerContactUpdateModal;