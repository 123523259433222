import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import { query } from "factor-lib/utils/graphQLPagination";
import { IPaginated } from 'factor-lib/utils/graphQLPagination';
import IYearMonth from "../IYearMonth";

export const billingsQuery = (pageParam: any | null): IGraphQLParams => ({
    query: `query (${/* $first: Int!, */ ''}$after: PaginationGuidCursor, $orderByField: BillingPaginationOrderByField!, $orderByDirection: PaginationOrderByDirection!) {
        billings{
            list (
                ${/* first: $first,*/ ''}
                after: $after,
                orderByField: $orderByField,
                orderByDirection: $orderByDirection
            ) ${query(`
                id
                customer { company { name } }
                amount
                yearMonth { year month }
                pennyLaneId
                stripeCharge { creationDateTime }
            `)}
        }
    }`,
    variables: {
        after: pageParam,
        orderByField: 'CREATION_DATE_TIME',
        orderByDirection: 'DESC' // TODO : direct const ? , // SortOrder.Desc,
    }
});

export const BillingsQueryKey: string[] =
    ['billings'];

export interface IBillingResult {
    id: string;
    customer: {
        company: {
            name: string;
        };
    };
    amount: number;
    yearMonth: IYearMonth;
    pennyLaneId: string;
    stripeCharge: {
        creationDateTime: string;
    } | null;
}

export interface INewBillingMinThresholdQueryResult {
    billings: {
        list: IPaginated<IBillingResult>;
    }
}