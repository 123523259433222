export const arraysEqual = <T,K>(
    array1: ReadonlyArray<T>,
    array2: ReadonlyArray<T>,
    keyExtractor: (t: T) => K
): boolean => {
    if (array1.length !== array2.length) {
        return false;
    }

    const array2KeySet = new Set<K>(array2.map(keyExtractor));

    for (let element1 of array1) {
        if (!array2KeySet.has(keyExtractor(element1))) {
            return false;
        }
    }

    return true;
};
