import IOption from "factor-lib/forms/Select/IOption";
import { comparingString } from "factor-lib/utils/comparingUtils";

// TODO: Share

export const DEFAULT_FRANCE: IOption<string> = { value: 'FR', label: 'France' };

export const COUNTRY_OPTIONS: Array<IOption<string>> = [
  {value: 'AD', label: 'Andorre' },
  {value: 'AE', label: 'Emirats arabes unis' },
  {value: 'AF', label: 'Afghanistan' },
  {value: 'AG', label: 'Antigua-et-Barbuda' },
  {value: 'AI', label: 'Anguilla' },
  {value: 'AL', label: 'Albanie' },
  {value: 'AM', label: 'Armenie' },
  // {value: 'AN', label: 'TO EXCLUDE' },
  {value: 'AO', label: 'Angola' },
  {value: 'AQ', label: 'Antarctique' },
  {value: 'AR', label: 'Argentine' },
  {value: 'AS', label: 'Samoa americaines' },
  {value: 'AT', label: 'Autriche' },
  {value: 'AU', label: 'Australie' },
  {value: 'AW', label: 'Aruba' },
  // {value: 'AX', label: 'TO EXCLUDE' },
  {value: 'AZ', label: 'Azerbaidjan' },
  {value: 'BA', label: 'Bosnie-Herzegovine' },
  {value: 'BB', label: 'Barbade' },
  {value: 'BD', label: 'Bangladesh' },
  {value: 'BE', label: 'Belgique' },
  {value: 'BF', label: 'Burkina Faso' },
  {value: 'BG', label: 'Bulgarie' },
  {value: 'BH', label: 'Bahrein' },
  {value: 'BI', label: 'Burundi' },
  {value: 'BJ', label: 'Benin' },
  {value: 'BL', label: 'Saint-Barthelemy' },
  {value: 'BM', label: 'Bermudes' },
  {value: 'BN', label: 'Brundi Darussalam' },
  {value: 'BO', label: 'Bolivie' },
  {value: 'BQ', label: 'Bonaire, Saint-Eustache et Saba' },
  {value: 'BR', label: 'Bresil' },
  {value: 'BS', label: 'Bahamas' },
  {value: 'BT', label: 'Bhoutan' },
  // {value: 'BV', label: 'TO EXCLUDE' },
  {value: 'BW', label: 'Botswana' },
  {value: 'BY', label: 'Bielorussie' },
  {value: 'BZ', label: 'Belize' },
  {value: 'CA', label: 'Canada' },
  // {value: 'CC', label: 'TO EXCLUDE' },
  {value: 'CD', label: 'Republique democratique du Congo' },
  {value: 'CF', label: 'Republique centrafricaine' },
  {value: 'CG', label: 'Congo' },
  {value: 'CH', label: 'Suisse' },
  {value: 'CI', label: `Cote d'Ivoire` },
  {value: 'CK', label: 'Iles Cook' },
  {value: 'CL', label: 'Chili' },
  {value: 'CM', label: 'Cameroun' },
  {value: 'CN', label: 'Chine' },
  {value: 'CO', label: 'Colombie' },
  {value: 'CR', label: 'Costa Rica' },
  {value: 'CU', label: 'Cuba' },
  {value: 'CV', label: 'Cabo Verde' },
  // {value: 'CW', label: 'TO EXCLUDE' },
  // {value: 'CX', label: 'TO EXCLUDE' },
  {value: 'CY', label: 'Chypre' },
  {value: 'CZ', label: 'Republique Tcheque' },
  {value: 'DE', label: 'Allemagne' },
  {value: 'DJ', label: 'Djibouti' },
  {value: 'DK', label: 'Danemark' },
  {value: 'DM', label: 'Dominique' },
  {value: 'DO', label: 'Republique dominicaine' },
  {value: 'DZ', label: 'Algerie' },
  {value: 'EC', label: 'Equateur' },
  {value: 'EE', label: 'Estonie' },
  {value: 'EG', label: 'Egypte' },
  {value: 'EH', label: 'Sahara occidental' },
  {value: 'ER', label: 'Erythree' },
  {value: 'ES', label: 'Espagne' },
  {value: 'ET', label: 'Ethiopie' },
  {value: 'FI', label: 'Finlande' },
  {value: 'FJ', label: 'Fidji' },
  {value: 'FK', label: 'Iles Falkland (Malvinas)' },
  {value: 'FM', label: 'Micronesie' },
  {value: 'FO', label: 'Iles Feroe' },
  DEFAULT_FRANCE,
  {value: 'GA', label: 'Gabon' },
  {value: 'GB', label: 'Royaume-Uni' },
  {value: 'GD', label: 'Grenade' },
  {value: 'GE', label: 'Georgie' },
  {value: 'GF', label: 'Guyane française' },
  {value: 'GG', label: 'Guernesey' },
  {value: 'GH', label: 'Ghana' },
  {value: 'GI', label: 'Gibraltar' },
  {value: 'GL', label: 'Groenland' },
  {value: 'GM', label: 'Gambie' },
  {value: 'GN', label: 'Guinée' },
  {value: 'GP', label: 'Guadeloupe' },
  {value: 'GQ', label: 'Guinée Equatoriale' },
  {value: 'GR', label: 'Grece' },
  {value: 'GS', label: 'Georgie du Sud' },
  {value: 'GT', label: 'Guatemala' },
  {value: 'GU', label: 'Guam' },
  {value: 'GW', label: 'Guinée-Bissau' },
  {value: 'GY', label: 'Guyana' },
  {value: 'HK', label: 'Hong Kong' },
  {value: 'HM', label: 'Ile Heard et Iles MacDonald' },
  {value: 'HN', label: 'Honduras' },
  {value: 'HR', label: 'Croatie' },
  {value: 'HT', label: 'Haïti' },
  {value: 'HU', label: 'Hongrie' },
  {value: 'ID', label: 'Indonésie' },
  {value: 'IE', label: 'Irlande' },
  {value: 'IL', label: 'Israël' },
  {value: 'IM', label: 'Ile de Man' },
  {value: 'IN', label: 'Inde' },
  {value: 'IO', label: `Territoire britannique de l'océan Indien` },
  {value: 'IQ', label: 'Iraq' },
  {value: 'IR', label: 'Iran' },
  {value: 'IS', label: 'Islande' },
  {value: 'IT', label: 'Italie' },
  {value: 'JE', label: 'Jersey' },
  {value: 'JM', label: 'Jamaïque' },
  {value: 'JO', label: 'Jordanie' },
  {value: 'JP', label: 'Japon' },
  {value: 'KE', label: 'Kenya' },
  {value: 'KG', label: 'Kirghizistan' },
  {value: 'KH', label: 'Cambodge' },
  {value: 'KI', label: 'Kiribati' },
  {value: 'KM', label: 'Comores' },
  {value: 'KN', label: 'Saint-Kitts-et-Nevis' },
  {value: 'KP', label: 'République populaire démocratique de Corée' },
  {value: 'KR', label: 'République de Corée' },
  {value: 'KW', label: 'Koweït' },
  {value: 'KY', label: 'Ies Caimanes' },
  {value: 'KZ', label: 'Kazakhstan' },
  {value: 'LA', label: 'Laos' },
  {value: 'LB', label: 'Liban' },
  {value: 'LC', label: 'Sainte-Lucie' },
  {value: 'LI', label: 'Liechtenstein' },
  {value: 'LK', label: 'Sri Lanka' },
  {value: 'LR', label: 'Liberia' },
  {value: 'LS', label: 'Lesotho' },
  {value: 'LT', label: 'Lituanie' },
  {value: 'LU', label: 'Luxembourg' },
  {value: 'LV', label: 'Lettonie' },
  {value: 'LY', label: 'Libye' },
  {value: 'MA', label: 'Maroc' },
  {value: 'MC', label: 'Monaco' },
  {value: 'MD', label: 'République de Moldavie' },
  {value: 'ME', label: 'Montenegro' },
  {value: 'MF', label: 'Saint-Martin (partie française)' },
  {value: 'MG', label: 'Madagascar' },
  {value: 'MH', label: 'Iles Marshall' },
  {value: 'MK', label: 'Macédoine' },
  {value: 'ML', label: 'Mali' },
  {value: 'MM', label: 'Myanmar' },
  {value: 'MN', label: 'Mongolie' },
  {value: 'MO', label: 'Région administrative spéciale de Macao' },
  {value: 'MP', label: 'Iles Mariannes du Nord' },
  {value: 'MQ', label: 'Martinique' },
  {value: 'MR', label: 'Mauritanie' },
  {value: 'MS', label: 'Montserrat' },
  {value: 'MT', label: 'Malte' },
  {value: 'MU', label: 'Maurice' },
  {value: 'MV', label: 'Maldives' },
  {value: 'MW', label: 'Malawi' },
  {value: 'MX', label: 'Mexique' },
  {value: 'MY', label: 'Malaisie' },
  {value: 'MZ', label: 'Mozambique' },
  {value: 'NA', label: 'Namibie' },
  {value: 'NC', label: 'Nouvelle-Calédonie' },
  {value: 'NE', label: 'Niger' },
  {value: 'NF', label: 'Ile Norfolk' },
  {value: 'NG', label: 'Nigeria' },
  {value: 'NI', label: 'Nicaragua' },
  {value: 'NL', label: 'Pays-Bas' },
  {value: 'NO', label: 'Norvege' },
  {value: 'NP', label: 'Nepal' },
  {value: 'NR', label: 'Nauru' },
  // {value: 'NU', label: 'TO EXCLUDE' },
  {value: 'NZ', label: 'Nouvelle-Zelande' },
  {value: 'OM', label: 'Oman' },
  {value: 'PA', label: 'Panama' },
  {value: 'PE', label: 'Perou' },
  {value: 'PF', label: 'Polynesie française' },
  {value: 'PG', label: 'Papouasie-Nouvelle-Guinée' },
  {value: 'PH', label: 'Philippines' },
  {value: 'PK', label: 'Pakistan' },
  {value: 'PL', label: 'Pologne' },
  {value: 'PM', label: 'Saint-Pierre-et-Miquelon' },
  {value: 'PN', label: 'Pitcairn' },
  {value: 'PR', label: 'Porto Rico' },
  {value: 'PS', label: 'Etat de Palestine' },
  {value: 'PT', label: 'Portugal' },
  {value: 'PW', label: 'Palaos' },
  {value: 'PY', label: 'Paraguay' },
  {value: 'QA', label: 'Qatar' },
  {value: 'RE', label: 'Réunion' },
  {value: 'RO', label: 'Roumanie' },
  {value: 'RS', label: 'Serbie' },
  {value: 'RU', label: 'Russie' },
  {value: 'RW', label: 'Rwanda' },
  {value: 'SA', label: 'Arabie saoudite' },
  {value: 'SB', label: 'Iles Salomon' },
  {value: 'SC', label: 'Seychelles' },
  {value: 'SD', label: 'Soudan' },
  {value: 'SE', label: 'Suede' },
  {value: 'SG', label: 'Singapour' },
  {value: 'SH', label: 'Sainte-Helene' },
  {value: 'SI', label: 'Slovenie' },
  {value: 'SJ', label: 'Iles Svalbard et Jan Mayen' },
  {value: 'SK', label: 'Slovaquie' },
  {value: 'SL', label: 'Sierra Leone' },
  {value: 'SM', label: 'Saint-Marin' },
  {value: 'SN', label: 'Senegal' },
  {value: 'SO', label: 'Somalie' },
  {value: 'SR', label: 'Suriname' },
  {value: 'SS', label: 'Soudan du Sud' },
  {value: 'ST', label: 'Sao Tome-et-Principe' },
  {value: 'SV', label: 'El Salvador' },
  {value: 'SX', label: 'Saint-Martin (partie néerlandaise)' },
  {value: 'SY', label: 'Syrie' },
  {value: 'SZ', label: 'Swaziland' },
  {value: 'TC', label: 'Iles Turques-et-Caïques' },
  {value: 'TD', label: 'Tchad' },
  {value: 'TF', label: 'Terres australes fran√ßaises' },
  {value: 'TG', label: 'Togo' },
  {value: 'TH', label: 'Thailande' },
  {value: 'TJ', label: 'Tadjikistan' },
  {value: 'TK', label: 'Tokélaou' },
  {value: 'TL', label: 'Timor-Leste' },
  {value: 'TM', label: 'Turkménistan' },
  {value: 'TN', label: 'Tunisie' },
  {value: 'TO', label: 'Tonga' },
  {value: 'TR', label: 'Turquie' },
  {value: 'TT', label: 'Trinité-et-Tobago' },
  {value: 'TV', label: 'Tuvalu' },
  {value: 'TW', label: 'Taiwan' },
  {value: 'TZ', label: 'Tanzanie' },
  {value: 'UA', label: 'Ukraine' },
  {value: 'UG', label: 'Ouganda' },
  {value: 'UM', label: 'Iles mineures éloignées des Etats-Unis' },
  {value: 'US', label: `Etats-Unis d'Amérique` },
  {value: 'UY', label: 'Uruguay' },
  {value: 'UZ', label: 'Ouzbékistan' },
  {value: 'VA', label: 'Saint-Siège' },
  {value: 'VC', label: 'Saint-Vincent-et-les Grenadines' },
  {value: 'VE', label: 'Venezuela (République bolivarienne du)' },
  {value: 'VG', label: 'Iles Vierges britanniques' },
  {value: 'VI', label: 'Iles Vierges américaines' },
  {value: 'VN', label: 'Viet Nam' },
  {value: 'VU', label: 'Vanuatu' },
  {value: 'WF', label: 'Iles Wallis-et-Futuna' },
  {value: 'WS', label: 'Samoa' },
  {value: 'YE', label: 'Yémen' },
  {value: 'YT', label: 'Mayotte' },
  {value: 'ZA', label: 'Afrique du Sud' },
  {value: 'ZM', label: 'Zambie' },
  {value: 'ZW', label: 'Zimbabwe' },
].sort(comparingString(c => c.label));

export const countryOptionsByCode = new Map<string, IOption<string>>();

COUNTRY_OPTIONS.forEach((c) => countryOptionsByCode.set(c.value, c));
