import IAccessToken from "../../IAccessToken";
import Loader from "factor-lib/Loader";
import IAuthUserLoad from "../../IAuthUserLoad";
import { preInvoiceDetailsQueryKey, IPreInvoiceDetailsQueryResult } from "./PreInvoiceDetailsQuery";
import PreInvoiceDetailsPageLoaded from "./PreInvoiceDetailsPageLoaded";
import GraphQLWithAccessTokenWrapper from "../../GraphQLQueryWithAccessTokenWrapper";
import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";

const PreInvoiceDetailsPage = (
    {
        accessTokenO,
        preInvoiceId
    } : {
        accessTokenO: IAccessToken | null;
        preInvoiceId: string;
    }
) =>
    <div className='container p-padding-4 p-full-width'>
        <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                       queryKey={preInvoiceDetailsQueryKey(preInvoiceId)}
                                       queryParams={{
                                           query: `query Q($preInvoiceId: Guid!) {
                                                preInvoiceDetails (id: $preInvoiceId) {
                                                    creationDateTime
                                                    amountTax amountTaxConfidence
                                                    amountWoTax amountWoTaxConfidence
                                                    invoiceNumber invoiceNumberConfidence
                                                    dueDate dueDateConfidence
                                                    issueDate issueDateConfidence
                                                    email {
                                                        id
                                                        sentDateTime
                                                        title
                                                        senderEmail
                                                        recipientEmails
                                                        matchingSeller {
                                                            id
                                                            certif {
                                                                solvaConfidenceFlag
                                                                fraudConfidenceFlag
                                                            }
                                                            isBlocked
                                                            company { id identifier { ${IntCompanyIdentifierGraphQLFields} } }
                                                        }
                                                        matchingBuyerEmail {
                                                            value
                                                            buyer {
                                                                id
                                                                company { id identifier { ${IntCompanyIdentifierGraphQLFields} } name }
                                                            }
                                                        }
                                                        thread { link }
                                                    }
                                                    blobFile { url contentType }
                                                }
                                            }`,
                                           variables: {
                                               preInvoiceId
                                           }
                                       }}
                                       onLoading={() => <Loader/>}
                                       onSuccess={(r: IAuthUserLoad<IPreInvoiceDetailsQueryResult>) =>
                                           <PreInvoiceDetailsPageLoaded axios={r.axios}
                                                                        authUser={r.authUserO}
                                                                        preInvoiceId={preInvoiceId}
                                                                        preInvoice={r.value.preInvoiceDetails} />
                                       } />
    </div>;

export default PreInvoiceDetailsPage;
