import { formatAmount } from "factor-lib/utils/amountUtils";
import {formatAdminDate} from "../../../../utils/dateTimeUtils";

const InvoiceBaseInfos = (
    {
        number,
        issueDate,
        dueDate,
        amountWoTax,
        amountTax,
        completePaidDate,
        partialAmountPaid
    } : {
        number: string;
        issueDate: Date;
        dueDate: Date;
        amountWoTax: number;
        amountTax: number;
        completePaidDate: string | null;
        partialAmountPaid: number;
    }
) =>
    <table className='table is-narrow'>
        <tbody>
            <tr>
                <td className='p-bold'>Numéro de facture</td>
                <td>{ number }</td>
            </tr>
            <tr>
                <td className='p-bold'>Date d'emission</td>
                <td>{ formatAdminDate(issueDate) }</td>
            </tr>
            <tr>
                <td className='p-bold'>Date d'echéance</td>
                <td>{ formatAdminDate(dueDate) }</td>
            </tr>
            <tr>
                <td className='p-bold'>Montant Hors Taxes</td>
                <td>{ formatAmount(amountWoTax) }</td>
            </tr>
            <tr>
                <td className='p-bold'>Montant des Taxes</td>
                <td>{ formatAmount(amountTax) }</td>
            </tr>
            <tr>
                <td className='p-bold'>Status de paiement</td>
                <td>
                    { !!completePaidDate
                        ? 'Complet'
                        : `Reste à payer ${formatAmount(amountWoTax + amountTax - partialAmountPaid)}`
                    }
                </td>
            </tr>
        </tbody>
    </table>;

export default InvoiceBaseInfos;
