import { useSearchParams } from "react-router-dom";
import Loader from "factor-lib/Loader";
import IAccessToken from "../../IAccessToken";
import AddBuyerForSeller from "./AddBuyer/AddBuyerForSeller";
import AddBuyerChooseSeller from "./AddBuyer/AddBuyerChooseSeller";
import GraphQLQueryWrapper from "../../utils/GraphQLQueryWrapper";
import { AddBuyerSellerIdQueryParamName } from "./buyersUrls";
import { IIntCompanyIdentifier, IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";

export interface ISellerForAddBuyer {
    company: {
        id: string;
        name: string;
        identifier: IIntCompanyIdentifier;
    };
}

export interface ISellerForAddBuyerQueryResult {
    sellerDetails: ISellerForAddBuyer;
}

const AddBuyer = (
    {
        accessTokenO,
    }: {
        accessTokenO: IAccessToken | null;
    }
) => {
    const [searchParams] = useSearchParams();
    const sellerId: string | null = searchParams.get(AddBuyerSellerIdQueryParamName);

    return (
        !!accessTokenO
            ? !!accessTokenO.authUserO?.canManageProfiles
                ? !!sellerId
                    ? <GraphQLQueryWrapper queryKey={['seller-for-add-buyer', sellerId]}
                                           axios={accessTokenO.axios}
                                           queryParams={{
                                               query: `query Q($sellerId: Guid!) {
                                                    sellerDetails (id: $sellerId) {
                                                        company {
                                                            id
                                                            name
                                                            identifier { ${IntCompanyIdentifierGraphQLFields} }
                                                        }
                                                    }
                                               }`,
                                               variables: {
                                                   sellerId
                                               }
                                           }}
                                           onLoading={() => <Loader />}
                                           onSuccess={(r: ISellerForAddBuyerQueryResult) =>
                                               <AddBuyerForSeller axios={accessTokenO.axios}
                                                                  sellerId={sellerId}
                                                                  sellerCompany={r.sellerDetails.company}  />
                                           } />
                    : <AddBuyerChooseSeller axios={accessTokenO.axios} />
                : <span className='p-both-center p-padding-4 p-bold'>
                    Vous n'avez pas les droits pour ajouter un Buyer
                </span>
            : <Loader />
    );
}

export default AddBuyer;
