import IAuthUser from "../../../IAuthUser";
import {
    IBuyerCompanyDetails, IBuyer,
    IBuyerEmail, IBuyerPhone,
    IBuyerEvent, IBuyerEventInvoiceMatch
} from "./buyerCompanyDetailsQuery";
import BuyerContactInfosSection from "./BuyerContactInfosSection/BuyerContactInfosSection";
import BuyerSpecialInfosSection from "./BuyerSpecialInfosSection/BuyerSpecialInfosSection";
import BuyerEventsSection from "./BuyerEventsSection/BuyerEventsSection";
import {Axios} from "axios";
import BuyerEligibilitySection from "./BuyerOutstandingsSection/BuyerEligibilitySection";
import {ReactNode} from "react";
import DataLoader from "dataloader";
import dataLoader from "factor-lib/dataLoader/dataLoader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import {getFactorContext} from "../../../IFactorContext";
import ILogger from "factor-lib/services/logger/ILogger";
import graphQLBatchLoadFunction from "factor-lib/dataLoader/graphQLBatchLoadFunctionAsync";
import {MFMTMiseEnDemeure, MFMTSubrogation} from "../../../utils/MerciFacteursMailsTypes";
import BuyerMerciFacteurMailSection from "./BuyerMerciFacteurMailsSection/BuyerMerciFacteurMailSection";

export const Section = (
    {
        title,
        children
    }: {
        title: string;
        children: ReactNode;
    }
) =>
    <section className='box'>
        <div className='title'> {title} </div>
        { children }
    </section>;

const BuyerInfosTabLoaded = (
    {
        axios,
        authUser,
        buyerCompanyDetails,
        buyers,
        buyersEmails,
        buyersPhones,
        buyerEvents,
        buyerEventsInvoiceMatches
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        buyerCompanyDetails: IBuyerCompanyDetails;
        buyers: IBuyer[];
        buyersEmails: IBuyerEmail[];
        buyersPhones: IBuyerPhone[];
        buyerEvents: IBuyerEvent[];
        buyerEventsInvoiceMatches: IBuyerEventInvoiceMatch[];
    }
) => {
    const logger: ILogger = getFactorContext().logger;

    // DS apart, as expected to be slower
    const dsGraphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any> =
        dataLoader<IGraphQLQueryWithKeyAndParams, any>(
            (graphQLQueries: ReadonlyArray<IGraphQLQueryWithKeyAndParams>) => graphQLBatchLoadFunction(axios, logger, graphQLQueries)
        );

    return (
        <div>
            <Section title='Contacts Infos'>
                <BuyerContactInfosSection axios={axios}
                                          authUser={authUser}
                                          buyerCompanyId={buyerCompanyDetails.id}
                                          buyers={buyers}
                                          buyerEmails={buyersEmails}
                                          buyerPhones={buyersPhones} />
            </Section>

            <Section title='Special infos'>
                <BuyerSpecialInfosSection axios={axios}
                                          dsGraphQLDataLoader={dsGraphQLDataLoader}
                                          authUser={authUser}
                                          buyerCompanyDetails={buyerCompanyDetails} />
            </Section>

            <Section title='Encours'>
                <BuyerEligibilitySection axios={axios}
                                         dsGraphQLDataLoader={dsGraphQLDataLoader}
                                         buyerCompanyId={buyerCompanyDetails.id}
                                         buyerOutstanding={buyerCompanyDetails.asBuyer.outstanding}
                                         buyerIsBlocked={buyerCompanyDetails.asBuyer.isBlocked}
                                         buyerManualOutstandingLimit={buyerCompanyDetails.asBuyer.manualOutstandingLimit}
                                         buyers={buyers} />
            </Section>

            <Section title={'Evenements récents' /* Je ne comprends pas ce qu'il y a de filtre 'récent' */}>
                <BuyerEventsSection axios={axios}
                                    authUser={authUser}
                                    buyerCompanyId={buyerCompanyDetails.id}
                                    buyers={buyers}
                                    buyerEmails={buyersEmails}
                                    buyerPhones={buyersPhones}
                                    buyerEvents={buyerEvents}
                                    buyerEventsInvoiceMatches={buyerEventsInvoiceMatches}
                                    merciFacteurMails={buyerCompanyDetails.asBuyer.merciFacteurMails}
                                    financingsMerciFacteurMails={buyerCompanyDetails.asBuyer.financingsMerciFacteurMails}/>
            </Section>

            <Section title='Courriers'>
                <div className='p-horizontal-center'>
                    <BuyerMerciFacteurMailSection axios={axios}
                                                  buttonText='Envoi subrogation'
                                                  generateDocUrl='generate-subrogation-doc'
                                                  mailLabel='subrogation'
                                                  mailType={MFMTSubrogation}
                                                  buyerCompanyId={buyerCompanyDetails.id}/>
                    <BuyerMerciFacteurMailSection className='p-margin-left-4'
                                                  axios={axios}
                                                  buttonText='Envoi mise en demeure'
                                                  generateDocUrl='generate-mise-en-demeure-doc'
                                                  mailLabel='mise en demeure'
                                                  mailType={MFMTMiseEnDemeure}
                                                  buyerCompanyId={buyerCompanyDetails.id}/>
                </div>
            </Section>
        </div>
    );
}

export default BuyerInfosTabLoaded;
