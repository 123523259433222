import {Axios} from "axios";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import Input from "factor-lib/forms/Inputs/Input";
import { ResultOrErrors } from "../../../../../utils/ResultOrErrors";
import {MainCustomerNamespace} from "../../../customersQuery";
import { ICustomerDetailsQueryResult, customerDetailsQueryKey } from "../../customerDetailsQuery";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { ISellerDetailsQueryResult, SellerDetailsQueryKeyPrefix } from "../../../../Sellers/SellerInfos/sellerDetailsQuery";
import ShowValidationErrorsButton from "../../../../../utils/ShowValidationErrorsButton";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import { isValidEmail } from "factor-lib/forms/Inputs/emailUtils";
import { isValidIban } from "factor-lib/forms/Inputs/ibanUtils";
import {KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";

const ConfirmText = 'Confirmer';

interface IStripeCreateParams {
    customerId: string;
    IBANs: string[];
    CompanyName: string;
    email: string;
}

const CreateInStripeEnabled = (
    {
        customerId,
        axios,
        createParams,
        closeModal,
        kind,
        size,
        text
    } : {
        customerId: string;
        axios: Axios;
        createParams: IStripeCreateParams;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const createCustomerInStripeMutation: UseMutationResult<string, any, IStripeCreateParams, null> =
        useMutation<string, any, IStripeCreateParams, null>(
            async (createParams2) => {
                return (await axios.post<string>(
                    `/adminStripe/create`,
                    createParams2
                )).data;
            },
            ({
                onSuccess: (stripeId: string) => {
                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId),
                        (old: ICustomerDetailsQueryResult | undefined) => ({
                            customerDetails: {
                                ...old!.customerDetails,
                                stripeCustomerId: stripeId
                            }
                        })
                    );
                    queryClient.setQueryData(
                        [MainCustomerNamespace],
                        (old: unknown[] | undefined) => !!old ? old.map((customer: any) =>
                            customer.id === customerId ? {
                                ...customer,
                                stripeCustomerId: stripeId
                            } : customer
                        ) : undefined
                    );
                    queryClient.setQueriesData<ISellerDetailsQueryResult>(
                        SellerDetailsQueryKeyPrefix,
                        (old) => old?.sellerDetails.customer.id === customerId
                            ? ({
                                ...old,
                                sellerDetails: {
                                    ...old.sellerDetails,
                                    customer: {
                                        ...old.sellerDetails.customer,
                                        stripeCustomerId: stripeId
                                    }
                                }
                            }) : old
                    );
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='createCustomerInStripeButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={createCustomerInStripeMutation}
                               displayFullError={true}
                               value={createParams} />
    );
}

const getStripeCreateParams = (
    contactEmail: string,
    customerId: string,
    companyName: string,
    billingIban: string,
    directSellerIbanO: string | null
): ResultOrErrors<IStripeCreateParams> => {
    const validationErrors: string[] = [];

    if (!isValidEmail(contactEmail)) {
        validationErrors.push(`L'email est invalide`);
    }

    if (!isValidIban(billingIban)) {
        validationErrors.push(`L'IBAN du billing est invalide`);
    }

    if (!!directSellerIbanO && !isValidIban(directSellerIbanO)) {
        validationErrors.push(`L'IBAN du seller est invalide`);
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    let ibansToSendToStripe = [billingIban];
    if (!!directSellerIbanO && directSellerIbanO !== billingIban) {
        ibansToSendToStripe.push(directSellerIbanO);
    }

    return ResultOrErrors.fromResult({
            CompanyName: companyName,
            customerId: customerId,
            email: contactEmail,
            IBANs: ibansToSendToStripe
        });
};

const CreateInStripeModalContent = (
    {
        axios,
        customerId,
        contactEmail,
        companyName,
        billingIban,
        directSellerIbanO,
        closeModal
    }: {
        axios: Axios;
        customerId: string;
        contactEmail: string;
        companyName: string;
        billingIban: string;
        directSellerIbanO: string | null;
        closeModal: () => void;
    }
) => {

    const createParams: ResultOrErrors<IStripeCreateParams> = getStripeCreateParams(
        contactEmail,
        customerId,
        companyName,
        billingIban,
        directSellerIbanO
    );

    return (
        <div className='p-padding-3'>
            <div>
                <span className='p-margin-right-4 p-bold'>Email de contact</span>
                <Input inputValue={contactEmail}
                       enabled={null} />
            </div>

            <div className='p-margin-top-4'>
                <span className='p-margin-right-4 p-bold'>Company name</span>
                <Input inputValue={companyName}
                       enabled={null} />

            </div>

            <div className='p-margin-top-4'>
                <span className='p-margin-right-4 p-bold'>IBAN de billing</span>
                <Input inputValue={billingIban}
                       enabled={null} />
            </div>

            { !!directSellerIbanO &&
                <div className='p-margin-top-4'>
                    <span className='p-margin-right-4 p-bold'>IBAN du seller</span>
                    <Input inputValue={directSellerIbanO}
                           enabled={null} />
                </div>
            }

            <div className='p-margin-top-4 p-vertical-center'>
                {  !!createParams.result
                    ? <CreateInStripeEnabled axios={axios}
                                             customerId={customerId}
                                             createParams={createParams.result}
                                             closeModal={closeModal}
                                             kind={KIND_PRIMARY}
                                             size={SIZE_FIXED}
                                             text={ConfirmText} />
                    : <ShowValidationErrorsButton validationErrors={createParams.errors!}
                                                  kind={KIND_PRIMARY}
                                                  size={SIZE_FIXED}
                                                  text={ConfirmText} />
                }
            </div>
        </div>
    );
}

const CreateCustomerInStripe = (
    {
        axios,
        customerId,
        contactEmail,
        companyName,
        billingIban,
        directSellerIbanO
    }: {
        axios: Axios;
        customerId: string;
        contactEmail: string;
        companyName: string;
        billingIban: string;
        directSellerIbanO: string | null;
    }
) =>
    <ButtonForModal id='createInStripeModal'
                    text='Créer dans Stripe'
                    childModalContent={(closeModal) =>
                        <CreateInStripeModalContent axios={axios}
                                             customerId={customerId}
                                             contactEmail={contactEmail}
                                             companyName={companyName}
                                             billingIban={billingIban}
                                             directSellerIbanO={directSellerIbanO}
                                             closeModal={closeModal} />
                    } />;

export default CreateCustomerInStripe;
