import { dateDiffDays, serverDateDeserialization } from 'factor-lib/utils/dateUtils';
import IFailable from 'factor-lib/IFailable';

export const financingWithStatusGraphQLQuery = (extraFinancingQuery: string, extraSettledFinancingQuery: string) =>
    `
        settled { dateTime ${extraSettledFinancingQuery} } 
        ${extraFinancingQuery}
    `;

export interface IFinancingStatus {
    // hardCollectionDate: string | null;
    settled: {
        dateTime: string;
    } | null;
}

// const InvoiceCustomerFinancingRequestAcceptedStatusGraphQLFields =
//     'creationDateTime';

export interface ICustomerFinancingRequestAcceptationStatus<F> {
    financing: F | null;
}

export interface ICustomerFinancingRequestStatus<A> {
    expirationDate: string;
    accepted: A;
}

export interface IRejectableCustomerFinancingRequestStatus<A> extends ICustomerFinancingRequestStatus<A> {
    rejectedDate: string | null;
}

export interface ICustomerFinancingRequestAcceptedStatus<AS extends ICustomerFinancingRequestAcceptationStatus<F>, F extends IFinancingStatus>
    extends IRejectableCustomerFinancingRequestStatus<AS | null>{
}

export const invoiceWithStatusGraphQLQuery = (
    extraInvoiceQuery: string,
    extraCustomerFinancingRequestQuery: string,
    extraCustomerFinancingAcceptedRequestQuery: string,
    extraFinancingQuery: string,
    extraSettledFinancingQuery: string
) =>
    `
        status {
            eligibility {
                successO { rejectionReason isPending }
                errorO
            }
            customerFinancingRequest { 
                rejectedDate 
                expirationDate
                accepted {
                    financing { ${financingWithStatusGraphQLQuery(extraFinancingQuery, extraSettledFinancingQuery)} }
                    ${extraCustomerFinancingAcceptedRequestQuery}
                }
                ${extraCustomerFinancingRequestQuery}
            }
        }
        ${extraInvoiceQuery}
    `;

export interface IEligibilitySuccess {
    rejectionReason: string | null; // null -> eligible
    isPending: boolean;
}

export interface IInvoiceStatus<CFR extends ICustomerFinancingRequestAcceptedStatus<AS, F>, AS extends ICustomerFinancingRequestAcceptationStatus<F>, F extends IFinancingStatus>  {
    customerFinancingRequest: CFR | null; // null -> financing not requested
    eligibility: IFailable<IEligibilitySuccess>;
}


// const UNKNOWN = 'Unknown';

const ELIGIBLE = 'Eligible';
const PENDING_ELIGIBILITY = 'Pending eligibility';
const NOT_ELIGIBLE = 'Not Eligible';

const FINANCING_REQUESTED = 'Financing requested';
const FINANCING_REQUEST_ACCEPTED = 'Financing request accepted, pending financing';
const FINANCING_REQUEST_EXPIRED = 'Financing request expired';
const FINANCING_REQUEST_REJECTED = 'Financing request rejected';

const FINANCED = 'Financed';
// const FINANCED_HARDCOLLECTION = 'Financed HardCollection';
const FINANCED_PAID = 'Financed complete paid';
// const FINANCED_PAID_BEFORE_HARDCOLLECTION = 'Financed Paid Before HardCollection';
// const FINANCED_PAID_AFTER_HARDCOLLECTION = 'Financed Paid After HardCollection';

const SETTLED_PAID = 'Settled complete paid';
const SETTLED_NOT_PAID = 'Settled not complete paid';
// const SETTLED_PAID_BEFORE_HARDCOLLECTION = 'Settled Paid Before HardCollection';
// const SETTLED_PAID_AFTER_HARDCOLLECTION = 'Settled Paid After HardCollection';
// const SETTLED_BUYER_DEFAULT = 'Settled Buyer Default';

export const computeInvoiceStatus = <CFR extends ICustomerFinancingRequestAcceptedStatus<AS, F>, AS extends ICustomerFinancingRequestAcceptationStatus<F>, F extends IFinancingStatus>
    (completePaid: boolean, invoiceStatus: IInvoiceStatus<CFR, AS, F>, displayNotEligibleReason: boolean): string =>
        !!invoiceStatus.customerFinancingRequest
            ? !!invoiceStatus.customerFinancingRequest.accepted?.financing
                ? computeFinancingStatus(completePaid, invoiceStatus.customerFinancingRequest.accepted.financing)
                : computeCustomerFinancingRequestStatusNonFinanced(invoiceStatus.customerFinancingRequest)
            : invoiceStatus.eligibility.errorO !== null
                ? `Erreur eligibilité : ${invoiceStatus.eligibility.errorO}`
            : computeEligibilitySuccessStatus(invoiceStatus.eligibility.successO!, displayNotEligibleReason);

const computeEligibilitySuccessStatus = (eligibilitySuccess: IEligibilitySuccess, displayNotEligibleReason: boolean) =>
    eligibilitySuccess.isPending
        ? PENDING_ELIGIBILITY
        : (!!eligibilitySuccess.rejectionReason
                ? `${NOT_ELIGIBLE}${displayNotEligibleReason ? ` (${eligibilitySuccess.rejectionReason})` : ''}`
                : ELIGIBLE
        );

export const computeCustomerFinancingRequestStatusNonFinancedNonRejected = <A, >
    (financingRequest: ICustomerFinancingRequestStatus<A>): string =>
        dateDiffDays(serverDateDeserialization(financingRequest.expirationDate), new Date()) > 0
            ? FINANCING_REQUEST_EXPIRED
            : !!financingRequest.accepted
                ? FINANCING_REQUEST_ACCEPTED
                : FINANCING_REQUESTED;

export const computeCustomerFinancingRequestStatusNonFinanced = <A, >
    (financingRequest: IRejectableCustomerFinancingRequestStatus<A>): string =>
        !!financingRequest.rejectedDate
            ? FINANCING_REQUEST_REJECTED
            : computeCustomerFinancingRequestStatusNonFinancedNonRejected(financingRequest);

export const computeFinancingStatus =
    (completePaid: boolean, financing: IFinancingStatus): string =>
        !!financing.settled
            ? computeSettledFinancingStatus(completePaid)
            : completePaid
                ? FINANCED_PAID
                : FINANCED;

export const computeSettledFinancingStatus =
    (completePaid: boolean): string =>
        completePaid
            ? SETTLED_PAID
            : SETTLED_NOT_PAID;
