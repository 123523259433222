
import { ReactComponent as Search } from 'factor-lib/Search.svg';

const SearchIcon = (
    {
        onClick
    }: {
        onClick?: () => void;
    }
) =>
    <div className={`${!!onClick ? 'p-clickable ' : ''}p-ok p-both-center p-i-square p-padding-5`}
         style={{fill: '#FFFFFF'}/* Cheat */}
         onClick={onClick}
    >
        <Search className='p-full-width p-full-height'/>
    </div>;

export default SearchIcon;