import IAccessToken from "../../IAccessToken";
import GraphQLWithAccessTokenWrapper from "../../GraphQLQueryWithAccessTokenWrapper";
import IAuthUserLoad from "../../IAuthUserLoad";
import Loader from "factor-lib/Loader";
import {
    IInvoicesResponse, InvoicesNotEligibleQuery, RecentNotEligibleInvoicesQueryKey
} from "./IneligibleInvoicesQuery";
import IneligibleInvoicesTemplate from "./IneligibleInvoicesTemplate";
import IneligibleInvoicesLoaded from "./IneligibleInvoicesLoaded";

const IneligibleInvoicesTable = (
    {
        className,
        accessTokenO,
        searchStrFilter
    }: {
        className?: string;
        accessTokenO: IAccessToken | null;
        searchStrFilter: string;
    }
) =>
    <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                   queryKey={RecentNotEligibleInvoicesQueryKey}
                                   queryParams={InvoicesNotEligibleQuery}
                                   onLoading={() =>
                                       <IneligibleInvoicesTemplate className={className}
                                                                   loadedO={null}>
                                           <tbody>
                                               <tr>
                                                   <td/>
                                                   <td/>
                                                   <td/>
                                                   <td><Loader/></td>
                                                   <td/>
                                                   <td/>
                                                   <td/>
                                               </tr>
                                           </tbody>
                                       </IneligibleInvoicesTemplate>
                                   }
                                   onSuccess={(r: IAuthUserLoad<IInvoicesResponse>) =>
                                       <IneligibleInvoicesLoaded className={className}
                                                                 searchStrFilter={searchStrFilter}
                                                                 invoices={r.value.invoices}/>
                                   }/>;

export default IneligibleInvoicesTable;