import Loader from "factor-lib/Loader";
import IAccessToken from "../../../IAccessToken";
import IAuthUserLoad from "../../../IAuthUserLoad";
import AddBuyerForSeller from "../../Buyers/AddBuyer/AddBuyerForSeller";
import GraphQLWithAccessTokenWrapper from "../../../GraphQLQueryWithAccessTokenWrapper";
import { IIntCompanyIdentifier, IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";

interface ISellerForAddBuyerQueryResult {
    sellerDetails: {
        company: {
            id: string;
            name: string;
            identifier: IIntCompanyIdentifier;
        };
    };
}

const SellerAddBuyer = (
    {
        sellerId,
        accessTokenO
    } : {
        sellerId: string;
        accessTokenO: IAccessToken | null;
    }
) =>
    <div className='container p-both-center p-margin-top-3'>
        <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                       queryKey={[`seller-for-add-buyer-${sellerId}`]}
                                       queryParams={{
                                           query: `
                                               query Q($sellerId: Guid!) {
                                                   sellerDetails (id: $sellerId) {
                                                       company {
                                                           id
                                                           name
                                                           identifier { ${IntCompanyIdentifierGraphQLFields} }
                                                       }
                                                   }
                                               }
                                           `,
                                           variables: {
                                               sellerId
                                           }
                                       }}
                                       onLoading={() => <Loader />}
                                       onSuccess={(r: IAuthUserLoad<ISellerForAddBuyerQueryResult>) =>
                                           !!r.authUserO?.canManageProfiles
                                               ? <AddBuyerForSeller axios={r.axios}
                                                                    sellerId={sellerId}
                                                                    sellerCompany={r.value.sellerDetails.company} />
                                               // TODO : bloquer avant la query
                                               : <span>Vous n'avez pas les droits pour ajouter un Buyer</span>
                                       } />
    </div>;

export default SellerAddBuyer;
