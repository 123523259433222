import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import {Axios} from "axios";
import IAuthUser from "../../../../IAuthUser";
import { ICustomerUser } from "../customerDetailsQuery";
import AddCustomerUser from "./CustomerUsers/AddCustomerUser";
import CustomerUsersList from "./CustomerUsers/CustomerUsersList";

const CustomerUsersSection = (
    {
        axios,
        authUser,
        customerId,
        users
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        customerId: string;
        users: ReadonlyArray<ICustomerUser>;
    }
) =>
    <div>
        { users.length === 0
            ? !!authUser?.canManageProfiles
                ? <AddCustomerUser axios={axios}
                                   customerId={customerId}
                                   users={users}
                                   kind={KIND_PRIMARY}
                                   size={SIZE_FIXED} />
                : `Aucun utilisateur n'est lié`
            : <CustomerUsersList axios={axios}
                                 authUser={authUser}
                                 customerId={customerId}
                                 users={users} />
        }
    </div>;

export default CustomerUsersSection;
