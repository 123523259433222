import { formatAdminDateStr, formatDateAndTimeAdmin } from "../../../utils/dateTimeUtils";
import { BuyerEventInvoiceGraphQLFields, IBuyerEvent } from "../../Buyers/BuyersInfo/buyerCompanyDetailsQuery";
import { useState } from "react";
import {
    buyerEventsInvoiceMatchesKey,
    IInvoiceDetailsEventsInfosQueryResult,
    IFinancingMerciFacteurMail
} from "../invoiceDetailsQuery";
import Loader from "factor-lib/Loader";
import BuyerEventModal from "../../Buyers/BuyersInfo/BuyerEventsSection/BuyerEventModal";
import { Axios } from "axios";
import IAuthUser from "../../../IAuthUser";
import Button from "factor-lib/Buttons/Button";
import { BUYER_EVENT_TYPES } from "../../Buyers/BuyersInfo/BuyerEventsSection/buyerEventUtils";
import GraphQLQueryWrapper from "../../../utils/GraphQLQueryWrapper";
import { comparingDate, serverDateDeserialization } from "factor-lib/utils/dateUtils";
import { truncateString } from "../../../utils/stringUtils";
import { comparingRev } from "factor-lib/utils/comparingUtils";
import {merciFacteurMailTypeLabel} from "../../../utils/MerciFacteursMailsTypes";

interface IInvoiceEventRow {
    who: string;
    date: Date;
    type: string;
    notes: string | null;
    onClickO: (() => void) | null;
}

const comparingInvoiceEventDate = comparingRev(comparingDate((e: IInvoiceEventRow) => e.date));

const formatInvoiceBuyerEventRow = (
    invoiceBuyerEvent: IBuyerEvent,
    setSelectedInvoiceBuyerEvent: (b: IBuyerEvent) => void
): IInvoiceEventRow => ({
    who: invoiceBuyerEvent.dimplOps.name,
    date: serverDateDeserialization(invoiceBuyerEvent.eventDateTime),
    type: BUYER_EVENT_TYPES[invoiceBuyerEvent.type],
    notes: !!invoiceBuyerEvent.notes ? truncateString(invoiceBuyerEvent.notes, 30) : null,
    onClickO: () => setSelectedInvoiceBuyerEvent(invoiceBuyerEvent)
});

const formatFinancingMerciFacteurMailRow = (
    financingMerciFacteurMail: IFinancingMerciFacteurMail
): IInvoiceEventRow => ({
    who: financingMerciFacteurMail.dimplOps.name,
    date: serverDateDeserialization(financingMerciFacteurMail.creationDateTime),
    type: `${merciFacteurMailTypeLabel(financingMerciFacteurMail.type)} Merci Facteur`,
    notes: !!financingMerciFacteurMail.receivedDateTime
        ? `Reçue le ${formatAdminDateStr(financingMerciFacteurMail.receivedDateTime)}`
        : null,
    onClickO: null
});

const InvoiceEventsTable = (
    {
        invoiceBuyerEvents,
        financingMerciFacteurMailsO,
        setSelectedInvoiceBuyerEvent
    }: {
        invoiceBuyerEvents: IBuyerEvent[];
        financingMerciFacteurMailsO: IFinancingMerciFacteurMail[] | null;
        setSelectedInvoiceBuyerEvent: (event: IBuyerEvent) => void;
    }
) =>
    <div style={{overflowX: 'auto'}}>
        <table className='table is-striped is-bordered is-hoverable is-fullwidth'>
            <thead>
                <tr>
                    <th>Who</th>
                    <th>Date - Heure</th>
                    <th>Type</th>
                    <th>Commentaire</th>
                </tr>
            </thead>
            <tbody>
                { invoiceBuyerEvents
                    .map((e) => formatInvoiceBuyerEventRow(e, setSelectedInvoiceBuyerEvent))
                    .concat(
                        (financingMerciFacteurMailsO ?? []).map(formatFinancingMerciFacteurMailRow)
                    )
                    .sort(comparingInvoiceEventDate)
                    .map((event, index) => (
                        <tr key={`invoice-event-${index}`}
                            className={!!event.onClickO ? 'p-clickable' : ''}
                            onClick={event.onClickO || undefined} >
                            <td>{ event.who.toUpperCase() }</td>
                            <td>{ formatDateAndTimeAdmin(event.date) }</td>
                            <td>{ event.type }</td>
                            <td>{ event.notes ?? '' }</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </div>;

const InvoiceEventsSection = (
    {
        axios,
        authUser,
        invoiceId,
        invoiceBuyerEvents,
        financingMerciFacteurMailsO,
        buyerCompanyId
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        invoiceId: string;
        invoiceBuyerEvents: IBuyerEvent[];
        financingMerciFacteurMailsO: IFinancingMerciFacteurMail[] | null; // null -> not financed
        buyerCompanyId: string;
    }
) => {

    const [ selectedInvoiceBuyerEvent, setSelectedInvoiceBuyerEvent ] = useState<IBuyerEvent | null>(null);
    const [ showAddEventModal, setShowAddEventModal ] = useState(false);

    return (
        <div>
            { authUser?.canManageFinancings &&
                <Button id='displayAddEventModal'
                        className='p-margin-bottom-5'
                        isLoading={false}
                        text='Ajouter'
                        actionO={() => setShowAddEventModal(true)}/>
            }

            { (invoiceBuyerEvents.length > 0 || (financingMerciFacteurMailsO?.length ?? 0) > 0)
                ? <InvoiceEventsTable invoiceBuyerEvents={invoiceBuyerEvents}
                                      financingMerciFacteurMailsO={financingMerciFacteurMailsO}
                                      setSelectedInvoiceBuyerEvent={setSelectedInvoiceBuyerEvent} />
                : (!authUser?.canManageFinancings && <span>Aucun événement n'a été ajouté</span>)
            }

            { (showAddEventModal || !!selectedInvoiceBuyerEvent) &&
                <GraphQLQueryWrapper queryKey={buyerEventsInvoiceMatchesKey(buyerCompanyId)}
                                     axios={axios}
                                     queryParams={{
                                         query: `query($buyerCompanyId: Guid!) {
                                             buyerEventsInvoiceMatches (companyId: $buyerCompanyId) {
                                                 eventId
                                                 invoice { ${BuyerEventInvoiceGraphQLFields} }
                                             }
                                         }`,
                                         variables: {
                                             buyerCompanyId
                                         }
                                     }}
                                     onLoading={() => <Loader />}
                                     onSuccess={(r: IInvoiceDetailsEventsInfosQueryResult) =>
                                         <BuyerEventModal axios={axios}
                                                          authUser={authUser}
                                                          buyerCompanyId={buyerCompanyId}
                                                          buyersAndContactsLoaded={null}
                                                          existingBuyerEvent={selectedInvoiceBuyerEvent}
                                                          initialSelectedInvoicesIds={!!selectedInvoiceBuyerEvent
                                                              ? r.buyerEventsInvoiceMatches
                                                                    .filter((m) => m.eventId === selectedInvoiceBuyerEvent.id)
                                                                    .map((m) => m.invoice.id)
                                                              : [invoiceId]
                                                          }
                                                          alwaysSelectedInvoiceId={invoiceId}
                                                          closeModal={() => {
                                                              setSelectedInvoiceBuyerEvent(null);
                                                              setShowAddEventModal(false);
                                                          }} />
                                     } />
            }
        </div>
    );
}

export default InvoiceEventsSection;
