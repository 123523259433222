
import BuyersListLoaded from "./BuyersListLoaded";
import Loader from "factor-lib/Loader";
import { buyersQueryKey, buyersWithInvoiceQueryKey, IBuyerCompaniesQueryResult } from "./buyersQuery";
import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import {WithBuyerCompanyIdParamWrapper} from "./buyersUrls";
import {useQuery} from "@tanstack/react-query";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import DataLoader from "dataloader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";

export const BuyersListAxiosReady = (
    {
        graphQLDataLoader,
        includeBuyersWithoutInvoice,
        searchInput
    }: {
        graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        includeBuyersWithoutInvoice: boolean;
        searchInput: string;
    }
) => {
    const queryKey = includeBuyersWithoutInvoice ? buyersQueryKey : buyersWithInvoiceQueryKey;
    const query = useQuery<IBuyerCompaniesQueryResult>(
        queryKey,
        async ({ signal }) =>
            (await graphQLDataLoader.load({
                query: ({
                    query: `query {
                        companies (${includeBuyersWithoutInvoice ? 'isBuyer' : 'isBuyerWithInvoice'}: true) {
                            id
                            name
                            identifier { ${IntCompanyIdentifierGraphQLFields} }
                            asBuyer {
                                isBlocked
                            }
                        }
                    }`
                }),
                queryKey,
                signal
            })) as IBuyerCompaniesQueryResult
    );
    return (
        <ReactQueryResultWrapper result={query}
                                 onLoading={() => <Loader />}
                                 onSuccess={(r: IBuyerCompaniesQueryResult) =>
                                     <WithBuyerCompanyIdParamWrapper child={(selectedBuyerCompanyIdO: string | null) =>
                                         <BuyersListLoaded buyerCompanies={r.companies}
                                                           includeBuyersWithoutInvoice={includeBuyersWithoutInvoice}
                                                           selectedBuyerCompanyIdO={selectedBuyerCompanyIdO}
                                                           searchInput={searchInput} />
                                     }/>
                                 }
                                 displayFullError={true} />
    );
}

export default BuyersListAxiosReady;