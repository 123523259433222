import { useState } from "react";
import Button from "factor-lib/Buttons/Button";
import InvoiceFinancingEmailsTable from "./InvoiceEmails/InvoiceFinancingEmailsTable";
import {Axios} from "axios";

const InvoiceEmailsSection = (
    {
        axios,
        financingId,
    }: {
        axios: Axios;
        financingId: string | null;
    }
) => {
    const [fetchInvoiceFinancingEmails, setFetchInvoiceFinancingEmails] = useState(false);

    return (
        !!financingId
            ? fetchInvoiceFinancingEmails
                ? <InvoiceFinancingEmailsTable axios={axios}
                                               financingId={financingId} />
                : <Button id='fetchInvoiceFinancingEmailsButton'
                          text='Afficher'
                          isLoading={false}
                          actionO={() => setFetchInvoiceFinancingEmails(true)} />

            : <span>Cette facture n'est pas encore financée</span>
    );
}

export default InvoiceEmailsSection;
