import {PropsWithChildren} from "react";

const BuyerLimitsTable = (
    {
        children
    } : PropsWithChildren
) =>
    <table className='table is-striped is-bordered is-hoverable is-fullwidth'>
        <thead>
            <tr>
                <th>Company</th>
                <th>Encours</th>
                <th/>
                <th/>
            </tr>
        </thead>
        { children }
    </table>;

export default BuyerLimitsTable;
