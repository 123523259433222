import {Axios} from "axios";
import { useState } from "react";
import { ISellerDetailsQueryResult, sellerDetailsQueryKey } from "../../../sellerDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import IPerson, { getPersonParams, getUpdatePersonParams, IUpdatePersonParams, updatePerson } from "../../../../../../utils/Person/IPerson";
import PersonInput, { getInitialExistingPersonInput, getInitialNewPersonInput, IPersonInput } from "../../../../../../utils/Person/PersonInput";
import { ResultOrErrors } from "../../../../../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../../../../../utils/ShowValidationErrorsButton";
import { SIZE_FIXED, KIND_PRIMARY } from "factor-lib/Buttons/Button";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IAddressOInput} from "../../../../../../utils/AddressInput";
import Modal from "factor-lib/Modal";

const ButtonUpdateText = 'Modifier';
const ButtonAddText = 'Ajouter';
const ButtonSize = SIZE_FIXED;
const ButtonKind = KIND_PRIMARY;

const SetCompanyOwnerEnabled = (
    {
        axios,
        sellerId,
        companyOwnerUrlSuffix,
        params,
        closeModal
    } : {
        axios: Axios;
        sellerId: string;
        companyOwnerUrlSuffix: string;
        params: IPerson;
        closeModal: () => void;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const setCompanyOwnerInfosMutation: UseMutationResult<void, any, IPerson, null> =
        useMutation<void, any, IPerson, null>(
            async (params2) => {
                await axios.post<void>(
                    `/adminSellers/${sellerId}/${companyOwnerUrlSuffix}`,
                    params2
                );
            },
            ({
                onSuccess: (_, params2) => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old: ISellerDetailsQueryResult | undefined) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                companyOwner: params2
                            }
                        })
                    );
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='setCompanyOwnerButton'
                               kind={ButtonKind}
                               size={ButtonSize}
                               text={ButtonAddText}
                               mutation={setCompanyOwnerInfosMutation}
                               value={params}
                               displayFullError={true} />
    );
};

const UpdateCompanyOwnerEnabled = (
    {
        axios,
        sellerId,
        updateParams,
        closeModal
    } : {
        axios: Axios;
        sellerId: string;
        updateParams: IUpdatePersonParams;
        closeModal: () => void;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateCompanyOwnerInfosMutation: UseMutationResult<void, any, IUpdatePersonParams, null> =
        useMutation<void, any, IUpdatePersonParams, null>(
            async (updateParams2) => {
                await axios.put<void>(
                    `/adminSellers/${sellerId}/companyOwner`,
                    updateParams2
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old: ISellerDetailsQueryResult | undefined) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                companyOwner: updatePerson(old!.sellerDetails.companyOwner, updateParams)
                            }
                        })
                    );
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateCompanyOwnerButton'
                               kind={ButtonKind}
                               size={ButtonSize}
                               text={ButtonUpdateText}
                               mutation={updateCompanyOwnerInfosMutation}
                               value={updateParams}
                               displayFullError={true} />
    );
};

export const CompanyOwnerModalContent = (
    {
        axios,
        sellerId,
        addCompanyOwnerUrlSuffix,
        companyOwner,
        isBirthInfosMandatory,
        closeModal
    } : {
        axios: Axios;
        sellerId: string;
        addCompanyOwnerUrlSuffix: string;
        companyOwner: IPerson | null;
        isBirthInfosMandatory: boolean;
        closeModal: () => void;
    }
) => {
    const companyOwnerInput: IPersonInput = !!companyOwner
        ? getInitialExistingPersonInput(companyOwner)
        : getInitialNewPersonInput(isBirthInfosMandatory);

    const [firstNameInput, setFirstNameInput] = useState(companyOwnerInput.firstName);
    const [lastNameInput, setLastNameInput] = useState(companyOwnerInput.lastName);
    const [nationalityCodeInput, setNationalityCodeInput] = useState(companyOwnerInput.nationalityCode);

    const [birthDateInput, setBirthDateInput] = useState(companyOwnerInput.birthDate);
    const [birthCityInput, setBirthCityInput] = useState(companyOwnerInput.birthCity);
    const [birthCountryCodeInput, setBirthCountryCodeInput] = useState(companyOwnerInput.birthCountryCode);

    const [residenceAddressInput, setResidenceAddressInput] = useState<IAddressOInput>(companyOwnerInput.residenceAddress);

    const companyOwnerParams: ResultOrErrors<IPerson> = getPersonParams(
        firstNameInput,
        lastNameInput,
        nationalityCodeInput,

        isBirthInfosMandatory,
        birthDateInput,
        birthCityInput,
        birthCountryCodeInput,

        residenceAddressInput
    );

    const updateCompanyOwnerParams: ResultOrErrors<IUpdatePersonParams> | null = !!companyOwner
        ? getUpdatePersonParams(companyOwner, companyOwnerParams)
        : null;

    return (
        <div className='p-padding-3'>
            <PersonInput firstNameInput={firstNameInput}
                         lastNameInput={lastNameInput}
                         nationalityCodeInput={nationalityCodeInput}
                         isBirthInfosMandatory={isBirthInfosMandatory}
                         birthDateInput={birthDateInput}
                         birthCityInput={birthCityInput}
                         birthCountryCodeInput={birthCountryCodeInput}
                         residenceAddressInput={residenceAddressInput}
                         enabled={{
                             setFirstNameInput: setFirstNameInput,
                             setLastNameInput: setLastNameInput,
                             setNationalityCodeInput: setNationalityCodeInput,
                             setBirthDateInput: setBirthDateInput,
                             setBirthCityInput: setBirthCityInput,
                             setBirthCountryCodeInput: setBirthCountryCodeInput,
                             setResidenceAddressInput: setResidenceAddressInput,
                             isBirthInfosMandatory,
                             autofocus: true
                         }} />

            <div className='p-margin-top-4 p-vertical-center'>
                { !!companyOwner
                    ? !!updateCompanyOwnerParams
                        ? !!updateCompanyOwnerParams.result
                            ? <UpdateCompanyOwnerEnabled axios={axios}
                                                         sellerId={sellerId}
                                                         updateParams={updateCompanyOwnerParams.result}
                                                         closeModal={closeModal} />
                            : <ShowValidationErrorsButton validationErrors={updateCompanyOwnerParams.errors!}
                                                          kind={ButtonKind}
                                                          size={ButtonSize}
                                                          text={ButtonUpdateText} />
                        : <ButtonDisabled kind={ButtonKind}
                                          size={ButtonSize}
                                          text={ButtonUpdateText} />
                    : !!companyOwnerParams.result
                        ? <SetCompanyOwnerEnabled axios={axios}
                                                  sellerId={sellerId}
                                                  companyOwnerUrlSuffix={addCompanyOwnerUrlSuffix}
                                                  params={companyOwnerParams.result}
                                                  closeModal={closeModal} />
                        : <ShowValidationErrorsButton validationErrors={companyOwnerParams.errors!}
                                                      kind={ButtonKind}
                                                      size={ButtonSize}
                                                      text={ButtonAddText} />
                }
            </div>
        </div>
    );
}

const CompanyOwnerModal = (
    {
        axios,
        sellerId,
        companyOwner,
        addCompanyOwnerUrlSuffix,
        isBirthInfosMandatory,
        closeModal
    } : {
        axios: Axios;
        sellerId: string;
        addCompanyOwnerUrlSuffix: string;
        companyOwner: IPerson | null;
        isBirthInfosMandatory: boolean;
        closeModal: () => void;
    }
) =>
    <Modal id='companyOwnerModal'
           maxWidth='640px'
           active={true}
           fullMaxWidth={true}
           close={closeModal}>
        <CompanyOwnerModalContent axios={axios}
                                  sellerId={sellerId}
                                  addCompanyOwnerUrlSuffix={addCompanyOwnerUrlSuffix}
                                  companyOwner={companyOwner}
                                  isBirthInfosMandatory={isBirthInfosMandatory}
                                  closeModal={closeModal} />
    </Modal>;

export default CompanyOwnerModal;
