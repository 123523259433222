import { DimplOpsGraphQLFields, IDimplOps } from "../../../utils/IDimplOps";
import { IBuyerEventInvoiceChoiceStatus, InvoiceChoiceStatusGraphQLFields } from "./BuyerEventsSection/BuyerEventInvoices";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import {
} from "./BuyerEventsSection/BuyerEventsTableLoaded";

import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";

export const buyerCompanyDetailsQueryKey = (buyerCompanyId: string) =>
    ['company', 'id', buyerCompanyId, 'buyer', 'details'];

export const BuyerEventInvoiceGraphQLFields =
    `id number amountWoTax amountTax status { ${InvoiceChoiceStatusGraphQLFields} }`;

export const BuyerEmailGraphQLFields =
    'id value buyer { id } nbEvents nbInvoices';

export const BuyerPhoneGraphQLFields =
    'id value buyer { id } nbEvents nbInvoices';

export const BuyerEventContactGraphQLFields =
    'value id';

export const BuyerEventGraphQLFields =
    `
        id
        eventDateTime
        type
        notes
        email { ${BuyerEventContactGraphQLFields} }
        phone { ${BuyerEventContactGraphQLFields} }
        dimplOps { ${DimplOpsGraphQLFields} }
    `;

export const QueryStr =
    `query Q($buyerCompanyId: Guid!) {
        companyDetails (id: $buyerCompanyId) {
            id
            name
            identifier { ${IntCompanyIdentifierGraphQLFields} }
            asBuyer {
                adminNotes
                isBlocked
                outstanding
                manualOutstandingLimit
                opsTags {
                    certified
                    cycleComplete
                    public
                    notInsured
                }
                merciFacteurMails { id dimplOps { name } creationDateTime receivedDateTime type }
                financingsMerciFacteurMails { merciFacteurMail { id } financing { invoice { id number amountWoTax amountTax } } }
            }
        }
        buyers (companyId: $buyerCompanyId) {
            id
            outstanding
            askedOutstanding 
            askedOutstandingDateTime
            seller { id company { name } }
        }
        buyerEmails (companyId: $buyerCompanyId) {
            ${BuyerEmailGraphQLFields}
        }
        buyerPhones (companyId: $buyerCompanyId) {
            ${BuyerPhoneGraphQLFields}
        }
        buyerEvents (companyId: $buyerCompanyId) {
            ${BuyerEventGraphQLFields}
        }
        buyerEventsInvoiceMatches (companyId: $buyerCompanyId) {
            eventId
            invoice { ${BuyerEventInvoiceGraphQLFields} }
        }
   }`;

export interface IBuyerEventInvoice {
    id: string;
    number: string;
    amountWoTax: number;
    amountTax: number;
    status: IBuyerEventInvoiceChoiceStatus;
}

export interface IBuyerEventContact {
    value: string;
    id: string; // either emailId, or phoneId
}

export interface IBuyerOpsTags {
    certified: boolean;
    cycleComplete: boolean;
    public: boolean;
    notInsured: boolean;
}

export interface IBuyerEvent {
    id: string;
    dimplOps: IDimplOps;
    eventDateTime: string;
    type: number;
    notes: string | null;

    // at most one of these two is present
    email: IBuyerEventContact | null;
    phone: IBuyerEventContact | null;
}

export interface IBuyerEventInvoiceMatch {
    eventId: string;
    invoice: IBuyerEventInvoice;
}

export interface IBuyer {
    id: string;
    outstanding: number;
    askedOutstanding: number | null;
    askedOutstandingDateTime: string | null;
    seller: {
        id: string;
        company: {
            name: string;
        };
    };
}

export interface IBuyerEmail {
    id: string;
    value: string;
    buyer: {
        id: string;
    };
    nbEvents: number;
    nbInvoices: number;
}

export interface IBuyerPhone {
    id: string;
    value: string;
    buyer: {
        id: string;
    };
    nbEvents: number;
    nbInvoices: number;
}

export interface IFinancingMerciFacteurMail {
    invoice: {
        id: string;
        number: string;
        amountWoTax: number;
        amountTax: number;
    };
}

export interface IMerciFacteurMail {
    id: number;
    dimplOps: {
        name: string;
    };
    creationDateTime: string;
    receivedDateTime: string | null;
    type: number;
}

export interface IFinancingWithMerciFacteurMail {
    merciFacteurMail: {
        id: number
    };
    financing: IFinancingMerciFacteurMail;
}

export interface IBuyerCompanyDetails {
    id: string;
    name: string;
    identifier: IIntCompanyIdentifier;

    asBuyer: {
        adminNotes: string | null;
        isBlocked: boolean;
        outstanding: number;
        manualOutstandingLimit: number | null;
        opsTags: IBuyerOpsTags;
        merciFacteurMails: IMerciFacteurMail[];
        financingsMerciFacteurMails: IFinancingWithMerciFacteurMail[];
    };
}

export interface IBuyerCompanyDetailsQueryResult {
    companyDetails: IBuyerCompanyDetails;
    buyers: IBuyer[];
    buyerEmails: IBuyerEmail[];
    buyerPhones: IBuyerPhone[];
    buyerEvents: IBuyerEvent[];
    buyerEventsInvoiceMatches: IBuyerEventInvoiceMatch[];
}
