import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {buyerCompanyDetailsQueryKey, IBuyerCompanyDetailsQueryResult} from "../buyerCompanyDetailsQuery";
import {Axios} from "axios";
import Link from "factor-lib/Link";
import { computeText } from 'factor-lib/Buttons/ButtonMutationEnabled';

const BuyerContactDelete = (
    {
        className,
        axios,
        buyerCompanyId,
        buyerContactId,
        buyerContactEndpoint,
        updateQuery,
        closeModal
    }: {
        className?: string;
        axios: Axios;
        buyerCompanyId: string;
        buyerContactId: string;
        buyerContactEndpoint: (buyerContactId: string) => string;
        updateQuery: (old: IBuyerCompanyDetailsQueryResult) => IBuyerCompanyDetailsQueryResult;
        closeModal: () => void;
    }
) => {

    const queryClient = useQueryClient();

    const deleteBuyerContactMutation: UseMutationResult<void, any, null> =
        useMutation<void, any, null>(
            async () => {
                await axios.delete<void>(
                    `/adminBuyerContacts/${buyerContactEndpoint(buyerContactId)}`
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<IBuyerCompanyDetailsQueryResult>(
                        buyerCompanyDetailsQueryKey(buyerCompanyId),
                        (old: IBuyerCompanyDetailsQueryResult | undefined) => !!old ? updateQuery(old) : undefined
                    );
                    closeModal();
                }
            })
        );

    return (
        <Link id='updateBuyerContactButton'
              className={className}
              text={deleteBuyerContactMutation.isLoading ? 'Suppression en cours...' : computeText('Supprimer', deleteBuyerContactMutation, true)}
              action={{
                  clickHandler: () => deleteBuyerContactMutation.mutate(null)
              }} />
    );
};

export default BuyerContactDelete;