import { IBuyerCompanyDetails } from "../buyerCompanyDetailsQuery";
import IAuthUser from "../../../../IAuthUser";
import { Axios } from "axios";
import BuyerOpsNotes from "./BuyerOpsNotes";
import BuyerOpsTags from "./BuyerOpsTags";
import DataLoader from "dataloader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import DimplScoreLoaderDL from "../../../../utils/DimplScore/DimplScoreLoaderDL";
import {buyerCompanyIdGraphQLVariableName} from "../buyerCompanyIdGraphQLVariableName";

const BuyerSpecialInfosSection = (
    {
        axios,
        dsGraphQLDataLoader,
        authUser,
        buyerCompanyDetails
    }: {
        axios: Axios;
        dsGraphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        authUser: IAuthUser | null;
        buyerCompanyDetails: IBuyerCompanyDetails;
    }
) =>
    <div>
        <DimplScoreLoaderDL dsGraphQLDataLoader={dsGraphQLDataLoader}
                            companyId={buyerCompanyDetails.id}
                            graphQLCompanyVariableName={buyerCompanyIdGraphQLVariableName}/>
        <BuyerOpsTags className='p-margin-top-4'
                      axios={axios}
                      authUser={authUser}
                      companyId={buyerCompanyDetails.id}
                      opsTags={buyerCompanyDetails.asBuyer.opsTags}/>

        <BuyerOpsNotes className='p-margin-top-4'
                       axios={axios}
                       authUser={authUser}
                       companyId={buyerCompanyDetails.id}
                       opsNotes={buyerCompanyDetails.asBuyer.adminNotes} />
    </div>;

export default BuyerSpecialInfosSection;
