import IAuthUser from "../../../../IAuthUser";
import { Axios } from "axios";
import CustomerAccountManager from "./CustomerMiscInfos/CustomerAccountManager";
import { IDimplOps } from "../../../../utils/IDimplOps";
import CustomerNewPricing from "./CustomerMiscInfos/CustomerNewPricing";


const CustomerMiscSection = (
    {
        axios,
        authUser,
        customerId,
        newPricingRequiredDate,
        newPricingAcceptedDate,
        accountManager
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        customerId: string;
        newPricingRequiredDate: string | null;
        newPricingAcceptedDate: string | null;
        accountManager: IDimplOps;
    }
) => {
    
    return (
        <div>
            <CustomerAccountManager axios={axios}
                                    authUser={authUser}
                                    customerId={customerId}
                                    accountManager={accountManager} />

            <CustomerNewPricing className='p-margin-top-4'
                                axios={axios}
                                authUser={authUser}
                                customerId={customerId}
                                newPricingRequiredDate={newPricingRequiredDate}
                                newPricingAcceptedDate={newPricingAcceptedDate} />                   
        </div>            
    );
}

export default CustomerMiscSection;
