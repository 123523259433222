import IAccessToken from "../../../IAccessToken";
import SellerInfosTemplate from "./SellerInfosTemplate";
import {sellerDetailsQueryKey, ISellerDetailsQueryResult, sellerDetailsGraphQLQuery} from "./sellerDetailsQuery";
import IAuthUserLoad from "../../../IAuthUserLoad";
import GraphQLWithAccessTokenWrapper from "../../../GraphQLQueryWithAccessTokenWrapper";

const SellerInfosTab = (
    {
        accessTokenO,
        sellerId
    }: {
        accessTokenO: IAccessToken | null,
        sellerId: string
    }
) =>
    <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                   queryKey={sellerDetailsQueryKey(sellerId)}
                                   queryParams={sellerDetailsGraphQLQuery(sellerId)}
                                   onLoading={() =>
                                       <SellerInfosTemplate sellerId={sellerId}
                                                            loaded={null} />
                                   }
                                   onSuccess={
                                        (r: IAuthUserLoad<ISellerDetailsQueryResult>) =>
                                            <SellerInfosTemplate sellerId={sellerId}
                                                                 loaded={({
                                                                     axios: r.axios,
                                                                     authUserO: r.authUserO,
                                                                     value: r.value?.sellerDetails || null
                                                                 })} />
                                   } />;

export default SellerInfosTab;
