import { PropsWithChildren } from "react";
import SplitPane from "react-split-pane";

import './splitpanes.scss';

const SplitPaneWrapper = (
    {
        className,
        defaultSize,
        children,
    }: PropsWithChildren<{
        className?: string;
        defaultSize: string;
    }>
) =>
    // SplitPane is not up-to-date with react
    // @ts-ignore
    <SplitPane className={`p-splitpanes${!!className ? ` ${className}` : ''}`}
               split='vertical'
               defaultSize={defaultSize}>
        { children }
    </SplitPane>;

export default SplitPaneWrapper;
