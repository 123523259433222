import BilanSelectionModal from "./BilanSelectionModal";
import {NavigateFunction} from "react-router-dom";
import {IBilansDescriptions} from "./IBilanDescription";
import ReactQueryWrapper from "../../utils/ReactQueryWrapper";
import {Axios} from "axios";
import bilanEndPoint from "./Bilan/bilanEndPoint";
import {bilansQueryKey} from "./bilansQuery";
import {DataCompanySearchUrl} from "./dataUrls";
import {useContext} from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";

//*
const searchBilansActionAsync = async (
    dataAxios: Axios,
    siren: string,
    signal?: AbortSignal
): Promise<IBilansDescriptions> =>
    (await dataAxios.get<{bilanDescriptions: IBilansDescriptions}>(
        `${bilanEndPoint}/list?siren=${siren}`,
        { signal }
    )).data.bilanDescriptions;
 //*/

const SirenAndDataClientLoaded = (
    {
        dataAxios,
        siren,

    }: {
        dataAxios: Axios;
        siren: string;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    return (
        <ReactQueryWrapper queryKey={bilansQueryKey(siren)}
                           queryFunc={(signal?: AbortSignal) =>
                               searchBilansActionAsync(dataAxios, siren, signal)
                               /*
                               Promise.resolve<IBilansDescriptions>([
                                   {
                                       dateCloture: "2021-12-31T00:00:00Z",
                                       bilanType: "consolide",
                                       confidentialite: "non confidentiel",
                                       proprietaryLiasse: true
                                   },
                                   {
                                       dateCloture: "2021-12-31T00:00:00Z",
                                       bilanType: "complet",
                                       confidentialite: "non confidentiel",
                                       proprietaryLiasse: false
                                   },
                                   {
                                       dateCloture: "2020-12-31T00:00:00Z",
                                       bilanType: "consolide",
                                       confidentialite: "non confidentiel",
                                       proprietaryLiasse: false
                                   },
                                   {
                                       dateCloture: "2020-12-31T00:00:00Z",
                                       bilanType: "complet",
                                       confidentialite: "non confidentiel",
                                       proprietaryLiasse: false
                                   }
                               ]) */
                           }
                           onSuccess={(d) =>
                               <BilanSelectionModal siren={siren}
                                                    existingBilans={d}
                                                    closeModal={() => navigate(DataCompanySearchUrl)} />
                           } />

    );
}

export default SirenAndDataClientLoaded;
