import Modal from "factor-lib/Modal";
import { Axios } from "axios";
import FinancingRequestsBatchUpdateTags from "./FinancingRequestsBatchUpdateTags"
import { ReactNode, useState } from "react";
import IBaseInvoice from "../IInvoice";

interface ITabDef {
    title: string;
    component: (
        axios: Axios,
        selectedInvoices: string[],
        closeModal: () => void
    ) => ReactNode;
}

const Tabs: ITabDef[] = [
    {
        title: 'Edit ops tags',
        component: (axios, selectedInvoicesIds, closeModal) =>
            <FinancingRequestsBatchUpdateTags axios={axios}
                                              selectedInvoicesIds={selectedInvoicesIds}
                                              closeModal={closeModal} />
    },
];

export const FinancingRequestsBatchUpdateModalContent = (
    {
        axios,
        selectedInvoices,
        closeModal
    }: {
        axios: Axios;
        selectedInvoices: IBaseInvoice[];
        closeModal: () => void;
    }
) => {
    const [selectedTabIndex, setSelectedIndex] = useState(0 /* Customer requests */);
    return (
        <div className='container p-margin-4' style={{height: '400px'}}>
            <div className='title p-vertical-center'>
                Editing {selectedInvoices.map(i => i.number).join(',')}
            </div>
            <div className='tabs'>
                <ul>
                    { Tabs.map((t, tIndex) => (
                        <li key={`t-${tIndex}`}
                            className={selectedTabIndex === tIndex ? 'is-active' : ''}
                            onClick={() => setSelectedIndex(tIndex)}>
                            <a>{ t.title }</a>
                        </li>
                    ))}
                </ul>
            </div>

            { Tabs[selectedTabIndex].component(axios, selectedInvoices.map((i) => i.id), closeModal) }
        </div>
    );
}

const FinancingRequestsBatchUpdateModal = (
    {
        axios,
        selectedInvoices,
        closeModal
    }: {
        axios: Axios;
        selectedInvoices: IBaseInvoice[];
        closeModal: () => void;
    }
) =>
    <Modal active={true}
           fullMaxWidth={false}
           id='batchInvoiceUpdateModal'
           close={closeModal}
           maxWidth='600px'>
        <FinancingRequestsBatchUpdateModalContent axios={axios}
                                                  selectedInvoices={selectedInvoices}
                                                  closeModal={closeModal} />
    </Modal>;

export default FinancingRequestsBatchUpdateModal;
