import { useState } from "react";
import IAccessToken from "../../IAccessToken";
import MultipleSelect from "factor-lib/forms/Select/MultipleSelect";
import IOption from "factor-lib/forms/Select/IOption";
import {
    approvedState,
    IArchivedPreInvoiceEmail,
    IPendingPreInvoiceEmail,
    IPreInvoiceWithEmailRecipients,
    rejectedState
} from "./preInvoicesQuery";
import PreInvoicesTable from "./PreInvoicesTable";
import PreInvoicesSyncButton from "./PreInvoicesSyncButton";

interface IPreInvoiceFilter {
    // one or the other
    pendingFilter: ((preInvoice: IPreInvoiceWithEmailRecipients<IPendingPreInvoiceEmail>) => boolean) | null;
    archivedFilter: ((preInvoice: IPreInvoiceWithEmailRecipients<IArchivedPreInvoiceEmail>) => boolean) | null;
}

const PreInvoicesReadyFilter: IOption<IPreInvoiceFilter> = {
    label: 'Prêt à traiter',
    value: {
        pendingFilter: (p) => !!p.preInvoice.email.matchingBuyerEmailId && !!p.preInvoice.email.matchingSellerId,
        archivedFilter: null
    }
};

const PreInvoicesNewBuyerFilter: IOption<IPreInvoiceFilter> = {
    label: 'Nouveau buyer',
    value: {
        pendingFilter: (p) => !p.preInvoice.email.matchingBuyerEmailId && !!p.preInvoice.email.matchingSellerId && p.preInvoiceEmailRecipients.length > 0,
        archivedFilter: null
    }
};

const PreInvoicesUnknownBuyerFilter: IOption<IPreInvoiceFilter> = {
    label: 'Buyer inconnu',
    value: {
        pendingFilter: (p) => !p.preInvoice.email.matchingBuyerEmailId && !!p.preInvoice.email.matchingSellerId && p.preInvoiceEmailRecipients.length === 0,
        archivedFilter: null
    }
};

const PreInvoicesUnknownSellerFilter: IOption<IPreInvoiceFilter> = {
    label: 'Seller inconnu',
    value: {
        pendingFilter: (p) => !p.preInvoice.email.matchingSellerId,
        archivedFilter: null
    }
};

const PreInvoicesFilters: Array<IOption<IPreInvoiceFilter>> = [
    PreInvoicesReadyFilter,
    PreInvoicesNewBuyerFilter,
    PreInvoicesUnknownBuyerFilter,
    PreInvoicesUnknownSellerFilter,
    {
        label: 'Archived discarded',
        value: {
            pendingFilter: null,
            archivedFilter: (p) => p.preInvoice.status === rejectedState
        }
    },
    {
        label: 'Archived processed',
        value: {
            pendingFilter: null,
            archivedFilter: (p) => p.preInvoice.status === approvedState
        }
    }
];

const PreInvoicesPage = (
    {
        accessTokenO
    }: {
        accessTokenO : IAccessToken | null;
    }
) => {
    const [ selectedPreInvoicesFilters, setSelectedPreInvoicesFilters ] = useState<ReadonlyArray<IOption<IPreInvoiceFilter>>>(
        [PreInvoicesReadyFilter, PreInvoicesNewBuyerFilter, PreInvoicesUnknownBuyerFilter, PreInvoicesUnknownSellerFilter]
    );

    const selectedFiltersPending = selectedPreInvoicesFilters.filter((f) => !!f.value.pendingFilter).map((f) => f.value.pendingFilter!);
    const selectedFiltersArchived = selectedPreInvoicesFilters.filter((f) => !!f.value.archivedFilter).map((f) => f.value.archivedFilter!);

    const preInvoiceFilterPending = (p: IPreInvoiceWithEmailRecipients<IPendingPreInvoiceEmail>): boolean => selectedFiltersPending.some(f => f(p));
    const preInvoiceFilterArchived = selectedFiltersArchived.length > 0
        ? (p: IPreInvoiceWithEmailRecipients<IArchivedPreInvoiceEmail>): boolean => selectedFiltersArchived.some(f => f(p))
        : null;

    return (
        <div>
            <div className='level p-margin-left-4 p-margin-right-4' style={{marginTop: '0 !important'}}>
                <div className='level-left p-size-62'>
                    <MultipleSelect options={PreInvoicesFilters}
                                    selectedOptions={selectedPreInvoicesFilters}
                                    setSelectedOptions={setSelectedPreInvoicesFilters} />
                </div>

                { !!accessTokenO?.authUserO?.canManageFinancings &&
                    <div className='level-right'>
                        <PreInvoicesSyncButton axios={accessTokenO.axios}
                                               text='Synchroniser' />
                    </div>
                }
            </div>

            <PreInvoicesTable accessTokenO={accessTokenO}
                              pendingPreInvoiceFilter={preInvoiceFilterPending}
                              archivedPreInvoiceFilter={preInvoiceFilterArchived}  />
        </div>
    );
}

export default PreInvoicesPage;
