import { Link } from "react-router-dom";
import {ISellerCompany} from "../invoiceDetailsQuery";
import {useQuery} from "@tanstack/react-query";
import {sellerUrl} from "../../Sellers/sellersUrls";
import { formatIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import DataLoader from "dataloader";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import DimplScoreResult from "../../../utils/DimplScore/DimplScoreResult";
import Loader from "factor-lib/Loader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import dimplScoreSingleCompanyQuery, {dimplScoreQueryKey, IDimplScoreQueryResult} from "../../../utils/DimplScore/dimplScoreSingleCompanyQuery";


const DS = (
    {
        dsGraphQLDataLoader,
        sellerCompanyId
    }: {
        dsGraphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        sellerCompanyId: string;
    }
) => {
    const dsQueryKey = dimplScoreQueryKey(sellerCompanyId);

    const queryResult = useQuery<IDimplScoreQueryResult /* should be consistent with DimplScoreLoader */>(
        dsQueryKey,
        async ({ signal }) => {
            const query: IGraphQLParams =
                    dimplScoreSingleCompanyQuery('sellerDS', 'sellerCompanyId', sellerCompanyId)
                /* {
                    // we use an alias because the buyer's dimpl score is being fetched in the same query
                    query: `query Q($sellerCompanyId: Guid!) {
                        sellerDS: companyDetails(id: $sellerCompanyId) {
                            ${DimplScoreGraphQLQueryFields}
                        }
                     }`,
                    variables: {
                        sellerCompanyId: sellerCompanyId
                    }
                }*/ ;
            return (await dsGraphQLDataLoader.load({
                query,
                queryKey: dsQueryKey,
                signal
            })).sellerDS;
        }
    );

    return (
        <ReactQueryResultWrapper result={queryResult}
                                 onLoading={() => <Loader />}
                                 onSuccess={(r: IDimplScoreQueryResult) =>
                                     <DimplScoreResult className='p-margin-top-5'
                                                       dimplScore={r.dimplScore}/>
                                 }
                                 displayFullError={true} />
    );
}

const InvoiceSellerInfosSection = (
    {
        dsGraphQLDataLoader,
        sellerId,
        email,
        phone,
        company
    } : {
        dsGraphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        sellerId: string;
        email: string;
        phone: string;
        company: ISellerCompany;
    }
) =>
    <div>
        <table className='table is-narrow'>
            <tbody>
            <tr>
                <td className='p-bold'>Nom société</td>
                <td>
                    { company.name }
                    &nbsp;[
                    <Link id='invoiceSellerLink'
                          to={sellerUrl(sellerId)}>
                        lien
                    </Link>
                    ]
                </td>
            </tr>
            <tr>
                <td className='p-bold'>Identifiant</td>
                <td>{ formatIntCompanyIdentifier(company.identifier) }</td>
            </tr>
            <tr>
                <td className='p-bold'>Email</td>
                <td>{ email }</td>
            </tr>
            <tr>
                <td className='p-bold'>Téléphone</td>
                <td>{ phone}</td>
            </tr>
            </tbody>
        </table>

        <DS dsGraphQLDataLoader={dsGraphQLDataLoader}
            sellerCompanyId={company.id}/>
    </div>;

export default InvoiceSellerInfosSection;
