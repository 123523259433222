import {ReactElement} from "react";
import {useLocation, useParams, useSearchParams} from "react-router-dom";

export const BuyersUrl = '/buyers';

const BuyerRouteBuyerCompanyIdParam = 'buyerCompanyId';
const BuyerUrlIncludeWithoutInvoiceQueryParam = 'includeWithoutInvoices';
export const BuyerUrlMapping = `${BuyersUrl}/:${BuyerRouteBuyerCompanyIdParam}`;

export const BuyerInfosTabParam = 'infos';
export const buyerUrl = (buyerCompanyId: string, includeWithoutInvoice: boolean) =>
    buyerUrlTab(
        {
            buyerCompanyId,
            tabO: BuyerInfosTabParam /* default */
        },
        includeWithoutInvoice
    );

interface IBuyerCompanyIdWithTabPath {
    buyerCompanyId: string;
    tabO: string | null;
}

export const buyerUrlTab = (buyerCompanyIdO: IBuyerCompanyIdWithTabPath | null, includeWithoutInvoice: boolean) =>
    `${BuyersUrl}${!!buyerCompanyIdO ? `/${buyerCompanyIdO.buyerCompanyId}${!!buyerCompanyIdO.tabO ? `/${buyerCompanyIdO.tabO}` : ''}` : ''}?${BuyerUrlIncludeWithoutInvoiceQueryParam}=${includeWithoutInvoice}`;

export const AddBuyerUrl = `${BuyersUrl}/add`;
export const AddBuyerSellerIdQueryParamName: string = 'sellerId';
export const addBuyerForSellerUrl = (sellerId: string) => `${AddBuyerUrl}?${AddBuyerSellerIdQueryParamName}=${sellerId}`;

export const WithBuyerCompanyIdParamWrapper = (
    {
        child
    }: {
        child: (buyerCompanyIdO: string | null) => ReactElement;
    }
) =>
    child((useParams()[BuyerRouteBuyerCompanyIdParam]) || null);

export const WithIncludeBuyersWithoutInvoiceQueryParamWrapper = (
    {
        child
    }: {
        child: (includeBuyersWithoutInvoice: boolean) => ReactElement;
    }
) => {
    const [searchParams] = useSearchParams();
    const includeBuyersWithoutInvoice: boolean = searchParams.get(BuyerUrlIncludeWithoutInvoiceQueryParam) === 'true';
    return child(includeBuyersWithoutInvoice);
}


const tabStartOffset = BuyersUrl.length + 36 + 2 /* // + guid size */;

const getUrlTab = (fullPath: string): string | null => {
    if (fullPath.length < tabStartOffset) {
        return null;
    }
    return fullPath.substring(tabStartOffset);
};

export const WithBuyerTabParamWrapper = (
    {
        child
    }: {
        child: (tabO: string | null) => ReactElement;
    }
) =>
    child(getUrlTab(useLocation().pathname) || null);