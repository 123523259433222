import { isValidString } from "factor-lib/forms/Inputs/utils";
import { countryOptionsByCode } from "./Countries";
import { ResultOrErrors } from "./ResultOrErrors";

// Similar to IAddressInput, but fields are valid
export default interface IAddress {
    streetAddress: string;
    city: string;
    postCode: string;
    countryCode: string;
}

export const AddressGraphQLFields =
    'streetAddress city postCode countryCode';

export const formatAddress = (address: IAddress): string =>
    `${address.streetAddress}, ${address.city} ${address.postCode}, ${address.countryCode}`;

// returns null if no address field was filled
const getAddressValidationErrors = (
    streetAddress: string,
    city: string,
    postCode: string,
    country: string | null
): string[] | null=> {
    const errors: string[] = [];

    if (streetAddress === '' && city === '' && postCode === '' && country === null) {
        return null;
    }

    if (!isValidString(streetAddress)) {
        errors.push(`L'addresse est invalide`);
    }

    if (!isValidString(city)) {
        errors.push(`La ville est invalide`);
    }

    if (!isValidString(postCode)) {
        errors.push(`Le code postal est invalide`);
    }

    if (country == null || !countryOptionsByCode.get(country)) {
        errors.push(`Le pays est invalide`);
    }

    return errors;
};

export const getAddressParams = (
    streetAddress: string,
    city: string,
    postCode: string,
    countryCode: string | null
): ResultOrErrors<IAddress> | null => {

    const validationErrors: string[] | null = getAddressValidationErrors(
        streetAddress,
        city,
        postCode,
        countryCode,
    );

    if (validationErrors == null) {
        return null;
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    return ResultOrErrors.fromResult({
        streetAddress: streetAddress,
        city: city,
        postCode: postCode,
        countryCode: countryCode!
    });
};

export interface IUpdateAddressParams {
    // null -> no update
    streetAddress: string | null;
    city: string | null;
    postCode: string | null;
    countryCode: string | null;
}

export const getUpdateAddressParams = (
    old: IAddress,
    addressParams: IAddress
): IUpdateAddressParams | null => {

    const streetAddressUpdate: string | null = (!old || addressParams.streetAddress !== old.streetAddress) ? addressParams.streetAddress : null;
    const cityUpdate: string | null = (!old || addressParams.city !== old.city) ? addressParams.city : null;
    const postCodeUpdate: string | null = (!old || addressParams.postCode !== old.postCode) ? addressParams.postCode : null;
    const countryUpdate: string | null = (!old || addressParams.countryCode !== old.countryCode) ? addressParams.countryCode : null;

    return (streetAddressUpdate !== null || cityUpdate !== null || postCodeUpdate !== null || countryUpdate !== null)
        ? ({
            streetAddress: streetAddressUpdate,
            city: cityUpdate,
            postCode: postCodeUpdate,
            countryCode: countryUpdate
        }) : null;
};

// if any update field is null, old cannot be null
export const updateAddress = (
    old: IAddress | null,
    update: IUpdateAddressParams
): IAddress => ({
    streetAddress: update.streetAddress ?? old!.streetAddress,
    city: update.city ?? old!.city,
    postCode: update.postCode ?? old!.postCode,
    countryCode: update.countryCode ?? old!.countryCode
});
