import SettledFinancingsTable, { ISettledFinancingsQueryParams } from "./SettledFinancingsTable";
import { useState } from "react";
import IAccessToken from "../../IAccessToken";
import IOption from "factor-lib/forms/Select/IOption";
import { ISettledFinancing } from "./settledFinancingsQuery";

const SettledFinancingsTableStateFilter = (
    {
        accessTokenO,
        queryKey,
        queryParam
    }: {
        accessTokenO: IAccessToken | null;
        queryKey: string[];
        queryParam: ISettledFinancingsQueryParams;
    }
) => {
    const [ selectedFilter, setSelectedFilter ] = useState<IOption<(settledFinancing: ISettledFinancing) => boolean> | null>(null);

    return (
        <SettledFinancingsTable accessTokenO={accessTokenO}
                                queryKey={queryKey}
                                queryParam={queryParam}
                                selectedFilter={selectedFilter}
                                setSelectedFilter={setSelectedFilter}
                                searchStrFilterO={null} />
    );
}

export default SettledFinancingsTableStateFilter;
