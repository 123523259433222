export enum ConfidenceFlag {
    GREEN = 'GREEN',
    //GRAY = 'GRAY',
    RED = 'RED',
    ORANGE = 'ORANGE'
}

export const CONFIDENCE_FLAGS_COLORS: { [key in ConfidenceFlag]: string; } = {
    [ConfidenceFlag.RED]: 'red',
    //[ConfidenceFlag.GRAY]: 'gray',
    [ConfidenceFlag.ORANGE]: 'orange',
    [ConfidenceFlag.GREEN]: 'green'
};

const VALID_CONFIDENCE_FLAGS = [
    ConfidenceFlag.ORANGE,
    ConfidenceFlag.GREEN
];

export const isValidConfidenceFlag = (flag: ConfidenceFlag | null): boolean =>
    !!flag && VALID_CONFIDENCE_FLAGS.includes(flag);
