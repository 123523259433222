import {Axios} from "axios";
import {IInvoiceDetailsQueryResult, invoiceDetailsQueryKey} from "../../../invoiceDetailsQuery";
import { useState } from "react";
import {ButtonKind, ButtonSize} from "../CustomerFinancingRequestButtonProps";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { GlobalOutstandingsQueryKey } from "../../../../Misc/GlobalOutstandings/globalOutstandingsQuery";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import {isValidString, validateMandatoryFieldError } from "factor-lib/forms/Inputs/utils";
import Input from "factor-lib/forms/Inputs/Input";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import Modal from "factor-lib/Modal";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import { FinancingRequestsQueryKeyPrefix, IAcceptedOrNot, ICustomerFinancingRequestsResponse } from "../../../../../utils/FinancingRequest/FinancingRequestsQuery";

const RejectText = 'Rejeter';

const RejectCustomerFinancingRequestEnabled = (
    {
        axios,
        invoiceId,
        customerFinancingRequestId,
        rejectionReason,
        closeModal
    } : {
        axios: Axios;
        invoiceId: string;
        customerFinancingRequestId: string;
        rejectionReason: string;
        closeModal: () => void;
    }
) => {

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const rejectCustomerFinancingRequestMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (rejectionReason2) => {
                await axios.post<void>(
                    `/adminCustomerFinancingRequests/${customerFinancingRequestId}/reject`,
                    {
                        reason: rejectionReason2
                    }
                );
            },
            ({
                onSuccess: () => {
                    // update invoice details
                    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                        invoiceDetailsQueryKey(invoiceId),
                        (old: IInvoiceDetailsQueryResult | undefined) => ({
                            invoiceDetails: {
                                ...old!.invoiceDetails,
                                status: {
                                    ...old!.invoiceDetails.status,
                                    customerFinancingRequest: {
                                        ...old!.invoiceDetails.status.customerFinancingRequest!, // can't be null
                                        rejectedReason: rejectionReason,
                                        rejectedDate: serverDateSerialization(new Date())
                                    }
                                }
                            },
                            buyerEvents: old!.buyerEvents
                        })
                    );

                    // remove from financing requests
                    queryClient.setQueriesData<ICustomerFinancingRequestsResponse<IAcceptedOrNot>>(
                        FinancingRequestsQueryKeyPrefix,
                        old => !!old ? {
                            ...old,
                            financingRequests: {
                                ...old.financingRequests,
                                base: old.financingRequests
                                    .base.filter((f) => f.id !== customerFinancingRequestId)
                            }
                        } : undefined
                    );

                    closeModal();

                    return queryClient.invalidateQueries(GlobalOutstandingsQueryKey);
                }
            })
        );

    return (
        <ButtonMutationEnabled id='rejectCustomerFinancingRequestButton'
                               kind={ButtonKind}
                               size={ButtonSize}
                               text={RejectText}
                               mutation={rejectCustomerFinancingRequestMutation}
                               displayFullError={true}
                               value={rejectionReason} />

    );
}

const RejectCustomerFinancingRequestModalContent = (
    {
        axios,
        invoiceId,
        customerFinancingRequestId,
        closeModal
    }: {
        axios: Axios,
        invoiceId: string,
        customerFinancingRequestId: string,
        closeModal: () => void
    }
) => {
    const [ rejectionReason, setRejectionReason ] = useState('');

    return (
        <div className='p-vertical-center p-padding-3'>
            <Input className='p-margin-bottom-5'
                   inputValue={rejectionReason}
                   placeHolder='Rejection reason'
                   enabled={{
                       updateInputValue: setRejectionReason,
                       validateInput: () => validateMandatoryFieldError(rejectionReason),
                       innerId: {
                           value: 'rejectionReasonInner',
                           autofocus: true
                       }
                   }}/>

            { !!rejectionReason && isValidString(rejectionReason)
                ? <RejectCustomerFinancingRequestEnabled axios={axios}
                                                         invoiceId={invoiceId}
                                                         customerFinancingRequestId={customerFinancingRequestId}
                                                         rejectionReason={rejectionReason}
                                                         closeModal={closeModal} />
                : <ButtonDisabled kind={ButtonKind}
                                  size={ButtonSize}
                                  text={RejectText} />

            }
        </div>
    );
}

const RejectCustomerFinancingRequestModal = (
    {
        axios,
        invoiceId,
        customerFinancingRequestId,
        closeModal
    }: {
        axios: Axios,
        invoiceId: string,
        customerFinancingRequestId: string,
        closeModal: () => void
    }
) =>
    <Modal id='rejectCustomerFinancingRequestModal'
           maxWidth={null}
           fullMaxWidth={false}
           close={closeModal}>
        <RejectCustomerFinancingRequestModalContent axios={axios}
                                                    invoiceId={invoiceId}
                                                    customerFinancingRequestId={customerFinancingRequestId}
                                                    closeModal={closeModal}/>
    </Modal>;

const InvoiceRejectCustomerFinancingRequest = (
    {
        className,
        axios,
        invoiceId,
        customerFinancingRequestId,
    }: {
        className?: string;
        axios: Axios;
        invoiceId: string;
        customerFinancingRequestId: string;
    }
) =>
    <ButtonForModal id='rejectCustomerFinancingRequest'
                    className={className}
                    kind={ButtonKind}
                    size={ButtonSize}
                    text='Reject Customer Financing'
                    modalMaxWidth={null}
                    modalFullMaxWidth={false}
                    childModalContent={(closeModal) =>
                        <RejectCustomerFinancingRequestModal axios={axios}
                                                             invoiceId={invoiceId}
                                                             customerFinancingRequestId={customerFinancingRequestId}
                                                             closeModal={closeModal}/>
                    }/>
export default InvoiceRejectCustomerFinancingRequest;
