import InvoiceAcceptCustomerFinancingRequest from "./InvoiceCustomerFinancingRequestActions/InvoiceAcceptCustomerFinancingRequest";
import InvoiceRejectCustomerFinancingRequest from "./InvoiceCustomerFinancingRequestActions/InvoiceRejectCustomerFinancingRequest";
import InvoiceResetCustomerFinancingRequest from "./InvoiceCustomerFinancingRequestActions/InvoiceResetCustomerFinancingRequest";
import InvoiceDeleteCustomerFinancingRequest from "./InvoiceCustomerFinancingRequestActions/InvoiceDeleteCustomerFinancingRequest";
import InvoiceFinance from "./InvoiceFinance/InvoiceFinance";
import { Axios } from "axios";
import { ICustomerFinancingRequest } from "../../../../utils/FinancingRequest/FinancingRequestsQuery";
import {IInvoiceDetailsSeller} from "../../invoiceDetailsQuery";
import {IInvoiceBuyerCompany} from "../../../../utils/IInvoice";
import { dateDiffDays, serverDateDeserialization } from "factor-lib/utils/dateUtils";

const InvoiceCustomerFinancingRequestActions = (
    {
        axios,
        customerFinancingRequest,
        seller,
        buyerCompany,
        acceptedCreationDateTimeO,
        rejectedDateTimeO
    }: {
        axios: Axios;
        customerFinancingRequest: ICustomerFinancingRequest;
        seller: IInvoiceDetailsSeller;
        buyerCompany: IInvoiceBuyerCompany;
        acceptedCreationDateTimeO: string | null;
        rejectedDateTimeO: string | null;
    }
) =>
    <div className='p-horizontal-center'>
        { dateDiffDays(new Date(), serverDateDeserialization(customerFinancingRequest.expirationDate)) >= 0 &&
            <>
                { !rejectedDateTimeO &&
                    <>
                        { !!acceptedCreationDateTimeO
                            ? <InvoiceFinance axios={axios}
                                              customerFinancingRequest={{
                                                  ...customerFinancingRequest,
                                                  accepted: {
                                                      creationDateTime: acceptedCreationDateTimeO
                                                  }
                                              }}
                                              seller={seller}
                                              buyerCompany={buyerCompany} />
                            : <InvoiceAcceptCustomerFinancingRequest axios={axios}
                                                                     customerFinancingRequest={customerFinancingRequest} />
                        }
            
                        <InvoiceRejectCustomerFinancingRequest axios={axios}
                                                               className='p-margin-left-6'
                                                               invoiceId={customerFinancingRequest.invoice.id}
                                                               customerFinancingRequestId={customerFinancingRequest.id} />
                    </>
                }

                { (!!acceptedCreationDateTimeO || !!rejectedDateTimeO) &&
                    <InvoiceResetCustomerFinancingRequest axios={axios}
                                                          className='p-margin-left-6'
                                                          invoiceId={customerFinancingRequest.invoice.id}
                                                          customerFinancingRequest={customerFinancingRequest}
                                                          isAccepted={!!acceptedCreationDateTimeO} />
                }
            </>
        }

        { !acceptedCreationDateTimeO && !rejectedDateTimeO && 
            <InvoiceDeleteCustomerFinancingRequest axios={axios}
                                                   className='p-margin-left-6'
                                                   invoiceId={customerFinancingRequest.invoice.id}
                                                   customerFinancingRequestId={customerFinancingRequest.id} />
        }
    </div>;


export default InvoiceCustomerFinancingRequestActions;
