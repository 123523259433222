import { ReactElement } from "react";
import { Navigate, useOutletContext, useParams } from "react-router-dom";
import CustomerDetailsPage from "./CustomerPage";
import { ICustomerDetails } from "./CustomerInfos/customerDetailsQuery";
import CustomerTabs from "./CustomerTabs";
import IAccessToken from "../../IAccessToken";
import { customerUrl, CustomerUrlCustomerIdParam, CustomerUrlMapping } from "./customersUrls";
import IRouteDefinitionWithAxiosAndRolesO from "../../routerBuilder/IRouteDefinitionWithAxiosAndRolesO";

const WithCustomerIdParamWrapper = ({child}: {child: (customerId: string) => ReactElement}): ReactElement =>
    child(useParams()[CustomerUrlCustomerIdParam]!);

const WithCustomerDetailsContext = (
    {
        child
    }: {
        child: (customerDetails: ICustomerDetails) => ReactElement | null;
    }
) => {
    const {customerDetails} = useOutletContext<{customerDetails: ICustomerDetails}>();
    return child(customerDetails);
}

const CustomerRoute: IRouteDefinitionWithAxiosAndRolesO = (
    {
        path: CustomerUrlMapping,
        titleO: 'Customer',
        component: (accessTokenO) =>
            <WithCustomerIdParamWrapper child={(customerId) =>
                <CustomerDetailsPage accessTokenO={accessTokenO}
                                     customerId={customerId} />
            }/>,
        children: [
            // Default redirect
            {
                path: CustomerUrlMapping,
                component: () =>
                    <WithCustomerIdParamWrapper child={(customerId) =>
                        <Navigate to={`${customerUrl(customerId)}`}/>
                    }/>
            },
            ...CustomerTabs.map((p) => ({
                path: p.path,
                component: (accessTokenO: IAccessToken | null) =>
                    <WithCustomerIdParamWrapper child={(customerId) =>
                        <WithCustomerDetailsContext child={(customerDetails: ICustomerDetails) =>
                            p.component(accessTokenO, customerId, customerDetails)
                        }/>
                    }/>
            }))
        ]
    }
);

export default CustomerRoute;