import { KIND_OUTLINED, SIZE_COMPACT } from "factor-lib/Buttons/Button";
import IAuthUser from "../../../IAuthUser";
import { formatAdminDateStr } from "../../../utils/dateTimeUtils";
import SingleGraphQLFileVisualizer from "../../../utils/SingleGraphQLFileVisualizer";
import { IInvoiceAdditionalDocument } from "../invoiceDetailsQuery";
import AddInvoiceAdditionalDocument, { documentTypeFriendlyNames } from "./InvoiceAdditionalDocuments/AddInvoiceAdditionalDocument";
import {Axios} from "axios";
import IUrlOnly, { UrlGraphQLField } from "factor-lib/forms/Upload/IUrlOnly";
import { comparingRev } from "factor-lib/utils/comparingUtils";
import { comparingDate, serverDateDeserialization } from "factor-lib/utils/dateUtils";

const compareInvoiceAdditionalDocumentDate = comparingRev(
    comparingDate((iad: IInvoiceAdditionalDocument) => serverDateDeserialization(iad.givenDate ?? iad.creationDateTime))
);

interface IInvoiceAdditionalDocumentQueryResult {
    invoiceAdditionalDocument: {
        blobFile: IUrlOnly;
    }
}

const InvoiceAdditionalDocumentsSection = (
    {
        axios,
        authUserO,
        invoiceId,
        invoiceAdditionalDocuments,
    }: {
        axios: Axios;
        authUserO: IAuthUser | null;
        invoiceId: string;
        invoiceAdditionalDocuments: IInvoiceAdditionalDocument[];
    }
) => {
    const invoiceAdditionalDocumentsSorted = invoiceAdditionalDocuments.sort(compareInvoiceAdditionalDocumentDate);

    return (
        <div className='p-full-width' style={{overflowX: 'auto'}}>
            { invoiceAdditionalDocuments.length === 0
                ? !!authUserO?.canManageFinancings
                    ? <AddInvoiceAdditionalDocument axios={axios}
                                                    invoiceId={invoiceId} />
                    : <span>Aucun document supplémentaire pour cette facture</span>
                : <table className='table is-narrow'>
                    <tbody>
                        <tr>
                            <td className='p-bold'>Date</td>
                            { invoiceAdditionalDocumentsSorted.map((iad, i) =>
                                <td key={i}>
                                    <span className='p-vertical-center'>{ formatAdminDateStr(iad.givenDate ?? iad.creationDateTime) }</span>
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td className='p-bold'>Type</td>
                            { invoiceAdditionalDocumentsSorted.map((iad, i) =>
                                <td key={i}>
                                    <span className='p-vertical-center'>{ documentTypeFriendlyNames.get(iad.type) ?? '<<unknown>>' }</span>
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td className='p-bold'>Titre</td>
                            { invoiceAdditionalDocumentsSorted.map((iad, i) =>
                                <td key={i} style={{width: '180px'}}>
                                    <span className='p-vertical-center'>{ iad.title || '<<none>>' }</span>
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td/>
                            { invoiceAdditionalDocumentsSorted.map((iad, i) =>
                                <td key={i}>
                                    <div className='p-both-center'>
                                        <SingleGraphQLFileVisualizer axios={axios}
                                                                     fetchId={`fetchInvoiceAdditionalDocumentFile-${iad.id}`}
                                                                     fetchText='Fetch'
                                                                     queryKey={[`invoice-additional-document-file-${iad.id}`]}
                                                                     queryParams={{
                                                                         query: `query Q($invoiceAdditionalDocumentId: Guid!) {
                                                                             invoiceAdditionalDocument (id: $invoiceAdditionalDocumentId) {
                                                                                 blobFile { ${UrlGraphQLField} }
                                                                             }
                                                                         }`,
                                                                         variables: {
                                                                             invoiceAdditionalDocumentId: iad.id
                                                                         }
                                                                     }}
                                                                     visualizerId={`viewInvoiceAdditionalDocumentFile-${iad.id}`}
                                                                     visualizerText='View'
                                                                     buttonKind={KIND_OUTLINED}
                                                                     buttonSize={SIZE_COMPACT}
                                                                     existingFileName={iad.blobFile.fileName}
                                                                     urlExtractor={(s: IInvoiceAdditionalDocumentQueryResult) => s.invoiceAdditionalDocument.blobFile.url} />
                                    </div>
                                </td>
                            )}
                            { !!authUserO?.canManageFinancings &&
                                <td>
                                    <AddInvoiceAdditionalDocument axios={axios}
                                                                  invoiceId={invoiceId} />
                                </td>
                            }
                        </tr>
                    </tbody>
                </table>
            }
        </div>
    );
}

export default InvoiceAdditionalDocumentsSection;
