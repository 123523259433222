import Loader from "factor-lib/Loader";
import IAccessToken from "../../IAccessToken";
import IAuthUserLoad from "../../IAuthUserLoad";
import {
    IArchivedPreInvoiceEmail,
    IPendingPreInvoiceEmail,
    IPreInvoiceEmail,
    IPreInvoiceEmailRecipient,
    IPreInvoices,
    IPreInvoiceWithEmailRecipients,
    IPreInvoicesQueryResult,
    preInvoicesQueryKey
} from "./preInvoicesQuery";
import PreInvoicesTableLoaded, { IPreInvoiceWithStatus } from "./PreInvoicesTableLoaded";
import GraphQLWithAccessTokenWrapper from "../../GraphQLQueryWithAccessTokenWrapper";

const buildPreInvoiceEmailRecipientsMap = (
    preInvoiceEmailRecipients: ReadonlyArray<IPreInvoiceEmailRecipient>
): Map<string, Array<string>> => {
    const result = new Map<string, Array<string>>();
    preInvoiceEmailRecipients.forEach((e) => {
        const preInvoiceEmailId = e.preInvoiceEmailId;
        const email = e.email;
        const preInvoiceEmailExistingResult = result.get(preInvoiceEmailId);
        if (preInvoiceEmailExistingResult !== undefined) {
            preInvoiceEmailExistingResult.push(email);
        } else {
            result.set(preInvoiceEmailId, [email]);
        }
    });
    return result;
};

const getPreInvoicesWithEmailRecipients = <E extends IPreInvoiceEmail>(
    preInvoices: IPreInvoices<E>
): Array<IPreInvoiceWithEmailRecipients<E>> => {
    const preInvoiceEmailRecipientsMap = buildPreInvoiceEmailRecipientsMap(preInvoices.recipientEmails);

    return preInvoices.base.map((p) => ({
        preInvoice: p,
        preInvoiceEmailRecipients: preInvoiceEmailRecipientsMap.get(p.email.id) ?? []
    }));
};

const mergePreInvoices = (
    preInvoicesPending: Array<IPreInvoiceWithEmailRecipients<IPendingPreInvoiceEmail>>,
    preInvoicesArchived: Array<IPreInvoiceWithEmailRecipients<IArchivedPreInvoiceEmail>> | null
): Array<IPreInvoiceWithStatus> => {
    const pendingPreInvoices: Array<IPreInvoiceWithStatus> =
        preInvoicesPending.map((p) => ({
            preInvoice: p,
            statusComponent: <PendingPreInvoiceStatus pendingPreInvoiceEmail={p} />
        }));

    return !!preInvoicesArchived
        ? pendingPreInvoices.concat(
            preInvoicesArchived.map((p) => ({
                preInvoice: p,
                statusComponent: <td>{ p.preInvoice.status }</td>
            }))
        )
        : pendingPreInvoices;
};

const PendingPreInvoiceStatus = (
    {
        pendingPreInvoiceEmail
    }: {
        pendingPreInvoiceEmail: IPreInvoiceWithEmailRecipients<IPendingPreInvoiceEmail>;
    }
) =>
    !pendingPreInvoiceEmail.preInvoice.email.matchingSellerId
        ? <td style={{backgroundColor: 'lightcoral'}}>Seller inconnu</td>
        : !pendingPreInvoiceEmail.preInvoice.email.matchingBuyerEmailId
            ? pendingPreInvoiceEmail.preInvoiceEmailRecipients.length === 0
                ? <td style={{backgroundColor: 'lightsalmon'}}>Buyer inconnu</td>
                : <td style={{backgroundColor: 'lightsalmon'}}>Nouveau buyer</td>
            : <td style={{backgroundColor: 'lightgreen'}}>Prêt à traiter</td>;

const PreInvoicesTable = (
    {
        accessTokenO,
        pendingPreInvoiceFilter,
        archivedPreInvoiceFilter
    }: {
        accessTokenO : IAccessToken | null;
        pendingPreInvoiceFilter: (preInvoice: IPreInvoiceWithEmailRecipients<IPendingPreInvoiceEmail>) => boolean;
        archivedPreInvoiceFilter: ((preInvoice: IPreInvoiceWithEmailRecipients<IArchivedPreInvoiceEmail>) => boolean) | null;
    }
) =>
    // the overflow should not be needed
    <div className='p-full-width p-margin-5' style={{overflowX: 'auto'}}>
        <table className='table is-narrow is-striped is-bordered is-hoverable is-fullwidth'>
            <thead>
                <tr>
                    <th>Date email</th>
                    <th>Seller</th>
                    <th>Buyer</th>
                    <th>Mindee N° de facture</th>
                    <th>Mindee montant TTC</th>
                    <th>Nb d'emails du thread</th>
                    <th>Titre email</th>
                    <th>Lien</th>
                    <th>Status</th>
                    <th>Ouvrir/archiver</th>
                </tr>
            </thead>
            <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                           queryKey={preInvoicesQueryKey(!!archivedPreInvoiceFilter)}
                                           queryParams={{
                                               query: `query {
                                                    preInvoicesPending: preInvoices(isArchived: false) {
                                                        base {
                                                            id
                                                            creationDateTime
                                                            amountWoTax
                                                            amountTax
                                                            invoiceNumber
                                                            status
                                                            email {
                                                                id
                                                                sentDateTime
                                                                thread { link length }
                                                                title
                                                                senderEmail
                                                                matchingSellerId
                                                                matchingBuyerEmailId
                                                            }
                                                        }
                                                        recipientEmails {
                                                            preInvoiceEmailId
                                                            email
                                                        }
                                                    }
                                                    ${(!!archivedPreInvoiceFilter && `
                                                    preInvoicesArchived: preInvoices(isArchived: true) {
                                                        base {
                                                            id
                                                            creationDateTime
                                                            amountWoTax
                                                            amountTax
                                                            invoiceNumber
                                                            status
                                                            email {
                                                                id
                                                                sentDateTime
                                                                thread { link length }
                                                                title
                                                                senderEmail
                                                            }
                                                        }
                                                        recipientEmails {
                                                            preInvoiceEmailId
                                                            email
                                                        }
                                                    }
                                                    `) || ''}
                                               }`
                                           }}
                                           onLoading={() =>
                                               <tbody>
                                                   <tr>
                                                       <td/>
                                                       <td/>
                                                       <td/>
                                                       <td/>
                                                       <td><Loader /></td>
                                                       <td/>
                                                       <td/>
                                                       <td/>
                                                       <td/>
                                                       <td/>
                                                   </tr>
                                               </tbody>
                                           }
                                           onSuccess={(r: IAuthUserLoad<IPreInvoicesQueryResult>) =>
                                               <PreInvoicesTableLoaded preInvoices={mergePreInvoices(
                                                                           getPreInvoicesWithEmailRecipients(r.value.preInvoicesPending).filter(pendingPreInvoiceFilter),
                                                                           !!archivedPreInvoiceFilter ? getPreInvoicesWithEmailRecipients(r.value.preInvoicesArchived!).filter(archivedPreInvoiceFilter) : null
                                                                       )} />
                                           } />
        </table>
    </div>;

export default PreInvoicesTable;
