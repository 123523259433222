import { useState } from "react";
import { Axios } from "axios";
import BuyerLimitsBatchUpdateLoaded, { ICompanyIdentifierWithCurrentLimitAndName } from "./BuyerLimitsBatchUpdateLoaded";
import { IIntCompanyIdentifier, IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import GraphQLQueryWrapper from "../../utils/GraphQLQueryWrapper";
import {allBuyersWithLimitQueryKey} from "./BuyerLimitsQuery";
import Loader from "factor-lib/Loader";

const BuyerLimitsUploadModalContent = (
    {
        axiosO,
        closeModal,
        setNonUpdatedCompanies,
        displayErrors
    } : {
        axiosO: Axios | null;
        closeModal: () => void;
        setNonUpdatedCompanies: (companies: ICompanyIdentifierWithCurrentLimitAndName[]) => void;
        displayErrors: (invalidIdentifiers: IIntCompanyIdentifier[]) => void;
    }
) => {

    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [readerResult, setReaderResult] = useState<string | null>(null);

    const reader = new FileReader();
    reader.addEventListener("load", async () => {
        setReaderResult(reader.result as string);
    });

    return (
        <div className='container p-both-center p-padding-4'>
            <div>
                <div className='title p-vertical-center'>
                    { !!uploadedFile ? 'Uploaded' : 'Upload du fichier' }
                </div>
                { !uploadedFile &&
                    <div style={{width: 600}}>
                        <div className='p-both-center p-margin-top-3'>
                            <input className='control'
                                   id='filePicker'
                                   type='file'
                                   onChange={(event) => {
                                       const files = event.target.files;
                                       if (!!files && files.length > 0) {
                                           setUploadedFile(files[0]);
                                           reader.readAsText(files[0]);
                                       }
                                   }}
                                   accept='text/csv' />
                        </div>
                    </div>
                }

                { !!axiosO
                    // We cannot reuse the limits from parent : we don't have all the companies limits
                    // We need to fetch all the limits to compute the diff.
                    ? <GraphQLQueryWrapper queryKey={allBuyersWithLimitQueryKey}
                                           queryParams={{
                                               query: `query { companies (hasOutstandingLimitsAsBuyer: true) { 
                                                               identifier { ${ IntCompanyIdentifierGraphQLFields } }
                                                               asBuyer {
                                                                   manualOutstandingLimit
                                                               }
                                                           } }`,
                                           }}
                                           axios={axiosO}
                                           onLoading={() => !!readerResult
                                               ? <Loader/> // We want to compute the diff, we're waiting got the load
                                               : <div/> // We don't want yet to compute the diff : loader is useless
                                           }
                                           onSuccess={((r: {
                                                   companies: Array<{
                                                       identifier: IIntCompanyIdentifier;
                                                       asBuyer: {
                                                           manualOutstandingLimit: number; // Not null, as per the filter on the query
                                                       }
                                                   }>
                                               }) =>
                                                   !!readerResult
                                                       ? <BuyerLimitsBatchUpdateLoaded axios={axiosO}
                                                                                       closeModal={closeModal}
                                                                                       displayErrors={displayErrors}
                                                                                       storedBuyerCompanies={r.companies.map((c) => ({
                                                                                           identifier: c.identifier,
                                                                                           manualOutstandingLimit: c.asBuyer.manualOutstandingLimit
                                                                                       }))}
                                                                                       setNonUpdatedCompanies={setNonUpdatedCompanies}
                                                                                       readerResult={readerResult} />

                                                       : <div/>
                                           )} /> : <Loader/>
                }
            </div>
        </div>
    );
}

export default BuyerLimitsUploadModalContent;