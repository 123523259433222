import {NavigateFunction} from "react-router-dom";
import IAuthUser from "../../../IAuthUser";
import MarketplaceSellersTable from "./MarketplaceSellersTable";
import Button from "factor-lib/Buttons/Button";
import {Axios} from "axios";
import {addSellerForCustomerUrl} from "../../Sellers/sellersUrls";
import {useContext} from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";

const MarketplaceSellers = (
    {
        axios,
        authUserO,
        customerId,
        marketplaceId
    }: {
        axios: Axios;
        authUserO: IAuthUser | null;
        customerId: string;
        marketplaceId: string;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    return (
        <div>
            { !!authUserO?.canManageProfiles &&
                <div className='p-margin-4'>
                    <Button id='addMarketplaceSellerButton'
                            text='Ajouter un Seller'
                            isLoading={false}
                            actionO={() => navigate(addSellerForCustomerUrl(customerId))} />
                </div>
            }

            <MarketplaceSellersTable axios={axios}
                                     marketplaceId={marketplaceId} />
        </div>
    );
}

export default MarketplaceSellers;
