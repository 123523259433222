export const CompaniesUrl = '/companies';

export const CompanyUrlCompanyIdParam = 'companyId';
export const CompanyUrlMapping = `${CompaniesUrl}/:${CompanyUrlCompanyIdParam}`;

export const CompanyInfosTabParam = 'infos';
export const companyUrl = (companyId: string) => companyUrlTab(companyId, CompanyInfosTabParam);
export const companyUrlTab = (companyId: string, tab: string) => `${CompaniesUrl}/${companyId}/${tab}`;

export const AddCompanyUrl = `${CompaniesUrl}/add`;
