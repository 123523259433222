import { Axios } from "axios";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { buyerCompanyDetailsQueryKey, IBuyerEvent, IBuyerEventContact } from "../buyerCompanyDetailsQuery";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {
    buyerEventsInvoiceMatchesKey,
    IInvoiceDetailsQueryResult, invoiceDetailsQueryKey
} from "../../../Invoice/invoiceDetailsQuery";
import { buyerEventContactsQueryKey, getUpdateBuyerEventContactParams } from "./BuyerEventTypeAndContact";
import { ResultOrErrors } from "../../../../utils/ResultOrErrors";
import { IBuyerEventParams } from "./BuyerEventModal";
import { arraysEqual } from "../../../../utils/arrayUtils";


export interface IUpdateBuyerEventParams {
    eventType: number | null;
    eventDateTime: string | null;
    eventNotes: string | null;
    emailId: string | null;
    phoneId: string | null;
    invoiceIds: string[] | null;
}

// null -> no update
export const getUpdateBuyerEventParams = (
    existingBuyerEvent: IBuyerEvent,
    initialSelectedInvoicesIds: string[],
    buyerEventParamsFailable: ResultOrErrors<IBuyerEventParams>
): ResultOrErrors<IUpdateBuyerEventParams> | null => {

    if (buyerEventParamsFailable.errors !== null) {
        return ResultOrErrors.fromError(buyerEventParamsFailable.errors);
    }

    const buyerEventParams = buyerEventParamsFailable.result!;

    const eventTypeUpdate: number | null = existingBuyerEvent.type === buyerEventParams.eventType ? null : buyerEventParams.eventType;
    const eventDateTimeUpdate: string | null = existingBuyerEvent.eventDateTime === buyerEventParams.eventDateTime ? null : buyerEventParams.eventDateTime;
    const eventNotesUpdate: string | null = existingBuyerEvent.notes === buyerEventParams.eventNotes ? null : buyerEventParams.eventNotes;

    const contactEmailUpdate: string | null = !!buyerEventParams.emailId
        ? getUpdateBuyerEventContactParams(existingBuyerEvent.email, buyerEventParams.emailId)
        : null;

    const contactPhoneUpdate: string | null = !!buyerEventParams.phoneId
        ? getUpdateBuyerEventContactParams(existingBuyerEvent.phone, buyerEventParams.phoneId)
        : null;

    const existingMatchedInvoicesIds = initialSelectedInvoicesIds;
    const matchedInvoicesUpdate: string[] | null = arraysEqual(existingMatchedInvoicesIds, buyerEventParams.invoiceIds, id => id) ? null : buyerEventParams.invoiceIds;

    // check something has changed
    if (eventTypeUpdate === null &&
        eventDateTimeUpdate === null &&
        eventNotesUpdate === null &&
        matchedInvoicesUpdate === null &&
        contactEmailUpdate === null &&
        contactPhoneUpdate === null
    ) {
        return null;
    }

    return ResultOrErrors.fromResult({
        eventType: eventTypeUpdate,
        eventDateTime: eventDateTimeUpdate,
        eventNotes: eventNotesUpdate ?? (existingBuyerEvent.notes === null ? '' : null),
        emailId: contactEmailUpdate,
        phoneId: contactPhoneUpdate,
        invoiceIds: matchedInvoicesUpdate
    });
};

interface IUpdateBuyerEventResponse {
    // one or the other
    email: IBuyerEventContact | null;
    phone: IBuyerEventContact | null;
}

const BuyerEventUpdateButtonEnabled = (
    {
        axios,
        existingBuyerEvent,
        params,
        selectedInvoicesIds,
        previousSelectedInvoiceIds,
        buyerCompanyId,
        closeModal,
        kind,
        size,
        text
    } : {
        axios: Axios;
        existingBuyerEvent: IBuyerEvent;
        params: IUpdateBuyerEventParams;
        selectedInvoicesIds: string[];
        previousSelectedInvoiceIds: string[];
        buyerCompanyId: string;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateBuyerEventMutation: UseMutationResult<IUpdateBuyerEventResponse, any, IUpdateBuyerEventParams> =
        useMutation<IUpdateBuyerEventResponse, any, IUpdateBuyerEventParams>(
            async (params2) =>
                (await axios.put<IUpdateBuyerEventResponse>(
                    `/adminBuyerEvents/${existingBuyerEvent.id}`,
                    params2
                )).data,
            ({
                onSuccess: (response, params2) => {
                    const event: IBuyerEvent = ({
                        ...existingBuyerEvent,
                        type: params2.eventType ?? existingBuyerEvent.type,
                        eventDateTime: params2.eventDateTime ?? existingBuyerEvent.eventDateTime,
                        notes: params2.eventNotes ?? existingBuyerEvent.notes,
                        email: response.email,
                        phone: response.phone,
                    });

                    // Too complicated to update here
                    const r = Promise.all([
                        queryClient.invalidateQueries(buyerCompanyDetailsQueryKey(buyerCompanyId)),
                        queryClient.invalidateQueries(buyerEventsInvoiceMatchesKey(buyerCompanyId)),
                        queryClient.invalidateQueries(buyerEventContactsQueryKey(buyerCompanyId))
                    ]);

                    const previousSelectedInvoiceIdsSet = new Set<string>(previousSelectedInvoiceIds);
                    selectedInvoicesIds.forEach((selectedInvoicesId) =>
                        queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                            invoiceDetailsQueryKey(selectedInvoicesId),
                            (old: IInvoiceDetailsQueryResult | undefined) =>
                                !!old ? ({
                                    ...old,
                                    buyerEvents: previousSelectedInvoiceIdsSet.has(selectedInvoicesId)
                                        ? old.buyerEvents.map((e) => e.id === existingBuyerEvent.id ? event : e) // updated
                                        : [
                                            ...old.buyerEvents,
                                            event
                                        ] // added
                                }) : undefined
                        )
                    );

                    const selectedInvoicesIdsSet = new Set<string>(selectedInvoicesIds);
                    previousSelectedInvoiceIds.forEach((previousSelectedInvoiceId) => {
                        if (!selectedInvoicesIdsSet.has(previousSelectedInvoiceId)) {
                            queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                                invoiceDetailsQueryKey(previousSelectedInvoiceId),
                                (old: IInvoiceDetailsQueryResult | undefined) =>
                                    !!old ? ({
                                        ...old,
                                        buyerEvents: old!.buyerEvents.filter((e) => e.id !== existingBuyerEvent.id)
                                    }) : undefined
                            )
                        }
                    });

                    closeModal();

                    return r;
                }
            })
        );

    return (
        <ButtonMutationEnabled id='UpdateBuyerEventButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateBuyerEventMutation}
                               displayFullError={true}
                               value={params} />

    );
}

export default BuyerEventUpdateButtonEnabled;
