import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {Axios} from "axios";
import ButtonMutation from 'factor-lib/Buttons/ButtonMutation';
import IYearMonth from "../IYearMonth";
import {BillingsUrl} from "../billingUrls";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import {NavigateFunction} from "react-router-dom";
import {useContext} from "react";
import {BillingsQueryKey} from "../Billings/BillingQueries";
import {existingBillingQueryKey, IExistingBillingQueryResult} from "./PostSelectionQuery";

interface IBillingContent {
    date: Date;
    minThresholdLineItems: Array<{
        invoiceNumber: string;
        amount: number; // In cents
    }>;
    overThresholdLineItems: Array<{
        invoiceNumber: string;
        amount: number; // In cents
    }>;
}

interface ICreateNormalRequest {
    customerId: string;
    yearMonth: IYearMonth;
    billingContent: IBillingContent;
}

const NewNormalBillingButton = (
    {
        axios,
        customerId,
        yearMonth,
        billingContentO
    }: {
        axios: Axios;
        customerId: string;
        yearMonth: IYearMonth;
        billingContentO: IBillingContent | null;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    const queryClient = useQueryClient();
    const mutation: UseMutationResult<void, any, ICreateNormalRequest> =
        useMutation<void, any, ICreateNormalRequest>(
            async (p) => (await axios.post(
                `/adminBillings`,
                p
            )).data,
            ({
                onSuccess: () => {
                    const r = queryClient.invalidateQueries(BillingsQueryKey);
                    queryClient.setQueryData<IExistingBillingQueryResult>(
                        existingBillingQueryKey(customerId, yearMonth),
                        (old) => !!old ? ({
                            ...old,
                            existingBilling: {
                                ...old.existingBilling,
                                any: true
                            }
                        }) : undefined
                    );
                    navigate(BillingsUrl);
                    return r;
                }
            })
        );

    return (
        <ButtonMutation<void, ICreateNormalRequest> text='Facturation Pennylane'
                                                    mutation={mutation}
                                                    valueO={!!billingContentO ? ({
                                                        value: ({
                                                            customerId,
                                                            yearMonth,
                                                            billingContent: billingContentO
                                                        })
                                                    }) : null}
                                                    displayFullError={true} />
    );
}

export default NewNormalBillingButton;