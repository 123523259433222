import {Axios} from "axios";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { useState } from "react";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import Input from "factor-lib/forms/Inputs/Input";
import { ResultOrErrors } from "../../../../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../../../../utils/ShowValidationErrorsButton";
import { ICustomerDetailsQueryResult, customerDetailsQueryKey } from "../../customerDetailsQuery";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { ISellerDetailsQueryResult, SellerDetailsQueryKeyPrefix } from "../../../../Sellers/SellerInfos/sellerDetailsQuery";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import { isValidIban, validateIbanError } from "factor-lib/forms/Inputs/ibanUtils";

const ConfirmText = 'Confirmer';

const UpdateCustomerBillingIbanEnabled = (
    {
        axios,
        customerId,
        hasCertifiedSellers,
        newBillingIban,
        closeModal,
        kind,
        size,
        text
    } : {
        axios: Axios;
        customerId: string;
        hasCertifiedSellers: boolean;
        newBillingIban: string;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateCustomerBillingIbanMutation: UseMutationResult<void, any, string, null> =
        useMutation<void, any, string, null>(
            async (newBillingIban2) => {
                await axios.put<void>(
                    `/adminCustomers/${customerId}/billingIban${hasCertifiedSellers ? 'PostCertif' : 'PreCertif'}`,
                    {
                        billingIban: newBillingIban2
                    }
                );
            },
            ({
                onSuccess: (_, newBillingIban2) => {
                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId),
                        (old: ICustomerDetailsQueryResult | undefined) => ({
                            customerDetails: {
                                ...old!.customerDetails,
                                billingIban: newBillingIban2
                            }
                        })
                    );
                    queryClient.setQueriesData<ISellerDetailsQueryResult>(
                        SellerDetailsQueryKeyPrefix,
                        (old) => old?.sellerDetails.customer.id === customerId
                            ? ({
                                ...old,
                                sellerDetails: {
                                    ...old.sellerDetails,
                                    customer: {
                                        ...old.sellerDetails.customer,
                                        billingIban: newBillingIban2
                                    }
                                }
                            }) : old
                    );
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateCustomerBillingIbanButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateCustomerBillingIbanMutation}
                               displayFullError={true}
                               value={newBillingIban} />
    );
}

const getUpdateCustomeBillingIbanParam = (
    oldBillingIbanO: string | null,
    newBillingIbanInput: string
): ResultOrErrors<string> | null =>
    !isValidIban(newBillingIbanInput)
        ? ResultOrErrors.fromError([`L'IBAN du billing est invalide`])
        : newBillingIbanInput !== oldBillingIbanO
            ? ResultOrErrors.fromResult(newBillingIbanInput)
            : null;

const UpdateCustomerBillingIbanModalContent = (
    {
        axios,
        customerId,
        hasCertifiedSellers,
        billingIbanO,
        closeModal
    }: {
        axios: Axios;
        customerId: string;
        hasCertifiedSellers: boolean;
        billingIbanO: string | null;
        closeModal: () => void;
    }
) => {
    const [newBillingIbanInput, setNewBillingIbanInput] = useState(billingIbanO ?? '');

    const newBillingIbanFailableO: ResultOrErrors<string> | null = getUpdateCustomeBillingIbanParam(
        billingIbanO,
        newBillingIbanInput
    );

    return (
        <div className='p-padding-3'>
            <div>
                <span className='p-margin-right-4 p-bold'>IBAN du billing</span>
                <Input inputValue={newBillingIbanInput}
                       enabled={{
                           updateInputValue: setNewBillingIbanInput,
                           validateInput: () => validateIbanError(newBillingIbanInput)
                       }} />
            </div>

            <div className='p-margin-top-4 p-vertical-center'>
                { !!newBillingIbanFailableO
                    ? !!newBillingIbanFailableO.result
                        ? <UpdateCustomerBillingIbanEnabled axios={axios}
                                                            customerId={customerId}
                                                            hasCertifiedSellers={hasCertifiedSellers}
                                                            newBillingIban={newBillingIbanFailableO.result}
                                                            closeModal={closeModal}
                                                            kind={KIND_PRIMARY}
                                                            size={SIZE_FIXED}
                                                            text={ConfirmText} />
                        : <ShowValidationErrorsButton validationErrors={newBillingIbanFailableO.errors!}
                                                      kind={KIND_PRIMARY}
                                                      size={SIZE_FIXED}
                                                      text={ConfirmText} />
                    : <ButtonDisabled kind={KIND_PRIMARY}
                                      size={SIZE_FIXED}
                                      text={ConfirmText} />
                }
            </div>
        </div>
    );
}

const UpdateCustomerBillingIban = (
    {
        className,
        axios,
        customerId,
        hasCertifiedSellers,
        billingIbanO
    }: {
        className?: string;
        axios: Axios;
        customerId: string;
        hasCertifiedSellers: boolean;
        billingIbanO: string | null;
    }
) =>
    <ButtonForModal className={className}
                    id='showUpdateCustomerBillingIbanModal'
                    text='Modifier Iban billing'
                    childModalContent={(closeModal) =>
                        <UpdateCustomerBillingIbanModalContent axios={axios}
                                                        customerId={customerId}
                                                        hasCertifiedSellers={hasCertifiedSellers}
                                                        billingIbanO={billingIbanO}
                                                        closeModal={closeModal} />
    }/>;

export default UpdateCustomerBillingIban;
