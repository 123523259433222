import {
    existingBillingQuery,
    existingBillingQueryKey, IExistingBillingQueryResult,
    IPennyLaneCustomerQueryResult, pennyLaneCustomerQuery,
    pennyLaneCustomerQueryKey
} from "./PostSelectionQuery";
import NewBillingPostCustomerYearMonthSelectionLayout, {
    IValidParam
} from "./NewBillingPostCustomerYearMonthSelectionLayout";
import {Axios} from "axios";
import IYearMonth from "../IYearMonth";
import CustomerYearMonthSelectionLayout, {ICustomer} from "./CustomerYearMonthSelectionLayout";
import IOption from "factor-lib/forms/Select/IOption";
import reactQueryResultCombiner from "factor-lib/reactquery/reactQueryResultCombiner";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import ReactQueryResultWrapper from 'factor-lib/reactquery/ReactQueryResultWrapper'
import DataLoader from "dataloader";
import ILogger from "factor-lib/services/logger/ILogger";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import dataLoader from "factor-lib/dataLoader/dataLoader";
import {getFactorContext} from "../../../IFactorContext";
import graphQLBatchLoadFunction from "factor-lib/dataLoader/graphQLBatchLoadFunctionAsync";

const NewBillingPostCustomerYearMonthSelectionAxios = (
    {
        axios,
        selectedMonth,
        selectMonth,
        selectedCustomer,
        selectCustomer,
        minThresholdInput,
        setMinThresholdInput,
        dailyRateInput,
        setDailyRateInput,
        dateInput,
        setDateInput,
        validParam,
        updateValidParam
    }: {
        axios: Axios;
        selectedMonth: IOption<IYearMonth>;
        selectMonth: (newMonth: IOption<IYearMonth> | null) => void;
        selectedCustomer: IOption<ICustomer>;
        selectCustomer: (newCustomer: IOption<ICustomer> | null) => void;
        minThresholdInput: string;
        setMinThresholdInput: (n: string) => void;
        dailyRateInput: string;
        setDailyRateInput: (n: string) => void;
        dateInput: string;
        setDateInput: (n: string) => void;
        validParam: IValidParam;
        updateValidParam: (newValidParam: IValidParam) => void;
    }
) => {
    const customerId = selectedCustomer.value.id;

    const logger: ILogger = getFactorContext().logger;

    const graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any> =
        dataLoader<IGraphQLQueryWithKeyAndParams, any>(
            (graphQLQueries: ReadonlyArray<IGraphQLQueryWithKeyAndParams>) => graphQLBatchLoadFunction(axios, logger, graphQLQueries)
        );

    const pennyLaneCustomerQueryKeyValue = pennyLaneCustomerQueryKey(customerId);
    const pennyLaneCustomerQueryResult: UseQueryResult<IPennyLaneCustomerQueryResult> =
        useQuery<IPennyLaneCustomerQueryResult>(
            pennyLaneCustomerQueryKeyValue,
            async ({ signal }) => ({
                customer: (
                    await graphQLDataLoader.load({
                        query: pennyLaneCustomerQuery(customerId),
                        queryKey: pennyLaneCustomerQueryKeyValue,
                        signal
                    })
                ).customer
            })
        );

    const existingBillingQueryKeyValue = existingBillingQueryKey(customerId, selectedMonth.value);
    const existingBillingQueryResult: UseQueryResult<IExistingBillingQueryResult> =
        useQuery<IExistingBillingQueryResult>(
            existingBillingQueryKeyValue,
            async ({ signal }) => ({
                existingBilling: (await graphQLDataLoader.load({
                    query: existingBillingQuery(customerId, selectedMonth.value),
                    queryKey: existingBillingQueryKeyValue,
                    signal
                })).existingBilling
            })
        );

    const pennyLaneCustomerAndExistingBillingQueryResult = reactQueryResultCombiner<IPennyLaneCustomerQueryResult & IExistingBillingQueryResult>(
        [
            pennyLaneCustomerQueryResult,
            existingBillingQueryResult
        ],
        () => ({
            ...pennyLaneCustomerQueryResult.data!,
            ...existingBillingQueryResult.data!
        })
    );

    return (
        <ReactQueryResultWrapper<IExistingBillingQueryResult & IPennyLaneCustomerQueryResult>
                displayFullError={true}
                result={pennyLaneCustomerAndExistingBillingQueryResult}
                onLoading={() =>
                    <NewBillingPostCustomerYearMonthSelectionLayout axiosO={axios}
                                                                    selectedMonth={selectedMonth}
                                                                    selectMonth={selectMonth}
                                                                    selectedCustomer={selectedCustomer}
                                                                    selectCustomer={selectCustomer}
                                                                    pennyLaneCustomerQueryResultO={null}
                                                                    minThresholdInput={minThresholdInput}
                                                                    setMinThresholdInput={setMinThresholdInput}
                                                                    dailyRateInput={dailyRateInput}
                                                                    setDailyRateInput={setDailyRateInput}
                                                                    dateInput={dateInput}
                                                                    setDateInput={setDateInput}
                                                                    validParam={validParam}
                                                                    updateValidParam={updateValidParam}/>
                }
                onSuccess={(t: IExistingBillingQueryResult & IPennyLaneCustomerQueryResult) =>
                    t.existingBilling.any
                        ? <CustomerYearMonthSelectionLayout axiosO={axios}
                                                            selectedMonth={selectedMonth}
                                                            selectMonth={selectMonth}
                                                            selectedCustomer={selectedCustomer}
                                                            selectCustomer={selectCustomer}>
                            <div className='p-vertical-center'>
                                <div className='p-bold'>
                                    Facturation déjà effectuée
                                </div>
                            </div>
                        </CustomerYearMonthSelectionLayout>
                        : <NewBillingPostCustomerYearMonthSelectionLayout axiosO={axios}
                                                                          selectedMonth={selectedMonth}
                                                                          selectMonth={selectMonth}
                                                                          selectedCustomer={selectedCustomer}
                                                                          selectCustomer={selectCustomer}
                                                                          pennyLaneCustomerQueryResultO={t}
                                                                          minThresholdInput={minThresholdInput}
                                                                          setMinThresholdInput={setMinThresholdInput}
                                                                          dailyRateInput={dailyRateInput}
                                                                          setDailyRateInput={setDailyRateInput}
                                                                          dateInput={dateInput}
                                                                          setDateInput={setDateInput}
                                                                          validParam={validParam}
                                                                          updateValidParam={updateValidParam} />
                } />
    );
}

export default NewBillingPostCustomerYearMonthSelectionAxios;