import Loader from "factor-lib/Loader";
import { ReactElement } from "react";
import IAuthUserLoad from "../../../IAuthUserLoad";
import { ICompanyDetails } from "./companyDetailsQuery";
import CompanyMainInfosSection from "./CompanyMainInfosSection";

const Section = (
    {
        title,
        children
    }: {
        title: string;
        children: ReactElement;
    }
) =>
    <section className='box'>
        <div className='title'>{ title }</div>
        { children }
    </section>;

const CompanyInfosTemplate = (
    {
        companyId,
        loaded
    }: {
        companyId: string;
        loaded: IAuthUserLoad<ICompanyDetails> | null
    }
) =>
    <div>
        <Section title='Informations Générales'>
            { !!loaded
                ? <CompanyMainInfosSection axios={loaded.axios}
                                           authUserO={loaded.authUserO}
                                           companyId={companyId}
                                           companyDetails={loaded.value} />
                : <Loader />
            }
        </Section>
    </div>;

export default CompanyInfosTemplate;
