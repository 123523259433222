import { QueryClient, useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Axios } from "axios";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import Loader from "factor-lib/Loader";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import {useState} from "react";
import { DimplOpsGraphQLFields, IDimplOps } from "./IDimplOps";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";


const UpdateAccountManagerButtonEnabled = (
    {
        axios,
        endpoint,
        dimplOpsAccountManager,
        mutationUpdate
    } : {
        axios: Axios;
        endpoint: string;
        dimplOpsAccountManager: IDimplOps;
        mutationUpdate: (queryClient: QueryClient, accountManager: IDimplOps) => void;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateDimplOpsAccountManagerMutation: UseMutationResult<void, any, IDimplOps> =
        useMutation<void, any, IDimplOps>(
            async (dimplOpsAccountManager2) => {
                await axios.put<void>(
                    endpoint,
                    {
                        accountManagerId: dimplOpsAccountManager2.azureObjectId
                    }
                );
            },
            ({
                onSuccess: (_, dimplOpsAccountManager2) => {
                    mutationUpdate(queryClient, dimplOpsAccountManager2);
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateDimplOpsAccountManagerMutation'
                               kind={UpdateButtonKind}
                               size={UpdateButtonSize}
                               text={UpdateButtonText}
                               mutation={updateDimplOpsAccountManagerMutation}
                               displayFullError={true}
                               value={dimplOpsAccountManager} />
    );
}

export const DimplOpsAccountManagersQueryKey =
    ['dimplOpsAccountManagers'];

const UpdateButtonText = 'Modifier';
const UpdateButtonKind = KIND_PRIMARY;
const UpdateButtonSize = SIZE_FIXED;

export const AccountsManagersQuery: IGraphQLParams = {
    query: `{                                               
          accountManagers: dimplers (isAccountManager: true) { 
               ${DimplOpsGraphQLFields} 
          }
      }`
};

export interface IAccountsManagersResult {
    accountManagers: IDimplOps[];
}

const AccountManagerCanUpdate = (
    {
        axios,
        accountManagerLoadedO,
        queryResult,
        endpoint,
        mutationUpdate
    }: {
        axios: Axios;
        accountManagerLoadedO: IDimplOps | null;
        queryResult: {
            isLoading: boolean;
            isError: boolean;
            data: IDimplOps[] | undefined;
            error: unknown;
        };
        endpoint: string;
        mutationUpdate: (queryClient: QueryClient, accountManager: IDimplOps) => void;
    }
) => {

    const [ selectedAccountManager, setSelectedAccountManager ] = useState<IDimplOps | null>(accountManagerLoadedO);

    return (
        <div className='p-horizontal-center'>
            <ReactQueryResultWrapper onLoading={() => <Loader />}
                                     result={queryResult}
                                     onSuccess={(r: IDimplOps[]) => {
                                         const options = r.map((d) => ({
                                             label: d.name,
                                             value: d.azureObjectId
                                         }));
                                         return (
                                            <SingleSelect options={options}
                                                          selectedOption={!!selectedAccountManager
                                                              ? options.find((o) => o.value === selectedAccountManager.azureObjectId)!
                                                              : null
                                                          }
                                                          selectOption={(newValue) => !!newValue
                                                              ? setSelectedAccountManager(r.find((d) => d.azureObjectId === newValue.value)!)
                                                              : null
                                                          } />
                                         );
                                     }}
                                     displayFullError={true} />

            <div className='p-margin-left-5'>
                { !!selectedAccountManager && !!accountManagerLoadedO && selectedAccountManager.azureObjectId !== accountManagerLoadedO.azureObjectId
                    ? <UpdateAccountManagerButtonEnabled axios={axios}
                                                         dimplOpsAccountManager={selectedAccountManager}
                                                         endpoint={endpoint}
                                                         mutationUpdate={mutationUpdate} />
                    : <ButtonDisabled text={UpdateButtonText}
                                      kind={UpdateButtonKind}
                                      size={UpdateButtonSize} />
                }
            </div>
        </div>
    );
}

const AccountManager = (
    {
        className,
        accountManagerLoadedO,
        canUpdate
    }: {
        className?: string;
        accountManagerLoadedO: IDimplOps | null; // null -> loaded, cannot be no set
        canUpdate: {
            axios: Axios;
            endpoint: string;
            mutationUpdate: (queryClient: QueryClient, accountManager: IDimplOps) => void;
            queryResult: {
                isLoading: boolean;
                isError: boolean;
                data: IDimplOps[] | undefined;
                error: unknown;
            };
        } | null;
    }
) =>
    <div className={`p-horizontal-center${!!className ? ` ${className}` : ''}`}>
        <div className='p-bold'>
            Account Manager
        </div>

        <div className='p-margin-left-5' style={{minWidth: 175}}>
            { !!canUpdate
                ? <AccountManagerCanUpdate axios={canUpdate.axios}
                                           accountManagerLoadedO={accountManagerLoadedO}
                                           endpoint={canUpdate.endpoint}
                                           mutationUpdate={canUpdate.mutationUpdate}
                                           queryResult={canUpdate.queryResult}/>
                : <span>{ accountManagerLoadedO?.name ?? '...' }</span>
            }
        </div>
    </div>;

export default AccountManager;
