import Loader from "factor-lib/Loader";
import InvoiceSellerInfosSection from "./InvoiceLoaded/InvoiceSellerInfosSection";
import InvoiceBuyerInfosSection from "./InvoiceLoaded/InvoiceBuyerInfosSection";
import InvoiceActionsSection from "./InvoiceLoaded/InvoiceActionsSection";
import {IInvoiceDetails, IInvoiceDetailsQueryResult} from "./invoiceDetailsQuery";
import InvoiceActivitiesSection from "./InvoiceLoaded/InvoiceActivitiesSection";
import InvoiceOpsInfosSection from "./InvoiceLoaded/InvoiceOpsInfosSection";
import InvoiceBaseInfosSection from "./InvoiceLoaded/InvoiceBaseInfosSection";
import {ReactNode} from "react";
import InvoiceEmailsSection from "./InvoiceLoaded/InvoiceEmailsSection";
import InvoiceEventsSection from "./InvoiceLoaded/InvoiceEventsSection";
import InvoiceAdditionalDocumentsSection from "./InvoiceLoaded/InvoiceAdditionalDocumentsSection";
import InvoiceDeleteButtonEnabled from "./InvoiceLoaded/InvoiceDeleteButtonEnabled";
import { serverDateDeserialization } from "factor-lib/utils/dateUtils";
import DataLoader from "dataloader";
import graphQLBatchLoadFunction from "factor-lib/dataLoader/graphQLBatchLoadFunctionAsync";
import ILogger from "factor-lib/services/logger/ILogger";
import dataLoader from "factor-lib/dataLoader/dataLoader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import InvoiceAccountManager from "./InvoiceLoaded/InvoiceOpsInfos/InvoiceAccountManager";
import {Axios} from "axios";
import IAuthUser from "../../IAuthUser";
import {getFactorContext} from "../../IFactorContext";

const Section = (
    {
        title,
        children
    }: {
        title: string;
        children: ReactNode | null; // null -> Loading
    }
) =>
    <div className='box'>
        <div className='title'>{ title }</div>
        { !!children
            ? children
            : <Loader />
        }
    </div>;

export interface IAxiosLoaded {
    axios: Axios;
    graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
    authUser: IAuthUser; // We wait for this one to be ready to batch the queries to load the account managers ()
    r: IInvoiceDetailsQueryResult | null;
}

const InvoiceDetailsTemplate = (
    {
        invoiceId,
        al
    }: {
        invoiceId: string;
        al: IAxiosLoaded | null;
    }
) => {

    const logger: ILogger = getFactorContext().logger;

    // DS apart, as expected to be slower
    const dsGraphQLDataLoaderO: (DataLoader<IGraphQLQueryWithKeyAndParams, any>) | null =
        !!al
            ? dataLoader<IGraphQLQueryWithKeyAndParams, any>(
                (graphQLQueries: ReadonlyArray<IGraphQLQueryWithKeyAndParams>) => graphQLBatchLoadFunction(al.axios, logger, graphQLQueries)
            )
            : null;

    const invoiceDetails: IInvoiceDetails | null = !!al?.r ? al.r.invoiceDetails : null;

    return (
        <div>
            <div className='container p-padding-4'>
                <Section title='Activities Log'>
                    { !!invoiceDetails &&
                        <InvoiceActivitiesSection creationDateTime={invoiceDetails.creationDateTime}
                                                  customerFinancingRequest={invoiceDetails.status.customerFinancingRequest}
                                                  payments={invoiceDetails.payment.payments} />
                    }
                </Section>
                <Section title='Invoice Infos'>
                    { !!al && !!invoiceDetails &&
                        <InvoiceBaseInfosSection axios={al.axios}
                                                 invoiceId={invoiceId}
                                                 invoiceFileName={invoiceDetails.file.fileName}
                                                 buyerCompany={invoiceDetails.buyer.company}
                                                 buyerEmails={invoiceDetails.buyer.emails}
                                                 buyerPhones={invoiceDetails.buyer.phones}
                                                 number={invoiceDetails.number}
                                                 issueDate={serverDateDeserialization(invoiceDetails.issueDate)}
                                                 dueDate={serverDateDeserialization(invoiceDetails.dueDate)}
                                                 amountWoTax={invoiceDetails.amountWoTax}
                                                 amountTax={invoiceDetails.amountTax}
                                                 mindee={invoiceDetails.file.mindee}
                                                 completePaidDate={invoiceDetails.payment.completePaidDate}
                                                 partialAmountPaid={invoiceDetails.payment.partialAmountPaid}
                                                 customerFinancingRequest={invoiceDetails.status.customerFinancingRequest} />
                    }
                </Section>

                <Section title='Invoice Additional Documents'>
                    { !!invoiceDetails && !!al &&
                        <InvoiceAdditionalDocumentsSection axios={al.axios}
                                                           authUserO={al.authUser}
                                                           invoiceId={invoiceId}
                                                           invoiceAdditionalDocuments={invoiceDetails.additionalDocuments} />
                    }
                </Section>

                <Section title='Seller Infos'>
                    { !!invoiceDetails && !!al && !!dsGraphQLDataLoaderO &&
                        <InvoiceSellerInfosSection dsGraphQLDataLoader={dsGraphQLDataLoaderO}
                                                   sellerId={invoiceDetails.seller.id}
                                                   company={invoiceDetails.seller.company}
                                                   email={invoiceDetails.seller.email}
                                                   phone={invoiceDetails.seller.phone} />
                    }
                </Section>

                <Section title='Buyer Infos'>
                    { !!invoiceDetails && !!al && !!dsGraphQLDataLoaderO &&
                        <InvoiceBuyerInfosSection dsGraphQLDataLoader={dsGraphQLDataLoaderO}
                                                  company={invoiceDetails.buyer.company}
                                                  emails={invoiceDetails.buyer.emails}
                                                  phones={invoiceDetails.buyer.phones} />

                    }
                </Section>

                <Section title='Invoice Emails'>
                    { !!invoiceDetails && !!al &&
                        <InvoiceEmailsSection axios={al.axios}
                                              financingId={invoiceDetails.status.customerFinancingRequest?.accepted?.financing?.id ?? null} />
                    }
                </Section>

                <Section title='Invoice Events'>
                    { !!al && !!al.r && !!invoiceDetails &&
                        !!invoiceDetails.status.customerFinancingRequest
                            ? <InvoiceEventsSection axios={al.axios}
                                                    authUser={al.authUser}
                                                    invoiceId={invoiceId}
                                                    invoiceBuyerEvents={al.r.buyerEvents}
                                                    financingMerciFacteurMailsO={invoiceDetails.status.customerFinancingRequest.accepted?.financing?.merciFacteurMails || null}
                                                    buyerCompanyId={invoiceDetails.buyer.company.id} />
                            : <span>Financement pas encore demandé</span>
                    }
                </Section>

                <Section title='Invoice Actions'>
                    { !!invoiceDetails && !!al && (
                        al.authUser.canManageFinancings
                            ? <InvoiceActionsSection authUser={al.authUser}
                                                     axios={al.axios}
                                                     invoice={invoiceDetails}
                                                     seller={invoiceDetails.seller}
                                                     sellerIsBlocked={invoiceDetails.seller.isBlocked}
                                                     sellerCertif={invoiceDetails.seller.certif}
                                                     buyerCompany={invoiceDetails.buyer.company}
                                                     invoiceStatus={invoiceDetails.status}
                                                     invoicePaymentStatus={invoiceDetails.payment}
                                                     invoiceIsBlocked={invoiceDetails.blockedByAdmin} />
                            : <span>Vous n'avez pas de droits pour gérer les financements</span>
                    )}
                </Section>

                <Section title='Ops Infos'>
                    { !!al &&
                        <div>
                            <InvoiceAccountManager axios={al.axios}
                                                   graphQLDataLoader={al.graphQLDataLoader}
                                                   authUser={al.authUser}
                                                   invoiceId={invoiceId}
                                                   accountManagerLoadedO={invoiceDetails?.effectiveAccountManager || null} />
                            { !!invoiceDetails &&
                                <InvoiceOpsInfosSection axios={al.axios}
                                                        authUser={al.authUser}
                                                        invoiceId={invoiceId}
                                                        opsType={invoiceDetails.opsType}
                                                        opsTags={invoiceDetails.opsTags}
                                                        opsNotes={invoiceDetails.opsNotes} />
                            }
                        </div>
                    }
                </Section>

                { !!al &&!!invoiceDetails && !invoiceDetails.status.customerFinancingRequest?.accepted &&
                    <InvoiceDeleteButtonEnabled axios={al.axios}
                                                invoiceId={invoiceId} />
                }
            </div>
        </div>
    );
}

export default InvoiceDetailsTemplate;