import {
    createBrowserRouter, Outlet, RouteObject, useParams
} from "react-router-dom";
import MainNavRoutes, { DataWithSirenParamWrapper } from "../MainNavRoutes";
import InvoiceDetailsPage from "../Pages/Invoice/InvoiceDetailsPage";
import IAccessToken from "../IAccessToken";
import PreInvoiceDetailsPage from "../Pages/PreInvoice/PreInvoiceDetailsPage";
import {ReactElement, ReactNode, useEffect} from "react";
import CurrentEnv from "../envs/CurrentEnv";
import DataNewBilan from "../Pages/Data/Bilan/DataNewBilan";
import { Axios } from "axios";
import ExistingBilanLoader from "../Pages/Data/Bilan/ExistingBilanLoader";
import Auth2AxiosCreatorWrapper, {WithAxiosOContext} from "../Auth2AxiosCreatorWrapper";
import Loader from "factor-lib/Loader";
import SwanPaymentConsent from "../Pages/SwanConsent/SwanPaymentConsent";
import SwanOAuthRedirect from "../Pages/SwanConsent/SwanOAuthRedirect";
import { InvoicesTabsDefs } from "../Pages/Invoices/InvoicesTabDefs";
import InvoicesPageLayout from "../Pages/Invoices/InvoicesPageLayout";
import SwanIncomingPaymentDetailsPage from "../Pages/SwanIncomingPaymentDetails/SwanIncomingPaymentDetailsPage";
import TopBar from "../TopBar";
import AuthRolesWrapper from "../AuthRolesWrapper";
import IAuthUser from "../IAuthUser";
import MsalProviderWrapper, {WithMsalAuthenticationOContext} from "../MsalProviderWrapper";
import {InvoiceUrlMapping, InvoiceUrlInvoiceIdParam, invoicesUrlTab} from "../Pages/Invoices/invoicesUrls";
import {PreInvoiceUrlMapping, PreInvoiceUrlPreInvoiceIdParam} from "../Pages/PreInvoices/preInvoicesUrls";
import {
    SwanIncomingPaymentUrlMapping,
    SwanIncomingPaymentUrlSwanIncomingPaymentIdParam
} from "../Pages/SwanIncomingPayments/swanIncomingPaymentsUrls";
import {
    DataExistingBilanUrlMapping,
    DataNewBilanUrlMapping,
    DataUrlBilanDateClotureParam,
    DataUrlBilanTypeParam,
    DataUrlSirenParam, DataSirenUrlMapping
} from "../Pages/Data/dataUrls";
import {SwanOAuthRedirectRoute, SwanPaymentConsentRoute} from "../Pages/SwanConsent/swanConsentUrls";
import ErrorHandler from "../ErrorHandler";
import UseNavigateHookHack from "factor-lib/navigationHack/UseNavigateHookHack";
import IRouteDefinitionWithAxiosAndRolesO, {
    buildRouteDefinitionsWithAxiosAndRolesORec
} from "./IRouteDefinitionWithAxiosAndRolesO";
import WithTitleSetter from "./WithTitleSetter";
import {useMsal} from "@azure/msal-react";

const WithTopBar = (
    {
        children
    }: {
        children: ReactNode;
    }
) =>
    <div className='p-vertical-stretch' style={{minHeight: '100vh'}}>
        <TopBar className='p-no-flex' />
        { children }
    </div>

const InvoiceDetailsContent = ({accessTokenO}: {accessTokenO: IAccessToken | null}) =>
    <InvoiceDetailsPage accessTokenO={accessTokenO}
                        invoiceId={useParams()[InvoiceUrlInvoiceIdParam]!} />;

const PreInvoiceDetailsContent = ({accessTokenO}: {accessTokenO: IAccessToken | null}) =>
    <PreInvoiceDetailsPage accessTokenO={accessTokenO}
                           preInvoiceId={useParams()[PreInvoiceUrlPreInvoiceIdParam]!} />;

const SwanIncomingPaymentDetailsContent = ({accessTokenO}: {accessTokenO: IAccessToken | null}) =>
    <SwanIncomingPaymentDetailsPage accessTokenO={accessTokenO}
                                    swanIncomingPaymentId={useParams()[SwanIncomingPaymentUrlSwanIncomingPaymentIdParam]!} />;


const DataNewBilanParamsWrapper = (
    {
        axiosO
    }: {
        axiosO: Axios | null
    }
) => {
    const params = useParams();
    return <DataNewBilan axiosO={axiosO}
                         siren={params[DataUrlSirenParam]!} />;
}

const DataExistingBilanParamsWrapper = (
    {
        axiosO,
        dataAxiosO
    }: {
        axiosO: Axios | null;
        dataAxiosO: Axios;
    }
) => {
    const params = useParams();
    return <ExistingBilanLoader axiosO={axiosO}
                                dataAxios={dataAxiosO}
                                siren={params[DataUrlSirenParam]!}
                                dateClotureBilan={params[DataUrlBilanDateClotureParam]!}
                                typeBilan={params[DataUrlBilanTypeParam]!} />
}

const NonMainNavBaseRoutes: IRouteDefinitionWithAxiosAndRolesO[] = [
    {
        path: InvoiceUrlMapping,
        titleO: 'Invoice-Details',
        component: (accessTokenO) =>
            <InvoiceDetailsContent accessTokenO={accessTokenO}/>
    },
    {
        path: PreInvoiceUrlMapping,
        titleO: 'PreInvoice-Details',
        component: (accessTokenO) =>
            <PreInvoiceDetailsContent accessTokenO={accessTokenO}/>
    },
    // merge with data in Main Nav Routes
    {
        path: SwanIncomingPaymentUrlMapping,
        titleO: 'SwanIncomingPayment-Details',
        component: (accessTokenO) =>
            <SwanIncomingPaymentDetailsContent accessTokenO={accessTokenO}/>
    },
    ...InvoicesTabsDefs.map((i) => ({
            path: invoicesUrlTab(i.urlParam),
            title: `Invoices-${i.title}`,
            component: (accessTokenO: IAccessToken | null) =>
                <InvoicesPageLayout currentTabNameO={i.urlParam}
                                    childFactory={(searchStrFilter: string) => i.component(accessTokenO, searchStrFilter)}/>
        })
    )
];

const rootChildrenWithAuthAndRoles: RouteObject[] = [
    ...MainNavRoutes.map((c) => c.route),
        ...buildRouteDefinitionsWithAxiosAndRolesORec(NonMainNavBaseRoutes),
];

export interface IRouteDefinitionWithAxiosOWoRoles {
    path: string;
    title: string;
    component: (axiosO: Axios | null) => ReactElement;
    children?: RouteObject[];
}

const rootRouteDefinitionWithAxiosOWoRoles: IRouteDefinitionWithAxiosOWoRoles[] = [
    // with topbar
    ...([
        {
            path: DataNewBilanUrlMapping,
            title: 'Data-New-Bilan',
            component: (axiosO: Axios | null) =>
                <DataNewBilanParamsWrapper axiosO={axiosO}/>
        },
        {
            path: DataExistingBilanUrlMapping,
            title: 'Data-Existing-Bilan',
            component: (axiosO: Axios | null) =>
                <Auth2AxiosCreatorWrapper backend={CurrentEnv.dataBackend}
                                          child={(dataAxiosO: Axios | null) =>
                                              (!!dataAxiosO &&
                                                  <DataExistingBilanParamsWrapper axiosO={axiosO}
                                                                                  dataAxiosO={dataAxiosO}/>
                                              ) || <Loader/> }/>
        }
    ].map((d) => ({
        ...d,
        component: (axiosO: Axios | null) =>
            <WithTopBar>
                { d.component(axiosO) }
            </WithTopBar>
    }))),
    {
        path: SwanPaymentConsentRoute,
        title: 'SwanPaymentConsent',
        component: (axiosO) =>
            <SwanPaymentConsent axiosO={axiosO}/>
    },
    {
        path: SwanOAuthRedirectRoute,
        title: 'SwanOAuthRedirect',
        component: (axiosO) =>
            <SwanOAuthRedirect axiosO={axiosO}/>
    }
];

interface IRouteDefinitionWithAxiosO {
    path: string;
    titleO?: string;
    component: (axiosO: Axios | null) => ReactElement;
    children?: RouteObject[];
}

const rootChildrenWithAuth: IRouteDefinitionWithAxiosO[] = [
    {
        path: '/',
        component: (axiosO) =>
            <WithTopBar>
                {
                    (!!axiosO &&
                        <AuthRolesWrapper axios={axiosO}
                                          child={(authUserO: IAuthUser | null) =>
                                              <Outlet context={{accessTokenO: ({
                                                      axios: axiosO,
                                                      authUserO
                                              })}} />
                                          }/>
                    ) || <Outlet context={{accessTokenO: null}} />
                }
            </WithTopBar>,
        children: rootChildrenWithAuthAndRoles
    },
    ...rootRouteDefinitionWithAxiosOWoRoles
        .map((d) => ({
            path: d.path,
            component: (axiosO: Axios | null) =>
                <WithTitleSetter title={d.title}>
                    { d.component(axiosO) }
                </WithTitleSetter>,
            children: d.children
        }))
];

export interface ITitledRouteDefinition {
    path: string;
    title: string;
    component: (msalAuthenticationComplete: boolean) => ReactElement;
    children?: RouteObject[];
}

const ErrorEffectThrower = () => {
    useEffect(() => {
        throw new Error('Test error');
    }, []);
    return <div/>;
}

const ErrorRenderThrower = () => {
    const p: any = {};
    const i = p.i.j;
    return <div id={i}/>;
}

const Logout = () => {
    const { instance } = useMsal();
    useEffect(() => {
        instance.logoutRedirect();
    }, [instance]);
    return <div/>;
}

const identifiedWoAxiosRoutesDefinition: ITitledRouteDefinition[] = [
    // Pages which need the topbar
    ...[
        // Identification needed to access the DATA API
        {
            path: DataSirenUrlMapping,
            title: 'Data',
            component: (isMsalAuthenticationComplete: boolean) =>
                <DataWithSirenParamWrapper isMsalAuthenticationComplete={isMsalAuthenticationComplete}/>
        }
    ]
        // Add the topbar. By default, most of them should have topbar.
        .map((c) => ({
            ...c,
            component: (isMsalAuthenticationComplete: boolean) =>
                <WithTopBar>
                    { c.component(isMsalAuthenticationComplete) }
                </WithTopBar>
        })),
    {
        path: '/test-error-effect',
        title: 'Error-Test',
        component: () =>
            <ErrorEffectThrower/>
    },
    {
        path: '/test-error-render',
        title: 'Error-Test',
        component: () =>
            <ErrorRenderThrower/>
    },
    {
        path: '/logout',
        title: 'Logout',
        component: () =>
            <Logout/>
    }
];

const buildTitledRoutes = (titledRoutesDefinition: ITitledRouteDefinition[]) =>
    titledRoutesDefinition.map((d) => ({
        path: d.path,
        component: (isMsalAuthenticationComplete: boolean) =>
            <WithTitleSetter title={d.title}>
                { d.component(isMsalAuthenticationComplete) }
            </WithTitleSetter>,
        children: d.children
    }));

const router =
    createBrowserRouter([
        {
            path: '/',
            element:
                <UseNavigateHookHack>
                    <MsalProviderWrapper />
                </UseNavigateHookHack>,
            children: [
                {
                    path: '/',
                    component: (msalAuthenticationComplete: boolean) =>
                        msalAuthenticationComplete
                            ? <Auth2AxiosCreatorWrapper backend={CurrentEnv.mainBackend}
                                                        child={(axiosO: Axios | null) =>
                                                            <Outlet context={{axiosO}} />
                                                        }/>
                            : <Outlet context={{axiosO : null}} />,
                    children: rootChildrenWithAuth.map((c) => ({
                        path: c.path,
                        element:
                            !!c.titleO
                                ?
                                <WithTitleSetter title={c.titleO}>
                                    <WithAxiosOContext child={(axiosO: Axios | null) => c.component(axiosO)}/>
                                </WithTitleSetter>
                                :
                                <WithAxiosOContext child={(axiosO: Axios | null) => c.component(axiosO)}/>,
                        children: c.children
                    }))
                },
                ...buildTitledRoutes(identifiedWoAxiosRoutesDefinition)
            ]
                .map((p) => ({
                    path: p.path,
                    element: <WithMsalAuthenticationOContext child={p.component}/>,
                    children: p.children
                })),
            errorElement: <ErrorHandler/>
        }
    ]);

export default router;
