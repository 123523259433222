import { isValidString } from "factor-lib/forms/Inputs/utils";
import { countryOptionsByCode } from "./Countries";
import { ResultOrErrors } from "./ResultOrErrors";

export const BirthLocationGraphQLFields =
    'city countryCode';

export default interface IBirthLocation {
    city: string | null;
    countryCode: string | null;
}

export const formatBirthLocation = (b: IBirthLocation): string =>
    `${b.city ?? '<<No City>>'}, ${b.countryCode ?? '<<No Country>>'}`;

export interface IUpdateBirthLocationParams {
    // Null -> no update
    city: string | null;
    countryCode: string | null;
}

const getBirthLocationValidationErrors = (
    isBirthInfosMandatory: boolean,
    city: string,
    country: string | null,
): string[] => {
    const errors: string[] = [];

    if (isBirthInfosMandatory) {
        if (!isValidString(city)) {
            errors.push(`La ville de naissance est invalide`);
        }
    }

    if (isBirthInfosMandatory && country === null) {
        errors.push(`Le pays de naissance est obligatoire`);
    } else {
        if ((!!country) && !countryOptionsByCode.get(country)) {
            errors.push(`Le pays de naissance est invalide`);
        }
    }

    return errors;
}

export const getBirthLocationParams = (
    isBirthInfosMandatory: boolean,
    city: string,
    countryCode: string | null,
): ResultOrErrors<IBirthLocation> => {

    const validationErrors: string[]  = getBirthLocationValidationErrors(
        isBirthInfosMandatory,
        city,
        countryCode,
    );

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    return ResultOrErrors.fromResult({
        city: isValidString(city) ? city : null,
        countryCode: countryCode
    });
}

export const getUpdateBirthLocationParams = (
    old: IBirthLocation,
    birthLocationParams: IBirthLocation,
): IUpdateBirthLocationParams | null => {

    const cityUpdate: string | null = (!old || birthLocationParams.city !== old.city) ? birthLocationParams.city : null;
    const countryUpdate: string | null = (!old || birthLocationParams.countryCode !== old.countryCode) ? birthLocationParams.countryCode : null;

    return (cityUpdate !== null || countryUpdate !== null)
        ? ({
            city: cityUpdate,
            countryCode: countryUpdate
        }) : null;
}

// if any update field is null, old cannot be null
export const updateBirthLocation = (
    old: IBirthLocation | null,
    update: IUpdateBirthLocationParams
): IBirthLocation => ({
    city: update.city ?? old!.city,
    countryCode: update.countryCode ?? old!.countryCode
});