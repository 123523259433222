import BuyerDeclareDirectRecuperationModalContent from "./InvoiceDeclareDirectRecuperation/BuyerDeclareDirectRecuperationModalContent";
import {Axios} from "axios";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import {IInvoiceDetails} from "../../invoiceDetailsQuery";

const DeclareDirectRecuperationEnabled = (
    {
        className,
        axios,
        invoiceId,
        invoice,
        kind,
        size
    } : {
        className?: string;
        axios: Axios;
        invoiceId: string;
        invoice: IInvoiceDetails;
        kind?: string;
        size?: string;
    }
) =>
    <ButtonForModal id='declareDirectRecuperation'
                    className={className}
                    text='Declare direct recuperation'
                    kind={kind}
                    size={size}
                    childModalContent={(closeModal) =>
                        <BuyerDeclareDirectRecuperationModalContent axios={axios}
                                                                    invoiceId={invoiceId}
                                                                    invoice={invoice}
                                                                    closeModal={closeModal} />
                    }/>;

export default DeclareDirectRecuperationEnabled;
