import windowErrorHandler from "../../utils/windowErrorHandler";
import { useEffect, useRef, useState } from "react";
import useUrlQuery from "../../utils/useUrlQuery";
import Loader from "factor-lib/Loader";
import {Axios} from "axios";

const SwanOAuthRedirectLoaded = (
    {
        axios,
        authorizationCode
    }: {
        axios: Axios;
        authorizationCode: string;
    }
) => {
    const [ isInitializingPayment, setInitializingPayment ] = useState(true);

    // for local-env because useEffect is triggered twice
    const initCalled = useRef(false);

    useEffect(() => {
        if (!initCalled.current) {
            initCalled.current = true;

            const opener = (window.opener as Window);
            opener.initSwanAction(axios, authorizationCode!)
                .finally(() => setInitializingPayment(false))
                .then((r) => window.location.assign(r.data /* consent URL */))
                .catch(windowErrorHandler);
        }
    }, [axios, authorizationCode]);

    return (
        isInitializingPayment
            ? <div>
                Initialization du paiement
                <Loader />
            </div> : <div>
                Le paiement doit s'initialiser
            </div>
    );
}


const SwanOAuthRedirect = (
    {
        axiosO,
    } : {
        axiosO: Axios | null;
    }
) => {
    const query = useUrlQuery();
    const authorizationCode = query.get('code');

    return (
        <div className='container p-padding-2 p-vertical-center'>
            { authorizationCode === null
                ? <div>Echec d'authentification Swan</div>
                : <div className='p-vertical-center'>
                    { !!axiosO
                        ? <SwanOAuthRedirectLoaded axios={axiosO}
                                                   authorizationCode={authorizationCode} />
                        : <Loader/>
                    }

                    <div>Ne changez pas de page, vous allez être redirigé</div>
                </div>
            }
        </div>
    );
}

export default SwanOAuthRedirect;
