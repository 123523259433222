import { Axios } from "axios";
import { IInvoiceDetailsQueryResult, invoiceDetailsQueryKey } from "../../invoiceDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";

const SwitchEligibilityEnabled = (
    {
        axios,
        className,
        invoiceId,
        invoiceIsBlocked,
    } : {
        axios: Axios;
        className?: string;
        invoiceId: string;
        invoiceIsBlocked: boolean;
    }
) => {

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const switchEligibilityMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (invoiceId2) => {
                await axios.put<string>(
                    `/adminInvoices/${invoiceId2}/${invoiceIsBlocked ? 'un' : ''}blockEligibility`
                );
            },
            ({
                onSuccess: () => {
                    if (!invoiceIsBlocked){
                        queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                            invoiceDetailsQueryKey(invoiceId),
                            (old: IInvoiceDetailsQueryResult | undefined) => ({
                                ...old!,
                                invoiceDetails: {
                                    ...old!.invoiceDetails,
                                    blockedByAdmin: !invoiceIsBlocked,
                                    status: {
                                        ...old!.invoiceDetails.status,
                                        eligibility: {
                                            ...old!.invoiceDetails.status.eligibility,
                                            successO: {
                                                ...old!.invoiceDetails.status.eligibility.successO!,
                                                rejectionReason: "Blocked by admin"
                                            }
                                        }
                                    }
                                }
                            })
                        );

                        return Promise.resolve();
                    } else {
                        // eligibility needs to be recomputed server-side
                        return queryClient.invalidateQueries(invoiceDetailsQueryKey(invoiceId));
                    }
                }
            })
        );

    return (
        <ButtonMutationEnabled id='switchInvoiceEligibility'
                               className={className}
                               kind={KIND_PRIMARY}
                               size={SIZE_FIXED}
                               text={`${invoiceIsBlocked ? 'Unblock Eligibility' : 'Block Eligibility'}`}
                               mutation={switchEligibilityMutation}
                               displayFullError={true}
                               value={invoiceId} />
    );
}

export default SwitchEligibilityEnabled;
