import {Outlet} from "react-router-dom";
import IAccessToken from "../../IAccessToken";

const BillingsRootPage =
    (
        {
            accessTokenO
        }: {
            accessTokenO: IAccessToken | null;
        }
    ) =>
        <Outlet context={{accessTokenO} /* {accessTokenO} */} />;

export default BillingsRootPage;
