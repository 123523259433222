import IAccessToken from "../../IAccessToken";
import { ReactElement } from "react";
import WithAccessTokenWaiting from "../../utils/WithAccessTokenWaiting";
import { ICustomerDetails } from "./CustomerInfos/customerDetailsQuery";
import CustomerInfosTab from "./CustomerInfos/CustomerInfosTab";
import MarketplaceSellers from "./MarketplaceSellers/MarketplaceSellers";
import { CustomerInfosTabParam, CustomerMarketplaceSellersTabParam } from "./customersUrls";

interface ICustomerTab {
    path: string;
    filter: (customerDetails: ICustomerDetails) => boolean;
    title: string;
    component: (accessTokenO: IAccessToken | null, customerId: string, customerDetails: ICustomerDetails) => ReactElement;
}

const CustomerTabs: ICustomerTab[] = [
    {
        path: CustomerInfosTabParam,
        title: 'Plateforme Infos',
        filter: () => true,
        component: (accessTokenO: IAccessToken | null, customerId: string, customerDetails: ICustomerDetails) =>
            <WithAccessTokenWaiting accessTokenO={accessTokenO}
                                    child={(accessToken) =>
                                        <CustomerInfosTab axios={accessToken.axios}
                                                          authUserO={accessToken.authUserO}
                                                          customerId={customerId}
                                                          customerDetails={customerDetails} />
                                    }/>
    },
    {
        path: CustomerMarketplaceSellersTabParam,
        title: 'Marketplace Sellers',
        filter: (customerDetails: ICustomerDetails) => !!customerDetails.marketplace,
        component: (accessTokenO: IAccessToken | null, customerId: string, customerDetails: ICustomerDetails) =>
            <WithAccessTokenWaiting accessTokenO={accessTokenO}
                                    child={(accessToken) =>
                                        !!customerDetails.marketplace
                                            ? <MarketplaceSellers axios={accessToken.axios}
                                                                  authUserO={accessToken.authUserO}
                                                                  customerId={customerId}
                                                                  marketplaceId={customerDetails.marketplace.id} />
                                            : <span>Ce seller n'est pas une marketplace</span>
                                    } />
    }
];

export default CustomerTabs;