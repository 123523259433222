import Loader from "factor-lib/Loader";
import AddBuyerChooseSellerLoaded from "./AddBuyerChooseSellerLoaded";
import { Axios } from "axios";
import GraphQLQueryWrapper from "../../../utils/GraphQLQueryWrapper";
import { IIntCompanyIdentifier, IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";

export interface ISellerCanAddBuyer {
    id: string;
    company: {
        identifier: IIntCompanyIdentifier;
        name: string;
    };
    customer: {
        marketplace: {
            id: string;
        } | null;
    }
}

export interface ISellersCanAddBuyerQueryResult {
    sellers: Array<ISellerCanAddBuyer>;
}

const AddBuyerChooseSeller = (
    {
        axios,
    }: {
        axios: Axios;
    }
) =>
    <GraphQLQueryWrapper queryKey={['sellersForAddBuyer']}
                         axios={axios}
                         queryParams={{
                            query: `{
                                sellers {
                                    id
                                    company { identifier { ${IntCompanyIdentifierGraphQLFields} } name }
                                    customer { marketplace { id } }
                                }
                            }`
                         }}
                         onLoading={() => <Loader />}
                         onSuccess={(r: ISellersCanAddBuyerQueryResult) =>
                             <AddBuyerChooseSellerLoaded sellers={r.sellers} />
                         } />;

export default AddBuyerChooseSeller;
