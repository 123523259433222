import GraphQLQueryWrapper from "../../../utils/GraphQLQueryWrapper";
import {buildCompanySelectionGraphQlQueryParams, ICompanySelection } from "factor-lib/Company/ICompanySelection";
import Input, { IInputEnabled } from "factor-lib/forms/Inputs/Input";
import {Axios} from "axios";
import { validatePhoneError } from "factor-lib/forms/Inputs/phoneUtils";
import AutoCompleteInput from "factor-lib/forms/AutoCompleteInput/AutoCompleteInput";

const BuyerPhoneFieldName = 'Téléphone';

const PreInvoiceDetailsPageLoadedPhone = (
    {
        className,
        axios,
        canUpdate,
        buyerCompanySelection,
        buyerPhoneInput,
        setBuyerPhoneInput
    }: {
        className?: string;
        axios: Axios;
        canUpdate: boolean;
        buyerCompanySelection: ICompanySelection | null;
        buyerPhoneInput: string;
        setBuyerPhoneInput: (newValue: string) => void;
    }
) => {
    const updateBuyerPhoneEnabled: IInputEnabled = ({
        updateInputValue: setBuyerPhoneInput,
        validateInput: () => validatePhoneError(buyerPhoneInput),
    });

    return (
        <div className={className}>
            <div className='p-padding-bottom-7'>Numéro de téléphone du Client</div>
            { canUpdate && !!buyerCompanySelection
                ? <GraphQLQueryWrapper queryKey={['company', 'buyer', 'phones', (buyerCompanySelection.id ?? buyerCompanySelection.siren)!]}
                                       axios={axios}
                                       queryParams={buildCompanySelectionGraphQlQueryParams(
                                           buyerCompanySelection,
                                           (paramName, paramType) =>
                                               `
                                                   query Q($${paramName}: ${paramType}) {
                                                       buyerPhones (${paramName}: $${paramName}) { value }
                                                   }
                                               `
                                       )}
                                       onLoading={() =>
                                           <Input fieldName={BuyerPhoneFieldName}
                                                  inputValue={buyerPhoneInput}
                                                  enabled={updateBuyerPhoneEnabled} />
                                       }
                                       onSuccess={(r: {
                                           buyerPhones: Array<{ value: string; }>;
                                       }) =>
                                           <AutoCompleteInput fieldName={BuyerPhoneFieldName}
                                                              inputValue={buyerPhoneInput}
                                                              enabled={{
                                                                  ...updateBuyerPhoneEnabled,
                                                                  // remove duplicates
                                                                  suggestions: Array.from(new Set(
                                                                      r.buyerPhones.map((p) => p.value)
                                                                  )),
                                                                  showButton: {

                                                                  },
                                                                  noSuggestionsMessage: null
                                                              }} />


                                       } />
                : <Input inputValue={buyerPhoneInput}
                         placeHolder={BuyerPhoneFieldName}
                         enabled={canUpdate ? updateBuyerPhoneEnabled : null} />

            }
        </div>
    );
}

export default PreInvoiceDetailsPageLoadedPhone;