import { Axios } from "axios";
import { useState } from "react";
import { IInvoiceDetailsQueryResult, invoiceDetailsQueryKey } from "../../invoiceDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import InputAmount, {formatAmountToInput, isValidAmount, parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ShowValidationErrorsButton from "../../../../utils/ShowValidationErrorsButton";
import { ResultOrErrors } from "../../../../utils/ResultOrErrors";
import DateInput, { formatDateInput, parseDateInput, validateDateInputWithinRange } from "factor-lib/forms/DateInput/DateInput";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import CompanySelector from "../../../../utils/Company/CompanySelector";
import { companyMatchesSelection, ICompanySelection } from "factor-lib/Company/ICompanySelection";
import { PlaceHolderSearchBuyer } from "../../../../utils/Company/CompanySearcher";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import { dateDiffDays, serverDateSerialization } from "factor-lib/utils/dateUtils";

const ConfirmText = 'Confirmer';

interface IUpdateInvoiceMajorInfosParams {
    buyerSelection: ICompanySelection | null;
    issueDate: string | null;
    dueDate: string | null;
    amountWoTax: number | null;
    amountTax: number | null;
}

interface IUpdateInvoiceMajorInfosResponse {
    newBuyerCompany: {
        id: string;
        name: string;
        identifier: IIntCompanyIdentifier;
    } | null;
}

const UpdateInvoiceMajorInfosEnabled = (
    {
        invoiceId,
        axios,
        updateParams,
        closeModal,
        kind,
        size,
        text
    } : {
        invoiceId: string;
        axios: Axios;
        updateParams: IUpdateInvoiceMajorInfosParams;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateInvoiceMajorInfosMutation: UseMutationResult<IUpdateInvoiceMajorInfosResponse, any, IUpdateInvoiceMajorInfosParams> =
        useMutation<IUpdateInvoiceMajorInfosResponse, any, IUpdateInvoiceMajorInfosParams>(
            async (updateParams2) =>
                (await axios.put<IUpdateInvoiceMajorInfosResponse>(
                    `/adminInvoices/${invoiceId}/majorInfos`,
                    updateParams2
                )).data,
            ({
                onSuccess: (response) => {
                    closeModal();
                    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                        invoiceDetailsQueryKey(invoiceId),
                        (old: IInvoiceDetailsQueryResult | undefined) => ({
                            invoiceDetails: {
                                ...old!.invoiceDetails,
                                issueDate: updateParams.issueDate ?? old!.invoiceDetails.issueDate,
                                dueDate: updateParams.dueDate ?? old!.invoiceDetails.dueDate,
                                amountWoTax: updateParams.amountWoTax ?? old!.invoiceDetails.amountWoTax,
                                amountTax: updateParams.amountTax ?? old!.invoiceDetails.amountTax,
                                buyer: (!!updateParams.buyerSelection && !!response.newBuyerCompany) ? {
                                    emails: old!.invoiceDetails.buyer.emails,
                                    phones: old!.invoiceDetails.buyer.phones,
                                    company: {
                                        id: response.newBuyerCompany.id,
                                        identifier: response.newBuyerCompany.identifier,
                                        name: response.newBuyerCompany.name,
                                    }
                                } : old!.invoiceDetails.buyer
                            },
                            buyerEvents: old!.buyerEvents
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateInvoiceMajorInfosButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateInvoiceMajorInfosMutation}
                               displayFullError={true}
                               value={updateParams} />
    );
}

const getUpdateInvoiceMajorInfosParams = (
    oldBuyerCompany: {
        id: string;
        identifier: IIntCompanyIdentifier;
    },
    oldIssueDate: Date,
    oldDueDate: Date,
    oldAmountWoTax: number,
    oldAmountTax: number,

    newBuyerSelection: ICompanySelection | null,
    newIssueDateInput: string,
    newDueDateInput: string,
    newAmountWoTaxInput: string,
    newAmountTaxInput: string
): ResultOrErrors<IUpdateInvoiceMajorInfosParams> | null => {
    const validationErrors: string[] = [];

    const newIssueDateInputParsed: Date | null = parseDateInput(newIssueDateInput);
    if (newIssueDateInputParsed === null) {
        validationErrors.push(`La date d'émission est invalide`);
    } else if (dateDiffDays(newIssueDateInputParsed, new Date()) < 0) {
        validationErrors.push(`La date d'émission est dans le futur`);
    }

    const newDueDateInputParsed: Date | null = parseDateInput(newDueDateInput);
    if (newDueDateInputParsed === null) {
        validationErrors.push(`La date d'échéance est invalide`);
    } else if (!!newIssueDateInputParsed && newDueDateInputParsed < newIssueDateInputParsed) {
        validationErrors.push(`La date d'émission est après la date d'échéance`);
    }

    if (!isValidAmount(newAmountWoTaxInput)) {
        validationErrors.push(`Le montant HT est invalide`);
    }

    if (!isValidAmount(newAmountTaxInput)) {
        validationErrors.push(`Le montant des taxes est invalide`);
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    const newAmountWoTax: number = parseInputAmount(newAmountWoTaxInput);
    const newAmountTax: number = parseInputAmount(newAmountTaxInput);

    const buyerSelectionUpdate: ICompanySelection | null = !!newBuyerSelection && !companyMatchesSelection(oldBuyerCompany, newBuyerSelection) ? newBuyerSelection : null;
    const issueDateUpdate: string | null = dateDiffDays(newIssueDateInputParsed!, oldIssueDate) !== 0 ? serverDateSerialization(newIssueDateInputParsed!) : null;
    const dueDateUpdate: string | null = dateDiffDays(newDueDateInputParsed!, oldDueDate) !== 0 ? serverDateSerialization(newDueDateInputParsed!) : null;
    const amountWoTaxUpdate: number | null = newAmountWoTax !== oldAmountWoTax ? newAmountWoTax : null;
    const amountTaxUpdate: number | null = newAmountTax !== oldAmountTax ? newAmountTax : null;

    return (!!newBuyerSelection || !!issueDateUpdate || !!dueDateUpdate || amountWoTaxUpdate !== null || amountTaxUpdate !== null)
        ? ResultOrErrors.fromResult({
            buyerSelection: buyerSelectionUpdate,
            issueDate: issueDateUpdate,
            dueDate: dueDateUpdate,
            amountWoTax: amountWoTaxUpdate,
            amountTax: amountTaxUpdate
        }) : null;
};

const InvoiceUpdateMajorInfosModalContent = (
    {
        axios,
        invoiceId,
        buyerCompany,
        issueDate,
        dueDate,
        amountWoTax,
        amountTax,
        closeModal
    } : {
        axios: Axios;
        invoiceId: string;
        buyerCompany: {
            id: string;
            identifier: IIntCompanyIdentifier;
        };
        issueDate: Date;
        dueDate: Date;
        amountWoTax: number;
        amountTax: number;
        closeModal: () => void;
    }
) => {
    const [buyerCompanySearchInput, setBuyerCompanySearchInput] = useState('');

    const [newBuyerCompanySelection, setNewBuyerCompanySelection] = useState<ICompanySelection | null>({ id: buyerCompany.id, siren: null});
    const [newIssueDateInput, setNewIssueDateInput] = useState(formatDateInput(issueDate));
    const [newDueDateInput, setNewDueDateInput] = useState(formatDateInput(dueDate));
    const [newAmountWoTaxInput, setNewAmountWoTaxInput] = useState(formatAmountToInput(amountWoTax));
    const [newAmountTaxInput, setNewAmountTaxInput] = useState(formatAmountToInput(amountTax));

    const updateParams: ResultOrErrors<IUpdateInvoiceMajorInfosParams> | null = getUpdateInvoiceMajorInfosParams(
        buyerCompany,
        issueDate,
        dueDate,
        amountWoTax,
        amountTax,

        newBuyerCompanySelection,
        newIssueDateInput,
        newDueDateInput,
        newAmountWoTaxInput,
        newAmountTaxInput
    );

    return (
        <div className='p-padding-3'>
            <div>
                <span className='p-bold'>Buyer</span>
                <CompanySelector axios={axios}
                                 searchPlaceHolder={PlaceHolderSearchBuyer}
                                 searchInput={buyerCompanySearchInput}
                                 setSearchInput={setBuyerCompanySearchInput}
                                 initialDisplaySearch={true}
                                 selection={newBuyerCompanySelection}
                                 setSelection={setNewBuyerCompanySelection}
                                 autofocus={true} />
            </div>

            <div className='p-margin-top-4'>
                <span className='p-bold'>Date d'émission</span>
                <DateInput dateInputValue={newIssueDateInput}
                           enabled={{
                               updateDateInputValue: setNewIssueDateInput,
                               validateDate: () => validateDateInputWithinRange(newIssueDateInput, null, new Date())
                           }} />
            </div>

            <div className='p-margin-top-4'>
                <span className='p-bold'>Date d'échéance</span>
                <DateInput dateInputValue={newDueDateInput}
                           enabled={{
                               updateDateInputValue: setNewDueDateInput,
                               validateDate: () => validateDateInputWithinRange(newDueDateInput, parseDateInput(newIssueDateInput), null)
                           }} />
            </div>

            <div className='p-margin-top-4'>
                <span className='p-bold'>Montant hors Taxes</span>
                <InputAmount inputAmountValue={newAmountWoTaxInput}
                             enabled={{
                                 updateInputValue: setNewAmountWoTaxInput
                             }} />
            </div>

            <div className='p-margin-top-4'>
                <span className='p-bold'>Montant des Taxes</span>
                <InputAmount inputAmountValue={newAmountTaxInput}
                             enabled={{
                                 updateInputValue: setNewAmountTaxInput
                             }} />
            </div>

            <div className='p-margin-top-4 p-vertical-center'>
                { !!updateParams
                    ? !!updateParams.result
                        ? <UpdateInvoiceMajorInfosEnabled axios={axios}
                                                          invoiceId={invoiceId}
                                                          updateParams={updateParams.result}
                                                          closeModal={closeModal}
                                                          kind={KIND_PRIMARY}
                                                          size={SIZE_FIXED}
                                                          text={ConfirmText} />
                        : <ShowValidationErrorsButton validationErrors={updateParams.errors!}
                                                      kind={KIND_PRIMARY}
                                                      size={SIZE_FIXED}
                                                      text={ConfirmText} />
                    : <ButtonDisabled kind={KIND_PRIMARY}
                                      size={SIZE_FIXED}
                                      text={ConfirmText} />
                }
            </div>
        </div>
    );
}

const InvoiceUpdateMajorInfos = (
    {
        axios,
        invoiceId,
        buyerCompany,
        issueDate,
        dueDate,
        amountWoTax,
        amountTax
    } : {
        axios: Axios;
        invoiceId: string;
        buyerCompany: {
            id: string;
            identifier: IIntCompanyIdentifier;
        };
        issueDate: Date;
        dueDate: Date;
        amountWoTax: number;
        amountTax: number;
    }
) =>
    <ButtonForModal id='showUpdateInvoiceMajorInfosModal'
                    text='Modifier Infos Majeures'
                    modalMaxWidth={null}
                    childModalContent={(closeModal) =>
                        <InvoiceUpdateMajorInfosModalContent axios={axios}
                                                             invoiceId={invoiceId}
                                                             buyerCompany={buyerCompany}
                                                             issueDate={issueDate}
                                                             dueDate={dueDate}
                                                             amountWoTax={amountWoTax}
                                                             amountTax={amountTax}
                                                             closeModal={closeModal} />
                    } />;

export default InvoiceUpdateMajorInfos;
