import { parseDateInput } from "factor-lib/forms/DateInput/DateInput";
import Input from "factor-lib/forms/Inputs/Input";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import IOption from "factor-lib/forms/Select/IOption";
import ILiasseInput from "./ILiasseInput";
import Loader from "factor-lib/Loader";
import {FormEvent} from "react";
import IBilanMainInput from "./IBilanMainInput";
import {formatAdminDate} from "../../../utils/dateTimeUtils";
import IBilan from "./IBilan";
import { validateAmountError } from "factor-lib/forms/Inputs/InputAmount";
import { serverDateDeserialization } from "factor-lib/utils/dateUtils";

export enum BilanTypeEnum {
    Complet = 1,
    Simplifie = 2,
    Consolide = 3,
    Banque = 4,
    Assurance = 5
}

export const bilanTypeToLabel = new Map<number, string>([
    [BilanTypeEnum.Complet, "Complet"],
    [BilanTypeEnum.Simplifie, "Simplifie"],
    [BilanTypeEnum.Consolide, "Consolide"],
    [BilanTypeEnum.Banque, "Banque"],
    [BilanTypeEnum.Assurance, "Assurance"]
]);

const bilanTypeOptions: Array<IOption<number>> =
    [BilanTypeEnum.Complet, BilanTypeEnum.Simplifie, BilanTypeEnum.Consolide, BilanTypeEnum.Banque, BilanTypeEnum.Assurance]
        .map((b) => ({
            value: b,
            label: bilanTypeToLabel.get(b)!
        }));

const DataBilanContent = (
    {
        existingBilanO,

        bilanMainInputO,

        liasseInputO
    }: {

        // At least one
        existingBilanO: IBilan | null;
        bilanMainInputO: IBilanMainInput | null;

        liasseInputO: ILiasseInput | null; // null -> loading
    }
) =>
    <div>
        <div>
            <div className='field p-horizontal-center'>
                <div className='p-margin-right-4'>Date cloture</div>
                { !bilanMainInputO
                    ? <div>{formatAdminDate(serverDateDeserialization(existingBilanO!.dateCloture))}</div>
                    : <div>
                        <Input inputValue={bilanMainInputO.dateClotureInput}
                               placeHolder='Date cloture'
                               enabled={{
                                   updateInputValue: bilanMainInputO.setDateClotureInput,
                                   validateInput: () => !parseDateInput(bilanMainInputO.dateClotureInput) ? 'Invalid date' : null,
                                   innerId: {
                                       value: 'dateClotureInner',
                                       autofocus: true
                                   }
                               }} />
                    </div>
                }
            </div>
            <div className='field p-horizontal-center'>
                <div className='p-margin-right-4'>Durée de l’exercice (en mois)</div>
                { !bilanMainInputO
                    ? <div>{existingBilanO!.dureeExercice}</div>
                    : <div>
                        <Input inputValue={bilanMainInputO.dureeExerciceInput}
                               placeHolder='Durée exercice'
                               enabled={{
                                   updateInputValue: bilanMainInputO.setDureeExerciceInput,
                                   validateInput: () => validateAmountError(bilanMainInputO.dureeExerciceInput)
                               }} />
                    </div>
                }
            </div>
            <div className='field p-horizontal-center'>
                <div className='p-margin-right-4'>Type de bilan</div>
                { !bilanMainInputO
                    ? <div>{existingBilanO!.bilanType}</div>
                    : <div>
                        <SingleSelect options={bilanTypeOptions}
                                      selectedOption={bilanTypeOptions.find((p) => p.value === bilanMainInputO.typeBilanInput)!}
                                      selectOption={(o) => bilanMainInputO.setTypeBilanInput(o!.value)}
                                      clearable={false} />
                    </div>
                }
            </div>
        </div>
        <div className='p-margin-top-3 p-vertical-center'>
            { !!liasseInputO
                ? <textarea value={liasseInputO.liasseInput}
                            style={{width: '600px', height: '400px'}}
                            onInput={(e: FormEvent<HTMLTextAreaElement>) => liasseInputO.setLiasseInput(e.currentTarget.value)} />
                : <Loader />
            }
        </div>
    </div>;

export default DataBilanContent;
