import { Axios } from "axios";
import { validateEmailError } from "factor-lib/forms/Inputs/emailUtils";
import { validatePhoneError } from "factor-lib/forms/Inputs/phoneUtils";
import {useContext, useState} from "react";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import Input from "factor-lib/forms/Inputs/Input";
import { ResultOrErrors } from "../../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../../utils/ShowValidationErrorsButton";
import Modal from "factor-lib/Modal";
import Button from "factor-lib/Buttons/Button";
import {NavigateFunction} from "react-router-dom";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { buyersQueryKey, IBuyerCompaniesQueryResult } from "../buyersQuery";
import {collectInvoiceBuyerParamsErrors} from "../../../utils/newInvoiceUtils";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { PlaceHolderSearchBuyer } from "../../../utils/Company/CompanySearcher";
import InputAmount from "factor-lib/forms/Inputs/InputAmount";
import { buyerCompanyDetailsQueryKey, IBuyerCompanyDetailsQueryResult } from "../BuyersInfo/buyerCompanyDetailsQuery";
import {AddBuyerUrl, buyerUrl} from "../buyersUrls";
import CompanySelector from "../../../utils/Company/CompanySelector";
import { ICompanySelection } from "factor-lib/Company/ICompanySelection";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import { isValidAmount, parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";

const AddText = 'Ajouter';

interface IAddBuyerParams {
    companySelection: ICompanySelection;
    askedOutstanding: number | null;
    email: string | null;
    phone: string | null;
}

interface IAddBuyerResponse {
    id: string;
    companyId: string;
    companyName: string;
    companyIdentifier: IIntCompanyIdentifier;
    emailId: string | null;
    phoneId: string | null;
}

const AddBuyerForSellerEnabled = (
    {
        axios,
        sellerId,
        sellerCompanyName,
        addParams,
        setAddResponse,
        kind,
        size,
        text
    } : {
        axios: Axios;
        sellerId: string;
        sellerCompanyName: string;
        addParams: IAddBuyerParams;
        setAddResponse: (addResponse: IAddBuyerResponse) => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const addBuyerForSellerMutation: UseMutationResult<IAddBuyerResponse, any, IAddBuyerParams> =
        useMutation<IAddBuyerResponse, any, IAddBuyerParams>(
            async (addParams2) =>
                (await axios.post<IAddBuyerResponse>(
                    `/adminSellers/${sellerId}/buyer`,
                    addParams2
                )).data,
            ({
                onSuccess: (addResponse) => {
                    const buyerId = addResponse.id;
                    const buyerCompanyId = addResponse.companyId;
                    const buyerEmailId = addResponse.emailId;
                    const buyerPhoneId = addResponse.phoneId;

                    queryClient.setQueryData<IBuyerCompaniesQueryResult>(
                        buyersQueryKey,
                        (old: IBuyerCompaniesQueryResult | undefined) => !!old ? ({
                            ...old,
                            companies: !!old.companies.find((c) => c.id === buyerCompanyId)
                                ? old.companies
                                : [
                                    ...old.companies,
                                    {
                                        id: buyerCompanyId,
                                        name: addResponse.companyName,
                                        identifier: addResponse.companyIdentifier,
                                        asBuyer: {
                                            isBlocked: false
                                        }
                                    }
                                ]
                        }) : undefined
                    );

                    queryClient.setQueryData<IBuyerCompanyDetailsQueryResult>(
                        buyerCompanyDetailsQueryKey(buyerCompanyId),
                        (old: IBuyerCompanyDetailsQueryResult | undefined) => !!old ? ({
                            ...old,
                            buyers: [
                                ...old.buyers,
                                {
                                    id: buyerId,
                                    outstanding: 0,
                                    askedOutstanding: addParams.askedOutstanding,
                                    askedOutstandingDateTime: addParams.askedOutstanding !== null ? serverDateSerialization(new Date()) : null,
                                    seller: {
                                        id: sellerId,
                                        company: {
                                            name: sellerCompanyName
                                        }
                                    }
                                }
                            ],
                            buyerEmails: buyerEmailId !== null && !old.buyerEmails.some((be) => be.id === buyerEmailId)
                                ? [
                                    ...old.buyerEmails,
                                    {
                                        id: buyerEmailId,
                                        value: addParams.email!,
                                        buyer: {
                                            id: buyerId
                                        },
                                        nbEvents: 0,
                                        nbInvoices: 0
                                    }
                                ]
                                : old.buyerEmails,
                            buyerPhones: buyerPhoneId !== null && !old.buyerPhones.some((bp) => bp.id === buyerPhoneId)
                                ? [
                                    ...old.buyerPhones,
                                    {
                                        id: buyerPhoneId,
                                        value: addParams.phone!,
                                        buyer: {
                                            id: buyerId
                                        },
                                        nbEvents: 0,
                                        nbInvoices: 0
                                    }
                                ]
                                : old.buyerPhones,
                        }) : undefined
                    );

                    setAddResponse(addResponse);
                }
            })
        );

    return (
        <ButtonMutationEnabled id='addBuyerForSellerButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={addBuyerForSellerMutation}
                               displayFullError={true}
                               value={addParams} />
    );
}

const getBuyerAddParams = (
    sellerCompany: {
        id: string;
        identifier: IIntCompanyIdentifier;
    },
    buyerSelection: ICompanySelection | null,
    askedOutstandingInput: string,
    emailInput: string,
    phoneInput: string
): ResultOrErrors<IAddBuyerParams> => {

    const emailInputNullable = emailInput !== '' ? emailInput : null;
    const phoneInputNullable = phoneInput !== '' ? phoneInput : null;

    const validationErrors: string[] = collectInvoiceBuyerParamsErrors(
        sellerCompany,
        buyerSelection,
        !!emailInputNullable ? [emailInputNullable] : null,
        !!phoneInputNullable ? [ phoneInputNullable ] : null
    );

    const askedOutstandingInputNullable = askedOutstandingInput !== '' ? askedOutstandingInput : null;

    if (askedOutstandingInputNullable !== null && !isValidAmount(askedOutstandingInputNullable)) {
        validationErrors.push(`L'encours demandé est invalide`);
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    return ResultOrErrors.fromResult({
        companySelection: buyerSelection!,
        askedOutstanding: askedOutstandingInputNullable != null ? parseInputAmount(askedOutstandingInputNullable) : null,
        email: emailInputNullable,
        phone: phoneInputNullable
    });
}

const AddBuyerForSeller = (
    {
        axios,
        sellerId,
        sellerCompany
    }: {
        axios: Axios;
        sellerId: string;
        sellerCompany: {
            id: string;
            name: string;
            identifier: IIntCompanyIdentifier;
        };
    }
) => {
    const [buyerSearchInput, setBuyerSearchInput] = useState('');
    const [buyerSelection, setBuyerSelection] = useState<ICompanySelection | null>(null);
    const [askedOutstandingInput, setAskedOutstandingInput] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [phoneInput, setPhoneInput] = useState('');

    const addParams: ResultOrErrors<IAddBuyerParams> =
        getBuyerAddParams(
            sellerCompany,
            buyerSelection,
            askedOutstandingInput,
            emailInput,
            phoneInput
        );

    const clearForm = () => {
        setBuyerSearchInput('');
        setBuyerSelection(null);
        setEmailInput('');
        setPhoneInput('');
    };

    const [addResponse, setAddResponse] = useState<IAddBuyerResponse | null>(null);

    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    // TODO : review pattern

    return (
        <div className='container p-both-center p-margin-top-3'>
            <div style={{width: 600}}>
                <span className='title p-both-center'>
                    Ajouter un Buyer pour {sellerCompany.name}
                </span>

                <div className='box'>
                    <span className='p-bold p-size-4 p-both-center p-margin-bottom-4'>Infos de la société</span>

                    <div className='p-margin-bottom-5'>
                        <CompanySelector axios={axios}
                                         searchPlaceHolder={PlaceHolderSearchBuyer}
                                         searchInput={buyerSearchInput}
                                         setSearchInput={setBuyerSearchInput}
                                         initialDisplaySearch={true}
                                         selection={buyerSelection}
                                         setSelection={setBuyerSelection}
                                         autofocus={true} />
                    </div>

                    <div>
                        <span className='p-bold'>Encours demandé (Optionnel)</span>
                        <InputAmount inputAmountValue={askedOutstandingInput}
                                     enabled={{
                                         updateInputValue: setAskedOutstandingInput
                                     }} />
                    </div>
                </div>

                <div className='box'>
                    <span className='p-bold p-size-4 p-both-center p-margin-bottom-4'>Infos de contact (Optionnels)</span>

                    <div className='p-padding-bottom-5'>
                        <span className='p-bold'>Email</span>
                        <Input inputValue={emailInput}
                               enabled={{
                                   updateInputValue: setEmailInput,
                                   validateInput: () => validateEmailError(emailInput)
                               }} />
                    </div>

                    <div className='p-padding-bottom-5'>
                        <span className='p-bold'>Numéro de téléphone</span>
                        <Input inputValue={phoneInput}
                               enabled={{
                                   updateInputValue: setPhoneInput,
                                   validateInput: () => validatePhoneError(phoneInput)
                               }} />
                    </div>
                </div>

                <div className='p-margin-3 p-vertical-center'>
                    { !!addParams.result
                        ? <AddBuyerForSellerEnabled axios={axios}
                                                    sellerId={sellerId}
                                                    sellerCompanyName={sellerCompany.name}
                                                    addParams={addParams.result}
                                                    setAddResponse={setAddResponse}
                                                    kind={KIND_PRIMARY}
                                                    size={SIZE_FIXED}
                                                    text={AddText} />
                        : <ShowValidationErrorsButton validationErrors={addParams.errors!}
                                                      kind={KIND_PRIMARY}
                                                      size={SIZE_FIXED}
                                                      text={AddText} />
                    }
                </div>

                { !!addResponse &&
                    <Modal id='addBuyerForSellerResponseModal'
                            maxWidth='800px'
                            fullMaxWidth={true}
                            close={() => {
                                setAddResponse(null);
                                clearForm();
                            }}>
                        <div className='p-padding-4 p-vertical-center'>
                            <span>Le buyer <span className='p-bold'>{addResponse.companyName}</span> du seller <span className='p-bold'>{sellerCompany.name}</span> a bien été ajouté</span>

                            <div className='columns p-margin-top-5'>
                                <div className='column'>
                                    <Button id='gotoAddedBuyerDetailsButton'
                                            text='Détails du Buyer'
                                            isLoading={false}
                                            actionO={() => navigate(buyerUrl(addResponse.companyId, true))} />

                                </div>
                                <div className='column'>
                                    <Button id='addAnotherSellerBuyerButton'
                                            text='Ajouter un autre Buyer pour ce Seller'
                                            isLoading={false}
                                            actionO={() => {
                                                setAddResponse(null);
                                                clearForm();
                                            }} />

                                </div>
                                <div className='column'>
                                    <Button id='addAnotherBuyerButton'
                                            text='Ajouter un autre Buyer'
                                            isLoading={false}
                                            actionO={() => navigate(AddBuyerUrl)} />

                                </div>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        </div>
    );
}

export default AddBuyerForSeller;
