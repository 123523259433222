import Input from "factor-lib/forms/Inputs/Input";
import IOption from "factor-lib/forms/Select/IOption";
import MultipleSelect from "factor-lib/forms/Select/MultipleSelect";
import {useState} from "react";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {Axios} from "axios";
import {buyerCompanyDetailsQueryKey, IBuyerCompanyDetailsQueryResult} from "../buyerCompanyDetailsQuery";
import { isValidEmail } from "factor-lib/forms/Inputs/emailUtils";
import { isValidPhone } from "factor-lib/forms/Inputs/phoneUtils";
import ButtonMutation from 'factor-lib/Buttons/ButtonMutation';

interface ICreateContactsRequest {
    buyersIds: string[];
    email?: string;
    phone?: string;
}

interface INewEmailsAndPhonesIds {
    newEmailId?: string;
    newPhoneId?: string;
}

type ICreateContactsResponse = {[key: string]: INewEmailsAndPhonesIds};

interface ISellerRef {
    buyerId: string;
    name: string;
}

const AddBuyerModalContent = (
    {
        axios,
        sellers,
        buyerCompanyId,
        closeModal
    }: {
        axios: Axios;
        sellers: Array<ISellerRef>;
        buyerCompanyId: string;
        closeModal: () => void;
    }
) => {

    const [ selectedSellers, setSelectedSellers ] = useState<ReadonlyArray<IOption<ISellerRef>>>(
        []
    );

    const [newEmailInput, setNewEmailInput] = useState('');
    const [newPhoneInput, setNewPhoneInput] = useState('');

    const queryClient = useQueryClient();

    const addBuyerContactMutation: UseMutationResult<ICreateContactsResponse, any, ICreateContactsRequest> =
        useMutation<ICreateContactsResponse, any, ICreateContactsRequest>(
            async (createContactsRequest) =>
                (await axios.post<ICreateContactsResponse>(
                    'adminBuyerContacts',
                    createContactsRequest
                )).data
            ,
            ({
                onSuccess: (response, request) => {
                    queryClient.setQueryData<IBuyerCompanyDetailsQueryResult>(
                        buyerCompanyDetailsQueryKey(buyerCompanyId),
                        (old: IBuyerCompanyDetailsQueryResult | undefined) => !!old
                            ? ({
                                ...old,
                                buyerEmails: !!request.email
                                        ? [
                                        ...old.buyerEmails,
                                        ...request.buyersIds.map((bId) => ({
                                            id: response[bId].newEmailId!,
                                            value: request.email!,
                                            buyer: {
                                                id: bId
                                            },
                                            nbEvents: 0,
                                            nbInvoices: 0
                                        }))
                                    ] : old.buyerEmails,
                                buyerPhones: !!request.phone
                                    ? [
                                        ...old.buyerPhones,
                                        ...request.buyersIds.map((bId) => ({
                                            id: response[bId].newPhoneId!,
                                            value: request.phone!,
                                            buyer: {
                                                id: bId
                                            },
                                            nbEvents: 0,
                                            nbInvoices: 0
                                        }))
                                    ] : old.buyerPhones
                            }) : undefined
                    );
                    closeModal();
                }
            })
        );

    return (
        <div className='p-padding-3 p-vertical-stretch'>

            <div className='field'>
                <span className='p-margin-right-4 p-bold'>Sellers</span>
                <MultipleSelect<ISellerRef> options={sellers.map((s) => ({
                                                value: s,
                                                label: s.name
                                            }))}
                                            selectedOptions={selectedSellers}
                                            setSelectedOptions={setSelectedSellers} />

            </div>

            <div className='field'>
                <span className='p-margin-right-4 p-bold'>Email</span>
                <Input inputValue={newEmailInput}
                       enabled={{
                           updateInputValue: setNewEmailInput,
                           validateInput: () => null,
                           innerId: {
                               value: 'emailInner',
                               autofocus: true
                           }
                       }} />
            </div>

            <div className='field'>
                <span className='p-margin-right-4 p-bold'>Téléphone</span>
                <Input inputValue={newPhoneInput}
                       enabled={{
                           updateInputValue: setNewPhoneInput,
                           validateInput: () => null
                       }} />
            </div>

            <div className='field p-vertical-center'>
                <ButtonMutation<ICreateContactsResponse, ICreateContactsRequest> id='addBuyerContacts'
                                                                                 text='Ajouter'
                                                                                 mutation={addBuyerContactMutation}
                                                                                 displayFullError={true}
                                                                                 valueO={(selectedSellers.length > 0 &&
                                                                                     (newEmailInput.length === 0 || isValidEmail(newEmailInput)) &&
                                                                                     (newPhoneInput.length === 0 || isValidPhone(newPhoneInput)) &&
                                                                                     (newEmailInput.length > 0 || newPhoneInput.length > 0)
                                                                                 ) ? ({
                                                                                    value: {
                                                                                        buyersIds: selectedSellers.map((s) => s.value.buyerId),
                                                                                        email: newEmailInput.length > 0 ? newEmailInput : undefined,
                                                                                        phone: newPhoneInput.length > 0 ? newPhoneInput : undefined
                                                                                    }
                                                                                 }) : null } />


            </div>
        </div>
    );
}

export default AddBuyerModalContent;