import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {customerDetailsQueryKey, ICustomerDetailsQueryResult} from "../customerDetailsQuery";
import {Axios} from "axios";
import IAuthUser from "../../../../IAuthUser";
import { KIND_PRIMARY, SIZE_COMPACT, SIZE_FIXED } from "factor-lib/Buttons/Button";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { useState } from "react";
import Input from "factor-lib/forms/Inputs/Input";
import { ResultOrErrors } from "../../../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../../../utils/ShowValidationErrorsButton";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import { getUpdatePercentageParam } from "./MarketplacePercentageParsingUtils";

const ConfirmText = 'Confirmer';

const UpdateSellerPercentageEnabled = (
    {
        axios,
        customerId,
        marketplaceId,
        newPercentage,
        closeModal,
        kind,
        size,
        text
    } : {
        axios: Axios;
        customerId: string;
        marketplaceId: string;
        newPercentage: number;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateSellerPercentageMutation: UseMutationResult<void, any, number> =
        useMutation<void, any, number>(
            async (newPercentage2) => {
                await axios.put<void>(
                    `/adminMarketplaces/${marketplaceId}/percentageForSeller`,
                    {
                        per10000ForSeller: newPercentage2
                    }
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId), (old?: ICustomerDetailsQueryResult) => !!old ? ({
                            customerDetails: {
                                ...old!.customerDetails,
                                marketplace: {
                                    ...old.customerDetails.marketplace!,
                                    invoicePer10000ForSeller: newPercentage
                                }
                            }
                        }) : undefined
                    );
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateMarketplaceSellerPercentageButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateSellerPercentageMutation}
                               displayFullError={true}
                               value={newPercentage} />
    );
}

const UpdateSellerPercentageModalContent = (
    {
        axios,
        customerId,
        marketplaceId,
        sellerPercentage,
        closeModal
    }: {
        axios: Axios;
        customerId: string;
        marketplaceId: string;
        sellerPercentage: number;
        closeModal: () => void;
    }
) => {
    const [newPercentageInput, setNewPercentageInput] = useState(sellerPercentage.toString());

    const newPercentageFailableO: ResultOrErrors<number> | null = getUpdatePercentageParam(
        sellerPercentage,
        newPercentageInput
    );

    return (
        <div className='p-padding-3'>
            <div>
                <span className='p-margin-right-4 p-bold'>Nouveau pourcentage pour le seller</span>
                <Input inputValue={newPercentageInput}
                       enabled={{
                           updateInputValue: setNewPercentageInput,
                           validateInput: () => null
                       }} />
            </div>

            <div className='p-margin-top-4 p-vertical-center'>
                { !!newPercentageFailableO
                    ? !!newPercentageFailableO.result
                        ? <UpdateSellerPercentageEnabled axios={axios}
                                                   marketplaceId={marketplaceId}
                                                   newPercentage={newPercentageFailableO.result}
                                                   customerId={customerId}
                                                   closeModal={closeModal}
                                                   kind={KIND_PRIMARY}
                                                   size={SIZE_FIXED}
                                                   text={ConfirmText} />
                        : <ShowValidationErrorsButton validationErrors={newPercentageFailableO.errors!}
                                                      kind={KIND_PRIMARY}
                                                      size={SIZE_FIXED}
                                                      text={ConfirmText} />
                    : <ButtonDisabled kind={KIND_PRIMARY}
                                      size={SIZE_FIXED}
                                      text={ConfirmText} />
                }
            </div>
        </div>
    );
}


const CustomerMarketplacePercentageForSellerSection = (
    {
        axios,
        customerId,
        authUserO,
        marketplaceId,
        invoicePer10000ForSeller
    }: {
        axios: Axios;
        authUserO: IAuthUser | null;
        customerId: string;
        marketplaceId: string;
        invoicePer10000ForSeller: number;
    }
) =>
    (
        <table>
            <tbody>
            <tr>
                <td style={{verticalAlign: 'middle'}}>Part for seller: {(invoicePer10000ForSeller/100).toFixed(2)} %, part for marketplace: {(100 - (invoicePer10000ForSeller/100)).toFixed(2)} %</td>
                { !!authUserO?.canManageProfiles &&
                    <td>
                        <ButtonForModal className='p-margin-left-5'
                                        size={SIZE_COMPACT}
                                        id='updateSellerPercentage'
                                        text='Modifier'
                                        childModalContent={(closeModal) =>
                                            <UpdateSellerPercentageModalContent axios={axios}
                                                                                customerId={customerId}
                                                                                marketplaceId={marketplaceId}
                                                                                sellerPercentage={invoicePer10000ForSeller/100}
                                                                                closeModal={closeModal} />
                                        } />
                    </td>
                }
            </tr>
            </tbody>
        </table>
    );

export default CustomerMarketplacePercentageForSellerSection;