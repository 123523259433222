import {countryOptionsByCode, COUNTRY_OPTIONS, DEFAULT_FRANCE} from "../Countries";
import DateInput, { formatDateInput, validateDateInputWithinRange } from "factor-lib/forms/DateInput/DateInput";
import Input from "factor-lib/forms/Inputs/Input";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import IPerson from "./IPerson";
import {AddressOInput, getInitialNewAddressInputO, IAddressOInput} from "../AddressInput";
import { serverDateDeserialization } from "factor-lib/utils/dateUtils";
import { validateMandatoryFieldError } from "factor-lib/forms/Inputs/utils";
import { isEmpty } from 'factor-lib/forms/Inputs/utils';

export interface IPersonInput {
    firstName: string;
    lastName: string;
    nationalityCode: string | null;

    birthDate: string;
    birthCity: string;
    birthCountryCode: string | null;

    residenceAddress: IAddressOInput;
}

export const getInitialExistingPersonInput = (person: IPerson): IPersonInput => ({
    firstName: person.firstName,
    lastName: person.lastName,
    nationalityCode: person.nationalityCode,

    birthDate: !!person.birthDate ? formatDateInput(serverDateDeserialization(person.birthDate)) : '',
    birthCity: person.birthLocation.city ?? '',
    birthCountryCode: person.birthLocation.countryCode ?? null,

    residenceAddress: {
        streetAddress: person.residenceAddress?.streetAddress ?? '',
        city: person.residenceAddress?.city ?? '',
        postCode: person.residenceAddress?.postCode ?? '',
        countryCode: person.residenceAddress?.countryCode ?? null
    }
});

export const getInitialNewPersonInput = (isBirthInfosMandatory: boolean): IPersonInput => ({
    firstName: '',
    lastName: '',
    nationalityCode: null,

    birthDate: '',
    birthCity: '',
    birthCountryCode: isBirthInfosMandatory ? DEFAULT_FRANCE.value : null,

    residenceAddress: getInitialNewAddressInputO()
});

interface IPersonInputEnabled {
    autofocus: boolean;
    isBirthInfosMandatory: boolean;

    setFirstNameInput: (input: string) => void;
    setLastNameInput: (input: string) => void;
    setNationalityCodeInput: (countryCodeInput: string | null) => void;

    setBirthDateInput: (input: string) => void;

    // TODO : merge these 2
    setBirthCityInput: (input: string) => void;
    setBirthCountryCodeInput: (countryCodeInput: string | null) => void;

    setResidenceAddressInput: (newInput: IAddressOInput) => void;
}

const PersonInput = (
    {
        firstNameInput,
        lastNameInput,
        nationalityCodeInput,

        isBirthInfosMandatory,
    
        birthDateInput,

        // TODO : merge these 2
        birthCityInput,
        birthCountryCodeInput,

        residenceAddressInput,

        enabled
    } : {
        firstNameInput: string;
        lastNameInput: string;
        nationalityCodeInput: string | null;

        isBirthInfosMandatory: boolean;
        birthDateInput: string;
        
        birthCityInput: string;
        birthCountryCodeInput: string | null;

        residenceAddressInput: IAddressOInput;

        enabled: IPersonInputEnabled | null;
    }
) => {
    const today = new Date();

    return (
        <div>
            <div className='columns'>
                <div className='column'>
                    <span className='p-bold'>Prénom</span>
                    <Input inputValue={firstNameInput}
                           placeHolder='Prénom'
                           enabled={(!!enabled && ({
                               updateInputValue: enabled.setFirstNameInput,
                               validateInput: () => validateMandatoryFieldError(firstNameInput),
                               innerId: {
                                   value: 'firstNameInner',
                                   autofocus: enabled.autofocus
                               }
                           })) || null} />
                </div>

                <div className='column'>
                    <span className='p-bold'>Nom</span>
                    <Input inputValue={lastNameInput}
                           placeHolder='Nom'
                           enabled={(!!enabled && ({
                               updateInputValue: enabled.setLastNameInput,
                               validateInput: () => validateMandatoryFieldError(lastNameInput)
                           })) || null} />
                </div>
            </div>

            <div className='p-margin-bottom-5'>
                <span className='p-bold'>Nationalité</span> (Optionnelle)
                <SingleSelect options={COUNTRY_OPTIONS}
                              placeholder='Nationalité'
                              selectedOption={nationalityCodeInput !== null ? countryOptionsByCode.get(nationalityCodeInput)! : null}
                              selectOption={!!enabled ? (newOption) => enabled.setNationalityCodeInput(newOption?.value ?? null) : null}
                              clearable={true} />
            </div>

            <hr />

            <div className='p-margin-bottom-5'>
                <span className='p-bold'>Date de naissance</span>
                { !isBirthInfosMandatory && <span> (Optionnelle)</span> }
                <DateInput dateInputValue={birthDateInput}
                           enabled={(!!enabled && ({
                               updateDateInputValue: enabled.setBirthDateInput,
                               validateDate: () => validateDateInputWithinRange(birthDateInput, null, today)
                           })) || null} />
            </div>

            <div className='columns'>
                <div className='column'>
                    <span className='p-bold'>Pays de naissance</span>
                    { !isBirthInfosMandatory && <span> (Optionnel)</span> }
                    <SingleSelect options={COUNTRY_OPTIONS}
                                  placeholder='Pays de naissance'
                                  clearable={!!enabled && !enabled.isBirthInfosMandatory}
                                  selectedOption={birthCountryCodeInput !== null ? countryOptionsByCode.get(birthCountryCodeInput)! : null}
                                  selectOption={!!enabled ? (newOption) => enabled.setBirthCountryCodeInput(!!newOption ? newOption.value : null) : null} />
                </div>

                <div className='column'>
                    <span className='p-bold'>Ville de naissance</span>
                    { !isBirthInfosMandatory && <span> (Optionnelle)</span> }
                    <Input inputValue={birthCityInput}
                           placeHolder='Ville de naissance'
                           enabled={(!!enabled && ({
                               updateInputValue: enabled.setBirthCityInput,
                               validateInput: () => enabled.isBirthInfosMandatory ? validateMandatoryFieldError(birthCityInput) : (isEmpty(birthCityInput) ? null /* ok */ : validateMandatoryFieldError(birthCityInput)),
                               innerId: null
                           })) || null} />
                </div>
            </div>

            <hr />

            <div className='p-vertical-center p-margin-bottom-5'>
                <div><span className='p-bold'>Adresse du domicile</span> (Optionnelle)</div>
            </div>

            <AddressOInput className='p-margin-bottom-4'
                           input={residenceAddressInput}
                           enabled={(!!enabled && ({
                               autofocus: false,
                               setAddressInput: enabled.setResidenceAddressInput
                           })) || null}/>

        </div>
    );
}

export default PersonInput;
