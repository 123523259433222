import {useState} from "react";
import FinancingRequestsTable, {INewFinancingRequestsQueryParams} from "./FinancingRequestsTable";
import IAccessToken from "../../IAccessToken";
import IOption from "factor-lib/forms/Select/IOption";
import {
    ICustomerFinancingRequestNonAcceptedNorRejected
} from "./FinancingRequestsQuery";

const FinancingRequestsTableStateFilter = (
    {
        accessTokenO,
        queryKey,
        queryParam,
        searchStrFilterO
    }: {
        accessTokenO: IAccessToken | null,
        queryKey: string[];
        queryParam: INewFinancingRequestsQueryParams;
        searchStrFilterO: string | null;
    }
) => {
    const [ selectedFilter, setSelectedFilter ] = useState<IOption<(financingRequest: ICustomerFinancingRequestNonAcceptedNorRejected) => boolean> | null>(null);

    return <FinancingRequestsTable accessTokenO={accessTokenO}
                                   queryKey={queryKey}
                                   queryParam={queryParam}
                                   selectedFilter={selectedFilter}
                                   setSelectedFilter={setSelectedFilter}
                                   searchStrFilterO={searchStrFilterO}/>;
}

export default FinancingRequestsTableStateFilter;
