import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import IAddress from "../../../utils/IAddress";

export const companyDetailsQueryKey = (companyId: string) =>
    ['company', companyId];

export interface ICompanyDetails {
    id: string;
    creationDateTime: string;
    identifier: IIntCompanyIdentifier;
    name: string;
    address: IAddress;
}

export interface ICompanyDetailsQueryResult {
    companyDetails: ICompanyDetails;
}
