import IAuthUser from "../../../../../IAuthUser";
import { ICustomer } from "../../sellerDetailsQuery";
import BankStatement from "./BankStatements/BankStatement";
import {Axios} from "axios";

const BankStatementsSection = (
    {
        axios,
        authUser,
        sellerId,
        customerId,
        existingBankStatement1FileName,
        existingBankStatement2FileName,
        existingBankStatement3FileName
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        sellerId: string;
        customerId: string;
        existingBankStatement1FileName: string | null;
        existingBankStatement2FileName: string | null;
        existingBankStatement3FileName: string | null;

    }
) =>
    <div className='level'>
        <BankStatement className='level-item'
                       axios={axios}
                       authUser={authUser}
                       customerId={customerId}
                       sellerId={sellerId}
                       bankStatementNumber={1}
                       existingBankStatementFileName={existingBankStatement1FileName}
                       customerUpdater={(oldCustomer: ICustomer, newFileName: string): ICustomer => ({
                           ...oldCustomer,
                           bankStatement1: {
                               fileName: newFileName
                           }
                       })} />

        <BankStatement className='level-item'
                       axios={axios}
                       authUser={authUser}
                       customerId={customerId}
                       sellerId={sellerId}
                       bankStatementNumber={2}
                       existingBankStatementFileName={existingBankStatement2FileName}
                       customerUpdater={(oldCustomer: ICustomer, newFileName: string): ICustomer => ({
                           ...oldCustomer,
                           bankStatement2: {
                               fileName: newFileName
                           }
                       })} />

        <BankStatement className='level-item'
                       axios={axios}
                       authUser={authUser}
                       customerId={customerId}
                       sellerId={sellerId}
                       bankStatementNumber={3}
                       existingBankStatementFileName={existingBankStatement3FileName}
                       customerUpdater={(oldCustomer: ICustomer, newFileName: string): ICustomer => ({
                           ...oldCustomer,
                           bankStatement3: {
                               fileName: newFileName
                           }
                       })} />
    </div>;

export default BankStatementsSection;
