import {CustomerNamespace} from "../../Customers/CustomerInfos/customerDetailsQuery";
import IYearMonth from "../IYearMonth";

export const pennyLaneCustomerQuery = (customerId: string) => ({
    query: `
        query ($customerId: Guid!, $yearMonth: YearMonthInput!) {
            customer: customerDetails(id: $customerId) {
                pennyLaneId
                contactEmail
            }
        }
    `,
    variables: {
        customerId
    }
});

export const existingBillingQuery = (customerId: string, yearMonth: IYearMonth) => ({
    query: `
        query ($customerId: Guid!, $yearMonth: YearMonthInput!) {
            existingBilling: billings(customerId: $customerId, yearMonth: $yearMonth) {
                any
            }
        }
    `,
    variables: {
        customerId,
        yearMonth
    }
});

export interface IPennyLaneCustomerQueryResult {
    customer: {
        pennyLaneId: string | null;
        contactEmail: string;
    };
}

export interface IExistingBillingQueryResult {
    existingBilling: {
        any: boolean;
    }
}

export const pennyLaneCustomerQueryKey = (customerId: string) =>
    [CustomerNamespace, customerId, 'pennylane'];

export const existingBillingQueryKey = (customerId: string, yearMonth: IYearMonth): string[] =>
    ['billings', 'existing', customerId, yearMonth.year.toString(), yearMonth.month.toString()];
