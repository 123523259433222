import { ConfidenceFlag, CONFIDENCE_FLAGS_COLORS } from "../../../../../../utils/ConfidenceFlags";
import { useState } from "react";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import IAuthUser from "../../../../../../IAuthUser";
import { Axios } from "axios";
import {Certif, ISellerDetailsQueryResult, sellerDetailsQueryKey} from "../../../sellerDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { ISellersQueryResult, sellersQueryKey } from "../../../../sellersQuery";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import {QueryClient, useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import ShowValidationErrorsButton from "../../../../../../utils/ShowValidationErrorsButton";
import { preInvoiceDetailsPrefix, IPreInvoiceDetailsQueryResult } from "../../../../../PreInvoice/PreInvoiceDetailsQuery";
import { customerDetailsQueryKey, ICustomerDetailsQueryResult } from "../../../../../Customers/CustomerInfos/customerDetailsQuery";
import { ISellerForAddInvoiceQueryResult, sellerForAddInvoiceQueryKey } from "../../../../SellerAddInvoice/sellerAddInvoiceQuery";
import { IInvoiceDetailsQueryResult, InvoiceDetailsQueryKeyPrefix } from "../../../../../Invoice/invoiceDetailsQuery";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import { formatAdminDateStr } from "../../../../../../utils/dateTimeUtils";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import IOption from "factor-lib/forms/Select/IOption";

const UpdateButtonText = 'Modifier';
const CertifyButtonText = 'Certifier';
const UpdateCertifyButtonKind = KIND_PRIMARY;
const UpdateCertifyButtonSize = SIZE_FIXED;

interface IFlags {
    solvaConfidenceFlag: ConfidenceFlag;
    fraudConfidenceFlag: ConfidenceFlag;
}

const UpdateConfidenceFlagEnabled = (
    {
        className,
        axios,
        sellerId,
        customerId,
        newSolvaConfidenceFlag,
        newFraudConfidenceFlag,
        kind,
        size,
        text
    } : {
        className?: string;
        axios: Axios;
        sellerId: string;
        customerId: string;
        newSolvaConfidenceFlag: ConfidenceFlag;
        newFraudConfidenceFlag: ConfidenceFlag;
        kind: string;
        size: string;
        text: string;
    }
) => {

    const queryClient: QueryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const setConfidenceFlagMutation: UseMutationResult<void, any, IFlags, null> =
        useMutation<void, any, IFlags, null>(
            async (flags) => {
                await axios.put<void>(
                    `/adminSellers/${sellerId}/confidenceFlag`,
                    flags
                );
            },
            ({
                onSuccess: (/* flag: number */) => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old: ISellerDetailsQueryResult | undefined) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                certif: {
                                    solvaConfidenceFlag: newSolvaConfidenceFlag,
                                    fraudConfidenceFlag: newFraudConfidenceFlag,
                                    dateTime: serverDateSerialization(new Date())
                                }
                            }
                        })
                    );

                    queryClient.setQueryData<ISellerForAddInvoiceQueryResult>(
                        sellerForAddInvoiceQueryKey(sellerId),
                        (old: ISellerForAddInvoiceQueryResult | undefined) => !!old ? ({
                            ...old!,
                            sellerDetails: {
                                ...old!.sellerDetails,
                                certif: {
                                    solvaConfidenceFlag: newSolvaConfidenceFlag,
                                    fraudConfidenceFlag: newFraudConfidenceFlag,
                                    dateTime: serverDateSerialization(new Date())
                                }
                            },
                        }) : undefined
                    );

                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId),
                        (old: ICustomerDetailsQueryResult | undefined) => !!old ? ({
                            customerDetails: {
                                ...old.customerDetails,
                                marketplace: !!old.customerDetails.marketplace ? {
                                    ...old.customerDetails.marketplace,
                                    hasCertifiedSellers: true
                                } : null,
                                directSeller: !!old.customerDetails.directSeller ? {
                                    ...old.customerDetails.directSeller,
                                    seller: {
                                        ...old.customerDetails.directSeller.seller,
                                        certif: {
                                            dateTime: serverDateSerialization(new Date())
                                        }
                                    }
                                } : null
                            }
                        }) : undefined
                    );

                    queryClient.setQueryData<ISellersQueryResult>(
                        sellersQueryKey,
                        (old: ISellersQueryResult | undefined) => !!old ? ({
                            sellers: old.sellers.map(seller =>
                                seller.id === sellerId
                                    ? ({
                                        ...seller,
                                        certif: {
                                            solvaConfidenceFlag: newSolvaConfidenceFlag,
                                            fraudConfidenceFlag: newFraudConfidenceFlag
                                        }
                                    }) : seller
                            )
                        }) : undefined
                    );

                    queryClient.setQueriesData<IInvoiceDetailsQueryResult>(
                        InvoiceDetailsQueryKeyPrefix,
                        (old) => old?.invoiceDetails.seller.id === sellerId
                            ? ({
                                ...old,
                                invoiceDetails: {
                                    ...old.invoiceDetails,
                                    seller: {
                                        ...old.invoiceDetails.seller,
                                        certif: {
                                            solvaConfidenceFlag: newSolvaConfidenceFlag,
                                            fraudConfidenceFlag: newFraudConfidenceFlag
                                        }
                                    }
                                }
                            }) : old
                    );

                    queryClient.setQueriesData<IPreInvoiceDetailsQueryResult>(
                        preInvoiceDetailsPrefix,
                        (old) => old?.preInvoiceDetails.email.matchingSeller?.id === sellerId
                            ? ({
                                ...old,
                                preInvoiceDetails: {
                                    ...old.preInvoiceDetails,
                                    email: {
                                        ...old.preInvoiceDetails.email,
                                        matchingSeller: {
                                            ...old.preInvoiceDetails.email.matchingSeller,
                                            certif: {
                                                solvaConfidenceFlag: newSolvaConfidenceFlag,
                                                fraudConfidenceFlag: newFraudConfidenceFlag
                                            }
                                        }
                                    }
                                }
                            }) : old
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled<void, IFlags> id='setConfidenceFlagButton'
                                             className={className}
                                             kind={kind}
                                             size={size}
                                             text={text}
                                             mutation={setConfidenceFlagMutation}
                                             displayFullError={true}
                                             value={{
                                                 solvaConfidenceFlag: newSolvaConfidenceFlag,
                                                 fraudConfidenceFlag: newFraudConfidenceFlag
                                             }} />
    );
}

export const CONFIDENCE_NOT_SET = 'Not Set'; // A status specific to this component

const ConfidenceFlagSelect = (
    {
        className,
        label,
        canManageProfiles,
        confidenceFlag,
        setConfidenceFlag
    }: {
        className?: string;
        label: string;
        canManageProfiles: boolean;
        confidenceFlag: ConfidenceFlag | null;
        setConfidenceFlag: (newValue: ConfidenceFlag) => void;
    }
) => {
    const options: IOption<ConfidenceFlag>[] =
        Object.entries(ConfidenceFlag).map(([label, value]) => ({
            label: label,
            value: value,
            color: CONFIDENCE_FLAGS_COLORS[value]
        }));

    return (
        <div className={`p-horizontal-center${!!className ? ` ${className}` : ''}`}>
            <span className='p-margin-right-6'>{label} :</span>

            <SingleSelect options={options}
                          selectedOption={!!confidenceFlag
                              ? options.find((o) => o.value === confidenceFlag)!
                              : null
                          }
                          selectOption={!canManageProfiles
                              ? (newOption) => {
                                  if (!!newOption) {
                                      setConfidenceFlag(newOption.value);
                                  }
                              }
                              : null
                          }
                          placeholder='Not Set'
                          clearable={false}
                          searchable={false} />
        </div>
    );
}

const SellerConfidenceFlags = (
    {
        axios,
        authUser,
        sellerId,
        customerId,
        hasIbans,
        currentCertif,
        isCompanyValid
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        sellerId: string;
        customerId: string;
        hasIbans: boolean;
        currentCertif: Certif;
        isCompanyValid: boolean;
    }
) => {
    const [solvaConfidenceFlag, setSolvaConfidenceFlag] = useState<ConfidenceFlag | null>(currentCertif?.solvaConfidenceFlag || null);
    const [fraudConfidenceFlag, setFraudConfidenceFlag] = useState<ConfidenceFlag | null>(currentCertif?.fraudConfidenceFlag || null);

    let errors: string[] = [];
    if (!hasIbans) {
        errors = [
            ...errors,
            `L'IBAN du seller et l'IBAN du billing de la plateforme doivent être présents`
        ];
    }

    if (!isCompanyValid) {
        errors = [
            ...errors,
            `Il manque des informations (nom et/ou élément d'adresse(s)) à la société`
        ];
    }

    return (
        <div className='p-horizontal-center p-margin-top-4'>
            <span className='p-bold'>Statut de certification</span>

            <ConfidenceFlagSelect className='p-margin-left-5'
                                  canManageProfiles={!authUser?.canManageProfiles}
                                  label='Solva'
                                  confidenceFlag={solvaConfidenceFlag}
                                  setConfidenceFlag={setSolvaConfidenceFlag}/>

            <ConfidenceFlagSelect className='p-margin-left-5'
                                  canManageProfiles={!authUser?.canManageProfiles}
                                  label='Fraud'
                                  confidenceFlag={fraudConfidenceFlag}
                                  setConfidenceFlag={setFraudConfidenceFlag}/>

            { !!currentCertif &&
                <div className='p-vertical-center p-margin-left-5'>
                    <span className='p-bold'>Date :</span>
                    <span>{ formatAdminDateStr(currentCertif.dateTime) }</span>
                </div>
            }

            { authUser?.canManageProfiles &&
                <div className='p-margin-left-5'>
                    { errors.length === 0
                        ? (
                            !!solvaConfidenceFlag &&
                            !!fraudConfidenceFlag && (

                                (/* First certification */ !currentCertif
                                    // No specific extra check
                                ) || (
                                    /* update certification */ !!currentCertif &&
                                    (solvaConfidenceFlag !== currentCertif.solvaConfidenceFlag || fraudConfidenceFlag !== currentCertif.fraudConfidenceFlag)
                                )
                            )
                                ? <UpdateConfidenceFlagEnabled axios={axios}
                                                               sellerId={sellerId}
                                                               customerId={customerId}
                                                               newSolvaConfidenceFlag={solvaConfidenceFlag}
                                                               newFraudConfidenceFlag={fraudConfidenceFlag}
                                                               kind={UpdateCertifyButtonKind}
                                                               size={UpdateCertifyButtonSize}
                                                               text={UpdateButtonText} />
                                : <ButtonDisabled kind={UpdateCertifyButtonKind}
                                                  size={UpdateCertifyButtonSize}
                                                  text={UpdateButtonText} />
                        ) : <ShowValidationErrorsButton headerText='Des informations manquent pour la certification'
                                                        validationErrors={errors}
                                                        kind={UpdateCertifyButtonKind}
                                                        size={UpdateCertifyButtonSize}
                                                        text={CertifyButtonText} />
                    }
                </div>
            }
        </div>
    );
}

export default SellerConfidenceFlags;
