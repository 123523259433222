import { ISwanIncomingPayment } from "../SwanIncomingPayments/swanIncomingPaymentsQuery";

import {
    ICustomerFinancingRequestAcceptationStatus,
    ICustomerFinancingRequestAcceptedStatus,
    IFinancingStatus,
    IInvoiceStatus
} from "../Invoices/invoiceStatus";

export interface ISwanIncomingPaymentDetailsInvoiceStatus extends IInvoiceStatus<
    ISwanIncomingPaymentDetailsInvoiceFinancingRequestStatus,
    ICustomerFinancingRequestAcceptationStatus<IFinancingStatus>,
    IFinancingStatus
> {

}

export interface ISwanIncomingPaymentDetailsInvoiceFinancingRequestStatus extends ICustomerFinancingRequestAcceptedStatus<
    ICustomerFinancingRequestAcceptationStatus<IFinancingStatus>,
    IFinancingStatus
> {

}

export interface ISwanIncomingPaymentDetailsInvoice {
    id: string;
    number: string;
    dueDate: string;
    amountWoTax: number;
    amountTax: number;
    buyer: {
        companyId: string;
    };
    sellerId: string;
    status: ISwanIncomingPaymentDetailsInvoiceStatus;
}

export interface ISwanIncomingPaymentDetailsInvoicePayment {
    completePaidDate: string | null;
    partialAmountPaid: number;
}

export interface ISwanIncomingPaymentDetailsMatchedInvoice extends ISwanIncomingPaymentDetailsInvoice {
    payment: ISwanIncomingPaymentDetailsInvoicePayment;
}

// all candidate invoices are not complete paid
export interface ISwanIncomingPaymentDetailsCandidateInvoice extends ISwanIncomingPaymentDetailsInvoice {
    payment: {
        partialAmountPaid: number;
    };
}

export interface ISwanIncomingPaymentDetailsInvoiceCompany {
    id: string;
    name: string;
}

export interface ISwanIncomingPaymentDetailsInvoiceSeller {
    id: string;
    customer: {
        directSeller: {
            id: string;
        }
    }
    company: {
        name: string;
    }
}

export interface ISwanIncomingPaymentDetails extends ISwanIncomingPayment {
    // nothing for now
}

export interface ISwanIncomingPaymentDetailsMatch {
    invoiceId: string;
    amount: number;
    isSentToCustomer: boolean;
    isComplete: boolean;
}

export const swanIncomingPaymentDetailsQueryKey = (swanIncomingPaymentId: string) =>
    ['swanIncomingPaymentDetails', swanIncomingPaymentId];

export interface ISwanIncomingPaymentDetailsQueryResult {
    swanIncomingPaymentDetails: ISwanIncomingPayment;
    invoices: {
        base: ISwanIncomingPaymentDetailsMatchedInvoice[];
        buyerCompanies: ISwanIncomingPaymentDetailsInvoiceCompany[];
        sellers: Array<{
            id: string;
            company: {
                name: string;
            }
        }>;
    }
    swanIncomingPaymentMatches: ISwanIncomingPaymentDetailsMatch[];
}

export interface ISwanIncomingPaymentDetailsCandidateInvoicesQueryResult {
    invoices: {
        base: ISwanIncomingPaymentDetailsCandidateInvoice[];
        buyerCompanies: ISwanIncomingPaymentDetailsInvoiceCompany[];
        sellers: ISwanIncomingPaymentDetailsInvoiceSeller[];
    }
}

export const swanIncomingPaymentDetailsCandidateInvoicesQueryKey= (swanIncomingPaymentId: string) =>
    ['swanIncomingPaymentDetails', swanIncomingPaymentId, 'candidate-invoices'];
