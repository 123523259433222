import { Axios } from "axios";
import { sum } from "factor-lib/utils/utils";
import { QueryClient } from "@tanstack/react-query";
import {
    ISwanIncomingPaymentDetailsCandidateInvoicesQueryResult, ISwanIncomingPaymentDetailsMatchedInvoice,
    ISwanIncomingPaymentDetailsQueryResult,
    swanIncomingPaymentDetailsCandidateInvoicesQueryKey,
    swanIncomingPaymentDetailsQueryKey
} from "../swanIncomingPaymentDetailsQuery";
import { ISwanIncomingPaymentsQueryResult, SwanIncomingPaymentsQueryKey } from "../../SwanIncomingPayments/swanIncomingPaymentsQuery";
import { INewInvoiceMatchAmountValidated } from "../SwanIncomingPaymentDetailsInvoices/invoiceMatch";
import { swanRedirect } from "../../../utils/swanUtils";
import { sellerActiveFinancingsQueryKey, sellerSettledFinancingsQueryKey } from "../../Sellers/SellerTabs";
import { buyerActiveFinancingsQueryKey, buyerSettledFinancingsQueryKey } from "../../Buyers/BuyerTabs";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import CurrentEnv from "../../../envs/CurrentEnv";

interface IMatchSummary {
    newAmountToMatch: number;
    amountToSendToCustomer: number;
    newAmountRemaining: number;
}

export interface INewInvoiceMatches {
    amounts: Map<string, INewInvoiceMatchAmountValidated>;
    total: number;
}

export interface IMatchOption {
    id: string;
    description: string;
    cannotSelectReason: (newInvoiceMatches: INewInvoiceMatches | null /* null -> flagged no match */) => string | null;
    summary: (oldPaymentAmountRemaining: number, newInvoiceMatches: INewInvoiceMatches | null) => IMatchSummary;
    action: (axios: Axios, queryClient: QueryClient, swanIncomingPaymentId: string, oldPaymentAmountRemaining: number, newInvoiceMatches: INewInvoiceMatches | null) => Promise<void>;
    updateQueries?: (queryClient: QueryClient, swanIncomingPaymentId: string, newInvoiceMatches: INewInvoiceMatches | null) => void;
}

const NoMatchFlaggedReason = 'flaggé pour un no match';

export const ClassicMatchFinancedOption: IMatchOption = ({
    id: 'classic-financed',
    description: 'Match Classique Financés',
    cannotSelectReason: (newInvoiceMatches) =>
        !newInvoiceMatches
            ? NoMatchFlaggedReason
            : newInvoiceMatches.amounts.size <= 0
                ? `aucune facture n'est selectionnée`
                : Array.from(newInvoiceMatches.amounts.values()).some((m) => !m.isFinanced)
                    ? `des factures non financées sont selectionnées`
                    : null,
    summary: (oldPaymentAmountRemaining, newInvoiceMatches) => ({
        newAmountToMatch: newInvoiceMatches!.total,
        amountToSendToCustomer: 0,
        newAmountRemaining: oldPaymentAmountRemaining - newInvoiceMatches!.total
    }),
    action: async (axios, _, swanIncomingPaymentId, _2, newInvoiceMatches) => {
        await axios.post<void>(
            `adminSwanIncomingPayments/${swanIncomingPaymentId}/match-financed`,
            {
                invoiceMatches: Array.from(newInvoiceMatches!.amounts.entries())
                    .map(([invoiceId, matchAmount]) => ({
                        invoiceId,
                        isComplete: matchAmount.isComplete,
                        amount: matchAmount.amount
                    }))
            }
        );
    },
    updateQueries: (queryClient, swanIncomingPaymentId, newInvoiceMatches) => {
        matchInvoicesUpdateQueries(queryClient, swanIncomingPaymentId, newInvoiceMatches!);
    }
});

export const ClassicMatchNotFinancedOption: IMatchOption = ({
    id: 'classic-not-financed',
    description: 'Match Classique Non Financés',
    cannotSelectReason: (newInvoiceMatches) =>
        !newInvoiceMatches 
            ? NoMatchFlaggedReason
            : newInvoiceMatches.amounts.size <= 0
                ? `aucune facture n'est selectionnée`
                : Array.from(newInvoiceMatches.amounts.values()).some((m) => m.isFinanced)
                    ? `des factures financées sont selectionnées`
                    : null,
    summary: (oldPaymentAmountRemaining, newInvoiceMatches) => ({
        newAmountToMatch: newInvoiceMatches!.total,
        amountToSendToCustomer: newInvoiceMatches!.total,
        newAmountRemaining: oldPaymentAmountRemaining - newInvoiceMatches!.total
    }),
    action: async (_, queryClient, swanIncomingPaymentId, _2, newInvoiceMatches) => {
        swanRedirect(
            CurrentEnv.baseUrl,
            CurrentEnv.swanClientId,
            (axios, swanAuthorizationCode) => axios.post<string>(
                `adminSwanIncomingPayments/${swanIncomingPaymentId}/init-match-not-financed`,
                {
                    swanAuthorizationCode: swanAuthorizationCode,
                    invoiceMatches: Array.from(newInvoiceMatches!.amounts.entries())
                        .map((e) => ({
                            invoiceId: e[0],
                            isComplete: e[1].isComplete,
                            amount: e[1].amount
                        }))
                }
            ),
            (axios, consentId) => axios.put<void>(
                `adminSwanIncomingPayments/${consentId}/validate-match-not-financed`
            ),
            () => {
                matchInvoicesUpdateQueries(queryClient, swanIncomingPaymentId, newInvoiceMatches!);
            },
            (axios, consentId) => axios.put<void>(
                `adminSwanIncomingPayments/${consentId}/reject-match-not-financed`
            )
        );
    }
});

const NoMatchOption: IMatchOption = ({
    id: 'nomatch',
    description: 'No Match',
    cannotSelectReason: (newInvoiceMatches) =>
        !!newInvoiceMatches && newInvoiceMatches.amounts.size !== 0
            ? 'des factures sont selectionnées'
            : null,
    summary: (oldPaymentAmountRemaining) => ({
        newAmountToMatch: 0,
        amountToSendToCustomer: oldPaymentAmountRemaining,
        newAmountRemaining: 0
    }),
    action: async (_, queryClient, swanIncomingPaymentId, oldPaymentAmountRemaining) => {
        swanRedirect(
            CurrentEnv.baseUrl,
            CurrentEnv.swanClientId,
            (axios, swanAuthorizationCode) => axios.post<string>(
                `adminSwanIncomingPayments/${swanIncomingPaymentId}/init-no-match`,
                {
                    amount: oldPaymentAmountRemaining,
                    swanAuthorizationCode: swanAuthorizationCode,
                    ensureFlaggedNoMatch: false
                }
            ),
            (axios, consentId) => axios.put<void>(
                `adminSwanIncomingPayments/${consentId}/validate-no-match`
            ),
            () => {
                queryClient.setQueryData<ISwanIncomingPaymentDetailsQueryResult>(
                    swanIncomingPaymentDetailsQueryKey(swanIncomingPaymentId),
                    (old: ISwanIncomingPaymentDetailsQueryResult | undefined) => ({
                        ...old!,
                        swanIncomingPaymentDetails: {
                            ...old!.swanIncomingPaymentDetails,
                            noMatchDateTime: serverDateSerialization(new Date())
                        }
                    })
                );
        
                queryClient.setQueryData<ISwanIncomingPaymentsQueryResult>(
                    SwanIncomingPaymentsQueryKey,
                    (old) => !!old ? ({
                        ...old,
                        swanIncomingPayments: old.swanIncomingPayments
                            .map((s) => s.id === swanIncomingPaymentId
                                ? {
                                    ...s,
                                    noMatchDateTime: serverDateSerialization(new Date())
                                }
                                : s
                            ),
                    }) : undefined
                );
            },
            (axios, consentId) => axios.put<void>(
                `adminSwanIncomingPayments/${consentId}/reject-no-match`
            )
        );
    }
});

export const MatchOptions: IMatchOption[] = [
    ClassicMatchFinancedOption,
    ClassicMatchNotFinancedOption,
    NoMatchOption
];

const matchInvoicesUpdateQueries = (
    queryClient: QueryClient,
    swanIncomingPaymentId: string,
    newInvoiceMatches: INewInvoiceMatches,
) => {
    const candidateInvoicesQueryResult = queryClient.getQueryData<ISwanIncomingPaymentDetailsCandidateInvoicesQueryResult>(
        swanIncomingPaymentDetailsCandidateInvoicesQueryKey(swanIncomingPaymentId)
    );

    if (!candidateInvoicesQueryResult) {
        return Promise.all([
            queryClient.invalidateQueries(swanIncomingPaymentDetailsQueryKey(swanIncomingPaymentId)),
            queryClient.invalidateQueries(SwanIncomingPaymentsQueryKey)
        ]);
    } else {
        const newMatchedAmountTotal = sum(Array.from(newInvoiceMatches.amounts.values()).map((m) => m.amount));

        const newMatchedInvoices: ISwanIncomingPaymentDetailsMatchedInvoice[] = candidateInvoicesQueryResult.invoices.base
            .filter((i) => newInvoiceMatches.amounts.has(i.id))
            .map((i) => {
                const invoiceMatch = newInvoiceMatches.amounts.get(i.id)!;
                return {
                    ...i,
                    status: invoiceMatch.isComplete
                        ? !!i.status.customerFinancingRequest
                            ? !!i.status.customerFinancingRequest?.accepted?.financing
                                ? {
                                    ...i.status,
                                    customerFinancingRequest: {
                                        ...i.status.customerFinancingRequest,
                                        accepted: {
                                            ...i.status.customerFinancingRequest.accepted,
                                            financing: {
                                                ...i.status.customerFinancingRequest.accepted.financing,
                                                settledDateTime: serverDateSerialization(new Date())
                                            }
                                        }
                                    }
                                }
                                : i.status
                            : {
                                ...i.status,
                                eligibility: {
                                    successO: {
                                        isPending: false,
                                        rejectionReason: 'Complete paid' // not displayed
                                    },
                                    errorO: null
                                }
                            }
                        : i.status,
                    payment: {
                        completePaidDate: invoiceMatch.isComplete
                            ? serverDateSerialization(new Date())
                            : null,
                        partialAmountPaid: i.payment.partialAmountPaid + invoiceMatch.amount
                    }
                }
            });

        queryClient.setQueryData<ISwanIncomingPaymentDetailsQueryResult>(
            swanIncomingPaymentDetailsQueryKey(swanIncomingPaymentId),
            (old) => !!old ? ({
                swanIncomingPaymentDetails: {
                    ...old.swanIncomingPaymentDetails,
                    matchedAmount: old.swanIncomingPaymentDetails.matchedAmount + newMatchedAmountTotal
                },
                invoices: {
                    base: [
                        ...old.invoices.base.filter((i) => !newInvoiceMatches.amounts.has(i.id)),
                        ...newMatchedInvoices
                    ],
                    buyerCompanies: [
                        ...old.invoices.buyerCompanies,
                        ...candidateInvoicesQueryResult.invoices.buyerCompanies
                    ],
                    sellers: [
                        ...old.invoices.sellers
                    ]
                },
                swanIncomingPaymentMatches: [
                    ...old.swanIncomingPaymentMatches,
                    ...Array.from(newInvoiceMatches.amounts).map(([invoiceId, matchAmount]) => ({
                        invoiceId: invoiceId,
                        isSentToCustomer: !matchAmount.isFinanced,
                        isComplete: matchAmount.isComplete,
                        amount: matchAmount.amount
                    }))
                ]
            }) : undefined
        );

        queryClient.setQueryData<ISwanIncomingPaymentDetailsCandidateInvoicesQueryResult>(
            swanIncomingPaymentDetailsCandidateInvoicesQueryKey(swanIncomingPaymentId),
            (old) => !!old ? ({
                invoices: {
                    base: [
                        ...old.invoices.base.filter((i) => !newInvoiceMatches.amounts.has(i.id)),
                        ...newMatchedInvoices.filter((i) => i.payment.completePaidDate === null)
                    ],
                    buyerCompanies: old.invoices.buyerCompanies,// not a problem leaving it as is
                    sellers: old.invoices.sellers
                }
            }) : undefined
        );

        queryClient.setQueryData<ISwanIncomingPaymentsQueryResult>(
            SwanIncomingPaymentsQueryKey,
            (old) => !!old ? ({
                swanIncomingPayments: old.swanIncomingPayments
                    .map((s) => s.id === swanIncomingPaymentId
                        ? {
                            ...s,
                            matchedAmount: s.matchedAmount + newMatchedAmountTotal
                        }
                        : s
                    ),
                swanIncomingPaymentMatches: [
                    ...old.swanIncomingPaymentMatches,
                    ...newMatchedInvoices.map((i) => {
                        const invoiceMatch = newInvoiceMatches.amounts.get(i.id)!;
                        return ({
                            swanIncomingPaymentId: swanIncomingPaymentId,
                            invoiceId: i.id,
                            isSentToCustomer: !invoiceMatch.isFinanced,
                            isComplete: invoiceMatch.isComplete,
                            amount: invoiceMatch.amount
                        });
                    })
                ],
                invoices: {
                    base: [
                        ...old!.invoices.base.filter((i) => !newInvoiceMatches.amounts.has(i.id)),
                        ...newMatchedInvoices.map((i) => ({
                            id: i.id,
                            number: i.number
                        }))
                    ]
                }
            }) : undefined
        );

        // update buyer/seller financings tabs
        const settledInvoices = newMatchedInvoices.filter((i) => !!i.status.customerFinancingRequest?.accepted?.financing?.settled);

        const r: Array<Promise<void>> = [];

        // TODO: too complex to handle
        if (settledInvoices.length > 0) {
            new Set(settledInvoices.map((i) => i.sellerId)).forEach((sellerId) => {
                r.push(queryClient.invalidateQueries(sellerActiveFinancingsQueryKey(sellerId)));
                r.push(queryClient.invalidateQueries(sellerSettledFinancingsQueryKey(sellerId)));
            });

            new Set(settledInvoices.map((i) => i.buyer.companyId)).forEach((buyerCompanyId) => {
                r.push(queryClient.invalidateQueries(buyerActiveFinancingsQueryKey(buyerCompanyId)));
                r.push(queryClient.invalidateQueries(buyerSettledFinancingsQueryKey(buyerCompanyId)));
            });
        }

        return Promise.all(r);
    }
};
