import { Axios } from "axios";
import {useContext, useState} from "react";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import Button from "factor-lib/Buttons/Button";
import {NavigateFunction} from "react-router-dom";
import RadioBox from "factor-lib/forms/Radio/RadioBox";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import {addSellerForCustomerUrl} from "../sellersUrls";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import {formatCompany} from "../../../utils/companyUtils";
import {IPaginated, query} from "factor-lib/utils/graphQLPagination";
import graphQLQueryAsync from "factor-lib/serverUtils/graphQLQueryAsync";
import { IIntCompanyIdentifier, IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import {getFactorContext} from "../../../IFactorContext";
import {CustomerTypeMarketplace, CustomerTypeUnassigned} from "../../../utils/customersQueriesUtils";
import ExistingPaginatedSelection from "../../../utils/ExistingPaginatedSelection";

export interface ICustomerCanAddSeller {
    id: string;
    company: {
        identifier: IIntCompanyIdentifier;
        name: string;
    };
}

const NextText = 'Suivant';

const CustomersGraphQLQuery =
    `query ($after: PaginationGuidCursor, $search: String, $type: CustomerTypeEnum!) {
        customers(search: $search, types: [$type]) {
            list(
                first: 10,
                after: $after,
                ${/* orderByField: ${CustomerSortPropertyToOrderByField.get(CustomerSortProperty.Name)!},
                orderByDirection: ${SortOrderToOrderByDirection.get(SortOrder.Asc)!} */ '' }
            ) ${query(
                `
                    id
                    company { identifier { ${IntCompanyIdentifierGraphQLFields} } name }
                `
            )}
        }
    }`;

const AddSellerChooseCustomer = (
    {
        axios,
    }: {
        axios: Axios;
    }
) => {
    const [isDirectFilter, setIsDirectFilter] = useState(true);

    const graphQLFilter = isDirectFilter ? CustomerTypeUnassigned : CustomerTypeMarketplace;

    // const [customerOption, setCustomerOption] = useState<IOption<string> | null>(null);
    // const selectedCustomer: ICustomerCanAddSeller | null = (!!customerOption && customers.find((c) => c.id === customerOption.value)) || null;

    const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);

    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    return (
        <div className='container p-both-center p-margin-top-3'>
            <div style={{width: 600}}>
                <span className='title'>Choisir une plateforme pour l'ajout d'un Seller</span>

                <div className='box p-margin-top-4'>
                    <div className='level'>
                        <div className='level-item p-horizontal-center'>
                            <RadioBox id='directCustomerRadioBoxId'
                                      inputValue={isDirectFilter}
                                      setInputValue={() => {
                                          if (!isDirectFilter) {
                                              // setCustomerOption(null);
                                              setSelectedCustomerId(null);
                                          }
                                          setIsDirectFilter(true);
                                      }}
                                      enabled={true} />
                            <span className='p-margin-left-6'>Direct</span>
                        </div>

                        <div className='level-item p-horizontal-center'>
                            <RadioBox id='marketplaceCustomerRadioBoxId'
                                      inputValue={!isDirectFilter}
                                      setInputValue={() => {
                                          if (isDirectFilter) {
                                              // setCustomerOption(null);
                                              setSelectedCustomerId(null);
                                          }
                                          setIsDirectFilter(false);
                                      }}
                                      enabled={true} />
                            <span className='p-margin-left-6'>Marketplace</span>
                        </div>
                    </div>

                    <div className='p-margin-top-5'>
                        <ExistingPaginatedSelection<ICustomerCanAddSeller> setSelection={(selection: ICustomerCanAddSeller | null) => setSelectedCustomerId(!!selection ? selection.id : null)}
                                                                           formatter={(s) => formatCompany(s.company)}
                                                                           placeholder='Plateforme'
                                                                           queryKeyFactory={(searchInputStr) => ['customers', 'add-seller', 'type', graphQLFilter, 'search', searchInputStr]}
                                                                           queryFn={async (signal: AbortSignal | undefined, pageParam: any | null, searchInputStr: string) =>
                                                                               (await graphQLQueryAsync<any>(
                                                                                   axios,
                                                                                   {
                                                                                       query: CustomersGraphQLQuery,
                                                                                       variables: {
                                                                                           search: searchInputStr,
                                                                                           after: pageParam,
                                                                                           type: graphQLFilter
                                                                                       }
                                                                                   },
                                                                                   getFactorContext().logger,
                                                                                   ['customers', 'add-seller', 'type', graphQLFilter, 'search', searchInputStr],
                                                                                   signal
                                                                               )).customers.list as IPaginated<ICustomerCanAddSeller>
                                                                           } />
                    </div>

                    <div className='p-margin-top-4 p-vertical-center'>
                        { !!selectedCustomerId
                            ? <Button id='addSellerChooseCustomerNext'
                                      text={NextText}
                                      isLoading={false}
                                      actionO={() => navigate(addSellerForCustomerUrl(selectedCustomerId))}/>

                            : <ButtonDisabled kind={KIND_PRIMARY}
                                              size={SIZE_FIXED}
                                              text={NextText} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddSellerChooseCustomer;
