import {
    IPendingPreInvoiceEmail,
    IPreInvoice,
    IPreInvoicesQueryResult,
    preInvoicesQueryKey
} from "../../PreInvoices/preInvoicesQuery";
import {QueryClient} from "@tanstack/react-query";
import {IPreInvoiceDetails} from "../PreInvoiceDetailsQuery";

const removeInPending = (queryResult: IPreInvoicesQueryResult, preInvoiceId: string): Array<IPreInvoice<IPendingPreInvoiceEmail>> =>
    queryResult.preInvoicesPending.base.filter((p) => p.id !== preInvoiceId);

const updatePreInvoicesQueries = (
    queryClient: QueryClient,
    preInvoiceId: string,
    preInvoice: IPreInvoiceDetails,
    newState: string
) => {
    const withArchivedQueryKey = preInvoicesQueryKey(true);
    const withArchivedQueryResult: IPreInvoicesQueryResult | undefined = queryClient.getQueryData<IPreInvoicesQueryResult>(withArchivedQueryKey);
    if (!!withArchivedQueryResult) {
        queryClient.setQueryData<IPreInvoicesQueryResult>(
            withArchivedQueryKey,
            () => ({
                preInvoicesPending: {
                    ...withArchivedQueryResult.preInvoicesPending, // doesnt hurt keeping emails
                    base: removeInPending(withArchivedQueryResult, preInvoiceId)
                },
                preInvoicesArchived: {
                    ...withArchivedQueryResult.preInvoicesArchived!, // doesnt hurt keeping emails
                    base: [
                        ...withArchivedQueryResult.preInvoicesArchived!.base,
                        {
                            ...preInvoice,
                            id: preInvoiceId,
                            status: newState
                        }
                    ],
                    recipientEmails: withArchivedQueryResult.preInvoicesArchived!.recipientEmails.some((e) => e.preInvoiceEmailId === preInvoice.email.id)
                        ? withArchivedQueryResult.preInvoicesArchived!.recipientEmails
                        : [
                            ...withArchivedQueryResult.preInvoicesArchived!.recipientEmails,
                            ...withArchivedQueryResult.preInvoicesPending.recipientEmails.filter((e) => e.preInvoiceEmailId === preInvoice.email.id)
                        ]
                }
            })
        );
    } // else was not loaded : nothing to update

    const pendingOnlyQueryKey = preInvoicesQueryKey(false);
    const pendingOnlyQueryResult: IPreInvoicesQueryResult | undefined = queryClient.getQueryData<IPreInvoicesQueryResult>(pendingOnlyQueryKey);
    if (!!pendingOnlyQueryResult) {
        queryClient.setQueryData<IPreInvoicesQueryResult>(
            pendingOnlyQueryKey,
            () => ({
                ...pendingOnlyQueryResult,
                preInvoicesPending: {
                    ...pendingOnlyQueryResult.preInvoicesPending, // doesnt hurt keeping emails
                    base: removeInPending(pendingOnlyQueryResult, preInvoiceId)
                }
            })
        );
    }  // else was not loaded : nothing to update
}

export default updatePreInvoicesQueries;