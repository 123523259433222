import { Axios } from "axios";
import Modal from "factor-lib/Modal";
import { IExistingBuyer } from "./BuyerLimitsPage";
import InputAmount, { formatAmountToInput, isValidAmount, parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import {
    allBuyersWithLimitQueryKey,
    buyersWithLimitSearch,
    IBuyerCompaniesWithLimitQueryResult
} from "./BuyerLimitsQuery";
import { useState } from "react";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import { companyMatchesSelection, ICompanySelection } from "factor-lib/Company/ICompanySelection";
import CompanySelector from "../../utils/Company/CompanySelector";
import { PlaceHolderSearchBuyer } from "../../utils/Company/CompanySearcher";

const DefaultBuyerLimitInput = 20_000_00;

const UpdateText = 'Modifier';

interface IUpdateBuyerLimitParams {
    buyerSelection: ICompanySelection;
    buyerLimit: number;
}

export interface IUpdateBuyerLimitResponse {
    identifier: IIntCompanyIdentifier;
    successBuyerInfosO: {
        id: string;
        name: string;
        outstanding: number;
    } | null;
}

const updateBatchLimits = (
    old: IBuyerCompaniesWithLimitQueryResult,
    updateParams: IUpdateBuyerLimitParams,
    response: IUpdateBuyerLimitResponse
): IBuyerCompaniesWithLimitQueryResult => {
    const companySelection = updateParams.buyerSelection;
    const existingCompany = old!.companies.find((c) => companyMatchesSelection(c, companySelection));
    return ({
        companies: !!existingCompany
            ? [
                {
                    ...existingCompany,
                    asBuyer: {
                        ...existingCompany.asBuyer,
                        manualOutstandingLimit: updateParams.buyerLimit
                    }
                },
                ...old!.companies.filter((c) => c.id !== existingCompany.id)
            ]
            : !!response.successBuyerInfosO
                ? [
                    {
                        id: response.successBuyerInfosO.id,
                        name: response.successBuyerInfosO.name,
                        identifier: response.identifier,
                        asBuyer: {
                            manualOutstandingLimit: updateParams.buyerLimit,
                            outstanding: response.successBuyerInfosO.outstanding
                        }
                    },
                    ...old!.companies
                ]
                : old!.companies
    });
}

const BuyerLimitsModalContent = (
    {
        axios,
        initialBuyerCompany,
        closeModal
    }: {
        axios: Axios;
        initialBuyerCompany: IExistingBuyer | null;
        closeModal: () => void;
    }
) => {
    const [ buyerCompanySearchInput, setBuyerCompanySearchInput ] = useState('');
    const [ buyerSelection, setBuyerSelection ] = useState<ICompanySelection | null>(!!initialBuyerCompany ? { id: initialBuyerCompany.id, siren: null } : null);

    const [ limitInput, setLimitInput ] = useState(formatAmountToInput(initialBuyerCompany?.existingLimit ?? DefaultBuyerLimitInput));

    const queryClient = useQueryClient();

    const mutation: UseMutationResult<IUpdateBuyerLimitResponse, any, IUpdateBuyerLimitParams> =
        useMutation<IUpdateBuyerLimitResponse, any, IUpdateBuyerLimitParams>(
            async (updateParams) =>
                await axios.put<IUpdateBuyerLimitParams, IUpdateBuyerLimitResponse>(
                    `/adminCompaniesOutstandingsLimitsAsBuyer`, // On devrait avoir un controller specifique aux limites buyers
                    updateParams
                ),
            ({
                onSuccess: (response, updateParams) => {
                    const r = queryClient.invalidateQueries(buyersWithLimitSearch);
                    queryClient.setQueryData<IBuyerCompaniesWithLimitQueryResult>(
                        allBuyersWithLimitQueryKey,
                        (old: IBuyerCompaniesWithLimitQueryResult | undefined) => old!! ? updateBatchLimits(old, updateParams, response) : undefined
                    );
                    closeModal();

                    return r;
                }
            })
        );

    const limitInputParsed: number | null = isValidAmount(limitInput) ? parseInputAmount(limitInput) : null;

    const validParamsO: IUpdateBuyerLimitParams | null =
        limitInputParsed !== null && !!buyerSelection
            ? {
                buyerSelection,
                buyerLimit: limitInputParsed
            } 
            : null

    return (
        <div className='container p-both-center p-padding-4'>
            <div style={{width: '600px'}}>
                <div className='title p-vertical-center'>
                    Ajouter/Modifier la limite d'un buyer
                </div>

                <CompanySelector axios={axios}
                                 searchPlaceHolder={PlaceHolderSearchBuyer}
                                 searchInput={buyerCompanySearchInput}
                                 setSearchInput={setBuyerCompanySearchInput}
                                 initialDisplaySearch={!initialBuyerCompany}
                                 selection={buyerSelection}
                                 setSelection={setBuyerSelection}
                                 autofocus={!initialBuyerCompany} />

                <div className='p-margin-top-5'>
                    <span className='p-bold'>Limite Encours TTC</span>

                    <InputAmount inputAmountValue={limitInput}
                                 placeHolder='Limite Encours TTC'
                                 enabled={{
                                     updateInputValue: setLimitInput,
                                     innerId: {
                                         value: 'innerLimitId',
                                         autofocus: !!initialBuyerCompany
                                     },
                                     tryComplete: () => {
                                         if (!!validParamsO) {
                                             mutation.mutate(validParamsO)
                                         }
                                     }
                                 }} />

                </div>

                <div className='p-margin-top-4 p-vertical-center'>
                    { !!validParamsO
                        ? <ButtonMutationEnabled id='updateBuyerLimitButton'
                                                 kind={KIND_PRIMARY}
                                                 size={SIZE_FIXED}
                                                 text={UpdateText}
                                                 mutation={mutation}
                                                 displayFullError={true}
                                                 value={validParamsO} />

                        : <ButtonDisabled kind={KIND_PRIMARY}
                                          size={SIZE_FIXED}
                                          text={UpdateText} />
                    }
                </div>
            </div>
        </div>
    );
}

const BuyerLimitsModal = (
    {
        axios,
        initialBuyerCompany,
        closeModal
    } : {
        axios: Axios;
        initialBuyerCompany: IExistingBuyer | null;
        closeModal: () => void;
    }
) =>
    <Modal id='buyerLimitsModal'
            maxWidth={null}
            fullMaxWidth={false}
            close={closeModal}>;
        <BuyerLimitsModalContent axios={axios}
                                 initialBuyerCompany={initialBuyerCompany}
                                 closeModal={closeModal} />
    </Modal>

export default BuyerLimitsModal;
