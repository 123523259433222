import { useEffect } from "react";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import ButtonMutationForModal from "factor-lib/Buttons/ButtonForModal/ButtonMutationForModal";
import { Axios } from "axios";
import BankinAccountSummary from "./BankinAccountSummary";
import {useMutation} from "@tanstack/react-query";

const DOWNLOAD_ITEMS_ID = 'downloadItems';

interface IBankinAccountSummaryResponse {
    success: IBankinAccountSummary | null;
    error: string | null;
}

export interface IBankinAccountSummary {
    account: IBankinAccountDetails;
    bank: IBankinBank;
}

export interface IBankinAccountDetails {
    id: number;
    item_id: number;
    name: string;
    type: string;
    updated_at: string | null;
    is_pro: boolean;

    balance: number;
    currency_code: string;

    status: number;
    status_code_info: string | null;
    status_code_description: string | null;

    iban: string | null;
    loan_details: IBankinAccountLoanDetails | null;
}

export interface IBankinBank {
    name: string;
    country_code: string;
}

export interface IBankinAccountLoanDetails {
    type: string | null;

    opening_date: string | null;
    maturity_date: string | null;

    borrowed_capital: number | null;
    repaid_capital: number | null;
    remaining_capital: number | null;
    interest_rate: number | null;
    total_estimated_interests: number | null;

    next_payment_date: string | null;
    next_payment_amount: number | null;
}

const GetSummaryModalContent = (
    {
        bankinAccountsSummary,
        sellerIbanO
    }: {
        bankinAccountsSummary: IBankinAccountSummaryResponse[];
        sellerIbanO: string | null;
    }
) =>
    <div className='p-padding-3'>
        { bankinAccountsSummary.map((summary, index) => (
            <div key={`div-${index}`} className='box p-margin-bottom-5'>
                { !!summary.error
                    ? <div className='p-bold'> {summary.error} </div>
                    : <BankinAccountSummary index={index}
                                            summary={summary.success!}
                                            sellerIbanO={sellerIbanO}/>
                }
            </div>
        ))}
    </div>;

const GetSummary = (
    {
        axios,
        customerId,
        sellerIbanO
    }: {
        axios: Axios;
        customerId: string;
        sellerIbanO: string | null;
    }
) =>
    <ButtonMutationForModal<IBankinAccountSummaryResponse[], string> id='getBankinSummary'
                                                                     text='Résumé des comptes'
                                                                     mutation={useMutation<IBankinAccountSummaryResponse[], any, string>(
                                                                         async (customerId2) =>
                                                                             (await axios.get<IBankinAccountSummaryResponse[]>(
                                                                                 `/adminBankin/${customerId2}/summary`
                                                                             )).data
                                                                     )}
                                                                     value={customerId}
                                                                     displayFullError={true}
                                                                     resultModalNode={(bankinAccountsSummary) =>
                                                                         <GetSummaryModalContent bankinAccountsSummary={bankinAccountsSummary}
                                                                                                 sellerIbanO={sellerIbanO} />
                                                                     }/>;

const DownloadTransactions = (
    {
        className,
        axios,
        customerId
    }: {
        className?: string;
        axios: Axios;
        customerId: string;
    }
) => {

    const downloadTransactionsMutation = useMutation<string, any, null>(
        async () => (await axios.get<string>(
            `/adminBankin/transactionsKey`
        )).data
    );

    const bankinTransactionKey = downloadTransactionsMutation.data;

    useEffect(() => {
        if (!!bankinTransactionKey) {
            (document.getElementById(DOWNLOAD_ITEMS_ID) as HTMLAnchorElement).click();
        }
    }, [bankinTransactionKey]);

    return (
        <div className={className}>
            <ButtonMutationEnabled id='downloadBankinTransactions'
                                   text='Download Transactions'
                                   mutation={downloadTransactionsMutation}
                                   value={null}
                                   displayFullError={true}/>

            <a style={{visibility: 'hidden'}}
               id={DOWNLOAD_ITEMS_ID}
               href={!!bankinTransactionKey
                   ? `${axios.defaults.baseURL}/adminBankin/transactions/${customerId}/${bankinTransactionKey}`
                   : undefined
               }/>
        </div>
    )
}

const BankinConnected = (
    {
        axios,
        customerId,
        sellerIbanO
    }: {
        axios: Axios;
        customerId: string;
        sellerIbanO: string | null;
    }
) =>
    <div className='p-horizontal-center p-margin-left-6'>
        <GetSummary axios={axios}
                    customerId={customerId}
                    sellerIbanO={sellerIbanO} />
        <DownloadTransactions className='p-margin-left-6'
                              axios={axios}
                              customerId={customerId} />
    </div>;

export default BankinConnected;
