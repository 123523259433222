
import IAccessToken from "../../../IAccessToken";
import TopNewBilling from "./TopNewBilling";
import BillingsWithAxios from "./BillingsWithAxios";
import BillingsTableLoading from "./BillingsTableLoading";


const BillingsPage =
(
    {
        accessTokenO
    }: {
        accessTokenO: IAccessToken | null
    }
) =>
    <section>
        <div className='container'>
            { accessTokenO?.authUserO?.canManageFinancings &&
                <TopNewBilling />
            }
            { !!accessTokenO?.axios
                ? <BillingsWithAxios canManageBillingsO={accessTokenO.authUserO?.canManageFinancings /* For now : this role */ || null}
                                     axios={accessTokenO.axios} />
                : <BillingsTableLoading/>
            }
        </div>
    </section>;

export default BillingsPage;