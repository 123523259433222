import { useState } from "react";
import Button from "factor-lib/Buttons/Button";
import RadioBox from "factor-lib/forms/Radio/RadioBox";
import {IInvoiceDetails} from "../../../invoiceDetailsQuery";
import IRecupSelection from "./IRecupSelection";
import { formatAmount } from "factor-lib/utils/amountUtils";
import {getPaymentAmount} from "../../../../../utils/paymentUtils";

export interface IDirectRecuperationType {
    label: string;
    filter: (i: IInvoiceDetails) => boolean;
    extraValidationMessageOProvider: (invoice: IInvoiceDetails, partialAmountInput: number | null) => string | null;
}

const computeAmountRemainingDueByBuyer = (i: IInvoiceDetails) =>
    (i.amountWoTax + i.amountTax) - i.payment.partialAmountPaidByBuyer;

export const directRecuperationTypeArray: Array<IDirectRecuperationType> = [
    {
        label: 'Paiement direct (commingling)',
        filter: (i: IInvoiceDetails) => computeAmountRemainingDueByBuyer(i) > 0,
        extraValidationMessageOProvider: (i: IInvoiceDetails, partialAmountInput: number | null) => {
            const paymentAmount: number = getPaymentAmount(partialAmountInput, i.amountWoTax + i.amountTax, i.payment.partialAmountPaid);
            const amountRemainingDueByBuyer = computeAmountRemainingDueByBuyer(i);
            if (paymentAmount > amountRemainingDueByBuyer) {
                return `Le montant ne peut dépasser le reste à payer par l'acheteur (${formatAmount(amountRemainingDueByBuyer)})`;
            } // else
            return null;
        }
    }, // 1
    { label: 'Paiement sur compte de recup par le buyer', filter: () => true, extraValidationMessageOProvider: () => null }, // 2
    {
        label: 'Paiement sur compte de recup par le seller',
        filter: (i: IInvoiceDetails) => (i.status.customerFinancingRequest?.accepted?.financing?.commingLingAmountToReturn || 0)  > 0,
        extraValidationMessageOProvider: (i: IInvoiceDetails, partialAmountInput: number | null) => {
            const paymentAmount: number = getPaymentAmount(partialAmountInput, i.amountWoTax + i.amountTax, i.payment.partialAmountPaid);
            const commingLingAmountToReturn: number = i.status.customerFinancingRequest!.accepted!.financing!.commingLingAmountToReturn;
            if (paymentAmount > commingLingAmountToReturn) {
                return `Le montant ne peut dépasser le résidu en commingLing (${formatAmount(commingLingAmountToReturn)})`;
            } // else
            return null;
        }
    }, // 3
    { label: `Récupération par l'assureur (chez l'assureur)`, filter: () => true, extraValidationMessageOProvider: () => null }, // 4
    { label: `Paiement sur le compte de recup par l'assureur (recupération)`, filter: () => true, extraValidationMessageOProvider: () => null }, // 5
    { label: `Paiement sur le compte de recup par l'assureur (indemnisation)`, filter: () => true, extraValidationMessageOProvider: () => null }, // 6
    { label: 'Paiement direct avant financement (commingling)', filter: () => true, extraValidationMessageOProvider: () => null }, // 7
    { label: 'Paiement sur le compte de recup par Dimpl (indemnisation)', filter: () => true, extraValidationMessageOProvider: () => null }, // 8
];

export const ChoseRecuperationType = (
    {
        invoice,
        setRecuperationType
    } : {
        invoice: IInvoiceDetails;
        setRecuperationType: (recupSelection: IRecupSelection) => void;
    }
) => {
    const [chosenType, setChosenType] = useState<IRecupSelection | null>(null);

    return (
        <div>
            { directRecuperationTypeArray
                // The index should not rely on filter
                .map((directRecuperationType, index) => ({
                    directRecuperationType,
                    index
                }))
                .filter((d) => d.directRecuperationType.filter(invoice))
                .map((d) =>
                    <div className='p-padding-top-6 p-padding-left-4' key={`recuperationOption-${d.index}`}>
                        <div className='level-left'>
                            <RadioBox id={`recuperationOption-${d.index}`}
                                      inputValue={chosenType?.directRecuperationType === d.index + 1}
                                      setInputValue={() => setChosenType({
                                          directRecuperationType: d.index + 1,
                                          extraValidationMessageOProvider: d.directRecuperationType.extraValidationMessageOProvider
                                      })}
                                      enabled={true} />
                            <span className='p-margin-left-6'>{ d.directRecuperationType.label }</span>
                        </div>
                    </div>
                )
            }
            <div className='p-vertical-center p-margin-top-4'>
                <Button id='declareDirectRecuperation'
                        text='Choose type'
                        isLoading={false}
                        actionO={() => setRecuperationType(chosenType!)} />

            </div>
        </div>
    );
}
