import {Axios} from "axios";
import Loader from "factor-lib/Loader";
import GraphQLQueryWrapper from "../../../utils/GraphQLQueryWrapper";

// TODO : dans l'API data, pour éviter une dépendance sur notre axiosO ?
const CompanyIdentification = (
    {
        axiosO,
        siren
    }: {
        axiosO: Axios | null;
        siren: string;
    }
) =>
    <div className='title p-horizontal-center'>
        <div>{`Siren ${siren} -`}</div>
        <div className='p-margin-left-6'>
            { !!axiosO
                ? <GraphQLQueryWrapper queryKey={['company', 'name', siren]}
                                       queryParams={{
                                           query: `query Q($siren: String!) {
                                                companySearch(siren: $siren) {
                                                    name
                                                }
                                            }`,
                                           variables: {
                                               siren
                                           }
                                       }}
                                       axios={axiosO}
                                       onLoading={() => <Loader/>}
                                       onSuccess={(r: {company:  {name: string} | null}) =>
                                           <span>{r.company?.name || '<<Company not found>>'}</span>
                                       } />
                : <Loader/>
            }
        </div>
    </div>;

export default CompanyIdentification;
