import BuyerInfosTabLoaded from "./BuyerInfosTabLoaded";
import Loader from "factor-lib/Loader";
import {
    buyerCompanyDetailsQueryKey, IBuyerCompanyDetailsQueryResult, QueryStr
} from "./buyerCompanyDetailsQuery";
import IAccessTokenWithDataLoader from "../IAccessTokenWithDataLoader";
import {useQuery} from "@tanstack/react-query";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";

const BuyerInfosTabAxiosReady = (
    {
        buyerCompanyId,
        accessTokenWithDataLoader
    }: {
        buyerCompanyId: string;
        accessTokenWithDataLoader: IAccessTokenWithDataLoader;
    }
) => {

    const queryKey = buyerCompanyDetailsQueryKey(buyerCompanyId);

    const query = useQuery<IBuyerCompanyDetailsQueryResult>(
        queryKey,
        async ({ signal }) =>
            (await accessTokenWithDataLoader.graphQLDataLoader.load({
                query: ({
                    query: QueryStr,
                    variables: {
                        buyerCompanyId
                    }
                }),
                queryKey,
                signal
            })) as IBuyerCompanyDetailsQueryResult
    );

    return (
        <ReactQueryResultWrapper result={query}
                                 onLoading={() => <Loader />}
                                 onSuccess={(r: IBuyerCompanyDetailsQueryResult) =>
                                     <BuyerInfosTabLoaded axios={accessTokenWithDataLoader.axios}
                                                          authUser={accessTokenWithDataLoader.authUserO}
                                                          buyerCompanyDetails={r.companyDetails}
                                                          buyers={r.buyers}
                                                          buyersEmails={r.buyerEmails}
                                                          buyersPhones={r.buyerPhones}
                                                          buyerEvents={r.buyerEvents}
                                                          buyerEventsInvoiceMatches={r.buyerEventsInvoiceMatches}/>
                                 }
                                 displayFullError={true} />
    );
}

const BuyerInfosTab = (
    {
        buyerCompanyId,
        accessTokenWithDataLoaderO
    }: {
        buyerCompanyId: string;
        accessTokenWithDataLoaderO: IAccessTokenWithDataLoader | null;
    }
) =>
    !!accessTokenWithDataLoaderO
        ? <BuyerInfosTabAxiosReady buyerCompanyId={buyerCompanyId}
                                   accessTokenWithDataLoader={accessTokenWithDataLoaderO}/>
        : <Loader />;

export default BuyerInfosTab;
