import GraphQLExplorerWrapperPage from "./Pages/GraphQLExplorerWrapperPage";
import SimulatorPage from "./Pages/Simulator/SimulatorPage";
import PreInvoicesPage from "./Pages/PreInvoices/PreInvoicesPage";
import BuyerLimitsPage from "./Pages/BuyerLimits/BuyerLimitsPage";
import DataPage from "./Pages/Data/DataPage";
import {Navigate, useParams} from "react-router-dom";
import { CustomerFinancingRequestsTabDef } from "./Pages/Invoices/InvoicesTabDefs";
import InvoicesPageLayout from "./Pages/Invoices/InvoicesPageLayout";
import SellersPage from "./Pages/Sellers/SellersPage";
import CompaniesPage from "./Pages/Companies/CompaniesPage";
import AddSeller from "./Pages/Sellers/AddSeller";
import SwanIncomingPaymentsPage from "./Pages/SwanIncomingPayments/SwanIncomingPaymentsPage";
import AddCustomer from "./Pages/Customers/AddCustomer";
import CustomerRoute from "./Pages/Customers/CustomerRoute";
import CustomersPage from "./Pages/Customers/CustomersPage";
import SellerRoute from "./Pages/Sellers/SellerRoute";
import AddCompany from "./Pages/Companies/AddCompany";
import CompanyRoute from "./Pages/Companies/CompanyRoute";
import MiscPage from "./Pages/Misc/MiscPage";
import {DataCompanySearchUrl, DataUrlSirenParam } from "./Pages/Data/dataUrls";
import {AddCompanyUrl, CompaniesUrl} from "./Pages/Companies/companiesUrls";
import {AddCustomerUrl, CustomersUrl } from "./Pages/Customers/customersUrls";
import {AddSellerUrl, SellersUrl} from "./Pages/Sellers/sellersUrls";
import {InvoicesUrl, invoicesUrlTab} from "./Pages/Invoices/invoicesUrls";
import { PreInvoicesUrl } from "./Pages/PreInvoices/preInvoicesUrls";
import IRouteDefinitionWithAxiosAndRolesO, {
    buildRouteDefinitionWithAxiosAndRolesORec
} from "./routerBuilder/IRouteDefinitionWithAxiosAndRolesO";
import buyerRoute from "./Pages/Buyers/buyerRoutes";
import IMainRouteDesc from "./routerBuilder/IMainRouteDesc";
import {BaseBillingUrl, BillingsUrl, NewBillingUrl} from "./Pages/Billings/billingUrls";
import BillingsRootPage from "./Pages/Billings/BillingsRootPage";
import BillingsPage from "./Pages/Billings/Billings/BillingsPage";
import NewBillingPage from "./Pages/Billings/NewBilling/NewBillingPage";

interface IMainBaseRouteDesc extends IRouteDefinitionWithAxiosAndRolesO {
    label: string; // In nav bar
}

export const DataWithSirenParamWrapper = (
    {
        isMsalAuthenticationComplete
    }: {
        isMsalAuthenticationComplete: boolean;
    }
) =>
    <DataPage isMsalAuthenticationComplete={isMsalAuthenticationComplete}
              initialSirenO={useParams()[DataUrlSirenParam] || null}/>;

const MainBaseNavRoutesBeforeBuyers: IMainBaseRouteDesc[] = [
    {
        label: 'Companies',
        path: CompaniesUrl,
        titleO: 'Companies',
        component: (accessTokenO) =>
            <CompaniesPage accessTokenO={accessTokenO} />,
        children: [
            {
                path: AddCompanyUrl,
                titleO: 'Company-Add',
                component: (accessTokenO) =>
                    <AddCompany accessTokenO={accessTokenO} />
            },
            CompanyRoute,
            {
                path: CompaniesUrl,
                titleO: 'Companies',
                component: () =>
                    <span className='p-both-center p-padding-4 p-bold'>
                        Veuillez d'abord selectionner une société
                    </span>
            }
        ]
    },
    {
        label: 'Platforms',
        path: CustomersUrl,
        titleO: 'Platforms',
        component: (accessTokenO) =>
            <CustomersPage accessTokenO={accessTokenO} />,
        children: [
            {
                path: AddCustomerUrl,
                titleO: 'Customer-Add',
                component: (accessTokenO) =>
                    <AddCustomer accessTokenO={accessTokenO} />
            },
            CustomerRoute,
            {
                path: CustomersUrl,
                titleO: 'Platforms',
                component: () =>
                    <span className='p-both-center p-padding-4 p-bold'>
                    Veuillez d'abord selectionner une Plateforme
                </span>
            }
        ]
    },
    {
        label: 'Sellers',
        path: SellersUrl,
        titleO: 'Sellers',
        component: (accessTokenO) =>
            <SellersPage accessTokenO={accessTokenO} />,
        children: [
            {
                path: SellersUrl,
                titleO: 'Sellers',
                component: () =>
                    <span className='p-both-center p-padding-4 p-bold'>
                        Veuillez d'abord selectionner un Seller
                    </span>
            },
            SellerRoute,
            {
                path: AddSellerUrl,
                titleO: 'Seller-Add',
                component: (accessTokenO) =>
                    <AddSeller accessTokenO={accessTokenO} />
            }
        ]
    },
    {
        label: 'Invoices',
        path: InvoicesUrl,
        titleO: 'Invoices',
        component: () =>
            <InvoicesPageLayout currentTabNameO={null}
                                childFactory={() =>
                                    <Navigate to={invoicesUrlTab(CustomerFinancingRequestsTabDef.urlParam)}/>
                                }/>
    },
    {
        label: 'Simulator',
        path: '/simulator',
        titleO: 'Simulator',
        component: (accessTokenO) =>
            <SimulatorPage accessTokenO={accessTokenO} />
    },

];

const MainBaseNavRoutesAfterBuyers: IMainBaseRouteDesc[] = [
    {
        label: 'SwanIncomingPayments',
        path: '/swanIncomingPayments',
        titleO: 'SwanIncomingPayments',
        component: (accessTokenO) =>
            <SwanIncomingPaymentsPage accessTokenO={accessTokenO} />
    },
    {
        label: 'PreInvoices',
        path: PreInvoicesUrl,
        titleO: 'PreInvoices',
        component: (accessTokenO) =>
            <PreInvoicesPage accessTokenO={accessTokenO} />
    },
    {
        label: 'BuyerLimits',
        path: '/buyerLimits',
        titleO: 'BuyerLimits',
        component: (accessTokenO) =>
            <BuyerLimitsPage accessTokenO={accessTokenO}/>
    },
    {
        label: 'Billings',
        path: BaseBillingUrl,
        component: (accessTokenO) =>
            <BillingsRootPage accessTokenO={accessTokenO}/>,
        children: [
            {
                path: BillingsUrl,
                titleO: 'Billings',
                component: (accessTokenO) =>
                    <BillingsPage accessTokenO={accessTokenO} />
            },
            {
                path: NewBillingUrl,
                titleO: 'New Billing',
                component: (accessTokenO) =>
                    <NewBillingPage accessTokenO={accessTokenO} />
            }
        ]
    },
    {
        label: 'Misc',
        path: '/misc',
        titleO: 'Misc',
        component: (accessTokenO) =>
            <MiscPage accessTokenO={accessTokenO}/>
    },
    {
        label: 'Data',
        path: DataCompanySearchUrl,
        titleO: 'Data',
        // On utiliser que le isMsalAuthenticationComplete, pas tout le token (ex : roles)
        // Il faudrait les préciser à chaque page si on voulait l'omettre ici, on se l'épargne.
        component: (accessTokenO) =>
            <DataWithSirenParamWrapper isMsalAuthenticationComplete={!!accessTokenO}/>
    },
    {
        label: 'GraphQL Explorer',
        path: '/graphql',
        titleO: 'GraphQL-Explorer',
        component: (accessTokenO) =>
            <GraphQLExplorerWrapperPage accessTokenO={accessTokenO}/>
    }
];

// In the order of the topbar
const MainNavRoutes: IMainRouteDesc[] = [
    ...MainBaseNavRoutesBeforeBuyers.map((c) => ({
        route: buildRouteDefinitionWithAxiosAndRolesORec(c),
        label: c.label
    })),
    buyerRoute,
    ...MainBaseNavRoutesAfterBuyers.map((c) => ({
        route: buildRouteDefinitionWithAxiosAndRolesORec(c),
        label: c.label
    }))
];

export default MainNavRoutes;
