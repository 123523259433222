import React from 'react';
import { createRoot } from 'react-dom/client';

// import reportWebVitals from './reportWebVitals';

import logger from "./logger";
import RouterWrapper from "./routerBuilder/RouterWrapper";
import QueryClientWrapper from "./QueryClientWrapper";
import {setFactorContext} from "./IFactorContext";

import CurrentEnv from "./envs/CurrentEnv";

// !!!  Need to be the last import !!!, otherwise link style breaks
import './index.scss';

setFactorContext(CurrentEnv.baseUrl, CurrentEnv.swanClientId, logger);

createRoot(document.getElementById('root')!)
    .render(
        <React.StrictMode>
            { /* Pas forcément utilisé par toutes les Pages, mais probablement ok */}
            <QueryClientWrapper>
                <RouterWrapper/>
            </QueryClientWrapper>
        </React.StrictMode>
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
