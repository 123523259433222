export const GlobalOutstandingsQueryKey = ['globalOutstandings'];

export interface IGlobalOutstandings {
    limit: number;
    activeFinancings: number;
    activeFinancingRequests: number;
}

export interface IGlobalOutstandingsQueryResult {
    globalOutstandings: IGlobalOutstandings;
}
