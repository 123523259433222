import { Axios } from "axios";
import { SimulationText } from "./SimulatorPage";
import { ICompanySelection } from "factor-lib/Company/ICompanySelection";
import IFailable from "factor-lib/IFailable";
import {useMutation} from "@tanstack/react-query";
import ButtonMutationForModal from "factor-lib/Buttons/ButtonForModal/ButtonMutationForModal";
import { formatAmount } from "factor-lib/utils/amountUtils";
import Button from "factor-lib/Buttons/Button";

export interface ISimulationBuyerResult {
    outstanding: number;

    // at most one or the other
    manualOutstandingLimit: number | null;
    defaultOutstandingLimit: IFailable<number | null> | null;
}

const simulationBuyerAction = async (
    axios: Axios,
    buyerSelection: ICompanySelection
): Promise<ISimulationBuyerResult> =>
    (await axios.get<ISimulationBuyerResult>(
        `/adminSimulator/buyer?${buyerSelection.id !== null ? `companyId=${buyerSelection.id}` : `siren=${buyerSelection.siren!}`}`
    )).data;

const SimulationBuyerAvailableOutstanding = (
    {
        availableOutstandingO
    }: {
        availableOutstandingO: number | null;
    }
) =>
    <div>
        { availableOutstandingO === null
            ? <div>L'encours disponible n'est pas calculable</div>
            : <div>L'encours disponible est {formatAmount(Math.max(0, availableOutstandingO))}</div>
        }
    </div>;

export const SimulationBuyerInfos = (
    {
        outstanding,

        // at most one or the other
        manualOutstandingLimit,
        defaultOutstandingLimit
    }: ISimulationBuyerResult
) =>
    <div>
        <div>L'encours buyer est {formatAmount(outstanding)}</div>

        { manualOutstandingLimit !== null
            ? <div>La limite d'encours buyer <span className='p-bold'>manuelle</span> est {formatAmount(manualOutstandingLimit)}</div>
            : defaultOutstandingLimit === null
                ? <div>La présense d'une limite d'encours buyer <span className='p-bold'>manuelle</span> est nécessaire (buyer étranger)</div>
                : defaultOutstandingLimit.errorO !== null
                    ? <div>Erreur lors de la récupération du Dimpl Score</div>
                    : defaultOutstandingLimit.successO === null
                        ? <div>La présense d'une limite d'encours buyer <span className='p-bold'>manuelle</span> est nécessaire (pas de limite discrétionnaire)</div>
                        : <div>La limite d'encours buyer <span className='p-bold'>discrétionnaire</span> est {formatAmount(defaultOutstandingLimit.successO)}</div>
        }

        { (manualOutstandingLimit !== null || (defaultOutstandingLimit !== null && defaultOutstandingLimit.successO !== null)) &&
            <SimulationBuyerAvailableOutstanding availableOutstandingO={(manualOutstandingLimit ?? defaultOutstandingLimit!.successO)! - outstanding} />
        }
    </div>;

const ResultModalContent = (
    {
        result,
        closeModal
    }: {
        result: ISimulationBuyerResult;
        closeModal: () => void;
    }
) =>
    <div className='p-vertical-center p-padding-4'>
        <SimulationBuyerInfos {...result} />

        <Button id='closeModalClearForm'
                className='p-margin-top-5'
                text='Fermer'
                isLoading={false}
                actionO={closeModal} />
    </div>;


const SimulatorBuyerEnabled = (
    {
        axios,
        buyerSelection,
        kind,
        size,
        onClose
    } : {
        axios: Axios;
        buyerSelection: ICompanySelection;
        kind?: string;
        size?: string;
        onClose: () => void;
    }
) =>
    <ButtonMutationForModal id='simulationBuyer'
                            kind={kind}
                            size={size}
                            text={SimulationText /* ne voudrait on pas mettre un texte specifique ? */}
                            mutation={useMutation<ISimulationBuyerResult, any, any>(
                                () => simulationBuyerAction(axios, buyerSelection)
                            )}
                            value={null}
                            displayFullError={true}
                            onClose={onClose}
                            resultModalNode={(r: ISimulationBuyerResult) =>
                                <ResultModalContent result={r}
                                                    closeModal={onClose}/>
                            }/>;

export default SimulatorBuyerEnabled;
