import {ICustomerFinancingRequest} from "../../../utils/FinancingRequest/FinancingRequestsQuery";
import {IBaseInvoiceSellerWithCompany, IInvoiceBuyerCompany, remainingToPay} from "../../../utils/IInvoice";
import {invoiceUrl} from "../invoicesUrls";
import { Link } from "react-router-dom";
import CheckBox from "factor-lib/forms/CheckBox";
import {sellerUrl} from "../../Sellers/sellersUrls";
import {formatCompany} from "../../../utils/companyUtils";
import {buyerUrl} from "../../Buyers/buyersUrls";
import {formatAdminDateStr} from "../../../utils/dateTimeUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";

const PendingPaymentRow = (
    {
        pendingPayment,
        isSelected,
        seller,
        buyerCompany,
        setSelectedCustomerFinancingRequest
    }: {
        pendingPayment: ICustomerFinancingRequest,
        isSelected: boolean;
        seller: IBaseInvoiceSellerWithCompany;
        buyerCompany: IInvoiceBuyerCompany;
        setSelectedCustomerFinancingRequest: (customerFinancingRequestId: string) => void;
    }
) => {
    const { invoice } = pendingPayment;
    return (
        <tr>
            <td>
                <CheckBox inputValue={isSelected}
                          internalId={`selectPayment-${pendingPayment.id}`}
                          updateInputValue={() => setSelectedCustomerFinancingRequest(pendingPayment.id)}
                />
            </td>
            <td>
                <Link to={invoiceUrl(invoice.id)}>
                    { invoice.number }
                </Link>
            </td>
            <td>
                <Link to={sellerUrl(seller.id)}>
                    { formatCompany(seller.company) }
                </Link>
            </td>
            <td>
                <Link to={buyerUrl(buyerCompany.id, false)}>
                    { formatCompany(buyerCompany) }
                </Link>
            </td>
            <td>
                { formatAmount(invoice.amountWoTax + invoice.amountTax) }
            </td>
            <td>
                { formatAmount(remainingToPay(invoice)) }
            </td>
            <td>
                { formatAdminDateStr(invoice.dueDate) }
            </td>
        </tr>
    );
}

export default PendingPaymentRow;