import {PreInvoicesUrl} from "../../PreInvoices/preInvoicesUrls";
import {rejectedState} from "../../PreInvoices/preInvoicesQuery";
import updatePreInvoicesQueries from "./updatePreInvoicesQueries";
import {QueryClient, useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {Axios} from "axios";
import {IPreInvoiceDetails} from "../PreInvoiceDetailsQuery";
import { KIND_DANGER, SIZE_FIXED } from "factor-lib/Buttons/Button";
import {NavigateFunction} from "react-router-dom";
import {useContext} from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";

const DiscardPreInvoiceButtonEnabled = (
    {
        axios,
        preInvoiceId,
        preInvoice
    } : {
        axios: Axios;
        preInvoiceId: string;
        preInvoice: IPreInvoiceDetails;
    }
) => {
    const queryClient: QueryClient = useQueryClient();
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const discardPreInvoiceMutation: UseMutationResult<void, any, null> =
        useMutation<void, any, null>(
            async () => {
                (await axios.put<void>(
                    `/adminPreInvoices/${preInvoiceId}/discard`,
                    null
                ))
            },
            ({
                onSuccess: () => {
                    updatePreInvoicesQueries(queryClient, preInvoiceId, preInvoice, rejectedState);
                    navigate(PreInvoicesUrl);
                }
            })
        );

    return (
        <ButtonMutationEnabled id='discardPreInvoiceButton'
                               kind={KIND_DANGER}
                               size={SIZE_FIXED}
                               text='Discard'
                               mutation={discardPreInvoiceMutation}
                               displayFullError={true}
                               value={null} />

    );
}

export default DiscardPreInvoiceButtonEnabled;