import { MindeeFieldEnum, MindeeFieldFriendlyNames, MindeeIbanComplianceFriendlyNames } from "../../../../utils/mindeeUtils";
import { IInvoiceMindee } from "../../invoiceDetailsQuery";

const formatMindeeFields = (mindeeFields: MindeeFieldEnum[]): string =>
    mindeeFields.map((f) => MindeeFieldFriendlyNames.get(f)!).join(', ');

const InvoiceMindeeInfos = (
    {
        className,
        mindee
    } : {
        className?: string;
        mindee: IInvoiceMindee | null;
    }
) =>
    <div className={className}>
        { !!mindee
            ? <div className='box' style={{display: 'inline-block'}}>
                <div className='title p-size-6'>Résultat OCR au moment de l'ajout</div>

                <table className='table is-narrow'>
                    <tbody>
                        { mindee.conflictingFields.length > 0 &&
                            <tr>
                                <td className='p-bold' style={{color: 'orange'}}>Conflits</td>
                                <td>{ formatMindeeFields(mindee.conflictingFields) }</td>
                            </tr>
                        }
                        { mindee.undetectedFields.length > 0 &&
                            <tr>
                                <td className='p-bold'>Non détectés</td>
                                <td>{ formatMindeeFields(mindee.undetectedFields) }</td>
                            </tr>
                        }
                        <tr>
                            <td className='p-bold'>Iban</td>
                            <td>{ MindeeIbanComplianceFriendlyNames.get(mindee.ibanCompliance)! }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            : <div>Pas d'OCR au moment de l'ajout</div>
        }
    </div>;

export default InvoiceMindeeInfos;
