import { useState } from "react";
import { Axios } from "axios";
import Modal from "factor-lib/Modal";
import OkCancelRow from "factor-lib/Modals/OkCancelRow";
import RadioBox from "factor-lib/forms/Radio/RadioBox";
import Button from "factor-lib/Buttons/Button";
import { KIND_OUTLINED, SIZE_FIXED } from "factor-lib/Buttons/Button";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { IMatchOption, INewInvoiceMatches, MatchOptions } from "./matchOption";
import { formatAmount } from "factor-lib/utils/amountUtils";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";


const SwanIncomingPaymentDetailsMatchSelection = (
    {
        oldPaymentAmountRemaining,
        newInvoiceMatches,
        selectedMatchOption,
        setSelectedMatchOption,
        confirm,
        cancel
    }: {
        oldPaymentAmountRemaining: number;
        newInvoiceMatches: INewInvoiceMatches | null; // null -> flaggedNoMatch
        selectedMatchOption: IMatchOption | null;
        setSelectedMatchOption: (newValue: IMatchOption) => void;
        confirm: () => void;
        cancel: () => void;
    }
) => {
    // already checked, just to be safe
    const commonNotValidReason =
        !!newInvoiceMatches
            ? newInvoiceMatches.total > oldPaymentAmountRemaining
                ? `montant à matcher supérieur au montant restant`
                : newInvoiceMatches.total < 0
                    ? `montant à matcher negatif`
                    : null
            : null;

    const getCannotSelectReason = (matchOption: IMatchOption) =>
        commonNotValidReason ?? matchOption.cannotSelectReason(newInvoiceMatches);

    return (
        <div>
            <div className='p-bold p-both-center'>Veuillez choisir le type du Match</div>

            <table>
                <tbody>
                    { MatchOptions.map((matchOption, matchOptionIndex) => {
                        const cannotSelectReason = getCannotSelectReason(matchOption);
                        return (
                            <tr key={matchOptionIndex}>
                                <td>
                                    <div className='p-padding-4'>
                                        <RadioBox id={`matchOptions-${matchOptionIndex}`}
                                                  inputValue={matchOption.id === selectedMatchOption?.id}
                                                  setInputValue={() => setSelectedMatchOption(matchOption)}
                                                  enabled={cannotSelectReason === null} />
                                    </div>
                                </td>
                                <td>
                                    <div className='p-padding-4'>
                                        { matchOption.description }
                                    </div>
                                </td>
                                <td>
                                    { !!cannotSelectReason &&
                                        <div className='p-padding-4'>
                                            { `<<pas possible car ${cannotSelectReason}>>` }
                                        </div>
                                    }
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <OkCancelRow className='p-margin-top-6'
                         okIdButton='selectMatchId'
                         okText='Suivant'
                         cancel={cancel}
                         isLoading={false}
                         validate={!!selectedMatchOption && getCannotSelectReason(selectedMatchOption) === null
                             ? confirm
                             : null
                         } />
        </div>
    );
}

const SwanIncomingPaymentDetailsMatchButtonEnabled = (
    {
        axios,
        swanIncomingPaymentId,
        oldPaymentAmountRemaining,
        newInvoiceMatches,
        matchOption,
        clearNewInvoiceMatches,
        closeModal
    }: {
        axios: Axios;
        swanIncomingPaymentId: string;
        oldPaymentAmountRemaining: number;
        newInvoiceMatches: INewInvoiceMatches | null;
        matchOption: IMatchOption;
        clearNewInvoiceMatches: () => void;
        closeModal: () => void;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const matchPaymentMutation: UseMutationResult<void, any, null> =
        useMutation<void, any, null>(
            () => matchOption.action(axios, queryClient, swanIncomingPaymentId, oldPaymentAmountRemaining, newInvoiceMatches),
            ({
                onSuccess: () => {
                    clearNewInvoiceMatches();
                    if (!!matchOption.updateQueries) {
                        matchOption.updateQueries(queryClient, swanIncomingPaymentId, newInvoiceMatches);
                    }
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='matchPaymentButton'
                               text='Confirmer'
                               mutation={matchPaymentMutation}
                               displayFullError={true}
                               value={null} />
    );
};

const SwanIncomingPaymentDetailsMatchReview = (
    {
        axios,
        swanIncomingPaymentId,
        oldPaymentAmountRemaining,
        newInvoiceMatches,
        matchOption,
        cancel,
        clearNewInvoiceMatches,
        closeModal
    }: {
        axios: Axios;
        swanIncomingPaymentId: string;
        oldPaymentAmountRemaining: number;
        newInvoiceMatches: INewInvoiceMatches | null;
        matchOption: IMatchOption;
        cancel: () => void;
        clearNewInvoiceMatches: () => void;
        closeModal: () => void;
    }
) => {
    const matchingSummary = matchOption.summary(oldPaymentAmountRemaining, newInvoiceMatches);

    return (
        <div>
            <div className='p-bold p-both-center'>Veuillez valider le Match</div>

            <div className='table-container p-padding-5 p-vertical-center'>
                <table className='table is-narrow'>
                    <tbody>
                        <tr>
                            <td className='p-bold'>Montant restant actuel</td>
                            <td>{ formatAmount(oldPaymentAmountRemaining) }</td>
                        </tr>
                        <tr>
                            <td className='p-bold'>Montant à Matcher</td>
                            <td>{ formatAmount(matchingSummary.newAmountToMatch) } ({ newInvoiceMatches?.amounts.size ?? 0 } Factures)</td>
                        </tr>
                        <tr>
                            <td className='p-bold'>Montant à verser à la plateforme</td>
                            <td>{ formatAmount(matchingSummary.amountToSendToCustomer) }</td>
                        </tr>
                        <tr>
                            <td className='p-bold'>Nouveau montant restant</td>
                            <td>{ formatAmount(matchingSummary.newAmountRemaining) }</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='level'>
                <div className='level-item p-margin-right-4'>
                    <Button id='unvalidateMatchId'
                            text='Précédent'
                            isLoading={false}
                            kind={KIND_OUTLINED}
                            size={SIZE_FIXED}
                            actionO={cancel} />
                </div>

                <div className='level-item p-margin-left-4'>
                    <SwanIncomingPaymentDetailsMatchButtonEnabled axios={axios}
                                                                  swanIncomingPaymentId={swanIncomingPaymentId}
                                                                  oldPaymentAmountRemaining={oldPaymentAmountRemaining}
                                                                  newInvoiceMatches={newInvoiceMatches}
                                                                  matchOption={matchOption}
                                                                  clearNewInvoiceMatches={clearNewInvoiceMatches}
                                                                  closeModal={closeModal} />
                </div>
            </div>
        </div>
    );
}

const SwanIncomingPaymentDetailsMatchModal = (
    {
        axios,
        swanIncomingPaymentId,
        oldPaymentAmountRemaining,
        newInvoiceMatches,
        clearNewInvoiceMatches,
        closeModal
    }: {
        axios: Axios;
        swanIncomingPaymentId: string;
        oldPaymentAmountRemaining: number;
        newInvoiceMatches: INewInvoiceMatches | null;
        clearNewInvoiceMatches: () => void;
        closeModal: () => void;
    }
) => {
    const [selectedMatchOption, setSelectedMatchOption] = useState<IMatchOption | null>(null);
    const [selectedMatchOptionConfirmed, setSelectedMatchOptionConfirmed] = useState(false);

    return (
        <Modal id='matchModal'
               close={closeModal}
               maxWidth='1200px'
               fullMaxWidth={false}>
            <div className='container p-both-center p-padding-4'>
                { !selectedMatchOption || !selectedMatchOptionConfirmed
                    ? <SwanIncomingPaymentDetailsMatchSelection oldPaymentAmountRemaining={oldPaymentAmountRemaining}
                                                                newInvoiceMatches={newInvoiceMatches}
                                                                selectedMatchOption={selectedMatchOption}
                                                                setSelectedMatchOption={setSelectedMatchOption}
                                                                confirm={() => setSelectedMatchOptionConfirmed(true)}
                                                                cancel={closeModal} />
                    : <SwanIncomingPaymentDetailsMatchReview axios={axios}
                                                             swanIncomingPaymentId={swanIncomingPaymentId}
                                                             oldPaymentAmountRemaining={oldPaymentAmountRemaining}
                                                             newInvoiceMatches={newInvoiceMatches}
                                                             matchOption={selectedMatchOption}
                                                             cancel={() => setSelectedMatchOptionConfirmed(false)}
                                                             clearNewInvoiceMatches={clearNewInvoiceMatches}
                                                             closeModal={closeModal} />
                }
            </div>
        </Modal>
    );
}

const MatchSwanIncomingPaymentButton = (
    {
        className,
        axios,
        swanIncomingPaymentId,
        oldPaymentAmountRemaining,
        newInvoiceMatches,
        clearNewInvoiceMatches,
        kind,
        size
    }: {
        className?: string;
        axios: Axios;
        swanIncomingPaymentId: string;
        oldPaymentAmountRemaining: number;
        newInvoiceMatches: INewInvoiceMatches | null;
        clearNewInvoiceMatches: () => void;
        kind: string;
        size: string;
    }
) =>
    <ButtonForModal className={className}
                    id='showMatcheSwanIncomingPaymentModal'
                    text='Matcher'
                    kind={kind}
                    size={size}
                    childModalContent={(closeModal) =>
                        <SwanIncomingPaymentDetailsMatchModal axios={axios}
                                                              swanIncomingPaymentId={swanIncomingPaymentId}
                                                              oldPaymentAmountRemaining={oldPaymentAmountRemaining}
                                                              newInvoiceMatches={newInvoiceMatches}
                                                              clearNewInvoiceMatches={clearNewInvoiceMatches}
                                                              closeModal={closeModal} />
                    } />;

export default MatchSwanIncomingPaymentButton;
