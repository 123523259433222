import Loader from "factor-lib/Loader";
import InvoiceFinancingEmailsTableLoaded from "./InvoiceFinancingEmailsTableLoaded";
import {Axios} from "axios";
import GraphQLQueryWrapper from "../../../../utils/GraphQLQueryWrapper";

export interface IFinancingEmail {
    sentDate: string;
    sentTo: string;
    templateName: string;

    subject: string | null;
    status: string | null;
    opensCount: number | null;
    clicksCount: number | null;
}

interface IFinancingEmailsQueryResult {
    financingEmails: IFinancingEmail[];
}

const InvoiceFinancingEmailsTable = (
    {
        axios,
        financingId
    }: {
        axios: Axios;
        financingId: string;
    }
) =>
    <div className='p-full-width' style={{overflowX: 'auto'}}>
        <table className='table is-narrow is-striped is-hoverable is-fullwidth'>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Destinataire</th>
                    <th>Template</th>
                    <th>Titre</th>
                    <th>Status</th>
                    <th>№ Ouvertures</th>
                    <th>№ Cliques</th>
                </tr>
            </thead>
            <GraphQLQueryWrapper queryKey={['financing-emails', financingId]}
                                 axios={axios}
                                 queryParams={{
                                     query: `
                                        query Q($financingId: Guid!) {
                                            financingEmails (financingId: $financingId) {
                                                sentDate
                                                sentTo
                                                templateName
    
                                                subject
                                                status
                                                opensCount
                                                clicksCount
                                            }
                                        }
                                     `,
                                     variables: {
                                         financingId
                                     }
                                 }}
                                 onLoading={() =>
                                     <tbody>
                                         <tr>
                                             <td/>
                                             <td/>
                                             <td/>
                                             <td><Loader /></td>
                                             <td/>
                                             <td/>
                                             <td/>
                                         </tr>
                                     </tbody>
                                 }
                               onSuccess={(r: IFinancingEmailsQueryResult) =>
                                   <InvoiceFinancingEmailsTableLoaded financingEmails={r.financingEmails} />
                               } />
        </table>
    </div>;

export default InvoiceFinancingEmailsTable;
