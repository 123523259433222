import {Link, Outlet} from "react-router-dom";
import BuyerTabs from "./BuyerTabs";
import {
    buyerUrlTab, WithBuyerTabParamWrapper,
    WithIncludeBuyersWithoutInvoiceQueryParamWrapper
} from "./buyersUrls";
import IAccessTokenWithDataLoader from "./IAccessTokenWithDataLoader";


const BuyerPage = (
    {
        accessTokenWithDataLoaderO,
        buyerCompanyId
    }: {
        accessTokenWithDataLoaderO: IAccessTokenWithDataLoader | null;
        buyerCompanyId: string;
    }
) =>
    <div>
        <WithBuyerTabParamWrapper child={(tabO: string | null) =>
            <WithIncludeBuyersWithoutInvoiceQueryParamWrapper child={(includeBuyersWithoutInvoice: boolean) =>
                <div className='tabs'>
                    <ul>
                        { BuyerTabs.map((buyerPage, index) =>
                            <li key={`t-${index}`}
                                className={tabO === buyerPage.path ? 'is-active' : ''}>
                                    <Link to={buyerUrlTab({
                                        buyerCompanyId: buyerCompanyId,
                                        tabO: buyerPage.path
                                    }, includeBuyersWithoutInvoice)}>
                                        { buyerPage.title }
                                    </Link>

                            </li>
                        ) }
                    </ul>
                </div>
            }/>
        }/>
        <div className='p-margin-4'>
            <Outlet context={{accessTokenWithDataLoaderO, buyerCompanyId}}/>
        </div>
    </div>;

export default BuyerPage;
