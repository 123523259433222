import {  userFormatDate, serverDateDeserialization } from "factor-lib/utils/dateUtils";

export const serverDateNullableDeserialization = (dateStrNullable: string | null): Date | null =>
    dateStrNullable != null
        ? serverDateDeserialization(dateStrNullable)
        : null;

export const formatAdminDateNullableStr = (dateStrNullable: string | null): string | null =>
    dateStrNullable != null
        ? formatAdminDate(serverDateDeserialization(dateStrNullable))
        : null;

export const formatAdminDateStr = (dateStr: string): string =>
    formatAdminDate(serverDateDeserialization(dateStr));

export const formatAdminDate = (date: Date): string =>
    userFormatDate(date);

export const formatDateAndTimeAdmin = (date: Date) =>
    date
        .toLocaleDateString(
            'fr-FR',
            {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }
        );

// // JS Date requires a 'Z' suffix in order to be consirdered UTC
// // Because .NET DateTime is not serialized with a 'Z' suffix, it is considered as Local Time instead of UTC
// export const ensureUtcSuffix = (dateStr: string) =>
//     dateStr.endsWith('Z') ? dateStr : dateStr + 'Z';