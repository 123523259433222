import { Axios } from "axios";
import IOption from "factor-lib/forms/Select/IOption";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import MultipleSelect from "factor-lib/forms/Select/MultipleSelect";
import { useState } from "react";
import {
    opsTagsOptions,
    onboardingBuyerNeededOption,
    onboardingBuyerOkEmailOption,
    onboardingBuyerOkPhoneOption,
    runningCheckBuyerNeededOption,
    runningCheckOkEmailOption,
    runningCheckOkPhoneOption
} from "../../Pages/Invoice/InvoiceLoaded/InvoiceOpsInfos/InvoiceUpdateOpsTags";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { IInvoiceDetailsQueryResult, invoiceDetailsQueryKey } from "../../Pages/Invoice/invoiceDetailsQuery";
import { FinancingRequestsQueryKeyPrefix, IAcceptedOrNot, ICustomerFinancingRequestsResponse } from "./FinancingRequestsQuery";

const UpdateBatchOpsTagsEnabled = (
    {
        invoiceIds,
        axios,
        opsTags,
        kind,
        size,
        text,
        closeModal
    } : {
        invoiceIds: string[];
        axios: Axios;
        opsTags: ReadonlyArray<IOption<number>>;
        kind: string;
        size: string;
        text: string;
        closeModal: () => void;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateOpsTagsMutation: UseMutationResult<void, any, Array<number>> =
        useMutation<void, any, Array<number>>(
            async (opsTagsValues) => {
                await axios.put(
                    `/adminInvoices/batchTags`,
                    {
                        opsTags: opsTagsValues,
                        invoiceIds: invoiceIds
                    }
                );
            },
            ({
                onSuccess: () => {
                    const onBoardingBuyerNeeded = opsTags.some(t => t === onboardingBuyerNeededOption);
                    const onBoardingBuyerOkEmail = opsTags.some(t => t === onboardingBuyerOkEmailOption);
                    const onBoardingBuyerOkPhone = opsTags.some(t => t === onboardingBuyerOkPhoneOption);
                    const runningCheckBuyerNeeded = opsTags.some(t => t === runningCheckBuyerNeededOption);
                    const runningCheckBuyerOkEmail = opsTags.some(t => t === runningCheckOkEmailOption);
                    const runningCheckBuyerOkPhone = opsTags.some(t => t === runningCheckOkPhoneOption);

                    if (onBoardingBuyerNeeded || onBoardingBuyerOkEmail || onBoardingBuyerOkPhone
                        || runningCheckBuyerNeeded || runningCheckBuyerOkEmail || runningCheckBuyerOkPhone
                    ) {
                        const invoiceIdSet = new Set<string>(invoiceIds);

                        queryClient.setQueriesData<ICustomerFinancingRequestsResponse<IAcceptedOrNot>>(
                            FinancingRequestsQueryKeyPrefix,
                            old => !!old ? ({
                                financingRequests: {
                                    ...old!.financingRequests,
                                    base: old!.financingRequests.base
                                        .map((f) => invoiceIdSet.has(f.invoice.id)
                                            ? {
                                                ...f,
                                                invoice: {
                                                    ...f.invoice,
                                                    opsTags: {
                                                        ...f.invoice.opsTags,
                                                        onboardingBuyerNeeded: onBoardingBuyerNeeded || f.invoice.opsTags.onboardingBuyerNeeded,
                                                        onboardingBuyerOkEmail: onBoardingBuyerOkEmail || f.invoice.opsTags.onboardingBuyerOkEmail,
                                                        onboardingBuyerOkPhone: onBoardingBuyerOkPhone || f.invoice.opsTags.onboardingBuyerOkPhone,
                                                        runningCheckBuyerNeeded: runningCheckBuyerNeeded || f.invoice.opsTags.runningCheckBuyerNeeded,
                                                        runningCheckOkEmail: runningCheckBuyerOkEmail || f.invoice.opsTags.runningCheckOkEmail,
                                                        runningCheckOkPhone: runningCheckBuyerOkPhone || f.invoice.opsTags.runningCheckOkPhone
                                                    }
                                                }
                                            } : f
                                        )
                                }
                            }) : undefined
                        );

                        invoiceIds.forEach((invoiceId) =>
                            queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                                invoiceDetailsQueryKey(invoiceId),
                                (old: IInvoiceDetailsQueryResult | undefined) =>
                                    !!old
                                        ? ({
                                            ...old,
                                            invoiceDetails: {
                                                ...old.invoiceDetails,
                                                opsTags: {
                                                    ...old.invoiceDetails.opsTags,
                                                    onboardingBuyerNeeded: onBoardingBuyerNeeded || old.invoiceDetails.opsTags.onboardingBuyerNeeded,
                                                    onboardingBuyerOkEmail: onBoardingBuyerOkEmail || old.invoiceDetails.opsTags.onboardingBuyerOkEmail,
                                                    onboardingBuyerOkPhone: onBoardingBuyerOkPhone || old.invoiceDetails.opsTags.onboardingBuyerOkPhone,
                                                    runningCheckBuyerNeeded: runningCheckBuyerNeeded || old.invoiceDetails.opsTags.runningCheckBuyerNeeded,
                                                    runningCheckOkEmail: runningCheckBuyerOkEmail || old.invoiceDetails.opsTags.runningCheckOkEmail,
                                                    runningCheckOkPhone: runningCheckBuyerOkPhone || old.invoiceDetails.opsTags.runningCheckOkPhone
                                                }
                                            }
                                        }) : undefined
                            )
                        );
                    }
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateInvoiceOpsTags'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateOpsTagsMutation}
                               displayFullError={true}
                               value={opsTags.map((o) => o.value)} />
    );
}

const FinancingRequestsBatchUpdateTags = (
    {
        axios,
        selectedInvoicesIds,
        closeModal
    }: {
        axios: Axios;
        selectedInvoicesIds: string[];
        closeModal: () => void;
    }
) => {
    const [ selectedOpsTags, setSelectedOpsTags ] = useState<ReadonlyArray<IOption<number>>>([]);

    return (
        <div>
            <div className="p-margin-4">
                <MultipleSelect options={opsTagsOptions}
                                selectedOptions={selectedOpsTags}
                                setSelectedOptions={setSelectedOpsTags} />
            </div>
            <div className="p-margin-4">
                { selectedOpsTags.length > 0
                    ? <UpdateBatchOpsTagsEnabled axios={axios}
                                                 invoiceIds={selectedInvoicesIds}
                                                 kind={KIND_PRIMARY}
                                                 size={SIZE_FIXED}
                                                 text='Batch update'
                                                 opsTags={selectedOpsTags}
                                                 closeModal={closeModal} />
                        : <ButtonDisabled kind={KIND_PRIMARY}
                                          size={SIZE_FIXED}
                                          text='Batch update' />
                }
            </div>
        </div>

    );
}

export default FinancingRequestsBatchUpdateTags;
