import Loader from "factor-lib/Loader";
import IAccessToken from "../../../IAccessToken";
import IAuthUserLoad from "../../../IAuthUserLoad";
import SellerAddInvoiceLoaded from "./SellerAddInvoiceLoaded";
import { ISellerForAddInvoiceQueryResult, sellerForAddInvoiceQueryKey } from "./sellerAddInvoiceQuery";
import GraphQLWithAccessTokenWrapper from "../../../GraphQLQueryWithAccessTokenWrapper";
import { isValidConfidenceFlag } from "../../../utils/ConfidenceFlags";
import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";

const SellerAddInvoice = (
    {
        accessTokenO,
        sellerId
    }: {
        accessTokenO: IAccessToken | null,
        sellerId: string
    }
) =>
    <div className='container p-vertical-center p-margin-top-3'>
        <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                       queryKey={sellerForAddInvoiceQueryKey(sellerId)}
                                       queryParams={{
                                           query: `
                                               query Q($sellerId: Guid!) {
                                                   sellerDetails (id: $sellerId) {
                                                       company {
                                                           id
                                                           name
                                                           identifier { ${IntCompanyIdentifierGraphQLFields} }
                                                       }
                                                       customer { directSeller { id } }
                                                       certif {
                                                           solvaConfidenceFlag
                                                           fraudConfidenceFlag
                                                       }
                                                       isBlocked
                                                   }
                                                   buyers (sellerId: $sellerId) {
                                                        company {
                                                            id
                                                            name
                                                            identifier { ${IntCompanyIdentifierGraphQLFields} }
                                                        }
                                                   }
                                               }
                                           `,
                                           variables: {
                                               sellerId
                                           }
                                       }}
                                       onLoading={() => <Loader />}
                                       onSuccess={(r: IAuthUserLoad<ISellerForAddInvoiceQueryResult>) =>
                                           <SellerAddInvoiceLoaded axios={r.axios}
                                                                   sellerId={sellerId}
                                                                   sellerCompany={r.value.sellerDetails.company}
                                                                   isDirectSeller={!!r.value.sellerDetails.customer.directSeller}
                                                                   isBlocked={r.value.sellerDetails.isBlocked}
                                                                   isCertified={!!r.value.sellerDetails.certif && isValidConfidenceFlag(r.value.sellerDetails.certif.solvaConfidenceFlag) && isValidConfidenceFlag(r.value.sellerDetails.certif.fraudConfidenceFlag)}
                                                                   buyers={r.value.buyers.map((b) => b.company)}/>
                                       } />
    </div>;

export default SellerAddInvoice;
