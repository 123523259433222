import { Axios} from "axios";
import { IInvoiceDetailsQueryResult, IInvoiceOpsTags, invoiceDetailsQueryKey } from "../../invoiceDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import IOption from "factor-lib/forms/Select/IOption";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

// maps to InvoiceOpsTagOrderedEnum
const fraudOption: IOption<number> = { label: 'Fraud', value: 1 };
const specialSeeNotesOption: IOption<number> = { label: 'Special See Notes', value: 2 };
const virtualIbanVisibleOption: IOption<number> = { label: 'Iban Virtuel Visible', value: 3 };
const mandatoryPayerOption: IOption<number> = { label: 'Mandataire Payeur', value: 4 };
const notInsuredOption: IOption<number> = { label: 'Not insured', value: 12 };
const specificPaymentFlowOption: IOption<number> = { label: 'Specific Payment Flow', value: 13 };
const publicBuyerOption: IOption<number> = { label: 'Public Buyer', value: 14 };

export const onboardingBuyerNeededOption: IOption<number> = { label: 'Onboarding buyer needed', value: 5 };
export const onboardingBuyerOkPhoneOption: IOption<number> = { label: 'Onboarding buyer ok tel', value: 6 };
export const onboardingBuyerOkEmailOption: IOption<number> = { label: 'Onboarding buyer ok email', value: 7 };

export const runningCheckBuyerNeededOption: IOption<number> = { label: 'Running check buyer needed', value: 8 };
export const runningCheckOkEmailOption: IOption<number> = { label: 'Running check buyer ok email', value: 9 };
export const runningCheckOkPhoneOption: IOption<number> = { label: 'Running check buyer ok tel', value: 10 };

const subrogationLetterNeededOption: IOption<number> = { label: 'Lettre de subrogation needed', value: 11 };


export const opsTagsOptions: Array<IOption<number>> = [
    fraudOption,
    specialSeeNotesOption,
    virtualIbanVisibleOption,
    mandatoryPayerOption,
    notInsuredOption,
    specificPaymentFlowOption,
    publicBuyerOption,

    onboardingBuyerNeededOption,
    onboardingBuyerOkPhoneOption,
    onboardingBuyerOkEmailOption,

    runningCheckBuyerNeededOption,
    runningCheckOkEmailOption,
    runningCheckOkPhoneOption,

    subrogationLetterNeededOption
];

export const getInitialOpsTags = (opsTags: IInvoiceOpsTags): Array<IOption<number>> => {
    const opsTagsOptions = new Array<IOption<number>>();

    const checkAddOpsTag = (
        tagPresent: boolean,
        tagOption: IOption<number>
    ): void => {
        if (tagPresent) {
            opsTagsOptions.push(tagOption);
        }
    };

    checkAddOpsTag(opsTags.fraud, fraudOption);
    checkAddOpsTag(opsTags.specialSeeNotes, specialSeeNotesOption);
    checkAddOpsTag(opsTags.virtualIbanVisible, virtualIbanVisibleOption);
    checkAddOpsTag(opsTags.mandatoryPayer, mandatoryPayerOption);
    checkAddOpsTag(opsTags.notInsured, notInsuredOption);
    checkAddOpsTag(opsTags.specificPaymentFlow, specificPaymentFlowOption);
    checkAddOpsTag(opsTags.publicBuyer, publicBuyerOption);

    checkAddOpsTag(opsTags.onboardingBuyerNeeded, onboardingBuyerNeededOption);
    checkAddOpsTag(opsTags.onboardingBuyerOkEmail, onboardingBuyerOkEmailOption);
    checkAddOpsTag(opsTags.onboardingBuyerOkPhone, onboardingBuyerOkPhoneOption);

    checkAddOpsTag(opsTags.runningCheckBuyerNeeded, runningCheckBuyerNeededOption);
    checkAddOpsTag(opsTags.runningCheckOkEmail, runningCheckOkEmailOption);
    checkAddOpsTag(opsTags.runningCheckOkPhone, runningCheckOkPhoneOption);

    checkAddOpsTag(opsTags.subrogationLetterNeeded, subrogationLetterNeededOption);

    return opsTagsOptions;
};

export const getOpsTagsFromArray = (opsTagsSet: ReadonlyArray<IOption<number>>): IInvoiceOpsTags => ({
    fraud: opsTagsSet.includes(fraudOption),
    specialSeeNotes: opsTagsSet.includes(specialSeeNotesOption),
    virtualIbanVisible: opsTagsSet.includes(virtualIbanVisibleOption),
    mandatoryPayer: opsTagsSet.includes(mandatoryPayerOption),
    notInsured: opsTagsSet.includes(notInsuredOption),
    specificPaymentFlow: opsTagsSet.includes(specificPaymentFlowOption),
    publicBuyer: opsTagsSet.includes(publicBuyerOption),

    onboardingBuyerNeeded: opsTagsSet.includes(onboardingBuyerNeededOption),
    onboardingBuyerOkEmail: opsTagsSet.includes(onboardingBuyerOkEmailOption),
    onboardingBuyerOkPhone: opsTagsSet.includes(onboardingBuyerOkPhoneOption),

    runningCheckBuyerNeeded: opsTagsSet.includes(runningCheckBuyerNeededOption),
    runningCheckOkEmail: opsTagsSet.includes(runningCheckOkEmailOption),
    runningCheckOkPhone: opsTagsSet.includes(runningCheckOkPhoneOption),

    subrogationLetterNeeded: opsTagsSet.includes(subrogationLetterNeededOption)
});

export const UpdateOpsTagsEnabled = (
    {
        invoiceId,
        axios,
        opsTags,
        kind,
        size,
        text
    } : {
        invoiceId: string;
        axios: Axios;
        opsTags: ReadonlyArray<IOption<number>>;
        kind: string;
        size: string;
        text: string;
    }
) => {

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateOpsTagsMutation: UseMutationResult<void, any, Array<number>> =
        useMutation<void, any, Array<number>>(
            async (opsTagsValues) => {
                await axios.put(
                    `/adminInvoices/${invoiceId}/opsTags`,
                    {
                        opsTags: opsTagsValues
                    }
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                        invoiceDetailsQueryKey(invoiceId),
                        (old: IInvoiceDetailsQueryResult | undefined) => ({
                            ...old!,
                            invoiceDetails: {
                                ...old!.invoiceDetails,
                                opsTags: getOpsTagsFromArray(opsTags)
                            }
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateInvoiceOpsTags'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateOpsTagsMutation}
                               displayFullError={true}
                               value={opsTags.map((o) => o.value)} />
    );
}
