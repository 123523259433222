import IAccessToken from "../../IAccessToken";
import { AddSellerUrl } from "./sellersUrls";
import WithListWithSearchLayoutPage from "../../utils/WithListWithSearchLayoutPage";
import WithTabs, {ITab} from "../../utils/WithTabs";
import DefaultInput from 'factor-lib/forms/Inputs/DefaultInput';
import {ISellerQueryResult, ISellersQueryResult, SellersQuery, sellersQueryKey} from "./sellersQuery";
import SellersListLoaded from "./SellersListLoaded";
import WithSearchList from "../../utils/WithSearchList";
import GraphQLQueryWrapper from "../../utils/GraphQLQueryWrapper";
import Loader from 'factor-lib/Loader';

interface IFilterValue {
    filter: (s: ISellerQueryResult) => boolean;
    displaySpecific: boolean;
}

type IFilter = ITab<IFilterValue>;


const AllFilter: IFilter = {
    tabText: 'Tous',
    value: {
        filter: () => true,
        displaySpecific: true
    }
};

const filters: IFilter[] = [
    AllFilter,
    {
        tabText: 'Direct',
        value: {
            filter: (seller) => !!seller.customer.directSeller,
            displaySpecific: false
        }
    },
    {
        tabText: 'Marketplace',
        value: {
            filter: (seller) => !seller.customer.directSeller,
            displaySpecific: false
        }
    }
];

const SellersPage = (
    {
        accessTokenO
    }: {
        accessTokenO: IAccessToken | null;
    }
) =>
    <WithListWithSearchLayoutPage outletContext={{accessTokenO}}
                                  defaultSize='30%'
                                  addButtonPropsO={(!!accessTokenO?.authUserO?.canManageProfiles && ({
                                      id: 'addSellerButton',
                                      text: 'Ajouter un Seller',
                                      url: AddSellerUrl
                                  })) || null}>
        <WithSearchList autofocus={false}
                        inputElement={
                            (
                                setFocused: (newFocus: boolean) => void,
                                searchInput: string,
                                setSearchInput: (newInput: string) => void
                            ) =>
                                <DefaultInput inputValue={searchInput}
                                              placeHolder='Recherche'
                                              enabled={{
                                                  updateInputValue: setSearchInput,
                                                  validateInput: () => null,
                                                  // tryComplete,
                                                  uponFocus: () => setFocused(true),
                                                  uponBlur: () => setFocused(false)
                                              }} />
                        }
                        childrenFactory={(searchInput: string) =>
                            <WithTabs<IFilterValue> filters={filters}
                                                    defaultFilter={AllFilter}
                                                    childrenFactory={(activeFilter: IFilterValue) =>
                                                        !!accessTokenO
                                                            ? <GraphQLQueryWrapper<ISellersQueryResult> queryKey={sellersQueryKey}
                                                                                                        queryParams={{
                                                                                                            query: SellersQuery
                                                                                                        }}
                                                                                                        axios={accessTokenO.axios}
                                                                                                        onLoading={() => <Loader />}
                                                                                                        onSuccess={((r: ISellersQueryResult) =>
                                                                                                                <SellersListLoaded sellers={r.sellers}
                                                                                                                                   activeFilter={activeFilter.filter}
                                                                                                                                   searchInput={searchInput}
                                                                                                                                   displaySpecific={activeFilter.displaySpecific} />
                                                                                                        )} />
                                                            : <Loader />
                                                    }
                                            />
                        }/>
    </WithListWithSearchLayoutPage>;

export default SellersPage;
