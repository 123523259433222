import { IInvoiceDetailsCustomerFinancingRequestStatus, IInvoiceMindee } from "../invoiceDetailsQuery";
import InvoiceBaseInfos from "./InvoiceInfos/InvoiceBaseInfos";
import InvoiceFiles from "./InvoiceInfos/InvoiceFiles";
import InvoiceUpdateMajorInfos from "./InvoiceInfos/InvoiceUpdateMajorInfos";
import InvoiceUpdateMinorInfos from "./InvoiceInfos/InvoiceUpdateMinorInfos";
import { Axios } from "axios";
import InvoiceMindeeInfos from "./InvoiceInfos/InvoiceMindeeInfos";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";

const InvoiceBaseInfosSection = (
    {
        axios,
        invoiceId,
        invoiceFileName,
        buyerCompany,
        buyerEmails,
        buyerPhones,
        number,
        issueDate,
        dueDate,
        amountWoTax,
        amountTax,
        mindee,
        completePaidDate,
        partialAmountPaid,
        customerFinancingRequest
    } : {
        axios: Axios;
        invoiceId: string;
        invoiceFileName: string;
        buyerCompany: {
            id: string;
            identifier: IIntCompanyIdentifier;
        };
        buyerEmails: string[];
        buyerPhones: string[];
        number: string;
        issueDate: Date;
        dueDate: Date;
        amountWoTax: number;
        amountTax: number;
        mindee: IInvoiceMindee | null;
        completePaidDate: string | null;
        partialAmountPaid: number;
        customerFinancingRequest: IInvoiceDetailsCustomerFinancingRequestStatus | null;
    }
) =>
    <div>
        <InvoiceBaseInfos number={number}
                          issueDate={issueDate}
                          dueDate={dueDate}
                          amountWoTax={amountWoTax}
                          amountTax={amountTax}
                          completePaidDate={completePaidDate}
                          partialAmountPaid={partialAmountPaid} />

        <InvoiceMindeeInfos mindee={mindee} />

        <div className='p-margin-top-4 p-horizontal-center'>
            <InvoiceFiles axios={axios}
                          invoiceId={invoiceId}
                          invoiceFileName={invoiceFileName} />

            <div className='p-margin-left-5'>
                <InvoiceUpdateMinorInfos axios={axios}
                                         invoiceId={invoiceId}
                                         number={number}
                                         buyerEmails={buyerEmails}
                                         buyerPhones={buyerPhones} />
            </div>

            { (!customerFinancingRequest || (!customerFinancingRequest.accepted && !customerFinancingRequest.rejectedDate)) &&
                <div className='p-margin-left-5'>
                    <InvoiceUpdateMajorInfos axios={axios}
                                             invoiceId={invoiceId}
                                             buyerCompany={buyerCompany}
                                             issueDate={issueDate}
                                             dueDate={dueDate}
                                             amountWoTax={amountWoTax}
                                             amountTax={amountTax} />
                </div>
            }
        </div>
    </div>;

export default InvoiceBaseInfosSection;
