import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";

export const CompaniesSearchQueryKeyPrefix = ['companiesSearch'];
export const companiesSearchQueryKey = (searchInput: string) =>
    [...CompaniesSearchQueryKeyPrefix, searchInput];

export interface ICompanyQueryResult {
    id: string;
    name: string;
    identifier: IIntCompanyIdentifier;
}

export interface ICompaniesQueryResult {
    companies: Array<ICompanyQueryResult>;
}
