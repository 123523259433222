import { KIND_PRIMARY, SIZE_COMPACT } from "factor-lib/Buttons/Button";
import { useState } from "react";
import IAuthUser from "../../../IAuthUser";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import MultipleSelect from "factor-lib/forms/Select/MultipleSelect";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import { IInvoiceOpsTags } from "../invoiceDetailsQuery";
import { InvoiceUpdateOpsNotesEnabled } from "./InvoiceOpsInfos/InvoiceUpdateOpsNotes";
import { getInitialOpsTags, opsTagsOptions, UpdateOpsTagsEnabled } from "./InvoiceOpsInfos/InvoiceUpdateOpsTags";
import { getInitialOpsType, opsTypeOptions, UpdateOpsTypeEnabled } from "./InvoiceOpsInfos/InvoiceUpdateOpsType";
import IOption from "factor-lib/forms/Select/IOption";
import {Axios} from "axios";
import { arraysEqual } from "../../../utils/arrayUtils";
import { isValidString } from "factor-lib/forms/Inputs/utils";

const UpdateText = 'Modifier';

const InvoiceOpsInfosSection = (
    {
        axios,
        authUser,
        invoiceId,
        opsType,
        opsTags,
        opsNotes
    } : {
        axios: Axios;
        authUser: IAuthUser | null;
        invoiceId: string;
        opsType: string | null;
        opsTags: IInvoiceOpsTags;
        opsNotes: string | null;
    }
) => {
    const canUpdateOpsInfos: boolean = !!authUser?.canManageFinancings;
    const initialOpsTags: Array<IOption<number>> = getInitialOpsTags(opsTags);

    const [ selectedOpsType, setSelectedOpsType ] = useState(getInitialOpsType(opsType));
    const [ selectedOpsTags, setSelectedOpsTags ] = useState<ReadonlyArray<IOption<number>>>(initialOpsTags);
    const [ newOpsNotes, setNewOpsNotes ] = useState(opsNotes ?? '');

    return (
        <div>
            <div className='columns p-margin-top-5'>
                <div className='column is-one-third'>
                    <div className='p-horizontal-center p-margin-bottom-5'>
                        <span className='p-size-5 p-margin-right-5'>Ops Type</span>
                        { canUpdateOpsInfos && (
                            (!!selectedOpsType && selectedOpsType.value !== opsType)
                                ? <UpdateOpsTypeEnabled axios={axios}
                                                        invoiceId={invoiceId}
                                                        opsType={selectedOpsType.value}
                                                        kind={KIND_PRIMARY}
                                                        size={SIZE_COMPACT}
                                                        text={UpdateText} />
                                : <ButtonDisabled kind={KIND_PRIMARY}
                                                  size={SIZE_COMPACT}
                                                  text={UpdateText} />

                        ) }
                    </div>
                    <SingleSelect options={opsTypeOptions}
                                  selectedOption={selectedOpsType}
                                  selectOption={setSelectedOpsType} />
                </div>

                <div className='column is-two-thirds'>
                    <div className='p-horizontal-center p-margin-bottom-5'>
                        <span className='p-size-5 p-margin-right-5'>Ops Tags</span>
                        { canUpdateOpsInfos && (
                            !arraysEqual(initialOpsTags, selectedOpsTags, t => t.value)
                                ? <UpdateOpsTagsEnabled axios={axios}
                                                        invoiceId={invoiceId}
                                                        opsTags={selectedOpsTags}
                                                        kind={KIND_PRIMARY}
                                                        size={SIZE_COMPACT}
                                                        text={UpdateText} />
                                : <ButtonDisabled kind={KIND_PRIMARY}
                                                  size={SIZE_COMPACT}
                                                  text={UpdateText} />

                        ) }
                    </div>
                    <MultipleSelect options={opsTagsOptions}
                                    selectedOptions={selectedOpsTags}
                                    setSelectedOptions={setSelectedOpsTags} />
                </div>
            </div>

            <div className='p-horizontal-center p-margin-bottom-5'>
                <span className='p-size-5 p-margin-right-5'>Ops Notes</span>
                { canUpdateOpsInfos &&
                    (newOpsNotes === '' || isValidString(newOpsNotes)) &&
                    newOpsNotes !== (opsNotes ?? '')
                        ? <InvoiceUpdateOpsNotesEnabled axios={axios}
                                                        invoiceId={invoiceId}
                                                        opsNotes={newOpsNotes}
                                                        kind={KIND_PRIMARY}
                                                        size={SIZE_COMPACT}
                                                        text={UpdateText} />
                        : <ButtonDisabled kind={KIND_PRIMARY}
                                          size={SIZE_COMPACT}
                                          text={UpdateText} />
                }
            </div>

            <textarea className='textarea'
                      placeholder='Notes'
                      value={newOpsNotes}
                      onChange={(e) => setNewOpsNotes(e.target.value)}
                      disabled={!canUpdateOpsInfos}  />
        </div>
    );
}

export default InvoiceOpsInfosSection;
