import { Axios } from "axios";
import { useState } from "react";
import Modal from "factor-lib/Modal";
import { ISellerDetailsQueryResult, sellerDetailsQueryKey } from "../../../sellerDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import { IBeneficiary } from "../../../sellerDetailsQuery";
import IPerson, { getPersonParams, getUpdatePersonParams, IUpdatePersonParams, updatePerson } from "../../../../../../utils/Person/IPerson";
import PersonInput, { getInitialExistingPersonInput, getInitialNewPersonInput, IPersonInput } from "../../../../../../utils/Person/PersonInput";
import { ResultOrErrors } from "../../../../../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../../../../../utils/ShowValidationErrorsButton";
import { SIZE_FIXED, KIND_PRIMARY } from "factor-lib/Buttons/Button";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IAddressOInput} from "../../../../../../utils/AddressInput";

const ButtonUpdateText = 'Modifier';
const ButtonAddText = 'Ajouter';
const ButtonSize = SIZE_FIXED;
const ButtonKind = KIND_PRIMARY;

const AddBeneficiaryEnabled = (
    {
        axios,
        sellerId,
        directSellerId,
        params,
        closeModal
    } : {
        axios: Axios;
        sellerId: string;
        directSellerId: string;
        params: IPerson;
        closeModal: () => void;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const addBeneficiaryMutation: UseMutationResult<string, any, IPerson, null> =
        useMutation<string, any, IPerson, null>(
            async (addParams2: IPerson) =>
                (await axios.post<string>(
                    `adminDirectSellerUltimateBeneficiaries/${directSellerId}`,
                    addParams2
                )).data,
            ({
                onSuccess: (beneficiaryId: string) => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old?: ISellerDetailsQueryResult) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                customer: {
                                    ...old!.sellerDetails.customer,
                                    directSeller: {
                                        ...old!.sellerDetails.customer.directSeller!,
                                        ultimateBeneficiaries: [
                                            ...old!.sellerDetails.customer.directSeller!.ultimateBeneficiaries,
                                            ({
                                                id: beneficiaryId,
                                                ...params
                                            })
                                        ]
                                    }
                                }
                            }
                        })
                    );
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='addBeneficiaryButton'
                               kind={ButtonKind}
                               size={ButtonSize}
                               text={ButtonAddText}
                               mutation={addBeneficiaryMutation}
                               displayFullError={true}
                               value={params} />
    );
};

const UpdateBeneficiaryEnabled = (
    {
        axios,
        sellerId,
        directSellerId,
        beneficiaryId,
        updateParams,
        closeModal
    } : {
        axios: Axios;
        sellerId: string;
        directSellerId: string;
        beneficiaryId: string;
        updateParams: IUpdatePersonParams;
        closeModal: () => void;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateBeneficiaryInfosMutation: UseMutationResult<void, any, IUpdatePersonParams, null> =
        useMutation<void, any, IUpdatePersonParams, null>(
            async (up: IUpdatePersonParams) =>
                (await axios.put<void>(
                    `adminDirectSellerUltimateBeneficiaries/${directSellerId}/${beneficiaryId}`,
                    up
                )).data,
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old?: ISellerDetailsQueryResult) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                customer: {
                                    ...old!.sellerDetails.customer,
                                    directSeller: {
                                        ...old!.sellerDetails.customer.directSeller!,
                                        ultimateBeneficiaries: old!.sellerDetails.customer.directSeller!.ultimateBeneficiaries.map(
                                            (beneficiary) => beneficiary.id === beneficiaryId
                                                ? {
                                                    id: beneficiary.id,
                                                    ...updatePerson(beneficiary, updateParams)
                                                }
                                                : beneficiary
                                        )
                                    }
                                }
                            }
                        })
                    );
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateBeneficiaryButton'
                               kind={ButtonKind}
                               size={ButtonSize}
                               text={ButtonUpdateText}
                               mutation={updateBeneficiaryInfosMutation}
                               displayFullError={true}
                               value={updateParams} />
    );
};

const BeneficiaryModal = (
    {
        axios,
        sellerId,
        directSellerId,
        beneficiary,
        closeModal
    } : {
        axios: Axios;
        sellerId: string;
        directSellerId: string;
        beneficiary: IBeneficiary | null;
        closeModal: () => void;
    }
) => {
    // TODO : review, looks like a generic pattern
    const beneficiaryInput: IPersonInput = !!beneficiary
        ? getInitialExistingPersonInput(beneficiary)
        : getInitialNewPersonInput(true);

    const [firstNameInput, setFirstNameInput] = useState(beneficiaryInput.firstName);
    const [lastNameInput, setLastNameInput] = useState(beneficiaryInput.lastName);
    const [nationalityCodeInput, setNationalityCodeInput] = useState(beneficiaryInput.nationalityCode);

    const [birthDateInput, setBirthDateInput] = useState(beneficiaryInput.birthDate);
    const [birthCityInput, setBirthCityInput] = useState(beneficiaryInput.birthCity);
    const [birthCountryCodeInput, setBirthCountryCodeInput] = useState(beneficiaryInput.birthCountryCode);

    const [residenceAddressInput, setResidenceAddressInput] = useState<IAddressOInput>(beneficiaryInput.residenceAddress);

    const beneficiaryParams: ResultOrErrors<IPerson> = getPersonParams(
        firstNameInput,
        lastNameInput,
        nationalityCodeInput,

        true,

        birthDateInput,
        birthCityInput,
        birthCountryCodeInput,

        residenceAddressInput
    );

    const updateBeneficiaryParams: ResultOrErrors<IUpdatePersonParams> | null = !!beneficiary
        ? getUpdatePersonParams(beneficiary, beneficiaryParams)
        : null;

    return (
        <Modal id='beneficiaryModal'
               maxWidth='640px'
               fullMaxWidth={true}
               close={closeModal}>
            <div className='p-padding-3'>
                <PersonInput firstNameInput={firstNameInput}
                             lastNameInput={lastNameInput}
                             nationalityCodeInput={nationalityCodeInput}
                             isBirthInfosMandatory={true}
                             birthDateInput={birthDateInput}
                             birthCityInput={birthCityInput}
                             birthCountryCodeInput={birthCountryCodeInput}
                             residenceAddressInput={residenceAddressInput}
                             enabled={{
                                 setFirstNameInput: setFirstNameInput,
                                 setLastNameInput: setLastNameInput,
                                 setNationalityCodeInput: setNationalityCodeInput,
                                 setBirthDateInput: setBirthDateInput,
                                 setBirthCityInput: setBirthCityInput,
                                 setBirthCountryCodeInput: setBirthCountryCodeInput,
                                 setResidenceAddressInput: setResidenceAddressInput,
                                 isBirthInfosMandatory: true,
                                 autofocus: true
                             }} />

                <div className='p-margin-top-4 p-vertical-center'>
                    { !!beneficiary
                        ? !!updateBeneficiaryParams
                            ? !!updateBeneficiaryParams.result
                                ? <UpdateBeneficiaryEnabled axios={axios}
                                                            sellerId={sellerId}
                                                            directSellerId={directSellerId}
                                                            beneficiaryId={beneficiary.id}
                                                            updateParams={updateBeneficiaryParams.result}
                                                            closeModal={closeModal} />
                                : <ShowValidationErrorsButton validationErrors={updateBeneficiaryParams.errors!}
                                                              kind={ButtonKind}
                                                              size={ButtonSize}
                                                              text={ButtonUpdateText} />
                            : <ButtonDisabled kind={ButtonKind}
                                              size={ButtonSize}
                                              text={ButtonUpdateText} />
                        : !!beneficiaryParams.result
                            ? <AddBeneficiaryEnabled axios={axios}
                                                     sellerId={sellerId}
                                                     directSellerId={directSellerId}
                                                     params={beneficiaryParams.result}
                                                     closeModal={closeModal} />
                            : <ShowValidationErrorsButton validationErrors={beneficiaryParams.errors!}
                                                          kind={ButtonKind}
                                                          size={ButtonSize}
                                                          text={ButtonAddText} />
                    }
                </div>
            </div>
        </Modal>
    );
};

export default BeneficiaryModal;
