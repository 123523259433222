import { comparingRev } from "factor-lib/utils/comparingUtils";
import { serverDateDeserialization, comparingDate } from "factor-lib/utils/dateUtils";
import { formatAdminDateStr } from "../../../../utils/dateTimeUtils";
import { IFinancingEmail } from "./InvoiceFinancingEmailsTable";

const compareInvoiceFinancingEmailDate = comparingRev(
    comparingDate((financingEmail: IFinancingEmail) =>
        serverDateDeserialization(financingEmail.sentDate)
    )
);

const InvoiceFinancingEmailsTableLoaded = (
    {
        financingEmails
    }: {
        financingEmails: IFinancingEmail[];
    }
) =>
    <tbody>
        { financingEmails
            .sort(compareInvoiceFinancingEmailDate)
            .map((e, index) => (
                <tr key={`invoice-financing-email-${index}`}>
                    <td>{ formatAdminDateStr(e.sentDate) }</td>
                    <td>{ e.sentTo }</td>
                    <td>{ e.templateName} </td>
                    <td>{ e.subject }</td>
                    <td>{ e.status }</td>
                    <td>{ e.opensCount }</td>
                    <td>{ e.clicksCount }</td>
                </tr>
            ))
        }
    </tbody>;

export default InvoiceFinancingEmailsTableLoaded;
