import router from "./router";
import {RouterProvider} from "react-router-dom";

const RouterWrapper = () => {

    // https://reactrouter.com/en/main/hooks/use-location

    // Semble inutile : est deja configure dans https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin

    // const location: Location = useLocation();
    //
    // useEffect(() => {
    //     // Google Analytics
    //     location
    // }, [location]);

    return (
        <RouterProvider router={router} />
    );
}

export default RouterWrapper;