import {NavigateFunction} from "react-router-dom";
import Button from 'factor-lib/Buttons/Button';
import navigateToNewSirenForBilans from "./navigateToNewSirenForBilans";
import {useContext} from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";

const SearchRedirect = (
    {
        kind,
        size,
        text,
        siren
    }: {
        kind: string;
        size: string;
        text:string;
        siren: string;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    return (
        <Button id='dataSearchRedirect'
                kind={kind}
                size={size}
                text={text}

                isLoading={false}
                actionO={() => navigateToNewSirenForBilans(navigate, siren)} />
    );
}

export default SearchRedirect;
