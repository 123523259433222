import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import IUrlWithContentType from "factor-lib/forms/Upload/IUrlWithContentType";
import { ConfidenceFlag } from "../../utils/ConfidenceFlags";

export const preInvoiceDetailsPrefix = ['preInvoice', 'details'];
export const preInvoiceDetailsQueryKey = (preInvoiceId: string) =>
    [ ...preInvoiceDetailsPrefix, preInvoiceId];

export interface IPreInvoiceMatchingSeller {
    id: string;
    isBlocked: boolean;
    certif: {
        solvaConfidenceFlag: ConfidenceFlag;
        fraudConfidenceFlag: ConfidenceFlag;
    } | null;
    company:  {
        id: string;
        identifier: IIntCompanyIdentifier;
    };
}

interface IPreInvoiceMatchingBuyer {
    value: string;
    buyer: {
        id: string;
        company: {
            id: string;
            identifier: IIntCompanyIdentifier;
            name: string;
        };
    };
}

export interface IPreInvoiceDetailsEmail {
    id: string;
    sentDateTime: string;
    thread: {
        link: string;
        length: number;
    };

    title: string;
    senderEmail: string;
    recipientEmails: string[];

    matchingSeller: IPreInvoiceMatchingSeller | null;
    matchingBuyerEmail: IPreInvoiceMatchingBuyer | null;
}

export interface IPreInvoiceDetails {
    // id: string;
    creationDateTime: string; // Only to update the table query
    status: number;
    email: IPreInvoiceDetailsEmail;
    blobFile: IUrlWithContentType;

    invoiceNumber: string | null;
    invoiceNumberConfidence: number;

    amountWoTax: number | null;
    amountWoTaxConfidence: number;

    amountTax: number | null;
    amountTaxConfidence: number;

    issueDate: string | null;
    issueDateConfidence: number;

    dueDate: string | null;
    dueDateConfidence: number;
}

// export enum PreInvoiceStatus
// {
//     Pending = 0,
//     Rejected = 1,
//     Approved = 2
// }

export interface IPreInvoiceDetailsQueryResult {
    preInvoiceDetails: IPreInvoiceDetails;
}
