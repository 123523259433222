import BuyerLimitsTable from "./BuyerLimitsTable";
import GraphQLQueryWrapper from "../../utils/GraphQLQueryWrapper";
import { buyersWithLimitSearchQueryKey, IBuyerCompaniesWithLimitQueryResult } from "./BuyerLimitsQuery";
import BuyerLimitsListLoaded from "./BuyerLimitsListLoaded";
import { IExistingBuyer } from "./BuyerLimitsPage";
import { useState} from "react";
import { Axios } from "axios";
import Loader from "factor-lib/Loader";
import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import InputDelayed from 'factor-lib/forms/Inputs/InputDelayed';
import SearchIcon from "../../utils/SearchIcon";

const searchId = 'companySearchId';
const MinCharactersCount = 3;

const BuyerLimitsDisplay = (
    {
        axiosO,
        setDisplayUpdateSingleModal
    }: {
        axiosO: Axios | null;
        setDisplayUpdateSingleModal: (buyer: IExistingBuyer) => void;
    }
) => {
    const [searchInput, setSearchInput] = useState('');

    return (
        <div>
            <div className='p-margin-bottom-4' style={{maxWidth: '400px'}}>
                <InputDelayed inputClassName='p-size-62'
                              inputValue={searchInput}
                              placeholder='Rechercher un acheteur (par nom)'
                              rightSlot={<SearchIcon />}
                              enabled={{
                                  innerId: {
                                    value: searchId,
                                    autofocus: true
                                  },
                                  minCharactersCount: MinCharactersCount,
                                  validateInput: () => null,
                                  updateInputValue: setSearchInput,
                              }} />
            </div>

            { (searchInput.trim().length >= MinCharactersCount) && (
                !!axiosO
                    ? <BuyerLimitsTable>
                        <GraphQLQueryWrapper axios={axiosO}
                                             queryKey={buyersWithLimitSearchQueryKey(searchInput)}
                                             queryParams={{
                                                 query: `query ($searchInput: String!) {
                                                    companies (hasOutstandingLimitsAsBuyer: true, search: $searchInput) {
                                                        id
                                                        name
                                                        identifier { ${ IntCompanyIdentifierGraphQLFields } }
                                                        asBuyer {
                                                            manualOutstandingLimit
                                                            outstanding
                                                        }
                                                    }
                                                 }`,
                                                 variables: {
                                                     searchInput
                                                 }
                                             }}
                                             onLoading={() =>
                                                 <tbody>
                                                     <tr>
                                                         <td>
                                                             <Loader />
                                                         </td>
                                                     </tr>
                                                 </tbody>
                                             }
                                             onSuccess={(r: IBuyerCompaniesWithLimitQueryResult) =>
                                                 <BuyerLimitsListLoaded axios={axiosO}
                                                                        buyerCompanies={r.companies}
                                                                        setDisplayUpdateSingleModal={setDisplayUpdateSingleModal}/>
                                             } />
                    </BuyerLimitsTable> : <Loader/>
            )}
        </div>
    );
}

export default BuyerLimitsDisplay;