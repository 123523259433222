import { searchBySirenAsync } from "factor-lib/CompanySearcher/CompanySearcher";
import ISearchCompaniesByNameResponse from "factor-lib/CompanySearcher/ISearchCompaniesByNameResponse";
import { isValidSiren } from "factor-lib/forms/Inputs/sirenUtils";
import {Axios} from "axios";
import ILogger from "factor-lib/services/logger/ILogger";

// TODO : there should already be a file to expose this kind of stuff
const companySearchRequestAsync = async (
    axios: Axios,
    inputCompanySearch: string,
    logger: ILogger
): Promise<ISearchCompaniesByNameResponse> => {
    if (isValidSiren(inputCompanySearch)) {
        return await searchBySirenAsync(
            axios,
            inputCompanySearch,
            logger,
            ['company', 'search', 'siren', inputCompanySearch /* valid siren if used */]
        );
    } // else search by name
    return (await axios.get<ISearchCompaniesByNameResponse>(
        `/adminCompanies/search?name=${encodeURI(inputCompanySearch)}`
    )).data;
};

export default companySearchRequestAsync;