import { ReactElement } from "react";
import IAuthUserLoad from "./IAuthUserLoad";
import IAccessToken from "./IAccessToken";
import { IGraphQLParams} from "factor-lib/serverUtils/graphQLQueryAsync";
import GraphQLQueryWrapper from "./utils/GraphQLQueryWrapper";

const GraphQLWithAccessTokenWrapper = <T, > (
    {
        accessTokenO,
        queryKey,
        queryParams,
        onLoading,
        onSuccess,
        onError
    }: {
        accessTokenO: IAccessToken | null;
        queryKey: string[];
        queryParams: IGraphQLParams;
        onLoading: () => ReactElement;
        onSuccess: (t: IAuthUserLoad<T>) => ReactElement;
        onError?: (e: string) => ReactElement; // TODO: make required?
    }
) =>
    !!accessTokenO
        ? <GraphQLQueryWrapper<T> queryKey={queryKey}
                                  queryParams={queryParams}
                                  axios={accessTokenO.axios}
                                  onLoading={onLoading}
                                  onError={onError}
                                  onSuccess={((r: T) =>
                                          onSuccess({
                                              axios: accessTokenO.axios,
                                              authUserO: accessTokenO?.authUserO,
                                              value: r
                                          })
                                  )} />
        : onLoading();

export default GraphQLWithAccessTokenWrapper;
