export const DimplScoreGraphQLQueryFields =
    `dimplScore {
        errorCode
        valid {
            score
            rawScore
            bucket
            dateSeconds
        }
    }`;

export interface ValidDimplScore {
    score: number;
    rawScore: number;
    bucket: string;
    dateSeconds: number;
}

export default interface IDimplScore {
    errorCode: string; // "Ok" if valid
    valid: ValidDimplScore | null;
}
