import {Axios} from "axios";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { isValidIban, validateIbanError } from "factor-lib/forms/Inputs/ibanUtils";
import { useState } from "react";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import Input from "factor-lib/forms/Inputs/Input";
import { ResultOrErrors } from "../../../../../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../../../../../utils/ShowValidationErrorsButton";
import { ISellerDetailsQueryResult, sellerDetailsQueryKey } from "../../../sellerDetailsQuery";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";


const ConfirmText = 'Confirmer';

const UpdateSellerIbanEnabled = (
    {
        axios,
        sellerId,
        isCertified,
        newIban,
        closeModal,
        kind,
        size,
        text
    } : {
        axios: Axios;
        sellerId: string;
        isCertified: boolean;
        newIban: string;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateSellerIbanMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (newIban2) => {
                await axios.put<void>(
                    `/adminSellers/${sellerId}/iban${isCertified ? 'PostCertif' : 'PreCertif'}`,
                    {
                        iban: newIban2
                    }
                );
            },
            ({
                onSuccess: (_, newIban2) => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old: ISellerDetailsQueryResult | undefined) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                iban: newIban2
                            }
                        })
                    );
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateSellerIbanButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateSellerIbanMutation}
                               displayFullError={true}
                               value={newIban} />
    );
}

const getUpdateIbanParam = (
    oldIbanO: string | null,
    newIbanInput: string
): ResultOrErrors<string> | null =>
    !isValidIban(newIbanInput)
        ? ResultOrErrors.fromError([`L'IBAN est invalide`])
        : newIbanInput !== oldIbanO
            ? ResultOrErrors.fromResult(newIbanInput)
            : null;

const UpdateSellerIbanModalContent = (
    {
        axios,
        sellerId,
        isCertified,
        ibanO,
        closeModal
    }: {
        axios: Axios;
        sellerId: string;
        isCertified: boolean;
        ibanO: string | null;
        closeModal: () => void;
    }
) => {
    const [newIbanInput, setNewIbanInput] = useState(ibanO ?? '');

    const newIbanFailableO: ResultOrErrors<string> | null = getUpdateIbanParam(
        ibanO,
        newIbanInput
    );

    return (
        <div className='p-padding-3'>
            <div>
                <span className='p-margin-right-4 p-bold'>IBAN</span>
                <Input inputValue={newIbanInput}
                       enabled={{
                           updateInputValue: setNewIbanInput,
                           validateInput: () => validateIbanError(newIbanInput)
                       }} />
            </div>

            <div className='p-margin-top-4 p-vertical-center'>
                { !!newIbanFailableO
                    ? !!newIbanFailableO.result
                        ? <UpdateSellerIbanEnabled axios={axios}
                                                   sellerId={sellerId}
                                                   isCertified={isCertified}
                                                   newIban={newIbanFailableO.result}
                                                   closeModal={closeModal}
                                                   kind={KIND_PRIMARY}
                                                   size={SIZE_FIXED}
                                                   text={ConfirmText} />
                        : <ShowValidationErrorsButton validationErrors={newIbanFailableO.errors!}
                                                      kind={KIND_PRIMARY}
                                                      size={SIZE_FIXED}
                                                      text={ConfirmText} />
                    : <ButtonDisabled kind={KIND_PRIMARY}
                                      size={SIZE_FIXED}
                                      text={ConfirmText} />
                }
            </div>
        </div>
    );
}

const UpdateSellerIban = (
    {
        className,
        axios,
        sellerId,
        isCertified,
        ibanO
    }: {
        className?: string;
        axios: Axios;
        sellerId: string;
        isCertified: boolean;
        ibanO: string | null;
    }
) =>
    <ButtonForModal className={className}
                    id='updateSellerIban'
                    text='Modifier Iban'
                    childModalContent={(closeModal) =>
                        <UpdateSellerIbanModalContent axios={axios}
                                               sellerId={sellerId}
                                               isCertified={isCertified}
                                               ibanO={ibanO}
                                               closeModal={closeModal} />
    } />;

export default UpdateSellerIban;
