import Loader from "factor-lib/Loader";
import { BUYER_EVENT_EMAIL_TYPES, BUYER_EVENT_PHONE_TYPES, BUYER_EVENT_TYPES_OPTIONS } from "./buyerEventUtils";
import { Axios } from "axios";
import { IBuyerContacts, IBuyersAndContacts } from "./BuyerEventModal";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import Input from "factor-lib/forms/Inputs/Input";
import IOption from "factor-lib/forms/Select/IOption";
import {
    IBuyerEventContact,
    IBuyerEmail, IBuyerPhone,
    BuyerEmailGraphQLFields, BuyerPhoneGraphQLFields
} from "../buyerCompanyDetailsQuery";
import { ResultOrErrors } from "../../../../utils/ResultOrErrors";
import GraphQLQueryWrapper from "../../../../utils/GraphQLQueryWrapper";
import { comparingString } from "factor-lib/utils/comparingUtils";


export const getBuyerEventContactParam = (
    contactInput: IBuyerEventContact | null
): ResultOrErrors<string> | null => {
    if (!contactInput) {
        return ResultOrErrors.fromError(['le contact est invalide']);
    }

    return ResultOrErrors.fromResult(contactInput.id ?? null);
};

export const getUpdateBuyerEventContactParams = (
    oldContactO: IBuyerEventContact | null,
    id: string
): string | null => {
    if (oldContactO === null) {
        return id;
    }

    return (
        id !== oldContactO.id
    )
        ? id
        : null;
};

interface IBuyerForMap {
    id: string;
    seller: {
        company: {
            name: string;
        };
    };
}

const buildContactOption = (
    value: string,
    id: string,
    buyer: IBuyerForMap | null
): IOption<IBuyerEventContact> => ({
    value: {
        value,
        id
    },
    label: `[${!!buyer ? buyer.seller.company.name : 'OTHER'}] ${value}`
});


const buildContactOptions = (
    buyers: IBuyerForMap[],
    buyersEmails: IBuyerEmail[],
    buyersPhones: IBuyerPhone[],
    isEmail: boolean
): Array<IOption<IBuyerEventContact>> => {
    const buyersMap = new Map<string, IBuyerForMap>(buyers.map((b) => [b.id, b]));

    return isEmail
        ? buyersEmails
            .map((sc) => buildContactOption(sc.value, sc.id, buyersMap.get(sc.buyer.id)!))
        : buyersPhones
            .map((sc) => buildContactOption(sc.value, sc.id, buyersMap.get(sc.buyer.id)!))
            ;
};

const BuyerEventContactSelectorLoaded = (
    {
        buyers,
        buyerEmails,
        buyerPhones,
        isEmail,
        contactInput,
        setContactInput
    }: {
        buyers: IBuyerForMap[];
        buyerEmails: IBuyerEmail[];
        buyerPhones: IBuyerPhone[];
        isEmail: boolean;
        contactInput: IBuyerEventContact | null; // this null -> not yet selected
        setContactInput: (newValue: IBuyerEventContact | null) => void;
    }
) => {
    const contactOptions = buildContactOptions(
        buyers,
        buyerEmails,
        buyerPhones,
        isEmail
    );

    return (
        <div>
            <SingleSelect options={contactOptions.sort(comparingString((o) => o.label))}
                          selectOption={(newOption) => setContactInput(
                              newOption?.value ?? null
                          )}
                          selectedOption={contactOptions.find((o) =>
                              o.value.id !== null
                                  ? o.value.id === contactInput?.id
                                  : false
                          ) ?? null} />
        </div>
    );
}

export interface IBuyerContactsQueryResult extends IBuyerContacts {
    buyers: IBuyerForMap[];
}

export const buyerEventContactsQueryKey = (buyerCompanyId: string) =>
    ['buyerContacts', buyerCompanyId];

const BuyerEventContact = (
    {
        className,
        axios,
        buyerCompanyId,
        buyersAndContactsLoaded,
        isEmail,
        contactInput,
        setContactInput
    }: {
        className?: string;
        axios: Axios;
        buyerCompanyId: string;
        buyersAndContactsLoaded: IBuyersAndContacts | null;
        isEmail: boolean;
        contactInput: IBuyerEventContact | null; // this null -> not yet selected
        setContactInput: ((newValue: IBuyerEventContact | null) => void) | null;
    }
) =>
    <div className={className}>
        <span className='p-bold'>Buyer { isEmail ? 'Email' : 'Phone' }</span>

        { !!setContactInput
            ? !!buyersAndContactsLoaded
                ? <BuyerEventContactSelectorLoaded buyers={buyersAndContactsLoaded.buyers}
                                                   buyerEmails={buyersAndContactsLoaded.buyerEmails}
                                                   buyerPhones={buyersAndContactsLoaded.buyerPhones}
                                                   isEmail={isEmail}
                                                   contactInput={contactInput}
                                                   setContactInput={setContactInput} />
                : <GraphQLQueryWrapper queryKey={buyerEventContactsQueryKey(buyerCompanyId)}
                                       axios={axios}
                                       queryParams={{
                                           query: `query Q ($buyerCompanyId: Guid!) {
                                               buyers (companyId: $buyerCompanyId) { id seller { company { name } } }
                                               buyerEmails (companyId: $buyerCompanyId) { ${BuyerEmailGraphQLFields} }
                                               buyerPhones (companyId: $buyerCompanyId) { ${BuyerPhoneGraphQLFields} }
                                           }`,
                                           variables: {
                                               buyerCompanyId
                                           }
                                       }}
                                       onLoading={() => <Loader />}
                                       onSuccess={(r: IBuyerContactsQueryResult) =>
                                           <BuyerEventContactSelectorLoaded buyers={r.buyers}
                                                                            buyerEmails={r.buyerEmails}
                                                                            buyerPhones={r.buyerPhones}
                                                                            isEmail={isEmail}
                                                                            contactInput={contactInput}
                                                                            setContactInput={setContactInput!} />
                                       } />
            : <Input inputValue={contactInput?.value ?? ''}
                     enabled={null} />
        }
    </div>;

const BuyerEventTypeAndContact = (
    {
        className,
        axios,
        buyerCompanyId,
        buyersAndContactsLoaded,
        eventType,
        contactInput,
        enabled
    }: {
        className?: string;
        axios: Axios;
        buyerCompanyId: string;
        buyersAndContactsLoaded: IBuyersAndContacts | null;
        eventType: string;
        contactInput: IBuyerEventContact | null;
        enabled: {
            setEventType: (newValue: string) => void;
            setContactInput: (newValue: IBuyerEventContact | null) => void;
        } | null;
    }
) => {
    const isEmail = BUYER_EVENT_EMAIL_TYPES.includes(eventType);
    const isPhone = BUYER_EVENT_PHONE_TYPES.includes(eventType);

    return (
        <div className={className}>
            <div>
                <span className='p-bold'>Type</span>
                { !!enabled
                    ? <SingleSelect options={BUYER_EVENT_TYPES_OPTIONS}
                                    selectedOption={{
                                        label: eventType,
                                        value: eventType
                                    }}
                                    selectOption={(newOption) => {
                                        const newEventType = newOption!.value;
                                        const newIsEmail = BUYER_EVENT_EMAIL_TYPES.includes(newEventType);
                                        const newIsPhone = BUYER_EVENT_PHONE_TYPES.includes(newEventType);
                                        if (isEmail !== newIsEmail || isPhone !== newIsPhone) {
                                            enabled.setContactInput(null);
                                        } else if (!newIsEmail && !newIsPhone && !!contactInput) {
                                            enabled.setContactInput(null);
                                        }
                                        enabled.setEventType(newEventType);
                                    }}
                                    clearable={false} />

                    : <Input inputValue={eventType}
                             enabled={null} />
                }
            </div>

            { (isEmail || isPhone) &&
                <BuyerEventContact className='p-margin-top-4'
                                   axios={axios}
                                   buyerCompanyId={buyerCompanyId}
                                   buyersAndContactsLoaded={buyersAndContactsLoaded}
                                   isEmail={isEmail}
                                   contactInput={contactInput}
                                   setContactInput={!!enabled ? enabled.setContactInput : null} />
            }
        </div>
    );
};

export default BuyerEventTypeAndContact;
