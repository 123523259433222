import IAccessToken from "../../IAccessToken";
import { AddCustomerUrl } from "./customersUrls";
import WithListWithSearchLayoutPage from "../../utils/WithListWithSearchLayoutPage";
import DefaultInput from 'factor-lib/forms/Inputs/DefaultInput';
import WithTabs, {ITab} from "../../utils/WithTabs";
import {customerGraphQLQueryFields, customersQueryKey, ICustomerQueryResult} from "./customersQuery";
import CustomersListLoaded from "./CustomersListLoaded";
import WithSearchList from "../../utils/WithSearchList";
import Loader from 'factor-lib/Loader';
import { IPaginated, query } from "factor-lib/utils/graphQLPagination";
import graphQLQueryAsync from "factor-lib/serverUtils/graphQLQueryAsync";
import {getFactorContext} from "../../IFactorContext";
import {InfiniteData, useInfiniteQuery, UseInfiniteQueryResult} from "@tanstack/react-query";
import {Axios} from "axios";
import {
    CustomerSortProperty,
    CustomerSortPropertyToOrderByField,
    CustomerTypeDirectSeller,
    CustomerTypeMarketplace
} from "../../utils/customersQueriesUtils";
import { SortOrder, SortOrderToOrderByDirection } from "factor-lib/utils/sortingUtils";
import ReactQueryResultWrapper from 'factor-lib/reactquery/ReactQueryResultWrapper';

interface IFilterValue {
    graphQLCustomerFilterO: string | null;
    // displayCustomerType: boolean;
}

type IFilter = ITab<IFilterValue /* ICustomerQueryResult, {
    displaySpecific: boolean;
} */>;

const AllFilter: IFilter = {
    tabText: 'Tous',
    value: {
        graphQLCustomerFilterO: null,
        // displayCustomerType: true
    }
    // filter: () => true,
    // prop: {
    //     displaySpecific: true
    // }
};

const filters: IFilter[] = [
    AllFilter,
    {
        tabText: 'Direct',
        value: {
            graphQLCustomerFilterO: CustomerTypeDirectSeller
        }
        // filter: (customer: ICustomerQueryResult) => !customer.marketplace,
        // prop: {
        //     displaySpecific: false
        // }
    },
    {
        tabText: 'Marketplace',
        value: {
            graphQLCustomerFilterO: CustomerTypeMarketplace
        }
        // filter: (customer: ICustomerQueryResult) => !!customer.marketplace,
        // prop: {
        //     displaySpecific: false
        // }
    }
];


const CustomersPageReady = (
    {
        axios,
        searchInputStr,
        activeFilter
    }: {
        axios: Axios;
        searchInputStr: string;
        activeFilter: IFilterValue;
    }
) => {
    const queryKey = customersQueryKey(searchInputStr, activeFilter.graphQLCustomerFilterO || undefined);
    const result: UseInfiniteQueryResult<IPaginated<ICustomerQueryResult>> = useInfiniteQuery<IPaginated<ICustomerQueryResult>>(
        queryKey,
        async ({ signal, pageParam = null }) => (await graphQLQueryAsync<any>(
            axios,
            {
                query: `query ($after: PaginationGuidCursor, $search: String, $types: [CustomerTypeEnum]) {
                    customers(search: $search, types: $types) {
                        list(
                            first: 50,
                            after: $after,
                            orderByField: ${CustomerSortPropertyToOrderByField.get(CustomerSortProperty.CreationDate)!}
                            orderByDirection: ${SortOrderToOrderByDirection.get(SortOrder.Desc)!}
                        ) ${query(customerGraphQLQueryFields)}
                    }
                }`,
                variables: {
                    search: searchInputStr,
                    after: pageParam,
                    types: !!activeFilter.graphQLCustomerFilterO ? [activeFilter.graphQLCustomerFilterO] : null
                }
            },
            getFactorContext().logger,
            queryKey,
            signal
        )).customers.list as IPaginated<ICustomerQueryResult>,
        {
            getNextPageParam: (lastPage, _) => lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.endCursor : undefined
        }
    );
    return (
        <ReactQueryResultWrapper result={result}
                                 onLoading={() => <Loader />}
                                 displayFullError={true}
                                 onSuccess={(customers: InfiniteData<IPaginated<ICustomerQueryResult>>, isIncrementalLoading: boolean) =>
                                     <CustomersListLoaded customers={customers}
                                                          fetchNextPage={() => result.fetchNextPage()}
                                                          isIncrementalLoading={isIncrementalLoading}
                                         // activeFilter={activeFilter.filter}
                                                          displaySpecific={!!activeFilter.graphQLCustomerFilterO} />}
                                  />
    );
}

const CustomersPage = (
    {
        accessTokenO
    }: {
        accessTokenO: IAccessToken | null;
    }
) =>
    <WithListWithSearchLayoutPage outletContext={{accessTokenO}}
                                  defaultSize='30%'
                                  addButtonPropsO={(!!accessTokenO?.authUserO?.canManageProfiles && ({
                                      id: 'addCustomerButton',
                                      text: 'Ajouter une Plateforme',
                                      url: AddCustomerUrl
                                  })) || null}>
        <WithSearchList autofocus={false}
                        inputElement={
                            (
                                setFocused: (newFocus: boolean) => void,
                                searchInput: string,
                                setSearchInput: (newInput: string) => void
                            ) =>
                                <DefaultInput inputValue={searchInput}
                                              placeHolder='Recherche'
                                              enabled={{
                                                  updateInputValue: setSearchInput,
                                                  validateInput: () => null,
                                                  // tryComplete,
                                                  uponFocus: () => setFocused(true),
                                                  uponBlur: () => setFocused(false)
                                              }} />
                        }
                        childrenFactory={(searchInputStr: string) =>
                            <WithTabs<IFilterValue> filters={filters}
                                                    defaultFilter={AllFilter}
                                                        // queryFactory={(activeFilter: IFilter) => ()}
                                                    childrenFactory={(/* r: ICustomersQueryResult, */ activeFilter: IFilterValue) =>
                                                              !!accessTokenO
                                                                  ? <CustomersPageReady axios={accessTokenO.axios}
                                                                                        searchInputStr={searchInputStr}
                                                                                        activeFilter={activeFilter}/>
                                                                  : <Loader />
                                                          }

                                            />
                        }/>
    </WithListWithSearchLayoutPage>;

export default CustomersPage;
