import Loader from "factor-lib/Loader";
import RadioBox from "factor-lib/forms/Radio/RadioBox";
import { useState } from "react";
import MultipleSelect from "factor-lib/forms/Select/MultipleSelect";
import {Axios} from "axios";
import GraphQLQueryWrapper from "../../../../utils/GraphQLQueryWrapper";
import { comparingString } from "factor-lib/utils/comparingUtils";

export const InvoiceChoiceStatusGraphQLFields =
    'customerFinancingRequest { rejectedDate accepted { financing { settled{ dateTime } } } }';

export interface IBuyerEventInvoiceChoiceStatus {
    customerFinancingRequest: {
        rejectedDate: string | null;
        accepted: {
            financing: {
                settled: {
                    dateTime: string;
                } | null;
            } | null;
        } | null;
    } | null;
}

interface IBuyerEventInvoiceChoice {
    id: string;
    number: string;
    sellerId: string;
    status: IBuyerEventInvoiceChoiceStatus;
}

interface IBuyerEventInvoiceChoiceSeller {
    id: string;
    company: {
        name: string;
    };
}

const BuyerEventInvoiceMultipleSelectWrapper = (
    {
        invoices,
        sellers,
        selectedInvoicesIds,
        setSelectedInvoicesIds,
        setSelectedInvoicesIdsCache,
        alwaysSelectedInvoiceId
    }: {
        invoices: IBuyerEventInvoiceChoice[];
        sellers: IBuyerEventInvoiceChoiceSeller[];
        selectedInvoicesIds: string[];
        setSelectedInvoicesIds: (newValue: string[]) => void;
        setSelectedInvoicesIdsCache: (newValue: string[]) => void;
        alwaysSelectedInvoiceId: string | null;
    }
) => {
    const sellersMap = new Map(
        sellers.map((seller) => [seller.id, seller])
    );

    const invoicesOptionsMap = new Map(
        invoices.map((invoice) => [
            invoice.id,
            {
                label: `[${sellersMap.get(invoice.sellerId)!.company.name}] ${invoice.number}`,
                value: invoice
            }
        ])
    );

    return (
        <MultipleSelect options={Array.from(invoicesOptionsMap.values())}
                        selectedOptions={selectedInvoicesIds
                            .map((invoiceId) => invoicesOptionsMap.get(invoiceId)!)
                            .sort(comparingString((o) => o.label))
                        }
                        setSelectedOptions={(newValue) => {
                            const newSelectedInvoicesIds = newValue.map((o) => o.value.id);
                            if (!!alwaysSelectedInvoiceId && !newSelectedInvoicesIds.includes(alwaysSelectedInvoiceId)) {
                                newSelectedInvoicesIds.push(alwaysSelectedInvoiceId);
                            }
                            setSelectedInvoicesIds(newSelectedInvoicesIds);
                            setSelectedInvoicesIdsCache(newSelectedInvoicesIds);
                        }} />
    );
}

interface IBuyerEventInvoiceChoicesQueryResult {
    invoices: {
        base: IBuyerEventInvoiceChoice[];
        sellers: IBuyerEventInvoiceChoiceSeller[];
    }
}

const buyerEventInvoiceChoicesQueryKey = (buyerCompanyId: string) =>
    ['buyer-event-invoices', buyerCompanyId];

const invoiceChoiceFilter = (initialSelectedInvoiceIds: string[]) =>
    (i: IBuyerEventInvoiceChoice) =>
        (!!i.status.customerFinancingRequest && !i.status.customerFinancingRequest.accepted?.financing?.settled) ||
        initialSelectedInvoiceIds.includes(i.id);

const HasBeenFinancingRequestedInvoiceStatusFilters =
    '[FINANCING_REQUESTED, FINANCING_REQUEST_REJECTED, FINANCING_ACTIVE, FINANCING_SETTLED]';

const BuyerEventInvoiceSelector = (
    {
        className,
        axios,
        buyerCompanyId,
        initialSelectedInvoicesIds,
        selectedInvoicesIds,
        setSelectedInvoicesIds,
        alwaysSelectedInvoiceId
    }: {
        className?: string;
        axios: Axios;
        buyerCompanyId: string;
        initialSelectedInvoicesIds: string[];
        selectedInvoicesIds: string[];
        setSelectedInvoicesIds: (newValue: string[]) => void;
        alwaysSelectedInvoiceId: string | null;
    }
) => {
   const [ displayInvoices, setDisplayInvoices ] = useState(!!alwaysSelectedInvoiceId || selectedInvoicesIds.length > 0);

   // used to cache selected invoices in case ops mistakenly chooses "other"
   const [ selectedInvoicesIdsCache, setSelectedInvoicesIdsCache ] = useState(selectedInvoicesIds);

   return (
        <div className={className}>
            { !alwaysSelectedInvoiceId &&
                <div>
                    <div className='p-bold p-margin-bottom-7'>Contact concernant</div>

                    <div className='level'>
                        <div className='level-left level-item'>
                            <RadioBox id='hasInvoicesInvoiceRadioBoxId'
                                      inputValue={displayInvoices}
                                      setInputValue={() => {
                                          if (!displayInvoices) {
                                              setSelectedInvoicesIds(selectedInvoicesIdsCache);
                                          }
                                          setDisplayInvoices(true);
                                      }}
                                      enabled={true} />
                            <span className='p-margin-left-6'>Financement demandé/en cours</span>
                        </div>

                        <div className='level-right level-item'>
                            <RadioBox id='noInvoicesRadioBoxId'
                                      inputValue={!displayInvoices}
                                      setInputValue={() => {
                                          setDisplayInvoices(false);
                                          setSelectedInvoicesIdsCache(selectedInvoicesIds);
                                          setSelectedInvoicesIds([]);
                                      }}
                                      enabled={true} />
                            <span className='p-margin-left-6'>Autre</span>
                        </div>
                    </div>
                </div>
            }

            { displayInvoices &&
                <div className='p-margin-top-4'>
                    <div className='p-bold p-margin-bottom-7'>Factures associées</div>

                    <GraphQLQueryWrapper queryKey={buyerEventInvoiceChoicesQueryKey(buyerCompanyId)}
                                         axios={axios}
                                         queryParams={{
                                             query: `
                                                 query Q($buyerCompanyId: Guid!) {
                                                     invoices (buyerCompanyId: $buyerCompanyId, statuses: ${HasBeenFinancingRequestedInvoiceStatusFilters}) {
                                                         base {
                                                             id
                                                             number
                                                             sellerId
                                                             status { ${InvoiceChoiceStatusGraphQLFields} }
                                                         }
                                                         sellers {
                                                            id
                                                            company { name }
                                                         }
                                                     }
                                                 }
                                             `,
                                             variables: {
                                                 buyerCompanyId
                                             }
                                         }}
                                         onLoading={() => <Loader />}
                                         onSuccess={(r: IBuyerEventInvoiceChoicesQueryResult) =>
                                             <BuyerEventInvoiceMultipleSelectWrapper invoices={r.invoices.base.filter(invoiceChoiceFilter(initialSelectedInvoicesIds))}
                                                                                     sellers={r.invoices.sellers}
                                                                                     selectedInvoicesIds={selectedInvoicesIds}
                                                                                     setSelectedInvoicesIds={setSelectedInvoicesIds}
                                                                                     setSelectedInvoicesIdsCache={setSelectedInvoicesIdsCache}
                                                                                     alwaysSelectedInvoiceId={alwaysSelectedInvoiceId} />
                                         } />
                </div>
            }
        </div>
    );
}

export default BuyerEventInvoiceSelector;
