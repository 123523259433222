import { computeInvoiceStatus } from "../../Invoices/invoiceStatus";
import SwitchReminderEmailsStatusEnabled from "./InvoiceActions/InvoiceSwitchReminderEmailsStatus";
import SwitchEligibilityEnabled from "./InvoiceActions/InvoiceSwitchEligibility";
import DeclareDirectRecuperationEnabled from "./InvoiceActions/InvoiceDeclareDirectRecuperation";
import {
    IInvoiceDetailsStatus,
    IInvoiceDetailsPaymentStatus,
    IAdminInvoicePayment,
    IInvoiceDetails,
    IInvoiceDetailsSeller,
    Certif,
    IInvoiceDetailsCustomerFinancingRequestAcceptedStatus,
    IInvoiceDetailsFinancingStatus, ISettledO
} from "../invoiceDetailsQuery";
import ManualSettleFinancing from "./InvoiceActions/ManualSettleFinancing";
import RequestFinancing from "./InvoiceActions/RequestFinancing";
import { Axios } from "axios";
import InvoiceRemoveLastPayment from "./InvoiceActions/InvoiceRemoveLastPayment";
import InvoiceCustomerFinancingRequestActions from "./InvoiceActions/InvoiceCustomerFinancingRequestActions";
import IAuthUser from "../../../IAuthUser";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import ChargeStripeCommingLingModalContent from "./InvoiceActions/ChargeStripeCommingLingModalContent";
import { PAYMENT_TYPE_SWAN } from "../../../utils/paymentUtils";
import { comparingDate } from "factor-lib/utils/dateUtils";
import { comparingRev } from "factor-lib/utils/comparingUtils";
import { serverDateDeserialization } from "factor-lib/utils/dateUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";
import { IInvoiceBuyerCompany } from "../../../utils/IInvoice";
import TextOnlyModal from "factor-lib/Modals/TextOnlyModal";
import { promiseWrapper } from "factor-lib/utils/utils";
import ManualUnSettleFinancing from "./InvoiceActions/ManualUnSettleFinancing";

const compareInvoicePayment = comparingRev(
    comparingDate((ip: IAdminInvoicePayment) => serverDateDeserialization(ip.creationDateTime))
);

const InvoiceActionsSection = (
    {
        authUser,
        axios,
        invoice,
        seller,
        sellerIsBlocked,
        sellerCertif,
        buyerCompany,
        invoiceStatus,
        invoicePaymentStatus,
        invoiceIsBlocked,
    }: {
        authUser: IAuthUser;
        axios: Axios;
        invoice: IInvoiceDetails;
        seller: IInvoiceDetailsSeller;
        sellerIsBlocked: boolean;
        sellerCertif: Certif;
        buyerCompany: IInvoiceBuyerCompany;
        invoiceStatus: IInvoiceDetailsStatus;
        invoicePaymentStatus: IInvoiceDetailsPaymentStatus;
        invoiceIsBlocked: boolean;
    }
) => {
    const invoiceId = invoice.id;

    const removablePayments =
        invoicePaymentStatus.payments.filter((ip) => ip.base.type !== PAYMENT_TYPE_SWAN)

    const lastPayment = removablePayments.length > 0
        ? removablePayments.sort(compareInvoicePayment)[0]
        : null;
    
    const invoiceAmountRemaining = invoicePaymentStatus.completePaidDate
        ? 0 // TODO: handled in the other case ?
        : invoice.amountWoTax + invoice.amountTax - invoicePaymentStatus.partialAmountPaid;

    const accepted: IInvoiceDetailsCustomerFinancingRequestAcceptedStatus | null = invoiceStatus.customerFinancingRequest?.accepted || null;
    const financing: IInvoiceDetailsFinancingStatus | null = accepted?.financing || null;
    const settled: ISettledO = financing?.settled || null;
    
    const commingLingAmountToReturn = accepted?.financing?.commingLingAmountToReturn ?? 0;
    
    return (
        <div>
            <div className='p-horizontal-center'>
                <span className='p-margin-right-6 p-bold'>Status : </span>
                { computeInvoiceStatus(!!invoicePaymentStatus.completePaidDate, invoiceStatus, true) }
            </div>

            { invoicePaymentStatus.partialAmountPaid > 0 &&
                !financing && /* TODO: only if financing requested? */
                !invoicePaymentStatus.completePaidDate &&
                <div className='p-horizontal-center' style={{color: "orange"}}>
                    <span className='p-margin-right-6 p-bold'>Montant à payer : </span>
                    { formatAmount(invoiceAmountRemaining) }
                </div>
            }

            <div className='p-margin-top-6 p-horizontal-center'>
                { !!invoiceStatus.customerFinancingRequest
                    ? !!financing
                        ? (
                            !settled
                                ? <div className='p-horizontal-center'>
                                    <ManualSettleFinancing axios={axios}
                                                           invoiceId={invoiceId}
                                                           financingId={financing.id}
                                                           sellerId={seller.id}
                                                           buyerCompanyId={buyerCompany.id}/>
                                    <div className='p-margin-left-6'>
                                        <SwitchReminderEmailsStatusEnabled axios={axios}
                                                                           invoiceId={invoiceId}
                                                                           financingId={financing.id}
                                                                           reminderEmailsPaused={financing.reminderEmailsPaused}/>
                                    </div>
                                </div> : (
                                    settled.isManual &&
                                        <ManualUnSettleFinancing axios={axios}
                                                                 invoiceId={invoiceId}
                                                                 financingId={financing.id}
                                                                 financing={{
                                                                     ...financing,
                                                                     invoice: {
                                                                         ...invoice,
                                                                         sellerId: invoice.seller.id,
                                                                         buyer: {
                                                                             ...invoice.buyer,
                                                                             companyId: invoice.buyer.company.id
                                                                         }
                                                                     }
                                                                 }}/>
                                )
                        )
                        : <InvoiceCustomerFinancingRequestActions axios={axios}
                                                                  customerFinancingRequest={{
                                                                      ...invoiceStatus.customerFinancingRequest,
                                                                      invoice: {
                                                                          ...invoice,
                                                                          sellerId: invoice.seller.id,
                                                                          buyer: {
                                                                              ...invoice.buyer,
                                                                              companyId: invoice.buyer.company.id
                                                                          }
                                                                      }
                                                                  }}
                                                                  seller={seller}
                                                                  buyerCompany={buyerCompany}
                                                                  acceptedCreationDateTimeO={accepted?.creationDateTime || null}
                                                                  rejectedDateTimeO={invoiceStatus.customerFinancingRequest.rejectedDate} />
                    : <div className='p-horizontal-center'>
                        <SwitchEligibilityEnabled axios={axios}
                                                  invoiceId={invoiceId}
                                                  invoiceIsBlocked={invoiceIsBlocked} />

                        { !!invoiceStatus.eligibility.successO && !invoiceStatus.eligibility.successO?.rejectionReason && (
                            <RequestFinancing axios={axios}
                                              className='p-margin-left-6'
                                              invoiceId={invoiceId}
                                              sellerIsBlocked={sellerIsBlocked}
                                              sellerCertif={sellerCertif} />
                        ) }
                    </div>
                }

                { !invoicePaymentStatus.completePaidDate && !financing?.settled /* not settled */ &&
                    <DeclareDirectRecuperationEnabled axios={axios}
                                                      className='p-margin-left-6'
                                                      invoiceId={invoiceId}
                                                      invoice={invoice} />
                }

                { !!lastPayment &&
                    <InvoiceRemoveLastPayment axios={axios}
                                              className='p-margin-left-6'
                                              invoiceId={invoiceId}
                                              lastPayment={lastPayment} />
                }

                { !invoicePaymentStatus.completePaidDate && commingLingAmountToReturn > 0 && authUser.canInvoiceChargeStripe &&
                    <ButtonForModal id='showChargeInStripeModal'
                                    className='p-margin-left-6'
                                    text='Reprendre commingling (stripe)'
                                    childModalContent={(closeModal) =>
                                        commingLingAmountToReturn > invoiceAmountRemaining
                                            ? <TextOnlyModal message={`Le montant total de commingling à récupérer ${formatAmount(commingLingAmountToReturn)} est supérieur au montant restant de la facture ${formatAmount(invoiceAmountRemaining)}`}
                                                             buttonsProps={{
                                                                 id: 'invalidAmountToReturn',
                                                                 text: 'Ok',
                                                                 actionO: promiseWrapper(closeModal),
                                                                 isLoading: false
                                                             }}
                                                             close={closeModal}
                                                             maxWidth={null}
                                                             fullMaxWidth={false} />
                                            : <ChargeStripeCommingLingModalContent axios={axios}
                                                                                   invoiceId={invoiceId}
                                                                                   commingLingAmountToReturn={commingLingAmountToReturn}
                                                                                   closeModal={closeModal}/>
                                    } />
                }
            </div>
        </div>
    );
};

export default InvoiceActionsSection;
