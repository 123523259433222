import IAccessToken from "../../IAccessToken";
import CompanyInfosTab from "./CompanyInfos/CompanyInfosTab";
import { ReactElement } from "react";
import { SellerInfosTabParam } from "../Sellers/sellersUrls";

interface ICompanyPage {
    path: string;
    title: string;
    //filter: (authUser: IAuthUser | null | undefined) => boolean;
    component: (accessTokenO: IAccessToken | null, companyId: string) => ReactElement;
}

const CompanyTabs: ICompanyPage[] = [
    {
        path: SellerInfosTabParam,
        title: 'Infos',
        //filter: (_) => true,
        component: (accessTokenO, companyId) =>
            <CompanyInfosTab accessTokenO={accessTokenO}
                             companyId={companyId} />
    }
];

export default CompanyTabs;
