import {ReactElement} from "react";
import {useParams} from "react-router-dom";
import {SellerUrlSellerIdParam} from "./sellersUrls";

const WithSellerIdParamWrapper = (
    {
        child
    }: {
        child: (sellerId: string) => ReactElement;
    }
) =>
{
    const sellerId = useParams()[SellerUrlSellerIdParam]!;
    return child(sellerId);
}

export default WithSellerIdParamWrapper;