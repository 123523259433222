import BillingSection from "./BillingSection";
import NewBillingMinThresholdFinancingTable, {
    computeMinThresholdFinancingAmount,
    CurrentMonthFinancingRow
} from "./NewBillingMinThresholdFinancingTable";
import {formatAmount} from 'factor-lib/utils/amountUtils';
import {KIND_OUTLINED} from 'factor-lib/Buttons/Button';
import {TitledModalContent} from 'factor-lib/Modals/TitledModal';
import NewBillingOverThresholdFinancingTable, {
    computeOverThresholdFinancingAmount,
    CurrentMonthOverThresholdFinancingRow, effectiveDaysRounding
} from "./NewBillingOverThresholdFinancingTable";
import {INewBillingMinThresholdQueryResult} from "./NewBillingQuery";
import Loader from 'factor-lib/Loader';
import NewNormalBillingButton from "./NewNormalBillingButton";
import {Axios} from "axios";
import IYearMonth from "../IYearMonth";
import ButtonForModal from 'factor-lib/Buttons/ButtonForModal/ButtonForModal';
import NewManualBillingModalContent from "./NewManualBillingModalContent";

const NewBillingPageWithSelectionTemplate = (
    {
        minThreshold,
        customerId,
        yearMonth,
        date,
        dailyRateInPercent,
        customerInPennyLane,
        loaded
    }: {
        minThreshold: number;
        customerId: string;
        yearMonth: IYearMonth;
        date: Date;
        dailyRateInPercent: number;
        customerInPennyLane: boolean;
        loaded: {
            axios: Axios;
            result: INewBillingMinThresholdQueryResult;
        } | null;
    }
) =>
    <div className='p-padding-bottom-2'>
        <div>
            <BillingSection className='p-margin-top-3'
                            title='Financées dans le mois'>
                <NewBillingMinThresholdFinancingTable>
                    <tbody>
                        { !!loaded
                            ? loaded.result.customerDetails.minThresholdFinancings.base
                                .map((f, index) => (
                                    <CurrentMonthFinancingRow key={`f-${index}`}
                                                              f={f}
                                                              minThreshold={minThreshold} />
                                ))
                            : <tr>
                                <td/>
                                <td><Loader /></td>
                                <td/>
                                <td/>
                            </tr>
                        }
                    </tbody>
                </NewBillingMinThresholdFinancingTable>
            </BillingSection>
            <BillingSection className='p-margin-top-3'
                            title={`Intérêts pour financements > 30j effectifs`}>
                <NewBillingOverThresholdFinancingTable>
                    <tbody>
                    { !!loaded
                        ? (loaded.result.customerDetails.overThresholdFinancings.base.length > 0
                            ? <>
                                { loaded.result.customerDetails.overThresholdFinancings.base
                                    .map((f, index) => (
                                        <CurrentMonthOverThresholdFinancingRow key={`f-${index}`}
                                                                               f={f}
                                                                               dailyRateInPercent={dailyRateInPercent}/>
                                    ))
                                }
                            </> : <tr>
                                <td/>
                                <td>Pas de factures</td>
                                <td/>
                                <td/>
                            </tr>
                        ) : <tr>
                            <td/>
                            <td><Loader /></td>
                            <td/>
                            <td/>
                        </tr>
                    }
                    </tbody>
                </NewBillingOverThresholdFinancingTable>
            </BillingSection>
        </div>

        <div className='p-margin-top-4'>
            <span>Montant total : </span>
            { !!loaded
                ? <span className='p-bold'>
                    { formatAmount(
                        loaded.result.customerDetails.minThresholdFinancings.base.reduce(
                            ((acc, f) =>
                                acc + computeMinThresholdFinancingAmount(minThreshold, f.amount)),
                            0
                        ) + loaded.result.customerDetails.overThresholdFinancings.base.reduce(
                            ((acc, f) =>
                                acc + computeOverThresholdFinancingAmount(f.amount, effectiveDaysRounding(f.effectiveDaysFinancingsOverMinThreshold), dailyRateInPercent)),
                            0
                        )
                    )}
                </span>: <Loader/>
            }
        </div>

        { !!loaded &&
            <div style={{
                display: 'flex',
                flexDirection: 'row-reverse'
            }}>
               <div className='p-horizontal-center'>
                   <ButtonForModal className='p-margin-right-5'
                                   kind={KIND_OUTLINED}
                                   text='Facturation Manuelle'
                                   childModalContent={(/* closeModal: () => void */) =>
                                       <TitledModalContent title='Facturation manuelle'>
                                           <NewManualBillingModalContent axios={loaded.axios}
                                                                         customerId={customerId}
                                                                         yearMonth={yearMonth}/>
                                       </TitledModalContent>
                   }/>
                   <NewNormalBillingButton axios={loaded.axios}
                                           customerId={customerId}
                                           yearMonth={yearMonth}
                                           billingContentO={customerInPennyLane ? ({
                                               date,
                                               minThresholdLineItems: loaded.result.customerDetails.minThresholdFinancings.base.map((p) => ({
                                                   invoiceNumber: p.invoice.number,
                                                   amount: computeMinThresholdFinancingAmount(minThreshold, p.amount)

                                               })),
                                               overThresholdLineItems: loaded.result.customerDetails.overThresholdFinancings.base.map((p) => ({
                                                   invoiceNumber: p.invoice.number,
                                                   amount: computeOverThresholdFinancingAmount(p.amount, effectiveDaysRounding(p.effectiveDaysFinancingsOverMinThreshold), dailyRateInPercent)
                                               }))
                                           }) : null}/>
               </div>
            </div>
        }
    </div>;

export default NewBillingPageWithSelectionTemplate;