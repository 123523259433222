import {buildCompanySelectionGraphQlQueryParams, ICompanySelection } from "factor-lib/Company/ICompanySelection";
import GraphQLQueryWrapper from "../../../utils/GraphQLQueryWrapper";
import {Axios} from "axios";
import Input, { IInputEnabled } from "factor-lib/forms/Inputs/Input";
import { validateEmailError } from "factor-lib/forms/Inputs/emailUtils";
import AutoCompleteInput from "factor-lib/forms/AutoCompleteInput/AutoCompleteInput";

const BuyerEmailFieldName = 'Email';

const PreInvoiceDetailsPageLoadedEmail = (
    {
        className,
        axios,
        canUpdate,
        buyerCompanySelection,

        preInvoiceEmailRecipients,
        buyerEmailInput,
        setBuyerEmailInput
    }: {
        className?: string;
        axios: Axios;
        canUpdate: boolean;
        buyerCompanySelection: ICompanySelection | null;

        preInvoiceEmailRecipients: string[];
        buyerEmailInput: string;
        setBuyerEmailInput: (newBuyerEmailValue: string) => void;
    }
) => {
    const updateBuyerEmailEnabled: IInputEnabled = ({
        updateInputValue: setBuyerEmailInput,
        validateInput: () => validateEmailError(buyerEmailInput)
    });

    return (
        <div className={className}>
            <div className='p-padding-bottom-7'>Email du Client</div>
            { canUpdate && !!buyerCompanySelection
                ? <GraphQLQueryWrapper queryKey={['company', 'buyer', 'emails', (buyerCompanySelection.id ?? buyerCompanySelection.siren)!]}
                                       axios={axios}
                                       queryParams={buildCompanySelectionGraphQlQueryParams(
                                           buyerCompanySelection,
                                           (paramName, paramType) =>
                                               `
                                                   query Q($${paramName}: ${paramType}) {
                                                       buyerEmails (${paramName}: $${paramName}) { value }
                                                   }
                                               `
                                       )}
                                       onLoading={() =>
                                           <Input fieldName={BuyerEmailFieldName}
                                                  inputValue={buyerEmailInput}
                                                  enabled={updateBuyerEmailEnabled} />
                                       }
                                       onSuccess={(r: {
                                           buyerEmails: Array<{ value: string; }>;
                                       }) =>
                                           <AutoCompleteInput fieldName={BuyerEmailFieldName}
                                                              inputValue={buyerEmailInput}
                                                              enabled={{
                                                                  ...updateBuyerEmailEnabled,
                                                                  // remove duplicates
                                                                  suggestions: Array.from(new Set(
                                                                      r.buyerEmails
                                                                          .map((e) => e.value)
                                                                          .concat(preInvoiceEmailRecipients)
                                                                  )),
                                                                  showButton: {

                                                                  },
                                                                  noSuggestionsMessage: null
                                                              }} />
                                       } />
                : canUpdate && preInvoiceEmailRecipients.length > 0
                    ? <AutoCompleteInput fieldName={BuyerEmailFieldName}
                                         inputValue={buyerEmailInput}
                                         enabled={{
                                             ...updateBuyerEmailEnabled,
                                             suggestions: preInvoiceEmailRecipients,
                                             showButton: {

                                             },
                                             noSuggestionsMessage: null
                                         }} />
                    : <Input inputValue={buyerEmailInput}
                             placeHolder={BuyerEmailFieldName}
                             enabled={canUpdate ? updateBuyerEmailEnabled : null} />
            }
        </div>
    );
}

export default PreInvoiceDetailsPageLoadedEmail;