import {useRouteError} from "react-router-dom";
import {useEffect} from "react";
import ILogger from "factor-lib/services/logger/ILogger";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import {getFactorContext} from "./IFactorContext";

const ErrorHandler = () => {
    // error here will be 'swallowed' and not reach 'root', which would be handled by telemetry js
    const routeError: any = useRouteError();
    const error: Error | undefined = routeError instanceof Error ? routeError : routeError.error as Error | undefined /* react-router specific, for invalid url for example */;
    const errorMessage = routeError.message ?? routeError.data /* react-router specific, for invalid url for example */;
    const logger: ILogger = getFactorContext().logger;
    useEffect(() => {
        if (!!error) {
            logger.trackException(error);
        } else {
            logger.trackTrace(SeverityLevel.Error, errorMessage);
        }
    }, [logger, error, errorMessage]);
    return (
        <div className='p-margin-top-4 container p-vertical-center'>
            <p>Une erreur s'est produite : </p>
            <p>
                { errorMessage }
            </p>
        </div>
    )
};

export default ErrorHandler;