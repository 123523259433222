export enum CustomerSortProperty {
    CreationDate = 'creationDateTime',
    Name = 'name'
}

export const CustomerSortPropertyToOrderByField: Map<CustomerSortProperty, string> = new Map([
    [ CustomerSortProperty.CreationDate, 'CREATION_DATE_TIME' ],
    [ CustomerSortProperty.Name, 'NAME' ],
]);

export const CustomerTypeUnassigned = 'UNASSIGNED';
export const CustomerTypeMarketplace = 'MARKETPLACE';
export const CustomerTypeDirectSeller = 'DIRECT_SELLER';