import {
    IBuyerEvent,
    IBuyerEventInvoice,
    IFinancingMerciFacteurMail,
    IMerciFacteurMail
} from "../buyerCompanyDetailsQuery";
import { IBuyerFinancingEmail } from "./BuyerEventsTable";
import { BUYER_EVENT_EMAIL_OUT_TYPE, BUYER_EVENT_TYPES } from "./buyerEventUtils";
import { Link } from "react-router-dom";
import {invoiceUrl} from "../../../Invoices/invoicesUrls";
import { serverDateDeserialization } from "factor-lib/utils/dateUtils";
import { comparingDate } from "factor-lib/utils/dateUtils";
import { comparingRev } from "factor-lib/utils/comparingUtils";
import {formatAdminDate, formatDateAndTimeAdmin} from "../../../../utils/dateTimeUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";
import { truncateString } from "../../../../utils/stringUtils";
import {merciFacteurMailTypeLabel} from "../../../../utils/MerciFacteursMailsTypes";

const compareBuyerEventRowDate = comparingRev(
    comparingDate((buyerEventRow: IBuyerEventRow) => buyerEventRow.date)
);

interface IBuyerEventRow {
    who: string;

    date: Date;
    type: string;
    notes: string | null;

    invoices: Array<{
        id: string;
        number: string;
        amountWiTax: number;
    }>;

    autoEmailInfosO: {
        subject: string;
        status: string | null;
        opensCount: number | null;
        clicksCount: number | null;
    } | null;

    onClickO: (() => void) | null;
}

const formatBuyerMerciFateurMailsRows = (
    merciFacteurMail: IMerciFacteurMail,
    financingMerciFacteurMails: IFinancingMerciFacteurMail[]
): IBuyerEventRow =>
    ({
        who: merciFacteurMail.dimplOps.name,
        date: serverDateDeserialization(merciFacteurMail.creationDateTime),
        type: `${merciFacteurMailTypeLabel(merciFacteurMail.type)} Merci Facteur`,
        notes: !!merciFacteurMail.receivedDateTime ? `Reçue le  ${formatAdminDate(serverDateDeserialization(merciFacteurMail.receivedDateTime))}` : null,
        invoices: financingMerciFacteurMails.map((f) => ({
            id: f.invoice.id,
            number: f.invoice.number,
            amountWiTax: f.invoice.amountWoTax + f.invoice.amountTax
        })),
        autoEmailInfosO: null,
        onClickO: null
    });

const formatBuyerFinancingEmailRow = (buyerFinancingEmail: IBuyerFinancingEmail): IBuyerEventRow => ({
    who: buyerFinancingEmail.trigger,

    date: serverDateDeserialization(buyerFinancingEmail.sentDate),
    type: BUYER_EVENT_EMAIL_OUT_TYPE,
    notes: `status: ${buyerFinancingEmail.status ?? 'NA'}`,

    invoices: [{
        id: buyerFinancingEmail.invoiceId,
        number: buyerFinancingEmail.invoiceNumber,
        amountWiTax: buyerFinancingEmail.invoiceAmountWiTax
    }],

    autoEmailInfosO: {
        subject: buyerFinancingEmail.subject ?? buyerFinancingEmail.templateName,
        status: buyerFinancingEmail.status,
        opensCount: buyerFinancingEmail.opensCount,
        clicksCount: buyerFinancingEmail.clicksCount
    },

    onClickO: null
});

export const formatBuyerEventRow = (
    buyerEvent: IBuyerEvent,
    buyerEventInvoices: IBuyerEventInvoice[],
    setSelectedEvent: (buyerEvent: IBuyerEvent) => void
): IBuyerEventRow => ({
        who: buyerEvent.dimplOps.name,

        date: serverDateDeserialization(buyerEvent.eventDateTime),
        type: BUYER_EVENT_TYPES[buyerEvent.type],
        notes: buyerEvent.notes,

        invoices: buyerEventInvoices.map(i => ({
            id: i.id,
            number: i.number,
            amountWiTax: i.amountWoTax + i.amountTax
        })),

        autoEmailInfosO: null,

        onClickO: () => setSelectedEvent(buyerEvent)
});

const BuyerEventsTableLoaded = (
    {
        buyerEvents,
        buyerEventsToInvoices,
        setSelectedBuyerEvent,
        merciFacteurMails,
        merciFacteurMailsToFinancings,
        buyerFinancingEmails
    }: {
        buyerEvents: IBuyerEvent[];
        buyerEventsToInvoices: Map<string, IBuyerEventInvoice[]>;
        setSelectedBuyerEvent: (buyerEvent: IBuyerEvent) => void;
        merciFacteurMails: IMerciFacteurMail[];
        merciFacteurMailsToFinancings: Map<number, IFinancingMerciFacteurMail[]>;
        buyerFinancingEmails: IBuyerFinancingEmail[];
    }
) =>
    <tbody>
        {
            [
                ...buyerEvents.map((e) => formatBuyerEventRow(e, buyerEventsToInvoices.get(e.id) || [], setSelectedBuyerEvent)),
                ...merciFacteurMails.map((s) => formatBuyerMerciFateurMailsRows(s, merciFacteurMailsToFinancings.get(s.id)!)),
                ...buyerFinancingEmails.map(formatBuyerFinancingEmailRow)
            ]
                .sort(compareBuyerEventRowDate)
                .map((e, index) => (
                    <tr key={`buyer-internal-event-${index}`}
                        className={!!e.onClickO ? 'p-clickable' : ''}
                        onClick={e.onClickO || undefined} >
                        <td>{ e.who.toUpperCase() }</td>
                        <td>{ formatDateAndTimeAdmin(e.date) }</td>
                        <td>
                            { e.invoices.length > 0
                                ? <ul>
                                    { e.invoices.map((invoice) =>
                                        <li key={invoice.id}>
                                            <Link id={`invoice-${invoice.id}-link`}
                                                  to={invoiceUrl(invoice.id)}>
                                                { invoice.number }
                                            </Link>
                                        </li>
                                    ) }
                                </ul>
                                : 'N/A'
                            }
                        </td>
                        <td>
                            { e.invoices.length > 0
                                ? <ul>
                                    { e.invoices.map(i =>
                                        <li key={i.number}>{ formatAmount(i.amountWiTax) }</li>)
                                    }
                                </ul> : 'N/A'
                            }
                        </td>
                        <td>{ e.type }</td>
                        <td>
                            { !!e.autoEmailInfosO &&
                                <ul>
                                    <li>subject: {e.autoEmailInfosO.subject}</li>
                                    <li>status: {e.autoEmailInfosO.status ?? 'N/A'}</li>
                                    <li>opensCount: {e.autoEmailInfosO.opensCount ?? 'N/A'}</li>
                                    <li>clicksCount: {e.autoEmailInfosO.clicksCount ?? 'N/A'}</li>
                                </ul>
                            }
                        </td>
                        <td>
                            { !!e.notes
                                ? truncateString(e.notes, 30)
                                : ''
                            }
                        </td>
                    </tr>
                ))
        }
    </tbody>;

export default BuyerEventsTableLoaded;
