import IEnvConfig from "./IEnvConfig";
import LocalEnv from "./LocalEnv";
import DevEnv from "./DevEnv";
import DemoEnv from "./DemoEnv";
import ProdEnv from "./ProdEnv";
import {getEnv, ensureNonNullEnvName, envError} from 'factor-lib/utils/envUtils';

const currentConfigO: IEnvConfig | null =
    getEnv(() => ensureNonNullEnvName(process.env.REACT_APP_ENV_NAME), LocalEnv, DevEnv, DemoEnv, ProdEnv);

const currentConfigEnv: IEnvConfig = !!currentConfigO ? currentConfigO : envError();

export default currentConfigEnv;
