import { useState } from "react";
import IAuthUser from "../../../../IAuthUser";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import { KIND_PRIMARY, SIZE_COMPACT } from "factor-lib/Buttons/Button";
import { Axios } from "axios";
import { isValidString } from "factor-lib/forms/Inputs/utils";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { buyerCompanyDetailsQueryKey, IBuyerCompanyDetailsQueryResult } from "../buyerCompanyDetailsQuery";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";

const UpdateText = 'Modifier';

const BuyerUpdateOpsNotesEnabled = (
    {
        companyId,
        axios,
        opsNotes,
        kind,
        size,
        text
    } : {
        companyId: string;
        axios: Axios;
        opsNotes: string;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateOpsNotesMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (opsNotes2) => {
                await axios.put<void>(
                    `/adminBuyers/${companyId}/opsNotes`,
                    {
                        opsNotes: opsNotes2
                    }
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<IBuyerCompanyDetailsQueryResult>(
                        buyerCompanyDetailsQueryKey(companyId),
                        (old: IBuyerCompanyDetailsQueryResult | undefined) => {
                            const { companyDetails } = old!;
                            const r: IBuyerCompanyDetailsQueryResult = ({
                                ...old!,
                                companyDetails: {
                                    ...companyDetails,
                                    asBuyer: {
                                        ...companyDetails.asBuyer,
                                        adminNotes: opsNotes
                                    }
                                }
                            });
                            return r;
                        }
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateBuyerOpsNotes'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateOpsNotesMutation}
                               displayFullError={true}
                               value={opsNotes} />

    );
}

const BuyerOpsNotes = (
    {
        className,
        axios,
        authUser,
        companyId,
        opsNotes
    }: {
        className?: string;
        axios: Axios;
        authUser: IAuthUser | null;
        companyId: string;
        opsNotes: string | null;
    }
) => {
    const [newOpsNotes, setNewOpsNotes] = useState(opsNotes ?? '');

    const canManageProfiles: boolean = !!authUser?.canManageProfiles;

    return (
        <div className={className}>
            <div className='p-horizontal-center p-margin-bottom-5'>
                <div className='p-bold p-margin-right-6'>Notes générales</div>

                { canManageProfiles && (newOpsNotes === '' || isValidString(newOpsNotes)) && newOpsNotes !== (opsNotes ?? '')
                    ? <BuyerUpdateOpsNotesEnabled axios={axios}
                                                companyId={companyId}
                                                opsNotes={newOpsNotes!}
                                                kind={KIND_PRIMARY}
                                                size={SIZE_COMPACT}
                                                text={UpdateText} />
                    : <ButtonDisabled kind={KIND_PRIMARY}
                                      size={SIZE_COMPACT}
                                      text={UpdateText} />
                }
            </div>

            <textarea className='textarea'
                      placeholder='Notes'
                      value={newOpsNotes}
                      onChange={(e) => setNewOpsNotes(e.target.value)}
                      disabled={!canManageProfiles} />
        </div>
    );

};

export default BuyerOpsNotes;
