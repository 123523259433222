import { Axios } from "axios";
import { useState } from "react";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { companyDetailsQueryKey, ICompanyDetails, ICompanyDetailsQueryResult } from "./companyDetailsQuery";
import { updateAddress } from "../../../utils/IAddress";
import { IAddressInput } from "../../../utils/AddressInput";
import { CompaniesSearchQueryKeyPrefix, ICompaniesQueryResult } from "../companiesQuery";
import CompanyInput, { getCompanyParams, getUpdateCompanyParams, IUpdateCompanyParams } from "../CompanyInput";
import { ResultOrErrors } from "../../../utils/ResultOrErrors";
import ValidationErrorModal from "../../../utils/ValidationErrorModal";
import Button from "factor-lib/Buttons/Button";
import { formatCompany } from "../../../utils/companyUtils";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import { existingCompaniesQueryKey, IExistingCompaniesQueryResult } from "../../../utils/Company/ExistingCompanySelector";
import { SIZE_FIXED, KIND_PRIMARY } from "factor-lib/Buttons/Button";
import {dimplScoreQueryKey} from "../../../utils/DimplScore/dimplScoreSingleCompanyQuery";
import {ISellerDetailsQueryResult, SellerDetailsQueryKeyPrefix} from "../../Sellers/SellerInfos/sellerDetailsQuery";

const UpdateCompanyInfosButton = (
    {
        className,
        companyId,
        axios,
        updateParams,
        closeModal,
        kind,
        size,
        text
    } : {
        className?: string;
        companyId: string;
        axios: Axios;
        updateParams: ResultOrErrors<IUpdateCompanyParams> | null;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {

    // TODO : review validation pattern

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateCompanyInfosMutation: UseMutationResult<void, any, IUpdateCompanyParams> =
        useMutation<void, any, IUpdateCompanyParams>(
            async (updateParams2) => {
                await axios.put<void>(
                    `/adminCompanies/${companyId}`,
                    updateParams2
                );
            },
            ({
                onSuccess: (_, updateParams2) => {
                    queryClient.setQueryData<ICompanyDetailsQueryResult>(
                        companyDetailsQueryKey(companyId),
                        (old: ICompanyDetailsQueryResult | undefined) => ({
                            companyDetails: {
                                ...old!.companyDetails,
                                name: updateParams2.name ?? old!.companyDetails.name,
                                identifier: updateParams2.identifier ?? old!.companyDetails.identifier,
                                address: !!updateParams2.address
                                    ? updateAddress(old!.companyDetails.address, updateParams2.address)
                                    : old!.companyDetails.address
                            }
                        })
                    );
                    queryClient.setQueryData<IExistingCompaniesQueryResult>(
                        existingCompaniesQueryKey,
                        (old: IExistingCompaniesQueryResult | undefined) =>
                            !!old ? ({
                                ...old,
                                companies: old.companies.map((c) => c.id === companyId ? ({
                                    id: c.id,
                                    name: updateParams2.name ?? c.name,
                                    identifier: updateParams2.identifier ?? c.identifier
                                }) : c)
                            }) : undefined
                    );

                    queryClient.setQueriesData<ISellerDetailsQueryResult>(
                        SellerDetailsQueryKeyPrefix,
                        (old: ISellerDetailsQueryResult | undefined) => {
                            return !!old
                                ? ( old.sellerDetails.company.id === companyId
                                        ? ({
                                            ...old,
                                            sellerDetails: {
                                                ...old.sellerDetails,
                                                company: {
                                                    ...old.sellerDetails.company,
                                                    name: updateParams2.name ?? old.sellerDetails.company.name,
                                                    identifier: updateParams2.identifier ?? old.sellerDetails.company.identifier,
                                                    address: {
                                                        streetAddress: updateParams2.address?.streetAddress ?? old.sellerDetails.company.address.streetAddress,
                                                        city: updateParams2.address?.city ?? old.sellerDetails.company.address.city,
                                                        postCode: updateParams2.address?.postCode ?? old.sellerDetails.company.address.postCode,
                                                    }
                                                }
                                            }
                                        }) : old
                                ): undefined;
                        }
                    );

                    // TODO: should probably invalidate all other queries (platforms, invoice details...)
                    const r = Promise.all([
                        queryClient.invalidateQueries(dimplScoreQueryKey(companyId)),
                        queryClient.invalidateQueries<ICompaniesQueryResult>(CompaniesSearchQueryKeyPrefix)
                    ]);

                    closeModal();
                    return r;
                }
            })
        );

    const [showValidationErrorsModal, setShowValidationErrorsModal] = useState<string[] | null>(null);

    return (
        <div className={className}>
            <Button id='updateCompanyInfosButton'
                    kind={kind}
                    size={size}
                    text={text}
                    isLoading={updateCompanyInfosMutation.isLoading}
                    actionO={() => {
                        if (updateParams === null) {
                            setShowValidationErrorsModal([`aucun changement n'a été repéré`]);
                        } else if (!!updateParams.result) {
                            updateCompanyInfosMutation.mutate(updateParams.result);
                        } else {
                            setShowValidationErrorsModal(updateParams.errors!);
                        }
                    }} />


            { showValidationErrorsModal &&
                <ValidationErrorModal validationErrors={showValidationErrorsModal}
                                      close={() => setShowValidationErrorsModal(null)}/>
            }
        </div>
    );
}

const UpdateCompanyMainInfosModalContent = (
    {
        axios,
        companyId,
        companyDetails,
        closeModal
    }: {
        axios: Axios;
        companyId: string;
        companyDetails: ICompanyDetails;
        closeModal: () => void;
    }
) => {
    const identifier = companyDetails.identifier;

    const [newIdentifierType, setNewIdentifierType] = useState<string | null>(identifier.type);
    const [newIdentifierValueInput, setNewIdentifierValueInput] = useState(identifier.value);
    const [newNameInput, setNewNameInput] = useState(companyDetails.name);
    const [newAddressInput, setNewAddressInput] = useState<IAddressInput>(companyDetails.address);

    return (
        <div className='p-padding-3'>
            <div className='p-both-center p-size-6 p-padding-5'>
                Modifier la société { formatCompany(companyDetails) }
            </div>

            <div className='box'>
                <CompanyInput identifierType={newIdentifierType}
                              setIdentifierType={setNewIdentifierType}
                              identifierValueInput={newIdentifierValueInput}
                              setIdentifierValueInput={setNewIdentifierValueInput}
                              nameInput={newNameInput}
                              setNameInput={setNewNameInput}
                              addressInput={newAddressInput}
                              setAddressInput={setNewAddressInput}
                              autofocus={true} />
            </div>

            <UpdateCompanyInfosButton className='p-margin-top-4 p-vertical-center'
                                      companyId={companyId}
                                      axios={axios}
                                      updateParams={getUpdateCompanyParams(
                                          companyDetails,
                                          getCompanyParams(
                                              newIdentifierType,
                                              newIdentifierValueInput,
                                              newNameInput,
                                              newAddressInput
                                          )
                                      )}
                                      closeModal={closeModal}
                                      text='Confirmer'
                                      kind={KIND_PRIMARY}
                                      size={SIZE_FIXED} />
        </div>
    );
}

const CompanyMainInfosUpdate = (
    {
        className,
        axios,
        companyId,
        companyDetails
    }: {
        className?: string;
        axios: Axios;
        companyId: string;
        companyDetails: ICompanyDetails;
    }
) =>
    <ButtonForModal className={className}
                    id='showUpdateCompanyMainInfosModal'
                    text='Modifier'
                    childModalContent={(closeModal) =>
                        <UpdateCompanyMainInfosModalContent axios={axios}
                                                            companyId={companyId}
                                                            companyDetails={companyDetails}
                                                            closeModal={closeModal} />
                    }/>;

export default CompanyMainInfosUpdate;

