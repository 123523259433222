import GraphQLQueryWrapper from "../../../utils/GraphQLQueryWrapper";
import {INewBillingMinThresholdQueryResult, newBillingQuery, newBillingQueryKey} from "./NewBillingQuery";
import NewBillingPageWithSelectionTemplate from "./NewBillingPageWithSelectionTemplate";
import CustomerYearMonthSelectionLayout, {ICustomer} from "./CustomerYearMonthSelectionLayout";
import DateInput, {isValidDateInput, parseDateInput} from 'factor-lib/forms/DateInput/DateInput';
import IOption from "factor-lib/forms/Select/IOption";
import Input from 'factor-lib/forms/Inputs/Input';
import Button from 'factor-lib/Buttons/Button';
import Loader from 'factor-lib/Loader';
import {Axios} from "axios";
import IYearMonth from "../IYearMonth";
import PennyLaneCustomerCreateModal from "./PennyLaneCustomerCreateModal";
import ButtonForModal from 'factor-lib/Buttons/ButtonForModal/ButtonForModal';
import { TitledModalContent } from 'factor-lib/Modals/TitledModal';
import {IPennyLaneCustomerQueryResult} from "./PostSelectionQuery";

export interface IValidParam {
    minThreshold: number;
    dailyRateInPercent: number;
    date: Date;
}


const NewBillingPostCustomerYearMonthSelectionLayout = (
    {
        axiosO,
        selectedMonth,
        selectMonth,
        selectedCustomer,
        selectCustomer,
        pennyLaneCustomerQueryResultO,
        minThresholdInput,
        setMinThresholdInput,
        dailyRateInput,
        setDailyRateInput,
        dateInput,
        setDateInput,
        validParam,
        updateValidParam
    }: {
        axiosO: Axios | null;
        selectedMonth: IOption<IYearMonth>;
        selectMonth: (newMonth: IOption<IYearMonth> | null) => void;
        selectedCustomer: IOption<ICustomer>;
        selectCustomer: (newCustomer: IOption<ICustomer> | null) => void;
        pennyLaneCustomerQueryResultO: IPennyLaneCustomerQueryResult | null;
        minThresholdInput: string;
        setMinThresholdInput: (n: string) => void;
        dailyRateInput: string;
        setDailyRateInput: (n: string) => void;
        dateInput: string;
        setDateInput: (n: string) => void;
        validParam: IValidParam;
        updateValidParam: (newValidParam: IValidParam) => void;
    }
) => {

    const minThresholdMaybeInvalid: number = parseFloat(minThresholdInput);
    const isMinThresholdValid: boolean = !Number.isNaN(minThresholdMaybeInvalid);
    const dailyRateMaybeInvalid: number = parseFloat(dailyRateInput);
    const isDailyRateValid: boolean = !Number.isNaN(dailyRateMaybeInvalid);

    const customerId = selectedCustomer.value.id;

    const { minThreshold, dailyRateInPercent, date } = validParam;

    return (
        <CustomerYearMonthSelectionLayout axiosO={axiosO}
                                          selectedMonth={selectedMonth}
                                          selectMonth={selectMonth}
                                          selectedCustomer={selectedCustomer}
                                          selectCustomer={selectCustomer}
                                          topRightNode={
                                              <div className='p-vertical-center'>
                                                  <div className='field'>
                                                      { !!pennyLaneCustomerQueryResultO
                                                          ? !pennyLaneCustomerQueryResultO.customer.pennyLaneId
                                                              ? <ButtonForModal text='Créer client dans PennyLane'
                                                                                childModalContent={(closeModal: () => void) =>
                                                                                    <TitledModalContent title='Créer dans Pennylane'>
                                                                                        <PennyLaneCustomerCreateModal axios={axiosO!}
                                                                                                                      customerId={customerId}
                                                                                                                      closeModal={closeModal}
                                                                                                                      customerEmail={pennyLaneCustomerQueryResultO.customer.contactEmail} />
                                                                                    </TitledModalContent>
                                                                                }/>
                                                              : null
                                                          : <Loader/>
                                                      }
                                                  </div>
                                                  <div className='field'>
                                                      <Input className='field'
                                                             fieldName='Seuil minimum (%)'
                                                             inputValue={minThresholdInput}
                                                             enabled={{
                                                                 updateInputValue: setMinThresholdInput,
                                                                 validateInput: () => !isMinThresholdValid ? 'Ratio non parsable' : null
                                                             }}/>
                                                      <Input className='field'
                                                             fieldName='Ratio journalier (%)'
                                                             inputValue={dailyRateInput}
                                                             enabled={{
                                                                 updateInputValue: setDailyRateInput,
                                                                 validateInput: () => !isDailyRateValid ? 'Ratio non parsable' : null
                                                             }}/>
                                                      <DateInput className='field'
                                                                 dateInputValue={dateInput}
                                                                 enabled={{
                                                                     updateDateInputValue: setDateInput,
                                                                     validateDate: () => null
                                                                 }} />
                                                  </div>
                                                  <Button className='field'
                                                          isLoading={false}
                                                          text='Mettre à jour'
                                                          actionO={isMinThresholdValid && isDailyRateValid && isValidDateInput(dateInput) && (
                                                              // au moins une différence
                                                              minThresholdMaybeInvalid !== minThreshold ||
                                                              dailyRateMaybeInvalid !== dailyRateInPercent ||
                                                              parseDateInput(dateInput) !== date
                                                          )
                                                              ? (() => updateValidParam({
                                                                  minThreshold: minThresholdMaybeInvalid,
                                                                  dailyRateInPercent: dailyRateMaybeInvalid,
                                                                  date: parseDateInput(dateInput)!
                                                              }))
                                                              : null
                                                          } />
                                              </div>
                                          }
        >
            { !!axiosO
                ? <GraphQLQueryWrapper<INewBillingMinThresholdQueryResult> axios={axiosO}
                                                                           queryKey={newBillingQueryKey(customerId, selectedMonth.value)}
                                                                           queryParams={newBillingQuery(customerId, selectedMonth.value)}
                                                                           onLoading={() =>
                                                                               <NewBillingPageWithSelectionTemplate minThreshold={minThreshold}
                                                                                                                    customerId={customerId}
                                                                                                                    yearMonth={selectedMonth.value}
                                                                                                                    date={date}
                                                                                                                    dailyRateInPercent={dailyRateInPercent}
                                                                                                                    customerInPennyLane={!!pennyLaneCustomerQueryResultO?.customer.pennyLaneId}
                                                                                                                    loaded={null} /> }
                                                                           onSuccess={((r: INewBillingMinThresholdQueryResult) =>
                                                                                   <NewBillingPageWithSelectionTemplate minThreshold={minThreshold}
                                                                                                                        customerId={customerId}
                                                                                                                        yearMonth={selectedMonth.value}
                                                                                                                        date={date}
                                                                                                                        dailyRateInPercent={dailyRateInPercent}
                                                                                                                        customerInPennyLane={!!pennyLaneCustomerQueryResultO?.customer.pennyLaneId}
                                                                                                                        loaded={{
                                                                                                                            axios: axiosO,
                                                                                                                            result: r
                                                                                                                        }}/>
                                                                           )}/>
                : <Loader />
            }
        </CustomerYearMonthSelectionLayout>
    );
}

export default NewBillingPostCustomerYearMonthSelectionLayout;