import { Axios } from "axios";
import {
    IAdminInvoicePayment, IInvoiceDetails,
    IInvoiceDetailsQueryResult,
    invoiceDetailsQueryKey
} from "../../invoiceDetailsQuery";
import ButtonWithConfirmationModal from "factor-lib/Buttons/ButtonMutationWithConfirmationModal";
import { QueryClient, useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { isPaymentNotReporting, PAYMENT_TYPE_COMMING_LING, PAYMENT_TYPE_COMMINGLING_RECUP, PAYMENT_TYPE_PAYMENT_ON_RECUP_BY_SELLER } from "../../../../utils/paymentUtils";
import { formatAmount } from 'factor-lib/utils/amountUtils';
import { GlobalOutstandingsQueryKey } from "../../../Misc/GlobalOutstandings/globalOutstandingsQuery";

interface IRemoveLastPaymentResponse {
    partialAmountPaid: number;
}

const updateQueryRemoveLastPayment = (
    queryClient: QueryClient,
    invoiceId: string,
    newInvoiceDetails: (old: IInvoiceDetails) => IInvoiceDetails
): Promise<void> => {

    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
        invoiceDetailsQueryKey(invoiceId),
        (old: IInvoiceDetailsQueryResult | undefined) => ({
            ...old!,
            invoiceDetails: newInvoiceDetails(old!.invoiceDetails),
        })
    );

    return queryClient.invalidateQueries(GlobalOutstandingsQueryKey);
};

const buttonText = (lastPayment: IAdminInvoicePayment) =>
    `Remove Last ${isPaymentNotReporting(lastPayment.base.type)
        ? `${lastPayment.isComplete ? 'Complete Payment' : 'Partial Payment'}${lastPayment.base.type === PAYMENT_TYPE_COMMINGLING_RECUP ? ' (stripe)' : ''}`
        : lastPayment.isComplete ? 'Complete Recuperation (reporting)' : 'Partial Recuperation (reporting)'}`;

const confirmationText = (lastPayment: IAdminInvoicePayment) =>
    `Etes vous sûr de vouloir supprimer ${isPaymentNotReporting(lastPayment.base.type)
        ? `le paiement ${lastPayment.isComplete ? 'complet' : 'partiel'}`
        : `la récupération ${lastPayment.isComplete ? 'complète' : 'partielle'} (reporting)`
    } de ${formatAmount(lastPayment.amount)} de cette facture ?`;

const InvoiceRemoveLastPaymentManual = (
    {
        className,
        axios,
        invoiceId,
        lastPayment,
        kind,
        size
    } : {
        className?: string;
        axios: Axios;
        invoiceId: string;
        lastPayment: IAdminInvoicePayment;
        kind?: string;
        size?: string;
    }
) => {

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const removeLastManualPaymentMutation: UseMutationResult<IRemoveLastPaymentResponse, any, IAdminInvoicePayment> =
        useMutation<IRemoveLastPaymentResponse, any, IAdminInvoicePayment>(
            async (lastPayment2) =>
                (await axios.delete<IRemoveLastPaymentResponse>(
                    `/adminInvoices/${invoiceId}/lastManualPayment/${lastPayment2.id}`
                )).data,
            ({
                onSuccess: (response, lastPayment2) => {
                    const paymentType = lastPayment2.base.type;
                    updateQueryRemoveLastPayment(
                        queryClient,
                        invoiceId,
                        (oldInvoiceDetails) => ({
                            ...oldInvoiceDetails,
                            payment: {
                                ...oldInvoiceDetails.payment,
                                completePaidDate: isPaymentNotReporting(paymentType) ? null : oldInvoiceDetails.payment.completePaidDate,
                                partialAmountPaid: response.partialAmountPaid,
                                payments: oldInvoiceDetails.payment.payments.filter((p) => p.id !== lastPayment2.id)
                            },
                            status: !!oldInvoiceDetails.status.customerFinancingRequest?.accepted?.financing
                                ? {
                                    ...oldInvoiceDetails.status,
                                    customerFinancingRequest: {
                                        ...oldInvoiceDetails.status.customerFinancingRequest,
                                        accepted: {
                                            ...oldInvoiceDetails.status.customerFinancingRequest.accepted,
                                            financing: {
                                                ...oldInvoiceDetails.status.customerFinancingRequest.accepted.financing,
                                                commingLingAmountToReturn: oldInvoiceDetails.status.customerFinancingRequest.accepted.financing.commingLingAmountToReturn
                                                    + (
                                                        paymentType === PAYMENT_TYPE_COMMING_LING
                                                            ? -lastPayment2.amount
                                                            : paymentType === PAYMENT_TYPE_PAYMENT_ON_RECUP_BY_SELLER
                                                                ? lastPayment2.amount
                                                                : 0
                                                    )
                                            }
                                        }
                                    }
                                }
                                : oldInvoiceDetails.status
                        })
                    );
                }
            })
        );

    return (
        <ButtonWithConfirmationModal id='removeLastPayment'
                                     className={className}
                                     kind={kind}
                                     size={size}
                                     text={buttonText(lastPayment)}
                                     confirmationText={confirmationText(lastPayment)}
                                     mutation={removeLastManualPaymentMutation}
                                     value={lastPayment} />
    );
}

const InvoiceRemoveLastPaymentCommingLingStripe = (
    {
        className,
        axios,
        invoiceId,
        lastPayment,
        kind,
        size
    } : {
        className?: string;
        axios: Axios;
        invoiceId: string;
        lastPayment: IAdminInvoicePayment;
        kind?: string;
        size?: string;
    }
) => {

    const queryClient = useQueryClient();

    const removeLastCommingLingStripeBackPaymentMutation: UseMutationResult<IRemoveLastPaymentResponse, any, IAdminInvoicePayment> =
        useMutation<IRemoveLastPaymentResponse, any, IAdminInvoicePayment>(
            async (lastPayment2) =>
                (await axios.delete<IRemoveLastPaymentResponse>(
                    `/adminInvoices/${invoiceId}/last-charge-commingLing/${lastPayment2.base.specificPaymentId!}`
                )).data,
            ({
                onSuccess: (response, lastPayment2) =>
                    updateQueryRemoveLastPayment(
                        queryClient,
                        invoiceId,
                        (oldInvoiceDetails) => ({
                            ...oldInvoiceDetails,
                            status: {
                                ...oldInvoiceDetails.status,
                                customerFinancingRequest: {
                                    ...oldInvoiceDetails.status.customerFinancingRequest!,
                                    accepted: {
                                        ...oldInvoiceDetails.status.customerFinancingRequest!.accepted!,
                                        financing: {
                                            ...oldInvoiceDetails.status.customerFinancingRequest!.accepted!.financing!,
                                            commingLingAmountToReturn: oldInvoiceDetails.status.customerFinancingRequest!.accepted!.financing!.commingLingAmountToReturn + lastPayment2.amount
                                        }
                                    }
                                }
                            },
                            payment: {
                                ...oldInvoiceDetails.payment,
                                completePaidDate: null,
                                partialAmountPaid: response.partialAmountPaid,
                                payments: oldInvoiceDetails.payment.payments.filter((p) => p.base.specificPaymentId !== lastPayment2.base.specificPaymentId)
                            }
                        })
                    )
            })
        );

    return (
        <ButtonWithConfirmationModal id='removeLastPayment'
                                     className={className}
                                     kind={kind}
                                     size={size}
                                     text={buttonText(lastPayment)}
                                     confirmationText={confirmationText(lastPayment)}
                                     mutation={removeLastCommingLingStripeBackPaymentMutation}
                                     value={lastPayment} />
    );
}

const InvoiceRemoveLastPayment = (
    {
        className,
        axios,
        invoiceId,
        lastPayment,
        kind,
        size
    } : {
        className?: string;
        axios: Axios;
        invoiceId: string;
        lastPayment: IAdminInvoicePayment;
        kind?: string;
        size?: string;
    }
) => (
        // On ne doit générer qu'une seule mutation, sinon elle ne se reset pas correctement
        lastPayment.base.type === PAYMENT_TYPE_COMMINGLING_RECUP
            ? <InvoiceRemoveLastPaymentCommingLingStripe axios={axios}
                                                         className={className}
                                                         invoiceId={invoiceId}
                                                         lastPayment={lastPayment}
                                                         kind={kind}
                                                         size={size}/>
            : <InvoiceRemoveLastPaymentManual axios={axios}
                                              className={className}
                                              invoiceId={invoiceId}
                                              lastPayment={lastPayment}
                                              kind={kind}
                                              size={size}/>

);

export default InvoiceRemoveLastPayment;