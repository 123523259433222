import IBaseInvoice, {
    buildBuyerCompaniesMap,
    buildSellersMap,
    IBaseInvoiceSellerWithCompany,
    IInvoiceBuyerCompany
} from "../../utils/IInvoice";
import {filterStrInvoice} from "../../utils/InvoiceUtils";
import { comparingRev } from "factor-lib/utils/comparingUtils";
import { comparingDate, serverDateDeserialization } from "factor-lib/utils/dateUtils";
import {ReactNode} from "react";

const comparingInvoice = comparingRev(
    comparingDate((i: { invoice: IBaseInvoice; }) => serverDateDeserialization(i.invoice.dueDate))
);

export interface IInvoiceWithSellerAndBuyer<I extends IBaseInvoice> {
    invoice: I;
    seller: IBaseInvoiceSellerWithCompany;
    buyerCompany: IInvoiceBuyerCompany;
}

const InvoicesTableLoaded = <I extends IBaseInvoice, > (
    {
        invoices,
        sellers,
        buyerCompanies,
        searchStrFilter,
        invoiceRowFactory
    }: {
        invoices: I[];
        sellers: IBaseInvoiceSellerWithCompany[];
        buyerCompanies: IInvoiceBuyerCompany[];
        searchStrFilter: string;
        invoiceRowFactory: (i: IInvoiceWithSellerAndBuyer<I>, index: number) => ReactNode;
    }
) => {
    const searchStrFilterLower = searchStrFilter.toLowerCase();
    const sellersById: Map<string, IBaseInvoiceSellerWithCompany> = buildSellersMap(sellers);
    const buyerCompaniesById: Map<string, IInvoiceBuyerCompany> = buildBuyerCompaniesMap(buyerCompanies);

    return (
        <tbody>
            { invoices
                .map((invoice: I) => ({
                    invoice,
                    seller: sellersById.get(invoice.sellerId)!,
                    buyerCompany: buyerCompaniesById.get(invoice.buyer.companyId)!
                }))
                .filter((f) => filterStrInvoice(f.invoice.number, f.seller, f.buyerCompany, searchStrFilterLower))
                .sort(comparingInvoice)
                .map(invoiceRowFactory)
            }
        </tbody>
    )
}

export default InvoicesTableLoaded;