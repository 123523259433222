import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import IYearMonth from "../IYearMonth";

export const newBillingQuery = (customerId: string, yearMonth: IYearMonth): IGraphQLParams => ({
    query: `query ($customerId: Guid!, $yearMonth: YearMonthInput!) {
        customerDetails(id: $customerId) {
            minThresholdFinancings: financings(financedAtMonth: $yearMonth) {
                base {
                    creationDateTime
                    amount
                    invoice {
                        number
                    }
                }
            }
            overThresholdFinancings: financings(billingOverMinThreshold: $yearMonth) {
                base {
                    amount
                    invoice {
                        number
                    }
                    effectiveDaysFinancingsOverMinThreshold (yearMonth: $yearMonth)
                }
            }
        }
    }`,
    variables: {
        customerId,
        yearMonth
    }
});

export interface INewBillingMinThresholdQueryFinancingResult {
    creationDateTime: string;
    amount: number;
    invoice: {
        number: string;
    };
}

export interface INewBillingOverThresholdQueryFinancingResult {
    amount: number;
    invoice: {
        number: string;
    };
    effectiveDaysFinancingsOverMinThreshold: number;
}

export const newBillingQueryKey = (customerId: string, yearMonth: IYearMonth): string[] =>
    ['new-billing', 'customerId', customerId, 'month', yearMonth.year.toString(), yearMonth.month.toString()];

export interface INewBillingMinThresholdQueryResult {
    customerDetails: {
        minThresholdFinancings: {
            base: INewBillingMinThresholdQueryFinancingResult[];
        };
        overThresholdFinancings: {
            base: INewBillingOverThresholdQueryFinancingResult[];
        };
    }
}