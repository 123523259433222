import {useContext} from "react";
import {NavigateFunction} from "react-router-dom";
import { IBuyerCompany } from "./buyersQuery";
import {buyerUrl} from "./buyersUrls";
import { formatCompanyInput } from "factor-lib/Company/companyUtils";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";

const filterBuyer = (filterInput: string) =>
    (buyer: IBuyerCompany): boolean =>
        [
            buyer.id,
            buyer.name,
            buyer.identifier.type,
            buyer.identifier.value
        ]
            .some((s) => s.toLowerCase().includes(filterInput.toLowerCase()));

const BuyersListLoaded = (
    {
        buyerCompanies,
        selectedBuyerCompanyIdO,
        includeBuyersWithoutInvoice,
        searchInput
    }:
    {
        buyerCompanies: Array<IBuyerCompany>;
        selectedBuyerCompanyIdO: string | null;
        includeBuyersWithoutInvoice: boolean;
        searchInput: string;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    return (
        <div>
            { buyerCompanies
                .filter(filterBuyer(searchInput))
                .map(({id, name, identifier, asBuyer}) =>
                    <div key={`buyer-${id}`}
                         className={`panel-block ${id === selectedBuyerCompanyIdO ? 'p-ok p-bold' : ' p-clickable'}`}
                         onClick={() => navigate(buyerUrl(id, includeBuyersWithoutInvoice))}
                         style={{
                             color: asBuyer.isBlocked ? 'red' : undefined
                         }}
                    >
                        { formatCompanyInput(identifier, name) }
                    </div>
                )
            }
        </div>
    );

}
export default BuyersListLoaded;
