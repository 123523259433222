import IAccessToken from "../../IAccessToken";
import SellerInfosTab from "./SellerInfos/SellerInfosTab";
import SellerAddBuyer from "./SellerAddBuyer/SellerAddBuyer";
import SellerAddInvoice from "./SellerAddInvoice/SellerAddInvoice";
import FinancingRequestsTableStateFilter from "../../utils/FinancingRequest/FinancingRequestsTableStateFilter";
import FinancingsTableStateFilter from "../../utils/Financing/FinancingsTableStateFilter";
import SettledFinancingsTableStateFilter from "../../utils/SettledFinancing/SettledFinancingsTableStateFilter";
import IAuthUser from "../../IAuthUser";
import { ReactElement } from "react";
import { SellerInfosTabParam } from "./sellersUrls";
import { NewFinancingRequestsQueryKeyPrefix } from "../../utils/FinancingRequest/FinancingRequestsQuery";
import { ActiveFinancingsQueryKeyPrefix } from "../../utils/Financing/financingsQuery";
import { SettledFinancingsQueryKeyPrefix } from "../../utils/SettledFinancing/settledFinancingsQuery";


const sellerNewFinancingRequestsQueryKey = (sellerId: string) =>
    [...NewFinancingRequestsQueryKeyPrefix, 'seller', sellerId];

export const sellerActiveFinancingsQueryKey = (sellerId: string) =>
    [...ActiveFinancingsQueryKeyPrefix, 'seller', sellerId];

export const sellerSettledFinancingsQueryKey = (sellerId: string) =>
    [...SettledFinancingsQueryKeyPrefix, 'seller', sellerId];

interface ISellerTab {
    path: string;
    title: string;
    filter: (authUser: IAuthUser | null | undefined) => boolean;
    component: (accessTokenO: IAccessToken | null, sellerId: string) => ReactElement;
}

const SellerTabs: ISellerTab[] = [
    {
        path: SellerInfosTabParam,
        title: 'Infos',
        filter: (_) => true,
        component: (accessTokenO, sellerId) =>
            <SellerInfosTab accessTokenO={accessTokenO}
                            sellerId={sellerId} />
    },
    {
        path: 'financing_requests',
        title: 'Financing requests',
        filter: (_) => true,
        component: (accessTokenO, sellerId) =>
            <FinancingRequestsTableStateFilter accessTokenO={accessTokenO}
                                               queryKey={sellerNewFinancingRequestsQueryKey(sellerId)}
                                               queryParam={{sellerId, buyerCompanyId: null}}
                                               searchStrFilterO={null} />
    },
    {
        path: 'financings',
        title: 'Financings',
        filter: (_) => true,
        component: (accessTokenO, sellerId) =>
            <FinancingsTableStateFilter accessTokenO={accessTokenO}
                                        queryKey={sellerActiveFinancingsQueryKey(sellerId)}
                                        queryParam={{sellerId, buyerCompanyId: null}} />
    },
    {
        path: 'settled_financings',
        title: 'Settled financings',
        filter: (_) => true,
        component: (accessTokenO, sellerId) =>
            <SettledFinancingsTableStateFilter accessTokenO={accessTokenO}
                                               queryKey={sellerSettledFinancingsQueryKey(sellerId)}
                                               queryParam={{sellerId, buyerCompanyId: null}} />
    },
    {
        path: 'add_invoice',
        title: 'Add Invoice',
        filter: (authUser) => !!authUser?.canManageFinancings,
        component: (accessTokenO, sellerId) =>
            <SellerAddInvoice accessTokenO={accessTokenO}
                              sellerId={sellerId} />
    },
    {
        path: 'add_buyer',
        title: 'Add Buyer',
        filter: (authUser) => !!authUser?.canManageFinancings,
        component: (accessTokenO, sellerId) =>
            <SellerAddBuyer accessTokenO={accessTokenO}
                            sellerId={sellerId} />
    }
];

export default SellerTabs;
