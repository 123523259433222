import { useEffect, useState } from "react";
import { Axios } from "axios";
import Link from "factor-lib/Link";
import Loader from "factor-lib/Loader";
import Button from "factor-lib/Buttons/Button";
import GraphQLQueryWrapper from "./GraphQLQueryWrapper";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";

// Shared utils ?
export const openInNewTabWoOpener = (url: string) => {
    const newTab = window.open(url, '_blank', 'noopener,noreferrer');
    // security consideration
    // https://stackoverflow.com/questions/45046030/maintaining-href-open-in-new-tab-with-an-onclick-handler-in-react
    if (newTab) {
        newTab.opener = null;
    }
};

const SingleGraphQLFileVisualizer = <T,> (
    {
        axios,
        fetchId,
        fetchText,
        queryKey,
        queryParams,
        visualizerId,
        visualizerText,
        buttonKind,
        buttonSize,
        existingFileName,
        urlExtractor
    }: {
        axios: Axios;
        fetchId: string;
        fetchText: string;
        queryKey: string[];
        queryParams: IGraphQLParams;
        visualizerId: string;
        visualizerText: string;
        buttonKind: string;
        buttonSize: string;
        existingFileName: string | null; // used to force re-render in case file changes
        urlExtractor: (graphQLResponse: T) => string;
    }
) => {
    const [withIdsUrls, setWithIdsUrls] = useState(false);
    // Uniquement un hack pour en local ?
    const [initiallyOpened, setInitiallyOpened] = useState(false);

    useEffect(() => {
        setWithIdsUrls(false);
        setInitiallyOpened(false);
    }, [existingFileName]);

    return withIdsUrls
            ? <GraphQLQueryWrapper queryKey={queryKey}
                                   axios={axios}
                                   queryParams={queryParams}
                                   onLoading={() => <Loader />}
                                   onSuccess={(d: T) => {
                                       const fileDownloadUrl: string = urlExtractor(d);
                                       // Use useEffect ?
                                       if (!initiallyOpened) {
                                           openInNewTabWoOpener(fileDownloadUrl);
                                           setInitiallyOpened(true);
                                       }
                                       return <Link id={visualizerId}
                                                    text={visualizerText}
                                                    action={{
                                                        clickHandler: () => openInNewTabWoOpener(fileDownloadUrl)
                                                    }} />;
                                   }} />
            : <Button id={fetchId}
                      kind={buttonKind}
                      size={buttonSize}
                      text={fetchText}
                      isLoading={false}
                      actionO={() => setWithIdsUrls(true)} />;


}

export default SingleGraphQLFileVisualizer;
