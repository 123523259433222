import { Axios } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { ISellerDetailsQueryResult, ISellerReminderEmailsSettings, sellerDetailsQueryKey } from "../../../sellerDetailsQuery";
import { useState } from "react";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import CheckBox from "factor-lib/forms/CheckBox";
import IAuthUser from "../../../../../../IAuthUser";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import Input from "factor-lib/forms/Inputs/Input";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import { isValidString } from "factor-lib/forms/Inputs/utils";

const UpdateButtonText = 'Modifier';
const UpdateButtonKind = KIND_PRIMARY;
const UpdateButtonSize = SIZE_FIXED;

const UpdateReminderEmailsSettingsEnabled = (
    {
        axios,
        sellerId,
        reminderEmailsPausedUpdate,
        reminderEmailsCCsPausedUpdate,
        companyFriendlyNameUpdate
    } : {
        axios: Axios;
        sellerId: string;
        reminderEmailsPausedUpdate: boolean | null;
        reminderEmailsCCsPausedUpdate: boolean | null;
        companyFriendlyNameUpdate: string | null;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateReminderEmailsSettingsMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (sellerId2) => {
                await axios.put<void>(
                    `/adminSellers/${sellerId2}/reminderEmailsSettings`,
                    {
                        remainderEmailsPaused: reminderEmailsPausedUpdate,
                        remainderEmailsCCsPaused: reminderEmailsCCsPausedUpdate,
                        companyFriendlyName: companyFriendlyNameUpdate
                    }
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old: ISellerDetailsQueryResult | undefined) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                reminderEmailsSettings: {
                                    reminderEmailsPaused: reminderEmailsPausedUpdate ?? old!.sellerDetails.reminderEmailsSettings.reminderEmailsPaused,
                                    reminderEmailsCCsPaused: reminderEmailsCCsPausedUpdate ?? old!.sellerDetails.reminderEmailsSettings.reminderEmailsCCsPaused,
                                    customCompanyFriendlyName: companyFriendlyNameUpdate ?? old!.sellerDetails.customCompanyFriendlyName
                                }
                            }
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateSellerReminderEmailsSettings'
                               kind={UpdateButtonKind}
                               size={UpdateButtonSize}
                               text={UpdateButtonText}
                               mutation={updateReminderEmailsSettingsMutation}
                               displayFullError={true}
                               value={sellerId} />
    );
}

const ReminderEmailsSettingsModalContent = (
    {
        axios,
        authUser,
        sellerId,
        settings,
        customCompanyFriendlyName
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        sellerId: string;
        settings: ISellerReminderEmailsSettings;
        customCompanyFriendlyName: string | null;
    }
) => {
    const [newReminderEmailsPaused, setNewReminderEmailsPaused] = useState(settings.reminderEmailsPaused);
    const [newReminderEmailsCCsPaused, setNewReminderEmailsCCsPaused] = useState(settings.reminderEmailsCCsPaused);
    const [newCompanyFriendlyName, setNewCompanyFriendlyName] = useState(customCompanyFriendlyName ?? '');

    const reminderEmailsPausedUpdate: boolean | null = newReminderEmailsPaused !== settings.reminderEmailsPaused ? newReminderEmailsPaused : null;
    const reminderEmailsCCsPausedUpdate: boolean | null = newReminderEmailsCCsPaused !== settings.reminderEmailsCCsPaused ? newReminderEmailsCCsPaused : null;
    const companyFriendlyNameUpdate: string | null = isValidString(newCompanyFriendlyName) && newCompanyFriendlyName !== customCompanyFriendlyName ? newCompanyFriendlyName : null;

    const canManageProfiles = !!authUser?.canManageProfiles;

    return (
        <div className='p-padding-3'>

            <div className='box'>
                <div className='p-horizontal-center'>
                    <CheckBox internalId='sellerReminderEmailsPaused'
                              inputValue={!newReminderEmailsPaused}
                              updateInputValue={canManageProfiles ? (value) => setNewReminderEmailsPaused(!value) : null} />

                    <span className='p-margin-left-5 p-bold'>Reminder emails</span>
                </div>

                <div className='p-horizontal-center p-margin-top-5'>
                    <CheckBox internalId='sellerReminderEmailsCCsPaused'
                              inputValue={!newReminderEmailsCCsPaused}
                              updateInputValue={canManageProfiles ? (value) => setNewReminderEmailsCCsPaused(!value) : null} />

                    <span className='p-margin-left-5 p-bold'>Seller en CCs des emails</span>
                </div>
            </div>

            <div className='p-margin-top-4'>
                <span className='p-bold'>Company friendly name</span> (pour emails et buyer-recap)
                <Input inputValue={newCompanyFriendlyName}
                       enabled={{
                           updateInputValue: setNewCompanyFriendlyName,
                           validateInput: () => null,
                           innerId: {
                               value: 'emailInner',
                               autofocus: true
                           }
                       }} />
            </div>

            { canManageProfiles &&
                <div className='p-margin-top-3 p-vertical-center'>
                    { (reminderEmailsPausedUpdate !== null || reminderEmailsCCsPausedUpdate !== null || companyFriendlyNameUpdate !== null)
                        ? <UpdateReminderEmailsSettingsEnabled axios={axios}
                                                               sellerId={sellerId}
                                                               reminderEmailsPausedUpdate={reminderEmailsPausedUpdate}
                                                               reminderEmailsCCsPausedUpdate={reminderEmailsCCsPausedUpdate}
                                                               companyFriendlyNameUpdate={companyFriendlyNameUpdate} />
                        : <ButtonDisabled kind={KIND_PRIMARY}
                                          size={SIZE_FIXED}
                                          text={UpdateButtonText} />
                    }
                </div>
            }
        </div>
    );
}

const SellerReminderEmailsSettings = (
    {
        className,
        axios,
        authUser,
        sellerId,
        settings,
        customCompanyFriendlyName
    } : {
        className?: string;
        axios: Axios;
        authUser: IAuthUser | null;
        sellerId: string;
        settings: ISellerReminderEmailsSettings;
        customCompanyFriendlyName: string | null;
    }
) =>
    <ButtonForModal className={className}
                    id='updateReminderEmailsSettingsOpenModal'
                    text='Reminder Emails Settings'
                    modalFullMaxWidth={false}
                    childModalContent={() =>
                        <ReminderEmailsSettingsModalContent axios={axios}
                                                     authUser={authUser}
                                                     sellerId={sellerId}
                                                     settings={settings}
                                                     customCompanyFriendlyName={customCompanyFriendlyName} />
                    }/>;

export default SellerReminderEmailsSettings;
