import dimplScoreSingleCompanyQuery, {dimplScoreQueryKey, IDimplScoreQueryResult} from "./dimplScoreSingleCompanyQuery";
import DimplScoreResult from "./DimplScoreResult";
import DataLoader from "dataloader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import GraphQLQueryWrapperDL from "../GraphQLQueryWrapperDL";


// TODO : ideally, directly to Vincent API
// In its own query as this is much slower
const DimplScoreLoaderDL = (
    {
        dsGraphQLDataLoader,
        graphQLCompanyVariableName,
        companyId,
        loadedClassName
    }: {
        dsGraphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        graphQLCompanyVariableName: string,
        companyId: string;
        loadedClassName?: string;
    }
) =>
    <GraphQLQueryWrapperDL<IDimplScoreQueryResult> graphQLDataLoader={dsGraphQLDataLoader}
                                                   queryKey={dimplScoreQueryKey(companyId)}
                                                   queryParams={dimplScoreSingleCompanyQuery('companyDetails', graphQLCompanyVariableName, companyId)}
                                                   postGraphQLQueryExtractor={(r: any) => r.companyDetails as IDimplScoreQueryResult}
                                                   onSuccess={(r: IDimplScoreQueryResult) =>
                                                       <DimplScoreResult className={loadedClassName}
                                                                         dimplScore={r.dimplScore}/>
                                                   } />;

export default DimplScoreLoaderDL;
