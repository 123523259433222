import BillingsTableLayout from "./BillingsTableLayout";
import Loader from 'factor-lib/Loader';

const BillingsTableLoading = () =>
    <BillingsTableLayout>
        <tbody>
            <tr>
                <td/>
                <td/>
                <td> <Loader/> </td>
                <td/>
                <td/>
            </tr>
        </tbody>
    </BillingsTableLayout>;

export default BillingsTableLoading;