import IAccessToken from "../../../IAccessToken";
import {ICustomerFinancingRequestAccepted} from "../../../utils/FinancingRequest/FinancingRequestsQuery";
import {
    IBaseInvoiceSellerWithCompanyAndSwanInstantPayment
} from "../../../utils/FinancingRequest/IBaseInvoiceSellerWithCompanyAndSwanInstantPayment";
import {IBaseInvoiceSellerWithCompany, IInvoiceBuyerCompany} from "../../../utils/IInvoice";
import {useState} from "react";
import PendingPaymentsTemplate from "./PendingPaymentsTemplate";
import {filterStrInvoice} from "../../../utils/InvoiceUtils";
import {compareFinancingRequestDate} from "../../../utils/FinancingRequest/FinancingRequestsTable";
import PendingPaymentRow from "./PendingPaymentRow";

const PendingPaymentsTableLoaded = (
    {
        className,
        accessToken,
        pendingPayments,
        sellers,
        buyerCompanies,
        sellersById,
        buyerCompaniesById,
        searchStrFilter
    }: {
        className?: string;
        accessToken: IAccessToken;
        pendingPayments: ICustomerFinancingRequestAccepted[];
        sellers: IBaseInvoiceSellerWithCompanyAndSwanInstantPayment[];
        buyerCompanies: IInvoiceBuyerCompany[];
        sellersById: Map<string, IBaseInvoiceSellerWithCompany>;
        buyerCompaniesById: Map<string, IInvoiceBuyerCompany>;
        searchStrFilter: string;
    }
) => {
    const [ selectedCustomerFinancingRequestsIds, setSelectedCustomerFinancingRequestsIds ] = useState<string[]>([]);
    const selectedCustomerFinancingRequestsIdsSet = new Set(selectedCustomerFinancingRequestsIds);
    const searchStrFilterLower = searchStrFilter.toLowerCase();

    return (
        <PendingPaymentsTemplate className={className}
                                 accessTokenO={accessToken}
                                 loadedO={{
                                     isAllSelected: pendingPayments.length === selectedCustomerFinancingRequestsIds.length,
                                     selectAll: () =>
                                         setSelectedCustomerFinancingRequestsIds(
                                             pendingPayments.length === selectedCustomerFinancingRequestsIds.length
                                                 ? []
                                                 : pendingPayments.map((i) => i.id)
                                         ),
                                     selectedCustomerFinancingRequests: pendingPayments
                                         .filter((p) => selectedCustomerFinancingRequestsIdsSet.has(p.id)),
                                     sellers,
                                     buyerCompanies
                                 }} >
            <tbody>
                { pendingPayments
                    .map((pendingPayment: ICustomerFinancingRequestAccepted) => ({
                        pendingPayment,
                        seller: sellersById.get(pendingPayment.invoice.sellerId)!,
                        buyerCompany: buyerCompaniesById.get(pendingPayment.invoice.buyer.companyId)!
                    }))
                    .filter((p) => filterStrInvoice(p.pendingPayment.invoice.number, p.seller, p.buyerCompany, searchStrFilterLower))
                    .sort((f, g) => compareFinancingRequestDate(f.pendingPayment, g.pendingPayment))
                    .map((p, index) =>
                        <PendingPaymentRow key={`financing-request-${index}`}
                                           pendingPayment={p.pendingPayment}
                                           seller={p.seller}
                                           buyerCompany={p.buyerCompany}
                                           isSelected={selectedCustomerFinancingRequestsIdsSet.has(p.pendingPayment.id)}
                                           setSelectedCustomerFinancingRequest={(customerFinancingRequestId) =>
                                               setSelectedCustomerFinancingRequestsIds(
                                                   !selectedCustomerFinancingRequestsIdsSet.has(customerFinancingRequestId)
                                                       ? [...selectedCustomerFinancingRequestsIds, customerFinancingRequestId] // add
                                                       : selectedCustomerFinancingRequestsIds.filter((i) => i !== customerFinancingRequestId) // remove
                                               )
                                           } />
                    )
                }
            </tbody>
        </PendingPaymentsTemplate>
    )
}

export default PendingPaymentsTableLoaded;