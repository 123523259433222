
// helps with the casting
const windowErrorHandler = (err: any): void => {
    // TODO: proper error handling
    if (err.response?.data !== undefined) {
        window.alert(err.response.data);
    } else {
        window.alert(err);
    }
    throw new Error(err);
}

export default windowErrorHandler;
