import { Axios } from "axios";
import {useState} from "react";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import {
    ICustomerFinancingRequest,
    ICustomerFinancingRequestAccepted
} from "../../../../../utils/FinancingRequest/FinancingRequestsQuery";
import { ButtonKind, ButtonSize } from "../CustomerFinancingRequestButtonProps";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import RadioBox from "factor-lib/forms/Radio/RadioBox";
import {
    financeSwanAction,
    INewFinancingResponse,
    financeManualAction,
    postFinanceUpdateQueries
} from "../../../../../utils/Financing/financingPaymentUtils";
import { getInstantTransactionNotPossibleReason } from "../../../../../utils/swanUtils";
import { getInvoiceAmountToFinance } from "../../../../../utils/Financing/financingUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";
import Button from "factor-lib/Buttons/Button";
import {IInvoiceDetailsSeller} from "../../../invoiceDetailsQuery";
import {IInvoiceBuyerCompany} from "../../../../../utils/IInvoice";
import IFactorContext, {getFactorContext} from "../../../../../IFactorContext";

const FinanceManualEnabled = (
    {
        axios,
        className,
        customerFinancingRequest,
        seller,
        buyerCompany
    } : {
        axios: Axios;
        className?: string;
        customerFinancingRequest: ICustomerFinancingRequestAccepted;
        seller: IInvoiceDetailsSeller;
        buyerCompany: IInvoiceBuyerCompany;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const financeManualMutation: UseMutationResult<INewFinancingResponse, unknown, ICustomerFinancingRequest> =
        useMutation<INewFinancingResponse, unknown, ICustomerFinancingRequest>(
            async (customerFinancingRequestId2: ICustomerFinancingRequest) =>
                (await financeManualAction(axios, [customerFinancingRequestId2]))[0],
            ({
                onSuccess: (newFinancing, customerFinancingRequest2: ICustomerFinancingRequest) => postFinanceUpdateQueries(
                    queryClient,
                    [customerFinancingRequest2],
                    [seller],
                    [buyerCompany],
                    [newFinancing]
                )
            })
        );

    return (
        <ButtonMutationEnabled className={className}
                               id='financeManual'
                               kind={ButtonKind}
                               size={ButtonSize}
                               text='Changer statut'
                               mutation={financeManualMutation}
                               displayFullError={true}
                               value={customerFinancingRequest} />
    );
}

const FinanceSwanEnabled = (
    {
        customerFinancingRequest,
        seller,
        buyerCompany,
        closeModal
    }: {
        customerFinancingRequest: ICustomerFinancingRequestAccepted;
        seller: IInvoiceDetailsSeller;
        buyerCompany: IInvoiceBuyerCompany;
        closeModal: () => void;
    }
) => {
    const queryClient = useQueryClient();

    const factorContext: IFactorContext = getFactorContext();

    return (
        <Button text='Payer avec Swan'
                id='swanPaymentRedirectButton'
                isLoading={false}
                actionO={() => {
                    financeSwanAction(
                        factorContext.baseUrl,
                        factorContext.swanClientId,
                        queryClient,
                        [customerFinancingRequest],
                        [seller],
                        [buyerCompany]
                    );
                    closeModal();
                }} />

    );
}

const InvoiceFinanceModalContent = (
    {
        axios,
        className,
        closeModal,
        customerFinancingRequest,
        seller,
        buyerCompany
    } : {
        axios: Axios;
        className?: string;
        closeModal: () => void;
        customerFinancingRequest: ICustomerFinancingRequestAccepted;
        seller: IInvoiceDetailsSeller;
        buyerCompany: IInvoiceBuyerCompany;
    }
) => {
    const [ payWithSwan, setPayWithSwan ] = useState<boolean>(false);

    const amountToFinance = getInvoiceAmountToFinance(customerFinancingRequest.invoice);

    const instantPaymentNotPossibleReason: string | null =
        getInstantTransactionNotPossibleReason(seller.swanInstantPayment, amountToFinance);

    return (
        <div className='container p-padding-3'>
            <div className='p-vertical-center'>
                Montant à financer : {formatAmount(amountToFinance)}
            </div>

            <div className='p-margin-top-4'>
                <div className='p-horizontal-center'>
                    <RadioBox id='directSellerRadioBoxId'
                              inputValue={!payWithSwan}
                              setInputValue={() => setPayWithSwan(false)}
                              enabled={true} />
                    <span className='p-margin-left-6'>Juste changer le statut à Financing Sent sans flux financier</span>
                </div>

                <div className='p-horizontal-center p-margin-top-5'>
                    <RadioBox id='marketplaceSellerRadioBoxId'
                              inputValue={payWithSwan}
                              setInputValue={() => setPayWithSwan(true)}
                              enabled={true /* TODO : only on role ? */} />
                    <span className='p-margin-left-6'>
                                    Procéder au virement par Swan {instantPaymentNotPossibleReason === null
                        ? <span className='p-bold'>Instantané</span>
                        : <span><span className='p-bold'style={{color: 'orange'}}>Non instantané</span> ({instantPaymentNotPossibleReason})</span>
                    }
                                </span>
                </div>
            </div>

            <div className='p-margin-top-4 p-vertical-center'>
                { !payWithSwan
                    ? <FinanceManualEnabled axios={axios}
                                            className={className}
                                            customerFinancingRequest={customerFinancingRequest}
                                            seller={seller}
                                            buyerCompany={buyerCompany} />
                    : <FinanceSwanEnabled customerFinancingRequest={customerFinancingRequest}
                                          seller={seller}
                                          buyerCompany={buyerCompany}
                                          closeModal={closeModal}/>
                }
            </div>
        </div>
    );
}

export default InvoiceFinanceModalContent;
