import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {Axios} from "axios";
import {Certif, IInvoiceDetailsQueryResult, invoiceDetailsQueryKey} from "../../invoiceDetailsQuery";
import { GlobalOutstandingsQueryKey } from "../../../Misc/GlobalOutstandings/globalOutstandingsQuery";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import {addDays, serverDateSerialization } from "factor-lib/utils/dateUtils";
import { isValidConfidenceFlag } from "../../../../utils/ConfidenceFlags";
import ShowValidationErrorsButton from "../../../../utils/ShowValidationErrorsButton";
import ButtonMutationWithConfirmationModal from "factor-lib/Buttons/ButtonMutationWithConfirmationModal";

const ButtonText = 'Demander financement';
const ButtonKind = KIND_PRIMARY;
const ButtonSize = SIZE_FIXED;

const RequestFinancingEnabled = (
    {
        axios,
        className,
        invoiceId
    }: {
        axios: Axios;
        className?: string;
        invoiceId: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const requestFinancingMutation: UseMutationResult<string, any, string> =
        useMutation<string, any, string>(
            async (invoiceId2) =>
                (await axios.post<string>(
                    `/adminInvoices/${invoiceId2}/requestFinancing`
                )).data,
            ({
                onSuccess: (id: string) => {
                    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                        invoiceDetailsQueryKey(invoiceId),
                        (old: IInvoiceDetailsQueryResult | undefined) => ({
                            ...old!,
                            invoiceDetails: {
                                ...old!.invoiceDetails,
                                status: {
                                    ...old!.invoiceDetails.status,
                                    customerFinancingRequest: {
                                        id,
                                        creationDateTime: serverDateSerialization(new Date()),
                                        rejectedDate: null,
                                        rejectedReason: null,
                                        expirationDate: serverDateSerialization(addDays(new Date(), 30)),
                                        accepted: null,
                                        amountForMarketplace: null
                                    }
                                }
                            }
                        })
                    );

                    return queryClient.invalidateQueries(GlobalOutstandingsQueryKey);
                }
            })
        );

    return (
        <ButtonMutationWithConfirmationModal id='requestFinancing'
                                             className={className}
                                             kind={ButtonKind}
                                             size={ButtonSize}
                                             text={ButtonText}
                                             confirmationText='Vous allez créer une demande de financement au nom du client'
                                             value={invoiceId}
                                             mutation={requestFinancingMutation} />

    );
}

const RequestFinancing = (
    {
        axios,
        className,
        invoiceId,
        sellerIsBlocked,
        sellerCertif
    }: {
        axios: Axios;
        className?: string;
        invoiceId: string;
        sellerIsBlocked: boolean;
        sellerCertif: Certif;
    }
) => {
    const sellerCannotFinanceReason = sellerIsBlocked
        ? `Le vendeur est (manuellement)t bloqué`
        : !sellerCertif
            ? 'Vendeur non certifié'
            : !isValidConfidenceFlag(sellerCertif.solvaConfidenceFlag)
                ? 'Certification solva vendeur rejetée'
                : !isValidConfidenceFlag(sellerCertif.fraudConfidenceFlag)
                    ? `Certification fraud vendeur rejetée`
                    : null;

    return (
        sellerCannotFinanceReason !== null
            ? <ShowValidationErrorsButton className={className}
                                          headerText='Vous ne pouvez pas demander un financement pour ce vendeur, raison :'
                                          validationErrors={[sellerCannotFinanceReason]}
                                          kind={ButtonKind}
                                          size={ButtonSize}
                                          text={ButtonText} />
            : <RequestFinancingEnabled className={className}
                                       axios={axios}
                                       invoiceId={invoiceId}/>
    );
}

export default RequestFinancing;
