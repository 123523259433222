import { ReactElement } from "react";
import BuyersInfosTab from "./BuyersInfo/BuyersInfosTab";
import FinancingRequestsTableStateFilter from "../../utils/FinancingRequest/FinancingRequestsTableStateFilter";
import FinancingsTableStateFilter from "../../utils/Financing/FinancingsTableStateFilter";
import SettledFinancingsTableStateFilter from "../../utils/SettledFinancing/SettledFinancingsTableStateFilter";
import { BuyerInfosTabParam } from "./buyersUrls";
import IAccessTokenWithDataLoader from "./IAccessTokenWithDataLoader";
import { NewFinancingRequestsQueryKeyPrefix } from "../../utils/FinancingRequest/FinancingRequestsQuery";
import { ActiveFinancingsQueryKeyPrefix } from "../../utils/Financing/financingsQuery";
import { SettledFinancingsQueryKeyPrefix } from "../../utils/SettledFinancing/settledFinancingsQuery";


const buyerNewFinancingRequestsQueryKey = (buyerCompanyId: string) =>
    [...NewFinancingRequestsQueryKeyPrefix, 'buyer', buyerCompanyId];

export const buyerActiveFinancingsQueryKey = (buyerCompanyId: string) =>
    [...ActiveFinancingsQueryKeyPrefix, 'buyer', buyerCompanyId];

export const buyerSettledFinancingsQueryKey = (buyerCompanyId: string) =>
    [...SettledFinancingsQueryKeyPrefix, 'buyer', buyerCompanyId];

interface IBuyerTab {
    path: string;
    title: string;
    component: (accessTokenWithDataLoaderO: IAccessTokenWithDataLoader | null, buyerCompanyId: string) => ReactElement;
}

const BuyerTabs: IBuyerTab[] = [
    {
        path: BuyerInfosTabParam,
        title: 'Infos',
        component: (accessTokenWithDataLoaderO, buyerCompanyId) =>
            <BuyersInfosTab accessTokenWithDataLoaderO={accessTokenWithDataLoaderO}
                            buyerCompanyId={buyerCompanyId} />
    },
    {
        path: 'financing_requests',
        title: 'Financing requests',
        component: (accessTokenWithDataLoaderO, buyerCompanyId) =>
            <FinancingRequestsTableStateFilter accessTokenO={accessTokenWithDataLoaderO}
                                               queryKey={buyerNewFinancingRequestsQueryKey(buyerCompanyId)}
                                               queryParam={{sellerId: null, buyerCompanyId}}
                                               searchStrFilterO={null} />
    },
    {
        path: 'financings',
        title: 'Financings',
        component: (accessTokenWithDataLoaderO, buyerCompanyId) =>
            <FinancingsTableStateFilter accessTokenO={accessTokenWithDataLoaderO}
                                        queryKey={buyerActiveFinancingsQueryKey(buyerCompanyId)}
                                        queryParam={{sellerId: null, buyerCompanyId}} />
    },
    {
        path: 'settled_financings',
        title: 'Settled financings',
        component: (accessTokenWithDataLoaderO, buyerCompanyId) =>
            <SettledFinancingsTableStateFilter accessTokenO={accessTokenWithDataLoaderO}
                                               queryKey={buyerSettledFinancingsQueryKey(buyerCompanyId)}
                                               queryParam={{sellerId: null, buyerCompanyId}} />
    }
];

export default BuyerTabs;