import {useEffect, useState} from "react";
import windowErrorHandler from "../../utils/windowErrorHandler";
import useUrlQuery from "../../utils/useUrlQuery";
import Loader from "factor-lib/Loader";
import { Axios, AxiosResponse } from "axios";

const SwanPaymentConsentLoaded = (
    {
        axios,
        consentId,
        status
    }: {
        axios: Axios;
        consentId: string;
        status: string;
    }
) => {
    const [ isSavingConsent, setSavingConsent ] = useState(true);

    // for local-env because useEffect is triggered twice
    // const savingConsentCalled = useRef(false);

    useEffect(() => {
        // if (!savingConsentCalled.current) {
        //     savingConsentCalled.current = true;

            const opener = (window.opener as Window);

            const validateSwanActionIntAsync = async () => {
                // !!! Transactions are not visible right away in graphql. Wait for 2s !!
                await new Promise((res) => setTimeout(res, 2_000));
                await opener.validateSwanAction(axios, consentId!)
                    .then((r: AxiosResponse) => opener.onValidateSwanAction(r.data))
            }

            const request =
                status === 'Accepted'
                    ? validateSwanActionIntAsync()
                    : opener.rejectSwanAction(axios, consentId!);

            request
                .then(window.close /* We opened in a new tab */)
                .catch(windowErrorHandler /* (e) => throw new Error(e); */)
                .finally(() => setSavingConsent(false));
        // }
    }, [axios, consentId, status]);

    return (
        isSavingConsent
            ? <div className='p-vertical-center'>
                Enregistrement du consent
                <Loader/>
            </div> : <div>
                Consent va s'enregistrer
            </div>
    );
}

// Callback
const SwanPaymentConsent = (
    {
        axiosO,
    } : {
        axiosO: Axios | null;
    }
) => {
    const query = useUrlQuery();
    const consentId = query.get('consentId');
    const status = query.get('status');

    return (
        <div className='container p-padding-2 p-vertical-center'>
            { consentId === null || status === null
                ? <div>Echec de récupération du consentement</div>
                : <div className='p-vertical-center'>
                    {!!axiosO
                        ? <SwanPaymentConsentLoaded axios={axiosO}
                                                    consentId={consentId}
                                                    status={status} />
                        : <Loader/>
                    }
                    <div>Ne changez pas de page, elle va se fermer une fois l'opération terminée</div>
                </div>
            }
        </div>
    );
}

export default SwanPaymentConsent;
