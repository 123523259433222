import {useState} from "react";
import Input from "factor-lib/forms/Inputs/Input";
import { validateEmailError, isValidEmail } from "factor-lib/forms/Inputs/emailUtils";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import ButtonMutation from 'factor-lib/Buttons/ButtonMutation';
import {Axios} from "axios";
import {IPennyLaneCustomerQueryResult, pennyLaneCustomerQueryKey} from "./PostSelectionQuery";

interface ICreatePennyLaneCustomerParams {
    customerId: string;
    email: string;
}

const PennyLaneCustomerCreateModal = (
    {
        axios,
        customerId,
        closeModal,
        customerEmail
    }: {
        axios: Axios;
        customerId: string;
        closeModal: () => void;
        customerEmail: string;
    }
) => {
    const [emailInput, setEmailInput] = useState(customerEmail);

    const queryClient = useQueryClient();

    const mutation: UseMutationResult<string, any, ICreatePennyLaneCustomerParams> =
        useMutation<string, any, ICreatePennyLaneCustomerParams>(
            async (p) => (await axios.post(
                `/adminBillings/customer`,
                p
            )).data,
            ({
                onSuccess: (r) => {
                    queryClient.setQueryData<IPennyLaneCustomerQueryResult>(
                        pennyLaneCustomerQueryKey(customerId),
                        (old) => ({
                            ...old!,
                            customer: {
                                ...old!.customer,
                                pennyLaneId: r
                            }
                        })
                    );
                    closeModal();
                }
            })
        );

    return (
        <div className='p-vertical-stretch p-padding-4'>
            <div className='p-margin-bottom-3 p-horizontal-center'>
                <span className='p-margin-right-4 p-bold'>Email</span>
                <Input inputValue={emailInput}
                       style={{minWidth: '360px'}}
                       enabled={{
                           updateInputValue: setEmailInput,
                           validateInput: () => validateEmailError(emailInput),
                           innerId: {
                               value: 'emailInner',
                               autofocus: true
                           }
                       }} />
            </div>
            <div className='p-vertical-center'>
                <ButtonMutation<string, ICreatePennyLaneCustomerParams> text='Créer dans PennyLane'
                                                                        mutation={mutation}
                                                                        valueO={isValidEmail(emailInput) ? ({
                                                                            value: ({
                                                                                customerId,
                                                                                email: emailInput,
                                                                            })
                                                                        }) : null}
                                                                        displayFullError={true} />
            </div>
        </div>
    );
}

export default PennyLaneCustomerCreateModal;