import IAccessToken from "../../IAccessToken";
import GlobalOutstandings from "./GlobalOutstandings/GlobalOutstandings";

// for now only global limits
const MiscPage = (
    {
        accessTokenO,
    } : {
        accessTokenO: IAccessToken | null;
    }
) =>
    <div className='container p-padding-2 p-vertical-center'>
        <GlobalOutstandings accessTokenO={accessTokenO} />
    </div>;

export default MiscPage;
