import IBankinError, { GenericItemError } from 'factor-lib/Bankin/IBankinError';
import nonConnectedBankinItemStatusToMessage from 'factor-lib/Bankin/bankinUtils';
import IAuthUser from "../../../../../../IAuthUser";
import {Axios} from "axios";
import BankinConnected from "./BankinConnected";
import { IItemContent } from 'factor-lib/Bankin/bankinUtils';
import BankinStatusCode from 'factor-lib/Bankin/BankinStatusCode';
import {useMutation} from "@tanstack/react-query";
import ButtonMutationEnabled from 'factor-lib/Buttons/ButtonMutationEnabled';

export const getItemsWithTransactions = (
    items: Array<IItemContent>
): Array<IItemContent> =>
    items.filter((bi) => bi.hasAnyTransaction);

const computeBankinStatusNonConnectedItems = (
    items: Array<IItemContent>
): string | null => {

    if (items.length === 0) {
        return null;
    }

    let result: string;

    if (getItemsWithTransactions(items.filter((bi) => bi.status === BankinStatusCode.Ok)).length === 0) {
        result = 'Pas de transactions de < 3 mois';
    } else {
        result = getStatusErrorMessage(items[0]);
    }

    result = result + ` (all status codes : [${items.map((bi) => bi.status).join(',')})]`;

    return result;
}

const getStatusErrorMessage = (item: IItemContent): string => {
    const errorO: IBankinError =
        nonConnectedBankinItemStatusToMessage.get(item.status) || GenericItemError;
    return `${errorO.title} - ${errorO.message}`;
}

const refreshBankinAsync = async (
    axios: Axios,
    customerId: string
): Promise<void> => {
    await axios.put<string>(
        `/adminBankin/${customerId}/refresh`
    ) // TODO : update the query
        /* .catch(serverErrorHandlerDefault) TODO */;
}

const computeBankinWoTransactionsStatus = (
    items: Array<IItemContent>,
    incompleteStep: string | null
): string => {
    const bankinIncompleteStepStr: string | null =
        !!incompleteStep
            ? `Last (known) registration step : ${incompleteStep}`
            : null;

    const bankinStatusNonConnectedItems: string | null = computeBankinStatusNonConnectedItems(items);
    return (
        !!bankinIncompleteStepStr
            ? !!bankinStatusNonConnectedItems
                ? `${bankinIncompleteStepStr} - ${bankinStatusNonConnectedItems}`
                : bankinIncompleteStepStr
            : !!bankinStatusNonConnectedItems
                ? bankinStatusNonConnectedItems
                : '<<No infos anywhere>>'
    );
}

const computeBankinStatus = (
    items: Array<IItemContent>,
    itemsWithTransactions: Array<IItemContent>,
    incompleteStep: string | null
): string =>
    itemsWithTransactions.length > 0
        ? itemsWithTransactions.filter((bi) => bi.status === BankinStatusCode.Ok).length > 0
            ? 'Ok'
            : `Connection perdue : ${getStatusErrorMessage(itemsWithTransactions[0])}`
        : computeBankinWoTransactionsStatus(items, incompleteStep);

const Refresh = (
    {
        axios,
        customerId,
    }: {
        axios: Axios;
        customerId: string;
    }
) =>
    <ButtonMutationEnabled id='refreshBankin'
                           text='Refresh'
                           mutation={useMutation<void, any, null>(
                               () => refreshBankinAsync(axios, customerId)
                           )}
                           value={null}
                           displayFullError={true}/>;

// Display if we have some bankin user created
const BankinStarted = (
    {
        className,
        axios,
        customerId,
        authUser,
        // hasAnyTransaction,
        incompleteStep,
        items,
        sellerIbanO
    }: {
        className?: string;
        axios: Axios;
        customerId: string;
        authUser: IAuthUser | null;
        // hasAnyTransaction: boolean;
        incompleteStep: string | null;
        items: Array<IItemContent>;
        sellerIbanO: string | null;
    }
) => {
    const itemsWithTransactions: Array<IItemContent> = getItemsWithTransactions(items);
    const bankinStatusStr = computeBankinStatus(items, itemsWithTransactions, incompleteStep);
    return (
        <div className={`${!!className ? `${className} ` : ''}`}>
            <div className='p-margin-bottom-6'>
                <span className='p-bold'>Statut :</span> {`${bankinStatusStr}`}
            </div>

            <div className='p-horizontal-center'>
                { (!!authUser?.canManageProfiles && items.filter((bi) => bi.status === BankinStatusCode.Ok).length > 0 /* we have something to refresh */) &&
                    <Refresh axios={axios}
                             customerId={customerId} />
                }
                { itemsWithTransactions.length > 0 &&
                    <BankinConnected axios={axios}
                                     customerId={customerId}
                                     sellerIbanO={sellerIbanO} />
                }
            </div>
        </div>
    );
}

export default BankinStarted;
