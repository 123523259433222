import {Axios} from "axios";
import { useState } from "react";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { customerDetailsQueryKey, ICustomerDetailsQueryResult, ICustomerUser } from "../../customerDetailsQuery";
import { ResultOrErrors } from "../../../../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../../../../utils/ShowValidationErrorsButton";
import Input from "factor-lib/forms/Inputs/Input";
import {isValidGuid, validateGuidError} from "factor-lib/utils/guidUtils";
import { SIZE_FIXED, KIND_PRIMARY } from "factor-lib/Buttons/Button";

const AddText = 'Ajouter';

interface IAddCustomerUserParams {
    azureObjectId: string;
}

const AddCustomerUserEnabled = (
    {
        axios,
        customerId,
        addParams,
        closeModal,
        kind,
        size,
        text
    } : {
        axios: Axios;
        customerId: string;
        addParams: IAddCustomerUserParams;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const addCustomerUserMutation: UseMutationResult<string, any, IAddCustomerUserParams, null> =
        useMutation<string, any, IAddCustomerUserParams, null>(
            async (addParams2: IAddCustomerUserParams) =>
                (await axios.post<string>(
                    `adminCustomerUsers/${customerId}/`,
                    addParams2
                )).data,
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId),
                        (old?: ICustomerDetailsQueryResult) => ({
                            customerDetails: {
                                ...old!.customerDetails,
                                users: [
                                    ...old!.customerDetails.users,
                                    {
                                        azureObjectId: addParams.azureObjectId
                                    }
                                ]
                            }
                        })
                    );
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='addCustomerUserButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={addCustomerUserMutation}
                               displayFullError={true}
                               value={addParams} />
    );
}

const getAddCustomerUserParams = (
    azureObjectIdInput: string,
    existingUsers: ReadonlyArray<ICustomerUser>
): ResultOrErrors<IAddCustomerUserParams> => {
    const validationErrors: string[] = [];

    if (!isValidGuid(azureObjectIdInput)) {
        validationErrors.push(`l'identifiant doit être de format GUID`);
    }

    if (existingUsers.some((u) => u.azureObjectId === azureObjectIdInput)) {
        validationErrors.push(`cet utilisateur est déjà lié à cette plateforme`);
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    return ResultOrErrors.fromResult({
        azureObjectId: azureObjectIdInput
    });
};

const AddCustomerUserModalContent = (
    {
        axios,
        customerId,
        users,
        closeModal
    } : {
        axios: Axios;
        customerId: string;
        users: ReadonlyArray<ICustomerUser>;
        closeModal: () => void;
    }
) => {

    const [ azureObjectIdInput, setAzureObjectIdInput ] = useState('');

    const addParams: ResultOrErrors<IAddCustomerUserParams> =
        getAddCustomerUserParams(
            azureObjectIdInput,
            users
        );

    return (
        <div className='p-padding-4'>
            <div className='p-padding-bottom-5 p-both-center p-bold p-size-4'>
                Lier un utilisateur Azure à la plateforme
            </div>

            <div>
                <div className='p-bold'>Azure Object ID</div>
                <Input inputValue={azureObjectIdInput}
                       placeHolder='8ed99601-2203-431f-bf10-ca1621f18270' // a random guid
                       enabled={{
                           updateInputValue: setAzureObjectIdInput,
                           validateInput: () => validateGuidError(azureObjectIdInput),
                           innerId: {
                               value: 'autofocus-azure-object-id',
                               autofocus: true
                           }
                       }} />
            </div>

            <div className='p-margin-top-4 p-vertical-center'>
                { !!addParams.result
                    ? <AddCustomerUserEnabled axios={axios}
                                              customerId={customerId}
                                              addParams={addParams.result}
                                              closeModal={closeModal}
                                              kind={KIND_PRIMARY}
                                              size={SIZE_FIXED}
                                              text={AddText} />
                    : <ShowValidationErrorsButton validationErrors={addParams.errors!}
                                                  kind={KIND_PRIMARY}
                                                  size={SIZE_FIXED}
                                                  text={AddText} />
                }
            </div>
        </div>
    );
}

const AddCustomerUser = (
    {
        axios,
        customerId,
        users,
        kind,
        size
    } : {
        axios: Axios;
        customerId: string;
        users: ReadonlyArray<ICustomerUser>;
        kind: string;
        size: string;
    }
) =>
    <ButtonForModal id='addCustomerUser'
                    kind={kind}
                    size={size}
                    text='Ajouter'
                    modalMaxWidth={null}
                    modalFullMaxWidth={false}
                    childModalContent={(closeModal) =>
                        <AddCustomerUserModalContent axios={axios}
                                                     customerId={customerId}
                                                     users={users}
                                                     closeModal={closeModal} />
                    }/>

export default AddCustomerUser;
