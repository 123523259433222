import { parseDateInput } from "factor-lib/forms/DateInput/DateInput";
import { companyMatchesSelection } from "factor-lib/Company/ICompanySelection";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import {isValidString } from 'factor-lib/forms/Inputs/utils';
import { isValidEmail } from 'factor-lib/forms/Inputs/emailUtils';
import { isValidPhone } from 'factor-lib/forms/Inputs/phoneUtils';
import { isValidAmount } from "factor-lib/forms/Inputs/InputAmount";
import { ICompanySelection } from "factor-lib/Company/ICompanySelection";

export const collectCoreInvoiceParamsErrors = (
    numberInput: string,
    amountWoTaxInput: string,
    amountTaxInput: string,
    issueDateInput: string,
    dueDateInput: string
): string[] => {
    const validationErrorsCollector: string[] = [];

    if (!isValidString(numberInput)) {
        validationErrorsCollector.push('Le numéro de facture est invalide');
    }

    if (!isValidAmount(amountWoTaxInput)) {
        validationErrorsCollector.push('Le montant hors taxes est invalide');
    }

    if (!isValidAmount(amountTaxInput)) {
        validationErrorsCollector.push('Le montant des taxes est invalide');
    }

    const issueInputParsed: Date | null = parseDateInput(issueDateInput);
    if (issueInputParsed === null) {
        validationErrorsCollector.push(`La date d'émission est invalide`);
    }

    const dueDateInputParsed: Date | null = parseDateInput(dueDateInput);
    if (dueDateInputParsed === null) {
        validationErrorsCollector.push(`La date d'échéance est invalide`);
    }

    if (!!issueInputParsed && !!dueDateInputParsed && dueDateInputParsed < issueInputParsed) {
        validationErrorsCollector.push(`La date d'échéance de ne peux pas être avant la date d'émission`);
    }

    return validationErrorsCollector;
}

export const validateBuyerEmailsErrorsCollector = (
    validationErrorsCollector: string[],
    buyerEmailsInputs: string[]
) => {
    if (buyerEmailsInputs.some((be) => !isValidEmail(be))) {
        validationErrorsCollector.push(`Un email du buyer est invalide`);
    }

    // Check for email duplicates
    const buyerEmailsSet = new Set<string>();
    for (const buyerEmail of buyerEmailsInputs) {
        if (buyerEmailsSet.has(buyerEmail)) {
            validationErrorsCollector.push(`Un email est en doublon : ${buyerEmail}`);
        } else {
            buyerEmailsSet.add(buyerEmail);
        }
    }
}

export const validateBuyerPhonesErrorsCollector = (
    validationErrorsCollector: string[],
    buyerPhonesInputs: string[] // assert already trimmed and filtered (phones are mostly optional)
) => {
    if (buyerPhonesInputs.some((bp) => !isValidPhone(bp))) {
        validationErrorsCollector.push(`Un numéro de téléphone du buyer est invalide`);
    }

    // Check for phones duplicates
    const buyerPhonesSet = new Set<string>();
    for (const buyerPhone of buyerPhonesInputs) {
        if (buyerPhonesSet.has(buyerPhone)) {
            validationErrorsCollector.push(`Un téléphone est en doublon : ${buyerPhone}`);
        } else {
            buyerPhonesSet.add(buyerPhone);
        }
    }
}

export const collectInvoiceBuyerParamsErrors = (
    sellerCompany: {
        id: string;
        identifier: IIntCompanyIdentifier;
    } | null, /* tested elsewhere */
    buyerSelection: ICompanySelection | null,
    buyerEmailsInputO: string[] | null, // null -> no check needed
    buyerPhonesInputO: string[] | null // null -> no check needed
): string[] => {

    const validationErrorsCollector: string[] = [];

    if (!buyerSelection) {
        validationErrorsCollector.push(`La société du buyer n'est pas renseignée`);
    } else if (!!sellerCompany) {
        if (companyMatchesSelection(sellerCompany, buyerSelection)) {
            validationErrorsCollector.push('Le société du buyer doit être différente de celle du seller');
        }
    }

    if (buyerEmailsInputO !== null) {
        validateBuyerEmailsErrorsCollector(validationErrorsCollector, buyerEmailsInputO);
    }

    if (buyerPhonesInputO !== null) {
        validateBuyerPhonesErrorsCollector(
            validationErrorsCollector,
            buyerPhonesInputO.map((bp) => bp.trim())
                .filter((bp) => bp.length > 0)
        );
    }

    return validationErrorsCollector;
}
