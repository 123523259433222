import Loader from "factor-lib/Loader";
import {
    IBuyerEvent,
    IBuyerEventInvoice,
    IFinancingMerciFacteurMail,
    IMerciFacteurMail
} from "../buyerCompanyDetailsQuery";
import BuyerEventsTableLoaded from "./BuyerEventsTableLoaded";
import {Axios} from "axios";
import GraphQLQueryWrapper from "../../../../utils/GraphQLQueryWrapper";

export interface IBuyerFinancingEmail {
    sentDate: string;
    sentTo: string;
    trigger: string;
    templateName: string;

    subject: string | null;
    status: string | null;
    opensCount: number | null;
    clicksCount: number | null;

    invoiceId: string;
    invoiceNumber: string;
    invoiceAmountWiTax: number;
}

interface IBuyerFinancingEmailsQueryResult {
    financingEmails: IBuyerFinancingEmail[];
}

const BuyerEventsTable = (
    {
        axios,
        buyerCompanyId,
        buyerEvents,
        buyerEventsToInvoices,
        merciFacteurMails,
        merciFacteurMailsToFinancings,
        fetchBuyerEmails,
        setSelectedBuyerEvent
    }: {
        axios: Axios;
        buyerCompanyId: string;
        buyerEvents: IBuyerEvent[];
        buyerEventsToInvoices: Map<string, IBuyerEventInvoice[]>;
        merciFacteurMails: IMerciFacteurMail[];
        merciFacteurMailsToFinancings: Map<number, IFinancingMerciFacteurMail[]>;
        fetchBuyerEmails: boolean;
        setSelectedBuyerEvent: (buyerEvent: IBuyerEvent) => void;
    }
) =>
    <div className='p-full-width' style={{overflowX: 'auto'}}>
        <table className='table is-striped is-bordered is-hoverable is-fullwidth'>
            <thead>
                <tr>
                    <th>Who</th>
                    <th>Date - Heure</th>
                    <th>Numéro Facture</th>
                    <th>Montant TTC</th>
                    <th>Type</th>
                    <th>Auto Email</th>
                    <th>Commentaire</th>
                </tr>
            </thead>
            { fetchBuyerEmails
                ? <GraphQLQueryWrapper queryKey={['company', buyerCompanyId, 'buyer', 'financing-emails' /* TODO : shared ?*/]}
                                       axios={axios}
                                       queryParams={{
                                           query: `
                                                query Q($buyerCompanyId: Guid!) {
                                                    financingEmails (buyerCompanyId: $buyerCompanyId) {
                                                        sentDate
                                                        sentTo
                                                        templateName
                                                        trigger
    
                                                        invoiceId
                                                        invoiceNumber
                                                        invoiceAmountWiTax
    
                                                        subject
                                                        status
                                                        opensCount
                                                        clicksCount
                                                    }
                                                }
                                           `,
                                           variables: {
                                               buyerCompanyId
                                           }
                                       }}
                                       onLoading={() =>
                                           <tbody>
                                               <tr>
                                                   <td/>
                                                   <td/>
                                                   <td/>
                                                   <td><Loader /></td>
                                                   <td/>
                                                   <td/>
                                                   <td/>
                                               </tr>
                                           </tbody>
                                       }
                                     onSuccess={(r: IBuyerFinancingEmailsQueryResult) =>
                                         <BuyerEventsTableLoaded buyerEvents={buyerEvents}
                                                                 buyerEventsToInvoices={buyerEventsToInvoices}
                                                                 setSelectedBuyerEvent={setSelectedBuyerEvent}
                                                                 merciFacteurMails={merciFacteurMails}
                                                                 merciFacteurMailsToFinancings={merciFacteurMailsToFinancings}
                                                                 buyerFinancingEmails={r.financingEmails} />
                                     } />
                : <BuyerEventsTableLoaded buyerEvents={buyerEvents}
                                          buyerEventsToInvoices={buyerEventsToInvoices}
                                          setSelectedBuyerEvent={setSelectedBuyerEvent}
                                          merciFacteurMails={merciFacteurMails}
                                          merciFacteurMailsToFinancings={merciFacteurMailsToFinancings}
                                          buyerFinancingEmails={[]} />
            }
        </table>
    </div>;

export default BuyerEventsTable;
