import FinancingRequestsTable, {
    financingRequestsFilterOptions
} from "../../utils/FinancingRequest/FinancingRequestsTable";
import IAccessToken from "../../IAccessToken";
import useUrlQuery from "../../utils/useUrlQuery";
import { NavigateFunction } from "react-router-dom";
import { useContext } from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { invoicesUrlTab } from "./invoicesUrls";
import { NewFinancingRequestsQueryKeyPrefix } from "../../utils/FinancingRequest/FinancingRequestsQuery";

const AllNewFinancingsRequestsQueryKey = [...NewFinancingRequestsQueryKeyPrefix, 'all'];

export const financingRequestsUrlParam = 'financingRequests';

const ALL_FINANCING_REQUESTS_FILTER_QUERY_PARAM_NAME = 'filter';

const financingRequestsAndFilterRoute = (filterIndex: number | null) =>
    `${invoicesUrlTab(financingRequestsUrlParam)}${filterIndex !== null ? `?${ALL_FINANCING_REQUESTS_FILTER_QUERY_PARAM_NAME}=${filterIndex}` : ''}`;

const FinancingRequestsTab = (
    {
        className,
        accessTokenO,
        searchStrFilter
    }: {
        className?: string;
        accessTokenO: IAccessToken | null;
        searchStrFilter: string;
    }
) => {
    const query = useUrlQuery();
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    const statusFilterQueryParam: string | null = query.get(ALL_FINANCING_REQUESTS_FILTER_QUERY_PARAM_NAME);
    const statusFilterIndexO: number | null = !!statusFilterQueryParam ? parseInt(statusFilterQueryParam) : null;

    // Ideally, we could already 'start' the queries (before the redirect), but ok for now
    return (
        <FinancingRequestsTable className={className}
                                accessTokenO={accessTokenO}
                                queryKey={AllNewFinancingsRequestsQueryKey}
                                queryParam={{ sellerId: null, buyerCompanyId: null }}
                                selectedFilter={statusFilterIndexO != null ? financingRequestsFilterOptions[statusFilterIndexO] : null}
                                setSelectedFilter={(newOption) => navigate(
                                    financingRequestsAndFilterRoute(
                                        !!newOption
                                            ? financingRequestsFilterOptions.findIndex((o) => o.label === newOption.label)
                                            : null)
                                    )
                                }
                                searchStrFilterO={searchStrFilter}/>
    )
}

export default FinancingRequestsTab;
