import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import {KIND_PRIMARY, SIZE_COMPACT } from "factor-lib/Buttons/Button";
import CheckBox from "factor-lib/forms/CheckBox";
import {useState} from "react";
import IAuthUser from "../../IAuthUser";
import {UseMutationResult} from "@tanstack/react-query";
import {IUpdateSwanInstantPaymentMutationParams} from "./CustomerSwanSection";

const UpdateInstantPaymentText = 'Modifier';

const InstantPayments = (
    {
        authUserO,
        instantPayment,
        updateSwanInstantPaymentMutation,
        updateSwanInstantPaymentMutationValue
    }: {
        authUserO: IAuthUser | null;
        instantPayment: boolean;
        updateSwanInstantPaymentMutation: UseMutationResult<void, any, IUpdateSwanInstantPaymentMutationParams>;
        updateSwanInstantPaymentMutationValue: string;
    }
) => {
    const [newInstantPayment, setNewInstantPayment] = useState(instantPayment);
    return (
        <tr>
            <td style={{verticalAlign: 'middle'}}>Paiements instantanés</td>
            <td style={{verticalAlign: 'middle'}}>
                <CheckBox internalId='updateSellerSwanInstantPayment'
                          inputValue={newInstantPayment}
                          updateInputValue={setNewInstantPayment} />
            </td>
            { !!authUserO?.canManageProfiles &&
                <td>
                    { newInstantPayment !== instantPayment
                        ? <ButtonMutationEnabled id='updateSwanInstantPayment'
                                                  kind={KIND_PRIMARY}
                                                  size={SIZE_COMPACT}
                                                  text={UpdateInstantPaymentText}
                                                  mutation={updateSwanInstantPaymentMutation}
                                                  value={{
                                                      newInstantPayment,
                                                      value: updateSwanInstantPaymentMutationValue
                                                  }}
                                                  displayFullError={true} />
                        : <ButtonDisabled kind={KIND_PRIMARY}
                                           size={SIZE_COMPACT}
                                           text={UpdateInstantPaymentText} />

                    }
                </td>
            }
        </tr>
    );
}

export default InstantPayments;