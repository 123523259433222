import { Axios } from "axios";
import { ISellerDetailsQueryResult, sellerDetailsQueryKey } from "../../../sellerDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { ISellersQueryResult, sellersQueryKey } from "../../../../sellersQuery";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { ISellerForAddInvoiceQueryResult, sellerForAddInvoiceQueryKey } from "../../../../SellerAddInvoice/sellerAddInvoiceQuery";
import { preInvoiceDetailsPrefix, IPreInvoiceDetailsQueryResult } from "../../../../../PreInvoice/PreInvoiceDetailsQuery";
import {IInvoiceDetailsQueryResult, InvoiceDetailsQueryKeyPrefix} from "../../../../../Invoice/invoiceDetailsQuery";

const SellerBlockUnblock = (
    {
        className,
        axios,
        sellerId,
        isBlocked,
    } : {
        className?: string;
        axios: Axios;
        sellerId: string;
        isBlocked : boolean;
    }
) => {

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const switchEligibilityMutation: UseMutationResult<void, any, boolean> =
        useMutation<void, any, boolean>(
            async (block) => {
                await axios.put<string>(
                    `/adminSellers/${sellerId}/${block ? '' : 'un'}block`
                )
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old: ISellerDetailsQueryResult | undefined) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                isBlocked: !isBlocked
                            },
                        })
                    );

                    queryClient.setQueryData<ISellerForAddInvoiceQueryResult>(
                        sellerForAddInvoiceQueryKey(sellerId),
                        (old: ISellerForAddInvoiceQueryResult | undefined) => !!old ? ({
                            ...old!,
                            sellerDetails: {
                                ...old!.sellerDetails,
                                isBlocked: !isBlocked
                            },
                        }) : undefined
                    );

                    queryClient.setQueryData<ISellersQueryResult>(
                        sellersQueryKey,
                        (old: ISellersQueryResult | undefined) => !!old ? ({
                            sellers: old.sellers.map(seller =>
                                seller.id === sellerId
                                    ? ({
                                        ...seller,
                                        isBlocked: !isBlocked
                                    }) : seller
                            )
                        }) : undefined
                    );

                    queryClient.setQueriesData<IInvoiceDetailsQueryResult>(
                        InvoiceDetailsQueryKeyPrefix,
                        (old) => old?.invoiceDetails.seller.id === sellerId
                            ? ({
                                ...old,
                                invoiceDetails: {
                                    ...old.invoiceDetails,
                                    seller: {
                                        ...old.invoiceDetails.seller,
                                        isBlocked: !isBlocked
                                    }
                                }
                            }) : old
                    );

                    queryClient.setQueriesData<IPreInvoiceDetailsQueryResult>(
                        preInvoiceDetailsPrefix,
                        (old) => old?.preInvoiceDetails.email.matchingSeller?.id === sellerId
                            ? ({
                                ...old,
                                preInvoiceDetails: {
                                    ...old.preInvoiceDetails,
                                    email: {
                                        ...old.preInvoiceDetails.email,
                                        matchingSeller: {
                                            ...old.preInvoiceDetails.email.matchingSeller,
                                            isBlocked: !isBlocked
                                        }
                                    }
                                }
                            }) : old
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled className={className}
                               id='switchBlockSeller'
                               text={`${isBlocked ? 'Unblock seller' : 'Block seller'}`}
                               mutation={switchEligibilityMutation}
                               displayFullError={true}
                               value={!isBlocked} />
    );
}

export default SellerBlockUnblock;
