import { useSearchParams } from "react-router-dom";
import AddSellerForCustomer from "./AddSeller/AddSellerForCustomer";
import AddSellerChooseCustomer from "./AddSeller/AddSellerChooseCustomer";
import IAccessToken from "../../IAccessToken";
import Loader from "factor-lib/Loader";
import GraphQLQueryWrapper from "../../utils/GraphQLQueryWrapper";
import { AddSellerUrlCustomerIdQueryParamName } from "./sellersUrls";
import { IIntCompanyIdentifier, IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";

export interface ICustomerForAddSeller {
    company: {
        identifier: IIntCompanyIdentifier;
        name: string;
    };

    // one or the other
    marketplace: { id: string; } | null;
    directSeller: { id: string; } | null;

    // for prefilling
    contactEmail: string;
    contactPhone: string;
    billingIban: string | null;
}

export interface ICustomerForAddSellerQueryResult {
    customerDetails: ICustomerForAddSeller;
}

const AddSeller = (
    {
        accessTokenO,
    }: {
        accessTokenO: IAccessToken | null;
    }
) => {
    const [searchParams] = useSearchParams();
    const customerId: string | null = searchParams.get(AddSellerUrlCustomerIdQueryParamName);

    return (
        !!accessTokenO
            ? !!accessTokenO.authUserO?.canManageProfiles
                ? !!customerId
                    ? <GraphQLQueryWrapper queryKey={['customer-for-add-seller', customerId]}
                                           axios={accessTokenO.axios}
                                           queryParams={{
                                               query: `
                                                    query Q($customerId: Guid!) {
                                                        customerDetails (id: $customerId) {
                                                            company { identifier { ${IntCompanyIdentifierGraphQLFields} } name }
                                                            marketplace { id }
                                                            directSeller { id }
                                                            contactEmail
                                                            contactPhone
                                                            billingIban
                                                        }
                                                    }
                                               `,
                                               variables: {
                                                   customerId
                                               }
                                           }}
                                           onLoading={() => <Loader />}
                                           onSuccess={(r: ICustomerForAddSellerQueryResult) =>
                                               <AddSellerForCustomer axios={accessTokenO.axios}
                                                                     customerId={customerId}
                                                                     customer={r.customerDetails} />
                                           } />
                    : <AddSellerChooseCustomer axios={accessTokenO.axios} />
                : <span className='p-both-center p-padding-4 p-bold'>
                    Vous n'avez pas les droits pour ajouter un Seller
                </span>
            : <Loader />
    );
}

export default AddSeller;
