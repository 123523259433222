import Loader from "factor-lib/Loader";
import { ISellerDetails } from "./sellerDetailsQuery";
import IAuthUserLoad from "../../../IAuthUserLoad";
import SellerMainInfosSection from "./SellerInfosLoaded/Base/MainInfosSection";
import SellerCertificationSection from "./SellerInfosLoaded/Base/CertificationSection";
import CompanyOwnerInfosSection from "./SellerInfosLoaded/Base/CompanyOwnerInfosSection";
import DirectSellerBankinSection from "./SellerInfosLoaded/DirectSeller/BankinSection";
import DirectSellerBeneficiariesSection from "./SellerInfosLoaded/DirectSeller/BeneficiariesSection";
import DirectSellerBankStatementsSection from "./SellerInfosLoaded/DirectSeller/BankStatementsSection";
import SellerMiscInfosSection from "./SellerInfosLoaded/Base/MiscInfosSection";
import AddCompanyOwnerAsBeneficiary from "./SellerInfosLoaded/DirectSeller/CompanyOwner/AddCompanyOwnerAsBeneficiary";
import SellerIdentityFilesSection from "./SellerInfosLoaded/Base/IdentityFilesSection";
import {ReactElement} from "react";
import SellerSwanSection from "./SellerInfosLoaded/Base/SellerSwanSection";
import SellerUbbleSection from "./SellerInfosLoaded/Base/SellerUbbleSection";

const Section = (
    {
        title,
        children
    }: {
        title: string;
        children: ReactElement;
    }
) =>
    <section className='box'>
        <div className='title'>{ title }</div>
        { children }
    </section>

const ND_REGEX = /^ *\[ND]( +\[ND])* *$/;

export const isNd = (value: string) =>
    ND_REGEX.test(value);

const SellerInfosTemplate = (
    {
        sellerId,
        loaded,
    }: {
        sellerId: string,
        loaded: IAuthUserLoad<ISellerDetails> | null
    }
) =>
    <div>
        <Section title='Informations Générales'>
            { !!loaded?.value
                ? <SellerMainInfosSection axios={loaded.axios}
                                          authUser={loaded.authUserO}
                                          sellerId={sellerId}
                                          customerId={loaded.value.customer.id}
                                          isCertified={!!loaded.value.certif}
                                          marketplaceName={!!loaded.value.customer.directSeller
                                              ? null
                                              : loaded.value.customer.company.name
                                          }
                                          email={loaded.value.email}
                                          phone={loaded.value.phone}
                                          ibanO={loaded.value.iban}
                                          company={loaded.value.company}
                                          customCompanyFriendlyName={loaded.value.customCompanyFriendlyName}
                                          reminderEmailsSettings={loaded.value.reminderEmailsSettings} />
                : <Loader />
            }
        </Section>

        <Section title={`Pièces d'identité`}>
            { !!loaded?.value
                ? <SellerIdentityFilesSection axios={loaded.axios}
                                              authUser={loaded.authUserO}
                                              sellerId={sellerId}
                                              identityFiles={loaded.value.identityFiles} />
                : <Loader/>
            }
        </Section>

        <Section title='Certif'>
            { !!loaded?.value
                ? <SellerCertificationSection axios={loaded.axios}
                                              authUser={loaded.authUserO}
                                              sellerId={sellerId}
                                              customerId={loaded.value.customer.id}
                                              isCompanyValid={
                                                  !isNd(loaded.value.company.name) &&
                                                  !isNd(loaded.value.company.address.streetAddress) &&
                                                  !isNd(loaded.value.company.address.postCode) &&
                                                  !isNd(loaded.value.company.address.city)
                                              }
                                              currentCertif={loaded.value.certif}
                                              isBlocked={loaded.value.isBlocked}
                                              hasIbans={loaded.value.iban !== null && loaded.value.customer.billingIban !== null} />
                : <Loader/>
            }
        </Section>

        <Section title='Mandataire'>
            { !!loaded?.value
                ? <CompanyOwnerInfosSection axios={loaded.axios}
                                            authUser={loaded.authUserO}
                                            sellerId={sellerId}
                                            addCompanyOwnerUrlSuffix={!!loaded.value.customer.directSeller ? 'companyOwnerDirectSeller' : 'companyOwnerMarketplace'}
                                            companyOwner={loaded.value.companyOwner}
                                            isBirthInfosMandatory={!!loaded.value.customer.directSeller}
                                            addAsBeneficiaryNode={
                                                !!loaded.value.companyOwner &&
                                                !!loaded.value.customer.directSeller &&
                                                !loaded.value.customer.directSeller.isCompanyOwnerAddedAsBeneficiary
                                                    ? <AddCompanyOwnerAsBeneficiary axios={loaded.axios}
                                                                                    sellerId={sellerId}
                                                                                    directSellerId={loaded.value.customer.directSeller.id}
                                                                                    companyOwner={loaded.value.companyOwner} />
                                                    : null
                                            } />
                : <Loader/>
            }
        </Section>

        { !!loaded?.value && !!loaded.value.customer.directSeller &&
            <>
                <hr className='p-margin-top-3' />
                <span className='title p-both-center'>Informations supplémentaires du Seller Direct</span>
                <hr className='p-margin-bottom-3' />

                <Section title='Bénéficiaires'>
                    <DirectSellerBeneficiariesSection axios={loaded.axios}
                                                      authUser={loaded.authUserO}
                                                      sellerId={sellerId}
                                                      directSellerId={loaded.value.customer.directSeller.id}
                                                      beneficiaries={loaded.value.customer.directSeller.ultimateBeneficiaries} />
                </Section>

                <section className='box'>
                    <div>
                        <div>
                            <div className='title'>Bankin</div>
                            <DirectSellerBankinSection axios={loaded.axios}
                                                       authUser={loaded.authUserO}
                                                       customer={loaded.value.customer}
                                                       sellerIbanO={loaded.value.iban} />
                        </div>

                        <div className='p-margin-top-3'>
                            <div className='title'>Relevés Bancaires</div>
                            <DirectSellerBankStatementsSection axios={loaded.axios}
                                                               authUser={loaded.authUserO}
                                                               sellerId={sellerId}
                                                               customerId={loaded.value.customer.id}
                                                               existingBankStatement1FileName={loaded.value.customer.bankStatement1?.fileName || null}
                                                               existingBankStatement2FileName={loaded.value.customer.bankStatement2?.fileName || null}
                                                               existingBankStatement3FileName={loaded.value.customer.bankStatement3?.fileName || null} />
                        </div>

                        {/* <div className='p-margin-top-3'>
                            <div className='title'>Sepa</div>
                            <DirectSellerSepaSection accessToken={loaded.accessToken}
                                                     authUser={loaded.authUser}
                                                     sellerId={sellerId}
                                                     directSellerId={loaded.value.customer.directSeller.id}
                                                     directSeller={loaded.value.customer.directSeller}
                                                     sellerIban={loaded.value.iban} />
                        </div> */}
                    </div>
                </section>
            </>
        }

        <Section title='Ondorse et Ubble'>
            { !!loaded?.value
                ? <SellerUbbleSection axios={loaded.axios}
                                      authUserO={loaded.authUserO}
                                      sellerId={sellerId}
                                      identifications={loaded.value.ubbleIdentifications} />
                : <Loader/>
            }
        </Section>

        <Section title='Swan'>
            { !!loaded?.value
                ? <SellerSwanSection axios={loaded.axios}
                                     authUserO={loaded.authUserO}
                                     customerId={loaded.value.customer.id}
                                     directSellerId={loaded.value.customer.directSeller?.id || null}
                                     instantPayment={loaded.value.swanInstantPayment}
                                     virtualIban={loaded.value.customer.dimplWallet?.virtualIban || null}
                                     sellerId={sellerId} />
                : <Loader/>
            }
        </Section>

        <Section title='Miscellaneous'>
            { !!loaded?.value
                ? <SellerMiscInfosSection axios={loaded.axios}
                                          authUserO={loaded.authUserO}
                                          sellerId={sellerId}
                                          sellerOpsNotes={loaded.value.opsNotes}
                                          sellerOtherDocumentsLink={loaded.value.otherDocumentsLink}
                                          sellerCustomDocumentFileName={loaded.value.customDocument?.fileName || null} />
                : <Loader/>
            }
        </Section>
    </div>;

export default SellerInfosTemplate;
