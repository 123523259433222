import {IBaseInvoiceSellerWithCompany, InvoiceBaseSellersGraphQLSubQuery} from "../IInvoice";

export const BaseInvoiceSellerWithCompanyAndSwanInstantPaymentGraphQLFields =
    `
        ${InvoiceBaseSellersGraphQLSubQuery}
        swanInstantPayment
    `

export interface IBaseInvoiceSellerWithCompanyAndSwanInstantPayment extends IBaseInvoiceSellerWithCompany {
    swanInstantPayment: boolean;
}