import { dateDiffDays, serverDateDeserialization } from "factor-lib/utils/dateUtils";
import IOption from "factor-lib/forms/Select/IOption";
import { IBuyerEvent } from "../buyerCompanyDetailsQuery";

export const BUYER_EVENT_EMAIL_OUT_TYPE = 'Email-OUT';

export const BUYER_EVENT_EMAIL_TYPES = [
    'Email-IN',
    BUYER_EVENT_EMAIL_OUT_TYPE
];

export const BUYER_EVENT_PHONE_TYPES = [
    'Tel-OUT-Answered',
    'Tel-OUT-Message',
    'Tel-OUT-Unanswered',
    'Tel-IN-Answered',
    'Tel-IN-Message',
    'Tel-IN-Unanswered',
    'Tel-OUT-BT-Message',
    'Tel-OUT-BT-Answered',
    'Tel-OUT-BT-Unanswered'
];

export const BUYER_EVENT_OTHER_TYPES = [
    'Letter-AR-OUT-Subrogation',
    'Other-OUT',
    'Other-IN',
    'Recouvrement-Tiers',
    'Cartan-OUT-DIC-Creation'
];

// order is important, maps to BuyerEventTypeEnum in back
export const BUYER_EVENT_TYPES = [
    ...BUYER_EVENT_EMAIL_TYPES,
    ...BUYER_EVENT_PHONE_TYPES,
    ...BUYER_EVENT_OTHER_TYPES
];

export const DEFAULT_BUYER_EVENT_TYPE = BUYER_EVENT_TYPES[0];

export const BUYER_EVENT_TYPES_OPTIONS: Array<IOption<string>> = BUYER_EVENT_TYPES.map((t) => ({ label: t, value: t }));

const ensure2digits = (n: number): string =>
    n < 10 ? '0' + n : n.toString();

export const getFormattedHoursAndMinutes = (value: Date): string => {
    return `${ensure2digits(value.getHours())}:${ensure2digits(value.getMinutes())}`;
};

export const isValidTime = (timeInput: string): boolean => {
    const hoursAndMinutes = timeInput.split(':');

    if (hoursAndMinutes.length !== 2) {
        return false;
    }

    const hoursPart = parseInt(hoursAndMinutes[0], 10);
    const minutesPart = parseInt(hoursAndMinutes[1], 10);

    return 0 <= hoursPart && hoursPart < 24 && 0 <= minutesPart && minutesPart < 60;
};

export const getDateAndTime = (date: Date, time: string): Date => {
    const hoursAndMinutes = time.split(':');
    const newDate = new Date(date);
    newDate.setHours(parseInt(hoursAndMinutes[0], 10), parseInt(hoursAndMinutes[1], 10));
    return newDate;
};

const DELAY_TO_UPDATE_BUYER_EVENT_DAYS = 7;

export const canUpdateBuyerEvent = (buyerEvent: IBuyerEvent, currentOpsId: string) =>
    currentOpsId === buyerEvent.dimplOps.azureObjectId &&
    dateDiffDays(new Date(), serverDateDeserialization(buyerEvent.eventDateTime)) < DELAY_TO_UPDATE_BUYER_EVENT_DAYS;
