import {ICustomerFinancingRequestStatus} from "../../Pages/Invoices/invoiceStatus";
import IBaseInvoice, {
    BaseInvoiceGraphQLFields,
    IInvoiceBuyerCompany,
    InvoiceBuyerCompaniesGraphQLSubQuery
} from "../IInvoice";
import {
    BaseInvoiceSellerWithCompanyAndSwanInstantPaymentGraphQLFields,
    IBaseInvoiceSellerWithCompanyAndSwanInstantPayment
} from "./IBaseInvoiceSellerWithCompanyAndSwanInstantPayment";

export interface IInvoiceFinancingRequested extends IBaseInvoice {
    payment: {
        partialAmountPaid: number;
        completePaidDate: string | null;
    };
    opsTags: {
        runningCheckBuyerNeeded: boolean;
        runningCheckOkEmail: boolean;
        runningCheckOkPhone: boolean;
        onboardingBuyerNeeded : boolean;
        onboardingBuyerOkEmail : boolean;
        onboardingBuyerOkPhone : boolean;
    };
}

export interface IGCustomerFinancingRequest<A> extends ICustomerFinancingRequestStatus<A> {
    id: string;
    creationDateTime: string;
    invoice: IInvoiceFinancingRequested;
}

export interface IAccepted {
    creationDateTime: string;
}

export interface ICustomerFinancingRequestAccepted extends IGCustomerFinancingRequest<IAccepted> {
}

// TODO : a better name ?
export type IAcceptedOrNot = IAccepted | null;

export interface ICustomerFinancingRequest extends IGCustomerFinancingRequest<IAcceptedOrNot> {
}

export interface ICustomerFinancingRequestNonAcceptedNorRejected extends IGCustomerFinancingRequest<null> {
}

export interface ICustomerFinancingRequestAccepted extends ICustomerFinancingRequestStatus<IAccepted> {
    id: string;
    creationDateTime: string;
    invoice: IInvoiceFinancingRequested;
}

export interface IFinancingRequests<C> {
    base: IGCustomerFinancingRequest<C>[];
    sellers: IBaseInvoiceSellerWithCompanyAndSwanInstantPayment[];
    buyerCompanies: IInvoiceBuyerCompany[];
}

export interface ICustomerFinancingRequestsResponse<C> {
    financingRequests: IFinancingRequests<C>;
}

export const FinancingRequestsQueryKeyPrefix = ['financing-requests'];
export const NewFinancingRequestsQueryKeyPrefix = [...FinancingRequestsQueryKeyPrefix, 'new'];

export const getFinancingRequestsGraphQLQuery =
    (sellerId: string | null, buyerCompanyId: string | null, isAccepted: boolean | null): string =>
        `{ financingRequests ${!!sellerId || !!buyerCompanyId  || (isAccepted !== null)
            ? /* At least one filter */`(${!!sellerId ? `sellerId: "${sellerId}"` : ''}${!!buyerCompanyId ? `${!!sellerId ? ', ': ''}buyerCompanyId: "${buyerCompanyId}"` : ''}${!!isAccepted !== null ? `${!!sellerId || buyerCompanyId ? ', ': ''}isAccepted: ${isAccepted}` : ''} )`
            : ''} {
                base {
                    expirationDate
                    accepted {
                        creationDateTime    
                    }
                    
                    id
                    creationDateTime
                    invoice {
                        ${BaseInvoiceGraphQLFields} 
                        opsTags {
                            onboardingBuyerNeeded
                            onboardingBuyerOkEmail
                            onboardingBuyerOkPhone
                            runningCheckBuyerNeeded
                            runningCheckOkEmail
                            runningCheckOkPhone
                        }
                        payment {
                            partialAmountPaid
                            completePaidDate
                        }
                    }
                }
                sellers {
                    ${BaseInvoiceSellerWithCompanyAndSwanInstantPaymentGraphQLFields}
                }
                ${InvoiceBuyerCompaniesGraphQLSubQuery}
            }
        }`;
