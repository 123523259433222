import {Axios} from "axios";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { useState } from "react";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { validatePhoneError, isValidPhone } from "factor-lib/forms/Inputs/phoneUtils";
import { isValidEmail, validateEmailError } from "factor-lib/forms/Inputs/emailUtils";
import Input from "factor-lib/forms/Inputs/Input";
import { ResultOrErrors } from "../../../../../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../../../../../utils/ShowValidationErrorsButton";
import { ISellersQueryResult, sellersQueryKey } from "../../../../sellersQuery";
import { ISellerDetailsQueryResult, sellerDetailsQueryKey } from "../../../sellerDetailsQuery";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";

const ConfirmText = 'Confirmer';

interface IUpdateSellerContactParams {
    // Null -> no update
    email: string | null;
    phone: string | null;
}

const UpdateSellerContactEnabled = (
    {
        sellerId,
        axios,
        updateParams,
        closeModal,
        kind,
        size,
        text
    } : {
        sellerId: string;
        axios: Axios;
        updateParams: IUpdateSellerContactParams;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    const updateSellerContactMutation: UseMutationResult<void, any, IUpdateSellerContactParams> =
        useMutation<void, any, IUpdateSellerContactParams>(
            async (updateParams2) => {
                await axios.put<void>(
                    `/adminSellers/${sellerId}/contact`,
                    updateParams2
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old: ISellerDetailsQueryResult | undefined) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                email: updateParams.email ?? old!.sellerDetails.email,
                                phone: updateParams.phone ?? old!.sellerDetails.phone
                            }
                        })
                    );
                    queryClient.setQueryData<ISellersQueryResult>(
                        sellersQueryKey,
                        (old: ISellersQueryResult | undefined) => ({
                            sellers: old!.sellers.map((seller) =>
                                seller.id === sellerId ? {
                                    ...seller,
                                    email: updateParams.email ?? seller.email,
                                } : seller
                            )
                        }
                    ));
                    closeModal();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateSellerContactButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateSellerContactMutation}
                               displayFullError={true}
                               value={updateParams} />
    );
}

const getUpdateSellerContactParams = (
    oldEmail: string,
    oldPhone: string,

    newEmailInput: string,
    newPhoneInput: string
): ResultOrErrors<IUpdateSellerContactParams> | null => {
    const validationErrors: string[] = [];

    if (!isValidEmail(newEmailInput)) {
        validationErrors.push(`L'email est invalide`);
    }

    if (!isValidPhone(newPhoneInput)) {
        validationErrors.push(`Le téléphone est invalide`);
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    const emailUpdate: string | null = newEmailInput !== oldEmail ? newEmailInput : null;
    const phoneUpdate: string | null = newPhoneInput !== oldPhone ? newPhoneInput : null;

    return (!!emailUpdate || !!phoneUpdate)
        ? ResultOrErrors.fromResult({
            email: emailUpdate,
            phone: phoneUpdate
        }) : null;
};

const UpdateSellerContactModalContent = (
    {
        axios,
        sellerId,
        email,
        phone,
        closeModal
    }: {
        axios: Axios;
        sellerId: string;
        email: string;
        phone: string;
        closeModal: () => void;
    }
) => {
    const [newEmailInput, setNewEmailInput] = useState(email);
    const [newPhoneInput, setNewPhoneInput] = useState(phone);

    const updateParams: ResultOrErrors<IUpdateSellerContactParams> | null = getUpdateSellerContactParams(
        email,
        phone,

        newEmailInput,
        newPhoneInput
    );

    return (
        <div className='p-padding-3'>
            <div>
                <span className='p-margin-right-4 p-bold'>Email</span>
                <Input inputValue={newEmailInput}
                       enabled={{
                           updateInputValue: setNewEmailInput,
                           validateInput: () => validateEmailError(newEmailInput),
                           innerId: {
                               value: 'emailInner',
                               autofocus: true
                           }
                       }} />
            </div>

            <div className='p-margin-top-4'>
                <span className='p-margin-right-4 p-bold'>Téléphone</span>
                <Input inputValue={newPhoneInput}
                       enabled={{
                           updateInputValue: setNewPhoneInput,
                           validateInput: () => validatePhoneError(newPhoneInput)
                       }} />
            </div>

            <div className='p-margin-top-4 p-vertical-center'>
                { !!updateParams
                    ? !!updateParams.result
                        ? <UpdateSellerContactEnabled axios={axios}
                                                      sellerId={sellerId}
                                                      updateParams={updateParams.result}
                                                      closeModal={closeModal}
                                                      kind={KIND_PRIMARY}
                                                      size={SIZE_FIXED}
                                                      text={ConfirmText} />
                        : <ShowValidationErrorsButton validationErrors={updateParams.errors!}
                                                      kind={KIND_PRIMARY}
                                                      size={SIZE_FIXED}
                                                      text={ConfirmText} />
                    : <ButtonDisabled kind={KIND_PRIMARY}
                                      size={SIZE_FIXED}
                                      text={ConfirmText} />
                }
            </div>
        </div>
    );
}

const UpdateSellerContact = (
    {
        className,
        axios,
        sellerId,
        email,
        phone
    }: {
        className?: string;
        axios: Axios;
        sellerId: string;
        email: string;
        phone: string;
    }
) =>
    <ButtonForModal className={className}
                    id='showUpdateSellerContactModal'
                    text='Modifier Contact'
                    childModalContent={(closeModal) =>
                        <UpdateSellerContactModalContent axios={axios}
                                                         sellerId={sellerId}
                                                         email={email}
                                                         phone={phone}
                                                         closeModal={closeModal} />
                    }/>;

export default UpdateSellerContact;
