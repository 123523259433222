import { ICustomerForAddSeller } from "../AddSeller";
import AddMarketplaceSeller from "./AddMarketplaceSeller";
import AddDirectSeller from "./AddDirectSeller";
import { Axios } from "axios";

const AddSellerForCustomer = (
    {
        axios,
        customerId,
        customer
    }: {
        axios: Axios;
        customerId: string;
        customer: ICustomerForAddSeller;
    }
) =>
    !!customer.marketplace
        ? <AddMarketplaceSeller axios={axios}
                                marketplaceId={customer.marketplace.id}
                                marketplaceName={customer.company.name}
                                marketplaceIdentifier={customer.company.identifier} />
        : !!customer.directSeller
            ? <span>Ce customer direct est déjà associé à un seller</span>
            : <AddDirectSeller axios={axios}
                               customerId={customerId}
                               customerName={customer.company.name}
                               customerIdentifier={customer.company.identifier}

                               // most likely the same as direct seller infos, we prefill them
                               initialSellerEmail={customer.contactEmail}
                               initialSellerPhone={customer.contactPhone}
                               initialSellerIbanO={customer.billingIban} />;

export default AddSellerForCustomer;
