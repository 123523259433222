import InputAmount, { isValidAmount, parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import { useState } from "react";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import SimulatorBuyerEnabled from "./SimulationBuyerEnabled";
import IAccessToken from "../../IAccessToken";
import Loader from "factor-lib/Loader";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { ICompanySelection } from "factor-lib/Company/ICompanySelection";
import { PlaceHolderSearchBuyer } from "../../utils/Company/CompanySearcher";
import CompanySelector from "../../utils/Company/CompanySelector";
import SimulatorOrderEnabled from "./SimulationOrderEnabled";

export const SimulationText = 'Simulation';

const SimulatorPage = (
    {
        accessTokenO,
    } : {
        accessTokenO: IAccessToken | null;
    }
) => {
    const [buyerSearchInput, setBuyerSearchInput] = useState('');
    const [buyerSelection, setBuyerSelection] = useState<ICompanySelection | null>(null);
    const [amountTTCInput, setAmountTTCInput] = useState('');

    const clearForm = (): void => {
        // setBuyerSirenInput(''); // Probably we want to keep the same buyer across multiple simulations
        setAmountTTCInput('');
    };

    return (
        <div className='container p-padding-2 p-vertical-center'>
            <div className='box p-vertical-stretch'>
                <div className='p-vertical-center'>
                    <div className='title'>Simulateur</div>
                    <div>(ne prends pas en compte les limites d'encours personalisables)</div>
                </div>

                <div className='p-margin-top-4 p-vertical-stretch'>
                    { !!accessTokenO
                        ? <CompanySelector axios={accessTokenO.axios}
                                           searchPlaceHolder={PlaceHolderSearchBuyer}
                                           searchInput={buyerSearchInput}
                                           setSearchInput={setBuyerSearchInput}
                                           initialDisplaySearch={true}
                                           selection={buyerSelection}
                                           setSelection={setBuyerSelection}
                                           autofocus={true} />
                        : <Loader/>
                    }

                    <InputAmount inputAmountValue={amountTTCInput}
                                 className='field p-margin-top-4'
                                 placeHolder='Montant TTC (optionnel)'
                                 enabled={{
                                     updateInputValue: setAmountTTCInput
                                 }} />


                    <div className='field p-margin-top-5 p-vertical-center'>
                        { !accessTokenO
                            ? <Loader/>
                            : !!buyerSelection ?
                                amountTTCInput === ''
                                    ? <SimulatorBuyerEnabled axios={accessTokenO.axios}
                                                             buyerSelection={buyerSelection}
                                                             kind={KIND_PRIMARY}
                                                             size={SIZE_FIXED}
                                                             onClose={clearForm} />
                                    : isValidAmount(amountTTCInput)
                                        ? <SimulatorOrderEnabled axios={accessTokenO.axios}
                                                                 buyerSelection={buyerSelection}
                                                                 amountTTC={parseInputAmount(amountTTCInput)}
                                                                 kind={KIND_PRIMARY}
                                                                 size={SIZE_FIXED}
                                                                 onClose={clearForm} />
                                        : <ButtonDisabled kind={KIND_PRIMARY}
                                                          size={SIZE_FIXED}
                                                          text={SimulationText} />

                                : <ButtonDisabled kind={KIND_PRIMARY}
                                                  size={SIZE_FIXED}
                                                  text={SimulationText} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SimulatorPage;
