import { formatAmountExtended } from "factor-lib/utils/amountUtils";
import { formatAdminDateNullableStr } from "../../../../../../utils/dateTimeUtils";
import { IBankinAccountDetails, IBankinAccountLoanDetails, IBankinAccountSummary, IBankinBank } from "./BankinConnected";

const UNKNOWN: string = '<<unknown>>';

const formatAmountNullable = (amountNullable: number | null, currency: string): string =>
    !!amountNullable
        ? formatAmountExtended(amountNullable * 100, true, currency)
        : UNKNOWN;

const formatDateStrNullable = (dateStrNullable: string | null): string =>
    formatAdminDateNullableStr(dateStrNullable) ?? UNKNOWN;

const LoanInfos = (
    {
        loan,
        currency
    }: {
        loan: IBankinAccountLoanDetails,
        currency: string;
    }
) =>
    <table className='table is-narrow'>
        <tbody>
            <tr>
                <td>Type</td>
                <td>{ loan.type ?? UNKNOWN }</td>
            </tr>
            <tr>
                <td>Date d'ouverture</td>
                <td>{ formatDateStrNullable(loan.opening_date) }</td>
            </tr>
            <tr>
                <td>Date de maturité </td>
                <td>{ formatDateStrNullable(loan.maturity_date) }</td>
            </tr>
            <tr>
                <td>Capital emprunté</td>
                <td>{ formatAmountNullable(loan.borrowed_capital, currency) }</td>
            </tr>
            <tr>
                <td>Capital remboursé</td>
                <td>{ formatAmountNullable(loan.repaid_capital, currency) }</td>
            </tr>
            <tr>
                <td>Capital restant</td>
                <td>{ formatAmountNullable(loan.remaining_capital, currency) }</td>
            </tr>
            <tr>
                <td>Taux d'intérêt</td>
                <td>{ loan.interest_rate ?? UNKNOWN }</td>
            </tr>
            <tr>
                <td>Total des intérêts estimés</td>
                <td>{ formatAmountNullable(loan.total_estimated_interests, currency) }</td>
            </tr>
            <tr>
                <td>Date du prochain paiement</td>
                <td>{ formatDateStrNullable(loan.next_payment_date) }</td>
            </tr>
            <tr>
                <td>Montant du prochain paiement</td>
                <td>{ formatAmountNullable(loan.next_payment_amount, currency) }</td>
            </tr>
        </tbody>
    </table>;

const BaseAccountInfos = (
    {
        sellerIbanO,
        account,
        bank
    }: {
        sellerIbanO: string | null;
        account: IBankinAccountDetails;
        bank: IBankinBank;
    }
) =>
    <table className='table is-narrow'>
        <tbody>
            <tr>
                <td className='p-bold'>Account ID</td>
                <td>{ account.id }</td>
            </tr>
            <tr>
                <td className='p-bold'>Item ID</td>
                <td>{ account.item_id }</td>
            </tr>
            <tr>
                <td className='p-bold'>Statut Bankin</td>
                <td>
                    { account.status }
                    { !!account.status_code_info ? ` - ${account.status_code_info}` : ''}
                    { !!account.status_code_description ? ` - ${account.status_code_description}` : ''}
                </td>
            </tr>
            <tr>
                <td className='p-bold'>Type</td>
                <td>{ account.type }</td>
            </tr>
            <tr>
                <td className='p-bold'>Compte Pro</td>
                <td>{ account.is_pro ? 'Oui' : 'Non' }</td>
            </tr>
            <tr>
                <td className='p-bold'>Nom</td>
                <td>{ account.name }</td>
            </tr>
            <tr>
                <td className='p-bold'>Banque</td>
                <td>{ bank.name } - { bank.country_code }</td>
            </tr>
            <tr>
                <td className='p-bold'>IBAN</td>
                <td className={account.iban === null || account.iban !== sellerIbanO ? 'p-danger-text' : ''}>
                    { account.iban ?? '<<unknown>>' }
                </td>
            </tr>
            <tr>
                <td className='p-bold'>Solde</td>
                <td>{ formatAmountExtended(account.balance * 100, true, account.currency_code) }</td>
            </tr>
            <tr>
                <td className='p-bold'>Dernière mise à jour</td>
                <td>{ formatDateStrNullable(account.updated_at) }</td>
            </tr>
        </tbody>
    </table>;

const BankinAccountSummary = (
    {
        className,
        index,
        summary,
        sellerIbanO
    }: {
        className?: string;
        index: number;
        summary: IBankinAccountSummary;
        sellerIbanO: string | null;
    }
) =>
    <div className={className}>
        <div className='p-vertical-center p-size-6 p-bold'>
            Compte #{index + 1}
        </div>
        <hr/>

        <BaseAccountInfos sellerIbanO={sellerIbanO}
                          account={summary.account}
                          bank={summary.bank} />

        { !!summary.account.loan_details &&
            <div>
                <div className='p-bold'>
                    Ce compte a des prêts en cours
                </div>

                <LoanInfos loan={summary.account.loan_details}
                           currency={summary.account.currency_code} />
            </div>
        }
    </div>;

export default BankinAccountSummary;
