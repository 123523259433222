import IAccessToken from "../../IAccessToken";
import GraphQLWithAccessTokenWrapper from "../../GraphQLQueryWithAccessTokenWrapper";
import IAuthUserLoad from "../../IAuthUserLoad";
import {Link} from "react-router-dom";
import Loader from "factor-lib/Loader";
import {invoiceUrl} from "./invoicesUrls";
import {sellerUrl} from "../Sellers/sellersUrls";
import { buyerUrl } from "../Buyers/buyersUrls";
import {formatCompany} from "../../utils/companyUtils";
import {formatAdminDateStr} from "../../utils/dateTimeUtils";
import {formatAmount} from 'factor-lib/utils/amountUtils';
import IBaseInvoice, {
    BaseInvoiceGraphQLFields,
    IBaseInvoiceSellerWithCompany,
    IInvoiceBuyerCompany, InvoiceBaseSellersGraphQLSubQuery, InvoiceBuyerCompaniesGraphQLSubQuery
} from "../../utils/IInvoice";
import InvoicesTableLoaded from "./InvoicesTableLoaded";
import { InvoicesNotFinancingRequestedQueryKeyPrefix } from "../../utils/InvoiceUtils";

export const FILTER_ELIGIBLE = 'ELIGIBLE';
export const FILTER_PENDING = 'PENDING';

export const invoicesNotFinancingRequestedQueryKey = (filter: string) =>
    [...InvoicesNotFinancingRequestedQueryKeyPrefix, filter];

export interface IEligibleOrPendingEligibleInvoice extends IBaseInvoice {
}

export interface IInvoices {
    base: IEligibleOrPendingEligibleInvoice[];
    sellers: IBaseInvoiceSellerWithCompany[];
    buyerCompanies: IInvoiceBuyerCompany[];
}

const InvoiceRow = (
    {
        invoice,
        seller,
        buyerCompany
    }: {
        invoice: IEligibleOrPendingEligibleInvoice;
        seller: IBaseInvoiceSellerWithCompany;
        buyerCompany: IInvoiceBuyerCompany;
    }
) =>
    <tr>
        <td>
            <Link to={invoiceUrl(invoice.id)}>{ invoice.number }</Link>
        </td>
        <td>
            <Link to={sellerUrl(seller.id)}>
                { formatCompany(seller.company) }
            </Link>
        </td>
        <td>
            <Link to={buyerUrl(buyerCompany.id, false)}>
                { formatCompany(buyerCompany) }
            </Link>
        </td>
        <td>
            { formatAdminDateStr(invoice.dueDate) }
        </td>
        <td>
            { formatAmount(invoice.amountWoTax + invoice.amountTax) }
        </td>
    </tr>;

export interface IInvoicesResponse {
    invoices: IInvoices;
}

const EligibleOrPendingEligibleInvoicesTable = (
    {
        className,
        accessTokenO,
        eligibilityFilter,
        searchStrFilter
    }: {
        className?: string;
        accessTokenO: IAccessToken | null,
        eligibilityFilter: string;
        searchStrFilter: string;
    }
) =>
    <table className={`${!!className ? `${className} ` : ''}table is-striped`}>
        <thead>
            <tr>
                <th>N° de facture</th>
                <th>Seller</th>
                <th>Buyer</th>
                <th>Date d'échéance</th>
                <th>Montant TTC</th>
            </tr>
        </thead>

        <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                       queryKey={invoicesNotFinancingRequestedQueryKey(eligibilityFilter)}
                                       queryParams={{
                                           query: `{
                                            invoices (statuses: [${eligibilityFilter}]) {
                                                base {
                                                    ${BaseInvoiceGraphQLFields} 
                                                }
                                                sellers {
                                                    ${InvoiceBaseSellersGraphQLSubQuery}
                                                }
                                                ${InvoiceBuyerCompaniesGraphQLSubQuery}
                                            }
                                       }`
                                       }}
                                       onLoading={() =>
                                           <tbody>
                                               <tr>
                                                   <td/>
                                                   <td/>
                                                   <td><Loader/></td>
                                                   <td/>
                                                   <td/>
                                               </tr>
                                           </tbody>
                                       }
                                       onSuccess={(r: IAuthUserLoad<IInvoicesResponse>) =>
                                           <InvoicesTableLoaded<IEligibleOrPendingEligibleInvoice> invoices={r.value.invoices.base}
                                                                                                   sellers={r.value.invoices.sellers}
                                                                                                   buyerCompanies={r.value.invoices.buyerCompanies}
                                                                                                   searchStrFilter={searchStrFilter}
                                                                                                   invoiceRowFactory={(i, index) =>
                                                                                                       <InvoiceRow key={`invoice-${index}`}
                                                                                                                   invoice={i.invoice}
                                                                                                                   seller={i.seller}
                                                                                                                   buyerCompany={i.buyerCompany} />
                                                                                                   }/>

                                       }/>
    </table>;

export default EligibleOrPendingEligibleInvoicesTable;
