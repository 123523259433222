import {ReactElement, ReactNode, useState} from "react";
import InputEnabled2 from "factor-lib/forms/Inputs/InputEnabled2";
import {isEmpty} from 'factor-lib/forms/Inputs/utils';

const WithSearchList = (
    {
        autofocus,
        inputElement,
        childrenFactory
    }: {
        autofocus: boolean;
        inputElement: (
            setFocused: (newFocus: boolean) => void,
            searchInput: string,
            setSearchInput: (newInput: string) => void
        ) => ReactElement;
        childrenFactory: (searchInput: string) => ReactNode;
    }
) => {
    const [searchInput, setSearchInput] = useState('');
    return (
        <div>
            <div className='panel-block'>
                <InputEnabled2 isEmpty={isEmpty(searchInput)}
                               className='p-full-width'
                               enabled={{
                                   // updateInputValue: setSearchInput,
                                   validateInput: () => null,
                                   autofocusInnerId: (autofocus && 'searchInner') || null
                               }}
                               inputElement={(setFocused: (newFocus: boolean) => void) => inputElement(setFocused, searchInput, setSearchInput)}/>
            </div>

            { childrenFactory(searchInput) }
        </div>
    );
}

export default WithSearchList;