import SellerConfidenceFlags from "./Certification/SellerConfidenceFlags";
import SellerBlockUnblock from "./Certification/SellerBlockUnblock";
import IAuthUser from "../../../../../IAuthUser";
import { Axios } from "axios";
import { Certif } from "../../sellerDetailsQuery";

const CertificationSection = (
    {
        axios,
        authUser,
        sellerId,
        customerId,
        isCompanyValid,
        currentCertif,
        isBlocked,
        hasIbans
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        sellerId: string;
        customerId: string;
        isCompanyValid: boolean;
        currentCertif: Certif;
        isBlocked: boolean;
        hasIbans: boolean;
    }
) =>
    <div>
        <SellerConfidenceFlags axios={axios}
                               authUser={authUser}
                               sellerId={sellerId}
                               customerId={customerId}
                               hasIbans={hasIbans}
                               currentCertif={currentCertif}
                               isCompanyValid={isCompanyValid}/>

        { !!authUser?.canManageProfiles &&
            <SellerBlockUnblock className='p-margin-top-4'
                                axios={axios}
                                sellerId={sellerId}
                                isBlocked={isBlocked} />
        }
    </div>;

export default CertificationSection;
