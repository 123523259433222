import { Axios } from "axios";
import Modal from "factor-lib/Modal";
import { useContext, useState } from "react";
import Button from "factor-lib/Buttons/Button";
import Input from "factor-lib/forms/Inputs/Input";
import { computeText } from "factor-lib/Buttons/ButtonMutationEnabled";
import { ResultOrErrors } from "../../../utils/ResultOrErrors";
import { collectCoreInvoiceParamsErrors, collectInvoiceBuyerParamsErrors } from "../../../utils/newInvoiceUtils";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import SellerAddInvoiceBuyerInfos, { IInvoiceBuyerInfosInput } from "./SellerAddInvoiceBuyerInfos";
import { ILineContactInput } from "factor-lib/AddInvoice/BuyerContactsInput";
import { FileUploadTimeoutMilliseconds } from "factor-lib/serverUtils/axiosConfigUtils";
import {
    addInvoiceAsync,
    IAddInvoiceResponse,
    IInvoiceAddResponseO
} from "../../../utils/AddInvoice/addInvoiceUtils";
import InvoiceAlreadyExistModal from "../../../utils/AddInvoice/InvoiceAlreadyExistModal";
import { NavigateFunction } from "react-router-dom";
import { IBuyerCompany } from 'factor-lib/AddInvoice/BuyerSelection';
import companySearchRequestAsync from "../../../utils/Company/companySearchRequestAsync";
import { invoiceUrl } from "../../Invoices/invoicesUrls";
import InvoiceAddBaseInfos, {IInvoiceBaseInfosInput, IInvoiceBaseInfosInputEnabled } from "factor-lib/AddInvoice/InvoiceAddBaseInfos";
import ValidationErrorModal from "../../../utils/ValidationErrorModal";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { isValidIban, validateIbanError } from "factor-lib/forms/Inputs/ibanUtils";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import { parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import { GlobalOutstandingsQueryKey } from "../../Misc/GlobalOutstandings/globalOutstandingsQuery";
import { parseDateInput, validateDateInputWithinRange } from "factor-lib/forms/DateInput/DateInput";
import { getFactorContext } from "../../../IFactorContext";
import IBuyerCompanySelection from "factor-lib/AddInvoice/IBuyerCompanySelection";

const AddText = 'Ajouter';

const getInvoiceAddParams = (
    sellerId: string,
    isDirectSeller: boolean,
    sellerCompany: {
        id: string;
        identifier: IIntCompanyIdentifier;
    },
    buyerSelection: IBuyerCompanySelection | null,
    buyerEmailsInput: string[],
    buyerPhonesInput: string[],
    numberInput: string,
    issueDateInput: string,
    dueDateInput: string,
    amountWoTaxInput: string,
    amountTaxInput: string,
    customIbanInput: string,
    deliveryValidationDateInput: string,
    file: File | null,
): ResultOrErrors<FormData> => {

    let validationErrors: string[] = collectInvoiceBuyerParamsErrors(
        sellerCompany,
        !!buyerSelection ? {
            id: buyerSelection.existingIdO,
            siren: buyerSelection.newSirenO
        } : null,
        buyerEmailsInput,
        buyerPhonesInput
    )

    validationErrors = [
        ...validationErrors,
        ...collectCoreInvoiceParamsErrors(
            numberInput,
            amountWoTaxInput,
            amountTaxInput,
            issueDateInput,
            dueDateInput
        )
    ];

    if (isDirectSeller) {
        if (customIbanInput !== '') {
            validationErrors.push(`Un custom IBAN n'est pas autorisé pour un seller direct`);
        }

        if (deliveryValidationDateInput !== '') {
            validationErrors.push(`Une date de validation de livraison n'est pas autorisée pour un seller direct`);
        }
    } else {
        if (customIbanInput !== '' && !isValidIban(customIbanInput)) {
            validationErrors.push(`Le custom IBAN est invalide`);
        }

        const deliveryValidationDateInputParsed: Date | null = parseDateInput(deliveryValidationDateInput);
        if (deliveryValidationDateInputParsed === null) {
            validationErrors.push(`La date de validation de livraison est invalide`);
        }
    }

    if (!file) {
        validationErrors.push('Le fichier est manquant');
    }

    if (validationErrors.length > 0) {
        return ResultOrErrors.fromError(validationErrors);
    }
    // else

    const formData = new FormData();

    formData.append('sellerId', sellerId);

    if (!!buyerSelection!.existingIdO) {
        formData.append('buyerCompanyId', buyerSelection!.existingIdO);
    } else {
        formData.append('buyerSiren', buyerSelection!.newSirenO!);
    }

    buyerEmailsInput.forEach((be) => formData.append('buyerEmail', be));
    buyerPhonesInput.forEach((bp) => formData.append('buyerPhone', bp));

    formData.append('invoiceNumber', numberInput);
    formData.append('invoiceIssueDate', serverDateSerialization(parseDateInput(issueDateInput)!));
    formData.append('invoiceDueDate', serverDateSerialization(parseDateInput(dueDateInput)!));
    formData.append('invoiceAmountWoTax', parseInputAmount(amountWoTaxInput).toString());
    formData.append('invoiceAmountTax', parseInputAmount(amountTaxInput).toString());

    if (customIbanInput !== '') {
        formData.append('invoiceCustomIban', customIbanInput);
    }

    if (deliveryValidationDateInput !== '') {
        formData.append('deliveryValidationDate', serverDateSerialization(parseDateInput(deliveryValidationDateInput)!));
    }

    formData.append('invoiceFile', file!);

    return ResultOrErrors.fromResult(formData);
}

const ResponseModal = (
    {
        canFinance,
        result,
        closeModalNewInvoice
    }: {
        canFinance: boolean;
        result: IAddInvoiceResponse;
        closeModalNewInvoice: () => void;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    return (
        <Modal id='AddAndRequestFinancingResultModal'
               maxWidth={null}
               fullMaxWidth={false}
               active={true}
               close={closeModalNewInvoice}>
            <div className='p-padding-4 p-vertical-center'>
                { !!result.notEligibleReason
                    ? <span>La facture a été ajoutée mais elle n'est pas éligible, raison : {result.notEligibleReason}</span>
                    : canFinance
                        ? <span>La facture a été ajoutée et une demande de financement a été ouverte</span>
                        : <span>La facture a été ajoutée</span>
                }
                <div className='columns p-margin-top-5'>
                    <div className='column'>
                        <Button id='GotoAddedInvoiceDetails'
                                text='Détails de la facture'
                                isLoading={false}
                                actionO={() => navigate(invoiceUrl(result.invoiceId))} />


                    </div>
                    <div className='column'>
                        <Button id='AddAnotherInvoiceButton'
                                text='Ajouter une autre facture'
                                isLoading={false}
                                actionO={closeModalNewInvoice} />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

const filePickerId = 'filePicker';

const SellerAddInvoiceLoaded = (
    {
        axios,
        sellerId,
        sellerCompany,
        isDirectSeller,
        isBlocked,
        isCertified,
        buyers
    }: {
        axios: Axios;
        sellerId: string;
        sellerCompany: {
            id: string;
            name: string;
            identifier: IIntCompanyIdentifier;
        };
        isDirectSeller: boolean;
        isBlocked: boolean;
        isCertified: boolean;
        buyers: ReadonlyArray<IBuyerCompany>;
    }
) => {
    const [ buyerSelection, setBuyerSelection ] = useState<IBuyerCompanySelection | null>(null);

    const [buyerContactsInputs, setBuyerContactsInputs] = useState<ILineContactInput[]>([{
        email: '',
        phone: ''
    }]);

    const [ numberInput, setNumberInput ] = useState('');
    const [ issueDateInput, setIssueDateInput ] = useState('');
    const [ dueDateInput, setDueDateInput ] = useState('');
    const [ amountWoTaxInput, setAmountWoTaxInput ] = useState('');
    const [ amountTaxInput, setAmountTaxInput ] = useState('');

    const [ customIbanInput, setCustomIbanInput ] = useState('');
    const [ deliveryValidationDateInput, setDeliveryValidationDateInput ] = useState('');

    const [ file, setFile ] = useState<File | null>(null);

    // not in specific components, to avoir validation on each input change
    const [showValidationErrorsModal, setShowValidationErrorsModal] = useState<string[] | null>(null);
    const [ result, setResult ] = useState<IAddInvoiceResponse | null>(null);
    const [displayExistingInvoice, setDisplayExistingInvoice] = useState<string | null>(null);
    
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const addAndRequestFinancingMutation: UseMutationResult<IInvoiceAddResponseO, any, FormData> =
        useMutation<IInvoiceAddResponseO, any, FormData /* TODO : weird. Remove */>(
            async (params2) => await addInvoiceAsync<FormData>(
                axios,
                `/adminInvoices/?requestFinancing=${!cannotFinanceReason}`,
                params2,
                {
                    timeout: FileUploadTimeoutMilliseconds
                }
            ),
            ({
                onSuccess: (result: IInvoiceAddResponseO) => {
                    const successO = result.nonDuplicateO;
                    if (!!successO) {
                        setResult(successO);
                        if (!successO.notEligibleReason) {
                            return queryClient.invalidateQueries(GlobalOutstandingsQueryKey);
                        }
                    } else {
                        setDisplayExistingInvoice(result.existingInvoiceIdO!);
                    }
                    // TODO: update queries
                    // return Promise.resolve();
                }
            })
        );

    const clearForm = () => {
        // mostly reused
        // setBuyerSearchInput('');
        // setBuyerCompany(null);
        // setBuyerEmailInput('');
        // setBuyerPhoneInput('');

        setNumberInput('');
        setIssueDateInput('');
        setDueDateInput('');
        setAmountWoTaxInput('');
        setAmountTaxInput('');
        //setCustomIbanInput(''); // mostly reused
        setDeliveryValidationDateInput('');

        setFile(null);
        // https://stackoverflow.com/questions/1703228/how-can-i-clear-an-html-file-input-with-javascript
        (document.getElementById(filePickerId) as HTMLInputElement).value = '';
    };

    const cannotFinanceReason: string | null = isBlocked
        ? 'seller bloqué'
        : !isCertified
            ? 'seller non certifié'
            : null;

    const buyerInfosInput: IInvoiceBuyerInfosInput = {
        companySelection: buyerSelection,
        contacts: buyerContactsInputs
    };

    const baseInfosInput: IInvoiceBaseInfosInput = {
        number: numberInput,
        issueDate: issueDateInput,
        dueDate: dueDateInput,
        amountWoTax: amountWoTaxInput,
        amountTax: amountTaxInput
    };

    const baseInfosInputEnabled: IInvoiceBaseInfosInputEnabled | null = {
        updateNumber: setNumberInput,
        updateIssueDate: setIssueDateInput,
        updateDueDate: setDueDateInput,
        updateAmountWoTax: setAmountWoTaxInput,
        updateAmountTax: setAmountTaxInput,
        autofocus: false
    };

    return (
        <div className='box p-padding-4' style={{width: 700}}>
            <span className='title p-both-center'>Ajouter une facture pour {sellerCompany.name}</span>

            { !!cannotFinanceReason
                ? <span className='p-both-center'>(sans demander le financement, raison : {cannotFinanceReason})</span>
                : <span className='p-both-center'>(et demander le financement)</span>
            }

            <SellerAddInvoiceBuyerInfos className='p-margin-top-4'
                                        axios={axios}
                                        buyer={null}
                                        buyers={buyers}
                                        input={buyerInfosInput}
                                        setBuyerSelection={setBuyerSelection}
                                        setBuyerContactsInputs={setBuyerContactsInputs}
                                        autofocus={true}
                                        companySearchRequest={(inputCompanySearch: string) => companySearchRequestAsync(
                                            axios,
                                            inputCompanySearch,
                                            getFactorContext().logger
                                        )}/>

            <InvoiceAddBaseInfos className='p-margin-top-4'
                                 input={baseInfosInput}
                                 enabled={baseInfosInputEnabled} />

            { !isDirectSeller &&
                <div>
                    <div className='p-padding-bottom-7'>Seller IBAN si différent (Optionnel)</div>
                    <Input inputValue={customIbanInput}
                           enabled={{
                               updateInputValue: setCustomIbanInput,
                               validateInput: () => validateIbanError(customIbanInput)
                           }} />
                </div>
            }

            { !isDirectSeller &&
                <div>
                    <div className='p-padding-bottom-7'>Date de validation de livraison</div>
                    <Input inputValue={deliveryValidationDateInput}
                           enabled={{
                               updateInputValue: setDeliveryValidationDateInput,
                               validateInput: () => validateDateInputWithinRange(deliveryValidationDateInput, null, new Date())
                           }} />
                </div>
            }

            <div className='p-both-center p-margin-top-4'>
                <input className='control'
                       id={filePickerId}
                       type='file'
                       onChange={(event) => {
                           const files = event.target.files;
                           if (!!files && files.length > 0) {
                               setFile(files[0]);
                           }
                       }}
                       accept='application/pdf, image/jpeg' />
            </div>

            <div className='p-vertical-center p-margin-top-4'>
                <Button id='addInvoiceButton'
                        text={computeText(AddText, addAndRequestFinancingMutation, true)}
                        isLoading={addAndRequestFinancingMutation.isLoading}
                        actionO={() => {
                            const addParams: ResultOrErrors<FormData> = getInvoiceAddParams(
                                sellerId,
                                isDirectSeller,
                                sellerCompany,
                                buyerSelection,
                                buyerContactsInputs.map((c) => c.email),
                                buyerContactsInputs.map((c) => c.phone).filter((p) => p.trim().length > 0),
                                numberInput,
                                issueDateInput,
                                dueDateInput,
                                amountWoTaxInput,
                                amountTaxInput,
                                customIbanInput,
                                deliveryValidationDateInput,
                                file
                            );

                            if(!!addParams.result) {
                                addAndRequestFinancingMutation.mutate(addParams.result);
                            } else {
                                setShowValidationErrorsModal(addParams.errors!);
                            }
                        }} />

                { showValidationErrorsModal &&
                    <ValidationErrorModal validationErrors={showValidationErrorsModal}
                                          close={() => setShowValidationErrorsModal(null)}/>
                }
                { displayExistingInvoice &&
                    <InvoiceAlreadyExistModal invoiceId={displayExistingInvoice}
                                              closeModal={() => setDisplayExistingInvoice(null)} />
                }
                { !!result &&
                    <ResponseModal canFinance={!cannotFinanceReason}
                                   result={result}
                                   closeModalNewInvoice={() => {
                                       setResult(null);
                                       clearForm();
                                   }}/>
                }
            </div>
        </div>
    );
}

export default SellerAddInvoiceLoaded;
