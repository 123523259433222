import { Axios } from "axios";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { buyerCompanyDetailsQueryKey, IBuyerEvent, IBuyerEventContact } from "../buyerCompanyDetailsQuery";
import { useMutation, UseMutationResult, useQueryClient}  from "@tanstack/react-query";
import { IBuyerEventParams } from "./BuyerEventModal";
import {
    buyerEventsInvoiceMatchesKey, IInvoiceDetailsQueryResult, invoiceDetailsQueryKey
} from "../../../Invoice/invoiceDetailsQuery";
import { buyerEventContactsQueryKey } from "./BuyerEventTypeAndContact";


export interface IAddBuyerEventParams extends IBuyerEventParams {
    buyerCompanyId: string;
}

interface IAddBuyerEventResponse {
    buyerEventId: string;
    contact: IBuyerEventContact | null;
}

const BuyerEventAddButtonEnabled = (
    {
        axios,
        params,
        selectedInvoicesIds,
        currentOpsId,
        closeModal,
        kind,
        size,
        text
    } : {
        axios: Axios;
        params: IAddBuyerEventParams;
        selectedInvoicesIds: string[];
        currentOpsId: string;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const addBuyerEventMutation: UseMutationResult<IAddBuyerEventResponse, any, IAddBuyerEventParams> =
        useMutation<IAddBuyerEventResponse, any, IAddBuyerEventParams>(
            async (params2) =>
                (await axios.post<IAddBuyerEventResponse>(
                    `/adminBuyerEvents`,
                    params2
                )).data,
            ({
                onSuccess: (response, params2) => {
                    const newBuyerEvent: IBuyerEvent = {
                        id: response.buyerEventId,
                        dimplOps: {
                            name: 'toi', // TODO?
                            azureObjectId: currentOpsId
                        },

                        type: params2.eventType,
                        eventDateTime: params2.eventDateTime,
                        notes: params2.eventNotes,

                        email: !!params2.emailId ? response.contact : null,
                        phone: !!params2.phoneId ? response.contact : null,
                    };

                    const buyerCompanyId = params2.buyerCompanyId;

                    // Too complicated to update at this point
                    const r = Promise.all([
                        queryClient.invalidateQueries(buyerCompanyDetailsQueryKey(buyerCompanyId)),
                        queryClient.invalidateQueries(buyerEventsInvoiceMatchesKey(buyerCompanyId)),
                        queryClient.invalidateQueries(buyerEventContactsQueryKey(buyerCompanyId))
                    ]);

                    selectedInvoicesIds.forEach((selectedInvoicesId) =>
                        queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                            invoiceDetailsQueryKey(selectedInvoicesId),
                            (old: IInvoiceDetailsQueryResult | undefined) =>
                                !!old
                                    ? ({
                                        ...old,
                                        buyerEvents: [
                                            ...old!.buyerEvents,
                                            newBuyerEvent
                                        ]
                                    }) : undefined
                        )
                    );
                    closeModal();

                    return r;
                },
            })
        );

    return (
        <ButtonMutationEnabled id='AddBuyerEventButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={addBuyerEventMutation}
                               displayFullError={true}
                               value={params} />
    );
}

export default BuyerEventAddButtonEnabled;
