import MultipleSelect from "factor-lib/forms/Select/MultipleSelect";
import IAuthUser from "../../../../IAuthUser";
import { IBuyerCompanyDetailsQueryResult, IBuyerOpsTags, buyerCompanyDetailsQueryKey } from "../buyerCompanyDetailsQuery";
import { Axios } from "axios";
import IOption from "factor-lib/forms/Select/IOption";
import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { KIND_PRIMARY, SIZE_COMPACT } from "factor-lib/Buttons/Button";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { arraysEqual } from "../../../../utils/arrayUtils";
import { useState } from "react";

const UpdateText = 'Modifier';

// maps to BuyerCompanyOpsTagOrderedEnum
const certifiedOption: IOption<number> = { label: 'Certifié', value: 1 };
const cycleCompleteOption: IOption<number> = { label: 'Cycle Complet', value: 2 };
const publicOption: IOption<number> = { label: 'Buyer Public ', value: 3 };
const notInsuredOption: IOption<number> = { label: 'Pas assuré', value: 4 };

const opsTagsOptions: Array<IOption<number>> = [
    certifiedOption,
    cycleCompleteOption,
    publicOption,
    notInsuredOption
];

const getInitialOpsTags = (opsTags: IBuyerOpsTags): Array<IOption<number>> => {
    const opsTagsOptions = new Array<IOption<number>>();

    const checkAddOpsTag = (
        tagPresent: boolean,
        tagOption: IOption<number>
    ): void => {
        if (tagPresent) {
            opsTagsOptions.push(tagOption);
        }
    };

    checkAddOpsTag(opsTags.certified, certifiedOption);
    checkAddOpsTag(opsTags.cycleComplete, cycleCompleteOption);
    checkAddOpsTag(opsTags.public, publicOption);
    checkAddOpsTag(opsTags.notInsured, notInsuredOption);

    return opsTagsOptions;
};

const getOpsTagsFromArray = (opsTagsSet: ReadonlyArray<IOption<number>>): IBuyerOpsTags => ({
    certified: opsTagsSet.includes(certifiedOption),
    cycleComplete: opsTagsSet.includes(cycleCompleteOption),
    public: opsTagsSet.includes(publicOption),
    notInsured: opsTagsSet.includes(notInsuredOption)
});

const UpdateOpsTagsEnabled = (
    {
        companyId,
        axios,
        opsTags,
        kind,
        size,
        text
    } : {
        companyId: string;
        axios: Axios;
        opsTags: ReadonlyArray<IOption<number>>;
        kind: string;
        size: string;
        text: string;
    }
) => {

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateOpsTagsMutation: UseMutationResult<void, any, Array<number>> =
        useMutation<void, any, Array<number>>(
            async (opsTagsValues) => {
                await axios.put(
                    `/adminBuyers/${companyId}/opsTags`,
                    {
                        opsTags: opsTagsValues
                    }
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<IBuyerCompanyDetailsQueryResult>(
                        buyerCompanyDetailsQueryKey(companyId),
                        (old: IBuyerCompanyDetailsQueryResult | undefined) => !!old ? ({
                            ...old,
                            companyDetails: {
                                ...old.companyDetails,
                                asBuyer: {
                                    ...old.companyDetails.asBuyer,
                                    opsTags: getOpsTagsFromArray(opsTags)
                                }
                            }
                        }) : undefined
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateBuyerOpsTags'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateOpsTagsMutation}
                               displayFullError={true}
                               value={opsTags.map((o) => o.value)} />
    );
}

const BuyerOpsTags = (
    {
        className,
        axios,
        authUser,
        companyId,
        opsTags
    }: {
        className?: string;
        axios: Axios;
        authUser: IAuthUser | null;
        companyId: string;
        opsTags: IBuyerOpsTags;
    }
) => {
    const initialOpsTags: Array<IOption<number>> = getInitialOpsTags(opsTags);

    const [ selectedOpsTags, setSelectedOpsTags ] = useState<ReadonlyArray<IOption<number>>>(initialOpsTags);

    return (
        <div className={className}>
            <div className='p-horizontal-center p-margin-bottom-5'>
                <span className='p-bold p-margin-right-5'>Ops Tags</span>

                { !!authUser?.canManageFinancings && (
                    !arraysEqual(initialOpsTags, selectedOpsTags, t => t.value)
                        ? <UpdateOpsTagsEnabled axios={axios}
                                                companyId={companyId}
                                                opsTags={selectedOpsTags}
                                                kind={KIND_PRIMARY}
                                                size={SIZE_COMPACT}
                                                text={UpdateText} />
                        : <ButtonDisabled kind={KIND_PRIMARY}
                                          size={SIZE_COMPACT}
                                          text={UpdateText} />

                ) }
            </div>

            <MultipleSelect options={opsTagsOptions}
                            selectedOptions={selectedOpsTags}
                            setSelectedOptions={setSelectedOpsTags} />
        </div>
    );
}

export default BuyerOpsTags;
