import {ReactElement} from "react";

import './billingtablelayout.scss'

const BillingsTableLayout = (
    {
        children
    }: {
        children: ReactElement;
    }
) =>
    <table className='table is-striped p-billing-table'>
        <thead>
            <tr>
                <th>Client</th>
                <th>Mois</th>
                <th>Prélèvement Stripe</th>
                <th>Montant</th>
                <th>Lien PennyLane</th>
            </tr>
        </thead>
        { children }
    </table>;

export default BillingsTableLayout;