import { ISwanIncomingPayment, ISwanIncomingPaymentsQueryResult, SwanIncomingPaymentsQueryKey } from "./swanIncomingPaymentsQuery";
import { swanRedirect } from "../../utils/swanUtils";
import CurrentEnv from "../../envs/CurrentEnv";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import {
    ISwanIncomingPaymentDetailsQueryResult,
    swanIncomingPaymentDetailsQueryKey
} from "../SwanIncomingPaymentDetails/swanIncomingPaymentDetailsQuery";
import { useQueryClient } from "@tanstack/react-query";
import ButtonWithConfirmationModal from "factor-lib/Buttons/ButtonWithConfirmationModal";
import { getAmountRemaining } from "./swanUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";
import { truncateString } from "../../utils/stringUtils";


const SwanBatchNoMatchButton = (
    {
        className,
        swanIncomingPaymentsNoMatchFlagged
    }: {
        className?: string;
        swanIncomingPaymentsNoMatchFlagged: ISwanIncomingPayment[];
    }
) => {
    const queryClient = useQueryClient();

    const valid = swanIncomingPaymentsNoMatchFlagged.filter((s) => !!s.matchingCustomer);
    const noMatchingCustomer = swanIncomingPaymentsNoMatchFlagged.filter((s) => !s.matchingCustomer);

    return (
        <ButtonWithConfirmationModal className={className}
                                     text='No Match flagged'
                                     id='swanNoMatchFlaggedRedirectButton'
                                     confirmationBody={
                                         <div>
                                             <div>
                                                 Vous allez faire un No Match pour les paiements suivants :
                                                 <ul>
                                                     { valid
                                                         .map((s) =>
                                                             <li key={s.id}>
                                                                 - { truncateString(s.id, 16) } 
                                                                 &nbsp;- {s.matchingCustomer!.company.name.toUpperCase()} 
                                                                 &nbsp;- {formatAmount(getAmountRemaining(s))}
                                                             </li>
                                                         )
                                                     }
                                                 </ul>
                                             </div>

                                             { noMatchingCustomer.length > 0 &&
                                                 <div className='p-margin-top-4'>
                                                     Les paiements suivants n'ont pas une plateforme identifiée et seront ignorés :
                                                     <ul>
                                                         { noMatchingCustomer
                                                             .map((s) =>
                                                                 <li key={s.id}>
                                                                     - { truncateString(s.id, 16) } 
                                                                     &nbsp;- {formatAmount(getAmountRemaining(s))}
                                                                 </li>
                                                             )
                                                         }
                                                     </ul>
                                                 </div>
                                             }
                                         </div>
                                     }
                                     action={() => {
                                         swanRedirect(
                                             CurrentEnv.baseUrl,
                                             CurrentEnv.swanClientId,
                                             (axios, swanAuthorizationCode) => axios.post<string>(
                                                 `adminSwanIncomingPayments/init-batch-no-match`,
                                                 {
                                                     payments: valid
                                                         .map((s) => ({
                                                             swanIncomingPaymentId: s.id,
                                                             amount: getAmountRemaining(s)
                                                         })),
                                                     swanAuthorizationCode: swanAuthorizationCode,
                                                     ensureFlaggedNoMatch: true,
                                                 }
                                             ),
                                             (axios, consentId) => axios.put<void>(
                                                 `adminSwanIncomingPayments/${consentId}/validate-no-match`
                                             ),
                                             () => {
                                                 valid.forEach((s) => {
                                                     queryClient.setQueryData<ISwanIncomingPaymentDetailsQueryResult>(
                                                         swanIncomingPaymentDetailsQueryKey(s.id),
                                                         (old) => !!old ? ({
                                                             ...old,
                                                             swanIncomingPaymentDetails: {
                                                                 ...old.swanIncomingPaymentDetails,
                                                                 noMatchDateTime: serverDateSerialization(new Date())
                                                             }
                                                         }) : undefined
                                                     );
                                                 });
                                         
                                                 const swanIncomingPaymentIds = new Set<string>(valid.map((s) => s.id));
                                                 queryClient.setQueryData<ISwanIncomingPaymentsQueryResult>(
                                                     SwanIncomingPaymentsQueryKey,
                                                     (old) => !!old ? ({
                                                         ...old,
                                                         swanIncomingPayments: old.swanIncomingPayments
                                                             .map((s) =>
                                                                 swanIncomingPaymentIds.has(s.id)
                                                                     ? {
                                                                         ...s,
                                                                         noMatchDateTime: serverDateSerialization(new Date())
                                                                     }
                                                                     : s
                                                             )
                                                     }) : undefined
                                                 );
                                             },
                                             (axios, consentId) => axios.put<void>(
                                                 `adminSwanIncomingPayments/${consentId}/reject-no-match`
                                             )
                                         );
                                     }} />
    );
}

export default SwanBatchNoMatchButton;
