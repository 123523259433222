import IAccessToken from "../../IAccessToken";
import IAuthUserLoad from "../../IAuthUserLoad";
import { computeFinancingStatus } from "../../Pages/Invoices/invoiceStatus";
import { Link } from "react-router-dom";
import IOption from "factor-lib/forms/Select/IOption";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import GraphQLQueryWithAccessTokenWrapper from "../../GraphQLQueryWithAccessTokenWrapper";
import { filterStrInvoice } from "../InvoiceUtils";
import { invoiceUrl } from "../../Pages/Invoices/invoicesUrls";
import {sellerUrl} from "../../Pages/Sellers/sellersUrls";
import {buyerUrl} from "../../Pages/Buyers/buyersUrls";
import Loader from "factor-lib/Loader";
import {comparingRev } from "factor-lib/utils/comparingUtils";
import {addDays, serverDateDeserialization, comparingDate,  comparingTwoDates } from "factor-lib/utils/dateUtils";
import {formatCompany} from "../companyUtils";
import {formatAdminDateStr} from "../dateTimeUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";
import {
    buildBuyerCompaniesMap,
    buildSellersMap,
    IBaseInvoiceSellerWithCompany,
    IInvoiceBuyerCompany
} from "../IInvoice";
import { IFinancing, IFinancingsQueryResult, getActiveFinancingsGraphQLQuery } from "./financingsQuery";


const compareFinancingDate = comparingRev(
    comparingDate((financing: IFinancing) => serverDateDeserialization(financing.creationDateTime))
);

const FinancingRow = (
    {
        financing,
        seller,
        buyerCompany
    }: {
        financing: IFinancing;
        seller: IBaseInvoiceSellerWithCompany;
        buyerCompany: IInvoiceBuyerCompany;
    }
) => {
    const { invoice } = financing;
    return (
        <tr>
            <td>
                <Link to={invoiceUrl(invoice.id)}>{ invoice.number }</Link>
            </td>
            <td>
                { computeFinancingStatus(!!invoice.payment.completePaidDate, financing) }
            </td>
            <td>
                <Link to={sellerUrl(seller.id)}>
                    { formatCompany(seller.company) }
                </Link>
            </td>
            <td>
                <Link to={buyerUrl(buyerCompany.id, false)}>
                    { formatCompany(buyerCompany) }
                </Link>
            </td>
            <td>
                { formatAdminDateStr(invoice.dueDate) }
            </td>
            <td>
                { formatAmount(invoice.amountWoTax + invoice.amountTax) }
            </td>
        </tr>
    );
}

const FinancingsTableLoaded = (
    {
        financings,
        sellers,
        buyerCompanies,
        filter,
        searchStrFilterO
    }: {
        financings: IFinancing[];
        sellers: IBaseInvoiceSellerWithCompany[];
        buyerCompanies: IInvoiceBuyerCompany[];
        filter: ((financing: IFinancing) => boolean) | null;
        searchStrFilterO: string | null;
    }
) => {
    const sellersById: Map<string, IBaseInvoiceSellerWithCompany> = buildSellersMap(sellers);
    const buyerCompaniesById: Map<string, IInvoiceBuyerCompany> = buildBuyerCompaniesMap(buyerCompanies);

    let filteredFinancings = financings;
    if (!!filter) {
        filteredFinancings = filteredFinancings.filter(filter);
    }

    let filteredFinancingsWithSellerAndBuyer = filteredFinancings
        .map((financing: IFinancing) => ({
            financing,
            seller: sellersById.get(financing.invoice.sellerId)!,
            buyerCompany: buyerCompaniesById.get(financing.invoice.buyer.companyId)!
        }));

    if (!!searchStrFilterO) {
        const searchStrFilterLower = searchStrFilterO.toLowerCase();
        filteredFinancingsWithSellerAndBuyer = filteredFinancingsWithSellerAndBuyer
            .filter((f) => filterStrInvoice(f.financing.invoice.number, f.seller, f.buyerCompany, searchStrFilterLower))

    }

    return (
        <tbody>
            { filteredFinancingsWithSellerAndBuyer
                .sort((f, g) => compareFinancingDate(f.financing, g.financing))
                .map((f, index) =>
                    <FinancingRow key={`financing-${index}`}
                                  financing={f.financing}
                                  seller={f.seller}
                                  buyerCompany={f.buyerCompany} />
                )
            }
       </tbody>
    );
}

export const financingsFilterOptions: Array<IOption<(financing: IFinancing) => boolean>> = [
    {
        label: 'Hard collection',
        value: (financing) => comparingTwoDates(addDays(serverDateDeserialization(financing.invoice.dueDate), 60), new Date()) <= 0
                              && comparingTwoDates(addDays(serverDateDeserialization(financing.invoice.dueDate), 120), new Date()) > 0
    },
    {
        label: 'Defaut',
        value: (financing) => comparingTwoDates(addDays(serverDateDeserialization(financing.invoice.dueDate), 120), new Date()) <= 0
    }
];

export interface IActiveFinancingsQueryParams {
    sellerId: string | null;
    buyerCompanyId: string | null;
}

const FinancingsTable = (
    {
        className,
        accessTokenO,
        queryKey,
        queryParam,

        selectedFilter,
        setSelectedFilter,
        searchStrFilterO
    }: {
        className?: string;
        accessTokenO: IAccessToken | null;
        queryKey: string[];
        queryParam: IActiveFinancingsQueryParams;

        selectedFilter: IOption<(financing: IFinancing) => boolean> | null;
        setSelectedFilter: (newOption: IOption<(financing: IFinancing) => boolean> | null) => void;
        searchStrFilterO: string | null;
    }
) =>
    <div className={className}>
        <div className='p-margin-bottom-5 column is-one-quarter'>
            <SingleSelect options={financingsFilterOptions}
                          selectedOption={selectedFilter}
                          selectOption={setSelectedFilter}
                          placeholder='Filtrer'
                          clearable={true} />
        </div>
        <table className='table is-striped is-bordered is-hoverable is-fullwidth'>
            <thead>
                <tr>
                    <th>N° de facture</th>
                    <th>Status</th>
                    <th>Seller</th>
                    <th>Buyer</th>
                    <th>Date d'échéance</th>
                    <th>Montant TTC</th>
                </tr>
            </thead>
            <GraphQLQueryWithAccessTokenWrapper accessTokenO={accessTokenO}
                                                queryKey={queryKey}
                                                queryParams={{query: getActiveFinancingsGraphQLQuery(queryParam.sellerId, queryParam.buyerCompanyId) }}
                                                onLoading={() =>
                                                    <tbody>
                                                        <tr>
                                                            <td/>
                                                            <td/>
                                                            <td/>
                                                            <td><Loader /></td>
                                                            <td/>
                                                            <td/>
                                                        </tr>
                                                    </tbody>
                                                }
                                                onSuccess={(r: IAuthUserLoad<IFinancingsQueryResult>) =>
                                                    <FinancingsTableLoaded financings={r.value.financings.base}
                                                                           sellers={r.value.financings.sellers}
                                                                           buyerCompanies={r.value.financings.buyerCompanies}
                                                                           filter={selectedFilter?.value ?? null}
                                                                           searchStrFilterO={searchStrFilterO} />

                                                } />
        </table>
    </div>;

export default FinancingsTable;
