import IDimplScore, {ValidDimplScore} from "./DimplScore";
import {formatAdminDate} from "../dateTimeUtils";
import dateFromUtcSeconds from "./dateFromUtcSeconds";

const DimplScoreInfosLoaded = (
    {
        className,
        dimplScore
    }: {
        className?: string;
        dimplScore: ValidDimplScore;
    }
) =>
    <table className={`table is-narrow${!!className ? ` ${className}` : ''}`}>
        <thead>
        <tr>
            <th>Dimpl Score</th>
            <th>Score brut</th>
            <th>Capital</th>
            <th>Date d'observation</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>{ dimplScore.score }</td>
            <td>{ dimplScore.rawScore }</td>
            <td>{ dimplScore.bucket }</td>
            <td>{ formatAdminDate(dateFromUtcSeconds(dimplScore.dateSeconds)) }</td>
        </tr>
        </tbody>
    </table>;

const DimplScoreResult = (
    {
        className,
        dimplScore
    }: {
        className?: string;
        dimplScore: IDimplScore | null;
    }
) =>
    !dimplScore
        ? <div className={className}>Pas de Dimpl Score</div>
        : !!dimplScore.valid
            ? <DimplScoreInfosLoaded className={className}
                                     dimplScore={dimplScore.valid} />
            : <div className={className}>Erreur Dimpl Score Code : {dimplScore.errorCode}</div>;

export default DimplScoreResult;