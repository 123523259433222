import IRouteDefinitionWithAxiosAndDataLoaderAndRolesO from "./IRouteDefinitionWithAxiosAndDataLoaderAndRolesO";
import {AddBuyerUrl, BuyersUrl} from "./buyersUrls";
import IAccessToken from "../../IAccessToken";
import AddBuyer from "./AddBuyer";
import BuyerRoute from "./BuyerRoute";
import BuyersPage from "./BuyersPage";
import IMainRouteDesc from "../../routerBuilder/IMainRouteDesc";
import IAccessTokenWithDataLoader from "./IAccessTokenWithDataLoader";
import {RouteObject, useOutletContext} from "react-router-dom";
import {ReactElement} from "react";
import WithTitleSetter from "../../routerBuilder/WithTitleSetter";
import {WithTitleSetterAndAccessTokenContext} from "../../routerBuilder/WithTitleSetterAndAccessTokenOContext";
import IBuersRoute from "./IBuyersRoute";

const buyerRoutes: IBuersRoute[] = [
    {
        path: AddBuyerUrl,
        titleO: 'Buyer-Add',
        component: (accessTokenWithDataLoaderO: IAccessTokenWithDataLoader | null) =>
            <AddBuyer accessTokenO={accessTokenWithDataLoaderO} />
    },
    BuyerRoute,
    {
        path: BuyersUrl,
        component: () =>
            <span className='p-both-center p-padding-4 p-bold'>
                Veuillez d'abord selectionner un Buyer
            </span>
    }
];

const WithAccessTokenAndDataloaderOContext = (
    {
        child
    }: {
        child: (accessTokenWithDataLoaderO: IAccessTokenWithDataLoader | null) => ReactElement;
    }
) => {
    const {accessTokenWithDataLoaderO} = useOutletContext<{accessTokenWithDataLoaderO: IAccessTokenWithDataLoader | null}>();
    return child(accessTokenWithDataLoaderO);
}

export const WithTitleOSetterAndAccessTokenWithDataloaderOContext = (
    {
        titleO,
        child
    }: {
        titleO?: string;
        child: (accessTokenWithDataLoaderO: IAccessTokenWithDataLoader | null) => ReactElement;
    }
) =>
    !!titleO
        ? <WithTitleSetter title={titleO}>
            <WithAccessTokenAndDataloaderOContext child={child}/>
        </WithTitleSetter> :
        <WithAccessTokenAndDataloaderOContext child={child}/>;

const buildRouteDefinitionWithAxiosAndRolesORec = (routeDefinitionWithAxiosAndRolesO: IRouteDefinitionWithAxiosAndDataLoaderAndRolesO): RouteObject =>
    ({
        path: routeDefinitionWithAxiosAndRolesO.path,
        element: <WithTitleOSetterAndAccessTokenWithDataloaderOContext titleO={routeDefinitionWithAxiosAndRolesO.titleO}
                                                                       child={routeDefinitionWithAxiosAndRolesO.component}/>,
        children: !!routeDefinitionWithAxiosAndRolesO.children ? buildRouteDefinitionsWithAxiosAndRolesORec(routeDefinitionWithAxiosAndRolesO.children) : undefined
    });

const buildRouteDefinitionsWithAxiosAndRolesORec = (routeDefinitionWithAxiosAndRolesO: IRouteDefinitionWithAxiosAndDataLoaderAndRolesO[]): RouteObject[] =>
    routeDefinitionWithAxiosAndRolesO
        .map(buildRouteDefinitionWithAxiosAndRolesORec);

const buyerRoute: IMainRouteDesc = ({
    label: 'Buyers',
    route: {
        path: BuyersUrl,
        element: <WithTitleSetterAndAccessTokenContext title='Buyers' // A 'default', which can be overriden by children
                                                       child={(accessTokenO: IAccessToken | null) =>
                                                           <BuyersPage accessTokenO={accessTokenO}/>
                                                       }/>,
        children: buildRouteDefinitionsWithAxiosAndRolesORec(
            buyerRoutes.map((b) => ({
                ...b,
                children: !!b.children
                    ? b.children.map((c) => ({
                        ...c,
                        component: () => c.component
                    }))
                    : undefined
            }))
        )
    }
});

export default buyerRoute;