import { useState } from "react";
import IAccessToken from "../../IAccessToken";
import BuyerLimitsModal from "./BuyerLimitsModal";
import Button from "factor-lib/Buttons/Button";
import { Axios } from "axios";
import {IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import BuyerBatchLimits from "./BuyerBatchLimits";
import BuyerLimitsDisplay from "./BuyerLimitsDisplay";

export interface IExistingBuyer {
    id: string;
    name: string;
    identifier: IIntCompanyIdentifier;
    existingLimit: number | null;
}

interface IDisplaySingleBuyerModal {
    existingBuyerO: IExistingBuyer | null;
}

export interface IExistingBuyer {
    id: string;
    name: string;
    identifier: IIntCompanyIdentifier;
    existingLimit: number | null;
}

const BuyerLimitsPage = (
    {
        accessTokenO,
    }: {
        accessTokenO : IAccessToken | null;
    }
) => {
    const [displayUpdateSingleModal, setDisplayUpdateSingleModal] = useState<IDisplaySingleBuyerModal | null>(null);
    // const [displayUploadModal, setDisplayUploadModal] = useState(false);

    const axiosO: Axios | null = (accessTokenO?.axios) || null;

    return (
        <div>
            <div className='container'>
                <div className='level'>
                    <div className='level-left'>
                        <div className='level-item'>
                            <Button id='addBuyerLimit'
                                    text='Ajouter une limite'
                                    isLoading={false}
                                    actionO={() => setDisplayUpdateSingleModal({ existingBuyerO: null })} />


                        </div>
                        <BuyerBatchLimits className='level-item'
                                          axiosO={axiosO}/>
                    </div>
                </div>
                <BuyerLimitsDisplay axiosO={axiosO}
                                    setDisplayUpdateSingleModal={(buyer: IExistingBuyer) =>
                                        setDisplayUpdateSingleModal({ existingBuyerO: buyer })
                                    }/>

                { !!displayUpdateSingleModal &&
                    <BuyerLimitsModal axios={axiosO! /* We've been able to load */}
                                      initialBuyerCompany={displayUpdateSingleModal.existingBuyerO}
                                      closeModal={() => setDisplayUpdateSingleModal(null)} />
                }
            </div>
        </div>
    );
}

export default BuyerLimitsPage;
