import { INewInvoiceMatch, IInvoiceMatchAmount } from "./invoiceMatch";
import SwanIncomingPaymentDetailsInvoiceRow from "./SwanIncomingPaymentDetailsInvoiceRow";
import {
    ISwanIncomingPaymentDetailsCandidateInvoice,
    ISwanIncomingPaymentDetailsInvoiceCompany,
    ISwanIncomingPaymentDetailsInvoiceSeller
} from "../swanIncomingPaymentDetailsQuery";
import { comparingRev } from "factor-lib/utils/comparingUtils";
import { comparingDate, serverDateDeserialization } from "factor-lib/utils/dateUtils";

const compareCandidateInvoice = comparingRev(
    comparingDate((invoice: ISwanIncomingPaymentDetailsCandidateInvoice) => serverDateDeserialization(invoice.dueDate))
);

const SwanIncomingPaymentDetailsCandidateInvoicesRows = (
    {
        searchInput,
        invoices,
        invoicesBuyerCompanies,
        invoicesSellers,
        matchEnabled
    }: {
        searchInput: string;
        invoices: ISwanIncomingPaymentDetailsCandidateInvoice[];
        invoicesBuyerCompanies: ISwanIncomingPaymentDetailsInvoiceCompany[];
        invoicesSellers: ISwanIncomingPaymentDetailsInvoiceSeller[];
        matchEnabled: {
            customerId: string;
            newInvoiceMatches: INewInvoiceMatch[];
            setNewInvoiceMatches: (newValue: INewInvoiceMatch[]) => void;
            newPaymentAmountRemaining: number | null;
        } | null;
    }
) => {
    const newInvoiceMatchesMap = !!matchEnabled ? new Map(matchEnabled.newInvoiceMatches.map((m) => [m.invoiceId, m])) : null;
    const buyerCompaniesMap = new Map(invoicesBuyerCompanies.map((b) => [b.id, b]));
    const sellersMap = new Map(invoicesSellers.map((b) => [b.id, b]));

    const searchInputLowerCase = searchInput.toLowerCase();

    const filterInvoice = (invoice: ISwanIncomingPaymentDetailsCandidateInvoice) =>
        invoice.number.toLocaleLowerCase().includes(searchInputLowerCase) ||
        buyerCompaniesMap.get(invoice.buyer.companyId)!.name.toLocaleLowerCase().includes(searchInputLowerCase);

    return (
        <>
            { invoices
                .filter(filterInvoice)
                .sort(compareCandidateInvoice)
                .map((invoice) => {
                    const invoiceId = invoice.id;
                    const newInvoiceMatch = !!newInvoiceMatchesMap ? (newInvoiceMatchesMap.get(invoiceId) ?? null) : null;
                    const seller = sellersMap.get(invoice.sellerId)!
                    return (
                        <SwanIncomingPaymentDetailsInvoiceRow key={invoiceId}
                                                              invoice={invoice}
                                                              invoicePayment={{
                                                                  completePaidDate: null,
                                                                  partialAmountPaid: invoice.payment.partialAmountPaid
                                                              }}
                                                              matchAmount={newInvoiceMatch?.matchAmount ?? null}
                                                              matchEnabled={!!matchEnabled ? {
                                                                  updateMatchAmount: (newMatchAmount: IInvoiceMatchAmount | null) => {
                                                                      const newInvoiceMatches = matchEnabled.newInvoiceMatches.filter((i) => i.invoiceId !== invoiceId);
                                                                      if (!!newMatchAmount) {
                                                                          newInvoiceMatches.push({
                                                                              invoiceId: invoiceId,
                                                                              isFinanced: !!invoice.status.customerFinancingRequest?.accepted?.financing,
                                                                              matchAmount: newMatchAmount
                                                                          });
                                                                      }
                                                                      matchEnabled.setNewInvoiceMatches(newInvoiceMatches);
                                                                  },
                                                                  newPaymentAmountRemaining: matchEnabled.newPaymentAmountRemaining
                                                              } : null}
                                                              buyerCompany={buyerCompaniesMap.get(invoice.buyer.companyId)!}
                                                              paymentToMarketplaceSeller={!!seller.customer.directSeller ? null : ({
                                                                  id: invoice.sellerId,
                                                                  companyName: seller.company.name
                                                              })}/>

                    );
                })
            }
        </>
    );
}

export default SwanIncomingPaymentDetailsCandidateInvoicesRows;
