import { Axios } from "axios";
import Loader from "factor-lib/Loader";
import { Link } from "react-router-dom";
import { KIND_PRIMARY, SIZE_COMPACT } from "factor-lib/Buttons/Button";
import { buyerCompanyDetailsQueryKey, IBuyer, IBuyerCompanyDetailsQueryResult } from "../buyerCompanyDetailsQuery";
import {QueryClient, useMutation, UseMutationResult, useQuery, useQueryClient} from "@tanstack/react-query";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import IFailable from "factor-lib/IFailable";
import { sellerUrl } from "../../../Sellers/sellersUrls";
import { comparingString } from "factor-lib/utils/comparingUtils";
import { formatAdminDateStr } from "../../../../utils/dateTimeUtils";
import {formatAmount} from 'factor-lib/utils/amountUtils';
import DataLoader from "dataloader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import {buyerCompanyIdGraphQLVariableName} from "../buyerCompanyIdGraphQLVariableName";

interface DefaultOutstandingLimitTypeWrapper {
    defaultOutstandingLimit: IFailable<number | null> | null;
}


const BuyerDefaultOutstandingLimitLoader = (
    {
        dsGraphQLDataLoader,
        buyerCompanyId
    }: {
        dsGraphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        buyerCompanyId: string;
    }
) => {

    const queryKey = ['company', buyerCompanyId, 'as-buyer-default-outstanding-limit'];

    const queryResult = useQuery<DefaultOutstandingLimitTypeWrapper>(
        queryKey,
        async ({ signal }) => {
            const query: IGraphQLParams = {
                query: `
                    query Q($${buyerCompanyIdGraphQLVariableName}: Guid!) {
                        companyDetails (id: $${buyerCompanyIdGraphQLVariableName}) {
                            asBuyer {
                                defaultOutstandingLimit {
                                    successO errorO
                                }
                            }
                        }
                    }
                `,
                variables: {
                    [buyerCompanyIdGraphQLVariableName]: buyerCompanyId
                }
            };
            return (await dsGraphQLDataLoader.load({
                query,
                queryKey,
                signal
            })).companyDetails.asBuyer as DefaultOutstandingLimitTypeWrapper;
        }
    );

    return (
        // in a separate query because it relies on dimpl score that might be slow to fetch

        <ReactQueryResultWrapper result={queryResult}
                                 displayFullError={true}
                                 onLoading={() => <Loader />}
                                 onSuccess={(r: DefaultOutstandingLimitTypeWrapper) => {
                                     const buyerDefaultOutstandingLimit = r.defaultOutstandingLimit;
                                     return (
                                         buyerDefaultOutstandingLimit === null
                                             ? <span>pas de limite discrétionnaire (company étrangère)</span>
                                             : buyerDefaultOutstandingLimit.errorO !== null
                                                 ? <span>erreur lors de la récupération de la limite discrétionnaire : {buyerDefaultOutstandingLimit.errorO}</span>
                                                 : buyerDefaultOutstandingLimit.successO === null
                                                     ? <span>pas de limite discrétionnaire</span>
                                                     : <span>{formatAmount(buyerDefaultOutstandingLimit.successO)} (discrétionnaire)</span>
                                     );
                                 }} />
    );
};

const BuyerAskedOutstandingRow = (
    {
        buyer
    }: {
        buyer: IBuyer;
    }
) => {
    const buyerAskedOutstanding: number | null = buyer.askedOutstanding;

    return (
        <tr>
            <td>
                { buyer.seller.company.name }
                &nbsp;[
                <Link id={`buyer-contact-${buyer.id}-seller-link`}
                      to={sellerUrl(buyer.seller.id)}>
                    lien
                </Link>
                ]
            </td>
            <td>
                { formatAmount(buyer.outstanding) }
            </td>
            <td>
                { buyerAskedOutstanding != null &&
                    <span>
                        { `${formatAmount(buyerAskedOutstanding)} (${formatAdminDateStr(buyer.askedOutstandingDateTime!)})`})
                    </span>
                }
            </td>
        </tr>
    );
}

const BlockButtonEnabled = (
    {
        className,
        axios,
        buyerCompanyId,
        kind,
        size
    } : {
        className?: string;
        axios: Axios;
        buyerCompanyId: string;
        kind: string;
        size: string;
    }
) => {

    const queryClient: QueryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const blockMutation: UseMutationResult<void, any, null, null> =
        useMutation<void, any, null, null>(
            async () => {
                await axios.put<void>(
                    `/adminBuyers/${buyerCompanyId}/block`
                );
            },
            ({
                onSuccess: (/* flag: number */) => {
                    queryClient.setQueryData<IBuyerCompanyDetailsQueryResult>(
                        buyerCompanyDetailsQueryKey(buyerCompanyId),
                        (old: IBuyerCompanyDetailsQueryResult | undefined) => !!old ? ({
                            ...old,
                            companyDetails: {
                                ...old.companyDetails,
                                asBuyer: {
                                    ...old.companyDetails.asBuyer,
                                    isBlocked: true
                                }
                            }
                        }) : undefined
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='blockBuyer'
                               className={className}
                               text='Bloquer'
                               kind={kind}
                               size={size}
                               mutation={blockMutation}
                               displayFullError={true}
                               value={null} />

    );
}

const UnblockButtonEnabled = (
    {
        className,
        axios,
        buyerCompanyId,
        kind,
        size
    } : {
        className?: string;
        axios: Axios;
        buyerCompanyId: string;
        kind: string;
        size: string;
    }
) => {

    const queryClient: QueryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const blockMutation: UseMutationResult<void, any, null, null> =
        useMutation<void, any, null, null>(
            async () => {
                await axios.put<void>(
                    `/adminBuyers/${buyerCompanyId}/unblock`
                );
            },
            ({
                onSuccess: (/* flag: number */) => {
                    queryClient.setQueryData<IBuyerCompanyDetailsQueryResult>(
                        buyerCompanyDetailsQueryKey(buyerCompanyId),
                        (old: IBuyerCompanyDetailsQueryResult | undefined) => !!old ? ({
                            ...old,
                            companyDetails: {
                                ...old.companyDetails,
                                asBuyer: {
                                    ...old.companyDetails.asBuyer,
                                    isBlocked: false
                                }
                            }
                        }) : undefined
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='unblockBuyer'
                               className={className}
                               kind={kind}
                               size={size}
                               text='Débloquer'
                               mutation={blockMutation}
                               displayFullError={true}
                               value={null} />


    );
}


const BuyerEligibilitySection = (
    {
        axios,
        dsGraphQLDataLoader,
        buyerCompanyId,
        buyerOutstanding,
        buyerIsBlocked,
        buyerManualOutstandingLimit,
        buyers
    }: {
        axios: Axios;
        dsGraphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        buyerCompanyId: string;
        buyerOutstanding: number;
        buyerIsBlocked: boolean;
        buyerManualOutstandingLimit: number | null;
        buyers: IBuyer[];
    }
) =>
    <div>
        <table className='table is-narrow'>
            <tbody>
                <tr>
                    <td className='p-bold' style={{
                        verticalAlign: 'middle'
                    }}>
                        { buyerIsBlocked ? 'Bloqué' : 'Débloqué' }
                    </td>
                    <td>
                        { buyerIsBlocked
                            ? <UnblockButtonEnabled axios={axios}
                                                    buyerCompanyId={buyerCompanyId}
                                                    kind={KIND_PRIMARY}
                                                    size={SIZE_COMPACT} />
                            : <BlockButtonEnabled axios={axios}
                                                  buyerCompanyId={buyerCompanyId}
                                                  kind={KIND_PRIMARY}
                                                  size={SIZE_COMPACT} />
                        }
                    </td>
                </tr>
                <tr>
                    <td className='p-bold'>
                        Encours Global
                    </td>
                    <td>
                        { formatAmount(buyerOutstanding) }
                    </td>
                </tr>
                <tr>
                    <td className='p-bold'>
                        Limite Encours Global
                    </td>
                    <td>
                        { buyerManualOutstandingLimit !== null
                            ? <span>{formatAmount(buyerManualOutstandingLimit)} (manuelle)</span>
                            : <BuyerDefaultOutstandingLimitLoader dsGraphQLDataLoader={dsGraphQLDataLoader}
                                                                  buyerCompanyId={buyerCompanyId} />
                        }
                    </td>
                </tr>
            </tbody>
        </table>

        <table className='table is-narrow is-hoverable is-fullwidth p-margin-top-5'>
            <thead>
                <tr>
                    <th>
                        Seller
                    </th>
                    <th>
                        Encours
                    </th>
                    <th>
                        Encours demandé
                    </th>
                </tr>
            </thead>

            <tbody>
                { buyers
                    .sort(comparingString((buyer) => buyer.seller.company.name))
                    .map((buyer, index) =>
                        <BuyerAskedOutstandingRow key={`buyer-outstanding-${index}`}
                                                  buyer={buyer} />
                    )
                }
            </tbody>
        </table>
    </div>;

export default BuyerEligibilitySection;