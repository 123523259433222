import { IIntCompanyIdentifier, IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";

export const BaseInvoiceGraphQLFields = `
    id
    number
    dueDate
    amountWoTax
    amountTax

    sellerId
    buyer {
        companyId
    }
`;

export default interface IBaseInvoice {
    id: string;
    number: string;
    dueDate: string;
    amountWoTax: number;
    amountTax: number;

    sellerId: string;
    buyer: {
        companyId: string;
    };
}

export interface IBaseInvoiceSeller {
    id: string;
}

export const buildSellersMap = <T extends IBaseInvoiceSeller> (sellers: T[]): Map<string, T> => {
    const sellersById: Map<string, T> = new Map<string, T>();
    sellers.forEach((seller) => sellersById.set(seller.id, seller));
    return sellersById;
}

export interface IBaseCompany {
    name: string;
    identifier: IIntCompanyIdentifier;
}

export const InvoiceBuyerCompaniesGraphQLSubQuery =
    `buyerCompanies {
        id
        name
        identifier { ${IntCompanyIdentifierGraphQLFields} }
    }`;

export interface IInvoiceBuyerCompany extends IBaseCompany {
    id: string;
}

export const buildBuyerCompaniesMap = <T extends IInvoiceBuyerCompany> (buyerCompanies: T[]): Map<string, T> =>
    new Map<string, T>(buyerCompanies.map((buyerCompany) => [buyerCompany.id, buyerCompany]));

export const InvoiceBaseSellersGraphQLSubQuery =
    `
        id
        company {
            name
            identifier { ${IntCompanyIdentifierGraphQLFields} }
        }
    `;

export interface IBaseInvoiceSellerWithCompany extends IBaseInvoiceSeller {
    company: IBaseCompany;
}

export interface IBaseInvoicePayment extends IBaseInvoice {
    payment: {
        partialAmountPaid: number;
    }
}

export const remainingToPay = (i: IBaseInvoicePayment) =>
    i.amountTax + i.amountWoTax - i.payment.partialAmountPaid;