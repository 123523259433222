import {ReactElement} from "react";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import ReactQueryWrapper from "./ReactQueryWrapper";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import DataLoader from "dataloader";

// Version with a data loader
const GraphQLQueryWrapperDL = <T, >(
    {
        graphQLDataLoader,
        queryKey,
        queryParams,
        postGraphQLQueryExtractor,
        onLoading,
        onError,
        onSuccess
    }: {
        graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        queryKey: string[];
        queryParams: IGraphQLParams;
        postGraphQLQueryExtractor: (fullResult: any) => T;
        onLoading?: () => ReactElement;
        onError?: (e: string) => ReactElement; // TODO: make required?
        onSuccess: (t: T) =>  ReactElement;
    }
) =>
    <ReactQueryWrapper queryKey={queryKey}
                       queryFunc={async (signal?: AbortSignal): Promise<T> => postGraphQLQueryExtractor(await graphQLDataLoader.load({
                           query: queryParams,
                           queryKey,
                           signal
                       }))}
                       onLoading={onLoading}
                       onError={onError}
                       onSuccess={onSuccess} />;

export default GraphQLQueryWrapperDL;
