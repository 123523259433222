import {NavigateFunction} from "react-router-dom";
import {useContext, useState} from "react";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {BillingsQueryKey} from "../Billings/BillingQueries";
import {BillingsUrl} from "../billingUrls";
import IYearMonth from "../IYearMonth";
import {Axios} from "axios";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import InputAmount, {isValidAmount, parseInputAmount} from 'factor-lib/forms/Inputs/InputAmount';
import ButtonMutation from 'factor-lib/Buttons/ButtonMutation';
import {existingBillingQueryKey, IExistingBillingQueryResult} from "./PostSelectionQuery";

export interface ICreateManualRequest {
    customerId: string;
    yearMonth: IYearMonth;
    amount: number;
}

const NewManualBillingModalContent = (
    {
        axios,
        customerId,
        yearMonth
    }: {
        axios: Axios;
        customerId: string;
        yearMonth: IYearMonth;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    const queryClient = useQueryClient();

    const [amountInput, setAmountInput] = useState('');

    const mutation: UseMutationResult<void, any, ICreateManualRequest> =
        useMutation<void, any, ICreateManualRequest>(
            async (p) => (await axios.post(
                `/adminBillings/manual`,
                p
            )).data,
            ({
                onSuccess: () => {
                    const r = queryClient.invalidateQueries(BillingsQueryKey);
                    queryClient.setQueryData<IExistingBillingQueryResult>(
                        existingBillingQueryKey(customerId, yearMonth),
                        (old) => !!old ? ({
                            ...old,
                            existingBilling: {
                                ...old.existingBilling,
                                any: true
                            }
                        }) : undefined
                    );
                    navigate(BillingsUrl);
                    return r;
                }
            })
        );

    return (
        <div className='p-vertical-stretch p-padding-4'>
            <div className='p-margin-bottom-3'>
                <InputAmount inputAmountValue={amountInput}
                             placeHolder='Montant de la facture'
                             enabled={{
                                 updateInputValue: setAmountInput,
                                 innerId: {
                                     value: 'amountId',
                                     autofocus: true
                                 },
                                 tryComplete: isValidAmount(amountInput)
                                     ? (() => mutation.mutate({
                                         customerId,
                                         yearMonth,
                                         amount: parseInputAmount(amountInput)
                                     }))
                                     : undefined
                             }} />
            </div>
            <div className='p-vertical-center'>
                <ButtonMutation<void, ICreateManualRequest> text='Créer Facturation Manuelle'
                                                            mutation={mutation}
                                                            valueO={isValidAmount(amountInput) ? ({
                                                                value: ({
                                                                    customerId,
                                                                    yearMonth,
                                                                    amount: parseInputAmount(amountInput)
                                                                })
                                                            }) : null}
                                                            displayFullError={true} />
            </div>
        </div>
    );
}

export default NewManualBillingModalContent;