import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import Input from "factor-lib/forms/Inputs/Input";
import {isValidString, validateMandatoryFieldError } from "factor-lib/forms/Inputs/utils";
import { AddressInput, IAddressInput } from "../../utils/AddressInput";
import IAddress, { getAddressParams, getUpdateAddressParams, IUpdateAddressParams } from "../../utils/IAddress";
import { ResultOrErrors } from "../../utils/ResultOrErrors";
import CompanyIdentifierInput, { getIntCompanyIdentifierParams, getUpdateIntCompanyIdentifierParams } from "./CompanyIdentifierInput";

export interface ICompanyParams {
    name: string;
    identifier: IIntCompanyIdentifier;
    address: IAddress;
}

export const getCompanyParams = (
    identifierTypeInput: string | null,
    identifierValueInput: string,
    nameInput: string,
    addressInput: IAddressInput
): ResultOrErrors<ICompanyParams> => {

    const validationErrors: string[] = [];

    const identifier: ResultOrErrors<IIntCompanyIdentifier> = getIntCompanyIdentifierParams(
        identifierTypeInput,
        identifierValueInput
    );

    if (!!identifier.errors) {
        validationErrors.push(...identifier.errors);
    }

    if (!isValidString(nameInput)) {
        validationErrors.push(`La raison sociale est invalide`);
    }

    const address: ResultOrErrors<IAddress> | null = getAddressParams(
        addressInput.streetAddress,
        addressInput.city,
        addressInput.postCode,
        addressInput.countryCode
    );

    if (!address) {
        validationErrors.push(`L'adresse n'est pas complète`);
    } else if (!!address.errors) {
        validationErrors.push(...address.errors);
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    return ResultOrErrors.fromResult({
        identifier: identifier.result!,
        name: nameInput,
        address: address!.result!
    });
};

export interface IUpdateCompanyParams {
    // Null -> no update
    name: string | null;
    identifier: IIntCompanyIdentifier | null;
    address: IUpdateAddressParams | null;
}

export const getUpdateCompanyParams = (
    old: ICompanyParams,
    companyParamsFailable: ResultOrErrors<ICompanyParams>,
): ResultOrErrors<IUpdateCompanyParams> | null => {

    if (companyParamsFailable.errors) {
        return ResultOrErrors.fromError(companyParamsFailable.errors);
    }

    const companyParams = companyParamsFailable.result!;

    const nameUpdate: string | null = companyParams.name !== old.name ? companyParams.name : null;

    const identifierUpdate: IIntCompanyIdentifier | null =
        getUpdateIntCompanyIdentifierParams(old.identifier, companyParams.identifier);

    const addressUpdate: IUpdateAddressParams | null =
        getUpdateAddressParams(old.address, companyParams.address);

    return (
            nameUpdate !== null ||
            identifierUpdate !== null ||
            addressUpdate !== null
        ) ? ResultOrErrors.fromResult({
            name: nameUpdate,
            identifier: identifierUpdate,
            address: addressUpdate
        }) : null;
};

const CompanyInput = (
    {
        identifierType,
        setIdentifierType,
        identifierValueInput,
        setIdentifierValueInput,
        nameInput,
        setNameInput,
        addressInput,
        setAddressInput,
        autofocus
    }: {
        identifierType: string | null;
        setIdentifierType: (newType: string | null) => void;
        identifierValueInput: string;
        setIdentifierValueInput: (newValue: string) => void;
        nameInput: string;
        setNameInput: (newName: string) => void;
        addressInput: IAddressInput;
        setAddressInput: (newAddress: IAddressInput) => void;
        autofocus: boolean;
    }
) =>
    <div>
        <div className='p-bold p-size-5 p-both-center p-margin-bottom-5'>Infos de la société</div>
    
        <CompanyIdentifierInput identifierType={identifierType}
                                setIdentifierType={setIdentifierType}
                                identifierValueInput={identifierValueInput}
                                setIdentifierValueInput={setIdentifierValueInput}
                                autofocusInnerId={autofocus ? 'company-identifier' : null} />
    
        <div className='p-margin-bottom-4'>
            <span className='p-bold'>Raison sociale</span>
            <Input inputValue={nameInput}
                   enabled={{
                       updateInputValue: setNameInput,
                       validateInput: () => validateMandatoryFieldError(nameInput)
                   }} />
        </div>
    
        <hr />
    
        <div className='p-bold p-size-5 p-both-center p-margin-bottom-5'>Adresse</div>
    
        <AddressInput input={addressInput}
                      enabled={{
                          setAddressInput: setAddressInput,
                          autofocus: false
                      }} />
    </div>;

export default CompanyInput;
