import {Axios} from "axios";
import dimplScoreSingleCompanyQuery, {dimplScoreQueryKey, IDimplScoreQueryResult} from "./dimplScoreSingleCompanyQuery";
import DimplScoreResult from "./DimplScoreResult";
import graphQLQueryAsync from "factor-lib/serverUtils/graphQLQueryAsync";
import {getFactorContext} from "../../IFactorContext";
import ReactQueryWrapper from "../ReactQueryWrapper";

const DimplScoreLoader = (
    {
        axios,
        companyId,
        loadedClassName
    }: {
        axios: Axios;
        companyId: string;
        loadedClassName?: string;
    }
) => {
    const queryKey = dimplScoreQueryKey(companyId);
    return (
        // TODO : ideally, directly to Vincent API
        // In its own query as this is much slower
        <ReactQueryWrapper<IDimplScoreQueryResult> queryKey={queryKey}
                                                   queryFunc={async (signal?: AbortSignal): Promise<IDimplScoreQueryResult> => (await graphQLQueryAsync<{
                                                       companyDetails: IDimplScoreQueryResult;
                                                   }>(
                                                       axios,
                                                       dimplScoreSingleCompanyQuery('companyDetails', 'companyId', companyId),
                                                       getFactorContext().logger,
                                                       queryKey,
                                                       signal
                                                   )).companyDetails}
                                                   onSuccess={(r: IDimplScoreQueryResult) =>
                                                       <DimplScoreResult className={loadedClassName}
                                                                         dimplScore={r.dimplScore}/>
                                                   }/>
    );
};

export default DimplScoreLoader;
