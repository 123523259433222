import FinancingsTable, { IActiveFinancingsQueryParams } from "./FinancingsTable";
import { useState } from "react";
import IAccessToken from "../../IAccessToken";
import IOption from "factor-lib/forms/Select/IOption";
import { IFinancing } from "./financingsQuery";

const FinancingsTableStateFilter = (
    {
        accessTokenO,
        queryKey,
        queryParam
    }: {
        accessTokenO: IAccessToken | null;
        queryKey: string[];
        queryParam: IActiveFinancingsQueryParams;
    }
) => {
    const [ selectedFilter, setSelectedFilter ] = useState<IOption<(financing: IFinancing) => boolean> | null>(null);

    return (
        <FinancingsTable accessTokenO={accessTokenO}
                         queryKey={queryKey}
                         queryParam={queryParam}
                         selectedFilter={selectedFilter}
                         setSelectedFilter={setSelectedFilter}
                         searchStrFilterO={null} />
    );
}

export default FinancingsTableStateFilter;
