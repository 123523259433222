import {IBaseInvoiceSellerWithCompany, IInvoiceBuyerCompany} from "./IInvoice";

export const filterStrInvoice = (
    invoiceNumber: string,
    seller: IBaseInvoiceSellerWithCompany,
    buyerCompany: IInvoiceBuyerCompany,
    searchStrFilterLower: string
): boolean =>
    invoiceNumber.toLowerCase().includes(searchStrFilterLower) ||
    seller.company.name.toLowerCase().includes(searchStrFilterLower) ||
    buyerCompany.name.toLowerCase().includes(searchStrFilterLower);

export const InvoiceQueryKeyPrefix = ['invoice'];
export const InvoicesNotFinancingRequestedQueryKeyPrefix = [...InvoiceQueryKeyPrefix, 'list'];
