import { KIND_PRIMARY, SIZE_COMPACT } from "factor-lib/Buttons/Button";
import { Axios } from "axios";
import { IIdentityFiles, ISellerDetailsQueryResult, sellerDetailsQueryKey } from "../../sellerDetailsQuery";
import IUrlOnly, { UrlGraphQLField } from "factor-lib/forms/Upload/IUrlOnly";
import SingleGraphQLFileVisualizerWithUpload from "../../../../../utils/SingleGraphQLFileVisualizerWithUpload";
import IAuthUser from "../../../../../IAuthUser";
import { PDF_OR_JPEG_OR_PNG_SET } from "factor-lib/forms/Upload/uploadUtils";
import { FileUploadTimeoutMilliseconds } from "factor-lib/serverUtils/axiosConfigUtils";

const uploadIdentityFileAsync = async (
    axios: Axios,
    rectoOrVerso: string,
    sellerId: string,
    file: File,
    onUploadProgress: (progressEvent: ProgressEvent) => void
): Promise<void> => {
    const data = new FormData();
    data.append('identityFile', file);

    await
        axios.put<void>(
            `/adminSellerDocuments/${sellerId}/identityFiles/${rectoOrVerso}`,
            data,
            {
                onUploadProgress,
                timeout: FileUploadTimeoutMilliseconds
            }
        );

    window.alert(`identity file ${rectoOrVerso} successfully uploaded`)
};

const sellerIdentityFilesQueryKey = (sellerId: string) =>
    ['seller', sellerId, 'identityFiles'];

const sellerIdentityFileRectoQueryKey = (sellerId: string) =>
    [...sellerIdentityFilesQueryKey(sellerId), 'recto'];

const sellerIdentityFileVersoQueryKey = (sellerId: string) =>
    [...sellerIdentityFilesQueryKey(sellerId), 'verso'];

const IdentityFilesSection = (
    {
        axios,
        authUser,
        sellerId,
        identityFiles
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        sellerId: string;
        identityFiles: IIdentityFiles | null;
    }
) =>
    (
        <div className='level'>
            <SingleGraphQLFileVisualizerWithUpload className='level-left'
                                                   axios={axios}
                                                   title='Recto'
                                                   fetchId='fetchIdentityFileRecto'
                                                   fetchText='Fetch'
                                                   queryKey={sellerIdentityFileRectoQueryKey(sellerId)}
                                                   queryParams={{
                                                       query: `query Q($sellerId: Guid!) {
                                                           sellerDetails (id: $sellerId) {
                                                               identityFiles {
                                                                   recto { ${UrlGraphQLField} }
                                                               }
                                                           }
                                                       }`,
                                                       variables: {
                                                           sellerId
                                                       }
                                                   }}
                                                   visualizerId='viewIdentityFileRecto'
                                                   visualizerText='View'
                                                   buttonKind={KIND_PRIMARY}
                                                   buttonSize={SIZE_COMPACT}
                                                   existingFileName={identityFiles?.recto.fileName ?? null}
                                                   urlExtractor={(d: { sellerDetails: { identityFiles: { recto: IUrlOnly }; }; }) =>
                                                       d.sellerDetails.identityFiles.recto.url
                                                   }
                                                   upload={!!authUser?.canManageFinancings ? {
                                                       id: 'uploadIdentityFileRecto',
                                                       acceptedFileTypesSet: PDF_OR_JPEG_OR_PNG_SET,
                                                       uploadFileAsync: (file: File, onUploadProgress: (progressEvent: ProgressEvent) => void) =>
                                                           uploadIdentityFileAsync(axios, 'recto', sellerId, file, onUploadProgress),
                                                       updateQueries: (queryClient, file) =>
                                                           queryClient.setQueryData<ISellerDetailsQueryResult>(
                                                               sellerDetailsQueryKey(sellerId),
                                                               (old: ISellerDetailsQueryResult | undefined) => ({
                                                                   sellerDetails: {
                                                                       ...old!.sellerDetails,
                                                                       identityFiles: {
                                                                           recto: {
                                                                               fileName: file.name
                                                                           },
                                                                           verso: identityFiles?.verso ?? null,
                                                                       }
                                                                   }
                                                               })
                                                           )
                                                   } : null} />

            { !!identityFiles &&
                <SingleGraphQLFileVisualizerWithUpload className='level-item'
                                                       axios={axios}
                                                       title='Verso'
                                                       fetchId='fetchIdentityFileVerso'
                                                       fetchText='Fetch'
                                                       queryKey={sellerIdentityFileVersoQueryKey(sellerId)}
                                                       queryParams={{
                                                           query: `query Q($sellerId: Guid!) {
                                                               sellerDetails (id: $sellerId) {
                                                                   identityFiles {
                                                                       verso { ${UrlGraphQLField} }
                                                                   }
                                                               }
                                                           }`,
                                                           variables: {
                                                               sellerId
                                                           }
                                                       }}
                                                       visualizerId='viewIdentityFileVerso'
                                                       visualizerText='View'
                                                       buttonKind={KIND_PRIMARY}
                                                       buttonSize={SIZE_COMPACT}
                                                       existingFileName={identityFiles.verso?.fileName ?? null}
                                                       urlExtractor={(d: { sellerDetails: { identityFiles: { verso: IUrlOnly }; }; }) =>
                                                           d.sellerDetails.identityFiles.verso.url
                                                       }
                                                       upload={!!authUser?.canManageFinancings ? {
                                                           id: 'uploadIdentityFileVerso',
                                                           acceptedFileTypesSet: PDF_OR_JPEG_OR_PNG_SET,
                                                           uploadFileAsync: (file: File, onUploadProgress: (progressEvent: ProgressEvent) => void) =>
                                                               uploadIdentityFileAsync(axios, 'verso', sellerId, file, onUploadProgress),
                                                           updateQueries: (queryClient, file) =>
                                                               queryClient.setQueryData<ISellerDetailsQueryResult>(
                                                                   sellerDetailsQueryKey(sellerId),
                                                                   (old: ISellerDetailsQueryResult | undefined) => ({
                                                                       sellerDetails: {
                                                                           ...old!.sellerDetails,
                                                                           identityFiles: {
                                                                               ...identityFiles,
                                                                               verso: {
                                                                                   fileName: file.name
                                                                               },
                                                                           }
                                                                       }
                                                                   })
                                                               )
                                                       } : null} />
            }
        </div>
    );

export default IdentityFilesSection;
