import {useAccount, useMsal} from "@azure/msal-react";
import {Axios, AxiosResponse} from "axios";
import buildDefaultAuthAxios, { ensureOneAuthUser } from "factor-lib/serverUtils/buildDefaultAuthAxios";
import ILogger from "factor-lib/services/logger/ILogger";
import IBackend from "./envs/IBackend";
import {ReactElement, useEffect} from "react";
import {AccountInfo, InteractionStatus} from "@azure/msal-browser";
import {useOutletContext} from "react-router-dom";
import {SeverityLevel} from "@microsoft/applicationinsights-web";
import {getFactorContext} from "./IFactorContext";

export const WithAxiosOContext = (
    {
        child
    }: {
        child: (axiosO: Axios | null) => ReactElement;
    }
) => {
    const { axiosO } = useOutletContext<{axiosO: Axios | null}>();
    return child(axiosO);
}

const Auth2AxiosCreatorWrapperCompleted = (
    {
        backend,
        child
    }: {
        backend: IBackend;
        child: (axiosO: Axios | null) => ReactElement;
    }
) => {
    const { instance, accounts } = useMsal();
    const logger: ILogger = getFactorContext().logger;

    // Apparemment il faut ajouter le useAccount hook :
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#acquiring-an-access-token
    // pas dans l'exemple router
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/react-router-sample/src/utils/MsGraphApiCall.js
    // mais le 1er a l'air plus officiel
    const account: AccountInfo = useAccount(ensureOneAuthUser(instance, logger, accounts/* , clientId */))!;

    const newAdminAxios: Axios = buildDefaultAuthAxios(
        backend.baseUrl,
        logger,
        instance,
        account,
        [backend.scope],
        `${window.location.protocol}//${window.location.host}/blank-silent-login.html`,
        // CurrentEnv.azureADClientId,
        0 /* no timeout */
    );
    newAdminAxios.interceptors.response.use(
        (response: AxiosResponse) => (response), // We don't do anything
        (error: any) => {
            logger.trackTrace(SeverityLevel.Warning /* Not error : can be expected : cancel (not sure where it comes from ) */, "Error for admin when calling backend server", { serverBaseUrl : backend.baseUrl });
            return Promise.reject(error); // Keep the standard flow, components handle the error themselves.
        }
    )
    return child(newAdminAxios);
}

const Auth2AxiosCreatorWrapperInProgress = (
    {
        child
    }: {
        child: (axiosO: Axios | null) => ReactElement;
    }
) => {
    const logger: ILogger = getFactorContext().logger;
    useEffect(() => {
        logger.trackTrace(SeverityLevel.Information, "Logging in progress");
    })
    return child(null);
}

const Auth2AxiosCreatorWrapper = (
    {
        backend,
        child
    }: {
        backend: IBackend;
        child: (axiosO: Axios | null) => ReactElement;
    }
) => {
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#usemsal-hook
    const { inProgress } = useMsal();

    // useMsal will trigger 2 hooks : one with instance.initialized = false, another one initialized = true.
    // Don't create twice the axios and call the child twice with another similar axios, or we may hit PRED-2103
    return inProgress === InteractionStatus.None /* Check we're done. */
        ? <Auth2AxiosCreatorWrapperCompleted backend={backend}
                                             child={child} />
        : <Auth2AxiosCreatorWrapperInProgress child={child}/>;
};

export default Auth2AxiosCreatorWrapper;
