import { Axios} from "axios";
import { invoiceDetailsQueryKey } from "../../../invoiceDetailsQuery";
import { ButtonKind, ButtonSize } from "../CustomerFinancingRequestButtonProps";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { GlobalOutstandingsQueryKey } from "../../../../Misc/GlobalOutstandings/globalOutstandingsQuery";
import ButtonMutationWithConfirmationModal from "factor-lib/Buttons/ButtonMutationWithConfirmationModal";
import { ICustomerFinancingRequestsResponse, NewFinancingRequestsQueryKeyPrefix } from "../../../../../utils/FinancingRequest/FinancingRequestsQuery";
import { InvoicesNotFinancingRequestedQueryKeyPrefix } from "../../../../../utils/InvoiceUtils";

const DeleteCustomerFinancingRequestEnabled = (
    {
        className,
        axios,
        invoiceId,
        customerFinancingRequestId
    } : {
        className?: string;
        axios: Axios;
        invoiceId: string;
        customerFinancingRequestId: string;
    }
) => {

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const deleteCustomerFinancingRequestMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (customerFinancingRequestId2) => {
                await axios.delete<void>(
                    `/adminCustomerFinancingRequests/${customerFinancingRequestId2}`
                );
            },
            ({
                onSuccess: () => {

                    // remove from new financing requests (all, seller, buyer)
                    queryClient.setQueriesData<ICustomerFinancingRequestsResponse<null>>(
                        NewFinancingRequestsQueryKeyPrefix,
                        old => !!old ? {
                            ...old,
                            financingRequests: {
                                ...old.financingRequests,
                                base: old.financingRequests
                                    .base.filter((f) => f.id !== customerFinancingRequestId)
                            }
                        } : undefined
                    );

                    return Promise.all([
                        queryClient.invalidateQueries(invoiceDetailsQueryKey(invoiceId)), // eligibility should be refetched
                        queryClient.invalidateQueries(InvoicesNotFinancingRequestedQueryKeyPrefix), // depends on eligibility
                        queryClient.invalidateQueries(GlobalOutstandingsQueryKey)
                    ]);
                }
            })
        );

    return (
        <ButtonMutationWithConfirmationModal className={className}
                                             id='deleteCustomerFinancingRequestButtonWithConfirmation'
                                             kind={ButtonKind}
                                             size={ButtonSize}
                                             text='Supprimer la demande de financement'
                                             confirmationText='Etes vous sûr de vouloir supprimer la demande de financement ?'
                                             mutation={deleteCustomerFinancingRequestMutation}
                                             value={customerFinancingRequestId} />

    );
}

export default DeleteCustomerFinancingRequestEnabled;
