import CheckBox from "factor-lib/forms/CheckBox";
import {ReactNode} from "react";
import IInvoicesIneligibleLoaded from "./IInvoicesIneligibleLoaded";
import { formatAmount } from "factor-lib/utils/amountUtils";

const IneligibleInvoicesTemplate = (
    {
        className,
        loadedO,
        children
    }: {
        className?: string;
        loadedO: IInvoicesIneligibleLoaded | null;
        children: ReactNode;
    }
) =>
    <div className={className}>
        <div className='p-margin-left-4'>
            { `Montant sélectionné ${
                formatAmount(
                    (!!loadedO ? loadedO.selectedInvoices : []).reduce(
                        (s, i) => s + i.amountTax + i.amountWoTax,
                        0
                    )
                )}` }
        </div>
        <table className='table is-striped'>
            <thead>
                <tr>
                    <th>
                        <CheckBox inputValue={loadedO?.isAllSelected || false}
                                  internalId='selectPayment-all'
                                  updateInputValue={(loadedO?.selectAll) || null} />

                    </th>
                    <th>N° de facture</th>
                    <th>Seller</th>
                    <th>Buyer</th>
                    <th>Date d'échéance</th>
                    <th>Montant TTC</th>
                    <th>Raison d'inéligibilité</th>
                </tr>
            </thead>

            { children }
        </table>
    </div>;

export default IneligibleInvoicesTemplate;