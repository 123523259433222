import IAuthUser from "../../../../../IAuthUser";
import { CustomDocument } from "./MiscInfos/CustomDocument";
import OpsNotes from "./MiscInfos/OpsNotes";
import OtherDocumentsLink from "./MiscInfos/OtherDocumentsLink";
import {Axios} from "axios";

const SellerMiscInfosSection = (
    {
        axios,
        authUserO,
        sellerId,
        sellerOpsNotes,
        sellerOtherDocumentsLink,
        sellerCustomDocumentFileName
    }: {
        axios: Axios;
        authUserO: IAuthUser | null;
        sellerId: string;
        sellerOpsNotes: string | null;
        sellerOtherDocumentsLink: string | null;
        sellerCustomDocumentFileName: string | null;
    }
) =>
    <div>
        <CustomDocument axios={axios}
                        authUserO={authUserO}
                        sellerId={sellerId}
                        existingCustomDocumentFileName={sellerCustomDocumentFileName} />

        <div className='p-margin-top-4'>
            <OtherDocumentsLink axios={axios}
                                authUser={authUserO}
                                sellerId={sellerId}
                                sellerOtherDocumentsLink={sellerOtherDocumentsLink} />
        </div>

        <div className='p-margin-top-4'>
            <OpsNotes axios={axios}
                      authUserO={authUserO}
                      sellerId={sellerId}
                      sellerOpsNotes={sellerOpsNotes} />
        </div>
    </div>;

export default SellerMiscInfosSection;
