import { formatAdminDateStr } from "../../../utils/dateTimeUtils";
import CheckBox from "factor-lib/forms/CheckBox";
import InputAmount, {formatAmountToInput, isValidAmount, parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import { computeInvoiceStatus } from "../../Invoices/invoiceStatus";
import { IInvoiceMatchAmount, IInvoiceMatchEnabled } from "./invoiceMatch";
import { Link } from "react-router-dom";
import {
    ISwanIncomingPaymentDetailsInvoice,
    ISwanIncomingPaymentDetailsInvoiceCompany,
    ISwanIncomingPaymentDetailsInvoicePayment
} from "../swanIncomingPaymentDetailsQuery";
import {invoiceUrl} from "../../Invoices/invoicesUrls";
import {buyerUrl} from "../../Buyers/buyersUrls";
import {sellerUrl} from "../../Sellers/sellersUrls";
import { formatAmount } from "factor-lib/utils/amountUtils";

export interface ISwanIncomingPaymentDetailsInvoiceSeller {
    id: string;
    companyName: string;
}

const SwanIncomingPaymentDetailsInvoiceRow = (
    {
        invoice,
        invoicePayment,
        matchAmount,
        matchEnabled,
        buyerCompany,
        paymentToMarketplaceSeller
    }: {
        invoice: ISwanIncomingPaymentDetailsInvoice;
        invoicePayment: ISwanIncomingPaymentDetailsInvoicePayment;
        matchAmount: IInvoiceMatchAmount | null;
        matchEnabled: IInvoiceMatchEnabled | null;
        buyerCompany: ISwanIncomingPaymentDetailsInvoiceCompany;
        paymentToMarketplaceSeller: ISwanIncomingPaymentDetailsInvoiceSeller | null;
    }
) => {
    const invoiceAmountTTC: number = invoice.amountWoTax + invoice.amountTax;
    const invoiceAmountRemaining: number = invoiceAmountTTC - invoicePayment.partialAmountPaid;

    return (
        <tr>
            <td>
                <Link id={`invoice-${invoice.id}-link`}
                      to={invoiceUrl(invoice.id)}>
                    { invoice.number }
                </Link>
            </td>
            <td>
                { formatAdminDateStr(invoice.dueDate) }
            </td>
            { !!paymentToMarketplaceSeller &&
                <td>
                    <Link id={`invoice-${invoice.id}-seller-link`}
                          to={sellerUrl(paymentToMarketplaceSeller.id)}>
                        { paymentToMarketplaceSeller.companyName }
                    </Link>
                </td>
            }
            <td>
                <Link id={`invoice-${invoice.id}-buyer-link`}
                      to={buyerUrl(buyerCompany.id, false)}>
                    { buyerCompany.name }
                </Link>
            </td>
            <td>
                { formatAmount(invoice.amountWoTax + invoice.amountTax) }
            </td>
            <td>
                { formatAmount(invoice.amountWoTax + invoice.amountTax - invoicePayment.partialAmountPaid) }
            </td>
            <td>
                { computeInvoiceStatus(!!invoicePayment.completePaidDate, invoice.status, false) }
            </td>

            <td>
                <CheckBox internalId={`invoice-${invoice.id})-completePayment`}
                          inputValue={!!matchAmount && matchAmount.isComplete}
                          updateInputValue={!!matchEnabled &&
                          (
                              (!!matchAmount && matchAmount.isComplete) ||
                              (matchEnabled.newPaymentAmountRemaining !== null && invoiceAmountRemaining <= matchEnabled.newPaymentAmountRemaining)
                          )
                              ? (newValue: boolean) => matchEnabled.updateMatchAmount(
                                  newValue
                                      ? {
                                          isComplete: true,
                                          amountInput: formatAmountToInput(invoiceAmountRemaining)
                                      }
                                      : null
                              )
                              : null
                          } />
            </td>

            <td>
                <CheckBox internalId={`invoice-${invoice.id}-partialPayment`}
                          inputValue={!!matchAmount && !matchAmount.isComplete}
                          updateInputValue={!!matchEnabled &&
                          (
                              (!!matchAmount && !matchAmount.isComplete) ||
                              (matchEnabled.newPaymentAmountRemaining !== null && matchEnabled.newPaymentAmountRemaining > 0)
                          )
                              ? (newValue: boolean) => matchEnabled.updateMatchAmount(
                                  newValue
                                      ? {
                                          isComplete: false,
                                          amountInput: matchEnabled.newPaymentAmountRemaining !== null
                                              ? formatAmountToInput(
                                                  Math.min(
                                                      invoiceAmountRemaining,
                                                      matchEnabled.newPaymentAmountRemaining
                                                  )
                                              )
                                              : ''
                                      }
                                      : null
                              )
                              : null
                          } />
            </td>

            <td style={{minWidth: '250px'}}>
                { !!matchAmount &&
                    <InputAmount style={{maxWidth: '180px'}}
                                 inputAmountValue={matchAmount.amountInput}
                                 customError={
                                     !!matchEnabled &&
                                     !matchAmount.isComplete &&
                                     isValidAmount(matchAmount.amountInput) &&
                                     parseInputAmount(matchAmount.amountInput) >= invoiceAmountRemaining
                                         ? `Le montant n'est pas partiel`
                                         : null
                                 }
                                 enabled={(!!matchEnabled && !matchAmount.isComplete) ? ({
                                     innerId: null,
                                     updateInputValue: (newValue: string) =>
                                         matchEnabled.updateMatchAmount({
                                             isComplete: false,
                                             amountInput: newValue
                                         })
                                 }) : null} />
                }
            </td>
        </tr>
    );
}

export default SwanIncomingPaymentDetailsInvoiceRow;
