import { IBuyerCompany } from "factor-lib/AddInvoice/BuyerSelection";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import { ConfidenceFlag } from "../../../utils/ConfidenceFlags";

export const sellerForAddInvoiceQueryKey = (sellerId: string) =>
    ['seller-for-add-invoice', sellerId];

export interface ISellerForAddInvoiceQueryResult {
    sellerDetails: {
        company: {
            id: string;
            name: string;
            identifier: IIntCompanyIdentifier;
        };
        customer:  {
            directSeller: {
                id: string;
            } | null;
        };
        certif: {
            solvaConfidenceFlag: ConfidenceFlag;
            fraudConfidenceFlag: ConfidenceFlag;
        } | null;
        isBlocked: boolean;
    }
    buyers: Array<{
        company: IBuyerCompany
    }>;
}
