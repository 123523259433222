import CheckBox from "factor-lib/forms/CheckBox";
import { useState } from "react";
import IAuthUser from "../../../../IAuthUser";
import Button from "factor-lib/Buttons/Button";
import {
    IBuyerEvent, IBuyerEventInvoice, IBuyerEventInvoiceMatch,
    IBuyerEmail, IBuyerPhone,
    IBuyer, IMerciFacteurMail, IFinancingWithMerciFacteurMail, IFinancingMerciFacteurMail
} from "../buyerCompanyDetailsQuery";
import BuyerEventModal from "./BuyerEventModal";
import BuyerEventsTable from "./BuyerEventsTable";
import { Axios } from "axios";

const groupById = <K, E, M, O>(
    events: E[],
    eventsInvoiceMatches: M[],
    eventKeyExtractor: (e: E) => K,
    matchKeyExtractor: (m: M) => K,
    matchOutputExtractor: (m: M) => O
): Map<K, O[]> => {
    const eventToInvoicesMap = new Map<K, O[]>();

    for (const eventInvoiceMatch of eventsInvoiceMatches) {
        const eventId: K = matchKeyExtractor(eventInvoiceMatch);
        const eventInvoice: O = matchOutputExtractor(eventInvoiceMatch);

        const eventInvoices: O[] | undefined =  eventToInvoicesMap.get(eventId);
        if (eventInvoices === undefined) {
            eventToInvoicesMap.set(eventId, [eventInvoice]);
        } else {
            eventInvoices.push(eventInvoice);
        }
    }

    for (const event of events) {
        const eventId = eventKeyExtractor(event);
        if (!eventToInvoicesMap.has(eventId)) {
            eventToInvoicesMap.set(eventId, []);
        }
    }

    return eventToInvoicesMap;
};

const BuyerEventsSection = (
    {
        axios,
        authUser,
        buyerCompanyId,
        buyers,
        buyerEmails,
        buyerPhones,
        buyerEvents,
        buyerEventsInvoiceMatches,
        merciFacteurMails,
        financingsMerciFacteurMails
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        buyerCompanyId: string;
        buyers: IBuyer[];
        buyerEmails: IBuyerEmail[];
        buyerPhones: IBuyerPhone[];
        buyerEvents: IBuyerEvent[];
        buyerEventsInvoiceMatches: IBuyerEventInvoiceMatch[];
        merciFacteurMails: IMerciFacteurMail[];
        financingsMerciFacteurMails: IFinancingWithMerciFacteurMail[];
    }
) => {
    const buyerEventsToInvoices: Map<string, IBuyerEventInvoice[]> = groupById(
        buyerEvents,
        buyerEventsInvoiceMatches,
        e => e.id,
        m => m.eventId,
        m => m.invoice
    );

    const merciFacteurMailsToFinancings: Map<number, IFinancingMerciFacteurMail[]> = groupById(
        merciFacteurMails,
        financingsMerciFacteurMails,
        e => e.id,
        f => f.merciFacteurMail.id,
        f => f.financing
    );

    const [showAddBuyerEventModal, setShowAddBuyerEventModal] = useState(false);
    const [selectedBuyerEvent, setSelectedBuyerEvent] = useState<IBuyerEvent | null>(null);
    const [fetchBuyerEmails, setFetchBuyerEmails] = useState(false);

    return (
        <div>
            <div className='level p-padding-left-5 p-padding-right-5'>
                <div className='level-left'>
                    <div className='p-both-center p-margin-top-7'>
                        <CheckBox internalId='fetchBuyerEmailsId'
                                  inputValue={fetchBuyerEmails}
                                  updateInputValue={setFetchBuyerEmails} />
                        <span className='p-bold p-margin-left-5'>Include automatic emails</span>
                    </div>
                </div>

                <div className='level-right'>
                    { !!authUser?.canManageFinancings &&
                        <div>
                            <Button id='addBuyerEventModal'
                                    text='Add event'
                                    isLoading={false}
                                    actionO={() => setShowAddBuyerEventModal(true)} />
                        </div>
                    }
                </div>
            </div>

            <BuyerEventsTable axios={axios}
                              buyerCompanyId={buyerCompanyId}
                              buyerEvents={buyerEvents}
                              buyerEventsToInvoices={buyerEventsToInvoices}
                              merciFacteurMails={merciFacteurMails}
                              merciFacteurMailsToFinancings={merciFacteurMailsToFinancings}
                              fetchBuyerEmails={fetchBuyerEmails}
                              setSelectedBuyerEvent={setSelectedBuyerEvent} />

            { (showAddBuyerEventModal || !!selectedBuyerEvent) &&
                <BuyerEventModal axios={axios}
                                 authUser={authUser}
                                 buyerCompanyId={buyerCompanyId}
                                 buyersAndContactsLoaded={{
                                     buyers,
                                     buyerEmails,
                                     buyerPhones
                                 }}
                                 existingBuyerEvent={selectedBuyerEvent}
                                 initialSelectedInvoicesIds={!!selectedBuyerEvent
                                     ? buyerEventsToInvoices.get(selectedBuyerEvent.id)!.map((i) => i.id)
                                     : []
                                 }
                                 alwaysSelectedInvoiceId={null}
                                 closeModal={() => {
                                     setShowAddBuyerEventModal(false);
                                     setSelectedBuyerEvent(null);
                                 }} />
            }
        </div>
    );
};

export default BuyerEventsSection;
