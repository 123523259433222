export const SellersUrl = '/sellers';

export const SellerUrlSellerIdParam = 'sellerId';
export const SellerUrlMapping = `${SellersUrl}/:${SellerUrlSellerIdParam}`;

export const SellerInfosTabParam = 'infos';
export const sellerUrl = (sellerId: string) => sellerUrlTab(sellerId, SellerInfosTabParam);
export const sellerUrlTab = (sellerId: string, tab: string) => `${SellersUrl}/${sellerId}/${tab}`;

export const AddSellerUrl = `${SellersUrl}/add`;
export const AddSellerUrlCustomerIdQueryParamName: string = 'customerId';
export const addSellerForCustomerUrl = (customerId: string) => `${AddSellerUrl}?${AddSellerUrlCustomerIdQueryParamName}=${customerId}`;