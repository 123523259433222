import { Axios } from "axios";
import {useContext, useState} from "react";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import Input from "factor-lib/forms/Inputs/Input";
import { ISellersQueryResult, sellersQueryKey } from "../sellersQuery";
import { ResultOrErrors } from "../../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../../utils/ShowValidationErrorsButton";
import Modal from "factor-lib/Modal";
import Button from "factor-lib/Buttons/Button";
import {NavigateFunction} from "react-router-dom";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import IPerson, { getPersonParams } from "../../../utils/Person/IPerson";
import PersonInput, { getInitialNewPersonInput, IPersonInput } from "../../../utils/Person/PersonInput";
import { getInitialNewAddressInputO, IAddressOInput } from "../../../utils/AddressInput";
import { DEFAULT_FRANCE } from "../../../utils/Countries";
import { AddSellerUrl, sellerUrl } from "../sellersUrls";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import { formatCompanyInput } from "factor-lib/Company/companyUtils";
import CompanySelector from "../../../utils/Company/CompanySelector";
import { ICompanySelection } from "factor-lib/Company/ICompanySelection";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { isValidPhone, validatePhoneError } from "factor-lib/forms/Inputs/phoneUtils";
import { isValidEmail, validateEmailError } from "factor-lib/forms/Inputs/emailUtils";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import { isValidIban, validateIbanError } from "factor-lib/forms/Inputs/ibanUtils";

const AddText = 'Ajouter';

interface IAddMarketplaceSellerParams {
    marketplaceId: string;
    companySelection: ICompanySelection;
    iban: string | null;
    email: string;
    phone : string;
    dimplTermsAcceptationDateTime: string;
}

interface IAddMarketplaceSellerResponse {
    sellerId: string;
    companyName: string;
    companyIdentifier: IIntCompanyIdentifier;
}

const AddMarketplaceSellerEnabled = (
    {
        axios,
        marketplaceName,
        addParams,
        kind,
        size,
        text,
        clearForm
    } : {
        axios: Axios;
        marketplaceName: string;
        addParams: IAddMarketplaceSellerParams;
        kind: string;
        size: string;
        text: string;
        clearForm: () => void;
    }
) => {
    const queryClient = useQueryClient();

    const [addResponse, setAddResponse] = useState<IAddMarketplaceSellerResponse | null>(null);

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const addMarketplaceSellerMutation: UseMutationResult<IAddMarketplaceSellerResponse, any, IAddMarketplaceSellerParams> =
        useMutation<IAddMarketplaceSellerResponse, any, IAddMarketplaceSellerParams>(
            async (addParams2) =>
                (await axios.post<IAddMarketplaceSellerResponse>(
                    `adminSellers`,
                    addParams2
                )).data,
            ({
                onSuccess: (addResponse) => {
                    queryClient.setQueryData<ISellersQueryResult>(
                        sellersQueryKey,
                        (old: ISellersQueryResult | undefined) => ({
                            sellers: [
                                ...old!.sellers,
                                {
                                    id: addResponse.sellerId,
                                    creationDateTime: serverDateSerialization(new Date()),
                                    email: addParams.email,
                                    isBlocked: false,
                                    certif: null,
                                    company: {
                                        name: addResponse.companyName,
                                        identifier: addResponse.companyIdentifier
                                    },
                                    customer: {
                                        directSeller: null
                                    }
                                }
                            ]
                        })
                    );
                    setAddResponse(addResponse);
                }
            })
        );

    return (
        <>
            { !!addResponse &&
                <Modal id='addMarketplaceSellerResponseModal'
                       maxWidth={null}
                       fullMaxWidth={true}
                       close={() => {
                           setAddResponse(null);
                           clearForm();
                       }}>
                    <ResultModalContent marketplaceName={marketplaceName}
                                        result={addResponse}
                                        reset={() => {
                                            setAddResponse(null);
                                            clearForm();
                                        }}/>
                </Modal>
            }
            <ButtonMutationEnabled id='addMarketplaceSellerButton'
                                   kind={kind}
                                   size={size}
                                   text={text}
                                   mutation={addMarketplaceSellerMutation}
                                   displayFullError={true}
                                   value={addParams} />
        </>
    );
};

const getMarketplaceSellerAddParams = (
    marketplaceId: string,

    companySelection: ICompanySelection | null,
    ibanInput: string,
    emailInput: string,
    phoneInput : string,

    ownerFirstNameInput: string,
    ownerLastNameInput: string,
    ownerNationality: string | null,

    ownerBirthDateInput: string,
    ownerBirthCityInput: string,
    ownerBirthCountry: string | null,

    ownerResidenceAddressInput: IAddressOInput,
): ResultOrErrors<IAddMarketplaceSellerParams> => {

    const validationErrors: string[] = [];

    if (!companySelection) {
        validationErrors.push(`La société n'est pas choisie`);
    }

    const ibanInputNullable = ibanInput !== '' ? ibanInput : null;
    if (ibanInputNullable !== null && !isValidIban(ibanInput)) {
        validationErrors.push(`L'IBAN est invalide`);
    }

    if (!isValidEmail(emailInput)) {
        validationErrors.push(`L'email est invalide`);
    }

    if (!isValidPhone(phoneInput)) {
        validationErrors.push('Le téléphone est invalide');
    }

    const companyOwnerParamsFailable: ResultOrErrors<IPerson> = getPersonParams(
        ownerFirstNameInput,
        ownerLastNameInput,
        ownerNationality,

        false,
        ownerBirthDateInput,
        ownerBirthCityInput,
        ownerBirthCountry,

        ownerResidenceAddressInput
    );

    if (!!companyOwnerParamsFailable.errors) {
        validationErrors.push(...companyOwnerParamsFailable.errors);
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    return ResultOrErrors.fromResult({
        marketplaceId: marketplaceId,
        companySelection: companySelection!,

        iban: ibanInputNullable,
        email: emailInput,
        phone: phoneInput,

        companyOwner: companyOwnerParamsFailable.result!,

        dimplTermsAcceptationDateTime: serverDateSerialization(new Date())
    });
};

const ResultModalContent = (
    {
        marketplaceName,
        result,
        reset
    }: {
        marketplaceName: string;
        result: IAddMarketplaceSellerResponse;
        reset: () => void;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    return (
        <div className='p-padding-4 p-vertical-center'>
            <span>Le seller <span className='p-bold' style={{textTransform: 'uppercase'}}>{result.companyName}</span> de la marketplace <span className='p-bold' style={{textTransform: 'uppercase'}}>{marketplaceName}</span> a bien été ajouté</span>
            <div className='columns p-margin-top-5'>
                <div className='column'>
                    <Button id='gotoAddedSellerDetailsButton'
                            text='Détails du Seller'
                            isLoading={false}
                            actionO={() => navigate(sellerUrl(result.sellerId))} />

                </div>
                <div className='column'>
                    <Button id='addAnotherMarketplaceSellerButton'
                            text='Ajouter un autre Seller pour cette Marketplace'
                            isLoading={false}
                            actionO={() => {
                                reset();
                            }} />
                </div>
                <div className='column'>
                    <Button id='addAnotherSellerButton'
                            text='Ajouter un autre Seller'
                            isLoading={false}
                            actionO={() => navigate(AddSellerUrl)} />
                </div>
            </div>
        </div>
    );
}

const AddMarketplaceSeller = (
    {
        axios,
        marketplaceId,
        marketplaceName,
        marketplaceIdentifier
    }: {
        axios: Axios;
        marketplaceId: string;
        marketplaceName: string;
        marketplaceIdentifier: IIntCompanyIdentifier;
    }
) => {

    // TODO : review generic validation pattern

    const [companySearchInput, setCompanySearchInput] = useState('');
    const [companySelection, setCompanySelection] = useState<ICompanySelection | null>(null);

    const [ibanInput, setIbanInput] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [phoneInput, setPhoneInput] = useState('');

    const companyOwnerInput: IPersonInput = getInitialNewPersonInput(false);

    const [firstNameInput, setFirstNameInput] = useState(companyOwnerInput.firstName);
    const [lastNameInput, setLastNameInput] = useState(companyOwnerInput.lastName);
    const [nationalityCodeInput, setNationalityCodeInput] = useState(companyOwnerInput.nationalityCode);

    const [birthDateInput, setBirthDateInput] = useState(companyOwnerInput.birthDate);
    const [birthCityInput, setBirthCityInput] = useState(companyOwnerInput.birthCity);
    const [birthCountryCodeInput, setBirthCountryCodeInput] = useState(companyOwnerInput.birthCountryCode);

    const [ownerResidenceAddressInput, setOwnerResidenceAddressInput] = useState<IAddressOInput>(companyOwnerInput.residenceAddress);

    const clearForm = () => {
        setCompanySearchInput('');
        setCompanySelection(null);

        setIbanInput('');
        setEmailInput('');
        setPhoneInput('');

        setFirstNameInput('');
        setLastNameInput('');
        setNationalityCodeInput(null);

        setBirthDateInput('');
        setBirthCityInput('');
        setBirthCountryCodeInput(DEFAULT_FRANCE.value);

        setOwnerResidenceAddressInput(getInitialNewAddressInputO());
    };

    const addParams: ResultOrErrors<IAddMarketplaceSellerParams> =
        getMarketplaceSellerAddParams(
            marketplaceId,

            companySelection,
            ibanInput,
            emailInput,
            phoneInput,

            firstNameInput,
            lastNameInput,
            nationalityCodeInput,

            birthDateInput,
            birthCityInput,
            birthCountryCodeInput,

            ownerResidenceAddressInput
        );

    return (
        <div className='container p-both-center p-margin-top-3'>
            <div style={{width: 600}}>
                <span className='title p-both-center'>
                    Ajouter un Seller pour la Marketplace {formatCompanyInput(marketplaceIdentifier, marketplaceName)}
                </span>

                <div className='box'>
                    <span className='p-bold p-size-4 p-both-center p-margin-bottom-4'>Infos générales</span>

                    <div className='p-padding-bottom-5'>
                        <span className='p-bold'>Email</span>
                        <Input inputValue={emailInput}
                               enabled={{
                                   updateInputValue: setEmailInput,
                                   validateInput: () => validateEmailError(emailInput),
                                   innerId: {
                                       value: 'emailInner',
                                       autofocus: true
                                   }
                               }} />
                    </div>

                    <div className='p-padding-bottom-5'>
                        <span className='p-bold'>Téléphone</span>
                        <Input inputValue={phoneInput}
                               enabled={{
                                   updateInputValue: setPhoneInput,
                                   validateInput: () => validatePhoneError(phoneInput)
                               }} />
                    </div>
                </div>

                <div className='box'>
                    <span className='p-bold p-size-4 p-both-center p-margin-bottom-4'>Infos de la société</span>

                    <div className='p-margin-bottom-5'>
                        <CompanySelector axios={axios}
                                         searchPlaceHolder='Nom ou SIREN du Seller'
                                         searchInput={companySearchInput}
                                         setSearchInput={setCompanySearchInput}
                                         initialDisplaySearch={true}
                                         selection={companySelection}
                                         setSelection={setCompanySelection}
                                         autofocus={false} />
                    </div>

                    <div>
                        <span className='p-bold'>IBAN (optionel)</span>
                        <Input inputValue={ibanInput}
                               enabled={{
                                   updateInputValue: setIbanInput,
                                   validateInput: () => validateIbanError(ibanInput)
                               }} />
                    </div>
                </div>

                <div className='box'>
                    <span className='p-bold p-size-4 p-both-center p-margin-bottom-4'>Mandataire</span>

                    <PersonInput firstNameInput={firstNameInput}
                                 lastNameInput={lastNameInput}
                                 nationalityCodeInput={nationalityCodeInput}
                                 isBirthInfosMandatory={false}
                                 birthDateInput={birthDateInput}
                                 birthCityInput={birthCityInput}
                                 birthCountryCodeInput={birthCountryCodeInput}
                                 residenceAddressInput={ownerResidenceAddressInput}
                                 enabled={{
                                     setFirstNameInput: setFirstNameInput,
                                     setLastNameInput: setLastNameInput,
                                     setNationalityCodeInput: setNationalityCodeInput,
                                     setBirthDateInput: setBirthDateInput,
                                     setBirthCityInput: setBirthCityInput,
                                     setBirthCountryCodeInput: setBirthCountryCodeInput,
                                     setResidenceAddressInput: setOwnerResidenceAddressInput,
                                     isBirthInfosMandatory: false,
                                     autofocus: false
                                 }} />
                </div>

                <div className='p-margin-3 p-vertical-center'>
                    { !!addParams.result
                        ? <AddMarketplaceSellerEnabled axios={axios}
                                                       marketplaceName={marketplaceName}
                                                       addParams={addParams.result}
                                                       kind={KIND_PRIMARY}
                                                       size={SIZE_FIXED}
                                                       text={AddText}
                                                       clearForm={clearForm}/>
                        : <ShowValidationErrorsButton validationErrors={addParams.errors!}
                                                      kind={KIND_PRIMARY}
                                                      size={SIZE_FIXED}
                                                      text={AddText} />
                    }
                </div>
            </div>
        </div>
    );
};

export default AddMarketplaceSeller;
