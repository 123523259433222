import {MsalAuthenticationTemplate, MsalProvider} from "@azure/msal-react";
import {
    AuthError, InteractionType,
    PopupRequest
} from "@azure/msal-browser";
import {configureCustomNavigationClient} from 'factor-lib/auth/CustomNavigationClient';
import {NavigateFunction, Outlet, useOutletContext} from "react-router-dom";
import CurrentEnv from "./envs/CurrentEnv";
import logger from "./logger";
import msalInit from 'factor-lib/auth/msalInit';
import {ReactElement, useContext} from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";

const loginRequest: PopupRequest = {
    scopes: ['openid', 'profile']
};

const AuthErrorC =  (props: {error: AuthError | null}) => {
    const { error } = props;
    return (
        <div>An Error Occurred during authentication: {error ? `${error.errorMessage}${!!error.subError ? ` - ${error.subError}` : null} - ` : "<<unknown>>"}</div>
    );
}

const msalInstance = msalInit(
    logger,
    {
        // clientId: 'e9750ff5-0880-4ac5-851a-bff534c54840', // api
        clientId: CurrentEnv.azureADClientId,
        authority: "https://login.microsoftonline.com/dimpl.io",
        redirectUri: CurrentEnv.baseUrl,
        // postLogoutRedirectUri: "/"
    },
    CurrentEnv.logMsalInfo
);

export const WithMsalAuthenticationOContext = (
    {
        child
    }: {
        child: (isMsalAuthenticationComplete: boolean) => ReactElement;
    }
) => {
    const {isMsalAuthenticationComplete} = useOutletContext<{isMsalAuthenticationComplete: boolean}>();
    return child(isMsalAuthenticationComplete);
}

const MsalProviderWrapper = () => {

    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/react-router-sample/src/App.js
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    configureCustomNavigationClient(msalInstance, navigate);

    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect /* PRED-1918 : many issues with popup */}
                                        authenticationRequest={loginRequest}
                                        errorComponent={AuthErrorC}
                                        loadingComponent={() => <Outlet context={{isMsalAuthenticationComplete: false}}/>} >
                <Outlet context={{isMsalAuthenticationComplete: true}}/>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}

export default MsalProviderWrapper;