import {ReactElement} from "react";
import {RouteObject} from "react-router-dom";
import IAccessToken from "../IAccessToken";
import {WithTitleOSetterAndAccessTokenOContext} from "./WithTitleSetterAndAccessTokenOContext";

export const buildRouteDefinitionWithAxiosAndRolesORec = (routeDefinitionWithAxiosAndRolesO: IRouteDefinitionWithAxiosAndRolesO): RouteObject =>
    ({
        path: routeDefinitionWithAxiosAndRolesO.path,
        element: <WithTitleOSetterAndAccessTokenOContext titleO={routeDefinitionWithAxiosAndRolesO.titleO}
                                                         child={routeDefinitionWithAxiosAndRolesO.component}/>,
        children: !!routeDefinitionWithAxiosAndRolesO.children ? buildRouteDefinitionsWithAxiosAndRolesORec(routeDefinitionWithAxiosAndRolesO.children) : undefined
    });

export const buildRouteDefinitionsWithAxiosAndRolesORec = (routeDefinitionWithAxiosAndRolesO: IRouteDefinitionWithAxiosAndRolesO[]): RouteObject[] =>
    routeDefinitionWithAxiosAndRolesO
        .map(buildRouteDefinitionWithAxiosAndRolesORec);

export default interface IRouteDefinitionWithAxiosAndRolesO {
    path: string;
    titleO?: string;
    component: (accessTokenO: IAccessToken | null) => ReactElement;
    children?: IRouteDefinitionWithAxiosAndRolesO[];
}