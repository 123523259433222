import { Link } from "react-router-dom";
import Loader from "factor-lib/Loader";
import { formatAdminDateStr } from "../../../utils/dateTimeUtils";
import { CONFIDENCE_FLAGS_COLORS, ConfidenceFlag } from "../../../utils/ConfidenceFlags";
import { Axios } from "axios";
import GraphQLQueryWrapper from "../../../utils/GraphQLQueryWrapper";
import { sellerUrl } from "../../Sellers/sellersUrls";
import { formatIntCompanyIdentifier, IIntCompanyIdentifier, IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import { comparingDate } from "factor-lib/utils/dateUtils";
import { comparingRev } from "factor-lib/utils/comparingUtils";
import { serverDateDeserialization } from "factor-lib/utils/dateUtils";

export interface IMarketplaceSeller {
    id: string;
    creationDateTime: string;
    company: {
        name: string;
        identifier: IIntCompanyIdentifier;
    };
    email: string;
    phone: string;
    certif: {
        solvaConfidenceFlag: ConfidenceFlag;
        fraudConfidenceFlag: ConfidenceFlag;
    } | null;
    isBlocked: boolean;
}

interface IMarketplaceSellersQueryResult {
    sellers: Array<IMarketplaceSeller>;
}

const compareSellerDate = comparingRev(
    comparingDate((seller: IMarketplaceSeller) => serverDateDeserialization(seller.creationDateTime))
);

const MarketplaceSellersTable = (
    {
        axios,
        marketplaceId
    }: {
        axios: Axios,
        marketplaceId: string;
    }
) =>
    <table className='table is-striped'>
        <GraphQLQueryWrapper queryKey={['marketplace-sellers', marketplaceId]}
                             axios={axios}
                             queryParams={{
                                 query: `query Q($marketplaceId: Guid!) {
                                    sellers (marketplaceId: $marketplaceId) {
                                        id
                                        creationDateTime
                                        company { name identifier { ${IntCompanyIdentifierGraphQLFields} } }
                                        email
                                        phone
                                        certif {
                                            solvaConfidenceFlag
                                            fraudConfidenceFlag
                                        }
                                        isBlocked
                                    }
                                }`,
                                 variables: {
                                     marketplaceId
                                 }
                             }}
                             onLoading={() =>
                                 <tbody>
                                    <tr>
                                        <td/>
                                        <td/>
                                        <td><Loader /></td>
                                        <td/>
                                        <td/>
                                        <td/>
                                    </tr>
                                 </tbody>
                             }
                             onSuccess={(r: IMarketplaceSellersQueryResult) =>
                                 <tbody>
                                    { r.sellers
                                        .sort(compareSellerDate)
                                        .map((s, index) => (
                                            <tr key={`marketplace-seller-${index}`}>
                                                <td>
                                                    <Link to={sellerUrl(s.id)}>{ s.company.name }</Link>
                                                    { s.isBlocked && <span style={{color: 'red'}}> (blocked)</span> }
                                                </td>
                                                <td>
                                                    { formatAdminDateStr(s.creationDateTime) }
                                                </td>
                                                <td>
                                                    { formatIntCompanyIdentifier(s.company.identifier) }
                                                </td>
                                                <td>
                                                    { s.email }
                                                </td>
                                                <td>
                                                    { s.phone }
                                                </td>
                                                <td>
                                                    { !!s.certif
                                                        ? <div>
                                                            <span style={{color: CONFIDENCE_FLAGS_COLORS[s.certif.solvaConfidenceFlag]}}>
                                                                { s.certif.solvaConfidenceFlag }
                                                            </span>/
                                                            <span style={{color: CONFIDENCE_FLAGS_COLORS[s.certif.fraudConfidenceFlag]}}>
                                                                { s.certif.fraudConfidenceFlag }
                                                            </span>
                                                        </div>
                                                        : 'N/A'
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                 </tbody>
                             } />
    </table>;

export default MarketplaceSellersTable;
