import { formatAmount } from "factor-lib/utils/amountUtils";
import { IGlobalOutstandings } from "./globalOutstandingsQuery";
import {ReactNode} from "react";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import IAuthUser from "../../../IAuthUser";
import ModifyGlobalOutstandingModalContent from "./ModifyGlobalOutstandingModalContent";
import { Axios } from "axios";

const SimpleLineItemAmount =
    (
        {
            title,
            explanationO,
            amount
        }: {
            title: string;
            explanationO?: string | null;
            amount: number;
        }
    ) =>
        <LineItem title={title}
                  explanationO={explanationO}>
            { formatAmount(amount) }
        </LineItem>;

const LineItem =
    (
        {
            title,
            explanationO,
            children
        }: {
            title: string;
            explanationO?: string | null;
            children: ReactNode;
        }
    ) =>
        <tr>
            <td>
                <span className='p-bold'>{ title }</span>
                {!!explanationO &&
                    <span className='p-size-7'>{` (${explanationO})`}</span>
                }
            </td>
            <td>
                { children }
            </td>
        </tr>;

const GlobalOutstandingsLoaded = (
    {
        axios,
        authUserO,
        globalOutstandings
    }: {
        axios: Axios;
        authUserO: IAuthUser | null;
        globalOutstandings: IGlobalOutstandings;
    }
) => {
    const { activeFinancings, activeFinancingRequests, limit } = globalOutstandings;
    const effectiveOutstanding = activeFinancings + activeFinancingRequests;
    const availableEffectiveOutstanding = limit - effectiveOutstanding;

    return (
        <div className='box p-padding-3'>
            <div className='title p-both-center'>
                Encours global
            </div>

            <table className='table is-narrow'>
                <tbody>
                    <SimpleLineItemAmount title='Encours réél'
                                          explanationO='financements actifs'
                                          amount={activeFinancings}/>


                    <SimpleLineItemAmount title='Encours en attente'
                                          explanationO='demandes de financement actives'
                                          amount={activeFinancingRequests}/>
                    <tr>
                        <td><hr/></td>
                    </tr>
                    <SimpleLineItemAmount title='Encours effectif'
                                          explanationO='somme des deux'
                                          amount={effectiveOutstanding} />

                    <LineItem title="Limite d'encours effectif">
                        <div className='p-horizontal-center'>
                            <span>{ formatAmount(limit) }</span>
                            { !!authUserO?.canUpdateEligibilityGlobalLimit &&
                                <ButtonForModal className='p-margin-left-5'
                                                text='Mofifier'
                                                childModalContent={(closeModal: () => void) =>
                                                    <ModifyGlobalOutstandingModalContent closeModal={closeModal}
                                                                                         axios={axios}
                                                                                         currentLimit={limit}/>
                                }
                                />
                            }
                        </div>
                    </LineItem>
                    <LineItem title="Encours effectif restant">
                        <span style={{color: availableEffectiveOutstanding <= 0 ? 'red' : 'green'}}>
                            { formatAmount(availableEffectiveOutstanding) }
                        </span>
                    </LineItem>
                </tbody>
            </table>
        </div>
    );
}

export default GlobalOutstandingsLoaded;
