import {Axios} from "axios";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { useState } from "react";
import Input from "factor-lib/forms/Inputs/Input";
import { ResultOrErrors } from "../../../../../utils/ResultOrErrors";
import {useMutation, UseMutationResult} from "@tanstack/react-query";
import ShowValidationErrorsButton from "../../../../../utils/ShowValidationErrorsButton";
import InputAmount, { isValidAmount, parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import ButtonMutationWithConfirmationModal from "factor-lib/Buttons/ButtonMutationWithConfirmationModal";
import IOption from "factor-lib/forms/Select/IOption";
import { isValidIban } from "factor-lib/forms/Inputs/ibanUtils";
import { isValidString, validateMandatoryFieldError } from "factor-lib/forms/Inputs/utils";
import { formatAmount } from "factor-lib/utils/amountUtils";

const ChargeText = 'Prélever';

interface IStripeChargeParams {
    customerId: string;
    IBAN: string;
    label: string;
    amount: number;
}

const ChargeInStripeEnabled = (
    {
        axios,
        companyName,
        params,
        closeModal,
        kind,
        size,
        text
    } : {
        axios: Axios;
        companyName: string;
        params: IStripeChargeParams;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const chargeInStripeMutation: UseMutationResult<void, any, IStripeChargeParams, null> =
        useMutation<void, any, IStripeChargeParams, null>(
            async (params2) => {
                await axios.post<void>(
                    `/adminStripe/charge`,
                    params2
                );
            },
            ({
                onSuccess: () => {                    
                    closeModal();
                }
            })
        );

    const confirmationText =
        `Vous vous appretez à prélever l'utilisateur ${companyName} ` +
        `d'un montant de ${formatAmount(params.amount)} libellé : ${params.label}.`

    return (
        <ButtonMutationWithConfirmationModal id='ChargeInStripeButton'
                                             kind={kind}
                                             size={size}
                                             text={text}
                                             confirmationText={confirmationText}
                                             mutation={chargeInStripeMutation}
                                             value={params} />
    );
}

const getStripeChargeParams = (
    customerId: string,
    iban: string,
    label: string,
    amount: string
): ResultOrErrors<IStripeChargeParams> => {
    const validationErrors: string[] = [];

    if (!isValidIban(iban)) {
        validationErrors.push(`L'IBAN est invalide`);
    }

    if (!isValidAmount(amount)) {
        validationErrors.push(`Le montant est invalide`);
    }

    if (!isValidString(label)) {
        validationErrors.push(`Le libellé est invalide`);
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    return ResultOrErrors.fromResult({
        customerId: customerId,
        amount: parseInputAmount(amount),
        IBAN: iban,
        label: label
    });
};

const ChargeCustomerInStripeModalContent = (
    {
        axios,
        customerId,
        companyName,
        billingIban,
        directSellerIbanO,
        closeModal
    }: {
        axios: Axios;
        customerId: string;
        companyName: string;
        billingIban: string;
        directSellerIbanO: string | null;
        closeModal: () => void;
    }
) => {
    const [ amountInput, setAmountInput ] = useState('');
    const [ labelInput, setLabelInput ] = useState('');

    const ibanOptions: Array<IOption<string>> = [
        {
            label: `Iban billing Plateforme : ${billingIban}`,
            value: billingIban
        }
    ];

    if (!!directSellerIbanO && directSellerIbanO !== billingIban) {
        ibanOptions.push({ label: `Iban Seller : ${directSellerIbanO}`, value: directSellerIbanO });
    }

    const [ selectedIban, setSelectedIban ] = useState(ibanOptions[0].value);

    const createParams: ResultOrErrors<IStripeChargeParams> = getStripeChargeParams(
        customerId,
        selectedIban,
        labelInput,
        amountInput
    );

    return (
        <div className='p-padding-3'>
            <InputAmount className='p-margin-top-5'
                         inputAmountValue={amountInput}
                         placeHolder='Montant à prélever'
                         enabled={{
                             updateInputValue: setAmountInput,
                             innerId: null //TODO: debug why this is not working properly when autofocus set
                         }} />

            <Input className='p-margin-top-5'
                   inputValue={labelInput}
                   placeHolder='Libellé'
                   enabled={{
                       updateInputValue: setLabelInput,
                       validateInput: () => validateMandatoryFieldError(labelInput)
                   }} />

            <div className='p-margin-top-4'>
                <span className='p-margin-right-4 p-bold'>IBAN</span>
                { ibanOptions.length === 1
                    ? <Input inputValue={selectedIban}
                             enabled={null} />
                    : <SingleSelect options={ibanOptions}
                                    selectedOption={ibanOptions.find((o) => o.value === selectedIban)!}
                                    selectOption={(newOption) => setSelectedIban(newOption!.value)}
                                    clearable={false} />
                }
            </div>

            <div className='p-margin-top-4 p-vertical-center'>
                { !!createParams.result
                    ? <ChargeInStripeEnabled axios={axios}
                                             companyName={companyName}
                                             params={createParams.result}
                                             closeModal={closeModal}
                                             kind={KIND_PRIMARY}
                                             size={SIZE_FIXED}
                                             text={ChargeText} />
                    : <ShowValidationErrorsButton validationErrors={createParams.errors!}
                                                  kind={KIND_PRIMARY}
                                                  size={SIZE_FIXED}
                                                  text={ChargeText} />
                }
            </div>
        </div>
    );
}

export default ChargeCustomerInStripeModalContent;
