import {IIntCompanyIdentifier, IntCompanyIdentifierTypeSiren } from "factor-lib/Company/IIntCompanyIdentifier";
import {
    ICompanyIdentifierWithCurrentLimitAndName,
    ICompanyIdentifierWithCurrentLimitAndNameAndOldLimit,
    intCompanyIdentifierKey
} from "./BuyerLimitsBatchUpdateLoaded";

const cifRegex = /^[a-zA-Z][0-9]{8}$/;

// https://balcellsgroup.com/cif-nif-tax-identification-numbers/
export const cifOrNif = (id: string) : string =>  !!id.match(cifRegex) ? 'CIF' : 'NIF';

const knownIdentifiers: Map<string, (id: string) => string> =  new Map<string, (id: string) => string>([
    ['FR', (_) => IntCompanyIdentifierTypeSiren],
    ['ES', (id) => cifOrNif(id)],
    ['NL', (_) => 'KVK'],
    ['DE', (_) => 'HR'],
    ['CH', (_) => 'CHRN'],
    ['BE', (_) => 'BERN']
]);

export const parseCartanAmount = (cartanAmount: string): number =>
    parseInt(cartanAmount.replace(/ /g, ''));

export const parseCsv = (inputText: string) : ICompanyIdentifierWithCurrentLimitAndName[] => {
    const splittedLines = inputText
                        .split(/\r\n|\n/)
                        .map((line) => line.split(';'))

    // Get resilient to column add as long as title remains the same (or to column renaming as long as indexes don't change thanks to previous initialization)
    // Current header :    
    //Police;N° National;Ref. Client;Raison Sociale;Denomination du contrat;CID;Note;C.P;Ville;Pays;Total limite;Devise;Date;Limite permanente;Expire le;Dépassement temporaire;Type limite temporaire;Expire le;Quotité assurée;Franchise acheteur;Délai maximum de paiement jours;Conditions particulières;Motif;Commentaires;Limite Primaire;Police;Courtier;Assuré
    
    let nationalIdentifierIndex = 1;
    let amountIndex = 10;
    let countryIndex = 9;
    let nameIndex = 3;

    const headerLine = splittedLines.find(split => split[0] === 'Police')!;

    for(let i = 0; i < headerLine.length; ++i) {
        const header = headerLine[i];
        switch (header) {
            case 'N° National':
                nationalIdentifierIndex = i;
                break;
            case 'Raison Sociale':
                nameIndex = i;
                break;
            case 'Pays':
                countryIndex = i;
                break;
            case 'Total limite':
            case 'Limite':
                amountIndex = i;
        }
    }

    return splittedLines
        .filter((split) => split.length >= amountIndex + 1 && !isNaN(parseCartanAmount(split[amountIndex])) && knownIdentifiers.has(split[countryIndex]))
        .map((split) => ({
            identifier: {
                type: knownIdentifiers.get(split[countryIndex])!(split[nationalIdentifierIndex]), //TODO: inexact, change according to country
                value: split[nationalIdentifierIndex]
            },
            limit: parseCartanAmount(split[amountIndex]) * 100,
            name: split[nameIndex]
        }));
}

export interface IBuyerIdentifierWithLimit {
    identifier: IIntCompanyIdentifier;
    manualOutstandingLimit: number; // Not null, as per the filter on the query
}

export const getDiff = (parsed: ICompanyIdentifierWithCurrentLimitAndName[], stored: IBuyerIdentifierWithLimit[]) : ICompanyIdentifierWithCurrentLimitAndNameAndOldLimit[] => {
    const mapOfStored: Map<string, number> = new Map(stored.map((object) => [intCompanyIdentifierKey(object.identifier), object.manualOutstandingLimit]));

    return parsed
        .filter((c) => mapOfStored.get(intCompanyIdentifierKey(c.identifier)) !== c.limit)
        .map((c) => ({
            ...c,
            currentLimit: mapOfStored.get(intCompanyIdentifierKey(c.identifier)) ?? null
        }));
}