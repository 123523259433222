import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import {Axios} from "axios";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { KIND_PRIMARY, SIZE_COMPACT } from "factor-lib/Buttons/Button";
import { useState } from "react";
import IAuthUser from "../../../../../../IAuthUser";
import { ISellerDetailsQueryResult, sellerDetailsQueryKey } from "../../../sellerDetailsQuery";
import { isValidString } from "factor-lib/forms/Inputs/utils";

const UpdateButtonText = 'Modifier';
const UpdateButtonKind = KIND_PRIMARY;
const UpdateButtonSize = SIZE_COMPACT;

const UpdateOpsNotesButtonEnabled = (
    {
        axios,
        sellerId,
        sellerOpsNotes
    } : {
        axios: Axios;
        sellerId: string;
        sellerOpsNotes: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateOpsNotesMutation: UseMutationResult<void, any, string, null> =
        useMutation<void, any, string, null>(
            async (sellerOpsNotes) => {
                await axios.put<void>(
                    `/adminSellers/${sellerId}/opsNotes`,
                    {
                        opsNotes: sellerOpsNotes
                    }
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old: ISellerDetailsQueryResult | undefined) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                opsNotes: sellerOpsNotes
                            }
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='update-ops-notes-button'
                               mutation={updateOpsNotesMutation}
                               value={sellerOpsNotes}
                               kind={UpdateButtonKind}
                               size={UpdateButtonSize}
                               displayFullError={true}
                               text={UpdateButtonText} />
    );
};

const OpsNotes = (
    {
        axios,
        authUserO,
        sellerId,
        sellerOpsNotes
    }: {
        axios: Axios;
        authUserO: IAuthUser | null;
        sellerId: string;
        sellerOpsNotes: string | null;
    }
) => {
    const [ sellerOpsNotesInput, setSellerOpsNotesInput ] = useState(sellerOpsNotes ?? '');
    const canUpdateOpsNotes: boolean = !!authUserO?.canManageProfiles;

    return (
        <div>
            <div className='p-horizontal-center'>
                <div className='p-bold'>Ops Notes</div>

                { canUpdateOpsNotes &&
                    <div className='p-margin-left-5'>
                        { (sellerOpsNotesInput === '' || isValidString(sellerOpsNotesInput)) &&
                            sellerOpsNotesInput !== (sellerOpsNotes ?? '')
                            ? <UpdateOpsNotesButtonEnabled axios={axios}
                                                           sellerId={sellerId}
                                                           sellerOpsNotes={sellerOpsNotesInput} />
                            : <ButtonDisabled kind={UpdateButtonKind}
                                              size={UpdateButtonSize}
                                              text={UpdateButtonText} />
                        }
                    </div>
                }
            </div>

            <textarea className='textarea p-margin-top-5'
                      placeholder='Notes'
                      value={sellerOpsNotesInput}
                      onChange={(e) => setSellerOpsNotesInput(e.target.value)}
                      disabled={!canUpdateOpsNotes}  />
        </div>
    )
};

export default OpsNotes;
