import {Axios} from "axios";
import {IInvoiceDetailsQueryResult, invoiceDetailsQueryKey} from "../../invoiceDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";

const SwitchReminderEmailsStatusEnabled = (
    {
        axios,
        invoiceId,
        financingId,
        reminderEmailsPaused,
        kind,
        size
    } : {
        axios: Axios;
        invoiceId: string;
        financingId: string;
        reminderEmailsPaused: boolean;
        kind?: string;
        size?: string;
    }
) => {

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const switchReminderEmailsStatusMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (financingId2) => {
                await axios.put<void>(
                    `/adminFinancings/${financingId2}/${reminderEmailsPaused ? 'un' : ''}pauseReminderEmails`
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                        invoiceDetailsQueryKey(invoiceId),
                        (old: IInvoiceDetailsQueryResult | undefined) => !!old ? ({
                            invoiceDetails: {
                                ...old.invoiceDetails,
                                status: {
                                    ...old.invoiceDetails.status,
                                    customerFinancingRequest: {
                                        ...old.invoiceDetails.status.customerFinancingRequest!,
                                        accepted: {
                                            ...old.invoiceDetails.status.customerFinancingRequest!.accepted!,
                                            financing: {
                                                ...old.invoiceDetails.status.customerFinancingRequest!.accepted!.financing!,
                                                reminderEmailsPaused: !reminderEmailsPaused
                                            }
                                        }
                                    }
                                }
                            },
                            buyerEvents: old!.buyerEvents
                        }) : undefined
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='switchInvoiceReminderEmailsStatus'
                               kind={kind}
                               size={size}
                               text={reminderEmailsPaused ? 'Unpause Reminder Emails' : 'Pause Reminder Emails'}
                               mutation={switchReminderEmailsStatusMutation}
                               displayFullError={true}
                               value={financingId} />
    );
}

export default SwitchReminderEmailsStatusEnabled;
