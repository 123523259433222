import { InvoicesTabsDefs } from "./InvoicesTabDefs";
import {ReactElement, useContext, useState} from "react";
import { NavigateFunction } from "react-router-dom";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { invoicesUrlTab } from "./invoicesUrls";

const InvoicesPageLayout = (
    {
        currentTabNameO,
        childFactory
    }: {
        currentTabNameO: string | null;
        childFactory: (searchStrFilter: string) => ReactElement;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    const [strFilter, setStrFilter] = useState('');
    return (
        <div className='p-vertical-stretch'>
            <div className='level'>
                <div className='level-left'>
                    <div className='tabs'>
                        <ul>
                            { InvoicesTabsDefs.map((t, tIndex) => (
                                <li key={`t-${tIndex}`}
                                    className={currentTabNameO === t.urlParam ? 'is-active' : ''}
                                    onClick={() => navigate(invoicesUrlTab(t.urlParam))}>
                                    <a>{ t.title }</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='level-right p-padding-right-4'>
                    <input className='p-search-box input'
                           type='text'
                           placeholder='Chercher'
                           value={strFilter}
                           onInput={(event: any/* FormEvent<HTMLInputElement> */) =>
                               setStrFilter((event.target as HTMLInputElement /* weird */).value)
                           }
                    />
                </div>
            </div>
            { childFactory(strFilter) }
            { /* Tabs.filter(t => t.tabName === selectedTab)[0].component(accessTokenO) */ }
        </div>
    );
}

export default InvoicesPageLayout;
