import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import {DimplOpsGraphQLFields, IDimplOps} from "../../../utils/IDimplOps";
import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import { IGraphQLParams} from "factor-lib/serverUtils/graphQLQueryAsync";

export const CustomerNamespace = 'customer';

export const customerDetailsQueryKey = (customerId: string) =>
    [CustomerNamespace, customerId, 'main'];

export interface ICustomerUser {
    azureObjectId: string;
}

export interface IMarketplace {
    id: string;
    outstandingLimitPerSeller: number;
    invoicePer10000ForSeller: number;
    hasCertifiedSellers: boolean;
    swanInstantPayment: boolean;
}

export const customerDetailsQuery = (customerId: string): IGraphQLParams => ({
    query: `query Q($customerId: Guid!) {
       customerDetails (id: $customerId) {
           contactEmail
           contactPhone
           billingIban
           stripeCustomerId
           company { id identifier { ${IntCompanyIdentifierGraphQLFields} } name }
           outstandingLimit
           minInvoiceAmount
           autoApproveFinancingRequests
           effectiveAccountManager { ${ DimplOpsGraphQLFields } }
           marketplace { id outstandingLimitPerSeller hasCertifiedSellers swanInstantPayment invoicePer10000ForSeller }
           directSeller { id seller { id iban certif { dateTime } } }
           users { azureObjectId }
           dimplWallet {
                virtualIban
           }
           newPricingRequiredDate
           newPricingAcceptedDate
       }
   }`,
    variables: {
        customerId
    }
});

export interface ICustomerDetails {
    contactEmail: string;
    contactPhone: string;
    billingIban: string | null;

    outstandingLimit: number;
    minInvoiceAmount: number;
    autoApproveFinancingRequests: boolean;
    stripeCustomerId: string | null;
    effectiveAccountManager: IDimplOps;

    company: {
        id: string;
        identifier: IIntCompanyIdentifier;
        name: string;
    };

    directSeller: {
        id: string;        
        seller: {
            id: string;
            iban: string;
            certif: {
                dateTime: string;
            } | null
        };
    } | null;

    marketplace: IMarketplace | null;

    users: ReadonlyArray<ICustomerUser>;

    dimplWallet: {
        virtualIban: string;
    } | null;

    newPricingRequiredDate: string | null;
    newPricingAcceptedDate: string | null;
}

export interface ICustomerDetailsQueryResult {
    customerDetails: ICustomerDetails;
}
