import IAccessToken from "../../IAccessToken";
import CompanyPage from "./CompanyPage";
import WithCompanyIdParamWrapper from "./WithCompanyIdParamWrapper";
import { Navigate } from "react-router-dom";
import { CompanyUrlMapping, companyUrl } from "./companiesUrls";
import CompanyTabs from "./CompanyTabs";
import IRouteDefinitionWithAxiosAndRolesO from "../../routerBuilder/IRouteDefinitionWithAxiosAndRolesO";

const CompanyRoute: IRouteDefinitionWithAxiosAndRolesO = ({
    path: CompanyUrlMapping,
    titleO: 'Companies',
    component: (accessTokenO: IAccessToken | null) =>
        <WithCompanyIdParamWrapper child={(companyId: string) =>
            <CompanyPage accessTokenO={accessTokenO}
                         companyId={companyId} />
        }/>,
    children: [
        // Default redirect
        {
            path: CompanyUrlMapping,
            component: () =>
                <WithCompanyIdParamWrapper child={(companyId) =>
                    <Navigate to={companyUrl(companyId)}/>
                }/>
        },
        ...CompanyTabs.map((p) => ({
            path: p.path,
            component: (accessTokenO: IAccessToken | null) =>
                <WithCompanyIdParamWrapper child={(companyId) =>
                    p.component(accessTokenO, companyId)
                }/>
        }))
    ]
});

export default CompanyRoute;