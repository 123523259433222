import { ICompanyQueryResult } from "./companiesQuery";
import { formatCompanyInput } from "factor-lib/Company/companyUtils";
import { comparingString } from "factor-lib/utils/comparingUtils";
import {NavigateFunction, useParams} from "react-router-dom";
import {useContext} from "react";
import {companyUrl, CompanyUrlCompanyIdParam} from "./companiesUrls";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";

const compareCompanyName =
    comparingString((company: ICompanyQueryResult) => company.name);

const CompaniesListLoaded = (
    {
        companies,
    }: {
        companies: Array<ICompanyQueryResult>;
    }
) => {

    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    // TODO : review, CompaniesList should be more autonomous, and accept less parameters
    const selectedCompanyIdId = useParams()[CompanyUrlCompanyIdParam]!; // This one should be directly in CompaniesList

    return (
        <div>
            { companies
                .sort(compareCompanyName)
                .map((company) =>
                    <div key={`company-${company.id}`}
                         className={`panel-block ${company.id === selectedCompanyIdId ? 'p-ok p-bold' : ' p-clickable'}`}
                         onClick={() => navigate(companyUrl(company.id))}
                         style={{ fontSize: '1rem' /* TODO */}}
                    >
                        { formatCompanyInput(company.identifier, company.name) }
                    </div>
                )
            }
        </div>
    );
}

export default CompaniesListLoaded;
