import { Axios } from "axios";
import ButtonMutationWithConfirmation from "factor-lib/Buttons/ButtonMutationWithConfirmationModal";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import {manualSettleChangeFinancingUpdateQueriesAsync} from "./manualSettleFinancingUtils";
import {IFinancing} from "../../../../utils/Financing/financingsQuery";

const ManualUnSettleFinancing = (
    {
        axios,
        invoiceId,
        financingId,
        financing,
        kind,
        size
    } : {
        axios: Axios;
        invoiceId: string;
        financingId: string;
        financing: IFinancing;
        kind?: string;
        size?: string;
    }
) => {

    const queryClient = useQueryClient();

    const unsettleMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (financingIdValue) =>
                (await axios.delete<void>(
                    `/adminFinancings/${financingIdValue}/settle`
                )).data,
            ({
                onSuccess:
                    () =>
                        manualSettleChangeFinancingUpdateQueriesAsync(
                            queryClient,
                            invoiceId,
                            financing.invoice.sellerId,
                            financing.invoice.buyer.companyId,
                            null,
                            (b) => [
                                ...b,
                                financing
                            ]
                        )
            })
        );

    return (
        <ButtonMutationWithConfirmation id='settleFinancing'
                                        kind={kind}
                                        size={size}
                                        text='Unsettle'
                                        confirmationText='Etes vous sûr de vouloir annuler le settle du financement ?'
                                        mutation={unsettleMutation}
                                        value={financingId} />
    );
}

export default ManualUnSettleFinancing;
