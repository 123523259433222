import IAccessToken from "../../IAccessToken";
import IAuthUserLoad from "../../IAuthUserLoad";
import SwanIncomingPaymentDetailsLoaded from "./SwanIncomingPaymentDetailsLoaded";
import Loader from "factor-lib/Loader";
import { ISwanIncomingPaymentDetailsQueryResult, swanIncomingPaymentDetailsQueryKey } from "./swanIncomingPaymentDetailsQuery";
import GraphQLWithAccessTokenWrapper from "../../GraphQLQueryWithAccessTokenWrapper";
import { invoiceWithStatusGraphQLQuery } from "../Invoices/invoiceStatus";
import { SwanIncomingPaymentGraphQLFields } from "../SwanIncomingPayments/swanIncomingPaymentsQuery";

const SwanIncomingPaymentDetailsPage = (
    {
        accessTokenO,
        swanIncomingPaymentId
    } : {
        accessTokenO: IAccessToken | null;
        swanIncomingPaymentId: string;
    }
) =>
    <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                   queryKey={swanIncomingPaymentDetailsQueryKey(swanIncomingPaymentId)}
                                   queryParams={{
                                       query: `query Q($swanIncomingPaymentId: String!) {
                                           swanIncomingPaymentDetails (id: $swanIncomingPaymentId) {
                                               ${SwanIncomingPaymentGraphQLFields}
                                           }
                                           invoices (swanIncomingPaymentId: $swanIncomingPaymentId) {
                                               base {
                                                   ${invoiceWithStatusGraphQLQuery(
                                                       `
                                                           id
                                                           number
                                                           amountWoTax
                                                           amountTax
                                                           dueDate
                                                           payment {
                                                               completePaidDate
                                                               partialAmountPaid
                                                           }
                                                           buyer {
                                                               companyId
                                                           }
                                                           sellerId
                                                       `,
                                                       '',
                                                       '',
                                                       '',
                                                       ''
                                                   )}
                                               }
                                               buyerCompanies { id name }
                                               sellers { id company { name } }
                                           }
                                           swanIncomingPaymentMatches (swanIncomingPaymentId: $swanIncomingPaymentId) {
                                               invoiceId
                                               amount
                                               isSentToCustomer 
                                               isComplete
                                           }
                                       }`,
                                       variables: {
                                           swanIncomingPaymentId
                                       }
                                   }}
                                   onLoading={() => <Loader />}
                                   onSuccess={(r: IAuthUserLoad<ISwanIncomingPaymentDetailsQueryResult>) =>
                                       <SwanIncomingPaymentDetailsLoaded axios={r.axios}
                                                                         authUser={r.authUserO}
                                                                         swanIncomingPayment={r.value.swanIncomingPaymentDetails}
                                                                         existingInvoiceMatches={r.value.swanIncomingPaymentMatches}
                                                                         existingMatchedInvoices={r.value.invoices.base}
                                                                         existingMatchedInvoicesBuyerCompanies={r.value.invoices.buyerCompanies}
                                                                         existingMatchedInvoicesSellers={r.value.invoices.sellers.map((s) => ({
                                                                             id: s.id,
                                                                             companyName: s.company.name
                                                                         }))}/>
                                   } />;

export default SwanIncomingPaymentDetailsPage;
