import EligibleOrPendingEligibleInvoicesTable, {
    FILTER_ELIGIBLE, FILTER_PENDING
} from "./EligibleOrPendingEligibleInvoicesTable";
import PendingPaymentsTable from "./PendingPayments/PendingPaymentsTable";
import IAccessToken from "../../IAccessToken";
import FinancingRequestsTab, {financingRequestsUrlParam} from "./FinancingRequestsTab";
import FinancingsTab, {financingsUrlParam} from "./FinancingsTab";
import {ReactElement} from "react";
import IneligibleInvoicesTable from "./IneligibleInvoicesTable";

interface IInvoicesTabDef {
    title: string;
    urlParam: string;
    component: (accessTokenO: IAccessToken | null, searchStrFilter: string) => ReactElement;
}

export const PendingEligibilitiesTabDef: IInvoicesTabDef = {
    title: 'Invoices Pending Eligibilities',
    urlParam: 'pendingEligibility',
    component: (accessTokenO: IAccessToken | null, searchStrFilter: string) =>
        <EligibleOrPendingEligibleInvoicesTable accessTokenO={accessTokenO}
                                                eligibilityFilter={FILTER_PENDING}
                                                searchStrFilter={searchStrFilter}/>
};

export const RecentIneligibleTabDef: IInvoicesTabDef = {
    title: 'Ineligibles -30j',
    urlParam: 'ineligibleRecentlyAdded',
    component: (accessTokenO: IAccessToken | null, searchStrFilter: string) =>
        <IneligibleInvoicesTable accessTokenO={accessTokenO}
                                 searchStrFilter={searchStrFilter}/>
};

export const EligibleTabDef: IInvoicesTabDef = {
    title: 'Invoices Eligible',
    urlParam: 'eligible',
    component: (accessTokenO: IAccessToken | null, searchStrFilter: string) =>
        <EligibleOrPendingEligibleInvoicesTable accessTokenO={accessTokenO}
                                                eligibilityFilter={FILTER_ELIGIBLE}
                                                searchStrFilter={searchStrFilter}/>
};

export const CustomerFinancingRequestsTabDef: IInvoicesTabDef = {
    title: 'Customer Financings Requests',
    urlParam: financingRequestsUrlParam,
    component: (accessTokenO: IAccessToken | null, searchStrFilter: string) =>
        <FinancingRequestsTab accessTokenO={accessTokenO}
                              searchStrFilter={searchStrFilter}/>
};

export const PendingPaymentsTabDef: IInvoicesTabDef = {
    title: 'Pending Payments',
    urlParam: 'pendingPayments',
    component: (accessTokenO: IAccessToken | null, searchStrFilter: string) =>
        <PendingPaymentsTable accessTokenO={accessTokenO}
                              searchStrFilter={searchStrFilter}/>
};

export const FinancingTrackingTabDef: IInvoicesTabDef = {
    title: 'Financings Tracking',
    urlParam: financingsUrlParam,
    component: (accessTokenO: IAccessToken | null, searchStrFilter: string) =>
        <FinancingsTab accessTokenO={accessTokenO}
                       searchStrFilter={searchStrFilter}/>
};

export const InvoicesTabsDefs: IInvoicesTabDef[] = [
    PendingEligibilitiesTabDef,
    RecentIneligibleTabDef,
    EligibleTabDef,
    CustomerFinancingRequestsTabDef,
    PendingPaymentsTabDef,
    FinancingTrackingTabDef
];
