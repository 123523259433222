import IAuthUser from "../../../../../../IAuthUser";
import { KIND_PRIMARY, SIZE_COMPACT } from "factor-lib/Buttons/Button";
import { PDF_OR_JPEG_OR_PNG_SET } from "factor-lib/forms/Upload/uploadUtils";
import {Axios} from "axios";
import { ISellerDetailsQueryResult, sellerDetailsQueryKey } from "../../../sellerDetailsQuery";
import IUrlOnly, { UrlGraphQLField } from "factor-lib/forms/Upload/IUrlOnly";
import { FileUploadTimeoutMilliseconds } from "factor-lib/serverUtils/axiosConfigUtils";
import SingleGraphQLFileVisualizerWithUpload from "../../../../../../utils/SingleGraphQLFileVisualizerWithUpload";

const uploadCustomDocumentFileAsync = async (
    axios: Axios,
    sellerId: string,
    file: File,
    onUploadProgress: (progressEvent: ProgressEvent) => void,
): Promise<void> => {
    const data = new FormData();
    data.append('customDocument', file);

    return await
        axios.put<void>(
            `adminSellerDocuments/${sellerId}/customDocument`,
            data,
            {
                onUploadProgress,
                timeout: FileUploadTimeoutMilliseconds
            }
        )
        .then(() =>
            window.alert('custom document file successfully uploaded')
        );
}

export const CustomDocument = (
    {
        axios,
        authUserO,
        sellerId,
        existingCustomDocumentFileName

    }: {
        axios: Axios;
        authUserO: IAuthUser | null;
        sellerId: string;
        existingCustomDocumentFileName: string | null;
    }
) =>
    <SingleGraphQLFileVisualizerWithUpload axios={axios}
                                           title='Custom Document'
                                           fetchId='fetchCustomDocumentFile'
                                           fetchText='Fetch'
                                           queryKey={['custom-document-file']}
                                           queryParams={{
                                               query: `query Q($sellerId: Guid!) {
                                                   sellerDetails (id: $sellerId) {
                                                       customDocument { ${UrlGraphQLField} }
                                                   }
                                               }`,
                                               variables: {
                                                   sellerId
                                               }
                                           }}
                                           visualizerId='viewCustomDocumentFile'
                                           visualizerText='View'
                                           buttonKind={KIND_PRIMARY}
                                           buttonSize={SIZE_COMPACT}
                                           existingFileName={existingCustomDocumentFileName}
                                           urlExtractor={(d: { sellerDetails: { customDocument: IUrlOnly; }; }) => d.sellerDetails.customDocument.url}
                                           upload={!!authUserO?.canManageFinancings ? {
                                               id: 'uploadCustomDocument',
                                               acceptedFileTypesSet: PDF_OR_JPEG_OR_PNG_SET,
                                               uploadFileAsync: (file: File, onUploadProgress: (progressEvent: ProgressEvent) => void) =>
                                                   uploadCustomDocumentFileAsync(axios, sellerId, file, onUploadProgress),
                                               updateQueries: (queryClient, file) =>
                                                   queryClient.setQueryData<ISellerDetailsQueryResult>(
                                                       sellerDetailsQueryKey(sellerId),
                                                       (old?: ISellerDetailsQueryResult) => ({
                                                           sellerDetails: {
                                                               ...old!.sellerDetails,
                                                               customDocument: {
                                                                   fileName: file.name
                                                               }
                                                           }
                                                       })
                                                   )
                                           } : null} />;
