import {Axios} from "axios";
import {IInvoiceDetailsQueryResult, invoiceDetailsQueryKey} from "../../invoiceDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import IOption from "factor-lib/forms/Select/IOption";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";

// maps to OpsTypeEnum
// TODO: duplicated with Predict-Admin, share
const opsTypes = [
    'A_TYPE',
    'AA_TYPE',
    'B_TYPE',
    'BB_TYPE',
    'C_TYPE',
    'CC_TYPE',
    'D_TYPE',
    'DD_TYPE'
];

export const opsTypeOptions: Array<IOption<string>> = opsTypes.map((t) => ({ label: t, value: t }));

export const getInitialOpsType = (value: string | null): IOption<string> | null => {
    if (!!value) {
        return opsTypeOptions.find(o => o.value === value) ?? null;
    }
    return null;
};


const updateOpsTypeActionAsync = async (axios: Axios, invoiceId: string, opsType: string): Promise<void> => {
    await axios.put(
        `/adminInvoices/${invoiceId}/opsType`,
        {
            opsType: opsType
        }
    );
}

export const UpdateOpsTypeEnabled = (
    {
        invoiceId,
        axios,
        opsType,
        kind,
        size,
        text
    } : {
        invoiceId: string;
        axios: Axios;
        opsType: string;
        kind: string;
        size: string;
        text: string;
    }
) => {

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateOpsTypeMutation: UseMutationResult<void, any, number | null> =
        useMutation<void, any, number | null>(
            () => updateOpsTypeActionAsync(axios, invoiceId, opsType),
            ({
                onSuccess: () => {
                    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                        invoiceDetailsQueryKey(invoiceId),
                        (old: IInvoiceDetailsQueryResult | undefined) => ({
                            invoiceDetails: {
                                ...old!.invoiceDetails,
                                opsType: opsType
                            },
                            buyerEvents: old!.buyerEvents
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateInvoiceOpsType'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateOpsTypeMutation}
                               displayFullError={true}
                               value={null} />
    );
}
