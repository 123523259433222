import { IBeneficiary } from "../../sellerDetailsQuery";
import BeneficiariesList from "./Beneficiaries/BeneficiariesList";
import IAuthUser from "../../../../../IAuthUser";
import { Axios } from "axios";
import { useState } from "react";
import BeneficiaryModal from "./Beneficiaries/BeneficiaryModal";
import Button from "factor-lib/Buttons/Button";

interface IShowModalProps {
    existingBeneficiaryO: IBeneficiary | null;
}

const BeneficiariesSection = (
    {
        axios,
        authUser,
        sellerId,
        directSellerId,
        beneficiaries
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        sellerId: string;
        directSellerId: string;
        beneficiaries: IBeneficiary[];
    }
) => {
    const [showBeneficiaryModal, setShowBeneficiaryModal] = useState<IShowModalProps | null>(null);

    return (
        <div>
            { beneficiaries.length === 0
                ? !!authUser?.canManageProfiles
                    ? <Button id='showAddCompanyFirstBeneficiary'
                              text='Ajouter'
                              isLoading={false}
                              actionO={() => setShowBeneficiaryModal({existingBeneficiaryO: null})} />

                    : `Aucun bénéficiaire n'a été ajouté`
                : <BeneficiariesList axios={axios}
                                     authUser={authUser}
                                     sellerId={sellerId}
                                     directSellerId={directSellerId}
                                     beneficiaries={beneficiaries}
                                     showAddBeneficiary={() => setShowBeneficiaryModal({existingBeneficiaryO: null})}
                                     showUpdateBeneficiary={(beneficiary: IBeneficiary) => setShowBeneficiaryModal({existingBeneficiaryO: beneficiary})}/>
            }

            { !!showBeneficiaryModal &&
                <BeneficiaryModal axios={axios}
                                  sellerId={sellerId}
                                  directSellerId={directSellerId}
                                  beneficiary={showBeneficiaryModal.existingBeneficiaryO}
                                  closeModal={() => setShowBeneficiaryModal(null)} />
            }
        </div>
    );
}

export default BeneficiariesSection;
