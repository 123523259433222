
// TODO : move to lib at some point
import IBaseInvoice from "../IInvoice";

export const getAmountToFinance = (amountWoTax: number, amountTax: number, partialAmountPaid: number) =>
    amountWoTax + amountTax - partialAmountPaid;

export const getInvoiceAmountToFinance = (invoice: IBaseInvoice & {
    payment: {
        partialAmountPaid: number;
    }
}) =>
    getAmountToFinance(invoice.amountWoTax, invoice.amountTax, invoice.payment.partialAmountPaid);
