import {useState} from "react";
import IOption from "factor-lib/forms/Select/IOption";
import MultipleSelect from "factor-lib/forms/Select/MultipleSelect";
import ButtonMutation from 'factor-lib/Buttons/ButtonMutation';
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {Axios} from "axios";
import Link from "factor-lib/Link";
import {openInNewTabWoOpener} from "../../../../utils/SingleGraphQLFileVisualizer";
import OkCancelRow from "factor-lib/Modals/OkCancelRow";
import {computeText} from 'factor-lib/Buttons/ButtonMutationEnabled';
import {
    IInvoiceDetailsQueryResult,
    invoiceDetailsQueryKey
} from "../../../Invoice/invoiceDetailsQuery";
import {buyerCompanyDetailsQueryKey, IBuyerCompanyDetailsQueryResult} from "../buyerCompanyDetailsQuery";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";

interface IGenerateDocsRequest {
    sellerId: string;
    buyerCompanyId: string;
    financingsIds: string[];
}

interface ISendMailRequest {
    fileId: string;
    sellerId: string;
    buyerCompanyId: string;
    financingsIds: string[];
    type: number;
}

interface INewMailDocument {
    id: string;
    financingsIds: string[]; // To make sure user cannot change them in between document generation
}

const BuyerMerciFacteurMailFinancingsSelectorLoaded = (
    {
        axios,
        generateDocUrl,
        mailLabel,
        mailType,
        closeModal,
        buyerCompanyId,
        sellerId,
        financings
    }: {
        axios: Axios;
        generateDocUrl: string;
        mailLabel: string;
        mailType: number;
        closeModal: () => void;
        buyerCompanyId: string;
        sellerId: string;
        financings: Array<{
            id: string;
            invoice: {
                number: string;
                amountWoTax: number;
                amountTax: number;
                id: string;
            }
        }>;
    }
) => {

    const queryClient = useQueryClient();

    const [selecteds, setSelecteds] = useState<ReadonlyArray<IOption<string>>>([]);

    const [newMailDoc, setNewMailDoc] = useState<INewMailDocument | null>(
        null

        // tests in local
        // {
        //     id: '1kd0kUsECE1M8DgvPc1ILLXDSop_udxkCZk67wztsbYI',
        //     financingsIds: [
        //         '6dcf093f-0710-4de5-be91-188a7716434f',
        //         '8f23a3f1-396a-4f10-a5f5-5ab2a0e6b4d4'
        //     ]
        // }
    );

    const generateDocMutation: UseMutationResult<string, any, IGenerateDocsRequest> =
        useMutation<string, any, IGenerateDocsRequest>(
            async (createContactsRequest) =>
                (await axios.post<string>(
                    `adminMerciFacteurMail/${/* 'generate-doc' */ generateDocUrl}`,
                    createContactsRequest
                )).data
            ,
            ({
                onSuccess: (newDocumentId: string, params: IGenerateDocsRequest) => {
                    setNewMailDoc({
                        id: newDocumentId,
                        financingsIds: params.financingsIds
                    })
                }
            })
        );

    const sendLRarMutation: UseMutationResult<number, any, ISendMailRequest> =
        useMutation<number, any, ISendMailRequest>(
            async (createContactsRequest) =>
                (await axios.post<number>(
                    'adminMerciFacteurMail/send-mail',
                    createContactsRequest
                )).data
            ,
            ({
                onSuccess: (fileId: number) => {

                    for (const financing of financings) {
                        queryClient.setQueriesData<IInvoiceDetailsQueryResult>(
                            invoiceDetailsQueryKey(financing.invoice.id),
                            (old?: IInvoiceDetailsQueryResult) => !!old
                                ? ({
                                    ...old,
                                    invoiceDetails: {
                                        ...old.invoiceDetails,
                                        status: {
                                            ...old.invoiceDetails.status,
                                            customerFinancingRequest: !!old.invoiceDetails.status.customerFinancingRequest ? {
                                                ...old.invoiceDetails.status.customerFinancingRequest,
                                                accepted: !!old.invoiceDetails.status.customerFinancingRequest.accepted ? {
                                                    ...old.invoiceDetails.status.customerFinancingRequest.accepted,
                                                    financing: !!old.invoiceDetails.status.customerFinancingRequest.accepted.financing ? {
                                                        ...old.invoiceDetails.status.customerFinancingRequest.accepted.financing,
                                                        merciFacteurMails: [
                                                            ...old.invoiceDetails.status.customerFinancingRequest.accepted.financing.merciFacteurMails,
                                                            {
                                                                creationDateTime: serverDateSerialization(new Date()),
                                                                receivedDateTime: null,
                                                                dimplOps: {
                                                                    name: 'toi' // TODO?
                                                                },
                                                                type: mailType
                                                            }
                                                        ]
                                                    } : null
                                                } : null
                                            } : null
                                        }
                                    }
                                }) : undefined
                        );
                    }

                    // Need to refresh, complicated to update
                    // const p = queryClient.invalidateQueries(buyerCompanyDetailsQueryKey(buyerCompanyId));

                    queryClient.setQueriesData<IBuyerCompanyDetailsQueryResult>(
                        buyerCompanyDetailsQueryKey(buyerCompanyId),
                        (old?: IBuyerCompanyDetailsQueryResult) =>
                            !!old ? ({
                                ...old,
                                companyDetails: {
                                    ...old.companyDetails,
                                    asBuyer: {
                                        ...old.companyDetails.asBuyer,
                                        merciFacteurMails: [
                                            ...old.companyDetails.asBuyer.merciFacteurMails,
                                            {
                                                id: fileId,
                                                creationDateTime: serverDateSerialization(new Date()),
                                                receivedDateTime: null,
                                                dimplOps: {
                                                    name: 'toi', // TODO ?
                                                },
                                                type: mailType
                                            }
                                        ],
                                        financingsMerciFacteurMails: [
                                            ...old.companyDetails.asBuyer.financingsMerciFacteurMails,
                                            ...selecteds.map((f) => {
                                                const invoice = financings.find((fi) => fi.id === f.value)!.invoice;
                                                return ({
                                                    merciFacteurMail: {
                                                        id: fileId,
                                                    },
                                                    financing: {
                                                        invoice: {
                                                            id: invoice.id,
                                                            number: invoice.number,
                                                            amountWoTax: invoice.amountWoTax,
                                                            amountTax: invoice.amountTax
                                                        }
                                                    }
                                                });
                                            })
                                        ]
                                    }
                                }
                            }) : undefined
                    );

                    closeModal();

                    // return p;
                }
            })
        );

    return (
        <div>
            <MultipleSelect className='field'
                            options={financings.map((f) => ({
                                value: f.id,
                                label: f.invoice.number
                            }))}
                            selectedOptions={selecteds}
                            setSelectedOptions={setSelecteds}
                            placeholder='Factures' />
            { !!newMailDoc
                ? <Link text='Visualiser'
                        action={{
                            clickHandler: () => openInNewTabWoOpener(`https://docs.google.com/document/d/${newMailDoc.id}`)
                        }}/>
                : <ButtonMutation<string, IGenerateDocsRequest> className='field'
                                                                id='generateDocs'
                                                                text='Générer doc'
                                                                mutation={generateDocMutation}
                                                                displayFullError={true}
                                                                valueO={(selecteds.length > 0) ? ({
                                                                    value: {
                                                                        buyerCompanyId,
                                                                        sellerId,
                                                                        financingsIds: selecteds.map((s) => s.value)
                                                                    }
                                                                }) : null }/>

            }

            <OkCancelRow className='p-margin-top-6'
                         okIdButton='selectMatchId'
                         okText={computeText('Envoyer LRAR', sendLRarMutation, true)}
                         cancel={closeModal}
                         isLoading={sendLRarMutation.isLoading}
                         validate={!!newMailDoc
                             ? () => {
                                if (window.confirm(`Confirmez l'envoi de ${/* subrogation*/ mailLabel} ?`))
                                 {
                                     sendLRarMutation.mutate({
                                         fileId: newMailDoc.id,
                                         buyerCompanyId,
                                         sellerId,
                                         financingsIds: newMailDoc.financingsIds,
                                         type: mailType
                                     });
                                 }
                             }
                             : null
                         } />

        </div>
    );
}

export default BuyerMerciFacteurMailFinancingsSelectorLoaded;