import {useState} from "react";
import {ICustomer} from "./CustomerYearMonthSelectionLayout";
import {formatDateInput} from 'factor-lib/forms/DateInput/DateInput';
import {Axios} from "axios";
import IYearMonth from "../IYearMonth";
import IOption from "factor-lib/forms/Select/IOption";
import NewBillingPostCustomerYearMonthSelectionLayout, {
    IValidParam
} from "./NewBillingPostCustomerYearMonthSelectionLayout";
import NewBillingPostCustomerYearMonthSelectionAxios from "./NewBillingPostCustomerYearMonthSelectionAxios";

export const MinThresholdDefaultValue = 1.5;
export const DailyRateDefaultValue = 0.05;

const NewBillingPostCustomerYearMonthSelection = (
    {
        axiosO,
        selectedMonth,
        selectMonth,
        selectedCustomer,
        selectCustomer
    }: {
        axiosO: Axios | null;
        selectedMonth: IOption<IYearMonth>;
        selectMonth: (newMonth: IOption<IYearMonth> | null) => void;
        selectedCustomer: IOption<ICustomer>;
        selectCustomer: (newCustomer: IOption<ICustomer> | null) => void;
    }
) => {
    const [minThresholdInput, setMinThresholdInput] = useState<string>(MinThresholdDefaultValue.toString());
    const [dailyRateInput, setDailyRateInput] = useState<string>(DailyRateDefaultValue.toString());
    const m = selectedMonth.value;
    const defaultDate = new Date(m.year, m.month - 1 /* the javascript index */ + 1 /* First on following month */, 1);
    const [dateInput, setDateInput] = useState<string>(formatDateInput(defaultDate));
    const [validParam, updateValidParam] = useState<IValidParam>({
        minThreshold: MinThresholdDefaultValue,
        dailyRateInPercent: DailyRateDefaultValue,
        date: defaultDate
    });

    return (
        !!axiosO
            ? <NewBillingPostCustomerYearMonthSelectionAxios axios={axiosO}
                                                             selectedMonth={selectedMonth}
                                                             selectMonth={selectMonth}
                                                             selectedCustomer={selectedCustomer}
                                                             selectCustomer={selectCustomer}
                                                             minThresholdInput={minThresholdInput}
                                                             setMinThresholdInput={setMinThresholdInput}
                                                             dailyRateInput={dailyRateInput}
                                                             setDailyRateInput={setDailyRateInput}
                                                             dateInput={dateInput}
                                                             setDateInput={setDateInput}
                                                             validParam={validParam}
                                                             updateValidParam={updateValidParam}/>
            : <NewBillingPostCustomerYearMonthSelectionLayout axiosO={axiosO}
                                                              selectedMonth={selectedMonth}
                                                              selectMonth={selectMonth}
                                                              selectedCustomer={selectedCustomer}
                                                              selectCustomer={selectCustomer}
                                                              pennyLaneCustomerQueryResultO={null}
                                                              minThresholdInput={minThresholdInput}
                                                              setMinThresholdInput={setMinThresholdInput}
                                                              dailyRateInput={dailyRateInput}
                                                              setDailyRateInput={setDailyRateInput}
                                                              dateInput={dateInput}
                                                              setDateInput={setDateInput}
                                                              validParam={validParam}
                                                              updateValidParam={updateValidParam}/>
    )
}

export default NewBillingPostCustomerYearMonthSelection;