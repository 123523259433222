import CompanyIdentification from "./CompanyIdentification";
import DataBilanContent, { bilanTypeToLabel } from "./DataBilanContent";
import { TextOnlyModalContent } from 'factor-lib/Modals/TextOnlyModal';
import ButtonMutationForModal from 'factor-lib/Buttons/ButtonForModal/ButtonMutationForModal';
import {Axios} from "axios";
import ILiasseInput from "./ILiasseInput";
import CurrentEnv from "../../../envs/CurrentEnv";
import Auth2AxiosCreatorWrapper from "../../../Auth2AxiosCreatorWrapper";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import Loader from "factor-lib/Loader";
import formatNewBilanDateCloture from "./formatNewBilanDateCloture";
import parseLiasse from "./parseLiasse";
import bilanEndPoint from "./bilanEndPoint";
import {IBilansDescriptions} from "../IBilanDescription";
import {bilansQueryKey} from "../bilansQuery";
import IBilanMainInput from "./IBilanMainInput";
import IBilan, {ILiasse} from "./IBilan";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import {existingBilanQueryKey} from "./existingBilanQuery";
import {useContext} from "react";
import {NavigateFunction} from "react-router-dom";
import {DataCompanySearchUrl} from "../dataUrls";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { promiseWrapper } from "factor-lib/utils/utils";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";

const newBilanType = (bilanMainInputO: IBilanMainInput) =>
    bilanTypeToLabel.get(bilanMainInputO.typeBilanInput)!.toLowerCase();

// Model from server

const SaveEnabled = (
    {
        dataAxios,
        siren,

        existingBilanO,
        bilanMainInputO,

        // liasseInput,
        liasse
    }: {
        dataAxios: Axios;
        siren: string;

        existingBilanO: IBilan | null;
        bilanMainInputO: IBilanMainInput | null;

        // liasseInput: string;
        liasse: ILiasse;
    }
) => {
    const queryClient = useQueryClient();
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const saveMutation: UseMutationResult<void, any, null> =
        useMutation<void, any, null>(
            async () => {
                const body: {
                    siren: string;
                    bilan: IBilan;
                } = {
                    siren,
                    bilan: {
                        dateCloture: !!bilanMainInputO ? formatNewBilanDateCloture(bilanMainInputO.dateClotureInput) : existingBilanO!.dateCloture,
                        bilanType: !!bilanMainInputO ? newBilanType(bilanMainInputO) : existingBilanO!.bilanType,
                        dureeExercice: !!bilanMainInputO ? parseInt(bilanMainInputO.dureeExerciceInput) : existingBilanO!.dureeExercice,
                        confidentialite: !existingBilanO ? null : existingBilanO.confidentialite,
                        liasse: liasse // parseLiasse(liasseInput)
                    }
                };
                await dataAxios.post<void>(
                    `${bilanEndPoint}/set`,
                    body
                );
            },
            ({
                onSuccess: () => {
                    if (!existingBilanO) {
                        // Add new bilan
                        queryClient.setQueryData<IBilansDescriptions>(
                            bilansQueryKey(siren),
                            (old: IBilansDescriptions | undefined) =>
                                !!old
                                    ? (
                                        [
                                            ...old,
                                            {
                                                bilanType: newBilanType(bilanMainInputO!),
                                                confidentialite: null,
                                                dateCloture: formatNewBilanDateCloture(bilanMainInputO!.dateClotureInput),
                                                proprietaryLiasse: true /* Si ! */
                                            }
                                        ]
                                    ) : undefined
                        );
                    }

                    if (!!existingBilanO) {
                        queryClient.setQueryData<IBilan>(
                            existingBilanQueryKey(siren, existingBilanO.dateCloture, existingBilanO.bilanType),
                            (old: IBilan | undefined) => {
                                return !!old
                                    ? ({
                                        ...old,
                                        liasse
                                    }) : undefined;
                            }
                        );
                    }
                    // setShowConfirmationModal(true);
                }
            })
        );

    const ok = () => navigate(DataCompanySearchUrl);

    return (
        <ButtonMutationForModal id='saveBilan'
                                text='Enregistrer'
                                mutation={saveMutation}
                                value={null}
                                displayFullError={true}
                                onClose={ok}
                                resultModalNode={() =>
                                    <TextOnlyModalContent message='Bilan enregistré'
                                                          buttonsProps={{
                                                              id: 'saveBilanOk',
                                                              text: 'Ok',
                                                              isLoading: false,
                                                              actionO: promiseWrapper(ok)
                                                          }}/>
        }/>
    );
}

const SaveLoaded = (
    {
        dataAxios,
        siren,

        existingBilanO,
        bilanMainInputO,

        liasseInput,
    }: {
        dataAxios: Axios;
        siren: string;

        existingBilanO: IBilan | null;
        bilanMainInputO: IBilanMainInput | null;

        liasseInput: string;
    }
) => {
    // TOTDO : Review
    const liasseO = parseLiasse(liasseInput)
    return (
        !!liasseO
            ? <SaveEnabled dataAxios={dataAxios}
                           siren={siren}
                           existingBilanO={existingBilanO}
                           bilanMainInputO={bilanMainInputO}
                           liasse={liasseO}/>
            : <ButtonDisabled kind={KIND_PRIMARY}
                              size={SIZE_FIXED}
                              text='Enregistrer' />
    );
}

const DataBilanLayout = (
    {
        axiosO,
        dataAxiosO,
        siren,
        editTitle,

        bilanMainInputO,
        existingBilanO,

        liasseInputO
    }: {
        axiosO: Axios | null;
        dataAxiosO: Axios | null;
        siren: string;
        editTitle: string;

        // One or the other
        bilanMainInputO: IBilanMainInput | null;
        existingBilanO: IBilan | null;

        liasseInputO: ILiasseInput | null; // null -> loading
    }
) =>
    <div>
        <div className='p-padding-left-4 p-padding-right-4 p-padding-bottom-4'>
            <div className='level'>
                <div className='level-left'>
                    <div>
                        <CompanyIdentification axiosO={axiosO}
                                               siren={siren}/>
                        <div className='title'>
                            { editTitle }
                        </div>
                    </div>
                </div>
                <div className='level-right'>
                    { dataAxiosO
                        ? !!liasseInputO
                            ? <SaveLoaded dataAxios={dataAxiosO}
                                          siren={siren}
                                          bilanMainInputO={bilanMainInputO}
                                          existingBilanO={existingBilanO}
                                          liasseInput={liasseInputO.liasseInput} />
                           : <Loader />
                        : <Auth2AxiosCreatorWrapper backend={CurrentEnv.dataBackend}
                                                    child={(dataAxiosO: Axios | null) =>
                                                    ((!!dataAxiosO && !!liasseInputO)
                                                        ? <SaveLoaded dataAxios={dataAxiosO}
                                                                    siren={siren}
                                                                    bilanMainInputO={bilanMainInputO}
                                                                    existingBilanO={existingBilanO}
                                                                    liasseInput={liasseInputO.liasseInput} />

                                                        : <Loader />
                                                    ) || <Loader/> }/>

                    }
                </div>
            </div>
        </div>
        <div className='p-horizontal-divider'/>
        <div className='p-padding-4'>
            <DataBilanContent existingBilanO={existingBilanO}
                              bilanMainInputO={bilanMainInputO}
                              liasseInputO={liasseInputO}  />
        </div>
    </div>;

export default DataBilanLayout;
