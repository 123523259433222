
export const MFMTSubrogation = 1;
export const MFMTMiseEnDemeure = 2;

const merciFacteurMailTypeLabelMap = new Map<number, string>([
    [MFMTSubrogation, 'Subrogation'],
    [MFMTMiseEnDemeure, 'Mise en demeure']
]);

const throwUnknown = (type: number): string => {
    throw new Error(`Unknown type ${type}`);
}

export const merciFacteurMailTypeLabel = (type: number): string =>
    merciFacteurMailTypeLabelMap.get(type) ?? throwUnknown(type);