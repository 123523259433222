import {Axios} from "axios";
import DimplScoreLoader from "./DimplScoreLoader";

const DimplScoreInfos = (
    {
        loadedClassName,
        axios,
        companyId
    }: {
        loadedClassName?: string;
        axios: Axios;
        companyId: string;
    }
) =>
    <DimplScoreLoader axios={axios}
                      companyId={companyId}
                      loadedClassName={loadedClassName} />

export default DimplScoreInfos;
