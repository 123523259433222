import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";

export const buyersQueryKey = ['buyers'];
export const buyersWithInvoiceQueryKey = ['buyersWithInvoice'];

export interface IBuyerCompany {
    id: string;
    name: string;
    identifier: IIntCompanyIdentifier;
    asBuyer:  {
        isBlocked: boolean;
    };
}

export interface IBuyerCompaniesQueryResult {
    companies: Array<IBuyerCompany>;
}
