import {IInvoiceDetailsQueryResult, invoiceDetailsQueryKey, ISettledO} from "../../invoiceDetailsQuery";
import {
    ActiveFinancingsQueryKeyPrefix,
    IFinancing,
    IFinancingsQueryResult
} from "../../../../utils/Financing/financingsQuery";
import {sellerSettledFinancingsQueryKey} from "../../../Sellers/SellerTabs";
import {buyerSettledFinancingsQueryKey} from "../../../Buyers/BuyerTabs";
import {QueryClient} from "@tanstack/react-query";

export const manualSettleChangeFinancingUpdateQueriesAsync = (
    queryClient: QueryClient,
    invoiceId: string,
    sellerId: string,
    buyerCompanyId: string,
    newManualStatus: ISettledO,
    updateFinancing: (prev: IFinancing[]) => IFinancing[]
) => {
    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
        invoiceDetailsQueryKey(invoiceId),
        (old) => !!old ? ({
            ...old,
            invoiceDetails: {
                ...old.invoiceDetails,
                status: {
                    ...old.invoiceDetails.status,
                    customerFinancingRequest: {
                        ...old.invoiceDetails.status.customerFinancingRequest!,
                        accepted: {
                            ...old.invoiceDetails.status.customerFinancingRequest!.accepted!,
                            financing: {
                                ...old.invoiceDetails.status.customerFinancingRequest!.accepted!.financing!,
                                settled: newManualStatus
                            }
                        }
                    }
                }
            },
        }) : undefined
    );

    // update active financings
    queryClient.setQueriesData<IFinancingsQueryResult>(
        ActiveFinancingsQueryKeyPrefix,
        (old) => !!old ? ({
            financings: {
                ...old.financings,
                base: updateFinancing(old.financings.base)
            }
        }) : undefined
    );

    // force refetch of settled financings
    return Promise.all([
        queryClient.invalidateQueries(sellerSettledFinancingsQueryKey(sellerId)),
        queryClient.invalidateQueries(buyerSettledFinancingsQueryKey(buyerCompanyId))
    ]);
}