import { Axios} from "axios";
import { IInvoiceDetailsQueryResult, invoiceDetailsQueryKey } from "../../../invoiceDetailsQuery";
import { ButtonKind, ButtonSize } from "../CustomerFinancingRequestButtonProps";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { GlobalOutstandingsQueryKey } from "../../../../Misc/GlobalOutstandings/globalOutstandingsQuery";
import ButtonMutationWithConfirmationModal from "factor-lib/Buttons/ButtonMutationWithConfirmationModal";
import { AcceptedFinancingRequestsQueryKey } from "../../../../Invoices/PendingPayments/PendingPaymentsTable";
import { IAccepted, ICustomerFinancingRequest, ICustomerFinancingRequestsResponse, NewFinancingRequestsQueryKeyPrefix } from "../../../../../utils/FinancingRequest/FinancingRequestsQuery";

const ResetCustomerFinancingRequestEnabled = (
    {
        className,
        axios,
        invoiceId,
        customerFinancingRequest,
        isAccepted
    } : {
        className?: string;
        axios: Axios;
        invoiceId: string;
        customerFinancingRequest: ICustomerFinancingRequest;
        isAccepted: boolean; // else rejected
    }
) => {

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const resetCustomerFinancingRequestMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (customerFinancingRequestId) => {
                await axios.put<void>(
                    `/adminCustomerFinancingRequests/${customerFinancingRequestId}/reset`,
                    null
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                        invoiceDetailsQueryKey(invoiceId),
                        (old: IInvoiceDetailsQueryResult | undefined) => !!old ? ({
                            ...old,
                            invoiceDetails: {
                                ...old.invoiceDetails,
                                status: {
                                    ...old.invoiceDetails.status,
                                    customerFinancingRequest: {
                                        ...old.invoiceDetails.status.customerFinancingRequest!, // can't be null
                                        accepted: null,
                                        rejectedReason: null,
                                        rejectedDate: null
                                    }
                                }
                            }
                        }) : undefined
                    );

                    if (isAccepted) {
                        // remove from pending payments
                        queryClient.setQueriesData<ICustomerFinancingRequestsResponse<IAccepted>>(
                            AcceptedFinancingRequestsQueryKey,
                            old => !!old ? {
                                ...old,
                                financingRequests: {
                                    ...old.financingRequests,
                                    base: old.financingRequests.base.filter((f) => f.id !== customerFinancingRequest.id)
                                }
                            } : undefined
                        );
                    }

                    // add to new financing requests (all, seller, buyer)
                    // TODO: need to add seller and buyer, seller requires swaninstantpayment field which we do not have, just invalide for now
                    // queryClient.setQueriesData<ICustomerFinancingRequestsResponse<null>>(
                    //     NewFinancingRequestsQueryKeyPrefix,
                    //     old => !!old ? {
                    //         ...old,
                    //         financingRequests: {
                    //             ...old.financingRequests,
                    //             base: [
                    //                 ...old.financingRequests.base,
                    //                 {
                    //                     ...customerFinancingRequest,
                    //                     accepted: null
                    //                 }
                    //             ],
                    //             sellers: []
                    //         }
                    //     } : undefined
                    // );

                    return Promise.all([
                        queryClient.invalidateQueries(NewFinancingRequestsQueryKeyPrefix),
                        queryClient.invalidateQueries(GlobalOutstandingsQueryKey)
                    ]);
                }
            })
        );

    const resetActionName = isAccepted ? 'validation' : 'rejection';

    return (
        <ButtonMutationWithConfirmationModal className={className}
                                             id='resetCustomerFinancingRequestButtonWithConfirmation'
                                             kind={ButtonKind}
                                             size={ButtonSize}
                                             text={`Annuler la ${resetActionName}`}
                                             confirmationText={`Etes vous sûr de vouloir annuler la ${resetActionName} de cette demande de financement ?`}
                                             mutation={resetCustomerFinancingRequestMutation}
                                             value={customerFinancingRequest.id} />

    );
}

export default ResetCustomerFinancingRequestEnabled;
