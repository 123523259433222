import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { ISellerDetailsQueryResult, ISellerUbbleIdentification, sellerDetailsQueryKey } from "../../sellerDetailsQuery";
import { Axios} from "axios";
import IAuthUser from "../../../../../IAuthUser";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { formatDateAndTimeAdmin } from "../../../../../utils/dateTimeUtils";
import { SIZE_COMPACT, KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { useState } from "react";
import Link from "factor-lib/Link";
import {serverDateDeserialization, serverDateSerialization, comparingDate } from "factor-lib/utils/dateUtils";
import { comparingRev } from "factor-lib/utils/comparingUtils";

interface IUbbleIdentification {
    id: string;
    link: string;
    status: string;
    statusUpdatedAt: string;
    score: number | null;
}

const SellerCreateUbbleIdentificationEnabled = (
    {
        className,
        axios,
        sellerId,
        kind,
        size,
        text
    }: {
        className?: string;
        axios: Axios;
        sellerId: string;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const createUbbleIdentificationMutation: UseMutationResult<IUbbleIdentification, any, string> =
        useMutation<IUbbleIdentification, any, string>(
            async (sellerId2: string) => (
                await axios.post<IUbbleIdentification>(
                    `/adminSellersUbble/${sellerId2}/`
                ))
                .data,
            ({
                onSuccess: (response) => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old?: ISellerDetailsQueryResult) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                ubbleIdentifications: [
                                    ...old!.sellerDetails.ubbleIdentifications,
                                    {
                                        creationDateTime: serverDateSerialization(new Date()),
                                        ubbleId: response.id,
                                        link: response.link,
                                        status: response.status,
                                        statusUpdatedAt: response.statusUpdatedAt,
                                        score: response.score
                                    }
                                ]
                            }
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled className={className}
                               id='createSellerUbbleIdentification'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={createUbbleIdentificationMutation}
                               value={sellerId}
                               displayFullError={true}/>
    )
};

const SellerRefreshUbbleIdentificationsEnabled = (
    {
        className,
        axios,
        sellerId,
        kind,
        size,
        text
    }: {
        className?: string;
        axios: Axios;
        sellerId: string;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const refreshUbbleIdentificationsMutation: UseMutationResult<IUbbleIdentification[], any, string> =
        useMutation<IUbbleIdentification[], any, string>(
            async (sellerId2: string) => (
                await axios.put<IUbbleIdentification[]>(
                    `/adminSellersUbble/${sellerId2}/refresh`
                ))
                .data,
            ({
                onSuccess: (updatedIdentifications) => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old?: ISellerDetailsQueryResult) => {
                            const updatedIdentificationIds = new Map<string, IUbbleIdentification>(updatedIdentifications.map((i) => [i.id, i]));
                            return ({
                                sellerDetails: {
                                    ...old!.sellerDetails,
                                    ubbleIdentifications: [
                                        ...old!.sellerDetails.ubbleIdentifications.filter((i) => !updatedIdentificationIds.has(i.ubbleId)),
                                        ...old!.sellerDetails.ubbleIdentifications.filter((i) => updatedIdentificationIds.has(i.ubbleId))
                                            .map((i) => {
                                                const updated = updatedIdentificationIds.get(i.ubbleId)!;
                                                return ({
                                                    creationDateTime: i.creationDateTime,
                                                    ubbleId: i.ubbleId,
                                                    link: i.link, // cannot be changed
                                                    status: updated.status,
                                                    statusUpdatedAt: updated.statusUpdatedAt,
                                                    score: updated.score
                                                });
                                            })
                                    ]
                                }
                            });
                        }
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled className={className}
                               id='refreshSellerUbbleIdentifications'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={refreshUbbleIdentificationsMutation}
                               value={sellerId}
                               displayFullError={true}/>
    )
};

const compareUbbleIdentification = comparingRev(
    comparingDate((i: ISellerUbbleIdentification) => serverDateDeserialization(i.creationDateTime))
);

const SellerUbbleUserLink = (
    {
        identificationUserLink 
    }: {
        identificationUserLink: string;
    }
) => {
    const [displayLink, setDisplayLink] = useState(false);

    return (
        <div>
            { !displayLink
                ? <Link id='displayUbbleUserLink'
                        text='Afficher'
                        action={{
                            clickHandler: () => setDisplayLink(true)
                        }} />
                : <textarea className='textarea'
                            readOnly
                            rows={1}
                            value={identificationUserLink} />
            }
        </div>
    );
}

const getUbbleProcessedStatus = (score: number | null): string =>
    score === 1
        ? 'valid'
        : score === 0
            ? 'invalid'
            : 'unknown';

const getUbbleStatus = (status: string, score: number | null) => {
    const statusLowerCase = status.toLowerCase();
    return `${statusLowerCase}${statusLowerCase === 'processed' ? ` (${getUbbleProcessedStatus(score)})` : ''}`;
};

const SellerUbbleSection = (
    {
        axios,
        authUserO,
        sellerId,
        identifications
    } : {
        axios: Axios;
        authUserO: IAuthUser | null;
        sellerId: string;
        identifications: ISellerUbbleIdentification[];
    }
) =>
    <div>
        { !!authUserO?.canManageProfiles &&
            <div className='level'>
                <div className='level-right'>
                    <SellerCreateUbbleIdentificationEnabled className='level-item'
                                                            axios={axios}
                                                            sellerId={sellerId}
                                                            kind={KIND_PRIMARY}
                                                            size={identifications.length === 0 ? SIZE_FIXED : SIZE_COMPACT}
                                                            text='Créer' />

                    { identifications.length > 0 &&
                        <SellerRefreshUbbleIdentificationsEnabled className='level-item'
                                                                  axios={axios}
                                                                  sellerId={sellerId}
                                                                  kind={KIND_PRIMARY}
                                                                  size={SIZE_COMPACT}
                                                                  text='Rafraîchir' />

                    }
                </div>
            </div>
        }

        {  identifications.length > 0 &&
            <table className='table is-narrow'>
                <thead>
                    <tr>
                        <th>Lien dashboard</th>
                        <th>Statut</th>
                        <th>Date du statut</th>
                        <th>Lien pour utilisateur</th>
                    </tr>
                </thead>

                <tbody>
                    { identifications
                        .sort(compareUbbleIdentification)
                        .map((i) =>
                            <tr key={i.ubbleId}>
                                <td>
                                    { /* for now prod and sandbox accounts have the same dashboard links */}
                                    <a href={`https://dashboard.ubble.ai/identifications/${i.ubbleId}`} target="_blank" rel="noreferrer">{ i.ubbleId }</a>
                                </td>
                                <td>
                                    { getUbbleStatus(i.status, i.score) }
                                </td>
                                <td>
                                    { formatDateAndTimeAdmin(serverDateDeserialization(i.statusUpdatedAt)) }
                                </td>
                                <td>
                                    <SellerUbbleUserLink identificationUserLink={i.link} />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        }
    </div>;

export default SellerUbbleSection;
