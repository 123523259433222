import { Axios } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import {
    allBuyersWithLimitQueryKey, buyersWithLimitSearch,
    IBuyerCompaniesWithLimitQueryResult,
    IBuyerCompanyWithLimit
} from "./BuyerLimitsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import {getDiff, IBuyerIdentifierWithLimit, parseCsv} from "./BuyerLimitsBatchUploadedUtils";
import { formatIntCompanyIdentifier, IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import { IUpdateBuyerLimitResponse } from "./BuyerLimitsModal";
import { formatAmountWoCents } from 'factor-lib/utils/amountUtils';

export interface ICompanyIdentifierWithCurrentLimitAndNameAndOldLimit extends ICompanyIdentifierWithCurrentLimitAndName {
    currentLimit: number | null;
}

export interface ICompanyIdentifierWithCurrentLimitAndName extends ICompanyIdentifierWithCurrentLimit {
    name: string;
}

interface ICompanyIdentifierWithCurrentLimit {
    identifier: IIntCompanyIdentifier;
    limit: number;
}

// TODO: shared ? use class and override hashcode/equals ?
export const intCompanyIdentifierKey = (identifier: IIntCompanyIdentifier): string =>
    `${identifier.type.toLocaleLowerCase()}_${identifier.value}`;

const BatchUpdateLimitsEnabled = (
    {
        axios,
        updateParams,
        closeModal,
        setNonUpdatedCompanies,
        displayErrors,
        text
    }: {
        axios: Axios;
        updateParams: ICompanyIdentifierWithCurrentLimitAndName[];
        closeModal: () => void;
        setNonUpdatedCompanies: (companies: ICompanyIdentifierWithCurrentLimitAndName[]) => void;
        displayErrors: (invalidIdentifiers: IIntCompanyIdentifier[]) => void;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateBatchLimitMutation: UseMutationResult<IUpdateBuyerLimitResponse[], any, ICompanyIdentifierWithCurrentLimit[]> =
        useMutation<IUpdateBuyerLimitResponse[], any, ICompanyIdentifierWithCurrentLimit[]>(
            async (updateParams2) =>
                (await axios.put<IUpdateBuyerLimitResponse[]>(
                    `/adminCompaniesOutstandingsLimitsAsBuyer/batch`,
                    {
                        updates: updateParams2.map(u => ({
                            buyerIdentifier: u.identifier,
                            buyerLimit: u.limit
                        }))
                    }
                )).data,
            ({
                onSuccess: (companyIdentifiersWithOutstanding) => {
                    const r = queryClient.invalidateQueries(buyersWithLimitSearch);
                    queryClient.setQueryData<IBuyerCompaniesWithLimitQueryResult>(
                        allBuyersWithLimitQueryKey,
                        (old: IBuyerCompaniesWithLimitQueryResult | undefined) => {
                            const mapOfIdentifierWithOutstanding = new Map<string, IUpdateBuyerLimitResponse>(
                                companyIdentifiersWithOutstanding.map((x) => [intCompanyIdentifierKey(x.identifier), x])
                            );

                            const mapOfIdentifierWithKnownCompanies = new Map<string, IBuyerCompanyWithLimit>(
                                old!.companies
                                    .filter((c) => mapOfIdentifierWithOutstanding.has(intCompanyIdentifierKey(c.identifier)))
                                    .map((x) => [intCompanyIdentifierKey(x.identifier), x])
                            );
                            
                            const nonUpdatedCompanies = updateParams
                                .filter(u => !mapOfIdentifierWithOutstanding.has(intCompanyIdentifierKey(u.identifier)));

                            if(nonUpdatedCompanies.length > 0) {
                                setNonUpdatedCompanies(nonUpdatedCompanies);
                            }

                            return ({
                                companies: [
                                    ...old!.companies.filter((c) => !mapOfIdentifierWithOutstanding.has(intCompanyIdentifierKey(c.identifier))),
                                    // Put updated companies at the top
                                    ...updateParams
                                        .filter(u => mapOfIdentifierWithOutstanding.has(intCompanyIdentifierKey(u.identifier)))
                                        .map((u) => ({
                                            companySirenWithCurrentLimitAndName: u,
                                            resultSuccess: mapOfIdentifierWithOutstanding.get(intCompanyIdentifierKey(u.identifier))!.successBuyerInfosO
                                        }))
                                        .filter((u) => !!u.resultSuccess)
                                        .map((u) => {
                                            const identifier = u.companySirenWithCurrentLimitAndName.identifier;
                                            const resultSuccess = u.resultSuccess!;
                                            return ({
                                                name: mapOfIdentifierWithKnownCompanies.get(intCompanyIdentifierKey(identifier))?.name ?? u.companySirenWithCurrentLimitAndName.name,
                                                identifier: identifier,
                                                id: resultSuccess.id,
                                                asBuyer: {
                                                    manualOutstandingLimit: u.companySirenWithCurrentLimitAndName.limit,
                                                    outstanding: resultSuccess.outstanding
                                                }
                                            });
                                        })
                                ]
                            });
                        }
                    );

                    closeModal();

                    const invalidIdentifiers: IIntCompanyIdentifier[] =
                        companyIdentifiersWithOutstanding
                            .filter((u) => !u.successBuyerInfosO)
                            .map((u) => u.identifier);

                    if (invalidIdentifiers.length > 0) {
                        displayErrors(invalidIdentifiers);
                    }

                    return r;
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateBatchLimitButton'
                               text={text}
                               mutation={updateBatchLimitMutation}
                               displayFullError={true}
                               value={updateParams} />
    );
}

const BuyerLimitsBatchUpdateLoaded = (
    {
        axios,
        closeModal,
        setNonUpdatedCompanies,
        displayErrors,
        storedBuyerCompanies,
        readerResult
    } : {
        axios: Axios;
        storedBuyerCompanies: IBuyerIdentifierWithLimit[]
        closeModal: () => void;
        setNonUpdatedCompanies: (companies: ICompanyIdentifierWithCurrentLimitAndName[]) => void;
        displayErrors: (invalidIdentifiers: IIntCompanyIdentifier[]) => void;
        readerResult: string;
    }
) => {
    const parsedCompanies = parseCsv(readerResult);
    
    const diff = getDiff(parsedCompanies, storedBuyerCompanies);

    return (
        <div>
            Changes
            { diff.length > 0
                ? <div>
                    <table className='table is-striped is-bordered is-hoverable'>
                        <thead>
                            <tr>
                                <th>nom</th>
                                <th>siren</th>
                                <th>Ancienne limite</th>
                                <th>Nouvelle limite</th>
                            </tr>
                        </thead>
                        <tbody>
                            { diff.map((c, index) =>
                                <tr key={`buyer-company-${index}`}>
                                    <td style={{verticalAlign: 'middle'}}>
                                        { c.name }
                                    </td>
                                    <td style={{verticalAlign: 'middle'}}>
                                        { formatIntCompanyIdentifier(c.identifier) }
                                    </td>
                                    <td>
                                        { c.currentLimit !== null ? formatAmountWoCents(c.currentLimit) : 'none' }
                                    </td>
                                    <td>
                                        { formatAmountWoCents(c.limit) }
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    <div>
                        <BatchUpdateLimitsEnabled axios={axios}
                                                  updateParams={diff}
                                                  closeModal={closeModal}
                                                  setNonUpdatedCompanies={setNonUpdatedCompanies}
                                                  displayErrors={displayErrors}
                                                  text='Modifier' />
                    </div>
                </div>
                : <div>None</div>
            }
        </div>
    );
}

export default BuyerLimitsBatchUpdateLoaded;
