import { Axios } from "axios";
import { IInvoiceDetailsQueryResult, invoiceDetailsQueryKey } from "../../../invoiceDetailsQuery";
import DeclarePayment from 'factor-lib/DeclarePayment/DeclarePayment';
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import {
    getPaymentAmount,
    isPaymentNotReporting,
    PAYMENT_TYPE_COMMING_LING,
    PAYMENT_TYPE_PAYMENT_ON_RECUP_BY_SELLER
} from "../../../../../utils/paymentUtils";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import { GlobalOutstandingsQueryKey } from "../../../../Misc/GlobalOutstandings/globalOutstandingsQuery";

interface IDeclareDirectRecuperationParams {
    date: Date;
    type: number;
    partialAmount: number | null;
}

interface IDeclareDirectRecuperationResponse {
    invoicePaymentId: string;
}

const DeclareDirectRecuperation = (
    {
        axios,
        invoiceId,
        invoiceAmountTTC,
        partialAmountPaid,
        directRecuperationType,
        extraAmountValidationMessageOProvider,
        closeModal
    }: {
        axios: Axios;
        invoiceId: string;
        invoiceAmountTTC: number;
        partialAmountPaid: number;
        directRecuperationType: number;
        extraAmountValidationMessageOProvider: (partialAmountInput: number | null) => string | null;
        closeModal: () => void;
    }
) => {
    const queryClient = useQueryClient();

    const declarePaymentInvoiceMutation: UseMutationResult<IDeclareDirectRecuperationResponse, any, IDeclareDirectRecuperationParams> =
        useMutation<IDeclareDirectRecuperationResponse, any, IDeclareDirectRecuperationParams>(
            async (params) =>
                (await axios.post<IDeclareDirectRecuperationResponse>(
                    `/adminInvoices/${invoiceId}/recuperation`,
                    params
                )).data,
            ({
                onSuccess: (response, params) => {
                    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                        invoiceDetailsQueryKey(invoiceId),
                        (old) => {
                            if (!old) {
                                return undefined;
                            }

                            const { invoiceDetails } = old;
                            const paymentAmount: number = getPaymentAmount(params.partialAmount, invoiceAmountTTC, partialAmountPaid);
                            const isNotReporting = isPaymentNotReporting(directRecuperationType);

                            return ({
                                ...old,
                                invoiceDetails: {
                                    ...invoiceDetails,
                                    payment: {
                                        ...invoiceDetails.payment,
                                        completePaidDate: isNotReporting && params.partialAmount === null
                                            ? serverDateSerialization(params.date)
                                            : null,
                                        partialAmountPaid: partialAmountPaid + (isNotReporting ? paymentAmount : 0),
                                        partialAmountPaidByBuyer: (!isNotReporting || directRecuperationType === PAYMENT_TYPE_COMMING_LING)
                                            ? invoiceDetails.payment.partialAmountPaidByBuyer + paymentAmount
                                            : invoiceDetails.payment.partialAmountPaidByBuyer,
                                        payments: [
                                            ...invoiceDetails.payment.payments,
                                            {
                                                id: response.invoicePaymentId,
                                                creationDateTime: serverDateSerialization(new Date() /* TODO: params.date? */),
                                                amount: paymentAmount,
                                                isComplete: params.partialAmount === null,
                                                base: {
                                                    type: directRecuperationType,
                                                    specificPaymentId: null
                                                }
                                            }
                                        ]
                                    },
                                    status: !!invoiceDetails.status.customerFinancingRequest?.accepted?.financing
                                        ? {
                                            ...invoiceDetails.status,
                                            customerFinancingRequest: {
                                                ...invoiceDetails.status.customerFinancingRequest,
                                                accepted: {
                                                    ...invoiceDetails.status.customerFinancingRequest.accepted,
                                                    financing: {
                                                        ...invoiceDetails.status.customerFinancingRequest.accepted.financing,
                                                        commingLingAmountToReturn: invoiceDetails.status.customerFinancingRequest.accepted.financing.commingLingAmountToReturn
                                                            + (
                                                                directRecuperationType === PAYMENT_TYPE_COMMING_LING
                                                                    ? paymentAmount
                                                                    : directRecuperationType === PAYMENT_TYPE_PAYMENT_ON_RECUP_BY_SELLER
                                                                        // TODO: can become negative, will be handled in a coming ticket, where we will not allow this type of payment to exceed the commingling amount to return
                                                                        ? -paymentAmount
                                                                        : 0
                                                            )
                                                    }
                                                }
                                            }
                                        }
                                        : invoiceDetails.status
                                }
                            });
                        }
                    );

                    // TODO: should update the invoice payment status in invoices/financingRequests/financings tabs

                    const r = queryClient.invalidateQueries(GlobalOutstandingsQueryKey);

                    closeModal();

                    return r;
                },
                onError: (e) => {
                    window.alert(e.response.data);
                    closeModal();
                }
            })
        );

    return (
        <DeclarePayment invoiceAmountTTC={invoiceAmountTTC}
                        partiallyAlreadyPaidByBuyer={partialAmountPaid /* TODO : confusing for the comming ling, not the same as customer front */}
                        amountRemainingDueByBuyer={invoiceAmountTTC - partialAmountPaid /* TODO : confusing for the comming ling, not the same as customer front */}
                        extraAmountValidationMessageOProvider={extraAmountValidationMessageOProvider}
                        ok={(date: Date, partialAmount: number | null) =>
                            declarePaymentInvoiceMutation.mutate({
                                date,
                                type: directRecuperationType,
                                partialAmount
                            })
                        }
                        isLoading={declarePaymentInvoiceMutation.isLoading}
                        close={closeModal} />

    )
}

export default DeclareDirectRecuperation;
