import Link from 'factor-lib/Link';
import {Axios} from "axios";
import {useEffect, useState} from "react";
import GraphQLQueryWrapper from "../../../utils/GraphQLQueryWrapper";
import Loader from 'factor-lib/Loader';
import {openInNewTabWoOpener} from "../../../utils/SingleGraphQLFileVisualizer";
import {ILinkAction} from "factor-lib/Link";
import {ReactQueryNoNeedRefresh} from "factor-lib/reactquery/utils";

const LinkInt = (
    {
        action
    }: {
        action: ILinkAction
    }
) =>
    <Link text='Voir'
          action={action} />

const Url = (
    {
        url
    }:
    {
        url: string
    }
) => {

    useEffect(() => {
        openInNewTabWoOpener(url);
    }, [url]);

    return (
        <LinkInt action={{
            clickHandler: () => openInNewTabWoOpener(url)
        }}/>
    );
}


const PennyLaneLinkSelected = (
    {
        axios,
        billingId
    }: {
        axios: Axios;
        billingId: string;
    }
) =>
    <GraphQLQueryWrapper axios={axios}
                         queryKey={['billing-url', billingId]}
                         queryParams={{
                             query: `
                                query ($billingId: Guid!) {
                                    billingDetails (id: $billingId) {
                                        url
                                    }
                                }
                            `,
                             variables: {
                                 billingId
                             }
                         }}
                         onLoading={() => <Loader /> /* Pourrait etre ameliore */}
                         onSuccess={(r: { billingDetails: {url: string } }) =>
                             <Url url={r.billingDetails.url}/>
                         }
                         options={ReactQueryNoNeedRefresh}/>

const PennyLaneLink = (
    {
        axios,
        billingId
    }: {
        axios: Axios;
        billingId: string;
    }
) => {
    const [selected, select] = useState(false);
    return (
        selected
            ? <PennyLaneLinkSelected axios={axios}
                                     billingId={billingId}/>
            : <LinkInt action={{
                clickHandler: () => {
                    select(true);
                }
            }}/>
    );
}

export default PennyLaneLink;