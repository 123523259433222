import IAuthUser from "../../../../../IAuthUser";
import { formatAdminDateStr } from "../../../../../utils/dateTimeUtils";
import IPerson from "../../../../../utils/Person/IPerson";
import { Axios } from "axios";
import { formatAddress } from "../../../../../utils/IAddress";
import { ReactElement, useState } from "react";
import Link from "factor-lib/Link";
import Button from 'factor-lib/Buttons/Button';
import {formatBirthLocation} from "../../../../../utils/IBirthLocation";
import CompanyOwnerModal from "./CompanyOwner/CompanyOwnerModal";

const CompanyOwnerInfosSection = (
    {
        axios,
        authUser,
        sellerId,
        addCompanyOwnerUrlSuffix,
        companyOwner,
        isBirthInfosMandatory,
        addAsBeneficiaryNode
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        sellerId: string;
        addCompanyOwnerUrlSuffix: string;
        companyOwner: IPerson | null;
        isBirthInfosMandatory: boolean;
        addAsBeneficiaryNode: ReactElement | null;
    }
) => {

    const [showCompanyOwnerModal, setShowCompanyOwnerModal] = useState(false);

    return (
        <div>
            { !!companyOwner
                ? <table className='table is-narrow'>
                    <tbody>
                    { !!authUser?.canManageProfiles &&
                        <tr>
                            <td/>
                            <td>
                                <Link id='showUpdateCompanyOwnerModal'
                                      text='Modifier'
                                      action={{
                                          clickHandler: () => setShowCompanyOwnerModal(true)
                                      }} />
                                { addAsBeneficiaryNode }
                            </td>
                        </tr>
                    }
                    <tr>
                        <td className='p-bold'>Prénom</td>
                        <td>{ companyOwner.firstName }</td>
                    </tr>
                    <tr>
                        <td className='p-bold'>Nom</td>
                        <td>{ companyOwner.lastName }</td>
                    </tr>
                    <tr>
                        <td className='p-bold'>Nationalité</td>
                        <td>{ companyOwner.nationalityCode ?? '<<none>>' }</td>
                    </tr>
                    <tr>
                        <td className='p-bold'>Date de naissance</td>
                        <td>{ !!companyOwner.birthDate ? formatAdminDateStr(companyOwner.birthDate) : '<<Non renseigné>>' }</td>
                    </tr>
                    <tr>
                        <td className='p-bold'>Lieu de naissance</td>
                        <td>{ formatBirthLocation(companyOwner.birthLocation) }</td>
                    </tr>
                    <tr>
                        <td className='p-bold p-padding-right-1'>Adresse</td>
                        <td>{ !!companyOwner.residenceAddress ? formatAddress(companyOwner.residenceAddress) : '<<none>>' }</td>
                    </tr>
                    </tbody>
                </table> : <Button id='showAddCompanyOwnerModal'
                                   text='Ajouter'
                                   isLoading={false}
                                   actionO={() => setShowCompanyOwnerModal(true)} />

            }

            { showCompanyOwnerModal &&
                <CompanyOwnerModal axios={axios}
                                   sellerId={sellerId}
                                   addCompanyOwnerUrlSuffix={addCompanyOwnerUrlSuffix}
                                   companyOwner={companyOwner}
                                   isBirthInfosMandatory={isBirthInfosMandatory}
                                   closeModal={() => setShowCompanyOwnerModal(false) } />
            }
        </div>
    );
}

export default CompanyOwnerInfosSection;
