import {Axios} from "axios";
import {IPreInvoiceDetails} from "../PreInvoiceDetailsQuery";
import {addInvoiceAsync, IAddInvoiceResponse, IInvoiceAddResponseO} from "../../../utils/AddInvoice/addInvoiceUtils";
import {QueryClient, useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {useState} from "react";
import {rejectedState} from "../../PreInvoices/preInvoicesQuery";
import InvoiceAlreadyExistModal from "../../../utils/AddInvoice/InvoiceAlreadyExistModal";
import { ICompanySelection } from "factor-lib/Company/ICompanySelection";
import updatePreInvoicesQueries from "./updatePreInvoicesQueries";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";

export interface IApprovePreInvoiceParams {
    sellerId: string;

    buyerSelection: ICompanySelection;
    buyerEmail: string;
    buyerPhone: string;

    invoiceNumber: string;
    invoiceIssueDate: Date;
    invoiceDueDate: Date;
    invoiceAmountWoTax: number;
    invoiceAmountTax: number;
}

const ApprovePreInvoiceButtonEnabled = (
    {
        axios,
        preInvoice,
        params,
        setResult,
        preInvoiceId,

        kind,
        size,
        text
    }: {
        axios: Axios,
        preInvoice: IPreInvoiceDetails;
        params: IApprovePreInvoiceParams;
        setResult: (result: IAddInvoiceResponse) => void,
        preInvoiceId: string;

        kind?: string;
        size?: string;
        text: string;
    }
) => {
    const queryClient: QueryClient = useQueryClient();
    const [displayExistingInvoice, setDisplayExistingInvoice] = useState<string | null>(null);

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const addAndRequestFinancingMutation: UseMutationResult<IInvoiceAddResponseO, any, IApprovePreInvoiceParams> =
        useMutation<IInvoiceAddResponseO, any, IApprovePreInvoiceParams>(
            async (params2) => await addInvoiceAsync<IApprovePreInvoiceParams>(
                axios,
                `/adminPreInvoices/${preInvoiceId}/approve`,
                params2,
                undefined
            ),
            ({
                onSuccess: (result: IInvoiceAddResponseO) => {
                    const successO = result.nonDuplicateO;
                    if (!!successO) {
                        updatePreInvoicesQueries(queryClient, preInvoiceId, preInvoice, rejectedState);
                        setResult(successO);
                    } else {
                        setDisplayExistingInvoice(result.existingInvoiceIdO!);
                    }
                }
            })
        );

    return (
        <>
            <ButtonMutationEnabled id='approvePreInvoiceButton'
                                   kind={kind}
                                   size={size}
                                   text={text}
                                   mutation={addAndRequestFinancingMutation}
                                   displayFullError={true}
                                   value={params} />
            { displayExistingInvoice &&
                <InvoiceAlreadyExistModal invoiceId={displayExistingInvoice}
                                          closeModal={() => setDisplayExistingInvoice(null)}/>
            }
        </>
    );
}

export default ApprovePreInvoiceButtonEnabled;