import { Axios } from "axios";
import {useContext, useState} from "react";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import Input from "factor-lib/forms/Inputs/Input";
import {CustomersQueryKeyPrefix} from "./customersQuery";
import { ResultOrErrors } from "../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../utils/ShowValidationErrorsButton";
import Modal from "factor-lib/Modal";
import Button from "factor-lib/Buttons/Button";
import {NavigateFunction} from "react-router-dom";
import RadioBox from "factor-lib/forms/Radio/RadioBox";
import IAccessToken from "../../IAccessToken";
import Loader from "factor-lib/Loader";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { customerUrl } from "./customersUrls";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import CompanySelector from "../../utils/Company/CompanySelector";
import { ICompanySelection } from "factor-lib/Company/ICompanySelection";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { isValidPhone, validatePhoneError } from "factor-lib/forms/Inputs/phoneUtils";
import {isValidEmail, validateEmailError } from "factor-lib/forms/Inputs/emailUtils";
import {isValidIban, validateIbanError } from "factor-lib/forms/Inputs/ibanUtils";

const AddText = 'Ajouter';

interface IAddCustomerParams {
    companySelection: ICompanySelection;
    billingIban: string | null;
    contactEmail: string;
    contactPhone : string;
    isDirect: boolean;
}

interface IAddCustomerResponse {
    customerId: string;
    marketplaceId: string | null;
    companyName: string;
    companyIdentifier: IIntCompanyIdentifier;
}

const AddCustomerEnabled = (
    {
        axios,
        addParams,
        setAddResponse,
        kind,
        size,
        text
    } : {
        axios: Axios;
        addParams: IAddCustomerParams;
        setAddResponse: (addResponse: IAddCustomerResponse) => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const addCustomerMutation: UseMutationResult<IAddCustomerResponse, any, IAddCustomerParams, null> =
        useMutation<IAddCustomerResponse, any, IAddCustomerParams, null>(
            async (addParams2) =>
                (await axios.post<IAddCustomerResponse>(
                    `/adminCustomers`,
                    addParams2
                )).data,
            ({
                onSuccess: (addResponse) => {
                    const p = queryClient.invalidateQueries(CustomersQueryKeyPrefix);
                    setAddResponse(addResponse);
                    return p;
                }
            })
        );

    return (
        <ButtonMutationEnabled<IAddCustomerResponse, IAddCustomerParams> id='addCustomerButton'
                                                                         kind={kind}
                                                                         size={size}
                                                                         text={text}
                                                                         mutation={addCustomerMutation}
                                                                         displayFullError={true}
                                                                         value={addParams} />

    );
}


const getCustomerAddParams = (
    companySelection: ICompanySelection | null,
    billingIbanInput: string,
    contactEmailInput: string,
    contactPhoneInput: string,
    isDirect: boolean
): ResultOrErrors<IAddCustomerParams> => {

    const validationErrors: string[] = [];

    if (!companySelection) {
        validationErrors.push(`La société de la plateforme n'est pas choisi`);
    }

    const billingIbanInputNullable = billingIbanInput !== '' ? billingIbanInput : null;
    if (billingIbanInputNullable !== null && !isValidIban(billingIbanInputNullable)) {
        validationErrors.push(`Le billing IBAN est invalide`);
    }

    if (!isValidEmail(contactEmailInput)) {
        validationErrors.push(`L'email est invalide`);
    }

    if (!isValidPhone(contactPhoneInput)) {
        validationErrors.push('Le téléphone est invalide');
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    return ResultOrErrors.fromResult({
        companySelection: companySelection!,
        billingIban: billingIbanInputNullable,
        contactEmail: contactEmailInput,
        contactPhone: contactPhoneInput,
        isDirect: isDirect
    });
}

const AddCustomer = (
    {
        accessTokenO
    }: {
        accessTokenO: IAccessToken | null;
    }
) => {
    const [companySearchInput, setCompanySearchInput] = useState('');
    const [companySelection, setCompanySelection] = useState<ICompanySelection | null>(null);

    const [ibanInput, setIbanInput] = useState('');
    const [contactEmailInput, setContactEmailInput] = useState('');
    const [contactPhoneInput, setContactPhoneInput] = useState('');
    const [isDirect, setIsDirect] = useState(true);

    const clearForm = () => {
        setCompanySearchInput('');
        setCompanySelection(null);
        setIbanInput('');
        setContactEmailInput('');
        setContactPhoneInput('');
        setIsDirect(true);
    };

    const addParams: ResultOrErrors<IAddCustomerParams> =
        getCustomerAddParams(
            companySelection,
            ibanInput,
            contactEmailInput,
            contactPhoneInput,
            isDirect
        );

    const [addResponse, setAddResponse] = useState<IAddCustomerResponse | null>(null);

    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    return (
        <div className='container p-both-center p-margin-top-3'>
            <div style={{width: 600}}>
                <span className='title p-both-center'>Ajouter une plateforme</span>

                <div className='box'>
                    <span className='p-bold p-size-4 p-both-center p-margin-bottom-4'>Type de la plateforme</span>
                    <div className='level'>
                        <div className='level-item p-horizontal-center'>
                            <RadioBox id='customerDirectRadioBox'
                                      inputValue={isDirect}
                                      setInputValue={() => setIsDirect(true)}
                                      enabled={true} />
                                       
                            <span className='p-margin-left-6'>Direct</span>
                        </div>
                        <div className='level-item p-horizontal-center'>
                            <RadioBox id='customerMarketplaceRadioBox'
                                      inputValue={!isDirect}
                                      setInputValue={() => setIsDirect(false)}
                                      enabled={true} />
                            <span className='p-margin-left-6'>Marketplace</span>
                        </div>
                    </div>
                </div>

                <div className='box'>
                    <span className='p-bold p-size-4 p-both-center p-margin-bottom-4'>Infos de la société</span>
                    <div className='p-margin-bottom-5'>
                        { !!accessTokenO
                            ? <CompanySelector axios={accessTokenO.axios}
                                               searchPlaceHolder='Nom ou SIREN de la plateforme'
                                               searchInput={companySearchInput}
                                               setSearchInput={setCompanySearchInput}
                                               initialDisplaySearch={true}
                                               selection={companySelection}
                                               setSelection={setCompanySelection}
                                               autofocus={true} />
                            : <Loader />
                        }
                    </div>

                    <div>
                        <span className='p-bold'>IBAN du billing (optionel)</span>
                        <Input inputValue={ibanInput}
                               enabled={{
                                   updateInputValue: setIbanInput,
                                   validateInput: () => validateIbanError(ibanInput)
                               }} />
                    </div>
                </div>
                <div className='box'>
                    <span className='p-bold p-size-4 p-both-center p-margin-bottom-4'>Infos de contact</span>
                    <div className='p-padding-bottom-5'>
                        <span className='p-bold'>Email</span>
                        <Input inputValue={contactEmailInput}
                               enabled={{
                                   updateInputValue: setContactEmailInput,
                                   validateInput: () => validateEmailError(contactEmailInput)
                               }} />

                    </div>
                    <div>
                        <span className='p-bold'>Téléphone</span>
                        <Input inputValue={contactPhoneInput}
                               enabled={{
                                   updateInputValue: setContactPhoneInput,
                                   validateInput: () => validatePhoneError(contactPhoneInput)
                               }} />
                    </div>
                </div>

                <div className='p-margin-3 p-vertical-center'>
                    { !!accessTokenO
                        ? !!addParams.result
                            ? <AddCustomerEnabled axios={accessTokenO.axios}
                                                  addParams={addParams.result}
                                                  setAddResponse={setAddResponse}
                                                  kind={KIND_PRIMARY}
                                                  size={SIZE_FIXED}
                                                  text={AddText} />
                            : <ShowValidationErrorsButton validationErrors={addParams.errors!}
                                                          kind={KIND_PRIMARY}
                                                          size={SIZE_FIXED}
                                                          text={AddText} />
                        : <Loader />
                    }
                </div>

                { !!addResponse &&
                    <Modal id='addCustomerResponseModal'
                           maxWidth={null}
                           fullMaxWidth={false}
                           close={() => {
                               setAddResponse(null);
                               clearForm();
                           }}>
                        <div className='p-padding-4 p-vertical-center'>
                            <span>La plateforme <span className='p-bold'>{addResponse.companyName}</span> a bien été ajoutée</span>
                            <div className='columns p-margin-top-5'>
                                <div className='column'>
                                    <Button id='gotoAddedCustomerDetailsButton'
                                            text='Détails de la plateforme'
                                            isLoading={false}
                                            actionO={() => navigate(customerUrl(addResponse.customerId))} />
                                </div>
                                <div className='column'>
                                    <Button id='addAnotherCustomerButton'
                                            text='Ajouter une autre plateforme'
                                            isLoading={false}
                                            actionO={() => {
                                                setAddResponse(null);
                                                clearForm();
                                            }} />
                                </div>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        </div>
    );
}

export default AddCustomer;
