import {Dispatch, ReactElement, SetStateAction, useEffect, useState} from "react";
import IAuthUser from "./IAuthUser";
import {Axios} from "axios";

const loadRolesAsync = async (
    axios: Axios,
    setAuthUser: Dispatch<SetStateAction<IAuthUser | null>>
): Promise<void> => {
    const roles: Set<string> = new Set(
        (await axios.get<string[]>(
            `/auth/roles`,
        )).data
    );
    setAuthUser({
        canManageProfiles: roles.has('canManageProfiles'),
        canManageFinancings: roles.has('canManageFinancings'),
        canChargeStripe: roles.has('canChargeStripe'),
        canInvoiceChargeStripe: roles.has('canInvoiceChargeStripe'),
        canUpdateIbanPostCertif: roles.has('canUpdateIbanPostCertif'),
        canUpdateEligibilityGlobalLimit: roles.has('canUpdateEligibilityGlobalLimit')
    });
};

const AuthRolesWrapper = (
    {
        axios,
        child
    }: {
        axios: Axios;
        child: (authUserO: IAuthUser | null) => ReactElement;
    }
) => {
    const [ authUserO, setAuthUser ] = useState<IAuthUser | null>(null);

    useEffect(
        () => {
            loadRolesAsync(axios, setAuthUser);
        },
        [axios]
    );

    return child(authUserO)
}

export default AuthRolesWrapper;
