import { Link } from "react-router-dom";
import {IBuyerCompany} from "../invoiceDetailsQuery";
import {useQuery} from "@tanstack/react-query";
import {buyerUrl} from "../../Buyers/buyersUrls";
import { formatIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import DimplScoreResult from "../../../utils/DimplScore/DimplScoreResult";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import Loader from "factor-lib/Loader";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import DataLoader from "dataloader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import dimplScoreSingleCompanyQuery, {dimplScoreQueryKey, IDimplScoreQueryResult} from "../../../utils/DimplScore/dimplScoreSingleCompanyQuery";

const DS = (
    {
        dsGraphQLDataLoader,
        buyerCompanyId
    }: {
        dsGraphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        buyerCompanyId: string;
    }
) => {

    const dsQueryKey = dimplScoreQueryKey(buyerCompanyId);

    const queryResult = useQuery<IDimplScoreQueryResult /* should be consistent with DimplScoreLoader */>(
        dsQueryKey,
        async ({ signal }) => {
            const query: IGraphQLParams =
                dimplScoreSingleCompanyQuery('buyerDS', 'buyerCompanyId', buyerCompanyId)
                /* {
                    // we use an alias because the seller's dimpl score is being fetched in the same query
                    query: `query($buyerCompanyId: Guid!) {
                        buyerDS: companyDetails(id: $buyerCompanyId) {
                            ${DimplScoreGraphQLQueryFields}
                        }
                     }`,
                    variables: {
                        buyerCompanyId
                    }
                } */ ;
            return (await dsGraphQLDataLoader.load({
                query,
                queryKey: dsQueryKey,
                signal
            })).buyerDS;
        }
    );

    return (
        <ReactQueryResultWrapper onLoading={() => <Loader />}
                                 result={queryResult}
                                 onSuccess={(r: IDimplScoreQueryResult) =>
                                     <DimplScoreResult className='p-margin-top-5'
                                                       dimplScore={r.dimplScore}/>}
                                 displayFullError={true} />
    );
}


const InvoiceBuyerInfosSection = (
    {
        dsGraphQLDataLoader,
        company,
        emails,
        phones
    } : {
        dsGraphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        company: IBuyerCompany;
        emails: string[];
        phones: string[];
    }
) =>
    <div>
        <table className='table is-narrow'>
            <tbody>
            <tr>
                <td className='p-bold'>Nom société</td>
                <td>
                    { company.name }
                    &nbsp;[
                    <Link id='invoiceBuyerLink'
                          to={buyerUrl(company.id, false)}>
                        lien
                    </Link>
                    ]
                </td>
            </tr>
            <tr>
                <td className='p-bold'>Identifiant</td>
                <td>{ formatIntCompanyIdentifier(company.identifier) }</td>
            </tr>
            <tr>
                <td className='p-bold'>Emails</td>
                <td>{ emails.length > 0 ? emails.join('; ') : '<<aucun>>'}</td>
            </tr>
            <tr>
                <td className='p-bold'>Téléphones</td>
                <td>{ phones.length > 0 ? phones.join('; ') : '<<aucun>>'}</td>
            </tr>
            </tbody>
        </table>

        <DS dsGraphQLDataLoader={dsGraphQLDataLoader}
            buyerCompanyId={company.id}/>
    </div>;

export default InvoiceBuyerInfosSection;
