import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import CustomerSwanSection, {IUpdateSwanInstantPaymentMutationParams} from "../../../../utils/Swan/CustomerSwanSection";
import {customerDetailsQueryKey, ICustomerDetailsQueryResult} from "../customerDetailsQuery";
import {Axios} from "axios";
import IAuthUser from "../../../../IAuthUser";
import CustomerMarketplacePercentageForSellerSection from "./CustomerMarketPlacePercentageForSellerSection";

const CustomerMarketplaceSwanSection = (
    {
        axios,
        authUserO,
        customerId,
        virtualIban,
        instantPayment,
        marketplaceId,
        invoicePer10000ForSeller
    }: {
        axios: Axios;
        authUserO: IAuthUser | null;
        customerId: string;
        virtualIban: string | null;
        instantPayment: boolean;
        marketplaceId: string;
        invoicePer10000ForSeller: number;
    }
) => {
    const queryClient = useQueryClient();

    const updateSwanInstantPaymentMutation: UseMutationResult<void, any, IUpdateSwanInstantPaymentMutationParams> =
        useMutation<void, any, IUpdateSwanInstantPaymentMutationParams>(
            async (p: IUpdateSwanInstantPaymentMutationParams) => {
                await axios.put<void>(
                    `/adminMarketplaces/${p.value}/swanInstantPayment`,
                    {
                        value: p.newInstantPayment
                    }
                );
            },
            ({
                onSuccess: (_, p: IUpdateSwanInstantPaymentMutationParams) => {
                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId), (old?: ICustomerDetailsQueryResult) => !!old ? ({
                            customerDetails: {
                                ...old!.customerDetails,
                                marketplace: {
                                    ...old.customerDetails.marketplace!,
                                    swanInstantPayment: p.newInstantPayment
                                }
                            }
                        }) : undefined
                    );
                }
            })
        );

    return (
        <div>
            <CustomerSwanSection axios={axios}
                                authUserO={authUserO}
                                customerId={customerId}
                                directSellerSellerId={null}
                                virtualIban={virtualIban}
                                instantPayment={instantPayment}
                                updateSwanInstantPaymentMutation={updateSwanInstantPaymentMutation}
                                updateSwanInstantPaymentMutationValue={marketplaceId} />
            <CustomerMarketplacePercentageForSellerSection invoicePer10000ForSeller={invoicePer10000ForSeller}
                                                           axios={axios}
                                                           customerId={customerId}
                                                           marketplaceId={marketplaceId}
                                                           authUserO={authUserO}
                                                        />
        </div>
    );
}

export default CustomerMarketplaceSwanSection;