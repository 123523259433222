import IAccessToken from "../../../IAccessToken";
import ILoadedSelected from "./ILoadedSelected";
import {ReactNode} from "react";
import PendingPaymentsPayManually from "./PendingPaymentsPayManually";
import PendingPaymentsPayWithSwan from "./PendingPaymentsPayWithSwan";
import CheckBox from "factor-lib/forms/CheckBox";
import { formatAmount } from "factor-lib/utils/amountUtils";
import {remainingToPay} from "../../../utils/IInvoice";

const PendingPaymentsTemplate = (
    {
        className,
        accessTokenO,
        loadedO,
        children
    }: {
        className?: string;
        accessTokenO: IAccessToken | null;
        loadedO: ILoadedSelected | null;
        children: ReactNode;
    }
) =>
    <div className={className}>
        <div className='level'>
            <div className='p-margin-left-4 column is-one-quarter'>
                { `Montant sélectionné ${
                    formatAmount(
                        (!!loadedO ? loadedO.selectedCustomerFinancingRequests : []).map((c) => c.invoice).reduce(
                            (s, i) => s + remainingToPay(i),
                            0
                        )
                    )}` }
            </div>


            { !!accessTokenO?.authUserO?.canManageFinancings &&
                <div className='p-margin-4 p-horizontal-center'>
                    <PendingPaymentsPayManually axios={accessTokenO.axios}
                                                loadedO={loadedO} />
                    <PendingPaymentsPayWithSwan className='p-margin-left-4'
                                                loadedO={loadedO} />
                </div>
            }
        </div>

        <table className='table is-striped is-bordered is-hoverable is-fullwidth'>
            <thead>
                <tr>
                    <th>
                        <CheckBox inputValue={loadedO?.isAllSelected || false}
                                  internalId='selectPayment-all'
                                  updateInputValue={(loadedO?.selectAll) || null} />

                    </th>
                    <th>N° de facture</th>
                    <th>Seller</th>
                    <th>Buyer</th>
                    <th>Montant TTC</th>
                    <th>Pay</th>
                    <th>Due</th>
                </tr>
            </thead>
            { children }
        </table>
    </div>;

    export default PendingPaymentsTemplate;