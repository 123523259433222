import {ReactNode} from "react";

const BillingSection = (
    {
        className,
        title,
        children
    }: {
        className?: string;
        title: string;
        children: ReactNode;
    }
) =>
    <div className={className}>
        <div className='p-bold'> { title } </div>
        { children }
    </div>;

export default BillingSection;