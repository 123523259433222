// TODO: better hierarchy
export const preInvoicesQueryKey = (includesArchived: boolean) =>
    [`preInvoices${includesArchived ? '-withArchived' : ''}`];

export const rejectedState = 'REJECTED';
export const approvedState = 'APPROVED';
export const pendingState = 'PENDING';


export interface IPreInvoiceEmail {
    id: string;
    sentDateTime: string;
    thread: {
        link: string;
        length: number;
    };
    title: string;
    senderEmail: string;
}

export interface IArchivedPreInvoiceEmail extends IPreInvoiceEmail {
}

export interface IPendingPreInvoiceEmail extends IPreInvoiceEmail {
    matchingSellerId: string | null;
    matchingBuyerEmailId: string | null;
}

export interface IPreInvoice<E extends IPreInvoiceEmail> {
    id: string;
    creationDateTime: string;
    amountWoTax: number | null;
    amountTax: number | null;
    invoiceNumber: string | null;
    status: string;
    email: E;
}

export interface IPreInvoiceEmailRecipient {
    preInvoiceEmailId: string;
    email: string;
}

export interface IPreInvoices<E extends IPreInvoiceEmail> {
    base: ReadonlyArray<IPreInvoice<E>>;
    recipientEmails: ReadonlyArray<IPreInvoiceEmailRecipient>;
}

export interface IPreInvoicesQueryResult {
    preInvoicesPending: IPreInvoices<IPendingPreInvoiceEmail>;
    preInvoicesArchived: IPreInvoices<IArchivedPreInvoiceEmail> | null;
}

// post graphql aggregation
export interface IPreInvoiceWithEmailRecipients<E extends IPreInvoiceEmail> {
    preInvoice: IPreInvoice<E>;
    preInvoiceEmailRecipients: Array<string>;
}
