import  {ReactElement} from "react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
    queries: {
        staleTime: 20 /* in seconds */ * 1_000,
        retry: false
        // refetchOnWindowFocus: false
    },
});

const QueryClientWrapper = (
    {
        children
    }: {
        children: ReactElement;
    }
) =>
    <QueryClientProvider client={queryClient}>
        { children }
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>;

export default QueryClientWrapper;