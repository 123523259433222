export const PAYMENT_TYPE_COMMING_LING = 1;
export const PAYMENT_TYPE_PAYMENT_ON_RECUP_BY_BUYER = 2;
export const PAYMENT_TYPE_PAYMENT_ON_RECUP_BY_SELLER = 3;
export const PAYMENT_TYPE_PAYMENT_ON_RECUP_BY_PARTNER_RECUP = 5;
export const PAYMENT_TYPE_COMMING_LING_BEFORE_FINANCING = 7;

export const PAYMENT_TYPE_SWAN = 11;
export const PAYMENT_TYPE_COMMINGLING_RECUP = 12;

const notReportingSet = new Set([
    PAYMENT_TYPE_PAYMENT_ON_RECUP_BY_BUYER, PAYMENT_TYPE_PAYMENT_ON_RECUP_BY_SELLER, PAYMENT_TYPE_PAYMENT_ON_RECUP_BY_PARTNER_RECUP, PAYMENT_TYPE_COMMING_LING_BEFORE_FINANCING,

    PAYMENT_TYPE_SWAN, PAYMENT_TYPE_COMMINGLING_RECUP
]);

export const isPaymentNotReporting = (paymentType: number) =>
    notReportingSet.has(paymentType);

export const getPaymentAmount = (partialAmountInput: number | null, invoiceAmountTTC: number, partialAmountPaid: number) =>
    partialAmountInput ?? (invoiceAmountTTC - partialAmountPaid);