import {useContext, useState} from "react";
import RadioBox from "factor-lib/forms/Radio/RadioBox";
import IOption from "factor-lib/forms/Select/IOption";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import ActionBottomBar from "./ActionBottomBar";
import {NavigateFunction} from "react-router-dom";
import {IBilansDescriptions} from "./IBilanDescription";
import {BilanUrl} from "./dataUrls";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { numberFormat2Digits, serverDateDeserialization } from "factor-lib/utils/dateUtils";

export enum BilanTypeSelectionEnum {
    Existing = 1,
    New = 2
}

const formatDate = (d: Date) =>
    `${d.getFullYear()}${numberFormat2Digits(d.getMonth() + 1)}${numberFormat2Digits(d.getDate())}`;

interface IBilanKey {
    dateCloture: string;
    bilanType: string; //  "consolide", "complet"
}

const buttonText = 'Continuer';

const verticalCenterStyle = ({ verticalAlign: 'middle' });

const BilanSelectionWithExisting = (
    {
        siren,
        existingBilans,
        closeModal,
        buttonKind,
        buttonSize
    }: {
        siren: string;
        existingBilans: IBilansDescriptions;
        closeModal: () => void;
        buttonKind: string;
        buttonSize: string;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    const [bilanTypeSelectionEnum, setBilanTypeSelectionEnum] = useState<number | null>(null);
    const [selectedBilan, setSelectedBilan] = useState<IBilanKey | null>(null);
    const bilanOptions: Array<IOption<IBilanKey>> =
        existingBilans.map((b) => ({
            value: {
                dateCloture: b.dateCloture,
                bilanType: b.bilanType
            },
            label: `${formatDate(serverDateDeserialization(b.dateCloture))} - ${b.confidentialite ?? 'user'} - ${b.bilanType}`
        }));

    return (
        <div>
            <table>
                <tbody>
                    <tr>
                        <td className='p-padding-right-4' style={{verticalAlign: 'middle'}}>
                            <RadioBox id='exitingBilan'
                                      inputValue={bilanTypeSelectionEnum === BilanTypeSelectionEnum.Existing}
                                      setInputValue={() => setBilanTypeSelectionEnum(BilanTypeSelectionEnum.Existing)}
                                      enabled={true} />
                        </td>
                        <td>Editer un bilan existant</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>
                            <div className='p-padding-top-6' style={{minWidth: '400px' /* assez pour afficher les bilans sur une ligne */}}>
                                <SingleSelect options={bilanOptions}
                                              selectedOption={(!!selectedBilan /* optim */ && bilanOptions.find((p) => p.value.dateCloture === selectedBilan?.dateCloture && p.value.bilanType === selectedBilan?.bilanType)) || null}
                                              selectOption={(o) => {
                                                  setBilanTypeSelectionEnum(BilanTypeSelectionEnum.Existing);
                                                  setSelectedBilan(o!.value);
                                              }}
                                              clearable={false} />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className='p-padding-right-4 p-padding-top-4' style={verticalCenterStyle}>
                            <RadioBox id='newBilan'
                                      inputValue={bilanTypeSelectionEnum === BilanTypeSelectionEnum.New}
                                      setInputValue={() => {
                                          setBilanTypeSelectionEnum(BilanTypeSelectionEnum.New);
                                          setSelectedBilan(null);
                                      }}
                                      enabled={true} />
                        </td>
                        <td className='p-padding-top-4'>Nouveau bilan</td>
                    </tr>
                </tbody>
            </table>
            <ActionBottomBar closeModal={closeModal}
                             kind={buttonKind}
                             size={buttonSize}
                             text={buttonText}
                             actionO={((bilanTypeSelectionEnum === BilanTypeSelectionEnum.New || !!selectedBilan) &&
                                 (() => navigate(`${BilanUrl}/${siren}${!!selectedBilan ? `/${selectedBilan.dateCloture}/${selectedBilan.bilanType}`: ''}`))) || null
                             }/>
        </div>
    );
}

export default BilanSelectionWithExisting;

