import { ISellerDetailsQueryResult, sellerDetailsQueryKey } from "../../../sellerDetailsQuery";
import LinkMutationWithConfirmationModal from "factor-lib/Links/LinkMutationWithConfirmationModal";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Axios } from "axios";

const RemoveBeneficiary = (
    {
        axios,
        sellerId,
        directSellerId,
        beneficiaryId
    } : {
        axios: Axios;
        sellerId: string;
        directSellerId: string;
        beneficiaryId: string
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const removeBeneficiaryMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (beneficiaryId2) => {
                await axios.delete<void>(
                    `adminDirectSellerUltimateBeneficiaries/${directSellerId}/${beneficiaryId2}`
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ISellerDetailsQueryResult>(
                        sellerDetailsQueryKey(sellerId),
                        (old?: ISellerDetailsQueryResult) => ({
                            sellerDetails: {
                                ...old!.sellerDetails,
                                customer: {
                                    ...old!.sellerDetails.customer,
                                    directSeller: {
                                        ...old!.sellerDetails.customer.directSeller!,
                                        ultimateBeneficiaries: old!.sellerDetails.customer.directSeller!.ultimateBeneficiaries.filter(
                                            (beneficiary) => beneficiary.id !== beneficiaryId
                                        )
                                    }
                                }
                            }
                        })
                    );
                }
            })
        );

    return (
        <LinkMutationWithConfirmationModal id={`showConfirmBeneficiaryRemovalModal-${beneficiaryId}`}
                                           className='p-danger-text'
                                           text='Supprimer'
                                           confirmationText='Êtes vous sûr de vouloir supprimer ce bénéficiaire ?'
                                           mutation={removeBeneficiaryMutation}
                                           value={beneficiaryId} />
    );
}

export default RemoveBeneficiary;
