import IPerson from "../../../utils/Person/IPerson";
import { IItemContent } from 'factor-lib/Bankin/bankinUtils';
import { IBaseCompany } from "../../../utils/IInvoice";
import { ConfidenceFlag } from "../../../utils/ConfidenceFlags";
import {BirthLocationGraphQLFields} from "../../../utils/IBirthLocation";
import {AddressGraphQLFields} from "../../../utils/IAddress";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";

export const SellerDetailsQueryKeyPrefix = ['seller', 'details'];
export const sellerDetailsQueryKey = (sellerId: string) =>
    [...SellerDetailsQueryKeyPrefix, sellerId];

export interface IBankin {
    // link: string;
    started: {
        incompleteStep: string | null;
        items: Array<{
            content: IItemContent;
        }>;
    } | null;
}

export interface IDirectSeller {
    id: string;
    // customer: ICustomer;
    isCompanyOwnerAddedAsBeneficiary: boolean;
    ultimateBeneficiaries: Array<IBeneficiary>;
    seller: {
        id: string;
        customer: {
            dimplWallet: {
                virtualIban: string;
            } | null;
        };
    };
}

export interface ICustomer {
    id: string;
    billingIban: string | null;
    bankStatement1: {
        fileName: string;
    } | null;
    bankStatement2: {
        fileName: string;
    } | null;
    bankStatement3: {
        fileName: string;
    } | null;
    bankin: IBankin;
    company: {
        name: string;
    };
    directSeller: IDirectSeller | null;
    dimplWallet: {
        virtualIban: string;
    } | null;

}

export interface IIdentityFiles {
    // Cannot be empty, but we just want to ckeck if it is null or not
    recto: {
        fileName: string;
    };
    verso: {
        fileName: string;
    } | null;
}

export interface IBeneficiary extends IPerson {
    id: string;
}

export interface ICompany extends IBaseCompany {
    id: string;
    // Needed to make sure valid
    address: {
        streetAddress: string;
        city: string;
        postCode: string;
    }
}

export interface ISellerReminderEmailsSettings {
    reminderEmailsPaused: boolean;
    reminderEmailsCCsPaused: boolean;
}

export interface ISellerUbbleIdentification {
    creationDateTime: string;
    ubbleId: string;
    link: string;
    status: string;
    statusUpdatedAt: string;
    score: number | null;
}

export type Certif = {
    solvaConfidenceFlag: ConfidenceFlag;
    fraudConfidenceFlag: ConfidenceFlag;
    dateTime: string;
} | null;

export interface ISellerDetails {
    company: ICompany;
    customer: ICustomer;
    companyOwner: IPerson | null;
    email: string;
    phone: string;
    iban: string | null;
    swanInstantPayment: boolean;
    reminderEmailsSettings: ISellerReminderEmailsSettings;
    customCompanyFriendlyName: string | null; // null -> not set, use company's normal name
    certif: Certif;
    isBlocked: boolean;
    opsNotes: string | null;
    otherDocumentsLink: string | null;
    customDocument: {
        fileName: string;
    } | null;
    identityFiles: IIdentityFiles | null;
    ubbleIdentifications: ISellerUbbleIdentification[];
}

export interface ISellerDetailsQueryResult {
    sellerDetails: ISellerDetails;
}

export const sellerDetailsGraphQLQuery = (sellerId: string): IGraphQLParams => ({
    query: `query Q($sellerId: Guid!) {
       sellerDetails (id: $sellerId) {
           email
           phone
           iban
           isBlocked
           certif {
               solvaConfidenceFlag
               fraudConfidenceFlag
               dateTime
           }
           customCompanyFriendlyName
           reminderEmailsSettings {
               reminderEmailsPaused
               reminderEmailsCCsPaused
           }
           opsNotes
           otherDocumentsLink
           customDocument { fileName }
           identityFiles { recto { fileName } verso { fileName } }
           company {
                id
                identifier { ${IntCompanyIdentifierGraphQLFields} }
                name
                address { streetAddress city postCode }

            }
           companyOwner {
               firstName
               lastName
               nationalityCode
               birthDate
               birthLocation { ${BirthLocationGraphQLFields} }
               residenceAddress { ${AddressGraphQLFields} }
           }
           customer {
               id
               billingIban
               bankStatement1 { fileName }
               bankStatement2 { fileName }
               bankStatement3 { fileName }
               bankin {
                   started {
                       incompleteStep
                       items { content { status bankName hasAnyTransaction } }
                   }
               }
               company { name }
               directSeller {
                   id
                   isCompanyOwnerAddedAsBeneficiary
                   ultimateBeneficiaries {
                       id
                       firstName
                       lastName
                       nationalityCode
                       birthDate
                       birthLocation { ${BirthLocationGraphQLFields} }
                       residenceAddress { ${AddressGraphQLFields} }
                   }
               }
               dimplWallet { virtualIban }
           }
           swanInstantPayment
           ubbleIdentifications { ubbleId link status statusUpdatedAt score }
       }
   }`,
    variables: {
        sellerId
    }
});