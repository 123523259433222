import Button, { KIND_PRIMARY, SIZE_COMPACT } from "factor-lib/Buttons/Button";
import {
    allBuyersWithLimitQueryKey, buyersWithLimitSearch, IBuyerCompaniesWithLimitQueryResult,
    IBuyerCompanyWithLimit
} from "./BuyerLimitsQuery";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Axios } from "axios";
import LinkMutationWithConfirmationModal from "factor-lib/Links/LinkMutationWithConfirmationModal";
import { Link } from "react-router-dom";
import { buyerUrl } from "../Buyers/buyersUrls";
import { IExistingBuyer } from "./BuyerLimitsPage";
import { formatCompany } from "../../utils/companyUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";

const DeleteLimit = (
    {
        axios,
        companyId
    }: {
        axios: Axios;
        companyId: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const deleteLimitMutation: UseMutationResult<void, any, string> =
        useMutation<void, any, string>(
            async (companyIdValue) =>
                (await axios.delete(
                    `/adminCompaniesOutstandingsLimitsAsBuyer/${companyIdValue}`
                )).data,
            ({
                onSuccess: () => {
                    queryClient.setQueryData<IBuyerCompaniesWithLimitQueryResult>(
                        allBuyersWithLimitQueryKey,
                        (old: IBuyerCompaniesWithLimitQueryResult | undefined) => !!old ? ({
                            companies: [...old.companies.filter((c) => c.id !== companyId)]
                        }) : undefined
                    );

                    return queryClient.invalidateQueries(buyersWithLimitSearch);
                }
            })
        );

    return (
        <LinkMutationWithConfirmationModal id='removePartialPayment'
                                           className='p-danger-text'
                                           text='Delete'
                                           confirmationText='Etes vous sûr de vouloir supprimer cette limite ?'
                                           mutation={deleteLimitMutation}
                                           value={companyId} />
    );
}
const BuyerLimitsListLoaded = (
    {
        axios,
        buyerCompanies,
        setDisplayUpdateSingleModal
    }: {
        axios: Axios;
        buyerCompanies: IBuyerCompanyWithLimit[];
        setDisplayUpdateSingleModal: (buyer: IExistingBuyer) => void;
    }
) =>
    <tbody>
        { buyerCompanies
            .map((company, index) =>
                <tr key={`buyer-company-${index}`}>
                    <td style={{verticalAlign: 'middle'}}>
                        <Link to={buyerUrl(company.id, false)}>
                            { formatCompany(company) }
                        </Link>
                    </td>
                    <td style={{verticalAlign: 'middle'}}>
                        { `${formatAmount(company.asBuyer.outstanding)} / ${formatAmount(company.asBuyer.manualOutstandingLimit)}` }
                    </td>
                    <td>
                        <Button id='updateBuyerLimitButton'
                                kind={KIND_PRIMARY}
                                size={SIZE_COMPACT}
                                text='Modifier'
                                isLoading={false}
                                actionO={() => setDisplayUpdateSingleModal({
                                    id: company.id,
                                    name: company.name,
                                    identifier: company.identifier,
                                    existingLimit: company.asBuyer.manualOutstandingLimit
                                })}/>
                    </td>
                    <td>
                        <div className='p-margin-top-7'>
                            <DeleteLimit axios={axios}                                     
                                         companyId={company.id} />
                        </div>
                    </td>
                </tr>
            )
        }
    </tbody>;

export default BuyerLimitsListLoaded;
