import { IFinancingStatus, financingWithStatusGraphQLQuery } from "../../Pages/Invoices/invoiceStatus";
import IBaseInvoice, { BaseInvoiceGraphQLFields, InvoiceBaseSellersGraphQLSubQuery, InvoiceBuyerCompaniesGraphQLSubQuery } from "../IInvoice";

const SettledInvoiceGraphQLFields =
    `
        ${BaseInvoiceGraphQLFields}
        payment { completePaidDate } 
    `;

interface ISettledInvoice extends IBaseInvoice {
    payment: {
        completePaidDate: string | null;
    }
}

export interface ISettledFinancing extends IFinancingStatus {
    creationDateTime: string;
    invoice: ISettledInvoice;
}

export const SettledFinancingsQueryKeyPrefix = ['settled-financings'];

export const getSettledFinancingsGraphQLQuery =
    (sellerId: string | null, buyerCompanyId: string | null): string =>
        `{
            financings (isActive: false${!!sellerId ? `, sellerId: "${sellerId}"` : ''}${!!buyerCompanyId ? `, buyerCompanyId: "${buyerCompanyId}"` : ''}) {
                base {
                    ${financingWithStatusGraphQLQuery(
                        `
                            creationDateTime
                            invoice {
                                ${SettledInvoiceGraphQLFields}
                            }
                        `,
                        ''
                    )}
                }
                sellers {
                    ${InvoiceBaseSellersGraphQLSubQuery}
                }
                ${InvoiceBuyerCompaniesGraphQLSubQuery}
            }
        }`;
