import IAuthUser from "../../../../../IAuthUser";
import { Axios } from "axios";
import { IDimplOps } from "../../../../../utils/IDimplOps";
import AccountManager, {
    AccountsManagersQuery,
    DimplOpsAccountManagersQueryKey, IAccountsManagersResult
} from "../../../../../utils/AccountManager";
import { useQuery } from "@tanstack/react-query";
import graphQLQueryAsync from "factor-lib/serverUtils/graphQLQueryAsync";
import { getFactorContext } from "../../../../../IFactorContext";
import { ICustomerDetailsQueryResult, customerDetailsQueryKey } from "../../customerDetailsQuery";

const UpdateAccountManagerEnabled = (
    {
        className,
        axios,
        customerId,
        accountManager
    }: {
        className?: string;
        axios: Axios;
        customerId: string;
        accountManager: IDimplOps;
    }
) =>
    <AccountManager className={className}
                    accountManagerLoadedO={accountManager}
                    canUpdate={{
                        endpoint: `adminCustomers/${customerId}/accountManager`,
                        mutationUpdate: (queryClient, newAccountManager) => {
                            queryClient.setQueryData<ICustomerDetailsQueryResult>(
                                customerDetailsQueryKey(customerId),
                                (old: ICustomerDetailsQueryResult | undefined) => !!old ? ({
                                    customerDetails: {
                                        ...old.customerDetails,
                                        effectiveAccountManager: newAccountManager
                                    }
                                }) : undefined
                            );
                        },
                        axios,
                        queryResult: useQuery<IDimplOps[]>(
                            DimplOpsAccountManagersQueryKey,
                            async ({ signal }) =>
                                // TODO: use graphQLDataloader ?
                                (await (graphQLQueryAsync<IAccountsManagersResult>(
                                    axios,
                                    AccountsManagersQuery,
                                    getFactorContext().logger,
                                    DimplOpsAccountManagersQueryKey,
                                    signal
                                ))).accountManagers
                        )
                    }} />;

const CustomerAccountManager = (
    {
        className,
        axios,
        authUser,
        customerId,
        accountManager
    }: {
        className?: string;
        axios: Axios;
        authUser: IAuthUser | null;
        customerId: string;
        accountManager: IDimplOps;
    }
) =>
    authUser?.canManageProfiles
        ? <UpdateAccountManagerEnabled className={className}
                                       axios={axios}
                                       customerId={customerId}
                                       accountManager={accountManager} />
        : <AccountManager className={className}
                          accountManagerLoadedO={accountManager}
                          canUpdate={null} />

export default CustomerAccountManager;
