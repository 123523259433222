import {formatAmount} from 'factor-lib/utils/amountUtils';
import {ReactElement} from "react";
import {INewBillingOverThresholdQueryFinancingResult} from "./NewBillingQuery";
import {billingRounding} from "./billingRounding";

export const effectiveDaysRounding = (effectiveDaysFinancingsOverMinThreshold: number) =>
    // Same as billing rounding. Even though not homogeneous
    billingRounding(effectiveDaysFinancingsOverMinThreshold);

export const computeOverThresholdFinancingAmount = (
    financingAmount: number,
    effectiveDaysFinancingsOverMinThresholdRounded: number,
    dailyRateInPercent: number
): number =>
    billingRounding(financingAmount * effectiveDaysFinancingsOverMinThresholdRounded * dailyRateInPercent / 100 /* In % */);

export const CurrentMonthOverThresholdFinancingRow = (
    {
        f,
        dailyRateInPercent
    }: {
        f: INewBillingOverThresholdQueryFinancingResult;
        dailyRateInPercent: number
    }
) => {
    const effectiveDaysFinancingsOverMinThresholdRounded = effectiveDaysRounding(f.effectiveDaysFinancingsOverMinThreshold);
    return (
        <tr>
            <td> { f.invoice.number }</td>
            <td> { effectiveDaysFinancingsOverMinThresholdRounded }</td>
            <td> { formatAmount(f.amount) } </td>
            <td> { formatAmount(computeOverThresholdFinancingAmount(f.amount, effectiveDaysFinancingsOverMinThresholdRounded, dailyRateInPercent) ) } </td>
        </tr>
    );
};

const NewBillingOverThresholdFinancingTable = (
    {
        children
    }: {
        children: ReactElement;
    }
) =>
    <table className='table is-striped'>
        <thead>
            <tr>
                <th>#</th>
                <th>{`Nb de jours effectifs dans le mois > 30`}</th>
                <th>Montant financé</th>
                <th>Frais calculés</th>
            </tr>
        </thead>
        { children }
    </table>;

export default NewBillingOverThresholdFinancingTable;