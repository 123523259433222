import Button from "factor-lib/Buttons/Button";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";

const ActionBottomBar = (
    {
        closeModal,
        kind,
        size,
        text,
        actionO
    }: {
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
        actionO: (() => void) | null;
    }
) =>
    <div className='level p-margin-top-4'>
        <div className='level-item'>
            <Button id='bilan-selection-cancel'
                    text='Annuler'
                    isLoading={false}
                    actionO={closeModal} />

        </div>
        <div className='level-item'>
            { !!actionO
                ? <Button id='bilan-selection-ok'
                          kind={kind}
                          size={size}
                          text={text}
                          isLoading={false}
                          actionO={actionO}/>

                : <ButtonDisabled kind={kind}
                                  size={size}
                                  text={text} />
            }
        </div>
    </div>

export default ActionBottomBar;
