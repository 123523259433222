export class ResultOrErrors<T> {
    result: T | null;
    errors: string[] | null;

    private constructor(result: T | null, errors: string[] | null) {
        this.result = result;
        this.errors = errors;
    }

    public static fromResult<T>(result: T | null): ResultOrErrors<T> {
        return new ResultOrErrors<T>(result, null);
    }

    public static fromError<T>(errors: string[]): ResultOrErrors<T> {
        return new ResultOrErrors<T>(null, errors);
    }
}
