import { Axios, AxiosResponse } from "axios";
import { SwanOAuthRedirectRoute } from "../Pages/SwanConsent/swanConsentUrls";
import { formatAmount } from "factor-lib/utils/amountUtils";

declare global {
    interface Window {
        initSwanAction: (axios: Axios, swanAuthorizationCode: string) => Promise<AxiosResponse<string>>; // returns consentUrl
        validateSwanAction: (axios: Axios, consentId: string) => Promise<AxiosResponse<any>>;
        onValidateSwanAction: (data: any) => void;
        rejectSwanAction: (axios: Axios, consentId: string) => Promise<AxiosResponse<void>>;
    }
}

// https://docs.swan.io/api/authentication/user-access-token#get-an-authorization-code
const craftOauthUrl = (baseUrl: string, clientId: string) : string =>
    `https://oauth.swan.io/oauth2/auth?response_type=code&client_id=${clientId}&redirect_uri=${baseUrl}${SwanOAuthRedirectRoute}&scope=openid%20offline%20idverified&state=asdakjsdhalskjdlaksdj`

export const swanRedirect = <T, >(
    baseUrl: string,
    clientId: string,
    initSwanAction: (axios: Axios, swanAuthorizationCode: string) => Promise<AxiosResponse<string>>,
    validateSwanAction: (axios: Axios, consentId: string) => Promise<AxiosResponse<T>>,
    onValidateSwanAction: (data: T) => void,
    rejectSwanAction: (axios: Axios, consentId: string) => Promise<AxiosResponse<void>>
) => {
    const newTab: Window | null = window.open(
        craftOauthUrl(baseUrl /* currentConfigEnv.baseUrl */, clientId /* currentConfigEnv.swanClientId */),
        '_blank' // Keep opener, so we can close it
    );

    if (!!newTab) {
        window.initSwanAction = initSwanAction;
        window.validateSwanAction = validateSwanAction;
        window.onValidateSwanAction = onValidateSwanAction;
        window.rejectSwanAction = rejectSwanAction;
    }
};

const InstantTransactionMaxAmount = 20_000_00; // Same as in TransactionInfos in backend
export const getInstantTransactionNotPossibleReason = (sellerInstantPayment: boolean, amount: number): string | null =>
    !sellerInstantPayment
        ? 'paiements instantés désactivés'
        : amount > InstantTransactionMaxAmount
            ? `montant supérieur à ${formatAmount(InstantTransactionMaxAmount)}`
            : null ;
