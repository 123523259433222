import {useContext, useState} from "react";
import Button from "factor-lib/Buttons/Button";
import {NavigateFunction} from "react-router-dom";
import IOption from "factor-lib/forms/Select/IOption";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import RadioBox from "factor-lib/forms/Radio/RadioBox";
import { ISellerCanAddBuyer } from "./AddBuyerChooseSeller";
import { addBuyerForSellerUrl } from "../buyersUrls";
import { formatCompany } from "../../../utils/companyUtils";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";

const NextText = 'Suivant';

const filterSellerType = (isDirectFilter: boolean) =>
    (seller: ISellerCanAddBuyer): boolean => {
        return !seller.customer.marketplace === isDirectFilter;
    };

const AddBuyerChooseSellerLoaded = (
    {
        sellers
    }: {
        sellers: Array<ISellerCanAddBuyer>;
    }
) => {
    const [isDirectFilter, setIsDirectFilter] = useState(true);

    const [sellerOption, setSellerOption] = useState<IOption<string> | null>(null);
    const selectedSeller: ISellerCanAddBuyer | null = (!!sellerOption && sellers.find((s) => s.id === sellerOption.value)) || null;

    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    return (
        <div className='container p-both-center p-margin-top-3'>
            <div style={{width: 600}}>
                <span className='title'>Choisir un Seller pour l'ajout d'un Buyer</span>

                <div className='box p-margin-top-4'>
                    <div className='level'>
                        <div className='level-item p-horizontal-center'>
                            <RadioBox id='directSellerRadioBoxId'
                                      inputValue={isDirectFilter}
                                      setInputValue={() => {
                                          if (!isDirectFilter) {
                                              setSellerOption(null);
                                          }
                                          setIsDirectFilter(true);
                                      }}
                                      enabled={true} />

                            <span className='p-margin-left-6'>Direct</span>
                        </div>

                        <div className='level-item p-horizontal-center'>
                            <RadioBox id='marketplaceSellerRadioBoxId'
                                      inputValue={!isDirectFilter}
                                      setInputValue={() => {
                                          if (isDirectFilter) {
                                              setSellerOption(null);
                                          }
                                          setIsDirectFilter(false);
                                      }}
                                      enabled={true} />
                            <span className='p-margin-left-6'>Marketplace</span>
                        </div>
                    </div>

                    <div className='p-margin-top-5'>
                        <SingleSelect options={sellers.filter(filterSellerType(isDirectFilter))
                                            .map(s => ({ value: s.id, label: formatCompany(s.company) }))
                                      }
                                      selectedOption={sellerOption}
                                      selectOption={setSellerOption}
                                      placeholder='Seller' />

                    </div>

                    <div className='p-margin-top-4 p-vertical-center'>
                        <Button id='addBuyerChooseSellerNext'
                                text={NextText}
                                isLoading={false}
                                actionO={!!selectedSeller ? (() => navigate(addBuyerForSellerUrl(selectedSeller.id))) : null}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBuyerChooseSellerLoaded;
