import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { CompanyUrlCompanyIdParam } from "./companiesUrls";

const WithCompanyIdParamWrapper = (
    {
        child
    }: {
        child: (companyId: string) => ReactElement;
    }
) =>
{
    const companyId = useParams()[CompanyUrlCompanyIdParam]!;
    return child(companyId);
}

export default WithCompanyIdParamWrapper;
