import IAuthUser from "../../../../IAuthUser";
import { Axios } from "axios";
import CreateCustomerInStripe from "./CustomerMainInfos/CreateCustomerInStripe";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import ChargeCustomerInStripeModalContent from "./CustomerMainInfos/ChargeCustomerInStripeModalContent";

const CustomerStripeSection = (
    {
        axios,
        authUser,
        customerId,
        companyName,
        contactEmail,
        billingIbanO,
        directSellerO,
        existsInStripe
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        customerId: string;
        companyName: string;
        contactEmail: string;
        billingIbanO: string | null;
        directSellerO: {
            ibanO: string | null;
        } | null;
        existsInStripe: boolean;
    }
) =>
    <div className='p-horizontal-center'>
        <div>
            { !existsInStripe 
                ? !billingIbanO
                    ? 'Plateforme sans Iban'
                    : !!directSellerO && !directSellerO.ibanO
                        ? 'Seller sans Iban'
                        : <CreateCustomerInStripe axios={axios}
                                              customerId={customerId}
                                              contactEmail={contactEmail}
                                              companyName={companyName}
                                              billingIban={billingIbanO}
                                              directSellerIbanO={directSellerO?.ibanO ?? null} />
                : 'Déjà créé dans Stripe'
            }
        </div>

        { existsInStripe && !!authUser?.canChargeStripe && !!billingIbanO &&
            <ButtonForModal className='p-margin-left-5'
                            id='showChargeInStripeModal'
                            text='Prélever dans Stripe'
                            modalFullMaxWidth={false}
                            childModalContent={(closeModal) =>
                                <ChargeCustomerInStripeModalContent axios={axios}
                                                                    customerId={customerId}
                                                                    companyName={companyName}
                                                                    billingIban={billingIbanO}
                                                                    directSellerIbanO={directSellerO?.ibanO ?? null}
                                                                    closeModal={closeModal} />
                        }/>
        }
    </div>;

export default CustomerStripeSection;
