import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal"
import {ValidationErrorModalContent} from "./ValidationErrorModal";

// TODO : delete, triggers validation input on every user input change.
// See SellerAddInvoiceLoaded for example
const ShowValidationErrorsButton  = (
    {
        className,
        id,
        headerText,
        validationErrors,
        kind,
        size,
        text
    }: {
        className?: string;
        id?: string;
        headerText?: string;
        validationErrors: string[];
        kind?: string;
        size?: string;
        text: string;
    }
) =>
    <ButtonForModal className={className}
                    id={id}
                    kind={kind}
                    size={size}
                    text={text}
                    modalFullMaxWidth={false}
                    modalMaxWidth='500px'
                    childModalContent={() =>
                        <ValidationErrorModalContent headerText={headerText}
                                                     validationErrors={validationErrors} />
                    }/>;

export default ShowValidationErrorsButton;
