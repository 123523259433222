import TelemetryLogger from "factor-lib/services/logger/TelemetryLogger";
import ILogger from "factor-lib/services/logger/ILogger";
import IEnvConfig from "./IEnvConfig";

// Expose for local to perform some temporary tests
export const ProdDataBackend = {
    baseUrl: 'https://data-admin-api-5s5itwtbza-ew.a.run.app',
    scope: 'api://f90961c4-8582-414b-afb4-cdd12d4a9409/SingleScope'
};

const ProdEnv: IEnvConfig = {

    logger: (): ILogger => new TelemetryLogger('88ed1371-8b3f-454c-822e-f55b05a27998'),

    title: 'prod',
    baseUrl: 'https://admin.dimpl.io', // https://happy-rock-0ba14d703.1.azurestaticapps.net',
    swanClientId: 'LIVE_1c6a074e-45ea-4c5f-8bf7-8a0625f19f2f',
    azureADClientId: '5dc8aac5-8cc1-4d63-8ffd-5b26d37c4ddf',
    mainBackend: {
        baseUrl: 'https://api.admin.dimpl.io', // 'https://factor-prod-admin-api.azurewebsites.net',
        scope: 'api://b729b0ce-d91e-430d-9e10-42c2b9e0b905/Single'
    },
    dataBackend: ProdDataBackend,
    logMsalInfo: false
};

export default ProdEnv;
