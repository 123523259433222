import { QueryClient, useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Axios } from "axios";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { IIntCompanyIdentifier, IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import IOption from "factor-lib/forms/Select/IOption";
import Loader from "factor-lib/Loader";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import { useState } from "react";
import GraphQLQueryWrapper from "../../utils/GraphQLQueryWrapper";
import { IPendingPreInvoiceEmail, IPreInvoice, preInvoicesQueryKey, IPreInvoicesQueryResult } from "../PreInvoices/preInvoicesQuery";
import { preInvoiceDetailsQueryKey, IPreInvoiceDetailsQueryResult } from "./PreInvoiceDetailsQuery";
import {formatCompany} from "../../utils/companyUtils";
import { ConfidenceFlag } from "../../utils/ConfidenceFlags";


const ValidateText = 'Valider';

interface IIdentifySellerResponse {
    companyId: string;
    certif: {
        solvaConfidenceFlag: ConfidenceFlag;
        fraudConfidenceFlag: ConfidenceFlag;
    } | null;
    isBlocked: boolean;
    identifier: IIntCompanyIdentifier;
}

const updateQueries = (
    queryClient: QueryClient,
    preInvoiceId: string,
    preInvoiceSenderEmail: string,
    sellerId: string,
    response: IIdentifySellerResponse
): void => {
    const withArchivedQueryKey = preInvoicesQueryKey(true);
    const withArchivedQueryResult: IPreInvoicesQueryResult | undefined = queryClient.getQueryData<IPreInvoicesQueryResult>(withArchivedQueryKey);
    if (!!withArchivedQueryResult) {
        queryClient.setQueryData<IPreInvoicesQueryResult>(
            withArchivedQueryKey,
            () => ({
                ...withArchivedQueryResult,
                preInvoicesPending: {
                    ...withArchivedQueryResult.preInvoicesPending,
                    base: withArchivedQueryResult.preInvoicesPending.base
                        .map((p) => updateIPendingPreInvoiceEmailIfSellerIdentified(p, preInvoiceSenderEmail, sellerId))
                }
            })
        );
    } // else was not loaded : nothing to update

    const pendingOnlyQueryKey = preInvoicesQueryKey(false);
    const pendingOnlyQueryResult: IPreInvoicesQueryResult | undefined = queryClient.getQueryData<IPreInvoicesQueryResult>(pendingOnlyQueryKey);
    if (!!pendingOnlyQueryResult) {
        queryClient.setQueryData<IPreInvoicesQueryResult>(
            pendingOnlyQueryKey,
            () => ({
                ...pendingOnlyQueryResult,
                preInvoicesPending: {
                    ...pendingOnlyQueryResult.preInvoicesPending,
                    base: pendingOnlyQueryResult.preInvoicesPending.base
                        .map((p) => updateIPendingPreInvoiceEmailIfSellerIdentified(p, preInvoiceSenderEmail, sellerId))
                }
            })
        );
    }

    queryClient.setQueryData<IPreInvoiceDetailsQueryResult>(
        preInvoiceDetailsQueryKey(preInvoiceId),
        (old: IPreInvoiceDetailsQueryResult | undefined) => ({
            ...old!,
            preInvoiceDetails: {
                ...old!.preInvoiceDetails,
                email: {
                    ...old!.preInvoiceDetails.email,
                    matchingSeller: {
                        id: sellerId,
                        isBlocked: response.isBlocked,
                        certif: response.certif,
                        company: {
                            id: response.companyId,
                            identifier: response.identifier
                        }
                    }
                }
            }
        })
    );

}

const updateIPendingPreInvoiceEmailIfSellerIdentified = (
    preInvoice: IPreInvoice<IPendingPreInvoiceEmail>,
    preInvoiceSenderEmail: string,
    sellerId: string
) : IPreInvoice<IPendingPreInvoiceEmail> =>
    preInvoice.email.senderEmail === preInvoiceSenderEmail
        ? ({
            ...preInvoice,
            email: {
                ...preInvoice.email,
                matchingSellerId: sellerId
            }
        }) : preInvoice;

const PreInvoiceIdentifySellerButtonEnabled = (
    {
        className,
        axios,
        preInvoiceId,
        preInvoiceSenderEmail,
        sellerId
    } : {
        className?: string;
        axios: Axios;
        preInvoiceId: string;
        preInvoiceSenderEmail: string;
        sellerId: string;
    }
) => {
    const queryClient: QueryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const identifySellerMutation: UseMutationResult<IIdentifySellerResponse, any, string> =
        useMutation<IIdentifySellerResponse, any, string>(
            async (sellerId2) =>
                (await axios.put<IIdentifySellerResponse>(
                    `/adminPreInvoices/${preInvoiceId}/identifySeller`,
                    {
                        sellerId: sellerId2
                    }
                )).data
            ,
            ({
                onSuccess: (response, sellerId2) => {
                    updateQueries(queryClient, preInvoiceId, preInvoiceSenderEmail, sellerId2, response);  // else was not loaded : nothing to update
                }
            })
        );

    return (
        <ButtonMutationEnabled className={className}
                               id='identifySellerButton'
                               kind={KIND_PRIMARY}
                               size={SIZE_FIXED}
                               text={ValidateText}
                               mutation={identifySellerMutation}
                               displayFullError={true}
                               value={sellerId} />
    );
}

const PreInvoiceIdentifySellerLoaded = (
    {
        className,
        sellers,
        axios,
        preInvoiceId,
        preInvoiceSenderEmail
    }: {
        className?: string;
        sellers: ReadonlyArray<ISellerForIdentifyPreInvoice>;
        axios: Axios;
        preInvoiceId: string;
        preInvoiceSenderEmail: string;
    }
) => {
    const [selectedSeller, setSelectedSeller] = useState<IOption<ISellerForIdentifyPreInvoice> | null >(null);

    return (
        <div className={className}>
            <span className='title p-both-center'>Sélectionner un seller</span>

            <div className='p-margin-top-4'>
                <SingleSelect options={sellers.map(s => ({ label: formatCompany(s.company), value: s }))}
                              selectedOption={selectedSeller}
                              selectOption={setSelectedSeller} />
            </div>

            { !!selectedSeller
                ? <PreInvoiceIdentifySellerButtonEnabled className='p-margin-top-4'
                                                         axios={axios}
                                                         preInvoiceSenderEmail={preInvoiceSenderEmail}
                                                         preInvoiceId={preInvoiceId}
                                                         sellerId={selectedSeller.value.id} />
                : <ButtonDisabled className='p-margin-top-4'
                                  text={ValidateText}
                                  kind={KIND_PRIMARY}
                                  size={SIZE_FIXED} />
            }
        </div>
    );
}

interface ISellerForIdentifyPreInvoice {
    id: string;
    email: string;
    company: {
        name: string;
        identifier: IIntCompanyIdentifier;
    };
}

const IdentifySellerModalContent = (
    {
        axios,
        preInvoiceId,
        preInvoiceSenderEmail
    }: {
        axios: Axios;
        preInvoiceId: string;
        preInvoiceSenderEmail: string;
    }
) =>
    <GraphQLQueryWrapper axios={axios}
                         queryKey={['sellers', 'short']}
                         queryParams={{
                             query: `{
                                 sellers {
                                        id
                                        email
                                        company { name identifier { ${IntCompanyIdentifierGraphQLFields} } }
                                }
                            }`
                         }}
                         onLoading={() => <Loader /> /* Pourrait etre ameliore */}
                         onSuccess={(r: { sellers: ReadonlyArray<ISellerForIdentifyPreInvoice> }) =>
                             <PreInvoiceIdentifySellerLoaded className='p-padding-4'
                                                             sellers={r.sellers}
                                                             axios={axios}
                                                             preInvoiceId={preInvoiceId}
                                                             preInvoiceSenderEmail={preInvoiceSenderEmail} />
                         } />;

const PreInvoiceIdentifySeller = (
    {
        axios,
        preInvoiceId,
        preInvoiceSenderEmail
    }: {
        axios: Axios;
        preInvoiceId: string;
        preInvoiceSenderEmail: string;
    }
) =>
    <ButtonForModal id='identifySeller'
                    text='Identifier le Seller'
                    modalMaxWidth='640px'
                    modalFullMaxWidth={false}
                    childModalContent={() =>
                        <IdentifySellerModalContent axios={axios}
                                                    preInvoiceId={preInvoiceId}
                                                    preInvoiceSenderEmail={preInvoiceSenderEmail} />
                    }/>
export default PreInvoiceIdentifySeller;

