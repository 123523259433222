import {Navigate, useOutletContext} from "react-router-dom";
import BuyerPage from "./BuyerPage";
import BuyerTabs from "./BuyerTabs";
import {
    buyerUrl, BuyerUrlMapping, WithBuyerCompanyIdParamWrapper, WithIncludeBuyersWithoutInvoiceQueryParamWrapper
} from "./buyersUrls";
import IRouteDefinitionWithAxiosAndDataLoaderAndRolesO from "./IRouteDefinitionWithAxiosAndDataLoaderAndRolesO";
import {ReactElement} from "react";
import IAccessTokenWithDataLoader from "./IAccessTokenWithDataLoader";
import IBuyersRoute from "./IBuyersRoute";

interface IBuyerRoute {
    path: string;
    titleO?: string;
    component: (accessTokenWithDataLoaderO: IAccessTokenWithDataLoader | null, buyerCompanyId: string /* non-null */) => ReactElement;
    children?: IRouteDefinitionWithAxiosAndDataLoaderAndRolesO[];
}

const buyerRoutes: IBuyerRoute[] = [
    // Default redirect
    {
        path: BuyerUrlMapping,
        component: (_, buyerCompanyId: string) =>
            <WithIncludeBuyersWithoutInvoiceQueryParamWrapper child={(initialIncludeBuyersWithoutInvoice: boolean) =>
                <Navigate to={buyerUrl(buyerCompanyId, initialIncludeBuyersWithoutInvoice)}/>
            }/>
    },
    ...BuyerTabs
];

const WithAccessTokenAndDataloaderOAndBuyerCompanyIdContext = (
    {
        child
    }: {
        child: (accessTokenWithDataLoaderO: IAccessTokenWithDataLoader | null, buyerCompanyId: string) => ReactElement;
    }
) => {
    const {accessTokenWithDataLoaderO, buyerCompanyId} =
        useOutletContext<{accessTokenWithDataLoaderO: IAccessTokenWithDataLoader | null ; buyerCompanyId: string;}>();
    return child(accessTokenWithDataLoaderO, buyerCompanyId);
}

const BuyerRoute: IBuyersRoute = ({
    path: BuyerUrlMapping,
    titleO: 'Buyer',
    component: (accessTokenWithDataLoaderO) =>
        <WithBuyerCompanyIdParamWrapper child={(buyerCompanyId) =>
            <BuyerPage accessTokenWithDataLoaderO={accessTokenWithDataLoaderO}
                       buyerCompanyId={buyerCompanyId!} />
        }/>,
    children: buyerRoutes.map((p) => ({
        path: p.path,
        titleO: p.titleO,
        children: p.children,
        component:
            <WithAccessTokenAndDataloaderOAndBuyerCompanyIdContext child={(accessTokenWithDataLoaderO: IAccessTokenWithDataLoader | null, buyerCompanyId: string) =>
                p.component(accessTokenWithDataLoaderO, buyerCompanyId)
            }/>
    }))
});

export default BuyerRoute;