import {COUNTRY_OPTIONS, countryOptionsByCode, DEFAULT_FRANCE} from "./Countries";
import Input from "factor-lib/forms/Inputs/Input";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import { validateMandatoryFieldError } from "factor-lib/forms/Inputs/utils";

export const getInitialNewAddressInputO = (): IAddressOInput => ({
    streetAddress: '',
    city: '',
    postCode: '',
    countryCode: null
});

export const getInitialNewAddressInput = (): IAddressInput => ({
    streetAddress: '',
    city: '',
    postCode: '',
    countryCode: DEFAULT_FRANCE.value
});

export interface IGAddressInput<CC extends string | null> {
    streetAddress: string;
    city: string;
    postCode: string;
    countryCode: CC;
}

export interface IAddressOInput extends IGAddressInput<string | null>{ }
export interface IAddressInput extends IGAddressInput<string>{ }

interface IGAddressInputEnabled<CC extends string | null, I extends IGAddressInput<CC>> {
    autofocus: boolean;

    setAddressInput: (address: I) => void;
}

export interface IAddressOInputEnabled extends IGAddressInputEnabled<string | null, IAddressOInput>{ }
export interface IAddressInputEnabled extends IGAddressInputEnabled<string, IAddressInput>{ }

const GAddressInput = <CC extends string | null, I extends IGAddressInput<CC>>(
    {
        className,
        input,
        enabled,
        isMandatory
    }: {
        className?: string;
        input: I;
        enabled: IGAddressInputEnabled<CC, I> | null;
        isMandatory: boolean;
    }
) =>
    <div className={className}>
        <div>
            <div className='p-bold'>Numéro et libellé de la voie</div>
            <Input inputValue={input.streetAddress}
                   placeHolder='Adresse'
                   enabled={(!!enabled && ({
                       updateInputValue: (streetAddress: string) => enabled.setAddressInput({
                           ...input,
                           streetAddress
                       }),
                       validateInput: () => validateMandatoryFieldError(input.streetAddress)
                   })) || null} />
        </div>

        <div className='columns p-margin-top-6'>
            <div className='column'>
                <span className='p-bold'>Ville</span>
                <Input inputValue={input.city}
                       placeHolder='Ville'
                       enabled={(!!enabled && ({
                           updateInputValue: (city: string) => enabled.setAddressInput({
                               ...input,
                               city
                           }),
                           validateInput: () => validateMandatoryFieldError(input.city)
                       })) || null} />
            </div>

            <div className='column'>
                <span className='p-bold'>Code Postal</span>
                <Input inputValue={input.postCode}
                        placeHolder='Code Postal'
                        enabled={(!!enabled && ({
                            updateInputValue: (postCode: string) => enabled.setAddressInput({
                                ...input,
                                postCode
                            }),
                            validateInput: () => validateMandatoryFieldError(input.postCode)
                        })) || null} />
            </div>
        </div>

        <div>
            <span className='p-bold'>Pays</span>
            <SingleSelect options={COUNTRY_OPTIONS}
                          placeholder='Pays'
                          selectedOption={input.countryCode !== null ? countryOptionsByCode.get(input.countryCode)! : null}
                          selectOption={!!enabled
                              ? (newOption) => enabled.setAddressInput({
                                  ...input,
                                  countryCode: newOption?.value ?? null
                              })
                              : null
                          }
                          clearable={!isMandatory} />
        </div>
    </div>;

// Non-null country code
export const AddressInput = (
    {
        className,
        input,
        enabled
    }: {
        className?: string;
        input: IAddressInput;
        enabled: IAddressInputEnabled | null;
    }
) =>
    <GAddressInput<string, IAddressInput> className={className}
                                          input={input}
                                          enabled={enabled}
                                          isMandatory={true}/>

// Nullable country code
export const AddressOInput = (
    {
        className,
        input,
        enabled
    }: {
        className?: string;
        input: IAddressOInput;
        enabled: IAddressOInputEnabled | null;
    }
) =>
    <GAddressInput<string | null, IAddressOInput> className={className}
                                                  input={input}
                                                  enabled={enabled}
                                                  isMandatory={false}/>;
