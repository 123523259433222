import {GraphiQL} from "graphiql";
import Loader from "factor-lib/Loader";
import IAccessToken from "../IAccessToken";

import 'graphiql/graphiql.min.css';
import './graphqlexplorerwrapper.scss';

// https://github.com/graphql/graphiql/blob/main/examples/graphiql-create-react-app/src/App.tsx
const GraphQLExplorerWrapperPage = (
    {
        accessTokenO
    }: {
        accessTokenO : IAccessToken | null
    }
) =>
    !!accessTokenO
        ? <GraphiQL fetcher={async (graphQLParams) =>
            // Cannot reuse graphQLQueryAsync : we need extra fields other than 'data'
            (await accessTokenO.axios.post(
                '/graphql',
                graphQLParams
            )).data } /> : <Loader />;

export default GraphQLExplorerWrapperPage;
