import {
    ICustomerFinancingRequestAcceptationStatus,
    ICustomerFinancingRequestAcceptedStatus,
    IInvoiceStatus,
    invoiceWithStatusGraphQLQuery
} from "../Invoices/invoiceStatus";
import {
    BuyerEventGraphQLFields,
    IBuyerEvent,
    IBuyerEventInvoiceMatch
} from "../Buyers/BuyersInfo/buyerCompanyDetailsQuery";
import {DimplOpsGraphQLFields, IDimplOps} from "../../utils/IDimplOps";
import { IInvoicePayment } from 'factor-lib/utils/invoicePayment';
import { MindeeFieldEnum, MindeeIbanComplianceEnum } from "../../utils/mindeeUtils";
import { IIntCompanyIdentifier, IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import {InvoiceQueryKeyPrefix} from "../../utils/InvoiceUtils";
import { ConfidenceFlag } from "../../utils/ConfidenceFlags";

export const InvoiceDetailsQueryKeyPrefix =
    [...InvoiceQueryKeyPrefix, 'details'];

export const invoiceDetailsQueryKey = (invoiceId: string): string[] =>
    [...InvoiceDetailsQueryKeyPrefix, invoiceId];

export interface IInvoiceDetailsStatus extends IInvoiceStatus<
    IInvoiceDetailsCustomerFinancingRequestStatus,
    IInvoiceDetailsCustomerFinancingRequestAcceptedStatus,
    IInvoiceDetailsFinancingStatus
> {

}

export interface IInvoiceDetailsCustomerFinancingRequestAcceptedStatus extends ICustomerFinancingRequestAcceptationStatus<IInvoiceDetailsFinancingStatus> {
    autoApproved: boolean;
    creationDateTime: string;
}

export interface IInvoiceDetailsCustomerFinancingRequestStatus extends ICustomerFinancingRequestAcceptedStatus<
    IInvoiceDetailsCustomerFinancingRequestAcceptedStatus,
    IInvoiceDetailsFinancingStatus
> {
    id: string;
    creationDateTime: string;
    rejectedReason: string | null;
    amountForMarketplace: number | null;
}

export interface IFinancingMerciFacteurMail {
    creationDateTime: string;
    receivedDateTime: string | null;
    dimplOps: {
        name: string;
    };
    type: number;
}

export type ISettledO = {
    dateTime: string;
    isManual: boolean
} | null;

export interface IInvoiceDetailsFinancingStatus /* extends IFinancingStatus */ {
    id: string;
    creationDateTime: string;
    amount: number;
    reminderEmailsPaused: boolean;
    commingLingAmountToReturn : number;
    merciFacteurMails: IFinancingMerciFacteurMail[];
    settled: ISettledO;
}

export interface IAdminBasePayment {
    type: number; // null -> irrelevant
    specificPaymentId: string | null;
}

export interface IAdminInvoicePayment extends IInvoicePayment<IAdminBasePayment> {
    id: string;
    creationDateTime: string;
}

export interface IInvoiceDetailsPaymentStatus {
    completePaidDate: string | null;
    partialAmountPaid: number;
    partialAmountPaidByBuyer: number;

    payments: IAdminInvoicePayment[];
}

export interface IInvoiceOpsTags {
    fraud: boolean;
    specialSeeNotes: boolean;
    virtualIbanVisible: boolean;
    mandatoryPayer: boolean;
    notInsured: boolean;
    specificPaymentFlow: boolean;
    publicBuyer: boolean;

    onboardingBuyerNeeded: boolean;
    onboardingBuyerOkPhone: boolean;
    onboardingBuyerOkEmail: boolean;

    runningCheckOkEmail: boolean;
    runningCheckOkPhone: boolean;
    runningCheckBuyerNeeded: boolean;

    subrogationLetterNeeded: boolean;
}

export interface IInvoiceAdditionalDocument {
    id: string;
    blobFile: {
        fileName: string;
    };
    creationDateTime: string;
    givenDate: string | null;
    type: string;
    title: string | null;
}

export interface ISellerCompany {
    id: string;
    name: string;
    identifier: IIntCompanyIdentifier;
}

export interface IBuyerCompany {
    id: string;
    name: string;
    identifier: IIntCompanyIdentifier;
}

export interface IInvoiceMindee {
    undetectedFields: MindeeFieldEnum[];
    conflictingFields: MindeeFieldEnum[];
    ibanCompliance: MindeeIbanComplianceEnum;
}

export type Certif = {
    solvaConfidenceFlag: ConfidenceFlag;
    fraudConfidenceFlag: ConfidenceFlag;
} | null;

export interface IInvoiceDetailsSeller {
    id: string;
    email: string;
    phone: string;
    isBlocked: boolean;
    certif: Certif;
    company: ISellerCompany;
    customer: {
        directSeller: {
            id: string;
        } | null;
    };
    swanInstantPayment: boolean;
}

export const invoiceDetailsQuery = (invoiceId: string): IGraphQLParams => ({
    query: `query Q($invoiceId: Guid!) {
        invoiceDetails (id: $invoiceId) {
            ${invoiceWithStatusGraphQLQuery(
                `
                    id
                    creationDateTime
                    seller {
                        id
                        email
                        phone
                        isBlocked
                        certif {
                            solvaConfidenceFlag
                            fraudConfidenceFlag
                        }
                        customer {
                            directSeller { id }
                        }
                        swanInstantPayment
                            company { id name identifier { ${IntCompanyIdentifierGraphQLFields} } }
                    }
                    buyer {
                        emails
                        phones
                        company { id name identifier { ${IntCompanyIdentifierGraphQLFields} } }
                    }
                    payment {
                        completePaidDate
                        partialAmountPaid
                        partialAmountPaidByBuyer
                        payments {
                            id
                            creationDateTime
                            amount
                            isComplete
                            base { type specificPaymentId }
                        }
                    }
                    number
                    issueDate
                    dueDate
                    amountWoTax
                    amountTax
                    blockedByAdmin
                    opsType
                    opsTags {
                        fraud
                        specialSeeNotes
                        virtualIbanVisible
                        mandatoryPayer
                        notInsured
                        specificPaymentFlow
                        publicBuyer
                        onboardingBuyerNeeded
                        onboardingBuyerOkPhone
                        onboardingBuyerOkEmail
                        runningCheckOkEmail
                        runningCheckOkPhone
                        runningCheckBuyerNeeded
                        subrogationLetterNeeded
                    }
                    opsNotes
                    effectiveAccountManager { ${DimplOpsGraphQLFields} }
                    file {
                        fileName
                        mindee {
                            undetectedFields
                            conflictingFields
                            ibanCompliance
                        }
                    }
                    additionalDocuments {
                        id
                        blobFile { fileName }
                        creationDateTime
                        givenDate
                        type
                        title
                    }
                `,
                `id creationDateTime rejectedReason amountForMarketplace`,
                `creationDateTime autoApproved`,
                `id creationDateTime amount reminderEmailsPaused commingLingAmountToReturn merciFacteurMails { creationDateTime receivedDateTime dimplOps { name } type }`,
                'isManual'
            )}
        }
        buyerEvents (invoiceId: $invoiceId) {
            ${BuyerEventGraphQLFields}
        }
    }`,
    variables: {
        invoiceId
    }
});

export interface IInvoiceDetails {
    id: string;
    creationDateTime: string;

    seller: IInvoiceDetailsSeller;

    buyer: {
        emails: string[];
        phones: string[];
        company: IBuyerCompany;
    };

    payment: IInvoiceDetailsPaymentStatus;
    status: IInvoiceDetailsStatus;

    number: string;
    issueDate: string;
    dueDate: string;
    amountWoTax: number;
    amountTax: number;

    blockedByAdmin: boolean;
    opsType: string | null;
    opsTags: IInvoiceOpsTags;
    opsNotes: string | null;
    effectiveAccountManager: IDimplOps;

    file: {
        fileName: string;
        mindee: IInvoiceMindee | null;
    };
    additionalDocuments: IInvoiceAdditionalDocument[];
}

export interface IInvoiceDetailsQueryResult {
    invoiceDetails: IInvoiceDetails;
    buyerEvents: IBuyerEvent[];
}

export interface IInvoiceDetailsEventsInfosQueryResult {
    buyerEventsInvoiceMatches: IBuyerEventInvoiceMatch[];
}

export const buyerEventsInvoiceMatchesKey = (companyId: string | undefined): string[] =>
    [`buyer-events-invoice-matches-${companyId}`];
