import { dateDiffDays, serverDateDeserialization, serverDateSerialization } from "factor-lib/utils/dateUtils";
import { isValidString } from "factor-lib/forms/Inputs/utils";
import IAddress, { getAddressParams, getUpdateAddressParams, IUpdateAddressParams, updateAddress } from "../IAddress";
import IBirthLocation, {
    getBirthLocationParams, getUpdateBirthLocationParams, IUpdateBirthLocationParams, updateBirthLocation
} from "../IBirthLocation";
import { ResultOrErrors } from "../ResultOrErrors";
import { parseDateInput } from "factor-lib/forms/DateInput/DateInput";
import {IAddressOInput} from "../AddressInput";

export default interface IPerson {
    firstName: string;
    lastName: string;
    birthDate: string | null;
    birthLocation: IBirthLocation;
    nationalityCode: string | null;
    residenceAddress: IAddress | null;
}

// Same, but most fields are nullable
export interface IUpdatePersonParams {
    // Null -> no update
    firstName: string | null;
    lastName: string | null;
    nationalityCode: string | null;
    birthDate: string | null;
    birthLocation: IUpdateBirthLocationParams | null;
    residenceAddress: IUpdateAddressParams | null;
}

const getPersonValidationErrors = (
    firstNameInput: string,
    lastNameInput: string,
    isBirthInfosMandatory: boolean,
    birthDateInput: string
): string[] => {
    const errors: string[] = [];

    if (!isValidString(firstNameInput)) {
        errors.push(`Le prénom est invalide`);
    }

    if (!isValidString(lastNameInput)) {
        errors.push(`Le nom est invalide`);
    }

    if (isBirthInfosMandatory || isValidString(birthDateInput)) {
        const birthDateInputParsed: Date | null = parseDateInput(birthDateInput);
        if (birthDateInputParsed === null) {
            errors.push(`La date de naissance est invalide`);
        } else if (dateDiffDays(birthDateInputParsed, new Date()) <= 0) {
            errors.push(`La date de naissance doit être dans le passé`);
        }
    }

    return errors;
};

export const getPersonParams = (
    firstNameInput: string,
    lastNameInput: string,
    nationalityCodeInput: string | null,

    isBirthInfosMandatory: boolean,
    birthDateInput: string,
    birthCityInput: string,
    birthCountryCodeInput: string | null,

    residenceAddressInput: IAddressOInput
): ResultOrErrors<IPerson> => {

    const validationErrors: string[] = getPersonValidationErrors(
        firstNameInput,
        lastNameInput,
        isBirthInfosMandatory,
        birthDateInput
    );

    const birthLocation: ResultOrErrors<IBirthLocation> = getBirthLocationParams(
        isBirthInfosMandatory,
        birthCityInput,
        birthCountryCodeInput
    );
    const residenceAddress: ResultOrErrors<IAddress> | null = getAddressParams(
        residenceAddressInput.streetAddress,
        residenceAddressInput.city,
        residenceAddressInput.postCode,
        residenceAddressInput.countryCode
    );

    if (!!birthLocation.errors) {
        validationErrors.push(...birthLocation.errors);
    }

    if (!!residenceAddress && !!residenceAddress.errors) {
        validationErrors.push(...residenceAddress.errors);
    }

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    return ResultOrErrors.fromResult({
        firstName: firstNameInput,
        lastName: lastNameInput,
        nationalityCode: nationalityCodeInput,
        birthDate: isValidString(birthDateInput) ? serverDateSerialization(parseDateInput(birthDateInput)!) : null,
        birthLocation: birthLocation.result!,
        residenceAddress: !!residenceAddress ? residenceAddress.result : null
    });
};

export const getUpdatePersonParams = (
    old: IPerson,
    personParamsFailable: ResultOrErrors<IPerson>,
): ResultOrErrors<IUpdatePersonParams> | null => {

    if (personParamsFailable.errors) {
        return ResultOrErrors.fromError(personParamsFailable.errors);
    }

    const personParams = personParamsFailable.result!;

    const firstNameUpdate: string | null = personParams.firstName !== old.firstName ? personParams.firstName : null;
    const lastNameUpdate: string | null = personParams.lastName !== old.lastName ? personParams.lastName : null;
    const nationalityUpdate: string | null = !!personParams.nationalityCode && personParams.nationalityCode !== old.nationalityCode ? personParams.nationalityCode : null;

    const birthDateUpdate: string | null =
        // On peut supposer qu'on ne supprime pas un birthdate
        !!old.birthDate ?
            dateDiffDays(serverDateDeserialization(personParams.birthDate!), serverDateDeserialization(old.birthDate)) !== 0
                ? personParams.birthDate
                : null
        : personParams.birthDate;

    const birthLocationUpdate: IUpdateBirthLocationParams | null =
        getUpdateBirthLocationParams(old.birthLocation, personParams.birthLocation);

    const residenceAddressUpdate: IUpdateAddressParams | null = !!old.residenceAddress && !!personParams.residenceAddress
        ? getUpdateAddressParams(old.residenceAddress, personParams.residenceAddress)
        : personParams.residenceAddress;

    return (
            firstNameUpdate !== null ||
            lastNameUpdate !== null ||
            nationalityUpdate !== null ||
            birthDateUpdate !== null ||
            birthLocationUpdate !== null ||
            residenceAddressUpdate !== null
        ) ? ResultOrErrors.fromResult({
            firstName: firstNameUpdate,
            lastName: lastNameUpdate,
            nationalityCode: nationalityUpdate,
            birthDate: birthDateUpdate,
            birthLocation: birthLocationUpdate,
            residenceAddress: residenceAddressUpdate
        }) : null;
};

// if any update field is null, old cannot be null
export const updatePerson = (
    old: IPerson | null,
    updateParams: IUpdatePersonParams
): IPerson => ({
    firstName: updateParams.firstName ?? old!.firstName,
    lastName: updateParams.lastName ?? old!.lastName,
    nationalityCode: updateParams.nationalityCode ?? old!.nationalityCode,
    birthDate: updateParams.birthDate ?? old!.birthDate,
    birthLocation: !!updateParams.birthLocation ? updateBirthLocation(!!old ? old.birthLocation : null, updateParams.birthLocation) : old!.birthLocation,
    residenceAddress: !!updateParams.residenceAddress ? updateAddress(!!old ? old.residenceAddress : null, updateParams.residenceAddress) : old!.residenceAddress
});
