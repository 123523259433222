import { Axios } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { preInvoicesQueryKey } from "./preInvoicesQuery";

interface ISyncPreInvoicesResult {
    addedCount: number;
    errorCount: number;
}

const SyncPreInvoicesButtonEnabled = (
    {
        axios,
        text
    }: {
        axios: Axios;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const syncPreInvoicesMutation: UseMutationResult<ISyncPreInvoicesResult, any, null> =
        useMutation<ISyncPreInvoicesResult, any, null>(
            async () =>
                (await axios.post<ISyncPreInvoicesResult>(
                    `/adminPreInvoices/sync`,
                    null
                )).data,
            ({
                onSuccess: async (response) => {
                    window.alert(`${response.addedCount} PreInvoices ont été ajoutés. ${response.errorCount} erreurs ont été encontrées.`);
                    if (response.addedCount > 0) {
                        return Promise.all([
                            queryClient.invalidateQueries(preInvoicesQueryKey(true)),
                            queryClient.invalidateQueries(preInvoicesQueryKey(false))
                        ]);
                    } // else
                    return Promise.resolve();
                }
            })
        );

    return (
        <ButtonMutationEnabled id='syncPreInvoicesButton'
                               text={text}
                               mutation={syncPreInvoicesMutation}
                               displayFullError={true}
                               value={null} />

    );
}

export default SyncPreInvoicesButtonEnabled;
