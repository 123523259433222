import {Link, NavigateFunction} from "react-router-dom";
import Button from "factor-lib/Buttons/Button";
import {addSellerForCustomerUrl, sellerUrl} from "../../../Sellers/sellersUrls";
import {useContext} from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";

// TODO: poor

const CustomerDirectInfosSection = (
    {
        customerId,
        sellerId
    }: {
        customerId: string;
        sellerId: string | null;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    return (
        !sellerId
            ? <div className='p-horizontal-center'>
                Cette plateforme directe n'est pas encore liée à son Seller
                <Button className='p-margin-left-5'
                        id='addDirectSellerSellerButton'
                        text='Ajouter le seller'
                        isLoading={false}
                        actionO={() => navigate(addSellerForCustomerUrl(customerId)) }/>


            </div>
            : <div>
                Cette plateforme directe est déja liée à son Seller :
                &nbsp;[
                <Link id='directCustomerSellerLink'
                      to={sellerUrl(sellerId)}>
                    lien
                </Link>
                ]
            </div>
    );
}

export default CustomerDirectInfosSection;
