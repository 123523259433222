import {Axios} from "axios";
import { useState } from "react";
import IAuthUser from "../../../../IAuthUser";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import InputAmount, {parseInputAmount, validateAmountError } from "factor-lib/forms/Inputs/InputAmount";
import ShowValidationErrorsButton from "../../../../utils/ShowValidationErrorsButton";
import { customerDetailsQueryKey, ICustomerDetailsQueryResult } from "../customerDetailsQuery";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { formatAmount } from "factor-lib/utils/amountUtils";

const UpdateText = 'Modifier';

const UpdateOutstandingLimitPerSellerEnabled = (
    {
        axios,
        customerId,
        marketplaceId,
        newOutstandingLimitPerSeller,
        kind,
        size,
        text
    }: {
        axios: Axios;
        customerId: string;
        marketplaceId: string;
        newOutstandingLimitPerSeller: number;
        kind?: string;
        size?: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateOutstandingLimitPerSellerMutation: UseMutationResult<void, any, number, void> =
        useMutation<void, any, number, void>(
            async (newOutstandingLimitPerSeller2: number) => {
                await axios.put<void>(
                    `/adminMarketplaces/${marketplaceId}/outstandingLimitPerSeller`,
                    {
                        outstandingLimitPerSeller: newOutstandingLimitPerSeller2
                    }
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<ICustomerDetailsQueryResult>(
                        customerDetailsQueryKey(customerId),
                        (old) => ({
                            customerDetails: {
                                ...old!.customerDetails,
                                marketplace: {
                                    ...old!.customerDetails.marketplace!,
                                    outstandingLimitPerSeller: newOutstandingLimitPerSeller
                                }
                            }
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateOutstandingLimitPerSeller'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateOutstandingLimitPerSellerMutation}
                               displayFullError={true}
                               value={newOutstandingLimitPerSeller} />
    );
};

const CustomerMarketplaceInfosSection = (
    {
        axios,
        authUser,
        customerId,
        marketplaceId,
        outstandingLimitPerSeller
    }: {
        axios: Axios;
        authUser: IAuthUser | null;
        customerId: string;
        marketplaceId: string;
        outstandingLimitPerSeller: number;
    }
) => {
    const initialOutstandingLimitPerSellerInput = (outstandingLimitPerSeller / 100).toString();

    const [newOutstandingLimitPerSellerInput, setNewOutstandingLimitPerSellerInput] = useState(initialOutstandingLimitPerSellerInput);

    const validationError: string | null = validateAmountError(newOutstandingLimitPerSellerInput);

    return (
        <div className='p-horizontal-center'>
            <span className='p-bold p-padding-5'>Maximum total amount per Seller</span>

            { !authUser?.canManageProfiles
                ? <span>{ formatAmount(outstandingLimitPerSeller) }</span>
                : <div className='p-horizontal-center'>
                    <InputAmount inputAmountValue={newOutstandingLimitPerSellerInput}
                                 enabled={{
                                     updateInputValue: setNewOutstandingLimitPerSellerInput
                                 }} />

                    <div className='p-padding-left-4'>
                        { !!validationError
                            ? <ShowValidationErrorsButton validationErrors={[validationError]}
                                                          text={UpdateText} />
                            : parseInputAmount(newOutstandingLimitPerSellerInput) !== outstandingLimitPerSeller
                                ? <UpdateOutstandingLimitPerSellerEnabled axios={axios}
                                                                          customerId={customerId}
                                                                          marketplaceId={marketplaceId}
                                                                          newOutstandingLimitPerSeller={parseInputAmount(newOutstandingLimitPerSellerInput)}
                                                                          text={UpdateText} />
                                : <ButtonDisabled text={UpdateText} />
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default CustomerMarketplaceInfosSection;
