import {Axios} from "axios";
import { useState } from "react";
import { IInvoiceDetailsQueryResult, invoiceDetailsQueryKey } from "../../invoiceDetailsQuery";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import Input from "factor-lib/forms/Inputs/Input";
import ButtonDisabled from "factor-lib/Buttons/ButtonDisabled";
import { ResultOrErrors } from "../../../../utils/ResultOrErrors";
import ShowValidationErrorsButton from "../../../../utils/ShowValidationErrorsButton";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib/Buttons/Button";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import Upload from "factor-lib/forms/Upload/Upload";
import windowErrorHandler from "../../../../utils/windowErrorHandler";
import { invoiceFileQueryKey } from "./InvoiceFiles";
import { FileUploadTimeoutMilliseconds } from "factor-lib/serverUtils/axiosConfigUtils";
import {
    validateBuyerEmailsErrorsCollector,
    validateBuyerPhonesErrorsCollector
} from "../../../../utils/newInvoiceUtils";
import ButtonForModal from "factor-lib/Buttons/ButtonForModal/ButtonForModal";
import { PDF_OR_JPEG_OR_PNG_SET } from "factor-lib/forms/Upload/uploadUtils";
import {isValidString, validateMandatoryFieldError } from "factor-lib/forms/Inputs/utils";

const ConfirmText = 'Confirmer';

interface IUpdateInvoiceMinorInfosParams {
    number: string | null;
    buyerEmails: string[] | null;
    buyerPhones: string[] | null;
}

const UpdateInvoiceMinorInfosEnabled = (
    {
        invoiceId,
        axios,
        updateParams,
        closeModal,
        kind,
        size,
        text
    } : {
        invoiceId: string;
        axios: Axios;
        updateParams: IUpdateInvoiceMinorInfosParams;
        closeModal: () => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const updateInvoiceMinorInfosMutation: UseMutationResult<void, any, IUpdateInvoiceMinorInfosParams> =
        useMutation<void, any, IUpdateInvoiceMinorInfosParams>(
            async (updateParams2) => {
                await axios.put<void>(
                    `/adminInvoices/${invoiceId}/minorInfos`,
                    updateParams2
                );
            },
            ({
                onSuccess: () => {
                    closeModal();
                    queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                        invoiceDetailsQueryKey(invoiceId),
                        (old: IInvoiceDetailsQueryResult | undefined) => ({
                            invoiceDetails: {
                                ...old!.invoiceDetails,
                                number: updateParams.number ?? old!.invoiceDetails.number,
                                buyer: {
                                    ...old!.invoiceDetails.buyer,
                                    emails: updateParams.buyerEmails ?? old!.invoiceDetails.buyer.emails,
                                    phones: updateParams.buyerPhones ?? old!.invoiceDetails.buyer.phones,
                                }
                            },
                            buyerEvents: old!.buyerEvents
                        })
                    );
                }
            })
        );

    return (
        <ButtonMutationEnabled id='updateInvoiceMinorInfosButton'
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={updateInvoiceMinorInfosMutation}
                               displayFullError={true}
                               value={updateParams} />
    );
}

const areArraySameContent = <S, >(a: S[], b: S[]): boolean => {

    // The faster test
    if (a.length !== b.length) {
        return false;
    }

    const aSet = new Set<S>(a);
    for (const x of b) {
        if (!aSet.has(x)) {
            return false;
        }
    }

    return true;
};

const ListInputSeparator = ';';

const getUpdateInvoiceMinorInfosParams = (
    oldNumber: string,
    oldBuyerEmails: string[],
    oldBuyerPhones: string[],

    newNumberInput: string,
    newBuyerEmailsSingle: string,
    newBuyerPhonesSingle: string
): ResultOrErrors<IUpdateInvoiceMinorInfosParams> | null => {
    const validationErrors: string[] = [];

    if (!isValidString(newNumberInput)) {
        validationErrors.push('Le numéro est invalide');
    }

    const newBuyersEmails: string[] = newBuyerEmailsSingle.split(ListInputSeparator);
    const newBuyersPhones: string[] = newBuyerPhonesSingle.split(ListInputSeparator)
        .map((bp) => bp.trim())
        .filter((bp) => bp.length > 0);

    validateBuyerEmailsErrorsCollector(validationErrors, newBuyersEmails);
    validateBuyerPhonesErrorsCollector(validationErrors, newBuyersPhones);

    if (validationErrors.length !== 0) {
        return ResultOrErrors.fromError(validationErrors);
    }

    const numberUpdate: string | null = newNumberInput !== oldNumber ? newNumberInput : null;
    const buyerEmailUpdate: string[] | null = areArraySameContent(oldBuyerEmails, newBuyersEmails) ? null : newBuyersEmails;
    const buyerPhoneUpdate: string[] | null = areArraySameContent(oldBuyerPhones, newBuyersPhones) ? null : newBuyersPhones;

    return (!!numberUpdate || !!buyerEmailUpdate || !!buyerPhoneUpdate) ?
        ResultOrErrors.fromResult({
            number: numberUpdate,
            buyerEmails: buyerEmailUpdate,
            buyerPhones: buyerPhoneUpdate
        }) : null;
}

const updateInvoiceFileAsync = async (
    axios: Axios,
    invoiceId: string,
    file: File,
    onUploadProgress: (progressEvent: ProgressEvent) => void
): Promise<void> => {
    const data = new FormData();
    data.append('invoiceFile', file);

    await axios.put<void>(
        `/adminInvoiceFiles/${invoiceId}`,
        data,
        {
            onUploadProgress,
            timeout: FileUploadTimeoutMilliseconds
        }
    );

    window.alert('invoice file successfully updated');
}

const InvoiceUpdateMinorInfosModalContent = (
    {
        axios,
        invoiceId,
        number,
        buyerEmails,
        buyerPhones,
        closeModal
    } : {
        axios: Axios,
        invoiceId: string,
        number: string;
        buyerEmails: string[];
        buyerPhones: string[];
        closeModal: () => void;
    }
) => {
    const [ newNumberInput, setNewNumberInput ] = useState(number);
    const [ newBuyerEmailInput, setNewBuyerEmailInput ] = useState(buyerEmails.join(ListInputSeparator));
    const [ newBuyerPhoneInput, setNewBuyerPhoneInput ] = useState(buyerPhones.join(ListInputSeparator));

    const updateParams: ResultOrErrors<IUpdateInvoiceMinorInfosParams> | null = getUpdateInvoiceMinorInfosParams(
        number,
        buyerEmails,
        buyerPhones,

        newNumberInput,
        newBuyerEmailInput,
        newBuyerPhoneInput
    );

    const [ newFileName, setNewFileName ] = useState<string | null>(null);

    const queryClient = useQueryClient();

    return (
        <div className='p-padding-3'>
            <div>
                <span className='p-bold'>Numero de facture</span>
                <Input inputValue={newNumberInput}
                       enabled={{
                           updateInputValue: setNewNumberInput,
                           validateInput: () => validateMandatoryFieldError(newNumberInput),
                           innerId: {
                               value: 'innerNumber' /* was not set, but I think this was a mistake */,
                               autofocus: true
                           }
                       }} />

            </div>

            <div className='p-margin-top-5'>
                <span className='p-bold'>Email(s) du Buyer</span>
                <Input inputValue={newBuyerEmailInput}
                       enabled={{
                           updateInputValue: setNewBuyerEmailInput,
                           validateInput: () => validateMandatoryFieldError(newBuyerEmailInput)
                       }} />
            </div>

            <div className='p-margin-top-5'>
                <span className='p-bold'>Téléphone(s) du Buyer</span>
                <Input inputValue={newBuyerPhoneInput}
                       enabled={{
                           updateInputValue: setNewBuyerPhoneInput,
                           validateInput: () => validateMandatoryFieldError(newBuyerPhoneInput)
                       }} />
            </div>

            <div className='p-margin-top-5'>
                <span className='p-bold'>Fichier</span> (ne nécessite pas de confirmer)
                <Upload id='invoiceFileUpload'
                        acceptedFileTypesSet={PDF_OR_JPEG_OR_PNG_SET}
                        externalInitialErrors={null}
                        existingFileName={newFileName}
                        actionO={({
                            uploadFile: async (file: File, onUploadProgress: (progressEvent: ProgressEvent) => void) => {
                                await updateInvoiceFileAsync(
                                    axios,
                                    invoiceId,
                                    file,
                                    onUploadProgress
                                );
                                queryClient.removeQueries(invoiceFileQueryKey(invoiceId));
                                queryClient.setQueryData<IInvoiceDetailsQueryResult>(
                                    invoiceDetailsQueryKey(invoiceId),
                                    (old?: IInvoiceDetailsQueryResult) => ({
                                        ...old!,
                                        invoiceDetails: {
                                            ...old!.invoiceDetails,
                                            file: {
                                                ...old!.invoiceDetails.file,
                                                fileName: file.name,
                                                mindee: null // TODO !
                                            }
                                        }
                                    })
                                );
                                setNewFileName(file.name);
                            },
                            deleteFile: null,
                            errorHandler: windowErrorHandler
                        })} />
            </div>

            <div className='p-margin-top-4 p-vertical-center'>
                { !!updateParams
                    ? !!updateParams.result
                        ? <UpdateInvoiceMinorInfosEnabled axios={axios}
                                                          invoiceId={invoiceId}
                                                          updateParams={updateParams.result}
                                                          closeModal={closeModal}
                                                          kind={KIND_PRIMARY}
                                                          size={SIZE_FIXED}
                                                          text={ConfirmText} />
                        : <ShowValidationErrorsButton validationErrors={updateParams.errors!}
                                                      kind={KIND_PRIMARY}
                                                      size={SIZE_FIXED}
                                                      text={ConfirmText} />
                    : <ButtonDisabled kind={KIND_PRIMARY}
                                      size={SIZE_FIXED}
                                      text={ConfirmText} />
                }
            </div>
        </div>
    );
}

const InvoiceUpdateMinorInfos = (
    {
        axios,
        invoiceId,
        number,
        buyerEmails,
        buyerPhones
    } : {
        axios: Axios,
        invoiceId: string,
        number: string;
        buyerEmails: string[];
        buyerPhones: string[];
    }
) =>
    <ButtonForModal id='showUpdateInvoiceMinorInfosModal'
                    text='Modifier Infos Mineures'
                    modalMaxWidth={null}
                    modalFullMaxWidth={false}
                    childModalContent={(closeModal) =>
                        <InvoiceUpdateMinorInfosModalContent axios={axios}
                                                      invoiceId={invoiceId}
                                                      number={number}
                                                      buyerEmails={buyerEmails}
                                                      buyerPhones={buyerPhones}
                                                      closeModal={closeModal} />
                    } />;

export default InvoiceUpdateMinorInfos;
