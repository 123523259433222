import {formatAdminDateStr} from "../../../utils/dateTimeUtils";
import {formatAmount} from 'factor-lib/utils/amountUtils';
import {ReactElement} from "react";
import {INewBillingMinThresholdQueryFinancingResult} from "./NewBillingQuery";
import {billingRounding} from "./billingRounding";

export const computeMinThresholdFinancingAmount = (
    minThreshold: number,
    financingAmount: number
): number =>
    billingRounding(financingAmount * minThreshold / 100 /* IN % */);

export const CurrentMonthFinancingRow = (
    {
        f,
        minThreshold
    }: {
        f: INewBillingMinThresholdQueryFinancingResult;
        minThreshold: number;
    }
) =>
    <tr>
        <td> { f.invoice.number } </td>
        <td> { formatAdminDateStr(f.creationDateTime) } </td>
        <td> { formatAmount(f.amount) } </td>
        <td> { formatAmount(computeMinThresholdFinancingAmount(minThreshold, f.amount)) } </td>
    </tr>;

const NewBillingMinThresholdFinancingTable = (
    {
        children
    }: {
        children: ReactElement;
    }
) =>
    <table className='table is-striped'>
        <thead>
            <tr>
                <th>#</th>
                <th>Date financement</th>
                <th>Montant financé</th>
                <th>Frais calculés</th>
            </tr>
        </thead>
        { children }
    </table>;

export default NewBillingMinThresholdFinancingTable;