import { Axios } from "axios";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import IOption from "factor-lib/forms/Select/IOption";
import Loader from "factor-lib/Loader";
import GraphQLQueryWrapper from "../GraphQLQueryWrapper";
import { formatIntCompanyIdentifier, IIntCompanyIdentifier, IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";

export const existingCompaniesQueryKey = ['company'];

interface IExistingCompany {
    id: string;
    name: string;
    identifier: IIntCompanyIdentifier;
}

export interface IExistingCompaniesQueryResult {
    companies: IExistingCompany[];
}

const buildExistingCompanyOption = (existingCompany: IExistingCompany): IOption<string> =>
    ({
        label: `${formatIntCompanyIdentifier(existingCompany.identifier)} - ${existingCompany.name}`,
        value: existingCompany.id
    });

const ExistingCompanySelector = (
    {
        axios,
        companyId,
        setCompanyId
    }: {
        axios: Axios;
        companyId: string | null;
        setCompanyId: (companyId: string | null) => void;
    }
) =>
    <GraphQLQueryWrapper queryKey={existingCompaniesQueryKey}
                         axios={axios}
                         queryParams={{
                             query: `query Q {
                                 companies {
                                     id
                                     name
                                     identifier { ${IntCompanyIdentifierGraphQLFields} }
                                 }
                             }`
                         }}
                         onLoading={() => <Loader />}
                         onSuccess={(r: IExistingCompaniesQueryResult) => {
                             const options = r.companies.map(buildExistingCompanyOption);
                             return (
                                <SingleSelect options={options}
                                              selectedOption={!!companyId ? options.find((o) => o.value === companyId)! : null}
                                              selectOption={(option) => setCompanyId(option?.value ?? null)}
                                              placeholder='Choisir une société'
                                              clearable={true} />
                             );
                         }} />

export default ExistingCompanySelector;
