import {Axios, AxiosRequestConfig} from "axios";
import { extractFullErrorMessageNonDefault } from "factor-lib/serverUtils/extractAxiosErrorMessage";
import checkDuplicateInvoiceId from "./checkDuplicateInvoiceId";

export interface IAddInvoiceResponse {
    invoiceId: string;
    notEligibleReason: string | null;
}

// one or the other
export interface IInvoiceAddResponseO {
    nonDuplicateO: IAddInvoiceResponse | null;
    existingInvoiceIdO: string | null;
}

export const addInvoiceAsync = async <T, > (
    axios: Axios,
    url: string,
    params: T,
    config?: AxiosRequestConfig<T>
): Promise<IInvoiceAddResponseO> => {
    try {
        return ({
            nonDuplicateO: (await axios.post<IAddInvoiceResponse>(
                url,
                params,
                config
            )).data,
            existingInvoiceIdO: null
        });
    } catch (error: any) {
        const message: string = extractFullErrorMessageNonDefault(error);
        const duplicateInvoiceIdO = checkDuplicateInvoiceId(message);
        if (duplicateInvoiceIdO) {
            return ({
                nonDuplicateO: null,
                existingInvoiceIdO: duplicateInvoiceIdO
            })
        } // else
        throw error;
    }
}