import { Axios } from "axios";
import CompanySearcherLib from "factor-lib/CompanySearcher/CompanySearcher";
import companySearchRequestAsync from "./companySearchRequestAsync";
import {getFactorContext} from "../../IFactorContext";

// utils
export const PlaceHolderSearchBuyer = 'Nom ou SIREN du Buyer';

const CompanySearcher = (
    {
        axios,
        placeHolder,
        searchInput,
        setSearchInput,
        autofocus,
        setSiren
    }: {
        axios: Axios;
        placeHolder: string;
        searchInput: string;
        setSearchInput: (newSearchInput: string) => void;
        autofocus: boolean;
        setSiren: (newSirenO: string | null) => void;
    }
) =>
    <CompanySearcherLib displayFullError={true}
                        placeHolder={placeHolder}
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        companySearchRequest={(inputCompanySearch: string) => companySearchRequestAsync(axios, inputCompanySearch, getFactorContext().logger)}
                        autofocus={autofocus}
                        setSiren={setSiren} />;

export default CompanySearcher;
