import GraphQLQueryWrapper from "../../../../utils/GraphQLQueryWrapper";
import {Axios} from "axios";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import IOption from "factor-lib/forms/Select/IOption";
import Loader from "factor-lib/Loader";

const BuyerMailSellerSelector = (
    {
        axios,
        buyerCompanyId,
        selectedOption,
        setSelectedOption
    }: {
        axios: Axios;
        buyerCompanyId: string;
        selectedOption: IOption<string> | null;
        setSelectedOption: (newOption: IOption<string> | null) => void;
    }
) =>
    <GraphQLQueryWrapper queryKey={['company', 'id', buyerCompanyId, 'sellers']}
                         axios={axios}
                         queryParams={{
                             query: `query Q($buyerCompanyId: Guid!) {
                                sellers (buyerCompanyId: $buyerCompanyId, withActiveInvoices: true) {
                                    id
                                    company {
                                        name
                                    }
                                }
                             }`,
                             variables: {
                                 buyerCompanyId
                             }
                         }}
                         onLoading={() => <Loader />}
                         onSuccess={(r: {
                             sellers: Array<{id: string; company: {name: string;};}>;
                         }) =>
                             <SingleSelect options={r.sellers.map((s) => ({
                                 value: s.id,
                                 label: s.company.name
                             }))}
                                           selectedOption={selectedOption}
                                           selectOption={setSelectedOption}
                                           placeholder='Seller'
                                           clearable={true} />
                         } />;

export default BuyerMailSellerSelector;