import { Axios } from "axios";
import Loader from "factor-lib/Loader";
import GraphQLQueryWrapper from "../../../utils/GraphQLQueryWrapper";
import { invoiceWithStatusGraphQLQuery } from "../../Invoices/invoiceStatus";
import {
    ISwanIncomingPaymentDetailsCandidateInvoicesQueryResult,
    swanIncomingPaymentDetailsCandidateInvoicesQueryKey
} from "../swanIncomingPaymentDetailsQuery";
import { INewInvoiceMatch } from "./invoiceMatch";
import SwanIncomingPaymentDetailsInvoicesRowsLoaded from "./SwanIncomingPaymentDetailsInvoicesRowsLoaded";

const NotFinancingSettledInvoiceStatusFilters =
    '[ELIGIBLE, NOT_ELIGIBLE, PENDING, FINANCING_REQUESTED, FINANCING_REQUEST_REJECTED, FINANCING_ACTIVE]';

const SwanIncomingPaymentDetailsCandidateInvoicesRows = (
    {
        axios,
        swanIncomingPaymentId,
        customerId,
        searchInput,
        matchEnabled
    }: {
        axios: Axios;
        swanIncomingPaymentId: string;
        customerId: string;
        searchInput: string;
        matchEnabled: {
            newInvoiceMatches: INewInvoiceMatch[];
            setNewInvoiceMatches: (newValue: INewInvoiceMatch[]) => void;
            newPaymentAmountRemaining: number | null;
        } | null;
    }
) =>
    <GraphQLQueryWrapper queryKey={swanIncomingPaymentDetailsCandidateInvoicesQueryKey(swanIncomingPaymentId)}
                         axios={axios}
                         queryParams={{
                             query: `query Q ($customerId: Guid!) {
                                 invoices (customerId: $customerId, isCompletelyPaid: false, statuses: ${NotFinancingSettledInvoiceStatusFilters}) {
                                     base {
                                         ${invoiceWithStatusGraphQLQuery(
                                             `
                                                 id
                                                 number
                                                 amountWoTax
                                                 amountTax
                                                 dueDate
                                                 payment {
                                                     partialAmountPaid
                                                 }
                                                 buyer {
                                                     companyId
                                                 }
                                                 sellerId
                                             `,
                                             '',
                                             '',
                                             '',
                                             ''
                                         )}
                                     }
                                     buyerCompanies { id name }
                                     sellers {
                                        id
                                        customer { directSeller { id } } 
                                        company { name } 
                                    }
                                 }
                             }`,
                             variables: {
                                 customerId
                             }
                         }}
                         onLoading={() =>
                             <tr>
                                 <td/>
                                 <td/>
                                 <td/>
                                 <td/>
                                 <td><Loader /></td>
                                 <td/>
                                 <td/>
                                 <td/>
                                 <td/>
                             </tr>
                         }
                         onSuccess={(r: ISwanIncomingPaymentDetailsCandidateInvoicesQueryResult) =>
                             <SwanIncomingPaymentDetailsInvoicesRowsLoaded searchInput={searchInput}
                                                                           invoices={r.invoices.base}
                                                                           invoicesBuyerCompanies={r.invoices.buyerCompanies}
                                                                           invoicesSellers={r.invoices.sellers}
                                                                           matchEnabled={!!matchEnabled ? {
                                                                               customerId: customerId,
                                                                               ...matchEnabled
                                                                           } : null} />
                         } />;

export default SwanIncomingPaymentDetailsCandidateInvoicesRows;
