
export const comparingMultiple = <T>(
    // uses all the comparators sequentially, until a strict comparison is found
    comparators: Array<(i1: T, i2: T) => number>
): (i1: T, i2: T) => number =>
    (i1, i2) => {
        for (const comparator of comparators) {
            const result: number = comparator(i1, i2);
            if (result !== 0) {
                return result;
            }
        }
        return 0;
    }
