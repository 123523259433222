import IAccessToken from "../../IAccessToken";
import { AddCompanyUrl } from "./companiesUrls";
import WithListWithSearchLayoutPage from "../../utils/WithListWithSearchLayoutPage";
import WithSearchList from "../../utils/WithSearchList";
import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import GraphQLWithAccessTokenWrapper from "../../GraphQLQueryWithAccessTokenWrapper";
import {companiesSearchQueryKey, ICompaniesQueryResult} from "./companiesQuery";
import IAuthUserLoad from "../../IAuthUserLoad";
import CompaniesListLoaded from "./CompaniesListLoaded";
import Loader from "factor-lib/Loader";
import DebounceInput from "react-debounce-input";
import { DefaultDelayMilliseconds } from "factor-lib/forms/Inputs/InputDelayed";

const SearchId = 'companySearchId';
const MinCharactersCount = 3;

const CompaniesPage = (
    {
        accessTokenO,
    }: {
        accessTokenO: IAccessToken | null;
    }
) =>
    <WithListWithSearchLayoutPage outletContext={{accessTokenO}}
                                  defaultSize='30%'
                                  addButtonPropsO={(!!accessTokenO?.authUserO?.canManageProfiles && ({
                                      id: 'addCompanyButton',
                                      text: 'Ajouter une société',
                                      url: AddCompanyUrl
                                  })) || null}>
        <WithSearchList autofocus={false}
                        inputElement={
                            (
                                setFocused: (newFocus: boolean) => void,
                                _: string,
                                setSearchInput: (newInput: string) => void
                            ) =>
                                <DebounceInput minLength={MinCharactersCount}
                                               className='p-size-62'
                                               id={SearchId}
                                               debounceTimeout={DefaultDelayMilliseconds}
                                               onFocus={() => setFocused(true)}
                                               onBlur={() => setFocused(false)}
                                               placeholder='Recherche'
                                               onChange={(e: any /* TODO */) => setSearchInput(e.target.value)} />
                        }
                        childrenFactory={(searchInput: string) =>
                            (searchInput.trim().length >= MinCharactersCount) && (
                                !!accessTokenO
                                    ? <GraphQLWithAccessTokenWrapper accessTokenO={accessTokenO}
                                                                     queryKey={companiesSearchQueryKey(searchInput)}
                                                                     queryParams={{
                                                                         query: `query ($searchInput: String!) {
                                                                             companies (search: $searchInput) {
                                                                                 id
                                                                                 name
                                                                                 identifier { ${ IntCompanyIdentifierGraphQLFields } }
                                                                             }
                                                                         }`,
                                                                         variables: {
                                                                             searchInput
                                                                         }
                                                                     }}
                                                                     onLoading={() => <Loader />}
                                                                     onSuccess={(r: IAuthUserLoad<ICompaniesQueryResult>) =>
                                                                         <CompaniesListLoaded companies={r.value.companies} />
                                                                     } />
                                    : <Loader/>
                            )
                        }/>
    </WithListWithSearchLayoutPage>;

export default CompaniesPage;
